import React, { useContext } from 'react';

import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { DeviceContext } from '../../../../contexts';
import { MAIN_CONTAINER_ID } from '../../../../constants';

interface Props {
  open: boolean;
  setOpen: Function;
  createNewLocation: Function;
  name: string;
}

export const LocationIsSetupDialog: React.FC<Props> = ({ open, setOpen, name, createNewLocation }) => {
  const { isMobile } = useContext(DeviceContext);
  const history = useHistory();

  const goToUsers = () => {
    history.push('/time/users?invite=true');
  };

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          setOpen(false);
        }
      }}>
      <DialogTitle>Great, your {name} location is set up!</DialogTitle>

      <DialogContent>
        <Typography variant="subtitle1" color="textSecondary">
          You can add team members to this location or create another one.
        </Typography>

        <Box marginTop={5} marginBottom={3} display="flex" flexDirection="column" gridGap={16}>
          <Button onClick={goToUsers} variant="contained" size="large" color="primary" fullWidth>
            Add Team members
          </Button>

          <Button
            onClick={() => createNewLocation()}
            variant="contained"
            size="large"
            color="primary"
            fullWidth>
            Add new location
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
