import React from 'react';

import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import { DatePickerProps, KeyboardDatePicker } from '@material-ui/pickers';
import { Button, createStyles, makeStyles, Theme, useTheme } from '@material-ui/core';
import { IoCalendarOutline } from 'react-icons/io5';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import { useAppTextFieldStyles } from './AppTextField';
import { monochrome } from '../../theme/styles';

export const LabelButton = ({ cancel }: { cancel: boolean }) => {
  const text = cancel ? 'Cancel' : 'Ok';
  return (
    <Button
      style={{ width: 90 }}
      disableRipple
      variant={cancel ? 'outlined' : 'contained'}
      color="primary"
      size="small">
      {text}
    </Button>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      borderRadius: '8px',
    },
    root: {
      '& .MuiButtonBase-root': {
        padding: '10px',
      },

      '& .MuiInputBase-root': {
        paddingRight: '3px',
      },
    },
    dialog: {
      '& .MuiDialogActions-root': {
        justifyContent: 'space-between',
        padding: theme.spacing(3),
        paddingTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
          whiteSpace: 'column',
        },
        '& .MuiButtonBase-root': {
          padding: 0,
        },
      },
      '& .MuiPickersCalendarHeader-switchHeader': {
        padding: '16px 24px 8px',
      },
      '& .MuiPickersDay-day': {
        borderRadius: '6px',
        width: 43,
        height: 43,
        [theme.breakpoints.down('xs')]: {
          width: 38,
          height: 38,
        },
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
      '& .MuiPickersBasePicker-pickerView': {
        minWidth: 371,
        [theme.breakpoints.down('xs')]: {
          minWidth: 311,
        },
      },
      '& .MuiPickersCalendar-transitionContainer': {
        minHeight: 260,
      },
      '& .MuiPickersCalendarHeader-transitionContainer': {
        width: '70%',
        height: '20px',
        '& .MuiTypography-root': {
          fontSize: '13px',
          fontWeight: 600,
          lineHeight: '20px',
          color: theme.palette.text.secondary,
          textAlign: 'start',
        },
      },
      '& .MuiPickersCalendarHeader-dayLabel': {
        width: '43px',
        [theme.breakpoints.down('xs')]: {
          width: 38,
        },
      },
      '& .MuiPickersMonthSelection-container': {
        width: 'auto',
      },
    },
    iconButton: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '6px',
      padding: '7px',
    },
    iconButtonLeft: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '6px',
      padding: '7px',
      position: 'absolute',
      right: 66,
    },
  })
);

export const AppKeyboardDatePicker = React.forwardRef((props: DatePickerProps, ref) => {
  const classes = useAppTextFieldStyles({ withoutLabel: !props.label });
  const componentClasses = useStyles();
  const theme = useTheme();

  const arrowButtonProps = {
    disableRipple: true,
    disableTouchRipple: true,
    classes: {
      root: componentClasses.iconButton,
    },
  };

  return (
    <KeyboardDatePicker
      innerRef={ref}
      inputVariant="filled"
      className={componentClasses.root}
      {...props}
      InputProps={
        {
          classes,
          disableUnderline: true,
          ...props.InputProps,
        } as Partial<OutlinedInputProps>
      }
      DialogProps={{
        ...props.DialogProps,
        className: componentClasses.dialog,
        PaperProps: {
          classes: {
            root: componentClasses.paper,
          },
        },
      }}
      cancelLabel={<LabelButton cancel={true} />}
      okLabel={<LabelButton cancel={false} />}
      keyboardIcon={<IoCalendarOutline color={monochrome.medium} />}
      rightArrowIcon={<FiChevronRight color={theme.palette.text.secondary} size="15px" />}
      rightArrowButtonProps={arrowButtonProps}
      leftArrowIcon={<FiChevronLeft color={theme.palette.text.secondary} size="15px" />}
      leftArrowButtonProps={{
        ...arrowButtonProps,
        classes: {
          root: componentClasses.iconButtonLeft,
        },
      }}
      KeyboardButtonProps={{
        disableRipple: true,
        disableTouchRipple: true,
      }}
    />
  );
});
