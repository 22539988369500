import { FilterSection, Team } from '@vyce/core/src/types';

export const getTeamsFilter = (teams: Team[]): FilterSection => ({
  title: 'Teams',
  expanded: true,
  filters: [
    {
      type: 'select',
      multiple: false,
      label: 'Team',
      field: 'team_name',
      values: ['', ...teams.map(team => team.name)],
      defaultValue: '',
    },
  ],
});
