import React from 'react';

import { Box } from '@material-ui/core';
import { TooltipWrapper } from '@nivo/tooltip';

import { PieChartDataItem } from '@vyce/core/src/types';

import { useStyles } from '../styles';
import { LegendItem } from './LegendItem';

interface Props {
  data: PieChartDataItem;
}

export const PieChartTooltip = ({ data }: Props) => {
  const classes = useStyles();

  return (
    <TooltipWrapper anchor="center" position={[0, -15]}>
      <Box className={classes.popup} minWidth={220}>
        <LegendItem data={data} />
      </Box>
    </TooltipWrapper>
  );
};
