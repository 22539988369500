import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Layout, Layouts } from 'react-grid-layout';
import debounce from 'lodash/debounce';

import { FilterSection, UserDashboard, WidgetItem } from '@vyce/core/src/types';
import { DeviceContext, TimeDashboardModuleContext, TimeModuleContext } from '@vyce/core/src/contexts';
import { generateQueryParamsFromObj } from '@vyce/core/src/utils/url';

export const useTimeDashboardData = () => {
  const [timeDashboard, setTimeDashboard] = useState<UserDashboard>();

  const { isMobile } = useContext(DeviceContext);
  const { dashboards, name, selectedCompanyId, permissions, updateUserDashboards } =
    useContext(TimeDashboardModuleContext);
  const { locations } = useContext(TimeModuleContext);

  const history = useHistory();

  const filterSections: FilterSection[] = useMemo(
    () => [
      {
        title: 'Locations',
        expanded: true,
        filters: [
          {
            type: 'select',
            multiple: false,
            label: 'Location',
            field: 'location',
            values: locations,
            defaultValue: undefined,
          },
        ],
      },
    ],
    [locations]
  );

  const handleFilterChange = (newFilters: any) => {
    history.push({
      pathname: history.location.pathname,
      search: generateQueryParamsFromObj({ site_id: newFilters.location?.uuid }),
    });
  };

  const onWidgetStatusChange = useCallback(
    (systemName: string) => {
      const dashboardWidgets = timeDashboard?.widgets || [];
      if (dashboardWidgets.length === 0) return;
      const newWidgets: WidgetItem[] = dashboardWidgets.map(item => {
        return item.system_name === systemName ? { ...item, is_active: !item.is_active } : item;
      });

      setTimeDashboard(value => ({ ...value, widgets: newWidgets }));
    },
    [timeDashboard?.widgets]
  );

  const update = useCallback(
    debounce(dashboard => {
      if (!dashboard) {
        return;
      }
      updateUserDashboards({
        ...dashboards,
        time: dashboard,
      });
    }, 1000),
    []
  );

  const onLayoutChange = (currentLayout: Layout[], newLayouts: Layouts) => {
    setTimeDashboard(value => ({ ...value, layouts: newLayouts }));
  };

  useEffect(() => {
    if (dashboards?.time) {
      setTimeDashboard(dashboards.time);
    }
  }, [dashboards?.time]);

  useEffect(() => {
    if (dashboards?.time === timeDashboard) {
      return;
    }
    update(timeDashboard);
  }, [selectedCompanyId, timeDashboard, update]);

  return {
    isMobile,
    name,
    timeDashboard,
    permissions,
    filterSections,
    selectedCompanyId,
    locations,
    handleFilterChange,
    onWidgetStatusChange,
    onLayoutChange,
  };
};
