import { useContext, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import isEqual from 'lodash/isEqual';

import { useBooleanState } from '@vyce/core/src/hooks';

import { defaultValues } from '../config';
import { getTransformedData } from '../utils';
import { EnrichedTeamsSyncSettingsDTO, Props } from '../types';
import { NotificationContext } from '../../../contexts/notificationContext';

export const useTeamSettingsData = ({
  teamId,
  selectedCompanyId,
  getTeamsSyncSettingsRequest,
  updateTeamsSyncSettingsRequest,
}: Props) => {
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const [mainLoading, setMainLoadingTrue, setMainLoadingFalse] = useBooleanState(false);
  const [buttonLoading, setButtonLoadingTrue, setButtonLoadingFalse] = useBooleanState(false);
  const [settings, setSettings] = useState(defaultValues);
  const [isShowButton, setIsShowButton] = useState(false);

  const methods = useForm<EnrichedTeamsSyncSettingsDTO>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: settings,
  });

  const { watch, getValues, reset } = methods;
  const activate = watch('active');
  const isTimeSync = watch('isTimeSync');
  const isPaySync = watch('isPaySync');
  const payPeriodsCount = watch('recent_pay_periods');
  const lastCheckinWeeksCount = watch('recent_time_periods');

  const fetchTeamsSyncSettings = async () => {
    if (!selectedCompanyId || !teamId) {
      return;
    }
    setMainLoadingTrue();
    try {
      const { data } = await getTeamsSyncSettingsRequest({ companyId: selectedCompanyId, teamId });
      setSettings(getTransformedData(data));
      setMainLoadingFalse();
    } catch (e) {
      console.error(e);
      handleServerError(e);
      setMainLoadingFalse();
    }
  };

  const updateTeamsSettings = async (data: EnrichedTeamsSyncSettingsDTO) => {
    if (!selectedCompanyId || !teamId) {
      return;
    }

    setButtonLoadingTrue();

    const payload = {
      active: data.active,
      recent_pay_periods: data.isPaySync ? data.recent_pay_periods : 0,
      recent_time_periods: data.isTimeSync ? data.recent_time_periods : 0,
    };
    try {
      const { data: responseData } = await updateTeamsSyncSettingsRequest({
        companyId: selectedCompanyId,
        teamId,
        payload,
      });
      setSettings(getTransformedData(responseData));
      setButtonLoadingFalse();
      showNotification({ message: `Settings was saved`, options: { variant: 'success' } });
    } catch (e) {
      console.error(e);
      handleServerError(e);
      setButtonLoadingFalse();
    }
  };

  useEffect(() => reset(settings), [reset, settings]);

  useEffect(() => {
    setIsShowButton(!isEqual(getValues(), settings) || buttonLoading);
  }, [payPeriodsCount, lastCheckinWeeksCount, activate, isTimeSync, isPaySync, settings, buttonLoading]);

  useEffect(() => {
    fetchTeamsSyncSettings();
  }, [selectedCompanyId, teamId]);

  return {
    mainLoading,
    buttonLoading,
    settings,
    methods,
    activate,
    isTimeSync,
    isPaySync,
    isShowButton,
    updateTeamsSettings,
  };
};
