import React, { useEffect, useMemo, useState } from 'react';

import { Tour } from '@reactour/tour';
import noop from 'lodash/noop';
import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { ContentComponent } from './ContentComponent';
import { TourContentItem } from './TourContentItem';

const useStyles = makeStyles<any>((theme: Theme) =>
  createStyles({
    dialogPaper: {
      borderRadius: '16px',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
    tour: {
      backgroundColor: `${theme.palette.background.paper} !important`,
      maxWidth: '600px !important',
      padding: `${theme.spacing(4)}px !important`,
      borderRadius: theme.spacing(2),
      right: '20px',
    },
    controls: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        gridGap: theme.spacing(2),
        '& > button': {
          width: '100%',
          marginLeft: 'unset !important',
        },
      },
    },
  })
);

interface Props {
  steps: any[];
  showTour?: boolean;
  setShowTour: Function;
  dialogTitle: string;
  dialogSubtitle?: string;
  closeTourCallback?: Function;
  message: string;
}

export const AppTour = ({
  steps,
  showTour,
  setShowTour,
  dialogTitle,
  dialogSubtitle,
  message,
  closeTourCallback,
}: Props) => {
  const [firstDialogOpen, setFirstDialogOpen] = useState<boolean>(!!showTour);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [step, setCurrentStep] = useState(0);
  const theme = useTheme<Theme>();
  const classes = useStyles();

  useEffect(() => {
    setFirstDialogOpen(!!showTour);
  }, [showTour]);

  const showAround = () => {
    setIsTourOpen(true);
    setFirstDialogOpen(false);
  };

  const closeTour = () => {
    setShowTour(false);
    setFirstDialogOpen(false);
    setCurrentStep(0);
    if (closeTourCallback) {
      closeTourCallback();
    }
  };

  const handleOpen = (newOpenValue: any) => setIsTourOpen(newOpenValue);

  const containerId = 'app-tour-container';

  const enrichedSteps = useMemo(() => steps.map(item => ({ ...item, stepInteraction: false })), [steps]);

  return (
    <Box id={containerId}>
      <Dialog
        container={document.getElementById(containerId)}
        classes={{ paper: classes.dialogPaper }}
        open={firstDialogOpen}
        onClose={closeTour}>
        <DialogTitle>
          <Typography style={{ color: theme.palette.primary.main, fontWeight: 600 }}>
            {dialogTitle}
          </Typography>
          {!!dialogSubtitle && (
            <Typography style={{ color: theme.palette.primary.main }}>{dialogSubtitle}</Typography>
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="subtitle1">{message}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.controls}>
          <Button size="large" onClick={closeTour} variant="outlined">
            Skip for now
          </Button>
          <Button size="large" onClick={showAround} color="primary" variant="contained">
            Show me around
          </Button>
        </DialogActions>
      </Dialog>

      {isTourOpen && (
        <Tour
          steps={enrichedSteps}
          isOpen={false}
          className={classes.tour}
          currentStep={step}
          showBadge={false}
          setCurrentStep={setCurrentStep}
          setIsOpen={handleOpen}
          setSteps={noop}
          disabledActions={false}
          setDisabledActions={noop}
          position="right"
          beforeClose={closeTour}
          ContentComponent={ContentComponent}
        />
      )}
    </Box>
  );
};

AppTour.TourContentItem = TourContentItem;
