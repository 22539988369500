import React, { useContext } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';

import { DeviceContext } from '../../../../contexts';
import { LocationField } from '../../../../components/controlled-inputs/LocationField';
import { MAIN_CONTAINER_ID } from '../../../../constants';

interface Props {
  open: boolean;
  onClose: any;
  handleSubmit: SubmitHandler<{ location: string }>;
}

export const SelectAnotherPlaceDialog: React.FC<Props> = ({ open, onClose, handleSubmit }) => {
  const methods = useForm<{ location: string }>({
    mode: 'all',
    defaultValues: {
      location: '',
    },
  });
  const { watch } = methods;
  const location = watch('location');
  const { isMobile } = useContext(DeviceContext);

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      open={open}
      onClose={onClose}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <DialogTitle id="responsive-dialog-title">Select another Address</DialogTitle>

          <DialogContent style={{ minWidth: isMobile ? 'auto' : 500 }}>
            <LocationField margin="normal" name="location" allCountries />
          </DialogContent>

          <DialogActions>
            <Button size="large" variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" size="large" color="primary" disabled={!location} variant="contained">
              Select
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
