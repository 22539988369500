import React, { useCallback, useContext } from 'react';

import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { UserPreview } from '@vyce/core/src/views/preview/user/UserPreview';
import {
  getHealthAndSafetyPDFByUserIdRequest,
  getPassportRequest,
  getRtwRequest,
  getTeamMemberPreviewRequest,
  createCustomQualificationRequest,
  createQualificationRequest,
  uploadCustomQualificationImageRequest,
} from '@vyce/core/src/api/teams';
import { DeviceContext } from '@vyce/core/src/contexts';
import { useEmployeeProfileData } from '@vyce/core/src/modules/userPreviewModule/hooks/useEmployeeProfileData';
import { QualificationDialog } from '@vyce/core/src/modules/qualificationDialog';

import { useTypedSelector } from '../../../hooks';
import { getCheckersRequest } from '@vyce/core/src/api/checkers';
import { getTagListRequest } from '@vyce/core/src/api/handbook';

export const TeamMemberPreview: React.FC = () => {
  const { selectedCompany, selectedPosition } = useTypedSelector(state => state.helper);
  const history = useHistory();
  const {
    userPreviewData,
    rtw,
    passport,
    hasPayrollPermissions,
    hasPermissionForCreateQualification,
    selectedCompanyId,
    userId,
    getUserPreviewData,
  } = useEmployeeProfileData({
    getEmployeePreviewRequest: getTeamMemberPreviewRequest,
    getPassportRequest,
    getRtwRequest,
    selectedCompany,
    selectedPosition,
  });
  const { isMobile } = useContext(DeviceContext);

  const getPayrollDetailsLink = useCallback(
    (tab: string): string => {
      return hasPayrollPermissions ? `${history.location.pathname}/${tab}` : '';
    },
    [hasPayrollPermissions]
  );

  return (
    <Box padding={isMobile ? 1 : 5}>
      {userPreviewData && (
        <UserPreview
          rtw={rtw}
          passport={passport}
          userPreviewData={userPreviewData}
          timesheetsLink={getPayrollDetailsLink('timesheets')}
          paymentsLink={getPayrollDetailsLink('payments')}
          agreementsLink={getPayrollDetailsLink('agreements')}
          companyId={selectedCompany?.uuid ?? ''}
          showHealthAndSafetyPDFRequest={getHealthAndSafetyPDFByUserIdRequest}
          withAddQualificationModal={true}
          hasPermissionForCreateQualification={hasPermissionForCreateQualification}
          renderQualificationModal={({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => (
            <QualificationDialog
              isAdmin={false}
              isOpen={isOpen}
              onClose={onClose}
              reRequestData={getUserPreviewData}
              getCheckersRequest={getCheckersRequest}
              triggerCheckerRequest={createQualificationRequest}
              createCustomQualificationRequest={createCustomQualificationRequest}
              uploadCustomQualificationImageRequest={uploadCustomQualificationImageRequest}
              getTagListRequest={getTagListRequest}
              type="outside"
              userId={userId}
              selectedCompanyId={selectedCompanyId}
            />
          )}
        />
      )}
    </Box>
  );
};
