import React from 'react';

import { Box, Typography } from '@material-ui/core';

import { Image } from '@vyce/core/src/types';
import { ImageUploader, OnboardingStepTitle } from '@vyce/core/src/components';
import { ReactComponent as VerifiedIcon } from '@vyce/core/src/assets/svg/verified-small-label-icon.svg';

interface Props {
  passportImage?: Image;
  selfieImage?: Image;
  setPassportImage: Function;
  setSelfieImage: Function;
  verified: boolean;
}

export const PICTURES_UPLOADING_RULES =
  'Please upload a clear picture of the photo page of your passport and a clear selfie. Make sure the passport picture captures the entire page and there is no reflection.';

export const PassportVerificationStep = ({
  passportImage,
  setPassportImage,
  selfieImage,
  setSelfieImage,
  verified,
}: Props) => {
  return (
    <>
      <OnboardingStepTitle text="Passport (ID) verification" />

      {verified ? (
        <Box display="flex" alignItems="center" marginTop={1} gridGap={8}>
          <VerifiedIcon />
          <Typography variant="subtitle2">
            You already have verified passport in VYCE. Great! Just click 'Next'...
          </Typography>
        </Box>
      ) : (
        <>
          <Box marginBottom={1}>
            <Typography variant="caption">
              It is a legal requirement for a company to have a valid up to date Identification Document for
              their workforce.
            </Typography>
          </Box>
          <Typography variant="caption">{PICTURES_UPLOADING_RULES}</Typography>

          <Box marginTop={2} position="relative">
            <Typography style={{ marginBottom: 16 }} variant="subtitle2">
              Passport (ID)
            </Typography>

            <ImageUploader
              width={200}
              height={100}
              image={passportImage}
              isDocument={true}
              onImageUpload={image => setPassportImage(image)}
              extraText="document"
            />
          </Box>

          <Box marginTop={2} position="relative">
            <Typography style={{ marginBottom: 16 }} variant="subtitle2">
              Selfie
            </Typography>

            <ImageUploader
              width={100}
              height={100}
              image={selfieImage}
              onImageUpload={image => setSelfieImage(image)}
              extraText="picture"
            />
          </Box>
        </>
      )}
    </>
  );
};
