import React, { useContext, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Typography,
} from '@material-ui/core';

import { MAIN_CONTAINER_ID } from '../../../constants';
import { DeviceContext } from '../../../contexts';
import { PullTimelogsStrategy, TimeAndAttendanceLocation } from '../../../types';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { AppRoundedRadio } from '../../../components/inputs';

interface TypeRadioProps {
  value: string;
  label: string;
  description: string;
}

const TypeRadio: React.FC<TypeRadioProps> = ({ value, description, label }) => {
  return (
    <Box display="flex" marginTop={2}>
      <FormControlLabel
        style={{ minWidth: 120 }}
        value={value}
        control={<AppRoundedRadio color="primary" />}
        label={label}
      />
      <Typography style={{ marginTop: 6, marginLeft: 4 }}>{description}</Typography>
    </Box>
  );
};

interface Props {
  open: boolean;
  handleClose: Function;
  locations: TimeAndAttendanceLocation[];
  importTimeLogs: (data: PullTimelogsForm) => void;
}

export interface PullTimelogsForm {
  ids: string[];
  strategy: PullTimelogsStrategy;
}

export const PullTimelogsDialog: React.FC<Props> = ({ open, handleClose, locations, importTimeLogs }) => {
  const { isMobile } = useContext(DeviceContext);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const methods = useForm<PullTimelogsForm>({
    defaultValues: {
      ids: [],
      strategy: PullTimelogsStrategy.APPEND,
    },
  });
  const { handleSubmit, control } = methods;

  return (
    <Dialog
      fullScreen={isMobile}
      container={document.getElementById(MAIN_CONTAINER_ID)}
      maxWidth="md"
      open={open}
      onClose={() => handleClose()}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(importTimeLogs)}>
          <DialogTitle>Import Time Logs</DialogTitle>

          <DialogContent style={{ width: isMobile ? 'auto' : 750 }}>
            {/*TODO: Add when backend will be ready*/}
            {/*<ControlledSelect*/}
            {/*  margin="normal"*/}
            {/*  label="Select locations"*/}
            {/*  name="ids"*/}
            {/*  rules={{ required: 'Chose at least one location' }}*/}
            {/*  items={locations}*/}
            {/*  multiple*/}
            {/*/>*/}

            <Box marginTop={2}>
              <Typography>
                There are new Time Logs for this time period available in Time & Attendance module! What
                would you like to do?
              </Typography>
            </Box>

            <Controller
              render={({ field }) => (
                <FormControl>
                  <RadioGroup aria-label="travel-time" value={field.value || null} onChange={field.onChange}>
                    <Box display="flex" flexDirection="column">
                      <TypeRadio
                        value={PullTimelogsStrategy.APPEND}
                        label="Merge - "
                        description="combine all the data from Time & Attendance’s Time Logs and Payroll’s Timesheets."
                      />
                      <TypeRadio
                        value={PullTimelogsStrategy.REPLACE}
                        label="Replace - "
                        description="current Payroll Timesheet contents will be removed and replaced by Time & Attendance’s data."
                      />
                    </Box>
                  </RadioGroup>
                </FormControl>
              )}
              name="strategy"
              control={control}
            />
          </DialogContent>

          <DialogActions>
            <Button size="large" variant="outlined" onClick={() => handleClose()}>
              Cancel
            </Button>

            <Button style={{ width: 100 }} size="large" variant="contained" color="primary" type="submit">
              Import
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
