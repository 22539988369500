import React from 'react';

import { Box, createStyles, makeStyles, Popover, Theme, Typography, useTheme } from '@material-ui/core';
import { BsFillQuestionSquareFill, BsQuestionCircleFill } from 'react-icons/bs';
import clsx from 'clsx';

import { monochrome } from '../theme/styles';
import { MAIN_CONTAINER_ID } from '../constants';

interface Props {
  content: React.ReactElement;
  title?: string;
  size?: string;
  popoverClass?: any;
  type?: 'square' | 'circle';
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverPaper: {
      minWidth: 250,
      boxShadow: 'none',
      border: `1px solid ${theme.palette.divider}`,
      padding: '24px',
    },
    popover: {
      pointerEvents: 'none',
    },
    title: {
      marginLeft: theme.spacing(1),
      color: monochrome.medium,
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
  })
);

export const DetailsIcon: React.FC<Props> = ({
  content,
  title,
  size = '22px',
  popoverClass,
  type = 'circle',
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const iconColor = theme.palette.type === 'dark' ? monochrome.mediumdark : monochrome.medium;

  const handleOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box>
      <span
        className={clsx(classes.iconContainer, 'details-icon')}
        aria-owns={open ? 'help-text-popover' : undefined}
        aria-haspopup="true"
        onMouseLeave={handleClose}
        onMouseEnter={handleOpen}>
        {type === 'circle' && <BsQuestionCircleFill size={size} color={iconColor} />}
        {type === 'square' && <BsFillQuestionSquareFill size={size} color={iconColor} />}
        {title && (
          <Typography variant="caption" className={classes.title}>
            {title}
          </Typography>
        )}
      </span>

      <Popover
        id="help-text-popover"
        open={open}
        container={document.getElementById(MAIN_CONTAINER_ID)}
        elevation={0}
        className={classes.popover}
        classes={{
          paper: clsx(classes.popoverPaper, popoverClass),
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onClose={handleClose}
        disableRestoreFocus>
        <Typography variant="caption" color="textSecondary">
          {content}
        </Typography>
      </Popover>
    </Box>
  );
};
