import React, { useContext } from 'react';

import { Login } from '@vyce/core/src/views/auth/components/Login';
import { AuthContext } from '@vyce/core/src/contexts';

import { useActions, useTypedSelector } from '../../../hooks';

export const EmployerLogin: React.FC = () => {
  const title = 'Welcome To Vyce';
  const subtitle = 'Login to manage your contractor workforce';
  const { userLogin } = useActions();
  const { userDataLoading } = useTypedSelector(state => state.helper);
  const { redirectUrl } = useContext(AuthContext);

  return (
    <Login
      title={title}
      redirectUrl={redirectUrl || '/dashboard'}
      subtitle={subtitle}
      userLogin={userLogin}
      loading={userDataLoading}
    />
  );
};
