import React from 'react';

import { Box, Button, Paper, Typography } from '@material-ui/core';

import { ImageUploader } from '../../../components';
import useStyles from '../styles';
import { Image } from '../../../types';

interface Props {
  avatar?: Image;
  setAvatar: Function;
  loading?: boolean;
  oldAvatarUrl?: string;
  title?: string;
  subtitle?: string;
  label?: string;
  showAvatarButtons: boolean;
  setShowAvatarButtons: (arg0: boolean) => void;
}

const buttonPosition = { top: 16, right: 16 };

export const ProfileAvatarUploader: React.FC<Props> = ({
  avatar,
  setAvatar,
  loading,
  label = 'Profile',
  oldAvatarUrl = '',
  title,
  subtitle,
  showAvatarButtons,
  setShowAvatarButtons,
}) => {
  const classes = useStyles();

  const handleUploadCancel = () => {
    setAvatar({ file: null, url: oldAvatarUrl });
    setShowAvatarButtons(false);
  };

  const handleImageUpload = (avatar: Image) => {
    setAvatar(avatar);
    setShowAvatarButtons(true);
  };

  return (
    <Paper variant="outlined" className={classes.paper}>
      <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%">
        <Box marginBottom={1}>
          <Typography variant="h6" className={classes.name} align="center">
            {label}
          </Typography>
        </Box>

        <ImageUploader
          image={avatar}
          onImageUpload={handleImageUpload}
          extraText="picture"
          buttonPosition={buttonPosition}
          outsideControl
        />
        <Box display="flex" flexDirection="column" alignItems="center" width="100%">
          <Typography variant="h6" className={classes.name} align="center">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center">
            {subtitle}
          </Typography>
          {showAvatarButtons && (
            <Box display="flex" justifyContent="center" marginTop={2}>
              <Button
                className={classes.imageButton}
                disabled={loading}
                onClick={handleUploadCancel}
                variant="outlined">
                Cancel
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Paper>
  );
};
