import React from 'react';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { EmployerDashboard } from './employer/EmployerDashboard';
import { EmployeeDashboard } from './employee/EmployeeDashboard';

export const MainDashboard: React.FC = () => {
  const { user } = useTypedSelector(state => state);
  const isEmployer = user.positions?.length;

  return <>{!!user?.uuid && <>{isEmployer ? <EmployerDashboard /> : <EmployeeDashboard />}</>}</>;
};
