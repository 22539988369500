// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { VerifyPhoneData } from '@vyce/core/src/types';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';

import useStyles from '../styles';
import { sendVerificationSMSRequest } from '../../../api/auth';
import { ButtonTitleWithLoading } from '../../../components/ButtonTitleWithLoading';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  phone?: string;
  loading?: boolean;
  redirectUrl?: string;
  verifyPhone: (data: VerifyPhoneData) => void;
  close?: Function;
}

export const VerifyPhone: React.FC<Props> = ({ loading, close, redirectUrl, verifyPhone }) => {
  const { handleServerError } = useContext(NotificationContext);
  const [code, setCode] = useState<string>('');
  const [counter, setCounter] = useState<number>(60);
  const classes = useStyles();

  useEffect(() => {
    const timer = counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    return () => {
      clearTimeout(timer as any);
    };
  }, [counter]);

  const resendCode = async () => {
    try {
      setCounter(60);
      await sendVerificationSMSRequest();
    } catch (e) {
      handleServerError(e);
    }
  };

  const verify = (skip?: boolean) => {
    verifyPhone({ code: +code, redirectUrl, skip, handleServerError });
    if (close) {
      setTimeout(() => close(), 500);
    }
  };

  return (
    <Box>
      <Typography variant="h5" style={{ fontWeight: 700 }}>
        Verify Mobile Number
      </Typography>

      <Typography className={classes.subtitle} color="textSecondary" variant="subtitle1">
        Please enter the verification code we sent to you
      </Typography>

      <InputMask
        mask="9  9  9  9"
        onChange={(event: any) => setCode(event.target.value.replace(/\s/g, ''))}
        maskChar={null}>
        {() => (
          <TextField
            type="tel"
            margin="normal"
            label="Code"
            autoFocus
            focused
            color="primary"
            InputProps={{
              classes: {
                input: classes.codeInput,
              },
            }}
          />
        )}
      </InputMask>

      <Box marginTop={2}>
        <Button variant="outlined" onClick={resendCode} size="small" disabled={!!counter} color="secondary">
          Request Again {counter ? `(${counter})` : ''}
        </Button>
      </Box>

      <Box marginTop={5}>
        <Button
          type="submit"
          color="primary"
          onClick={() => verify()}
          variant="contained"
          disabled={code?.length !== 4 || loading}
          fullWidth>
          <ButtonTitleWithLoading title="Verify" loaderVariant="paper" loading={!!loading} />
        </Button>
      </Box>

      {redirectUrl && (
        <Box display="flex" marginTop={2} alignItems="center">
          <Link
            className={classes.helpLink}
            style={{ fontSize: '13px' }}
            onClick={() => verify(true)}
            to="#">
            Verify later
          </Link>
        </Box>
      )}
    </Box>
  );
};
