import { useEffect, useMemo, useCallback, useState, useContext } from 'react';

import { useQuery, useBooleanState } from '@vyce/core/src/hooks';
import { CardForm, Subscription } from '@vyce/core/src/types';
import { createSubscriptionRequest } from '@vyce/core/src/api/billing';

import { useActions, useTypedSelector } from '../hooks';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

export const useInviteButton = () => {
  const [isActivateSubscriptionDialogOpen, openActivateSubscriptionDialog, closeActivateSubscriptionDialog] =
    useBooleanState(false);
  const { handleServerError } = useContext(NotificationContext);
  const [isInviteFormOpen, setOpenInviteForm, setCloseInviteForm] = useBooleanState(false);
  const [subscribeLoading, setSubscribeLoading] = useState<boolean>(false);
  const { updateSelectedCompanySubscription } = useActions();
  const query = useQuery();
  const { selectedCompanyAppData, selectedCompany, access_token } = useTypedSelector(state => state.helper);

  const { paySchedules = [], subscription } = selectedCompanyAppData ?? {};
  const selectedCompanyIsSubscribed = useMemo(() => subscription?.active ?? false, [subscription]);

  const onlyOutsourcedPaySchedules = useMemo(
    () => paySchedules.every(item => item.outsourced),
    [paySchedules]
  );

  const inviteFormCanBeOpen = selectedCompanyIsSubscribed || onlyOutsourcedPaySchedules;

  // Default subscribe function with common logic
  // if you have another logic you can not use it
  const simpleSubscribe = useCallback(
    async (data: CardForm) => {
      if (!selectedCompany?.uuid) {
        return;
      }
      setSubscribeLoading(true);
      try {
        const res = await createSubscriptionRequest(access_token, selectedCompany.uuid, data);
        const subscription: Subscription = res.data;
        setSubscribeLoading(false);
        if (updateSelectedCompanySubscription) {
          updateSelectedCompanySubscription(subscription);
        }
        closeActivateSubscriptionDialog();
        setOpenInviteForm();
      } catch (e) {
        handleServerError(e);
        setSubscribeLoading(false);
      }
    },
    [selectedCompany, access_token, updateSelectedCompanySubscription]
  );

  const handleInviteButtonClick = () => {
    if (inviteFormCanBeOpen) {
      setOpenInviteForm();
      return;
    }
    openActivateSubscriptionDialog();
  };

  useEffect(() => {
    const inviteDialog = !!query.get('invite');
    if (inviteDialog) {
      setOpenInviteForm();
    }
  }, []);

  return {
    isActivateSubscriptionDialogOpen,
    inviteFormCanBeOpen,
    isInviteFormOpen,
    subscribeLoading,
    handleInviteButtonClick,
    setCloseInviteForm,
    simpleSubscribe,
    openActivateSubscriptionDialog,
    closeActivateSubscriptionDialog,
  };
};
