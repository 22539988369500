import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { AppTextField } from '@vyce/core/src/components/inputs';

const rules = {
  required: 'Accounts Office Reference is required',
};

export const AccountsOfficeReferenceField = ({ margin = undefined }: { margin?: 'normal' }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      rules={rules}
      name="accounting_office_ref"
      render={({ field }) => (
        <AppTextField
          label="Accounts Office Reference"
          margin={margin}
          fullWidth
          {...field}
          error={!!errors?.accounting_office_ref?.message}
          helperText={(errors?.accounting_office_ref?.message as string) || ''}
        />
      )}
    />
  );
};
