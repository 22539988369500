import React, { useContext } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';

import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';

export const DeleteDialog = ({
  fieldName,
  isOpen,
  onClick,
  onClose,
}: {
  isOpen: boolean;
  fieldName: string;
  onClose: () => void;
  onClick: () => void;
}) => {
  const { isMobile } = useContext(DeviceContext);

  return (
    <Dialog
      fullScreen={isMobile}
      container={document.getElementById(MAIN_CONTAINER_ID)}
      open={isOpen}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">Do you want to delete {fieldName} field?</DialogTitle>
      <DialogContent>
        <Typography variant="caption">
          If you do so, this field will be removed from Location Details of all your Locations. Once you save
          changes, this action cannot be undone.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button size="large" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button size="large" onClick={onClick} color="primary" variant="contained">
          Yes, delete it
        </Button>
      </DialogActions>
    </Dialog>
  );
};
