import { HMRCSetting } from '@vyce/core/src/types';

import { Form } from '../types';

export const prepareHMRCSetting = (data: Form): Partial<HMRCSetting> => {
  const taxInfo = data.tax_office_info.split('/');
  return {
    name: data.name,
    tax_office_number: taxInfo[0],
    tax_office_ref: taxInfo[1],
    accounting_office_ref: data.accounting_office_ref,
    utr: data.utr,
    sender: data.sender,
    sender_id: data.sender_id,
    contact_first_name: data.contact_first_name,
    contact_last_name: data.contact_last_name,
    contact_telephone: data.contact_telephone,
    contact_email: data.contact_email,
    password: data.password,
  };
};
