import React, { useContext } from 'react';

import { DeviceContext } from '../contexts';
import { IconButtonSearch } from './IconButtonSearch';
import { ColorOption } from '../types';
import { AppSearchInput } from './inputs';

interface Props {
  handleSearchChange: any;
  searchText: string;
  variant?: ColorOption;
}

export const AdaptiveSearch: React.FC<Props> = ({ handleSearchChange, searchText, variant }) => {
  const { isMobile } = useContext(DeviceContext);

  return (
    <>
      {isMobile ? (
        <IconButtonSearch
          handleSearchChange={handleSearchChange}
          searchText={searchText}
          variant={variant}
        />
      ) : (
        <AppSearchInput
          placeholder="Search"
          isSmall
          iconColor="#A0A3BD"
          isBorder
          expanded
          onChange={handleSearchChange}
        />
      )}
    </>
  );
};
