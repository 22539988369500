import React from 'react';

import { Box, Divider, Typography } from '@material-ui/core';

import useStyles from '../styles';

export const ContactDivider: React.FC = () => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Divider className={classes.divider} />
      <Typography className={classes.or}>or</Typography>
      <Divider className={classes.divider} />
    </Box>
  );
};
