import React from 'react';

import { Box, Divider, Typography } from '@material-ui/core';

interface Props {
  clocked_in_hours: string;
  clocked_out_hours: string;
}

const fontWeight = { fontWeight: 500 };
const dividerStyles = { width: 2, margin: '0 4px' };

export const ClockValue = ({ clocked_in_hours, clocked_out_hours }: Props) => {
  return (
    <Box width={117} display="flex" gridGap={4} alignItems="center" justifyContent="center">
      <Box width={28} display="flex" justifyContent="center">
        <Typography variant="caption" style={fontWeight}>
          {clocked_in_hours || '-'}
        </Typography>
      </Box>

      <Divider style={dividerStyles} orientation="vertical" flexItem />

      <Box width={28} display="flex" justifyContent="center">
        <Typography variant="caption" style={fontWeight}>
          {clocked_out_hours || '-'}
        </Typography>
      </Box>
    </Box>
  );
};
