import React, { useContext } from 'react';

import { Box, Button } from '@material-ui/core';

import { OnboardingStepTitle } from '@vyce/core/src/components/OnboardingStepTitle';

import { ManualClockContext } from './ManualClockProvider';

const styles = { minWidth: 120 };

export const WhenClockStep = () => {
  const { action, timestamp, onChangeDate, setDialogOpen } = useContext(ManualClockContext);

  const handleClick = () => onChangeDate('now');

  return (
    <>
      <OnboardingStepTitle text={`When to clock ${action}?`} />

      <Box display="flex" justifyContent="center" marginTop={2} gridGap={16}>
        <Button
          onClick={handleClick}
          size="large"
          style={styles}
          variant={timestamp === 'now' ? 'contained' : 'outlined'}
          color="primary">
          Right now
        </Button>
        <Button
          onClick={setDialogOpen}
          size="large"
          style={styles}
          variant={timestamp && typeof timestamp === 'object' ? 'contained' : 'outlined'}
          color="primary">
          Set the time
        </Button>
      </Box>
    </>
  );
};
