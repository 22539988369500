import React, { useContext } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from '@material-ui/core';

import { DeviceContext } from '../../contexts';
import { CopyToClipboard } from '../CopyToClipboard';
import { MAIN_CONTAINER_ID } from '../../constants';

interface Props {
  open: boolean;
  handleClose: Function;
  name: string;
  email?: string;
  phone?: string;
  password: string;
}

export const NewUserPasswordDialog: React.FC<Props> = ({
  open,
  handleClose,
  name,
  email,
  phone,
  password,
}) => {
  const { isMobile } = useContext(DeviceContext);
  const theme = useTheme();

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      open={open}
      onClose={() => handleClose()}>
      <DialogTitle>{name}'s password has been changed.</DialogTitle>

      <DialogContent>
        {email && (
          <Box marginTop={1} display="flex" alignItems="center">
            <Typography align="center" variant="subtitle1">
              Email: <span style={{ color: theme.palette.primary.main }}>{email}</span>
            </Typography>
            <CopyToClipboard text={email} color={theme.palette.primary.main} />
          </Box>
        )}
        {phone && (
          <Box marginTop={1} display="flex" alignItems="center">
            <Typography align="center" variant="subtitle1">
              Phone: <span style={{ color: theme.palette.primary.main }}>{phone}</span>
            </Typography>
            <CopyToClipboard text={phone} color={theme.palette.primary.main} />
          </Box>
        )}
        <Box marginTop={1} display="flex" alignItems="center">
          <Typography align="center" variant="subtitle1">
            New password: <span style={{ color: theme.palette.primary.main }}>{password}</span>
          </Typography>
          <CopyToClipboard text={password} color={theme.palette.primary.main} />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button size="large" variant="outlined" onClick={() => handleClose()}>
          Ok, close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
