import React from 'react';

import { Box, Button, useTheme } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import type { GridColDef } from '@mui/x-data-grid';
import noop from 'lodash/noop';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import DateFnsUtils from '@date-io/date-fns';
import { FormProvider } from 'react-hook-form';
import { RiListSettingsLine } from 'react-icons/ri';
import { IoEyeOutline } from 'react-icons/io5';

import { AppSearchInput } from '@vyce/core/src/components/inputs/AppSearchInput';
import { AppKeyboardDatePicker } from '@vyce/core/src/components/inputs/AppKeyboardDatePicker';
import { AppDataGrid, ButtonTitleWithLoading, GridCellWithAvatar } from '@vyce/core/src/components';
import { getUKFormattedDate, getYesterday } from '@vyce/core/src/utils/dates';
import { EditSingleTimeLogWorkerDialog } from '@vyce/core/src/components/EditWorkerHoursDialog/EditSingleTimeLogWorkerDialog';
import { ExpandTimeLogsInformation } from '@vyce/core/src/components/EditWorkerHoursDialog/ExpandTimeLogsInformation';
import { CheckOutWorkerDTO } from '@vyce/core/src/api/types';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { getAvatar } from '@vyce/core/src/utils';

import { useWidgetData } from './hooks';
import { Props } from './types';
import { useStyles } from './styles';
import { DailyActivityComponent } from '../../time/ActivityLogs/DailyActivity';
import { FiltersDialog } from './components/FiltersDialog';

export const NotClockedOutWidget = (props: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    methods,
    workers,
    roleOptions,
    locationOptions,
    selectedWorker,
    total,
    sortModel,
    cnaViewWorker,
    isMobile,
    dateOfTimeLog,
    logByShift,
    loading,
    isEditSingleTimeLogDialogOpen,
    isExpandTimeLogsInformationDialog,
    isFilterDialogOpen,
    onSubmit,
    restoreFilters,
    openFilterDialog,
    closeFilterDialog,
    handlePageChange,
    handleEditButtonClick,
    handleSortModelChange,
    handleSearchChange,
    handleDateToChange,
    switchFromEditToExpand,
    closeEditSingleTimeLogDialog,
    handleCloseExpandTimeLogsInformationDialog,
    actionsAfterDeletingTimeLog,
    getMembers,
    handlePickTheNextWorker,
  } = useWidgetData(props);

  const columns: GridColDef[] = [
    {
      field: 'first_name',
      headerName: 'Name',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 150,
      renderCell: params => (
        <GridCellWithAvatar
          avatarUrl={params.row.photo_url}
          avatarPlaceholder={getAvatar(params.row.gender)}
          link={cnaViewWorker ? `/time/users/${params.row.first_name}_${params.row.user_id}/profile` : ''}
          name={`${params.row.first_name} ${params.row.last_name}`}
        />
      ),
    },
    {
      field: 'role',
      headerName: 'Role',
      flex: 0.8,
      disableColumnMenu: true,
      minWidth: 100,
    },
    {
      field: 'site_name',
      headerName: 'Location',
      flex: 0.8,
      disableColumnMenu: true,
      minWidth: 100,
    },

    {
      field: 'checked_out',
      headerName: 'Clocked out',
      flex: 0.8,
      disableColumnMenu: true,
      minWidth: 100,
      valueGetter: params => getUKFormattedDate(params.row.checked_out),
    },
    {
      field: 'clocked_hours',
      headerName: 'Clocked Hours',
      flex: 0.8,
      disableColumnMenu: true,
      minWidth: 100,
    },
    {
      field: 'paid_hours',
      headerName: 'Paid Hours',
      flex: 0.7,
      disableColumnMenu: true,
      minWidth: 100,
    },
    {
      field: '',
      headerName: 'Add Paid hours',
      hideSortIcons: true,
      sortable: false,
      flex: 0.7,
      disableColumnMenu: true,
      renderCell: params => (
        <Box width="100%" alignItems="center" justifyContent="center">
          <Button
            disabled={!cnaViewWorker}
            startIcon={<AiOutlinePlusCircle size="28px" color={theme.palette.primary.main} />}
            className={classes.buttonWrapper}
            onClick={() => handleEditButtonClick(params.row as CheckOutWorkerDTO)}
          />
        </Box>
      ),
    },
  ];

  return (
    <Box width="100%" height="100%" display="flex" flexDirection="column" gridGap={16}>
      <Box width="100%" display="grid" gridTemplateColumns="200px 1fr 100px" gridGap={12}>
        {/* AppKeyboardDatePicker very tall component, work with it  Remove label */}
        <AppSearchInput onChange={handleSearchChange} isSmall isBorder expanded={!isMobile} />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <AppKeyboardDatePicker
            value={dateOfTimeLog}
            onChange={handleDateToChange}
            label=""
            margin="none"
            format="dd/MM/yyyy"
            fullWidth
            maxDate={getYesterday()}
            id="date-picker-dialog"
            disableFuture
          />
        </MuiPickersUtilsProvider>
        <Button
          variant="outlined"
          size="small"
          endIcon={<RiListSettingsLine size="20px" />}
          onClick={openFilterDialog}>
          Filter
        </Button>
      </Box>

      <AppDataGrid
        rows={workers}
        getRowId={row => row.user_id}
        columns={columns}
        noPaper
        rowHeight={80}
        height="calc(100% - 50px)"
        rowCount={total}
        sortModel={sortModel}
        pageSize={GRID_PAGE_SIZE}
        paginationMode="server"
        sortingMode="server"
        rowsPerPageOptions={[GRID_PAGE_SIZE]}
        onPageChange={handlePageChange}
        onSortModelChange={handleSortModelChange}
        disableSelectionOnClick
      />

      {logByShift && selectedWorker && isEditSingleTimeLogDialogOpen && (
        <EditSingleTimeLogWorkerDialog
          onSuccess={getMembers}
          userId={selectedWorker.user_id}
          log={logByShift.time_logs[0]}
          open={isEditSingleTimeLogDialogOpen}
          handleClose={closeEditSingleTimeLogDialog}
          date={selectedWorker.checked_out}
          renderAdditionalButton={() => (
            <Button
              size="large"
              onClick={switchFromEditToExpand}
              variant="outlined"
              endIcon={<IoEyeOutline size="20px" />}>
              View
            </Button>
          )}
          renderNextWorkerButton={({ saveFn, loading, disabled }) => (
            <Button
              style={{ width: 152 }}
              size="large"
              disabled={disabled}
              onClick={async () => {
                await saveFn();
                handlePickTheNextWorker();
              }}
              color="primary"
              variant="outlined">
              <ButtonTitleWithLoading title="Save, next" loaderVariant="primary" loading={loading} />
            </Button>
          )}
          renderAvatar={() => (
            <GridCellWithAvatar
              avatarUrl={selectedWorker?.photo_url}
              avatarPlaceholder={getAvatar(selectedWorker?.gender)}
              name={`${selectedWorker?.first_name} ${selectedWorker?.last_name}`}
            />
          )}
        />
      )}
      {logByShift && selectedWorker && isExpandTimeLogsInformationDialog && (
        <ExpandTimeLogsInformation
          timeLogByShift={logByShift}
          isOpen={isExpandTimeLogsInformationDialog}
          userId={selectedWorker.user_id}
          onClose={handleCloseExpandTimeLogsInformationDialog}
          onSuccess={actionsAfterDeletingTimeLog}
          firstName={selectedWorker.first_name}
          lastName={selectedWorker.last_name}
          renderAvatar={() => (
            <GridCellWithAvatar
              avatarUrl={selectedWorker?.photo_url}
              avatarPlaceholder={getAvatar(selectedWorker?.gender)}
              name={`${selectedWorker?.first_name} ${selectedWorker?.last_name}`}
            />
          )}>
          <DailyActivityComponent
            companyId={props.selectedCompanyId}
            canViewLocation
            shiftId={logByShift?.time_logs[0]?.shift_id}
            siteName={logByShift?.time_logs[0]?.site_name}
            userId={selectedWorker.user_id}
            date={selectedWorker.checked_out}
            withControls={false}
          />
        </ExpandTimeLogsInformation>
      )}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(noop)}>
          <FiltersDialog
            isOpen={isFilterDialogOpen}
            onClose={closeFilterDialog}
            locations={locationOptions}
            roles={roleOptions}
            onSubmit={onSubmit}
            restoreFilters={restoreFilters}
            loading={loading}
          />
        </form>
      </FormProvider>
    </Box>
  );
};
