import { ReportSettingsDTO } from '@vyce/core/src/api/types';
import { DragItem } from '../types';

export const prepareData = (
  data: ReportSettingsDTO
): {
  time_logs_fields: DragItem[];
  default_location_fields: DragItem[];
  custom_location_fields: DragItem[];
  detailed_summary_fields: DragItem[];
} => {
  return {
    time_logs_fields:
      data?.time_logs_fields?.data?.fields?.items.map(f => ({ ...f, id: f.system_name, type: 'time' })) ||
      [],
    detailed_summary_fields:
      data?.time_logs_fields?.detailed_summary_tab_data?.fields?.items.map(f => ({
        ...f,
        id: f.system_name,
        type: 'detailed_summary',
      })) || [],
    default_location_fields:
      data?.location_fields?.systemic_fields?.items.map(f => ({
        ...f,
        id: f.system_name,
        disabled: true,
        is_active: true,
        type: 'location',
      })) || [],
    custom_location_fields:
      data?.location_fields?.custom_fields?.items.map(f => ({
        ...f,
        id: f.system_name,
        type: 'location',
      })) || [],
  };
};
