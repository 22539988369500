import React from 'react';

import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { ReactComponent as VerifiedBigLabel } from '@vyce/core/src/assets/svg/verified-big-label-icon.svg';

import useStyles from '../styles';

interface Props {
  title: string;
  verified: boolean;
}

export const DetailsHeader = ({ title, verified }: Props) => {
  const classes = useStyles();
  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Typography className={clsx(classes.bold, classes.headerText)} variant="h5">
          {title}
        </Typography>
        {verified && (
          <Box>
            <VerifiedBigLabel />
          </Box>
        )}
      </Box>
    </Box>
  );
};
