import React, { memo } from 'react';

import { Box } from '@material-ui/core';

import { useStyles } from '../styles';

interface ColorItemProps {
  color: string;
  onChange?: (arg0: string) => void;
}
export const ColorItem = memo(({ color, onChange }: ColorItemProps) => {
  const classes = useStyles();

  const handleClick = () => {
    onChange && onChange(color);
  };

  return <Box className={classes.colorItem} style={{ backgroundColor: color }} onClick={handleClick} />;
});
