// @ts-nocheck
import React from 'react';

import InputMask from 'react-input-mask';
import { Controller, useFormContext } from 'react-hook-form';

import { AppTextField } from '@vyce/core/src/components/inputs';

const rules = {
  required: 'Employer PAYE Reference is required',
};

export const EmployerPAYEReferenceField = ({ margin = undefined }: { margin?: 'normal' }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      rules={rules}
      name="tax_office_info"
      render={({ field }) => (
        <InputMask {...field} mask="***/*******" maskChar={null}>
          {() => (
            <AppTextField
              error={!!errors.tax_office_info?.message}
              label="Employer PAYE Reference (Office No. / Reference Number)"
              margin={margin}
              type="tel"
              fullWidth
              helperText={errors.tax_office_info?.message || ''}
            />
          )}
        </InputMask>
      )}
    />
  );
};
