import React from 'react';

import { useParams } from 'react-router-dom';

import {
  downloadAgreementByEmployerRequest,
  downloadPayslipsRequestByEmployer,
  getAgreementsByEmployerRequest,
  getEmployeeTimesheetsByEmployerRequest,
  getPayslipsPDFByEmployerRequest,
  searchUserPayslipsRequest,
} from '@vyce/core/src/api/pay';
import { getUrlItems } from '@vyce/core/src/utils/url';
import { EmployeePayments } from '@vyce/core/src/views/payroll/components/EmployeePayments';
import { EmployeeTimesheets } from '@vyce/core/src/views/payroll/components/EmployeeTimesheets';
import { Agreements } from '@vyce/core/src/views/profile/user/Agreements';

import { useTypedSelector } from '../../../hooks';

export const RouteEmployerUserProfileComponent: React.FC = () => {
  const { id, tab } = useParams<{ id: string; tab: string }>();
  const { access_token, selectedCompany } = useTypedSelector(state => state.helper);
  const userId = getUrlItems(id).id;

  switch (tab) {
    case 'payments':
      return (
        <EmployeePayments
          getPayslipsPDFRequest={getPayslipsPDFByEmployerRequest}
          searchPayslipsRequest={searchUserPayslipsRequest}
          downloadUserPayslipsRequest={downloadPayslipsRequestByEmployer}
          isLegend={false}
          selectedCompany={selectedCompany}
        />
      );
    case 'timesheets':
      return (
        <EmployeeTimesheets
          getEmployeeTimesheetsRequest={getEmployeeTimesheetsByEmployerRequest}
          token={access_token}
          companyId={selectedCompany?.uuid}
        />
      );
    case 'agreements':
      return (
        <Agreements
          userId={userId}
          companyId={selectedCompany?.uuid}
          downloadAgreementRequest={downloadAgreementByEmployerRequest}
          getAgreementsRequest={getAgreementsByEmployerRequest}
          token={access_token}
        />
      );
    default:
      return <></>;
  }
};
