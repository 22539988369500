import React, { useMemo } from 'react';

import { Button, Menu, MenuItem, Typography, Box, makeStyles, createStyles } from '@material-ui/core';
import clsx from 'clsx';
import { BiChevronUp, BiChevronDown } from 'react-icons/bi';

export interface Option {
  title: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  htmlFor?: string;
  cy?: string;
  onClick: () => void;
}

interface Props {
  options: Option[];
  cy?: string;
}

const useStyles = makeStyles(theme =>
  createStyles({
    rootMenu: {
      '& .MuiPaper-root': {
        border: `2px solid ${theme.palette.primary.main}`,
        width: '220px',
        margin: '10px 0',
      },
      '& .MuiMenuItem-root:last-child': {
        borderRadius: '0 0 6px 6px ',
      },
      '& .MuiMenuItem-root:first-child': {
        borderRadius: '6px 6px 0 0',
      },
    },
    listRoot: {
      padding: 0,
      whiteSpace: 'unset',
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      '&:last-element': {
        borderBottom: 'unset',
      },
      '& .MuiListItemText-root': {
        margin: 0,
      },
    },
    disabled: {
      opacity: 0.5,
    },
    listButton: {
      justifyContent: 'flex-start',
      padding: '6px 18px',
      borderRadius: 0,

      '& .MuiButton-label': {
        whiteSpace: 'unset !important',
      },
    },

    title: {
      textAlign: 'start',
    },
    buttonLabel: {
      width: '100%',
    },
  })
);

const ListItem = ({
  title,
  icon,
  disabled = false,
  htmlFor,
  cy = '',
  onClick,
  onCloseMenu,
}: Option & { onCloseMenu: () => void }) => {
  const classes = useStyles();

  const handleClick = () => {
    if (disabled) return;
    onClick();
    onCloseMenu();
  };

  const itemButton = useMemo(
    () => (
      <Button
        startIcon={icon}
        fullWidth
        cy-test-id={cy}
        component="span"
        onClick={handleClick}
        className={clsx(classes.listButton, { [classes.disabled]: disabled })}
        disabled={disabled}>
        <Typography variant="subtitle2" color="primary" className={classes.title}>
          {title}
        </Typography>
      </Button>
    ),
    [disabled, icon, title, handleClick]
  );

  return (
    <MenuItem className={classes.listRoot}>
      {htmlFor ? (
        <label htmlFor={htmlFor} className={classes.buttonLabel}>
          {itemButton}
        </label>
      ) : (
        itemButton
      )}
    </MenuItem>
  );
};

export const AppMenu = ({ options, cy = '' }: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        color="primary"
        variant="contained"
        size="small"
        onClick={handleClick}
        cy-test-id={cy}
        startIcon={Boolean(anchorEl) ? <BiChevronUp size="20px" /> : <BiChevronDown size="20px" />}>
        Actions
      </Button>

      <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.rootMenu}>
        {options.map(item => (
          <ListItem key={item.title} {...item} onCloseMenu={handleClose} />
        ))}
      </Menu>
    </>
  );
};
