import React, { useCallback, useMemo } from 'react';

import { TbPencil } from 'react-icons/tb';
import { Box, Theme, Typography, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { AppIconButton } from '@vyce/core/src/components/AppIconButton';
import { PAY_FREQUENCIES } from '@vyce/core/src/constants';
import { PaySchedule } from '@vyce/core/src/types';
import { useStyles } from '@vyce/core/src/views/payroll/styles';

import { useTypedSelector } from 'src/hooks';

interface Props {
  schedule: PaySchedule;
}

export const PayScheduleItem = ({ schedule }: Props) => {
  const theme = useTheme<Theme>();
  const classes = useStyles();
  const { selectedCompany } = useTypedSelector(state => state.helper);
  const history = useHistory();
  const payFrequency = useMemo(() => {
    return PAY_FREQUENCIES.find(item => item.value === schedule.pay_frequency)?.label || '';
  }, [schedule]);

  const outsourceType = useMemo(() => {
    return schedule.outsourced ? 'Outsourced to Vyce' : 'Self Managed';
  }, [schedule]);

  const handleEditButtonClick = useCallback(() => {
    history.push(`/payroll/settings/${schedule.name}_${schedule.uuid}_${selectedCompany?.uuid}`);
  }, [history, schedule.name, schedule.uuid, selectedCompany?.uuid]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      marginTop={2}
      className={classes.schedule}>
      <Box>
        <Typography variant="subtitle2">{schedule.name}</Typography>
        <Typography variant="caption">{`${payFrequency} - ${outsourceType}`}</Typography>
      </Box>

      <AppIconButton
        onClick={handleEditButtonClick}
        cy-test-id={`edit-${schedule.name}`}
        variant="secondary">
        <TbPencil color={theme.palette.secondary.dark} size="18px" />
      </AppIconButton>
    </Box>
  );
};
