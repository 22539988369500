import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { AppTextField } from '../inputs';
import { FieldProps } from '../../types';

export const getHelperText = (
  max: number,
  lengthToShowMessage: number,
  value: string,
  errorMessage?: string
): string => {
  if (errorMessage) {
    return errorMessage;
  }
  const length = value?.length || 0;
  const charactersLeft = max - length;
  return charactersLeft <= lengthToShowMessage ? `Characters left: ${charactersLeft}` : '';
};

export const AppTextFieldWithLimit: React.FC<FieldProps> = ({
  limit = 100,
  name = 'name',
  label,
  rules,
  lengthToShowLimitMessage = 10,
  multiline = false,
  margin,
  rows = 1,
}) => {
  const {
    control,
    formState: { errors = {} },
    getValues,
  } = useFormContext();

  return (
    <Controller
      control={control}
      rules={{
        maxLength: limit,
        ...rules,
      }}
      name={name}
      render={({ field }) => (
        <AppTextField
          {...field}
          label={label}
          margin={margin}
          fullWidth
          rows={rows}
          multiline={multiline}
          inputProps={{ maxlength: limit }}
          error={!!errors[name]?.message}
          helperText={getHelperText(
            limit,
            lengthToShowLimitMessage,
            getValues(name),
            errors[name]?.message as string
          )}
        />
      )}
    />
  );
};
