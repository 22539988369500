import React, { Dispatch, MouseEventHandler, SetStateAction, useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Box, Collapse, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { FaCircle } from 'react-icons/fa';
import { RiLogoutCircleRLine } from 'react-icons/ri';
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/styles';
import { NavItem } from '../types';
import clsx from 'clsx';
import { monochrome } from '../theme/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nested: {
      padding: '4px 0',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    nestedIcon: {
      marginLeft: '6px',
    },
    listItem: {
      marginRight: theme.spacing(2),
      borderRadius: '8px',
      padding: theme.spacing(2),
      paddingLeft: '12px',
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.type === 'dark' ? monochrome.medium : monochrome.dark,
    },
    itemTextActive: {
      color: theme.palette.primary.main,
    },
    itemIcon: {
      minWidth: '42px',
    },
    list: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(1),
    },
    spacer: {
      flexGrow: 1,
    },
    selected: {
      borderRadius: '8px',
      backgroundColor:
        theme.palette.type === 'dark'
          ? `${theme.palette.background.default} !important`
          : '#EBECFE !important',
    },
    nestedSelected: {
      borderRadius: '8px',
      backgroundColor: `${theme.palette.background.paper} !important`,
    },
    textSelected: {
      color: theme.palette.primary.main,
    },
    nestedItems: {
      marginLeft: theme.spacing(1),
    },
    itemText: {
      fontSize: '13px',
    },
  })
);

interface Props {
  setMobileOpen: Dispatch<SetStateAction<boolean>>;
  navItems: NavItem[];
  logout: MouseEventHandler<HTMLDivElement>;
}

export const NavDrawer: React.FC<Props> = ({ setMobileOpen, navItems, logout }) => {
  const theme = useTheme<Theme>();
  const classes = useStyles();
  const [nestedCollapses, setNestedCollapses] = useState<any>({});
  const [items, setItems] = useState<NavItem[]>(navItems);
  const history = useHistory();
  const handleCollapse = (name: string) => {
    setNestedCollapses({
      ...nestedCollapses,
      [name]: !nestedCollapses[name],
    });
  };

  const isNestedItemActive = (items: NavItem[]): boolean => {
    return !!items.find(item => window.location.href.includes(item.link));
  };

  const isActive = (link: string, pathname: string): boolean => {
    const pathnameItems = pathname
      .split('?')[0]
      .split('/')
      .filter(i => !!i);
    const linkItems = link
      .split('?')[0]
      .split('/')
      .filter(i => !!i);
    return pathnameItems[0] === linkItems[0];
  };

  const isNestedActive = (link: string, pathname: string): boolean => {
    return link === pathname;
  };

  const setActiveNavItems = (pathname: string, navItems: NavItem[]) => {
    const items = navItems.map(item => ({
      ...item,
      isActive: isActive(item.link, pathname),
      nestedItems: item.nestedItems?.map(item => ({
        ...item,
        isActive: isNestedActive(item.link, pathname),
      })),
    }));
    if (items.length) {
      setItems(items);
    }
  };

  useEffect(() => {
    const appPath = window.location.href.split('#')[1];
    setActiveNavItems(appPath, navItems);
    history.listen(location => {
      setActiveNavItems(location.pathname, navItems);
    });
  }, [navItems]);

  useEffect(() => {
    setActiveNavItems(history.location.pathname, navItems);
  }, [navItems]);

  return (
    <List className={classes.list}>
      {items
        .filter(i => !i.isBottom)
        .map(item => {
          return item.nestedItems ? (
            <div key={item.name}>
              <ListItem
                classes={{ selected: classes.selected, root: classes.listItem }}
                button
                disabled={item?.disabled ?? false}
                selected={item.isActive}
                data-tour={item.name}
                component={Link}
                to={item.link}>
                <ListItemIcon classes={{ root: classes.itemIcon }}>
                  {item.icon && (
                    <item.icon
                      color={
                        isNestedItemActive(item.nestedItems) || item.isActive
                          ? theme.palette.primary.main
                          : monochrome.mediumdark
                      }
                      size="22px"
                    />
                  )}
                </ListItemIcon>
                <Typography
                  className={clsx(classes.itemText, {
                    [classes.itemTextActive]: item.isActive,
                  })}
                  variant="subtitle2">
                  {item.name}
                </Typography>

                <Box
                  onClick={() => handleCollapse(item.name)}
                  marginLeft={8}
                  display="flex"
                  alignItems="center">
                  {nestedCollapses[item.name] ? (
                    <FiChevronDown
                      size="17px"
                      color={
                        isNestedItemActive(item.nestedItems) || item.isActive
                          ? theme.palette.primary.main
                          : theme.palette.text.secondary
                      }
                    />
                  ) : (
                    <FiChevronRight
                      size="17px"
                      color={
                        isNestedItemActive(item.nestedItems) || item.isActive
                          ? theme.palette.primary.main
                          : theme.palette.text.secondary
                      }
                    />
                  )}
                </Box>
              </ListItem>

              <Collapse in={nestedCollapses[item.name]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className={classes.nestedItems}>
                  {item.nestedItems.map(nestedItem => (
                    <ListItem
                      selected={nestedItem.isActive}
                      component={Link}
                      classes={{
                        selected: classes.nestedSelected,
                        root: classes.listItem,
                      }}
                      onClick={() => setMobileOpen(false)}
                      to={nestedItem.link}
                      className={classes.nested}
                      key={nestedItem.name}>
                      <ListItemIcon classes={{ root: classes.itemIcon }}>
                        <FaCircle
                          color={nestedItem.isActive ? theme.palette.primary.main : undefined}
                          className={classes.nestedIcon}
                          size="8px"
                        />
                      </ListItemIcon>

                      <Typography
                        className={clsx(classes.itemText, {
                          [classes.itemTextActive]: item.isActive,
                        })}
                        variant="subtitle2">
                        {nestedItem.name}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </div>
          ) : (
            <ListItem
              selected={item.isActive}
              disabled={item?.disabled ?? false}
              button
              data-tour={item.name}
              cy-test-id={item.name}
              classes={{ selected: classes.selected, root: classes.listItem }}
              component={Link}
              onClick={() => setMobileOpen(false)}
              to={item.link}
              key={item.name}>
              <ListItemIcon classes={{ root: classes.itemIcon }}>
                {item.icon && (
                  <item.icon
                    color={item.isActive ? theme.palette.primary.main : monochrome.mediumdark}
                    size="22px"
                  />
                )}
              </ListItemIcon>
              <Typography
                className={clsx(classes.itemText, {
                  [classes.itemTextActive]: item.isActive,
                })}
                variant="subtitle2">
                {item.name}
              </Typography>
            </ListItem>
          );
        })}

      <div className={classes.spacer} />

      {items
        .filter(i => i.isBottom)
        .map(item => (
          <ListItem
            selected={item.isActive}
            disabled={item?.disabled ?? false}
            data-tour={item.name}
            classes={{ selected: classes.selected, root: classes.listItem }}
            button
            component={Link}
            onClick={() => setMobileOpen(false)}
            to={item.link}
            key={item.name}>
            <ListItemIcon classes={{ root: classes.itemIcon }}>
              {item.icon && (
                <item.icon
                  color={item.isActive ? theme.palette.primary.main : monochrome.mediumdark}
                  size="22px"
                />
              )}
            </ListItemIcon>
            <Typography
              className={clsx(classes.itemText, {
                [classes.itemTextActive]: item.isActive,
              })}
              variant="subtitle2">
              {item.name}
            </Typography>
          </ListItem>
        ))}
      <ListItem button classes={{ selected: classes.selected, root: classes.listItem }} onClick={logout}>
        <ListItemIcon classes={{ root: classes.itemIcon }}>
          <RiLogoutCircleRLine size="22px" color={monochrome.mediumdark} />
        </ListItemIcon>
        <Typography className={classes.itemText} variant="subtitle2">
          Logout
        </Typography>
      </ListItem>
    </List>
  );
};
