import React from 'react';

import { Box, MenuItem, MenuList } from '@material-ui/core';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import { useHistory } from 'react-router-dom';

import { AppA, AppDataGrid, GridActions, GridCellWithAvatar } from '../../../components';
import { TimeMember } from '../../../types';
import { getAvatar } from '../../../utils/getAvatar';
import { GRID_PAGE_SIZE } from '../../../constants';

interface Props {
  closeGridAction: boolean;
  tableHeight?: string;
  workers: TimeMember[];
  loading: boolean;
  total: number;
  sortModel: GridSortModel;
  removeWorker: (param: TimeMember) => void;
  handleSortModelChange: (newModel: GridSortModel) => void;
  handlePageChange: (newPage: number) => void;
}

export const LocationWorkers: React.FC<Props> = ({
  closeGridAction,
  tableHeight = '443px',
  workers,
  loading,
  total,
  sortModel,
  removeWorker,
  handleSortModelChange,
  handlePageChange,
}) => {
  const history = useHistory();

  const goToSettings = (name: string, userId: string) => {
    history.push({
      pathname: `/time/users/${name}_${userId}/profile`,
      state: {
        from: `${history.location.pathname}${history.location.search}`,
        nameOfPrevRoute: 'Locations & Shifts',
      },
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'first_name',
      headerName: 'Name',
      flex: 0.2,
      minWidth: 140,
      disableColumnMenu: true,
      renderCell: params => (
        <GridCellWithAvatar
          link={`/time/users/${params.row.first_name}_${params.row.user_id}/profile`}
          avatarUrl={params.row.photo || params.row.avatar}
          avatarPlaceholder={getAvatar(params.row.gender)}
          name={`${params.row.first_name} ${params.row.last_name}`}
        />
      ),
    },
    {
      field: 'ni_number',
      headerName: 'Ref #',
      width: 95,
      flex: 0.2,
      disableColumnMenu: true,
    },
    {
      field: 'site_name',
      headerName: 'Locations',
      width: 95,
      flex: 0.2,
      disableColumnMenu: true,
    },
    {
      field: 'shift_name',
      headerName: 'Shifts',
      width: 95,
      flex: 0.15,
      disableColumnMenu: true,
    },
    {
      field: 'phone',
      headerName: 'Phone Number',
      disableColumnMenu: true,
      flex: 0.15,
      minWidth: 180,
      renderCell: params => <AppA content={params.row.phone} href={`tel: ${params.row.phone}`} />,
    },
    {
      field: '',
      headerName: 'Actions',
      width: 80,
      hideSortIcons: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => (
        <Box display="flex" width="100%">
          <GridActions close={closeGridAction}>
            <MenuList>
              <MenuItem onClick={() => goToSettings(params.row.first_name, params.row.user_id)}>
                Settings
              </MenuItem>
              <MenuItem onClick={() => removeWorker(params.row as TimeMember)}>
                Remove from location
              </MenuItem>
            </MenuList>
          </GridActions>
        </Box>
      ),
    },
  ];

  return (
    <AppDataGrid
      noPaper
      rows={workers}
      loading={loading}
      height={tableHeight}
      getRowId={row => row.uuid}
      columns={columns}
      rowCount={total}
      paginationMode="server"
      sortingMode="server"
      sortModel={sortModel}
      onSortModelChange={handleSortModelChange}
      onPageChange={handlePageChange}
      pageSize={GRID_PAGE_SIZE}
      rowsPerPageOptions={[GRID_PAGE_SIZE]}
      disableSelectionOnClick
    />
  );
};
