import { createContext } from 'react';
import { ActionType, DragItem } from '../types';

interface Context {
  findCard: (id: string) => {
    field: DragItem;
    index: number;
  };
  moveCard: (dragId: string, hoverIndex: number) => void;
  handleUpdateFields: (type: ActionType) => void;
  createNewField: () => void;
  setNewCandidateForDeleting: (id: string) => void;
  changeCustomFieldName: (id: string, value: string) => void;
}

export const CustomDetailsFieldsContext = createContext({} as Context);

export const CustomDetailsFieldsProvider = CustomDetailsFieldsContext.Provider;
