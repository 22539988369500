import { createStyles, makeStyles, Theme } from '@material-ui/core';

// Styles for block with horizontal scroll with hidden scrollbar

export const useHorizontalScrollStyles = makeStyles((theme: Theme) =>
  createStyles({
    blockWrapper: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.only('sm')]: {
        justifyContent: 'flex-end',
      },
    },
    blockWithHideScroll: {
      justifyContent: 'space-between',
      padding: '10px 10px 0 0',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '-ms-overflow-style': 'none',
      scrollbarWidth: 'none',
      overflow: 'scroll',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'flex-start',
      },
      [theme.breakpoints.down('lg')]: {
        minWidth: '100%',
      },
    },
    navBarBlock: {
      gridGap: '12px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
  })
);
