import React, { useMemo } from 'react';

import Lottie from 'react-lottie';
import { Box } from '@material-ui/core';

import { isDateExpired, isDateExpiring } from '@vyce/core/src/utils';
import { ReactComponent as VerifiedLabel } from '@vyce/core/src/assets/svg/verified-label.svg';
import { ReactComponent as VerifiedLabelExpiring } from '@vyce/core/src/assets/svg/verification-label-expiring.svg';

import * as expiredData from './animations/expired-tag.json';
import * as unverifiedData from './animations/unverified-tag.json';
type Props = {
  verified?: boolean;
  expiry_date?: string | null;
  maxHeight?: number;
  withVerifiedLabel?: boolean;
  withUnverifiedLabel?: boolean;
  withExpiringLabel?: boolean;
};

export const DEFAULT_ANIMATION_OPTIONS = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const expiredOptions = {
  ...DEFAULT_ANIMATION_OPTIONS,
  animationData: expiredData,
};

export const unverifiedOptions = {
  ...DEFAULT_ANIMATION_OPTIONS,
  animationData: unverifiedData,
};
const getStatusComponent = ({
  verified,
  expiry_date,
  maxHeight = 35,
  withVerifiedLabel = true,
  withUnverifiedLabel = true,
  withExpiringLabel = true,
}: Props) => {
  if (verified && isDateExpiring(expiry_date)) {
    return withExpiringLabel ? <VerifiedLabelExpiring /> : null;
  }
  if (verified && isDateExpired(expiry_date)) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <Lottie options={expiredOptions} height={maxHeight} width={80} />
      </Box>
    );
  }
  if (verified) {
    return withVerifiedLabel ? <VerifiedLabel /> : null;
  }
  if (!verified) {
    return withUnverifiedLabel ? (
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <Lottie options={unverifiedOptions} height={maxHeight} width={105} />
      </Box>
    ) : null;
  }
};
export const IconStatusComponent = (data: Props) => {
  const statusComponent = useMemo(() => getStatusComponent(data), [data]);
  return <>{statusComponent}</>;
};
