import React, { MouseEventHandler } from 'react';

import { Box, Button, Fade } from '@material-ui/core';
import { MdCheckBoxOutlineBlank } from 'react-icons/md';
import { HiOutlineTrash } from 'react-icons/hi';
import clsx from 'clsx';

import { useStyles } from '../styles';

interface Props {
  handleClick: MouseEventHandler<HTMLButtonElement>;
  selected: boolean;
}

export const SelectButton: React.FC<Props> = ({ handleClick, selected }) => {
  const classes = useStyles();

  return (
    <Button
      size="small"
      className={clsx(classes.filterButton, {
        [classes.selectedButton]: selected,
        [classes.selectButton]: !selected,
      })}
      onClick={handleClick}>
      {!selected && (
        <Fade in={!selected} timeout={300}>
          <Box display="flex" gridGap={8} alignItems="center">
            <MdCheckBoxOutlineBlank size="16px" />
            <Box>Select</Box>
          </Box>
        </Fade>
      )}
      {selected && (
        <Fade in={selected} timeout={300}>
          <Box display="flex" gridGap={8} alignItems="center">
            <HiOutlineTrash size="16px" />
            <Box>Delete selected</Box>
          </Box>
        </Fade>
      )}
    </Button>
  );
};
