import { useContext, useEffect, useMemo, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { LatLngLiteral } from 'leaflet';
import isEqual from 'lodash/isEqual';
import { useForm } from 'react-hook-form';

import { useBooleanState } from '@vyce/core/src/hooks';
import { LONDON_POSITION } from '@vyce/core/src/views/map/constants';
import { TimeAndAttendanceLocation } from '@vyce/core/src/types';
import { DeviceContext } from '@vyce/core/src/contexts';
import { getLocationDetails } from '@vyce/core/src/utils/location';
import { EMPTY_ADDRESS } from '@vyce/core/src/modules/timeModule/constants';

import { Props } from '../types';
import { prepareCustomForDefault } from '../utils';
import { NotificationContext } from '../../../../contexts/notificationContext';

export const useEditLocationData = ({
  location,
  paySchedules,
  customLocationFields,
  createShift,
  deleteShift,
  saveLocationChanges,
  handleClose,
}: Props) => {
  const preparedCustomForDefault = prepareCustomForDefault(customLocationFields);
  const methods = useForm<TimeAndAttendanceLocation & { customFields: Record<string, string> }>({
    defaultValues: { ...location, customFields: preparedCustomForDefault },
  });
  const { showNotification } = useContext(NotificationContext);
  const [centerPosition, setCenterPosition] = useState<LatLngLiteral>(LONDON_POSITION);
  const [openGoToSettingsDialog, setGoToSettingsDialogOpen, setGoToSettingsDialogClose] =
    useBooleanState(false);

  const [openEditAreaDialog, setEditAreaDialogOpen, setEditAreaDialogClose] = useBooleanState(false);
  const { isMobile } = useContext(DeviceContext);
  const history = useHistory();

  const { watch, setValue, getValues, handleSubmit } = methods;
  const values = watch();

  const addressSnippet = watch('address.address_snippet');
  const polygon = watch('polygon');
  const address = watch('address');

  const isLocationChanged = useMemo(() => {
    return !isEqual(values, { ...location, customFields: preparedCustomForDefault });
  }, [location, preparedCustomForDefault, values]);

  const saveChanges = () => {
    const formData: TimeAndAttendanceLocation & { customFields: Record<string, string> } = getValues();
    formData.shifts = formData.shifts?.map(shift => {
      if (shift.breaks) {
        shift.breaks = shift.breaks.filter(item => item.name && item.minutes);
      }
      return shift;
    });
    saveLocationChanges(formData);
  };

  const checkAddress = async (addressSnippet: string) => {
    const addressData = await getLocationDetails({
      fullAddress: addressSnippet || '',
    });
    if (!addressData.lat || !addressData.lon) {
      setValue('address', EMPTY_ADDRESS);
      return showNotification({ message: 'Please input correct address', options: { variant: 'error' } });
    } else if (
      (addressData && address && addressData.address_snippet !== address.address_snippet) ||
      (address && (!address.lat || !address.lon))
    ) {
      setValue('address', addressData);
    }
  };

  const goToSettings = () => {
    history.push(`/time/settings`);
  };

  useEffect(() => {
    if (customLocationFields.length) {
      customLocationFields.forEach(cf => setValue(`customFields.${cf.system_name}`, cf.value));
    }
  }, [customLocationFields]);

  useEffect(() => {
    if (addressSnippet && addressSnippet !== location.address?.address_snippet) {
      checkAddress(addressSnippet);
    } else if (!addressSnippet) {
      setValue('address', EMPTY_ADDRESS);
    }
  }, [addressSnippet]);

  useEffect(() => {
    if (polygon?.coordinates) {
      const firstCoord = polygon.coordinates[0][0];
      setCenterPosition({ lat: firstCoord[1], lng: firstCoord[0] });
    }
    if (address.lat && address.lon) {
      setCenterPosition({ lat: address.lat, lng: address.lon });
    }
  }, [polygon, address, location]);

  return {
    methods,
    handleClose,
    isLocationChanged,
    addressSnippet,
    centerPosition,
    polygon,
    openEditAreaDialog,
    openGoToSettingsDialog,
    isMobile,
    paySchedules,
    customLocationFields,
    setGoToSettingsDialogClose,
    setValue,
    setGoToSettingsDialogOpen,
    saveChanges,
    handleSubmit,
    createShift,
    deleteShift,
    goToSettings,
    setEditAreaDialogOpen,
    setEditAreaDialogClose,
    saveLocationChanges,
    location,
  };
};
