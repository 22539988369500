import React from 'react';

import { TeamsDashboard } from '@vyce/core/src/modules';
import { TeamsDashboardModuleProvider } from '@vyce/core/src/contexts';
import { ActivateSubscriptionDialog } from '@vyce/core/src/components';

import { UniversalInviteModule } from 'src/modules';
import { useTeamsDashboardData } from './hooks';

export const TeamsDashboardModule = () => {
  const {
    isActivateSubscriptionDialogOpen,
    subscribeLoading,
    contextOptions,
    isInviteFormOpen,
    selectedCompany,
    simpleSubscribe,
    setCloseInviteForm,
    closeActivateSubscriptionDialog,
  } = useTeamsDashboardData();

  return (
    <TeamsDashboardModuleProvider value={contextOptions}>
      <TeamsDashboard />
      <UniversalInviteModule onClose={setCloseInviteForm} isOpen={isInviteFormOpen} />
      <ActivateSubscriptionDialog
        open={isActivateSubscriptionDialogOpen}
        onClose={closeActivateSubscriptionDialog}
        callback={simpleSubscribe}
        loading={subscribeLoading}
        wasSubscribed={selectedCompany?.subscribed_company}
      />
    </TeamsDashboardModuleProvider>
  );
};
