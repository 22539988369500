import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Avatar,
  Box,
  createStyles,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import orderBy from 'lodash/orderBy';
import { IoIosArrowDown } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';

import { CompanyPosition, User } from '@vyce/core/src/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      height: '40px',
      width: '40px',
      backgroundColor: theme.palette.primary.main,
      marginRight: '12px',
    },
    userInfo: {
      cursor: 'pointer',
      padding: '0 11px 0',
      borderRadius: '16px 16px 0 0',
    },
    name: {
      color: theme.palette.type === 'dark' ? '#EFF0F6' : '#4E4B66',
      fontSize: '15px',
      fontWeight: 500,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    companyName: {
      color: theme.palette.type === 'dark' ? '#D9DBE9' : '#6E7191',
      fontSize: '13px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      lineHeight: '21px',
    },
    arrow: {
      color: theme.palette.type === 'dark' ? '#D9DBE9' : '#A0A3BD',
      transition: 'transform .2s ease-in-out',
    },
    arrowUp: {
      transform: 'rotate(180deg)',
    },
    menuItemWrapper: {
      borderTop: `1px solid ${theme.palette.divider}`,
      padding: '4px 0',
    },
    menuItem: {
      width: 153,
      maxWidth: 153,
      padding: '2px 8px',
      borderRadius: '12px',
    },
    menuList: {
      '& .MuiMenuItem-root:first-child': {
        '&:hover': {
          borderRadius: '8px',
        },
      },
    },
    menuPaper: {
      paddingLeft: '54px',
      paddingRight: '32px',
      paddingBottom: 0,
      border: 'none',
      filter: 'drop-shadow(2px 50px 12px rgba(20, 20, 43, 0.04))',
      borderRadius: '0 0 16px 16px',
    },
    boxPaper: {
      filter: 'drop-shadow(40px 5px 12px rgba(20, 20, 43, 0.04))',
      backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.paper : 'white',
      transition:
        'all 314ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 209ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    selectedCompanyName: {
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
    selectedMenuItem: {
      backgroundColor: `${theme.palette.background.paper} !important`,
    },
  })
);

interface Props {
  user: User;
  selectedPosition?: CompanyPosition;
  setPosition: Function;
}

const containerId = 'header-account-menu-container';

export const AccountMenu: React.FC<Props> = ({ user, selectedPosition, setPosition }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { photo, first_name, last_name, positions } = user;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();

  const sortedPositions = useMemo(() => orderBy(positions, 'company.name'), [positions]);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    if (user.is_superuser || !user.positions?.length) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (position: CompanyPosition) => {
    setPosition(position);
    setSelectedCompanyId(position.company.uuid);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      history.push('/profile');
    },
    [history]
  );

  useEffect(() => {
    if (selectedPosition) {
      setSelectedCompanyId(selectedPosition.company.uuid);
    }
  }, [selectedPosition]);

  const avatar = (
    <Avatar data-tour="profile" src={photo?.url} className={classes.avatar} onClick={handleProfileClick} />
  );

  return (
    <Box id={containerId}>
      {isMobile ? (
        avatar
      ) : (
        <>
          <Box
            onClick={handleClickListItem}
            display="flex"
            className={clsx(classes.userInfo, { [classes.boxPaper]: Boolean(anchorEl) })}
            alignItems="center"
            justifyContent="space-between">
            <Box display="flex" marginRight={1}>
              {avatar}
              <Box width={140} maxWidth={140} display="flex" flexDirection="column" justifyContent="center">
                <Typography className={classes.name}>{`${first_name} ${last_name}`}</Typography>
                {selectedPosition?.company?.name && (
                  <Typography className={classes.companyName}>
                    {selectedPosition?.company?.trading_name || selectedPosition?.company?.name}
                  </Typography>
                )}
              </Box>
            </Box>

            {!user.is_superuser && !!user.positions?.length && (
              <IoIosArrowDown
                className={clsx(classes.arrow, { [classes.arrowUp]: Boolean(anchorEl) })}
                size="18px"
              />
            )}
          </Box>

          <Menu
            anchorEl={anchorEl}
            keepMounted
            container={document.getElementById(containerId)}
            classes={{ list: classes.menuList, paper: classes.menuPaper }}
            open={Boolean(anchorEl)}
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            onClose={handleClose}>
            {sortedPositions?.map(position => (
              <Box className={classes.menuItemWrapper} key={position.company.uuid}>
                <MenuItem
                  className={clsx(classes.menuItem, classes.companyName)}
                  classes={{ selected: classes.selectedMenuItem }}
                  selected={position.company.uuid === selectedCompanyId}
                  onClick={() => handleMenuItemClick(position)}>
                  <Typography
                    className={clsx(classes.companyName, {
                      [classes.selectedCompanyName]: position.company.uuid === selectedCompanyId,
                    })}>
                    {position?.company?.trading_name || position?.company?.name}
                  </Typography>
                </MenuItem>
              </Box>
            ))}
          </Menu>
        </>
      )}
    </Box>
  );
};
