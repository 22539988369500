import React, { useContext } from 'react';

import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';

import { DeviceContext } from '../../../contexts';
import { ButtonTitleWithLoading } from '../../../components/ButtonTitleWithLoading';
import { MAIN_CONTAINER_ID } from '../../../constants';

interface Props {
  open: boolean;
  loading: boolean;
  setOpen: Function;
  cancelDeclination: () => void;
}

export const CancelDeclinationDialog: React.FC<Props> = ({ open, setOpen, cancelDeclination, loading }) => {
  const { isMobile } = useContext(DeviceContext);

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">Are you sure you want to cancel declination?</DialogTitle>
      <DialogActions>
        <Button size="large" variant="outlined" onClick={() => setOpen(false)}>
          No, take me back
        </Button>
        <Button
          size="large"
          style={{ width: 230 }}
          color="primary"
          onClick={cancelDeclination}
          variant="contained">
          <ButtonTitleWithLoading
            title="Yes, I want to connect again"
            loaderVariant="paper"
            loading={loading}
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
