import React from 'react';

import { Hiring } from '@vyce/core/src/views/hiring/Hiring';
import config from '@vyce/core/src/api/config';

import { useActions, useTypedSelector } from '../../hooks';

export const EmployerHiring: React.FC = () => {
  const { setIsJobPostedStatus } = useActions();
  const { access_token, selectedCompany, selectedCompanyAppData } = useTypedSelector(state => state.helper);
  const { isJobPosted, companyDataLoading } = selectedCompanyAppData || {};
  const { first_name, last_name, phone, email } = useTypedSelector(state => state.user);
  const { comingSoon } = config;

  return (
    <Hiring
      token={access_token}
      companyId={selectedCompany?.uuid}
      companyDataLoading={companyDataLoading}
      isJobPosted={isJobPosted}
      contactName={`${first_name} ${last_name}`}
      contactPhone={phone}
      contactEmail={email}
      comingSoon={comingSoon}
      setIsJobPostedStatus={setIsJobPostedStatus}
    />
  );
};
