import { useContext } from 'react';

import { AxiosResponse } from 'axios';
import { VariantType } from 'notistack';

import { useBooleanState } from './useBooleanState';
import { useIsMounted } from './useIsMounted';
import { NotificationContext } from '../contexts/notificationContext';

interface Props<RT> {
  successMessage?: string;
  messageVariant?: VariantType;
  showSuccessNotification?: boolean;
  showErrorNotification?: boolean;
  request: RT;
  successCallback?: Function;
}

export const useRequest = <
  RT extends (...args: Parameters<RT>) => Promise<AxiosResponse | null | undefined>
>({
  successMessage,
  messageVariant = 'success',
  showSuccessNotification = false,
  showErrorNotification = true,
  request,
  successCallback,
}: Props<RT>): [boolean, RT] => {
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const [loading, setLoadingTrue, setLoadingFalse] = useBooleanState();
  const isMounted = useIsMounted();

  const sendRequest = async (...params: Parameters<RT>) => {
    if (!isMounted) return null;

    try {
      setLoadingTrue();
      const res = await request(...params);

      if (showSuccessNotification) {
        const message = successMessage || res?.data?.message;
        showNotification({ message, options: { variant: messageVariant } });
      }

      if (successCallback) {
        await successCallback();
      }

      setLoadingFalse();
      return res;
    } catch (e) {
      setLoadingFalse();
      if (showErrorNotification) {
        handleServerError(e);
      }
      console.error(e);
      return null;
    }
  };

  return [loading, sendRequest as RT];
};
