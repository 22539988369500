import { Box } from '@material-ui/core';

import { TeamsSettings as TeamsSettingsCoreModule } from '@vyce/core/src/modules';

import { useTeamSettingsData } from './hooks';
import { useStyles } from './styles';

export const TeamsSettings = () => {
  const classes = useStyles();
  const options = useTeamSettingsData();

  return (
    <Box className={classes.moduleContainer}>
      <TeamsSettingsCoreModule {...options} />
    </Box>
  );
};
