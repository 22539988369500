import React, { useContext, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import { useFormContext } from 'react-hook-form';

import { WeekHours } from '../../components';
import { ScheduleType } from '../../../../modules/timeModule/constants';
import { DeviceContext } from '../../../../contexts';
import { MAIN_CONTAINER_ID } from '../../../../constants';
import { ShiftSchedule } from '../../../../types';

interface Props {
  open: boolean;
  handleClose: Function;
  handleSaveChangesClick?: Function;
  scheduleDetailsFieldName: string;
  scheduleType: ScheduleType;
  scheduleToCompare: ShiftSchedule;
}

export const ScheduleDialog: React.FC<Props> = ({
  open,
  handleClose,
  scheduleType,
  scheduleDetailsFieldName,
  handleSaveChangesClick,
  scheduleToCompare,
}) => {
  const { isMobile } = useContext(DeviceContext);
  const {
    formState: { dirtyFields },
  } = useFormContext();
  const isScheduleDirty = !!get(dirtyFields, scheduleDetailsFieldName);
  const [overlappingDays, setOverlappingDays] = useState<string[]>([]);

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      open={open}
      onClose={() => handleClose()}>
      <DialogTitle>{`Edit ${capitalize(scheduleType)} work hours`}</DialogTitle>

      <DialogContent>
        <WeekHours
          overlappingDays={overlappingDays}
          setOverlappingDays={setOverlappingDays}
          scheduleToCompare={scheduleToCompare}
          scheduleType={scheduleType}
          inputLabel={capitalize(scheduleType)}
          scheduleDetailsFieldName={scheduleDetailsFieldName}
        />
      </DialogContent>

      <DialogActions>
        <Button size="large" variant="outlined" onClick={() => handleClose()}>
          {isScheduleDirty && handleSaveChangesClick ? 'Close without saving' : 'Close'}
        </Button>

        {isScheduleDirty && handleSaveChangesClick && (
          <Button
            disabled={!!overlappingDays.length}
            size="large"
            variant="contained"
            color="primary"
            onClick={() => handleSaveChangesClick()}>
            Save changes
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
