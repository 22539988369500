import React from 'react';

import { Box, Button, makeStyles, Paper, Typography, useTheme } from '@material-ui/core';
import { AiOutlineCloseCircle, AiOutlinePlus } from 'react-icons/ai';
import { HiOutlineCheckCircle, HiOutlineTrash } from 'react-icons/hi';
import ShowMoreText from 'react-show-more-text';

import { TestimonialAuthor } from '../../preview/company/components/TestimonialAuthor';
import { AppIconButton, ConfirmDialog } from '../../../components';
import { AddTestimonialDialog } from '../components/AddTestimonialDialog';
import { useBooleanState } from '../../../hooks';
import { Company, Testimonial } from '../../../types';
import { useTestimonialData } from '../hooks/useTestimonialData';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: 16,
  },
}));

interface Props {
  company?: Company;
  testimonials: Testimonial[];
  getCompanyData: Function;
  token: string;
}

export const CompanyTestimonials: React.FC<Props> = ({ company, getCompanyData, testimonials, token }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [isTestimonialDialogOpen, openTestimonialDialog, closeTestimonialDialog] = useBooleanState(false);
  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] = useBooleanState(false);
  const { loading, setSelectedTestimonial, createTestimonial, deleteTestimonial, togglePublic } =
    useTestimonialData({
      company,
      getCompanyData,
      token,
      closeTestimonialDialog,
      closeConfirmDialog,
    });

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={3}>
        <Typography variant="h6">Testimonials</Typography>

        <Button
          size="small"
          onClick={() => openTestimonialDialog()}
          variant="contained"
          color="primary"
          startIcon={<AiOutlinePlus />}>
          Add Testimonial
        </Button>
      </Box>

      {testimonials.map((testimonial, index) => (
        <Paper key={index} classes={{ root: classes.paper }} variant="outlined">
          <Box display="flex" alignItems="center">
            <Box flex={0.25} style={{ opacity: testimonial.public ? 1 : 0.5 }}>
              <TestimonialAuthor
                avatar={testimonial.photo?.url || null}
                author={testimonial.author_name}
                position={testimonial.author_position}
                company={testimonial.author_company}
              />
            </Box>

            <Box flex={0.75} style={{ opacity: testimonial.public ? 1 : 0.5 }}>
              <ShowMoreText
                lines={2}
                more="Show more"
                less="Show less"
                className="show-more-text-content-testimonial"
                anchorClass={
                  theme.palette.type === 'dark' ? 'my-anchor-css-class-dark' : 'my-anchor-css-class'
                }
                expanded={false}
                truncatedEndingComponent={'... '}>
                "{testimonial.testimonial}"
              </ShowMoreText>
            </Box>

            <Box marginLeft={3} display="flex" gridGap={16}>
              <AppIconButton
                disabled={loading}
                variant={testimonial.public ? 'success' : 'grey'}
                onClick={() => togglePublic(testimonial)}>
                {testimonial.public ? (
                  <HiOutlineCheckCircle color={theme.palette.success.dark} size="20px" />
                ) : (
                  <AiOutlineCloseCircle color={theme.palette.text.secondary} size="20px" />
                )}
              </AppIconButton>

              <AppIconButton
                disabled={loading}
                onClick={() => {
                  setSelectedTestimonial(testimonial);
                  openConfirmDialog();
                }}
                variant="error">
                <HiOutlineTrash color={theme.palette.error.dark} size="20px" />
              </AppIconButton>
            </Box>
          </Box>
        </Paper>
      ))}

      <AddTestimonialDialog
        createTestimonial={createTestimonial}
        open={isTestimonialDialogOpen}
        handleClose={closeTestimonialDialog}
      />

      <ConfirmDialog
        open={isConfirmDialogOpen}
        title="Are you sure you want to delete testimonial?"
        handleClose={() => closeConfirmDialog()}
        handleConfirm={() => deleteTestimonial()}
        loading={loading}
      />
    </>
  );
};
