import React, { FocusEventHandler } from 'react';
import { AppTextField } from '../inputs';
import { Controller, useFormContext } from 'react-hook-form';
import { FieldProps } from '../../types';

interface Props extends FieldProps {
  handleInputFocus?: FocusEventHandler<HTMLInputElement>;
}

const rules = {
  required: 'Name is required',
  validate: (value: string) => {
    return /^[a-zA-Z ]+$/.test(value) || 'Only letters';
  },
};

export const CardNameField: React.FC<Props> = ({ handleInputFocus, margin }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      rules={rules}
      name="name_on_card"
      render={({ field }) => (
        <AppTextField
          label="Name on card"
          margin={margin}
          onFocus={handleInputFocus}
          fullWidth
          {...field}
          error={!!errors?.name?.message}
          helperText={(errors?.name?.message as string) || ''}
        />
      )}
    />
  );
};
