import React from 'react';

import { Typography, Box, useTheme } from '@material-ui/core';

import { getExpiringDays, formatDate, isDateExpiring, isDateExpired } from '@vyce/core/src/utils';

type Props = {
  verified: boolean;
  expiry_date?: string | null;
};

export const TextStatusComponent = ({ verified, expiry_date }: Props) => {
  const theme = useTheme();
  if (verified && expiry_date === null) {
    return (
      <Box color={theme.palette.primary.main} key="status_component">
        <Typography variant="subtitle2">Valid</Typography>
      </Box>
    );
  }
  if (verified && isDateExpiring(expiry_date)) {
    const days = getExpiringDays(formatDate(expiry_date ?? null, undefined, 'DD/MM/YYYY'));
    return (
      <Box color={theme.palette.warning.main} key="status_component">
        <Typography variant="subtitle2">Expiring {days ? `(${days} days)` : ''}</Typography>
      </Box>
    );
  }
  if (verified && isDateExpired(expiry_date)) {
    return (
      <Box color={theme.palette.error.main} key="status_component">
        <Typography variant="subtitle2">Expired</Typography>
      </Box>
    );
  }
  if (verified) {
    return (
      <Box color={theme.palette.primary.main} key="status_component">
        <Typography variant="subtitle2">Valid</Typography>
      </Box>
    );
  }

  return null;
};
