import React from 'react';

import { OnboardingStepTitle } from '@vyce/core/src/components';
import { UTRNumberField } from '@vyce/core/src/components/controlled-inputs';
import { TitleProps } from './types';

export const UTRNumberStep = ({ title = 'What is your UTR Number?' }: TitleProps) => {
  return (
    <>
      <OnboardingStepTitle text={title} />
      <UTRNumberField margin="normal" />
    </>
  );
};
