//@ts-nocheck
import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { FieldProps } from '../../types';
import { AppTextField } from '../inputs';

export const BankAccountName: React.FC<FieldProps> = ({
  margin = undefined,
  disabled = false,
  required = true,
  name = 'bank_account.account_name',
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      rules={required ? { required: 'Account Name is required' } : undefined}
      name={name}
      render={({ field }) => (
        <AppTextField
          {...field}
          disabled={disabled}
          error={!!errors?.bank_account?.account_name?.message}
          id="bank-name"
          label="Account Name"
          margin={margin}
          fullWidth
          helperText={errors?.bank_account?.account_name?.message || ''}
          inputProps={{ 'cy-test-id': 'bank-account-name' }}
        />
      )}
    />
  );
};
