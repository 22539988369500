import React, { useContext, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';

import { NewShiftSchedule } from './NewShiftSchedule';
import { EditBreaks } from './EditBreaks';
import { ShiftNameField } from '../../../../components/controlled-inputs/ShiftNameField';
import { Shift } from '../../../../types';
import { useStyles } from '../../styles';
import { DeviceContext } from '../../../../contexts';
import { EMPTY_SHIFT, ScheduleType } from '../../../../modules/timeModule/constants';
import { MAIN_CONTAINER_ID } from '../../../../constants';
import { isScheduleValid } from '../../LocationOnboarding';

interface Props {
  open: boolean;
  setOpen: Function;
  createShift: (shift: Shift) => void;
}

export const CreateNewShiftDialog: React.FC<Props> = ({ open, setOpen, createShift }) => {
  const classes = useStyles();
  const { isMobile } = useContext(DeviceContext);
  const methods = useForm<Shift>({
    defaultValues: EMPTY_SHIFT,
  });
  const { reset, watch, getValues } = methods;
  const [isValid, setIsValid] = useState(false);
  const values = watch();

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit = (data: Shift) => {
    createShift(data);
    handleClose();
  };

  useEffect(() => {
    const regularSchedule: any = getValues('regular.schedule');
    const overtimeSchedule: any = getValues('overtime.schedule');
    setIsValid(isScheduleValid(regularSchedule, overtimeSchedule));
  }, [values]);

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      open={open}
      maxWidth="lg"
      onClose={handleClose}
      classes={{ paper: classes.shiftDialogPaper }}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle>Create new shift</DialogTitle>

          <DialogContent style={{ overflow: 'hidden' }}>
            <ShiftNameField margin="normal" name="name" />

            <Box display="flex" flexDirection="column" gridGap={16} marginTop={1}>
              <NewShiftSchedule type={ScheduleType.REGULAR} />

              <NewShiftSchedule type={ScheduleType.OVERTIME} />
            </Box>

            {!isValid && (
              <Box marginTop={1} marginBottom={1}>
                <Typography variant="subtitle2" color="error">
                  Overtime hours overlap with Regular work hours
                </Typography>
              </Box>
            )}

            <Box className={classes.shiftHorizontalDivider} />

            <Box marginTop={3} display="flex" justifyContent="flex-start">
              <EditBreaks />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button style={{ width: 105 }} size="large" variant="outlined" onClick={handleClose}>
              Close
            </Button>

            <Button
              disabled={!isValid}
              size="large"
              style={{ width: 105 }}
              type="submit"
              variant="contained"
              color="primary">
              Create
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
