import React, { useContext, useEffect } from 'react';

import { Box, Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import config from '../../assets/config';
import { ColorThemeContext, DeviceContext } from '../../contexts';
import { VYCE_PHONE } from '../../constants';
import { checkApiRequest } from '../../api/auth';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 700,
      fontSize: '48px',
      textAlign: 'center',
      marginTop: '32px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '24px',
        fontWeight: 600,
        marginTop: '16px',
      },
    },
    subtitle: {
      fontWeight: 600,
      fontSize: '26px',
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        fontSize: '15px',
        fontWeight: 500,
      },
    },
    smallText: {
      fontWeight: 500,
      fontSize: '15px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '13px',
        fontWeight: 400,
      },
    },
  })
);

export const Maintenance: React.FC = () => {
  const classes = useStyles();
  const { colorTheme } = useContext(ColorThemeContext);
  const isDarkTheme = colorTheme === 'dark';
  const { dark_mode, light_mode } = config.maintenance;
  const { isMobile } = useContext(DeviceContext);
  const history = useHistory();

  const checkApi = async () => {
    try {
      const res = await checkApiRequest();
      if (res.status === 200) {
        history.push('./dashboard');
      }
    } catch (e) {
      console.error(e);
    }
  };

  const callVYCE = () => {
    window.open(`tel:${VYCE_PHONE}`, '_blank');
  };

  useEffect(() => {
    checkApi();
  }, []);

  return (
    <Box
      height="100%"
      width="100%"
      maxWidth="100vw"
      maxHeight="100vh"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center">
      <img alt="Maintenance" width={isMobile ? '70%' : 779} src={isDarkTheme ? dark_mode : light_mode} />

      <Typography className={classes.title}>We’re just making a few improvements!</Typography>
      <Typography className={classes.subtitle}>
        We’re sorry for any inconvenience. Vyce will be back up and running soon.
      </Typography>

      <Box marginTop={5} display="flex" flexDirection="column" gridGap={8} alignItems="center">
        <Typography className={classes.smallText}>Can’t wait that long?</Typography>

        <Button onClick={callVYCE} color="primary" variant="contained">
          Contact Vyce
        </Button>
      </Box>
    </Box>
  );
};
