import React, { useCallback, useMemo } from 'react';

import { useHistory } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';

import { HoldingPage } from '../../../components';
import config from '../../../assets/config';
import { ModuleVideo } from '../../../components/ModuleVideo';

interface Props {
  hadSubscription?: boolean;
  hasPermissions?: boolean;
  hasSubscription?: boolean;
}

export const GetStarted: React.FC<Props> = ({
  hadSubscription,
  hasPermissions = true,
  hasSubscription = true,
}) => {
  const history = useHistory();

  const textInsteadButton = hasPermissions
    ? ''
    : 'Please contact your company Admin to get access to this module';
  const buttonText = useMemo(() => {
    if (!hasSubscription) return 'Activate your Time Module now...';
    if (hadSubscription) return 'Reactivate your Time Module now...';
    return "Let's start";
  }, [hadSubscription, hasSubscription]);

  const goToSubscription = () => {
    history.push('/subscriptions?dialog=true');
  };

  const letsStartClickHandler = () => {
    history.push('/time/create-location');
  };

  const handleClick = useCallback(() => {
    if (hadSubscription || !hasSubscription) {
      goToSubscription();
    } else {
      letsStartClickHandler();
    }
  }, [hadSubscription]);

  return (
    <HoldingPage
      title="Time & Attendance"
      buttonText={buttonText}
      handleButtonClick={handleClick}
      image={config.welcome.time_and_attendance}
      textInsteadButton={textInsteadButton}>
      <Box marginBottom={4}>
        <Box marginTop={2} marginBottom={2}>
          <ModuleVideo
            previewUrl={config.previewVideo.time_and_attendance}
            url="https://youtu.be/uBJrg6pbnSY?si=WOm7oQkG0YlWXZKv"
          />
        </Box>

        <Box marginTop={1} display="flex">
          <Typography>
            Track your team’s time on location more accurately with Vyce’s Time & Attendance Module
          </Typography>
        </Box>

        <Box marginTop={3}>
          <Typography>
            No hardware, mobile powered time tracking, approvals and reporting. Customise individual location
            settings and allocate hours to projects.
          </Typography>
        </Box>

        <Box marginTop={3}>
          <Typography>Set up your Vyce Time in minutes!</Typography>
        </Box>
      </Box>
    </HoldingPage>
  );
};
