import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from '../styles';
import { ExpandableViewItem } from '../types';

interface Props {
  title: string;
  items: ExpandableViewItem[];
  getDisplayedItem: (item: ExpandableViewItem) => ReactElement;
  isHiring?: boolean;
}

const countOfItems = 4;
const minValueForShowingStepper = 1;

export const ExpandableViews: React.FC<Props> = ({ title, items, isHiring, getDisplayedItem }) => {
  const classes = useStyles();
  const [displayedItems, setDisplayedItems] = useState<ExpandableViewItem[]>(items?.slice(0, 2));
  const [viewAll, setViewAll] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState(0);

  const amountOfSteps = useMemo(() => Math.ceil(items.length / countOfItems), [items]);
  const showCounter = amountOfSteps > minValueForShowingStepper && viewAll;

  const onViewAllClick = () => {
    setViewAll(!viewAll);
  };

  const increaseStep = () => setCurrentStep(currentStep + 1);
  const decreaseStep = () => setCurrentStep(currentStep - 1);

  useEffect(() => {
    const from = currentStep ? currentStep * countOfItems : 0;
    const to = (currentStep + 1) * countOfItems;
    const newItems = viewAll ? items.slice(from, to) : items?.slice(0, 2);
    setDisplayedItems(newItems || []);
  }, [items, currentStep, viewAll]);

  return (
    <>
      <Box display="flex" flexDirection="column" gridGap={isHiring ? 12 : 16}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            className={clsx(classes.bold, { [classes.smallBold]: isHiring, [classes.smallText]: isHiring })}
            color="textSecondary"
            variant="h6">
            {title}
          </Typography>

          {items.length > 2 && (
            <Typography
              onClick={onViewAllClick}
              className={clsx(classes.viewAll, { [classes.smallText]: isHiring })}
              color="primary"
              variant="subtitle1">
              {viewAll ? 'View Less' : 'View All'}
            </Typography>
          )}
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          minHeight={showCounter && 500}
          gridGap={isHiring ? 12 : 16}>
          {displayedItems.map((item, index) => (
            <Box
              key={index}
              padding={2}
              display="flex"
              alignItems="center"
              position="relative"
              overflow="hidden"
              className={classes.tileItem}>
              {getDisplayedItem(item)}
            </Box>
          ))}
        </Box>
      </Box>

      {showCounter && (
        <Box display="flex" alignItems="center" justifyContent="space-between" marginTop={2}>
          <Button
            disabled={currentStep === 0}
            onClick={decreaseStep}
            variant="outlined"
            size="small"
            className={classes.customButton}>
            Previous
          </Button>

          <Typography>
            Page {currentStep + 1} of {amountOfSteps}
          </Typography>

          <Button
            disabled={currentStep + 1 === amountOfSteps}
            onClick={increaseStep}
            variant="outlined"
            size="small"
            className={classes.customButton}>
            Next
          </Button>
        </Box>
      )}
    </>
  );
};
