import React from 'react';

import { BillingInfo } from '@vyce/core/src/views/subscription/BillingInfo';

import { useTypedSelector } from '../../hooks';

export const EmployerBillingInfo: React.FC = () => {
  const { access_token, selectedCompany } = useTypedSelector(state => state.helper);
  const { user } = useTypedSelector(state => state);

  return (
    <BillingInfo
      emailToVerifyIdentity={user?.email}
      token={access_token}
      user={user}
      selectedCompany={selectedCompany}
    />
  );
};
