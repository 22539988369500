import React, { useEffect, useState } from 'react';

import { Box, Button, Fade, FormControlLabel } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

import { AppCheckbox } from '../../../components/inputs';
import { VatNumberField } from '../../../components/controlled-inputs';

interface Props {
  disabled?: boolean;
  checkVAT?: Function;
  value?: string;
  loading: boolean;
  verifyDisabled?: boolean;
  verified?: boolean;
}

export const VATRegisteredInput: React.FC<Props> = ({
  disabled,
  checkVAT,
  verifyDisabled = false,
  ...vatFieldProps
}) => {
  const { getValues, setValue } = useFormContext();
  const [isVatRegistered, setIsVatRegistered] = useState<boolean>(!!getValues('vat_reg_number'));

  useEffect(() => {
    if (!isVatRegistered) {
      setValue('vat_reg_number', null);
    }
    setValue('vat_registered', isVatRegistered);
  }, [isVatRegistered]);

  return (
    <>
      <Box display="flex" marginTop={1} width="100%">
        <FormControlLabel
          control={
            <AppCheckbox
              onChange={e => setIsVatRegistered(e.target.checked)}
              disabled={disabled}
              color="primary"
              checked={isVatRegistered}
            />
          }
          label="Are you VAT Registered?"
        />
      </Box>

      {isVatRegistered && (
        <Fade timeout={500} in={true}>
          <Box width="100%" marginTop={2} display="flex" alignItems="center">
            <VatNumberField {...vatFieldProps} />
            {checkVAT && (
              <Button
                onClick={() => checkVAT()}
                style={{ marginLeft: 8, height: 56 }}
                variant="contained"
                color="primary"
                disabled={verifyDisabled}>
                Verify
              </Button>
            )}
          </Box>
        </Fade>
      )}
    </>
  );
};
