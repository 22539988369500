import { AxiosResponse } from 'axios';

import { UserPreviewData, Testimonial, CaseStudy, Course, Certificate, Test } from '../../types';

export enum Statuses {
  indefinite = 'Indefinite',
  limited = 'Limited',
  no_rtw = 'Unverified',
}

type ShortQualificationProps = {
  verified?: boolean;
  isHiring?: boolean;
};

export type QualificationType =
  | (Course & ShortQualificationProps & { type: 'course' })
  | (Certificate & ShortQualificationProps & { type: 'certificate' })
  | (Test & ShortQualificationProps & { type: 'test' });

export interface WorkExperienceDTO {
  title: string;
  start_date: string;
  end_date: string;
  currently_active: boolean;
  company: {
    name: string;
  };
  isHiring?: boolean;
}

export interface QualificationDTO {
  title: string;
  crt_type: string;
  issuer: string;
  start_date: string;
  expiry_date: string;
  source: string;
  uuid: string;
  isHiring?: boolean;
}

export type SetNotification = (args0: Notification) => void;
export type ShowHealthAndSafetyPDFRequest = (args0: {
  userId: string;
  companyId?: string;
}) => Promise<AxiosResponse<any>>;

export interface UserPreviewProps {
  userPreviewData: UserPreviewData;
  goToEditView?: Function;
  paymentsLink?: string;
  agreementsLink?: string;
  timesheetsLink?: string;
  rtw?: any;
  passport?: any;
  companyId?: string;
  showHealthAndSafetyPDFRequest?: ShowHealthAndSafetyPDFRequest;
  hasPermissionForCreateQualification?: boolean;
  withAddQualificationModal?: boolean;
  renderQualificationModal?: ({
    isOpen,
    onClose,
  }: {
    isOpen: boolean;
    onClose: () => void;
  }) => React.ReactNode;
}

export type ExpandableViewItem = WorkExperienceDTO | QualificationType | Testimonial | CaseStudy;
