import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme =>
  createStyles({
    customTextCell: {
      width: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontSize: 13,
      fontWeight: 500,
    },
  })
);
