import React, { useMemo } from 'react';

import { DailyActivity } from '@vyce/core/src/views/time/ActivityLogs/DailyActivity';
import { TIME_INTERFACE_PERMISSIONS } from '@vyce/core/src/types';

import { useTypedSelector } from '../../hooks';

export const EmployerDailyActivity: React.FC = () => {
  const { selectedCompany, selectedPosition } = useTypedSelector(state => state.helper);

  const canViewLocation = useMemo(
    () =>
      selectedPosition?.permissions?.length
        ? selectedPosition?.permissions.includes(TIME_INTERFACE_PERMISSIONS.LOCATIONS_AND_SHIFTS)
        : true,
    [selectedPosition]
  );

  return <DailyActivity canViewLocation={canViewLocation} companyId={selectedCompany?.uuid} />;
};
