import React from 'react';

import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from '../styles';

export const Empty = () => {
  const classes = useStyles();
  return (
    <Box display="flex" height="100%" alignItems="center" justifyContent="center">
      <Typography className={clsx(classes.emptyText, classes.legendItemCount)}>
        Have your team fill out our Health & Safety Questionnaire to display this chart.
      </Typography>
    </Box>
  );
};
