import React, { MouseEventHandler } from 'react';
import { Button, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(6),
      paddingBottom: 0,
      flexDirection: 'column',
      width: '100%',
    },
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
      fontWeight: 600,
    },
    subtitle: {
      textAlign: 'center',
      marginBottom: theme.spacing(3),
      maxWidth: '400px',
    },
  })
);

interface Props {
  title: string;
  callback?: MouseEventHandler<HTMLButtonElement>;
  buttonText?: string;
  subtitle?: string;
  image: any;
}

export const EmptyList: React.FC<Props> = ({ title, callback, buttonText, subtitle, image }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <img height={100} alt="Success" src={image} />

      <Typography color="textSecondary" className={classes.title} variant="body1">
        {title}
      </Typography>

      <Typography color="textSecondary" variant="subtitle1" className={classes.subtitle}>
        {subtitle}
      </Typography>

      {buttonText && callback && (
        <Button color="primary" variant="contained" onClick={callback}>
          {buttonText}
        </Button>
      )}
    </div>
  );
};
