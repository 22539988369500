import React from 'react';

import { Avatar, Box, Typography, useTheme } from '@material-ui/core';

import { ActivityLogs } from '@vyce/core/src/views/time/ActivityLogs';
import { BackTo, EmptyList, PageLoading } from '@vyce/core/src/components';
import { getAvatar } from '@vyce/core/src/utils/getAvatar';
import config from '@vyce/core/src/assets/config';

import { useData } from './hooks';

export const TimeEmployeeDailyActivity = () => {
  const theme = useTheme();

  const { worker, loading, workerActivityLog, isMobile, back } = useData();
  return (
    <>
      {loading ? (
        <Box height="calc(100vh - 200px)">
          <PageLoading />
        </Box>
      ) : (
        <Box>
          <Box margin="0 -15px 0 0">
            <BackTo backTo={{ text: 'Logs', back }} />
            <Box
              display="flex"
              alignItems="center"
              paddingRight={5}
              paddingLeft={5}
              paddingBottom={3}
              bgcolor={theme.palette.background.paper}>
              <Avatar src={getAvatar(worker?.gender ?? null)} />
              <Box marginLeft={2}>
                <Typography variant="h6">
                  {worker?.first_name} {worker?.last_name}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            gridGap={24}
            paddingRight={isMobile ? 2 : 5}
            paddingLeft={isMobile ? 2 : 5}>
            {!worker || !workerActivityLog.length ? (
              <Box height="calc(100vh - 200px)" display="flex" alignItems="center" justifyContent="center">
                <EmptyList title="No data found..." image={config.icons3D.lightning} />
              </Box>
            ) : (
              <Box>
                {workerActivityLog.map((log, index) => (
                  <Box maxWidth="700px" key={index}>
                    <ActivityLogs.WorkerLog
                      clockedHours={0}
                      overtimeHours={0}
                      locationName=""
                      check_in_timestamp={log.check_in_timestamp}
                      check_out_timestamp={log.check_out_timestamp}
                      check_in_within_area={log.check_in_within_area}
                      check_out_within_area={log.check_out_within_area}
                      auto_clock_out={false}
                      locationVerificationTitle={log.locationVerificationTitle}
                      faceVerificationTitle={log.faceVerificationTitle}
                      clockInFaceVerified={log.clockOutFaceVerified}
                      clockOutFaceVerified={log.clockOutFaceVerified}
                      clockInIcon={log.clockInIcon}
                      clockOutIcon={log.clockOutIcon}
                      first_name={worker?.first_name ?? ''}
                      last_name={worker?.last_name ?? ''}
                      gender={worker?.gender ?? null}
                    />
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};
