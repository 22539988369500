import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import {
  BIRTHDAYS_TABLE_COLUMNS,
  getTimesheetsTableColumns,
  RECENT_EMPLOYEES_TABLE_COLUMNS,
} from '../config';
import { GRID_PAGE_SIZE } from '../../../constants';

interface Props {
  data: any[];
  systemName: string;
  companyId: string;
}

export const TableWidget = ({ data, systemName, companyId }: Props) => {
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [idFieldName, setIdFieldName] = useState<string>('id');

  useEffect(() => {
    let cols: GridColDef[] = [];
    switch (systemName) {
      case 'team_members_birthdays':
        cols = BIRTHDAYS_TABLE_COLUMNS;
        setIdFieldName('user_id');
        break;
      case 'recent_timesheets':
        cols = getTimesheetsTableColumns(companyId);
        setIdFieldName('pay_run_id');
        break;
      case 'recent_registered_employees':
        cols = RECENT_EMPLOYEES_TABLE_COLUMNS;
        setIdFieldName('user_id');
        break;
    }
    setColumns(cols);
  }, [systemName]);

  return (
    <Box width="100%" height="100%">
      <DataGrid
        rows={data}
        getRowId={data => data[idFieldName]}
        columns={columns}
        rowCount={data.length}
        pageSize={GRID_PAGE_SIZE}
        rowsPerPageOptions={[data.length]}
        disableSelectionOnClick
      />
    </Box>
  );
};
