import { GridSortModel } from '@mui/x-data-grid';

export const DEFAULT_JOBS_SORTING = {
  field_name: 'updated_at',
  desc: true,
};

export const getJobsSortingObject = (sortLabel: string) => {
  switch (sortLabel) {
    case 'Nearest':
      return {
        field_name: 'distance',
        desc: false,
      };
    case 'Highest salary':
      return {
        field_name: 'salary_to',
        desc: true,
      };
    case 'Recent':
      return {
        field_name: 'updated_at',
        desc: true,
      };
    case 'Starts soon':
      return {
        field_name: 'start_date',
        desc: true,
      };
    case 'Starts late':
      return {
        field_name: 'start_date',
        desc: false,
      };
    default:
      return DEFAULT_JOBS_SORTING;
  }
};

export const formatSortModel = <T>(models: GridSortModel) => {
  return models.map(item => ({
    field_name: item.field as keyof T,
    desc: item.sort === 'desc',
  }));
};

export const formatTimeSortModel = <T>(models: GridSortModel) => {
  return models.map(item => ({
    field: item.field as keyof T,
    desc: item.sort === 'desc',
  }));
};
