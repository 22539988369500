import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  authContainer: {
    maxWidth: '430px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    padding: '64px 0',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3),
    },
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  itemsContainer: {
    minHeight: '100vh',
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  picture: {
    width: '100%',
    objectFit: 'cover',
    [theme.breakpoints.up('lg')]: {
      maxHeight: '100vh',
    },
  },
  rights: {
    left: 0,
    color: theme.palette.text.secondary,
    position: 'static',
    marginTop: theme.spacing(5),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  welcomeText: {
    marginTop: theme.spacing(3),
    fontWeight: 700,
  },
  subtitle: {
    marginBottom: theme.spacing(1),
  },
  form: {
    width: '100%',
  },
  switchLink: {
    color: theme.palette.text.secondary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  helpLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  gutterTop: {
    marginTop: theme.spacing(3),
  },
  spaceLeft: {
    marginLeft: '4px',
  },
  bottomButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  userName: {
    color: theme.palette.primary.main,
  },
  codeInput: {
    fontSize: '42px',
    width: '180px',
  },
  buttonCard: {
    backgroundColor: '#EBECFE',
    display: 'flex',
    gridGap: '10px',
    paddingLeft: 16,
    paddingRight: 32,
    borderRadius: 16,
    cursor: 'pointer',
    '& h6': {
      cursor: 'pointer',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      '& h6': {
        color: '#fff',
      },
    },
  },

  questionCard: {
    padding: 32,
  },
  reversedButtonCard: {
    paddingLeft: 32,
    paddingRight: 16,
    flexDirection: 'row-reverse',
  },
  cardImageWrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '160px',
    height: '200px',
    [theme.breakpoints.down('xs')]: {
      height: '100px',
      maxWidth: '80px',
    },
  },

  wrongPlaceImageWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    maxHeight: '280px',
    [theme.breakpoints.down('xs')]: {
      maxHeight: '100px',
    },
  },
}));
