import React, { useEffect, useMemo, useState } from 'react';

import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { AuthLayout } from '@vyce/core/src/views/auth/AuthLayout';
import { VerifyPhone } from '@vyce/core/src/views/auth/components/VerifyPhone';
import { ForgotPassword } from '@vyce/core/src/views/auth/components/ForgotPassword';
import { WrongPlace } from '@vyce/core/src/views/auth/components/WrongPlace';
import config from '@vyce/core/src/assets/config';
import { AuthProvider } from '@vyce/core/src/contexts';

import { GeneralRegister } from './GeneralRegister';
import { GeneralLogin } from './GeneralLogin';
import { useTypedSelector } from '../../../hooks';
import { useActions } from '../../../hooks';

export enum GENERAL_AUTH_PATHS {
  SIGNUP = '/auth/signup',
  LOGIN = '/auth/login',
  VERIFY_PHONE = '/auth/verify-phone',
  WRONG_PLACE = '/auth/wrong-place',
  FORGOT_PASSWORD = '/auth/forgot-password/:token',
}

export const GeneralAuth: React.FC = (props: any) => {
  const { userDataLoading } = useTypedSelector(state => state.helper);
  const { verifyPhone } = useActions();
  const [fromUrl, setFromUrl] = useState<string>('');
  const [passedByRoleSelection, setPassedByRoleSelection] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    const from = props?.location?.state?.from;
    if (from) {
      setFromUrl(from);
    }
  }, [props?.location?.state?.from]);

  const contextOptions = useMemo(
    () => ({
      redirectUrl: fromUrl,
      passedByRoleSelection,
      setPassedByRoleSelection,
    }),
    [fromUrl, passedByRoleSelection, setPassedByRoleSelection]
  );
  useEffect(() => {
    setPassedByRoleSelection(false);
  }, [pathname]);

  return (
    <AuthLayout rightPictureSrc={config.auth.generic}>
      <AuthProvider value={contextOptions}>
        <Switch>
          <Route exact path="/auth" render={() => <Redirect to={GENERAL_AUTH_PATHS.SIGNUP} />} />
          <Route path={GENERAL_AUTH_PATHS.SIGNUP} component={GeneralRegister} />
          <Route path={GENERAL_AUTH_PATHS.LOGIN} component={GeneralLogin} />
          <Route path={GENERAL_AUTH_PATHS.WRONG_PLACE} component={WrongPlace} />

          <Route path={GENERAL_AUTH_PATHS.FORGOT_PASSWORD} component={() => <ForgotPassword />} />
          <Route
            path={GENERAL_AUTH_PATHS.VERIFY_PHONE}
            component={() => (
              <VerifyPhone
                loading={userDataLoading}
                redirectUrl={fromUrl || '/onboarding'}
                verifyPhone={verifyPhone}
              />
            )}
          />
        </Switch>
      </AuthProvider>
    </AuthLayout>
  );
};
