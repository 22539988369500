import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useForm } from 'react-hook-form';
import { Box, Dialog, Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

import { createEmployeeRequest, updateEmployeeRequest } from '@vyce/core/src/api/pay';
import {
  getUserInviteRequest,
  redeemInviteTokenRequest,
  redeemPublicInviteTokenRequest,
  redeemPublicInviteToTeamTokenRequest,
} from '@vyce/core/src/api/invites';
import {
  ActiveOnboardingStep,
  Answer,
  CSCSParams,
  Employee,
  HowItWorksStep,
  Image,
  INVITE_MODULES,
  InviteLinkType,
  OnboardingStep,
  PayTag,
  PayTagGroup,
  Starters,
  Tag,
  TaskNames,
  UserSurvey,
} from '@vyce/core/src/types';
import { checkNINumber, checkPhoneNumber } from '@vyce/core/src/utils/onboarding';
import { AppOnboarding, HowItWorks, OnboardingStepTitle } from '@vyce/core/src/components';
import {
  BankDetailsStep,
  CSCSRegistrationNumberStep,
  DateOfBirthStep,
  EmergencyContactsStep,
  EmployeeJobTitleStep,
  GenderStep,
  HealthAndSafetyQuestions,
  HomeAddressStep,
  LimitedCompanyStep,
  NationalityStep,
  NINumberStep,
  PassportVerificationStep,
  PhoneNumberStep,
  StartDateStep,
  StarterLocationStep,
  StarterManagerStep,
  UTRNumberStep,
  VatRegisteredStep,
  WelcomeStep,
} from '@vyce/core/src/components/onboarding';
import { useQuery } from '@vyce/core/src/hooks/useQuery';
import { getLocationDetails } from '@vyce/core/src/utils/location';
import { DeviceContext } from '@vyce/core/src/contexts';
import { getSurveyAnswers, getSurveysRequest, saveSurveyAnswers } from '@vyce/core/src/api/survey';
import { SEARCH_HEALTH_SURVEY_SUBSTRING } from '@vyce/core/src/views/profile/user/H&S';
import { triggerCheckerRequest } from '@vyce/core/src/api/checkers';
import { uploadPassportRequest, uploadSelfieRequest } from '@vyce/core/src/api/documents';
import { BUSINESS_TYPES, MAIN_CONTAINER_ID } from '@vyce/core/src/constants';
import {
  clearOnboardingLSData,
  saveOnboardingDataToLS,
  setOnboardingData,
} from '@vyce/core/src/utils/local-storage';
import config from '@vyce/core/src/assets/config';
import { createPayScheduleTagRequest } from '@vyce/core/src/api/pay-schedules';
import { NEW_TAG_ID } from '@vyce/core/src/components/controlled-inputs';

import { useActions, useTypedSelector } from '../../../hooks';
import { CISConfirmationStep } from './steps/CISConfirmationStep';
import { SuccessDialog } from '../components/SuccessDialog';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

interface RedeemInviteTokenParams {
  token: string;
  inviteToken: string;
  showNotification: Function;
  inviteType: InviteLinkType;
  starters: Starters;
  isTeamsPublicLink?: boolean;
}

export const redeemInviteToken = async ({
  token,
  inviteToken,
  showNotification,
  inviteType,
  starters,
  isTeamsPublicLink,
}: RedeemInviteTokenParams) => {
  try {
    if (inviteType === InviteLinkType.PRIVATE) {
      await redeemInviteTokenRequest(token, inviteToken, starters);
    }
    if (inviteType === InviteLinkType.PUBLIC) {
      if (isTeamsPublicLink) {
        await redeemPublicInviteToTeamTokenRequest(inviteToken);
      } else {
        await redeemPublicInviteTokenRequest(token, inviteToken, starters);
      }
    }
    showNotification({ message: 'You joined the team at VYCE', options: { variant: 'success' } });
  } catch (e) {
    console.error(e);
  }
};

interface Form extends Employee {
  shareCode: string;
  isLimitedCompany: string;
  isVatRegistered: string;
  cscs_id: string;
  pay_starter_manager?: PayTag;
  pay_starter_location?: PayTag;
}

export const EmployeeOnboarding: React.FC = () => {
  const {
    user,
    helper: { access_token },
  } = useTypedSelector(state => state);
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const { inviteToken, type } = useParams<{ inviteToken: string; type: InviteLinkType }>();
  const { me, uploadUserAvatar, setShowTour } = useActions();
  const [passportImage, setPassportImage] = useState<Image | undefined>({ file: null, url: '' });
  const [selfieImage, setSelfieImage] = useState<Image | undefined>({ file: null, url: '' });
  const [loading, setLoading] = useState<boolean>(false);
  const childRef = useRef<any>();
  const { isMobile } = useContext(DeviceContext);
  const [open, setOpen] = useState<boolean>(false);
  const [companyName, setCompanyName] = useState<string>('');
  const [companyId, setCompanyId] = useState<string>('');
  const [invitePayScheduleIds, setInvitePayScheduleIds] = useState<string[]>([]);
  const [howItWorksOpen, setHowItWorksOpen] = useState<boolean>(false);
  const history = useHistory();
  const query = useQuery();
  const methods = useForm<Form>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      first_name: user?.first_name,
      last_name: user?.last_name,
      ni_number: user?.context?.ni_number,
      date_of_birth: user?.date_of_birth,
      nationality: user?.nationality,
      address: user?.address,
      gender: user?.gender,
      job_title: '',
      effective_date: user?.employee?.effective_date,
      trading_name: user?.employee?.trading_name,
      utr: user?.employee?.utr,
      company_utr: user?.employee?.company_utr,
      company_name: user?.employee?.company_name,
      company_reg_number: user?.employee?.company_reg_number,
      vat_reg_number: user?.employee?.vat_reg_number,
      phone: user?.phone,
      payment_method: 'BACS',
      bank_account: {
        account_name: `${user.first_name} ${user.last_name}`,
        account_number: user?.employee?.bank_account?.account_number,
        sort_code: user?.employee?.bank_account?.sort_code,
        building_society_roll_number: user?.employee?.bank_account?.building_society_roll_number,
      },
      emergency_contact_name: user?.employee?.emergency_contact_name,
      emergency_contact_number: user?.employee?.emergency_contact_number,

      isLimitedCompany: user?.employee?.company_reg_number ? 'yes' : 'no',
      isVatRegistered: user?.employee?.vat_reg_number ? 'yes' : 'no',
      shareCode: user?.context?.share_code,
      cscs_id: user?.context?.cscs_id,
      pay_starter_manager: undefined,
      pay_starter_location: undefined,
    },
  });
  const howItWorksSteps: HowItWorksStep[] = [
    {
      label: `Vyce helps teams work better together. You’ve been added to the ${companyName}'s team. Let’s get a few details from you to get you set up.`,
      subtitle: `Welcome ${user.first_name}!`,
      imgPath: config.how_it_works.welcome,
    },
  ];
  const [survey, setSurvey] = useState<UserSurvey>();
  const { watch, setValue, reset } = methods;
  const address = watch('address');
  const isVatRegistered = watch('isVatRegistered');

  const initialSteps: OnboardingStep[] = useMemo(
    () => [
      {
        modules: [INVITE_MODULES.GENERAL, INVITE_MODULES.TEAMS, INVITE_MODULES.PAY],
        component: <WelcomeStep userName={user?.first_name} />,
        name: 'WelcomeStep',
      },
      {
        modules: [INVITE_MODULES.GENERAL, INVITE_MODULES.TEAMS, INVITE_MODULES.PAY],
        component: <OnboardingStepTitle text="First of all, let's get some work details from you..." />,
        name: 'WorkDetailsMessage',
      },
      {
        modules: [INVITE_MODULES.GENERAL, INVITE_MODULES.TEAMS, INVITE_MODULES.PAY],
        component: <EmployeeJobTitleStep />,
        name: 'EmployeeJobTitleStep',
      },
      {
        modules: [INVITE_MODULES.GENERAL, INVITE_MODULES.TEAMS, INVITE_MODULES.PAY],
        component: <StartDateStep />,
        name: 'StartDateStep',
      },
      {
        modules: [INVITE_MODULES.PAY],
        component: <StarterLocationStep payScheduleIds={invitePayScheduleIds} companyId={companyId} />,
        name: 'StarterLocationStep',
      },
      {
        modules: [INVITE_MODULES.PAY],
        component: <StarterManagerStep payScheduleIds={invitePayScheduleIds} companyId={companyId} />,
        name: 'StarterManagerStep',
      },
      {
        modules: [INVITE_MODULES.GENERAL, INVITE_MODULES.TEAMS, INVITE_MODULES.PAY],
        component: <LimitedCompanyStep />,
        name: 'LimitedCompanyStep',
      },
      {
        modules: [INVITE_MODULES.GENERAL, INVITE_MODULES.TEAMS, INVITE_MODULES.PAY],
        component: <VatRegisteredStep />,
        name: 'VatRegisteredStep',
      },
      {
        modules: [INVITE_MODULES.GENERAL, INVITE_MODULES.TEAMS, INVITE_MODULES.PAY],
        component: <UTRNumberStep />,
        name: 'UTRNumberStep',
      },
      {
        modules: [INVITE_MODULES.GENERAL, INVITE_MODULES.TEAMS, INVITE_MODULES.PAY],
        component: (
          <OnboardingStepTitle text="Thanks for that. Now please confirm the following information about you..." />
        ),
        name: 'ThanksStep',
      },
      {
        modules: [INVITE_MODULES.GENERAL, INVITE_MODULES.TEAMS, INVITE_MODULES.PAY],
        component: <HomeAddressStep />,
        name: 'HomeAddressStep',
      },
      {
        modules: [INVITE_MODULES.GENERAL, INVITE_MODULES.TEAMS, INVITE_MODULES.PAY],
        component: <PhoneNumberStep />,
        name: 'PhoneNumberStep',
      },
      {
        modules: [INVITE_MODULES.GENERAL, INVITE_MODULES.TEAMS, INVITE_MODULES.PAY],
        component: <NationalityStep />,
        name: 'NationalityStep',
      },
      {
        modules: [INVITE_MODULES.GENERAL, INVITE_MODULES.TEAMS, INVITE_MODULES.PAY],
        component: <DateOfBirthStep />,
        name: 'DateOfBirthStep',
      },
      {
        modules: [INVITE_MODULES.GENERAL, INVITE_MODULES.TEAMS, INVITE_MODULES.PAY],
        component: <GenderStep />,
        name: 'GenderStep',
      },
      {
        modules: [INVITE_MODULES.GENERAL, INVITE_MODULES.TEAMS, INVITE_MODULES.PAY],
        component: <NINumberStep disabled={!!user?.context?.ni_number} />,
        name: 'NINumberStep',
      },
      {
        modules: [INVITE_MODULES.GENERAL, INVITE_MODULES.TEAMS, INVITE_MODULES.PAY],
        component: (
          <PassportVerificationStep
            verified={!!user?.id_verification?.verified}
            passportImage={passportImage}
            selfieImage={selfieImage}
            setPassportImage={setPassportImage}
            setSelfieImage={setSelfieImage}
          />
        ),
        name: 'PassportVerificationStep',
      },
      {
        modules: [INVITE_MODULES.GENERAL, INVITE_MODULES.TEAMS, INVITE_MODULES.PAY],
        component: <EmergencyContactsStep />,
        name: 'EmergencyContactsStep',
      },
      {
        modules: [INVITE_MODULES.GENERAL, INVITE_MODULES.TEAMS, INVITE_MODULES.PAY],
        component: <HealthAndSafetyQuestions setSurvey={setSurvey} survey={survey} />,
        name: 'HealthAndSafetyQuestions',
      },
      {
        modules: [INVITE_MODULES.GENERAL, INVITE_MODULES.TEAMS, INVITE_MODULES.PAY],
        component: <CSCSRegistrationNumberStep />,
        name: 'CSCSRegistrationNumberStep',
      },
      {
        modules: [INVITE_MODULES.GENERAL, INVITE_MODULES.PAY],
        component: <BankDetailsStep title="Please confirm your bank details" />,
        name: 'BankDetailsStep',
      },
      {
        modules: [INVITE_MODULES.GENERAL, INVITE_MODULES.TEAMS, INVITE_MODULES.PAY],
        component: <CISConfirmationStep />,
        name: 'CISConfirmationStep',
      },
    ],
    [
      survey,
      passportImage,
      selfieImage,
      user?.id_verification?.verified,
      user?.first_name,
      invitePayScheduleIds,
      companyId,
    ]
  );
  const [activeStep, setActiveStep] = useState<ActiveOnboardingStep>({ step: initialSteps[0], number: 0 });
  const [steps, setSteps] = useState<OnboardingStep[]>(initialSteps);

  const submitForm = async (data: Employee, starters: Starters) => {
    try {
      const employeeId = user.employee?.uuid;
      if (employeeId) {
        await updateEmployeeRequest(access_token, data, employeeId);
      } else {
        await createEmployeeRequest(access_token, data);
        checkCSCS({ cscs_id: data.cscs_id, last_name: data.last_name });
      }
      if (inviteToken) {
        await redeemInviteToken({
          token: access_token,
          inviteToken,
          showNotification,
          inviteType: type,
          starters,
          isTeamsPublicLink: !!query.get(INVITE_MODULES.TEAMS),
        });
      }
      await me();
      reset();
      if (!employeeId) {
        setShowTour(true);
      }
      history.push('/dashboard');
    } catch (e) {
      handleServerError(e);
    }
  };

  const triggerChecker = async (taskName: TaskNames, parameters?: any) => {
    try {
      await triggerCheckerRequest({ task_name: taskName, parameters });
    } catch (e) {
      console.error(e);
    }
  };

  const nextStep = (jumpTo?: number) => {
    childRef?.current?.nextStep(jumpTo);
  };

  const processDocuments = async (passport: File, selfie: File) => {
    try {
      await uploadPassportRequest(passport);
      await uploadSelfieRequest(selfie);
      await triggerChecker(TaskNames.CHECK_PASSPORT);
      uploadUserAvatar({ file: selfie, saveToProfile: true, handleServerError });
    } catch (e) {
      console.error(e);
    }
  };

  const checkCSCS = ({ cscs_id, last_name }: CSCSParams) => {
    const params = {
      serial_number: cscs_id,
      scheme_id: 'CSCS',
      surname: last_name,
    };
    triggerChecker(TaskNames.CHECK_QUALIFICATIONS, params);
  };

  const createPayTag = async (tag: PayTag) => {
    try {
      for (const id of invitePayScheduleIds) {
        await createPayScheduleTagRequest({
          payScheduleId: id,
          companyId,
          byStaff: false,
          group: tag.group,
          verified: false,
          name: tag.name,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleNext = async (data: Form) => {
    switch (activeStep.step.name) {
      case 'CISConfirmationStep':
        const { pay_starter_location, pay_starter_manager } = data;
        setLoading(true);

        if (pay_starter_location?.uuid === NEW_TAG_ID) {
          await createPayTag({
            name: pay_starter_location.name,
            group: PayTagGroup.LOCATION,
            verified: false,
          });
        }

        if (pay_starter_manager?.uuid === NEW_TAG_ID) {
          await createPayTag({
            name: pay_starter_manager.name,
            group: PayTagGroup.MANAGER,
            verified: false,
          });
        }

        saveMedicalAnswers();
        const employeeData = await prepareEmployeeData(data);
        await submitForm(employeeData, {
          location: pay_starter_location?.name,
          manager: pay_starter_manager?.name,
        });
        await me();
        clearOnboardingLSData();
        setLoading(false);
        break;
      case 'PhoneNumberStep':
        if (user.phone && user.phone === data.phone) {
          return nextStep();
        }
        if (data.phone) {
          const isPhoneAvailable = await checkPhoneNumber(data.phone, showNotification);
          if (isPhoneAvailable) {
            nextStep();
          }
        }
        break;
      case 'DateOfBirthStep':
        if (data.shareCode) {
          setLoading(true);
          const parameters = {
            date_of_birth: data.date_of_birth,
            rtw_share_code: data.shareCode,
          };
          await triggerChecker(TaskNames.CHECK_RTW, parameters);
          setLoading(false);
        }
        nextStep();
        break;
      case 'NINumberStep':
        if (user.context?.ni_number && user.context.ni_number === data.ni_number) {
          return nextStep();
        }
        const isNINumberAvailable = await checkNINumber(data.ni_number, showNotification);
        if (isNINumberAvailable) {
          setLoading(false);
          nextStep();
        }
        break;
      case 'PassportVerificationStep':
        if (passportImage?.file && selfieImage?.file) {
          processDocuments(passportImage.file, selfieImage.file);
        }
        nextStep();
        break;
      case 'HealthAndSafetyQuestions':
        const answers = getAnswers(survey);
        const allQuestionsAnswered = answers.every(answer => answer.yes_no !== null);
        if (!allQuestionsAnswered) {
          return showNotification({
            message: 'Please answer all medical questions',
            options: { variant: 'warning' },
          });
        }
        nextStep();
        break;
      default:
        nextStep();
        break;
    }
  };

  const saveMedicalAnswers = () => {
    if (!survey) {
      return;
    }
    try {
      setLoading(true);
      const answers = getAnswers(survey);
      saveSurveyAnswers(survey.uuid, answers);
    } catch (e) {
      console.error(e);
    }
  };

  const getAnswers = (survey?: UserSurvey): Answer[] => {
    return survey?.content
      ? survey.content.map(item => ({
          yes_no: item.answer?.yes_no === undefined ? null : item.answer?.yes_no,
          comment: item.answer?.yes_no ? item.answer.comment : null,
          question_id: item.question.uuid,
        }))
      : [];
  };

  const prepareEmployeeData = async (data: Employee): Promise<Employee> => {
    const address = await getLocationDetails({
      fullAddress: data.address?.address_snippet as string,
      town: data.address?.address_line_3,
      street: data.address?.address_line_2,
      houseNumber: data.address?.address_line_1,
    });
    return {
      ...data,
      address,
      bank_account: data.bank_account?.account_number ? data.bank_account : null,
      date_of_birth: data.date_of_birth,
      effective_date: data.effective_date,
      job_title: (data.job_title as Tag)?.name,
      company_reg_number: data.company_reg_number || undefined,
      company_name: data.company_name || undefined,
      company_utr: data.company_utr || undefined,
      gender: data.gender === 'rather_not_say' ? null : data.gender,
      business_type: data.company_reg_number ? BUSINESS_TYPES.COMPANY : BUSINESS_TYPES.SOLE_TRADER,
    };
  };

  const getQuestions = async (token: string) => {
    try {
      const surveysRes = await getSurveysRequest(SEARCH_HEALTH_SURVEY_SUBSTRING);
      const surveyRes = surveysRes.data?.items[0];
      if (!surveyRes) {
        return showNotification({
          message: 'Health and Safety Questionnaire not found',
          options: { variant: 'error' },
        });
      }
      const res = await getSurveyAnswers(surveyRes.uuid);
      setSurvey(res.data);
    } catch (e) {
      handleServerError(e);
    }
  };

  const getInvite = async () => {
    try {
      const res = await getUserInviteRequest(inviteToken);
      const invite = res?.data;
      const scheduleIds = invite?.payload?.pay?.map((item: any) => item.pay_schedule_id);
      if (invite?.company_id) {
        setCompanyId(invite.company_id);
      }
      if (scheduleIds?.length) {
        setInvitePayScheduleIds(scheduleIds);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const checkAddress = async (address: string) => {
    setLoading(true);
    const addressData = await getLocationDetails({ fullAddress: address });
    setValue('address.address_line_2', addressData.address_line_2 || '');
    setValue('address.address_line_1', addressData.address_line_1 || user?.address?.address_line_1 || '');
    setValue('address.address_line_3', addressData.address_line_3 || '');
    setLoading(false);
    if (!addressData.address_line_4) {
      showNotification({
        message: 'Please input full address',
        options: { variant: 'error' },
      });
      return;
    }
  };

  useEffect(() => {
    const isTeamsModule = !!query.get(INVITE_MODULES.TEAMS);
    const isPayModule = !!query.get(INVITE_MODULES.PAY);
    const isTimeModule = !!query.get(INVITE_MODULES.TIME);
    const isGeneralOnboarding = !!query.get(INVITE_MODULES.GENERAL);

    const company = query.get('company');
    setCompanyName(company || 'company');

    const steps = initialSteps.filter(
      item =>
        (isTeamsModule && item.modules?.includes(INVITE_MODULES.TEAMS)) ||
        (isTimeModule && item.modules?.includes(INVITE_MODULES.GENERAL)) ||
        (isGeneralOnboarding && item.modules?.includes(INVITE_MODULES.GENERAL)) ||
        (isPayModule && item.modules?.includes(INVITE_MODULES.PAY))
    );

    setSteps(steps);
  }, [initialSteps, query]);

  useEffect(() => {
    const companyId = query.get('companyId');
    const payScheduleId = query.get('payScheduleId');
    if (companyId) {
      setCompanyId(companyId);
    }
    if (payScheduleId) {
      setInvitePayScheduleIds([payScheduleId]);
    }
  }, [query]);

  useEffect(() => {
    const status = setOnboardingData(methods.setValue, setActiveStep, nextStep, steps);
    if (!status && companyName) {
      setHowItWorksOpen(true);
    }
    return () => {
      saveOnboardingDataToLS(methods.getValues(), activeStep.number);
    };
  }, []);

  window.onbeforeunload = () => {
    saveOnboardingDataToLS(methods.getValues(), activeStep.number);
  };

  useEffect(() => {
    if (access_token) {
      getQuestions(access_token);
      getInvite();
    }
  }, [access_token]);

  useEffect(() => {
    if (address?.address_snippet) {
      checkAddress(address.address_snippet);
    }
  }, [address?.address_snippet]);

  useEffect(() => {
    if (isVatRegistered === 'yes' && activeStep.step.name === 'VatRegisteredStep') {
      childRef?.current?.scrollDown();
    }
  }, [isVatRegistered]);

  return (
    <Box paddingTop={4}>
      <Box position="absolute" top={16} left={16}>
        <Typography variant="h5">Onboarding</Typography>
      </Box>

      <AppOnboarding
        currentMessage=""
        lastStepButtonLabel="Agree & finish"
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        methods={methods}
        handleNext={handleNext}
        steps={steps}
        loading={loading}
        ref={childRef}
      />

      <SuccessDialog open={open} setOpen={setOpen} />

      <Dialog
        fullScreen={isMobile}
        open={howItWorksOpen}
        container={document.getElementById(MAIN_CONTAINER_ID)}
        onClose={() => setHowItWorksOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <HowItWorks steps={howItWorksSteps} handleClose={() => setHowItWorksOpen(false)} />
      </Dialog>
    </Box>
  );
};
