import { EnrichedTeamsSyncSettingsDTO } from '../types';

export const extraText = {
  time: 'Remove the worker from Teams after they haven’t clocked in for',
  pay: 'Remove the worker from Teams after not paying them via Payroll for',
};

export const extraTitle = {
  time: 'Time & Attendance',
  pay: 'Payroll',
};

export const checkboxValues = {
  time: 'isTimeSync',
  pay: 'isPaySync',
};

export const timeAttendanceOptions = [
  { name: '1 week', value: 1 },
  { name: '2 weeks', value: 2 },
  { name: '3 weeks', value: 3 },
];

export const payrollOptions = [
  { name: '1 pay period', value: 1 },
  { name: '2 pay periods', value: 2 },
  { name: '3 pay periods', value: 3 },
];

export const defaultValues: EnrichedTeamsSyncSettingsDTO = {
  active: false,
  isPaySync: true,
  isTimeSync: false,
  recent_pay_periods: 3,
  recent_time_periods: 3,
};
