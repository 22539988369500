import React, { useContext, useEffect, useState } from 'react';

import { Box, CircularProgress, Tooltip, useTheme } from '@material-ui/core';
import isEqual from 'lodash/isEqual';
import { MdOutlinePictureAsPdf } from 'react-icons/md';
import { AxiosResponse } from 'axios';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';

import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { AppDataGrid } from '@vyce/core/src/components';
import { Agreement } from '@vyce/core/src/types';
import { formatSortModel } from '@vyce/core/src/utils/sorting';
import { formatTableDate } from '@vyce/core/src/utils/dates';
import { IsVerified } from '@vyce/core/src/components';
import { AppIconButton } from '@vyce/core/src/components';
import { generateFileLinkAndSave } from '@vyce/core/src/utils';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';
import { GetAgreementRequest, GetAgreementsRequest } from '@vyce/core/src/api/types';

interface Props {
  token: string;
  userId?: string;
  companyId?: string;
  unit?: string;
  withProfileButton?: boolean;
  getAgreementsRequest: (data: GetAgreementsRequest) => Promise<AxiosResponse>;
  downloadAgreementRequest: (data: GetAgreementRequest) => Promise<AxiosResponse>;
}

export const Agreements: React.FC<Props> = ({
  token,
  getAgreementsRequest,
  downloadAgreementRequest,
  userId,
  companyId,
  unit,
  withProfileButton,
}) => {
  const theme = useTheme();
  const { handleServerError } = useContext(NotificationContext);
  const [agreements, setAgreements] = useState<Agreement[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingRowId, setLoadingRowId] = useState<string>('');
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'created_at', sort: 'desc' }]);
  const columns: GridColDef[] = [
    {
      field: 'company',
      headerName: 'Company',
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: params => params.row.company.name,
      minWidth: 120,
    },
    {
      field: 'pay_schedule',
      headerName: 'Pay Schedule',
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: params => params.row.pay_schedule.name,
      minWidth: 120,
    },
    {
      field: 'pay_scheme',
      headerName: 'Type',
      valueGetter: params => params.row?.pay_scheme?.toUpperCase(),
      flex: 1,
      disableColumnMenu: true,
      minWidth: 120,
    },
    {
      field: 'created_at',
      headerName: 'Start Date',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: params => formatTableDate(params.row?.created_at),
      minWidth: 120,
    },
    {
      field: 'updated_at',
      headerName: 'End Date',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: params => (params.row.active ? '' : formatTableDate(params.row?.created_at)),
      minWidth: 120,
    },
    {
      field: 'active',
      headerName: 'Status',
      width: 150,
      type: 'boolean',
      disableColumnMenu: true,
      renderCell: params => <IsVerified verified={params.row.active} />,
    },
    {
      field: '',
      headerName: ' ',
      width: 80,
      hideSortIcons: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => (
        <Tooltip title="Download agreement">
          <Box display="flex" width="100%">
            <AppIconButton
              variant="paper"
              disabled={loadingRowId === params.row.pay_schedule.pay_schedule_id}
              onClick={() => downloadAgreement(params.row.pay_schedule.pay_schedule_id)}>
              {loadingRowId === params.row.pay_schedule.pay_schedule_id ? (
                <CircularProgress color="secondary" size={24} />
              ) : (
                <MdOutlinePictureAsPdf color={theme.palette.secondary.main} />
              )}
            </AppIconButton>
          </Box>
        </Tooltip>
      ),
    },
  ];

  const getAgreements = async (sortModel: GridSortModel) => {
    try {
      setLoading(true);
      const res = await getAgreementsRequest({
        token,
        order_by: formatSortModel<Agreement>(sortModel),
        userId,
        companyId,
      });
      setAgreements(res.data.items);
      setTotal(res.data.count);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const downloadAgreement = async (payScheduleId: string) => {
    try {
      setLoadingRowId(payScheduleId);
      const res = await downloadAgreementRequest({ token, payScheduleId, userId, companyId });
      generateFileLinkAndSave(res);
      setLoadingRowId('');
    } catch (e) {
      setLoadingRowId('');
      handleServerError(e);
    }
  };

  const handleSortModelChange = (newModel: GridSortModel) => {
    if (isEqual(newModel, sortModel)) {
      return;
    }
    setSortModel(newModel);
    getAgreements(newModel);
  };

  useEffect(() => {
    getAgreements(sortModel);
  }, []);

  return (
    <AppDataGrid
      noPaper
      rows={agreements}
      height={'calc(100vh - 200px)'}
      getRowId={row => row.pay_schedule.pay_schedule_id}
      loading={loading}
      columns={columns}
      rowCount={total}
      sortingMode="server"
      sortModel={sortModel}
      onSortModelChange={handleSortModelChange}
      pageSize={GRID_PAGE_SIZE}
      rowsPerPageOptions={[GRID_PAGE_SIZE]}
      disableSelectionOnClick
      unit={unit}
      withProfileButton={withProfileButton}
    />
  );
};
