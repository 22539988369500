import React from 'react';

import { Box, Button, useTheme } from '@material-ui/core';
import { AiOutlinePlus } from 'react-icons/ai';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { EditBreak } from '../../components/EditBreak';
import { BREAKS_FIELD_NAME, EMPTY_BREAK, SHIFTS_FIELD_NAME } from '../../../../modules/timeModule/constants';
import { Break } from '../../../../types';

interface Props {
  shiftIndex?: number;
}

export const EditBreaks: React.FC<Props> = ({ shiftIndex }) => {
  const theme = useTheme();
  const { control, watch } = useFormContext();
  const fieldName =
    shiftIndex !== undefined
      ? `${SHIFTS_FIELD_NAME}[${shiftIndex}].${BREAKS_FIELD_NAME}`
      : `${BREAKS_FIELD_NAME}`;
  const { append, remove } = useFieldArray({
    control,
    name: fieldName,
  });
  const breaks = watch(fieldName) || [];

  return (
    <Box display="flex" flexDirection="column" gridGap={16}>
      {breaks?.map((item: Break, index: number) => (
        <EditBreak key={index + 'break'} index={index} remove={remove} parentField={fieldName} />
      ))}

      <Box display="flex" justifyContent="flex-end">
        <Button
          style={{ color: theme.palette.primary.main }}
          size="small"
          onClick={() => append(EMPTY_BREAK)}
          variant="outlined"
          startIcon={<AiOutlinePlus />}>
          Add {breaks?.length ? 'another' : 'a'} break
        </Button>
      </Box>
    </Box>
  );
};
