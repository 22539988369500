import React, { useEffect, useState } from 'react';

import { Box, Grid, Paper, Typography, useTheme } from '@material-ui/core';
import { LatLngExpression } from 'leaflet';
import dayjs from 'dayjs';

import {
  MapMarker,
  TimeAndAttendanceLocation,
  TimePeriod,
  TimeWorker,
  TrackEvent,
} from '@vyce/core/src/types';
import { PageLoading } from '@vyce/core/src/components';
import { GetEventsRequestPayload } from '@vyce/core/src/api/types';
import { getEventsRequest, getSiteRequest } from '@vyce/core/src/api/time';
import { getActivityMarkers, siteToLocation } from '@vyce/core/src/modules/timeModule/utils';

import { useStyles } from '../../styles';
import { processEventFaces } from '../utils';
import { EventFacesProps } from '../types';
import { WorkerLog } from './WorkerLog';
import { WorkerMap } from './WorkerMap';
import { getFaceTitle } from '../utils/getFaceTitle';
import { getLocationTitle } from '../utils/getLocationTitle';

interface Props {
  period: TimePeriod;
  worker: TimeWorker;
  companyId?: string;
  center?: LatLngExpression;
  canViewLocation?: boolean;
  shiftId: string;
  siteName: string;
  userId: string;
}

export const ClockInOutPeriod: React.FC<Props> = ({
  period,
  worker,
  companyId,
  canViewLocation = true,
  shiftId,
  siteName,
  userId,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [markers, setMarkers] = useState<MapMarker[]>([]);
  const [lineCoords, setLineCoords] = useState<LatLngExpression[]>([]);
  const [location, setLocation] = useState<TimeAndAttendanceLocation>();
  const [locationName, setLocationName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [eventFaces, setEventFaces] = useState<ReturnType<typeof processEventFaces>>({} as EventFacesProps);

  const getData = async () => {
    if (!companyId) {
      return setLoading(false);
    }

    try {
      const payload: GetEventsRequestPayload = {
        offset: 0,
        limit: 1000,
        order_by: [
          {
            field: 'updated_at',
            desc: true,
          },
        ],
        user_id: worker.user_id,
        shift_id: shiftId,
        time_period: {
          gte: period.checked_in,
          lte: dayjs(period.checked_out).add(1, 'minute').format().slice(0, -6),
        },
      };

      const res = await getEventsRequest({ companyId, payload });
      const events: TrackEvent[] = res.data.items || [{}];

      if (canViewLocation) {
        const siteRes = await getSiteRequest(companyId, period.site_id);
        const loc = siteToLocation(siteRes.data);
        const { markers, lineCoords } = getActivityMarkers(
          events,
          theme.palette.type === 'dark',
          loc?.tracking_type
        );
        setLocation(loc);
        setMarkers(markers);
        setLineCoords(lineCoords);
      }
      setEventFaces(processEventFaces(events));
      setLocationName(siteName !== 'all' ? siteName : '');
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      getData();
    }
  }, [userId]);

  return (
    <Box display="flex" justifyContent="center">
      <Grid style={{ maxWidth: canViewLocation ? '100%' : 900 }} container spacing={3}>
        <Grid item xs={12} md={canViewLocation ? 6 : 12}>
          <WorkerLog
            locationName={locationName}
            first_name={worker.first_name}
            last_name={worker.last_name}
            gender={worker?.gender ?? null}
            clockedHours={period.total_hours}
            overtimeHours={period.overtime_hours}
            check_in_timestamp={period.checked_in}
            check_out_timestamp={period.checked_out}
            check_in_within_area={period.check_in_ok}
            check_out_within_area={period.check_out_ok}
            auto_clock_out={period.auto_check_out}
            clockInFaceVerified={period.face_in_ok}
            clockOutFaceVerified={period.face_out_ok}
            clockInIcon={eventFaces.clockInIcon}
            clockOutIcon={eventFaces.clockOutIcon}
            faceVerificationTitle={getFaceTitle(period.face_in_ok, period.face_out_ok)}
            locationVerificationTitle={getLocationTitle(period.check_in_ok, period.check_out_ok)}
          />
        </Grid>

        {canViewLocation && (
          <Grid item xs={12} md={6}>
            <Paper className={classes.paper} variant="outlined">
              <Box display="flex" height={32} alignItems="center" marginBottom="12px">
                <Typography variant="h6">Worker’s location during this shift</Typography>
              </Box>

              <Box display="flex" alignItems="center" justifyContent="center" height={353}>
                {loading ? (
                  <PageLoading />
                ) : (
                  <WorkerMap markers={markers} lineCoords={lineCoords} location={location} />
                )}
              </Box>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
