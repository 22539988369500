import React from 'react';

import {
  createOrUpdateCompanyBankAccountRequest,
  getCompanyBankAccountRequest,
  getPayScheduleRequest,
  updatePaySchedulesRequest,
} from '@vyce/core/src/api/pay';
import { PaySchedulePage } from '@vyce/core/src/views/payroll/PaySchedulePage';

import { useTypedSelector } from '../../hooks';

export const EmployerPaySchedulePage: React.FC = () => {
  const { selectedCompany } = useTypedSelector(state => state.helper);

  return (
    <PaySchedulePage
      createOrUpdateCompanyBankAccountRequest={createOrUpdateCompanyBankAccountRequest}
      getCompanyBankAccountRequest={getCompanyBankAccountRequest}
      getPayScheduleRequest={getPayScheduleRequest}
      updatePaySchedulesRequest={updatePaySchedulesRequest}
      selectedCompanyName={selectedCompany?.name}
    />
  );
};
