/*
    Deside show information or hide it
*/
import React from 'react';

import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from '../../styles';
import { CloseInfo } from './CloseInfo';

interface ArbitratorProps {
  children: React.ReactNode;
  isShowInfo: boolean;
  title: string;
  isHiring?: boolean;
}
export const Arbitrator = ({ children, title, isShowInfo, isHiring = false }: ArbitratorProps) => {
  const classes = useStyles();
  if (isShowInfo) return <>{children}</>;

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Typography color={isHiring ? 'initial' : 'textSecondary'} variant="h6">
        {title}
      </Typography>
      <Box
        className={clsx(classes.tileItem, classes.closeInfoItem)}
        marginTop={2}
        padding={2}
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexGrow={1}>
        <CloseInfo />
      </Box>
    </Box>
  );
};
