import React, { useContext, useState } from 'react';

import { Box, Grid, Paper, Typography, useTheme } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { AxiosResponse } from 'axios';
import { CgWebsite } from 'react-icons/cg';
import { ImFacebook2, ImLinkedin } from 'react-icons/im';
import { AiOutlineInstagram, AiOutlineTwitter } from 'react-icons/ai';

import { URLField } from '../../../components/controlled-inputs';
import { AppFormWrapper } from '../../../components';
import { Company, Image } from '../../../types';
import useStyles from '../styles';
import { DeviceContext } from '../../../contexts';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  company?: Company;
  setCompany: Function;
  token: string;
  updateCompanyRequest: (token: string, companyId: string, data: Company) => Promise<AxiosResponse>;
}

export const CompanySocials: React.FC<Props> = ({ company, setCompany, updateCompanyRequest, token }) => {
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const classes = useStyles();
  const theme = useTheme();
  const { isMobile } = useContext(DeviceContext);
  const [loading, setLoading] = useState<boolean>();
  const methods = useForm<Company & { logo: Image }>({
    defaultValues: {
      website: company?.website || '',
      facebook: company?.facebook || '',
      instagram: company?.instagram || '',
      linkedin: company?.linkedin || '',
      twitter: company?.twitter || '',
    },
  });

  const handleSubmit = async ({ logo, ...data }: Company) => {
    if (!company?.uuid) {
      return;
    }
    const updatedFields: Company = {
      website: data.website || null,
      facebook: data.facebook || null,
      instagram: data.instagram || null,
      linkedin: data.linkedin || null,
      twitter: data.twitter || null,
    };
    try {
      setLoading(true);
      const res = await updateCompanyRequest(token, company.uuid, updatedFields);
      setCompany(res.data);
      setLoading(false);
      showNotification({ message: 'Socials have been updated', options: { variant: 'success' } });
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  };

  return (
    <AppFormWrapper methods={methods} initialData={company} handleSubmit={handleSubmit} loading={loading}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={8}>
          <Paper variant="outlined" className={classes.formPaper}>
            <Box marginBottom={2}>
              <Typography variant="h6">Social Media Links</Typography>
            </Box>

            <URLField name="website" label="Website">
              <CgWebsite size="20px" color={theme.palette.text.secondary} />
            </URLField>

            <Box display="flex" gridGap={24} marginTop={2} flexDirection={isMobile ? 'column' : 'row'}>
              <URLField name="facebook" label="Facebook">
                <ImFacebook2 size="18px" color={theme.palette.text.secondary} />
              </URLField>

              <URLField name="instagram" label="Instagram">
                <AiOutlineInstagram size="22px" color={theme.palette.text.secondary} />
              </URLField>
            </Box>

            <Box display="flex" gridGap={24} marginTop={2} flexDirection={isMobile ? 'column' : 'row'}>
              <URLField name="linkedin" label="LinkedIn">
                <ImLinkedin size="18px" color={theme.palette.text.secondary} />
              </URLField>

              <URLField name="twitter" label="Twitter">
                <AiOutlineTwitter size="22px" color={theme.palette.text.secondary} />
              </URLField>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </AppFormWrapper>
  );
};
