import React from 'react';
import { Avatar, Box, createStyles, makeStyles, Tooltip, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { AppLink } from './AppLink';
import { RandomLogo } from './RandomLogo';
import { monochrome } from '../theme/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    tableAvatar: {
      width: 35,
      height: 35,
    },
    bold: {
      fontWeight: 500,
    },

    primaryBorder: {
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: '50%',
    },
    warningBorder: {
      border: `2px solid ${theme.palette.warning.main}`,
      borderRadius: '50%',
    },
    neutralBorder: {
      border: `2px solid ${monochrome.mediumlight}`,
      borderRadius: '50%',
    },
  })
);

interface Borders {
  primaryBorder?: boolean;
  warningBorder?: boolean;
  neutralBorder?: boolean;
}

interface Props extends Borders {
  avatarUrl?: string | null;
  avatarPlaceholder?: string;
  link?: string;
  name: string;
  tooltip?: string;
  icon?: React.ReactElement;
}

const RoundAvatarBorder = ({
  warningBorder,
  primaryBorder,
  neutralBorder,
  children,
}: { children: React.ReactNode } & Borders) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx({
        [classes.primaryBorder]: primaryBorder,
        [classes.warningBorder]: warningBorder,
        [classes.neutralBorder]: neutralBorder,
      })}>
      {children}
    </Box>
  );
};

export const GridCellWithAvatar: React.FC<Props> = ({
  avatarUrl,
  avatarPlaceholder,
  link,
  name,
  tooltip,
  icon,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Box display="flex" width="max-content" alignItems="center" cy-test-id="grid-cell-with-avatar">
      <Box marginRight={1}>
        <RoundAvatarBorder {...rest}>
          {!avatarUrl && !avatarPlaceholder ? (
            <RandomLogo name={name} />
          ) : (
            <Avatar
              imgProps={{ loading: 'lazy' }}
              className={classes.tableAvatar}
              src={avatarUrl || avatarPlaceholder}></Avatar>
          )}
        </RoundAvatarBorder>
      </Box>

      <Box display="flex" gridGap={8}>
        {link ? (
          <AppLink to={link}>{name}</AppLink>
        ) : (
          <Typography className={classes.bold} variant="caption">
            {name}
          </Typography>
        )}
        {tooltip && icon && (
          <Tooltip title={tooltip} placement="top">
            <Box display="flex" alignItems="center">
              {icon}
            </Box>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};
