import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useTypedSelector } from '../hooks';

export const AuthorizedRoute = ({ component, isEmployee, isEmployer, ...rest }: any) => {
  const { user } = useTypedSelector(state => state);
  const allowed = user.uuid;
  const pathname = isEmployee
    ? '/auth/employee/signup'
    : isEmployer
    ? '/auth/employer/signup'
    : '/auth/login';

  const routeComponent = (props: any) => {
    return allowed ? (
      React.createElement(component, props)
    ) : (
      <Redirect to={{ pathname, state: { from: props.location.pathname + props.location.search } }} />
    );
  };
  return <Route {...rest} render={routeComponent} />;
};
