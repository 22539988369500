import React from 'react';

import { Marker, MarkerProps, Tooltip } from 'react-leaflet';
import L from 'leaflet';
import { makeStyles, Typography } from '@material-ui/core';

interface Props extends MarkerProps {
  picture?: string;
  workerName?: string;
}

export const TimeModuleMarker: React.FC<Props> = ({ picture, position, workerName }) => {
  const styles = tooltipUseStyles();
  const icon = new L.Icon({
    iconUrl: picture,
    iconRetinaUrl: picture,
    popupAnchor: [-0, -0],
    iconSize: [40, 40],
  });

  return (
    <div>
      <Marker position={position} icon={icon}>
        <Tooltip direction="bottom" className={styles.tooltip}>
          <Typography variant="subtitle2" color="primary">
            {workerName}
          </Typography>
        </Tooltip>
      </Marker>
    </div>
  );
};

export const tooltipUseStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: 'white',
    boxShadow: 'none',
    border: 'none',
    opacity: 1,
  },
}));
