import React from 'react';

import { Box, Typography } from '@material-ui/core';

import { useStyles } from '../styles';

interface Props {
  bgColor: string;
  textColor: string;
  label: string | number;
  fontSize?: string;
}

export const HourNumber = ({ bgColor, textColor, label, fontSize = '13px' }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.numberContainer} style={{ backgroundColor: bgColor }}>
      <Typography style={{ color: textColor, fontWeight: 700, fontSize }}>{label}</Typography>
    </Box>
  );
};
