import React, { useContext } from 'react';

import { useGridSlotComponentProps } from '@mui/x-data-grid';
import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { Theme } from '@material-ui/core/styles';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import clsx from 'clsx';

import { DeviceContext } from '@vyce/core/src/contexts';

import { backgrounds, monochrome } from '../theme/styles';

enum buttonTypes {
  NEXT = 'Next',
  PREVIOUS = 'Prev',
}

interface ButtonProps {
  type: buttonTypes;
  disabled: boolean;
  setPage: Function;
  width: number;
  extraSmallMobile: boolean;
}

export interface CustomPaginationProps {
  page: number;
  pageCount: number;
  pageSize: number;
  rowCount: number;
  setPage: (arg0: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paginationRoot: {
      display: 'flex',
      border: 'none',
      '& .MuiPaginationItem-page': {
        border: `2px solid ${theme.palette.type === 'dark' ? monochrome.dark : monochrome.mediumlight}`,
        color: theme.palette.primary.main,
        fontWeight: 600,
        margin: '0 8px',
        backgroundColor: theme.palette.type === 'dark' ? backgrounds.darkModePaper : backgrounds.paper,
      },
      '& .MuiPaginationItem-page.Mui-selected': {
        border: 'none',
        color: '#fff',
        backgroundColor: theme.palette.primary.main,
      },
      '& .MuiPaginationItem-ellipsis': {
        fontSize: '13px',
        fontWeight: 600,
        color: theme.palette.primary.main,
        margin: '0 8px 9px 8px',
        padding: 0,
        minWidth: 'auto',
      },
    },
    wrapper: {
      padding: '8px',
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.only('xs')]: {
        flexDirection: 'column-reverse',
        alignItems: 'center',
        gridGap: '8px',
        padding: 0,
      },
    },
    buttonWrapper: {
      [theme.breakpoints.only('xs')]: {
        width: 'max-content !important',
        minWidth: 'max-content !important',

        '& .MuiButton-startIcon': {
          marginRight: 0,
          marginLeft: 0,
        },
        '& .MuiButton-endIcon': {
          marginRight: 0,
          marginLeft: 0,
        },
      },
    },

    infoWrapper: {
      left: 16,
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.only('xs')]: { position: 'unset' },
    },
  })
);

const PaginationButton: React.FC<ButtonProps> = ({ type, disabled, setPage, width, extraSmallMobile }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Button
      size="small"
      disabled={disabled}
      onClick={() => setPage()}
      className={clsx({ [classes.buttonWrapper]: extraSmallMobile })}
      style={{
        height: 32,
        width,
        color: disabled ? theme.palette.divider : theme.palette.primary.main,
        borderRadius: '16px',
        backgroundColor: theme.palette.type === 'dark' ? backgrounds.darkModePaper : backgrounds.paper,
      }}
      startIcon={type === buttonTypes.PREVIOUS && <FaChevronLeft size="12px" />}
      endIcon={type === buttonTypes.NEXT && <FaChevronRight size="12px" />}
      variant="outlined">
      {extraSmallMobile ? '' : type}
    </Button>
  );
};

export const GridCustomPagination = () => {
  const {
    state: { pagination },
    apiRef,
  } = useGridSlotComponentProps();
  const setPage = apiRef.current.setPage;

  return <CustomPagination {...pagination} setPage={setPage} />;
};

export const CustomPagination = ({
  page,
  pageCount,
  pageSize,
  rowCount,
  setPage,
}: CustomPaginationProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const extraSmallMobile = useMediaQuery(theme.breakpoints.only('xs'));

  const getToResult = (): number => {
    const maxSize = pageSize * (page + 1);
    return maxSize > rowCount ? rowCount : maxSize;
  };

  return (
    <Box className={classes.wrapper}>
      {!!rowCount && (
        <Box className={classes.infoWrapper}>
          <Typography
            variant="caption"
            style={{ color: theme.palette.type === 'dark' ? monochrome.medium : monochrome.dark }}>
            {pageSize * page + 1} - {getToResult()} of {rowCount}
          </Typography>
        </Box>
      )}

      {!!rowCount && (
        <Box display="flex" gridGap={extraSmallMobile ? 8 : 16} alignItems="center">
          <PaginationButton
            width={77}
            type={buttonTypes.PREVIOUS}
            disabled={!page}
            extraSmallMobile={extraSmallMobile}
            setPage={() => setPage(page - 1)}
          />
          <Pagination
            color="primary"
            siblingCount={0}
            hideNextButton
            hidePrevButton
            classes={{
              root: classes.paginationRoot,
            }}
            count={pageCount}
            page={page + 1}
            onChange={(event, value) => setPage(value - 1)}
          />
          <PaginationButton
            width={77}
            type={buttonTypes.NEXT}
            disabled={page + 1 === pageCount}
            extraSmallMobile={extraSmallMobile}
            setPage={() => setPage(page + 1)}
          />
        </Box>
      )}
    </Box>
  );
};
