import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { FieldProps } from '../../types';
import { AppTextField } from '../inputs';

export const emailPattern = {
  value: /\S+@\S+\.\S+/,
  message: 'Invalid email format',
};
export const emailFieldRules = {
  required: 'Email is required',
  pattern: emailPattern,
};

export const EmailField: React.FC<FieldProps> = ({
  autoFocus = true,
  margin = undefined,
  name = 'email',
  label = 'Email Address',
  disabled = false,
  rules = emailFieldRules,
  onKeyDown,
  ...props
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => (
        <AppTextField
          {...field}
          {...props}
          error={!!errors[name]?.message}
          variant="filled"
          margin={margin}
          disabled={disabled}
          fullWidth
          id="email"
          cy-test-id="email"
          label={label}
          type="email"
          autoComplete="email"
          autoFocus={autoFocus}
          helperText={(errors[name]?.message as string) || ''}
          onKeyDown={onKeyDown}
        />
      )}
    />
  );
};
