import React from 'react';
import { useHistory } from 'react-router-dom';
import { Avatar, Box, Button, Card, Typography, Divider, useTheme } from '@material-ui/core';
import { RiTeamLine, RiGroupLine } from 'react-icons/ri';
import clsx from 'clsx';

import { Team } from '@vyce/core/src/types';

import { useStyles } from '../styles';

export const TeamCard = ({ uuid, logo, name, member_count }: Team) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  return (
    <Card style={{ padding: 24 }} variant="outlined">
      <Box display="flex" alignItems="center" overflow="hidden" mb={2.5} gridGap={16}>
        <Avatar style={{ height: 48, width: 48 }} src={logo?.url}>
          {!logo && <RiTeamLine size="30px" />}
        </Avatar>

        <Box overflow="hidden">
          <Typography
            variant="subtitle1"
            className={classes.bold}
            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {name}
          </Typography>
          <Box display="flex" alignItems="center" gridGap={8}>
            <RiGroupLine size="14px" color={theme.palette.text.secondary} />
            <Typography
              variant="subtitle1"
              color="textSecondary"
              className={clsx(classes.bold, classes.smallText)}>
              {member_count || 0} members
            </Typography>
          </Box>
        </Box>
      </Box>

      <Divider />

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={classes.controlArea}
        mt={3}>
        <Typography variant="subtitle1" color="textSecondary">
          {member_count || 0} members
        </Typography>

        <Button
          size="small"
          variant="outlined"
          cy-test-id="manage-team-button"
          className={classes.customButton}
          onClick={() => history.push(`/teams/list/${name}_${uuid}`)}>
          Manage team
        </Button>
      </Box>
    </Card>
  );
};
