import { createContext } from 'react';
import { DragItem } from '../types';

interface Context {
  timeLogFields: DragItem[];
  customLocationFields: DragItem[];
  systemLocationFields: DragItem[];
  detailedSummaryFields: DragItem[];
}

export const FieldsValuesContext = createContext({} as Context);

export const FieldsValuesProvider = FieldsValuesContext.Provider;
