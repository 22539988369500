import { useContext, useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { getPaySchedulesRequest } from '@vyce/core/src/api/pay';
import { useQuery } from '@vyce/core/src/hooks';
import { Company, PaySchedule, Shift, TimeAndAttendanceLocation } from '@vyce/core/src/types';
import { getUrlItems } from '@vyce/core/src/utils/url';
import {
  createShiftRequest,
  deleteShiftRequest,
  getSiteRequest,
  updateShiftRequest,
  updateSiteRequest,
  getCustomLocationFields,
  updateCustomLocationFields,
} from '@vyce/core/src/api/time';
import { TimeModuleContext } from '@vyce/core/src/contexts';
import { CustomLocationField } from '@vyce/core/src/api/types';

import { locationToSite, shiftToSiteShift, siteToLocation, prepareCustomDetailsForSending } from '../utils';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  selectedCompany?: Company;
  token: string;
  fetchCompanyData?: Function;
}

export const useLocationData = ({ selectedCompany, fetchCompanyData }: Props) => {
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const [location, setLocation] = useState<TimeAndAttendanceLocation>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [paySchedule, setPaySchedule] = useState<PaySchedule>();
  const [customLocationFields, setCustomLocationFields] = useState<CustomLocationField[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { setBackTo } = useContext(TimeModuleContext);
  const [paySchedules, setPaySchedules] = useState<PaySchedule[]>([]);
  const query = useQuery();

  const selectedCompanyId = selectedCompany?.uuid ?? '';
  const locationId = location?.uuid ?? '';

  const getPaySchedules = async (companyId: string) => {
    try {
      const res = await getPaySchedulesRequest({ companyId });
      setPaySchedules(res.data.items);
    } catch (e) {
      handleServerError(e);
    }
  };

  const fetchCustomLocationFields = async (companyId: string, locationId: string) => {
    try {
      const { data } = await getCustomLocationFields({ companyId, siteId: locationId });
      setCustomLocationFields(data.values ?? []);
    } catch (e) {
      handleServerError(e);
    }
  };

  useEffect(() => {
    if (selectedCompanyId) {
      getPaySchedules(selectedCompanyId);
    }
  }, [selectedCompanyId]);

  useEffect(() => {
    if (selectedCompanyId && locationId) {
      fetchCustomLocationFields(selectedCompanyId, locationId);
    }
  }, [selectedCompanyId, locationId]);

  const getLocationData = async () => {
    if (!selectedCompanyId) {
      return;
    }
    setLoading(true);
    try {
      const urlItems = getUrlItems(id);
      const res = await getSiteRequest(selectedCompanyId, urlItems.id);
      const loc = siteToLocation(res.data);
      setLocation(loc);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  };

  const closeLocation = () => {
    if (!location?.uuid) {
      return;
    }
    // TODO finish when backend will be ready
    showNotification({ message: 'The location has been closed.', options: { variant: 'success' } });
    history.goBack();
  };

  const back = () => {
    history.goBack();
  };

  const saveCustomLocationChanges = async ({
    customFields,
    siteId,
  }: {
    customFields: Record<string, string>;
    siteId: string;
  }) => {
    if (!customFields || Object.keys(customFields).length === 0) {
      return;
    }
    try {
      const preparedCustomDetailsForSending = prepareCustomDetailsForSending(customFields);
      const { data } = await updateCustomLocationFields({
        companyId: selectedCompanyId,
        siteId,
        data: { values: preparedCustomDetailsForSending },
      });
      setCustomLocationFields(data.values);
      showNotification({
        message: 'Custom Location Fields has been updated.',
        options: { variant: 'success' },
      });
    } catch (e) {
      handleServerError(e);
    }
  };

  const saveLocationChanges = async ({
    customFields,
    ...location
  }: TimeAndAttendanceLocation & { customFields: Record<string, string> }) => {
    if (!selectedCompanyId || !location?.uuid) {
      return;
    }
    try {
      const site = locationToSite(location);
      saveCustomLocationChanges({ customFields, siteId: location.uuid });
      await updateSiteRequest({ companyId: selectedCompanyId, siteId: location.uuid, site });

      const siteShifts = location.shifts ? location.shifts.map(shift => shiftToSiteShift(shift)) : [];

      for (let i = 0; i < siteShifts.length; i++) {
        const shift = siteShifts[i];
        if (shift.uuid) {
          await updateShiftRequest({
            companyId: selectedCompanyId,
            siteId: location.uuid,
            shiftId: shift.uuid,
            shift,
          });
        }
      }
      await getLocationData();
      showNotification({ message: 'Location has been updated.', options: { variant: 'success' } });
    } catch (e) {
      handleServerError(e);
    }
  };

  const createShift = async (shift: Shift) => {
    if (!selectedCompanyId || !location?.uuid) {
      return;
    }
    const siteShift = shiftToSiteShift(shift);
    try {
      await createShiftRequest({ companyId: selectedCompanyId, shift: siteShift, siteId: location.uuid });
      showNotification({ message: 'Shift has been updated.', options: { variant: 'success' } });

      getLocationData();

      if (fetchCompanyData) {
        fetchCompanyData(selectedCompanyId);
      }
    } catch (e) {
      handleServerError(e);
    }
  };

  const deleteShift = async (shiftId: string) => {
    if (!selectedCompanyId || !location?.uuid) {
      return;
    }

    try {
      await deleteShiftRequest(selectedCompanyId, location.uuid, shiftId);
      showNotification({ message: 'Shift has been deleted.', options: { variant: 'success' } });
      getLocationData();
    } catch (e) {
      handleServerError(e);
    }
  };

  useEffect(() => {
    if (!id) {
      return;
    }
    getLocationData();
    setBackTo({ text: 'Locations & Shifts', back });
    return () => {
      setBackTo(null);
    };
  }, [id]);

  useEffect(() => {
    const mode = query.get('mode');
    setEditMode(mode === 'edit');
  }, [query]);

  return {
    loading,
    setOpenConfirmDialog,
    setEditMode,
    editMode,
    location,
    saveLocationChanges,
    paySchedules,
    paySchedule,
    openConfirmDialog,
    customLocationFields,
    closeLocation,
    createShift,
    deleteShift,
  };
};
