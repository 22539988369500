import React from 'react';

import { Box } from '@material-ui/core';

import { ControlledSelect } from '@vyce/core/src/components/controlled-inputs';
import { OnboardingStepTitle } from '@vyce/core/src/components';
import { SENDER_TYPES } from '@vyce/core/src/constants';

interface Props {
  companyName?: string;
}

const rules = {
  required: 'Sender Type is required',
};

export const SenderStep = ({ companyName }: Props) => {
  return (
    <Box>
      <OnboardingStepTitle
        text={`In what capacity will you be processing the payroll for ${companyName}? E.G. As the Employer or as an Agent on behalf of the employer.`}
      />

      <ControlledSelect
        label="Select the role"
        rules={rules}
        margin="normal"
        name="sender"
        items={SENDER_TYPES}
        multiple={false}
      />
    </Box>
  );
};
