import React, { useContext } from 'react';

import { Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Link } from 'react-router-dom';
import noop from 'lodash/noop';

import { DeviceContext } from '@vyce/core/src/contexts';
import { getDayWithDeclination } from '@vyce/core/src/utils/dates';

import { MAIN_CONTAINER_ID } from '../constants';

interface Props {
  open: boolean;
  teamsLink?: string;
  payrollLink?: string;
  teamsText?: string;
  setOpen: Function;
  withTeams?: boolean;
  withPayroll?: boolean;
  withTime?: boolean;
  closeFunction?: () => void;
}

const defaultTeamsLink = '/teams/list';
const defaultPayrollLink = '/payroll/users?invite=true';
const defaultTimeLink = '/time/users?invite=true';
const defaultTeamsText = 'Add to Teams';

export const SuccessSubscriptionDialog: React.FC<Props> = ({
  open,
  setOpen,
  teamsText = defaultTeamsText,
  withTeams = false,
  withPayroll = false,
  withTime = false,
  teamsLink = defaultTeamsLink,
  payrollLink = defaultPayrollLink,
  closeFunction = noop,
}) => {
  const { isMobile } = useContext(DeviceContext);

  const handleClose = () => {
    closeFunction();
    setOpen(false);
  };

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      open={open}
      onClose={handleClose}>
      <DialogTitle>Congrats!</DialogTitle>

      <DialogContent>
        <DialogContentText>
          You’ve successfully subscribed to Vyce. Now you have access to all the Vyce modules that will help
          transform your business.
        </DialogContentText>
        <DialogContentText>
          You’ll be billed on the {getDayWithDeclination()} of every month going forward for the previous
          months usage based on the number of users activated on each module.
        </DialogContentText>
        <DialogContentText>Now, go ahead and add your team to your modules!</DialogContentText>

        {withTeams && (
          <Button
            variant="contained"
            component={Link}
            to={teamsLink}
            size="large"
            style={{ marginTop: 38 }}
            onClick={handleClose}
            color="primary"
            fullWidth>
            {teamsText}
          </Button>
        )}

        {withPayroll && (
          <Button
            variant="contained"
            style={{ marginTop: 16 }}
            component={Link}
            size="large"
            to={payrollLink}
            color="primary"
            onClick={handleClose}
            fullWidth>
            Add to Payroll
          </Button>
        )}

        {withTime && (
          <Button
            variant="contained"
            style={{ marginTop: 16 }}
            component={Link}
            size="large"
            to={defaultTimeLink}
            color="primary"
            onClick={handleClose}
            fullWidth>
            Add to Time & Attendance
          </Button>
        )}

        <Box marginBottom={5} marginTop={2} display="flex" justifyContent="center">
          <Button size="large" onClick={handleClose} variant="outlined">
            Close
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
