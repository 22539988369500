import React from 'react';

import { BarDatum, ResponsiveBar } from '@nivo/bar';
import { Box, Theme, IconButton, useTheme } from '@material-ui/core';
import { ComputedDatum } from '@nivo/pie';
import { AxisTickProps } from '@nivo/axes';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import clsx from 'clsx';

import { HealthSafetyWidget } from '@vyce/core/src/views/widgets/HealthSafetyWidget';

import { useStyles } from '../../styles';
import { monochrome } from '../../../../theme/styles';
import { useChartData } from './hooks';

interface Props {
  data: BarDatum[];
  handleChartClick?: (datum: ComputedDatum<BarDatum> & any, event: any) => void;
}

const keys = ['value'];
const marginOptions = { top: 50, right: 5, bottom: 50, left: 40 };

const CustomBottomTick = (tick: AxisTickProps<string>) => {
  const classes = useStyles();
  return (
    <g transform={`translate(${tick.x},${tick.y + 16})`}>
      <text textAnchor="middle" dominantBaseline="middle" className={classes.barText}>
        {tick.value}
      </text>
    </g>
  );
};

export const BarChart = ({ data, handleChartClick }: Props): JSX.Element => {
  const theme = useTheme<Theme>();
  const classes = useStyles();

  const { ref, cuttedData, showDecreaseButton, showIncreaseButton, axisBottom, increasePage, decreasePage } =
    useChartData(data);

  return (
    <div ref={ref} className={clsx(classes.chartWrapper, classes.widgetContainer)}>
      <ResponsiveBar
        data={cuttedData}
        keys={keys}
        indexBy="id"
        margin={marginOptions}
        padding={0.7}
        colorBy="id"
        groupMode="stacked"
        valueFormat=" >-"
        theme={{
          labels: {
            text: {
              fill: theme.palette.type === 'dark' ? monochrome.dark : monochrome.lightest,
              fontSize: 13,
              fontWeight: 500,
              lineHeight: '22px',
            },
          },
          axis: {
            ticks: {
              line: {
                display: 'none',
              },
              text: {
                fill: theme.palette.type === 'dark' ? monochrome.lightest : monochrome.dark,
                fontSize: 13,
                fontFamily: 'inherit',
                fontWeight: 500,
                lineHeight: '22px',
                transform: 'translate(-4px, 0) rotate(0deg)',
              },
            },
            legend: {
              text: {
                fontSize: 13,
                fill: theme.palette.type === 'dark' ? monochrome.lightest : monochrome.dark,
                fontFamily: 'inherit',
                fontWeight: 600,
                lineHeight: '22px',
              },
            },
          },
        }}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        barComponent={HealthSafetyWidget.CustomBarComponent}
        axisBottom={{
          renderTick: CustomBottomTick,
          legend: axisBottom,
          legendPosition: 'middle',
          legendOffset: 40,
        }}
        axisLeft={{
          format: e => Math.floor(e) === e && e,
          tickValues: 5,
        }}
        animate={false}
        axisTop={null}
        axisRight={null}
        labelSkipWidth={22}
        labelSkipHeight={12}
        onClick={handleChartClick}
      />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        className={classes.pageButtonsWrapper}
        gridGap={16}>
        <IconButton
          onClick={decreasePage}
          disabled={!showDecreaseButton}
          className={clsx(classes.pageIconButton, {
            [classes.pageIconButtonDisabled]: !showDecreaseButton,
          })}>
          <FiChevronLeft
            size="20px"
            color={theme.palette.type === 'dark' ? monochrome.lighter : monochrome.dark}
          />
        </IconButton>
        <IconButton
          onClick={increasePage}
          disabled={!showIncreaseButton}
          className={clsx(classes.pageIconButton, {
            [classes.pageIconButtonDisabled]: !showIncreaseButton,
          })}>
          <FiChevronRight
            size="20px"
            color={theme.palette.type === 'dark' ? monochrome.lighter : monochrome.dark}
          />
        </IconButton>
      </Box>
    </div>
  );
};
