import React, { useContext } from 'react';

import { Box, Typography, Button } from '@material-ui/core';

import { useHistory } from 'react-router-dom';

import config from '@vyce/core/src/assets/config';
import { DeviceContext } from '../contexts';

export const NoRowsOverlayComponent = ({
  unit,
  withProfileButton = false,
}: {
  unit?: string;
  withProfileButton?: boolean;
}) => {
  const history = useHistory();
  const { isMobile } = useContext(DeviceContext);

  const handleClick = () => history.push('/profile');

  return (
    <Box
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      padding={3}
      className="MuiDataGrid-overlay">
      <Box height={isMobile ? 40 : '20%'}>
        <img height="100%" alt="Zap" src={config.icons3D.lightning} />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        marginTop={isMobile ? 1 : 3}>
        <Typography color="textSecondary" variant="subtitle1">
          Nothing here yet!
        </Typography>
        {unit && (
          <Typography color="textSecondary" variant="subtitle1" align="center">
            Your {unit} will appear here when they're added.
          </Typography>
        )}
      </Box>
      {withProfileButton && (
        <>
          <Box marginBottom={isMobile ? 1 : 2} marginTop={isMobile ? 1 : 2}>
            <Typography color="textSecondary" variant="subtitle1" align="center">
              In the meantime, why not update your profile?
            </Typography>
          </Box>
          <Button
            size={isMobile ? 'small' : 'medium'}
            variant="contained"
            color="primary"
            onClick={handleClick}
            style={{ zIndex: 10 }}>
            Update my profile
          </Button>
        </>
      )}
    </Box>
  );
};
