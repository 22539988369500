import { makeStyles, Theme } from '@material-ui/core';

import { monochrome } from '../../theme/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100%',
  },
  bold: {
    fontWeight: 600,
  },
  bold700: {
    fontWeight: 700,
  },
  bold500: {
    fontWeight: 500,
  },
  lineHeight30: {
    lineHeight: '30px',
  },
  textSize14: {
    fontSize: '14px !important',
  },
  textCenter: {
    textAlign: 'center',
  },
  moduleName: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginLeft: '4px',
  },
  paper: {
    padding: theme.spacing(3),
    height: '100%',
  },
  shiftContainer: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2, 0),
  },
  shiftContainerLastChild: {
    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: 0,
    },
  },
  divider: {
    margin: theme.spacing(0, 5),
  },
  breakCheckbox: {
    height: '22px',
  },
  editPositionSubtitle: {
    fontSize: '14px',
    lineHeight: '27px',
    color: theme.palette.type === 'dark' ? monochrome.medium : monochrome.dark,
  },
  shiftDivider: {
    margin: '16px -1px 16px 0',
    borderRight: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: '-1px 16px 0 16px',
      borderRight: 'none',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  shiftHorizontalDivider: {
    margin: '16px -1px 16px 0',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  timeFields: {
    transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  shiftDialogPaper: {
    overflowY: 'hidden',
    width: '800px',
    [theme.breakpoints.down('md')]: {
      overflowY: 'auto',
      width: '100%',
    },
  },
  totalContainer: {
    padding: '16px 24px',
    alignItems: 'center',
    display: 'flex',
    borderRadius: '0 0 8px 8px ',
    border: `1px solid ${theme.palette.divider}`,
    borderTop: 'none',
    backgroundColor: theme.palette.type === 'light' ? monochrome.lightest : monochrome.darkest,
    color: theme.palette.type === 'light' ? monochrome.mediumdark : monochrome.mediumlight,
    fontWeight: 500,
  },
  subtitle: {
    fontSize: '15px',
    lineHeight: '24px',
    color: theme.palette.type === 'dark' ? monochrome.medium : monochrome.dark,
  },
  tableCheckboxLabel: {
    fontSize: '15px',
    fontWeight: 400,
  },
  activityAvatar: {
    width: 104,
    height: 104,
    [theme.breakpoints.down('md')]: {
      width: 64,
      height: 64,
    },
    [theme.breakpoints.down('xs')]: {
      width: 56,
      height: 56,
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  dialog: {
    '& .MuiDialog-paper': {
      overflowX: 'hidden',
    },
  },
  customButton: {
    color: theme.palette.primary.main,
  },
}));
