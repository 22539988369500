import React from 'react';

import {
  EmergencyContactNameField,
  EmergencyContactNumberField,
} from '@vyce/core/src/components/controlled-inputs';
import { OnboardingStepTitle } from '@vyce/core/src/components';
import { TitleProps } from './types';

export const EmergencyContactsStep = ({
  title = 'Please provide us with a name and number to contact in case of an emergency...',
}: TitleProps) => {
  return (
    <>
      <OnboardingStepTitle text={title} />

      <EmergencyContactNameField margin="normal" />

      <EmergencyContactNumberField margin="normal" />
    </>
  );
};
