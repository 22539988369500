import { useContext } from 'react';

import { TbFile } from 'react-icons/tb';
import { IoClose } from 'react-icons/io5';
import { Box, Typography, useTheme, makeStyles, Theme, createStyles } from '@material-ui/core';

import { bytesToSize } from '@vyce/core/src/utils';

import { AppIconButton } from '../../../components';
import { monochrome } from '../../../theme/styles';
import { DeviceContext } from '../../../contexts';
import { AIChatFile } from '../../../types';

interface Props {
  file: AIChatFile;
  setFile?: (file: AIChatFile | undefined) => void;
  borderRadius?: string;
}

export const FileCard: React.FC<Props> = ({ file, setFile, borderRadius }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { isMobile } = useContext(DeviceContext);

  return (
    <Box
      display="flex"
      padding="10px 32px 10px 16px"
      width="fit-content"
      position="relative"
      alignItems="center"
      color="#FFFFFF"
      gridGap={16}
      maxWidth={isMobile ? '100%' : 400}
      borderRadius={borderRadius || 16}
      style={{
        backgroundColor: '#6E7191',
      }}>
      <Box width={24} display="flex" alignItems="center">
        <TbFile size={24} color="#FFFFFF" />
      </Box>

      <Box overflow="hidden">
        <Typography
          style={{
            fontSize: '15px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}>
          {file.name}
        </Typography>
        {file.size && <Typography variant="caption">{bytesToSize(file.size)}</Typography>}
      </Box>

      {!!setFile && (
        <AppIconButton
          variant="paper"
          className={classes.closeButton}
          size="small"
          style={{ width: 32, height: 32 }}
          onClick={() => setFile(undefined)}>
          <IoClose color={theme.palette.error.main} size="18px" />
        </AppIconButton>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      top: -10,
      right: -10,
      border: `1px solid ${monochrome.mediumlight}`,
      '&:hover': {
        opacity: 1,
        backgroundColor: theme.palette.background.paper,
      },
    },
  })
);
