import { makeStyles, Theme } from '@material-ui/core';

import { monochrome } from '../../../theme/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  axisText: {
    fill: theme.palette.type === 'dark' ? monochrome.lightest : monochrome.dark, //fff :4E4B66
    fontSize: 13,
    fontWeight: 500,
    lineHeight: '22px',
    display: 'inline-block',
    textAlign: 'center',
    width: '100%',
    fontFamily: 'inherit',
  },
  barText: {
    fontSize: 13,
    fontWeight: 500,
    lineHeight: '22px',
  },
  insideText: {
    fill: monochrome.lightest,
  },
  outsideText: {
    fill: theme.palette.type === 'dark' ? monochrome.lightest : monochrome.dark, //fff :4E4B66
  },
  colorItem: {
    borderRadius: '50px',
    width: '12px',
    height: '12px',
  },
  legendWrapper: {
    color: theme.palette.type === 'dark' ? monochrome.lightest : monochrome.dark,

    '& > div:not(:first-of-type)': {
      borderLeft: `1px solid ${theme.palette.type === 'dark' ? monochrome.dark : monochrome.mediumlight}`,
      padding: '0 32px',
    },
    '& > div:last-of-type': {
      padding: '0 0 0 32px',
    },
    '& > div:first-of-type': {
      padding: '0 32px 0 0 ',
    },

    '& > div:only-child': {
      padding: 0,
    },

    [theme.breakpoints.down('xs')]: {
      '& > div:first-of-type': {
        padding: 0,
      },
    },
  },
  legendItemText: {
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '22px',
  },
  legendItemCount: {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  emptyText: {
    width: '250px',
    textAlign: 'center',
  },
  filterWrapper: {
    position: 'absolute',
    top: '-56px',
    right: '80px',
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      top: 0,
      right: 0,
      width: '100%',
    },
  },
  settingsWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  tooltipWrapper: {
    borderRadius: '6px',
    '& div': {
      background: `${theme.palette.type === 'dark' ? monochrome.dark : monochrome.lightest} !important`,
    },
  },
}));
