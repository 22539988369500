import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { AppTextField } from '../../../components/inputs';
import { getHelperText } from './TeamNameField';

const MAX_DESCRIPTION_CHARACTERS = 1000;

export const TeamDescriptionField: React.FC = () => {
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext();

  return (
    <Controller
      control={control}
      rules={{
        maxLength: MAX_DESCRIPTION_CHARACTERS,
      }}
      name="description"
      render={({ field }) => (
        <AppTextField
          {...field}
          label="Description"
          cy-test-id="team-description"
          margin="normal"
          multiline
          fullWidth
          inputProps={{ maxlength: MAX_DESCRIPTION_CHARACTERS }}
          error={!!errors?.description?.message}
          helperText={getHelperText(
            MAX_DESCRIPTION_CHARACTERS,
            200,
            getValues('description'),
            errors?.description?.message as string
          )}
        />
      )}
    />
  );
};
