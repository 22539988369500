import React, { useContext } from 'react';

import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { UserPreview } from '@vyce/core/src/views/preview/user/UserPreview';
import {
  getEmployeePreviewRequest,
  getHealthAndSafetyPDFByUserIdRequest,
  getPassportRequest,
  getRtwRequest,
} from '@vyce/core/src/api/pay';
import { DeviceContext } from '@vyce/core/src/contexts';
import { useEmployeeProfileData } from '@vyce/core/src/modules/userPreviewModule/hooks/useEmployeeProfileData';
import { PageLoading } from '@vyce/core/src/components';

import { useTypedSelector } from '../../../hooks';

export const EmployeePreview: React.FC = () => {
  const { selectedCompany } = useTypedSelector(state => state.helper);
  const { userPreviewData, userPreviewDataLoading, rtw, passport } = useEmployeeProfileData({
    getEmployeePreviewRequest,
    getPassportRequest,
    getRtwRequest,
    selectedCompany,
  });
  const { isMobile } = useContext(DeviceContext);
  const history = useHistory();

  if (userPreviewDataLoading)
    return (
      <Box height="100%">
        <PageLoading />
      </Box>
    );

  return (
    <Box padding={isMobile ? 1 : 5}>
      {userPreviewData && (
        <UserPreview
          rtw={rtw}
          passport={passport}
          timesheetsLink={`${history.location.pathname}/timesheets`}
          paymentsLink={`${history.location.pathname}/payments`}
          agreementsLink={`${history.location.pathname}/agreements`}
          userPreviewData={userPreviewData}
          companyId={selectedCompany?.uuid ?? ''}
          showHealthAndSafetyPDFRequest={getHealthAndSafetyPDFByUserIdRequest}
        />
      )}
    </Box>
  );
};
