import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { CompaniesField } from '@vyce/core/src/components/controlled-inputs/CompaniesField';
import { useFormContext } from 'react-hook-form';

export const CompanyStep: React.FC = () => {
  const { watch } = useFormContext();
  const company = watch('company', '');

  return (
    <div>
      <CompaniesField
        disabled
        name="company"
        label="Company name"
        multiple={false}
        rules={{ required: 'This filed is required' }}
      />

      {company?.registration_number && (
        <Box marginTop={2}>
          <Typography variant="caption" color="textSecondary">
            Registration Number: <strong>{company?.reg_number}</strong>
          </Typography>
        </Box>
      )}

      {company?.registered_address && (
        <Box marginTop={2}>
          <Typography variant="caption" color="textSecondary">
            Registered address: <strong>{company?.address?.address_snippet}</strong>
          </Typography>
        </Box>
      )}
    </div>
  );
};
