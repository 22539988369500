import { Passport, IDVerification } from '@vyce/core/src/types';
import { getUKFormattedDate } from '@vyce/core/src/utils/dates';

export const getIDDetailsData = (passport: Passport, iDVerification: IDVerification) => {
  const {
    last_name = '',
    first_name = '',
    date_of_birth = '',
    gender = '',
    country = '',
    number = '',
  } = passport;

  const { status, expiration_date } = iDVerification;
  return {
    surname: { title: 'Surname', value: last_name },
    given_names: { title: 'Given Names', value: first_name },
    date_of_birth: { title: 'Date of Birth', value: getUKFormattedDate(date_of_birth) },
    sex: { title: 'Sex', value: gender },
    expiration_date: { title: 'Date Of Expiry', value: getUKFormattedDate(expiration_date) },
    country: { title: 'Country Code', value: country },
    number: { title: 'Document/Passport Number', value: number },
    status: { title: 'Status', value: status },
    isShow: !!(
      last_name ||
      first_name ||
      date_of_birth ||
      gender ||
      expiration_date ||
      country ||
      number ||
      status
    ),
  };
};
