import React, { useContext, useState } from 'react';

import { Box, Button, FormControlLabel, Typography } from '@material-ui/core';

import { AppCheckbox, AppTextField } from '@vyce/core/src/components/inputs';
import { createFeedbackRequest } from '@vyce/core/src/api/feedback';
import { FeedbackItem } from '@vyce/core/src/types';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import useStyles from './styles';
import { useTypedSelector } from '../../hooks';

const reasons = ['Too many emails', 'I don’t read them anyway', 'I have privacy concerns'];

export const FeedbackForm: React.FC = () => {
  const classes = useStyles();
  const { handleServerError } = useContext(NotificationContext);
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
  const [anotherReason, setAnotherReason] = useState<string>('');
  const [anotherReasonChecked, setAnotherReasonChecked] = useState<boolean>(false);
  const [isFeedbackSent, setIsFeedbackSent] = useState<boolean>(false);
  const { access_token } = useTypedSelector(state => state.helper);

  const handleReasonCheckboxChange = (checked: boolean, reason: string) => {
    if (checked) {
      setSelectedReasons(value => [...value, reason]);
    } else {
      setSelectedReasons(value => value.filter(item => item !== reason));
    }
  };

  const handleAnotherReasonCheckboxChange = (checked: boolean) => {
    setAnotherReasonChecked(checked);
  };

  const handleSendClick = async () => {
    let reasons = [...selectedReasons];
    if (anotherReason) {
      reasons.push(anotherReason);
    }
    const reason = reasons.join('. ');
    const feedback: FeedbackItem = {
      comment: 'Unsubscribe from email notifications',
      reason,
    };
    try {
      await createFeedbackRequest(access_token, feedback);
      setIsFeedbackSent(true);
    } catch (e) {
      handleServerError(e);
    }
  };

  return (
    <>
      <Typography className={classes.title}>Done, you’ve been unsubscribed.</Typography>
      <Typography variant="subtitle1" color="textSecondary">
        Would you mind telling us why? It will help us to improve our emails in the future.
      </Typography>

      <Box marginTop={4}>
        {reasons.map(reason => (
          <Box marginBottom={3} key={reason} marginLeft="-8px">
            <FormControlLabel
              classes={{ label: classes.label }}
              control={
                <AppCheckbox
                  onChange={e => handleReasonCheckboxChange(e.target.checked, reason)}
                  color="primary"
                  checked={selectedReasons.includes(reason)}
                />
              }
              label={reason}
            />
          </Box>
        ))}

        <Box marginBottom={2} marginLeft="-8px">
          <FormControlLabel
            classes={{ label: classes.label }}
            control={
              <AppCheckbox
                onChange={e => handleAnotherReasonCheckboxChange(e.target.checked)}
                color="primary"
                checked={anotherReasonChecked}
              />
            }
            label="I have another reason"
          />
        </Box>

        {anotherReasonChecked && (
          <AppTextField
            value={anotherReason}
            label="Write the reason here"
            multiline
            fullWidth
            onChange={e => setAnotherReason(e.target.value)}
          />
        )}

        <Box marginTop={5}>
          {isFeedbackSent ? (
            <Button variant="outlined" color="primary" size="large" fullWidth>
              Thank you!
            </Button>
          ) : (
            <Button variant="contained" onClick={handleSendClick} color="primary" size="large" fullWidth>
              Send
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};
