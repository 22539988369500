import { useCallback, useRef, useState, useContext } from 'react';

import { Box, Paper, Typography } from '@material-ui/core';
import { FormProvider } from 'react-hook-form';

import { useStyles } from './styles';
import { useData } from './hooks';
import { Props } from './types';
import {
  FieldsFunctionsProvider,
  FieldsValuesProvider,
  CustomiseTimeLogExportColumns,
  MultipleTimeLogsExport,
} from './components';

/* 
  Module for Exporting Time Logs in T&A settings page
*/

export const ExportingTimeLogsModule = (props: Props) => {
  const classes = useStyles();

  const { contextFunctionOptions, contextValuesOptions, methods } = useData(props);

  return (
    <Paper variant="outlined" className={classes.wrapper}>
      <Box marginBottom={3}>
        <Typography variant="h6">Exporting Time Logs</Typography>
      </Box>

      <Box className={classes.controlsContainer}>
        <FieldsFunctionsProvider value={contextFunctionOptions}>
          <FieldsValuesProvider value={contextValuesOptions}>
            <CustomiseTimeLogExportColumns />
          </FieldsValuesProvider>
        </FieldsFunctionsProvider>
        {/* TODO uncomment when backend will be ready */}
        {/*   <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(noop)}>
            <MultipleTimeLogsExport />
          </form>
        </FormProvider> */}
      </Box>
    </Paper>
  );
};
