import React, { useContext, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { DeviceContext } from '../../../contexts';
import { Image, Passport } from '../../../types';
import { FirstNameField, LastNameField, NationalityField } from '../../../components/controlled-inputs';
import { AppCheckbox, AppKeyboardDatePicker, AppSelect, AppTextField } from '../../../components/inputs';
import { formatDate } from '../../../utils/dates';
import { ButtonTitleWithLoading, ImageUploader } from '../../../components';
import {
  createPassportManuallyRequest,
  uploadPassportRequest,
  uploadSelfieRequest,
} from '../../../api/legend/documents';
import { MAIN_CONTAINER_ID } from '../../../constants';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  open: boolean;
  userId: string;
  setOpen: Function;
  passport?: Passport;
  setPassportState: Function;
  updatePassportManuallyCallback: Function;
}

const GENDERS = ['M', 'F'];

export const ManualIDUploadingDialog: React.FC<Props> = ({
  open,
  setOpen,
  passport,
  userId,
  setPassportState,
  updatePassportManuallyCallback,
}) => {
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const { isMobile } = useContext(DeviceContext);
  const [passportImage, setPassportImage] = useState<Image | undefined>({
    file: null,
    url: passport?.document?.url,
  });
  const [selfieImage, setSelfieImage] = useState<Image | undefined>({
    file: null,
    url: passport?.selfie?.url,
  });
  const methods = useForm({
    defaultValues: {
      country: passport?.country || '',
      number: passport?.number,
      date_of_birth: passport?.date_of_birth,
      expiration_date: passport?.expiration_date,
      nationality: passport?.nationality,
      gender: passport?.gender,
      first_name: passport?.first_name,
      last_name: passport?.last_name,
      verified: passport?.verified,
    },
  });
  const { control, watch, setValue } = methods;
  const nationality: any = watch('nationality');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (nationality) {
      setValue('country', nationality.iso3);
    }
  }, [nationality]);

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      if (passportImage?.file) {
        await uploadPassportRequest(passportImage?.file, userId);
      }
      if (selfieImage?.file) {
        await uploadSelfieRequest(selfieImage?.file, userId);
      }
      const res = await createPassportManuallyRequest({
        data: {
          ...data,
          nationality: data.nationality.iso3,
        },
        userId,
      });
      setPassportState(res.data);
      showNotification({ message: 'Document has been saved', options: { variant: 'success' } });
      updatePassportManuallyCallback();
      setLoading(false);
      setOpen(false);
    } catch (e) {
      handleServerError(e);
    }
  };

  return (
    <Dialog
      fullScreen={isMobile}
      container={document.getElementById(MAIN_CONTAINER_ID)}
      maxWidth="lg"
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="responsive-dialog-title">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle id="responsive-dialog-title">Manual Passport (ID) verification</DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Box position="relative">
                  <Typography style={{ marginBottom: 8 }} variant="h6">
                    Passport (ID)
                  </Typography>

                  <ImageUploader
                    width={250}
                    height={150}
                    image={passportImage}
                    isDocument={true}
                    onImageUpload={image => setPassportImage(image)}
                    extraText="document"
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box position="relative">
                  <Typography style={{ marginBottom: 8 }} variant="h6">
                    Selfie
                  </Typography>

                  <ImageUploader
                    width={112}
                    height={112}
                    image={selfieImage}
                    isDocument={false}
                    onImageUpload={image => setSelfieImage(image)}
                    extraText="picture"
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <FirstNameField margin="normal" rules={{}} />
              </Grid>

              <Grid item xs={12} md={6}>
                <LastNameField margin="normal" rules={{}} />
              </Grid>

              <Grid item xs={12} md={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    name="date_of_birth"
                    control={control}
                    render={props => (
                      <AppKeyboardDatePicker
                        margin="normal"
                        value={props.field.value || null}
                        onChange={data => props.field.onChange(formatDate(data))}
                        label="Date of Birth"
                        format="dd/MM/yyyy"
                        fullWidth
                        disableFuture
                      />
                    )}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="number"
                  render={({ field }) => (
                    <AppTextField
                      {...field}
                      margin="normal"
                      onChange={e => field.onChange(e.target.value)}
                      id="document-number"
                      label="Document Number"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <NationalityField margin="normal" />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="country"
                  render={({ field }) => (
                    <AppTextField
                      {...field}
                      margin="normal"
                      onChange={e => field.onChange(e.target.value)}
                      value={field.value}
                      id="country-code"
                      disabled
                      label="Country Code"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    name="expiration_date"
                    control={control}
                    render={props => (
                      <AppKeyboardDatePicker
                        margin="normal"
                        value={props.field.value || null}
                        onChange={data => props.field.onChange(formatDate(data))}
                        label="Expiration Date"
                        format="dd/MM/yyyy"
                        fullWidth
                      />
                    )}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="gender"
                  control={control}
                  render={props => (
                    <FormControl margin="normal" fullWidth variant="filled">
                      <InputLabel>Gender</InputLabel>
                      <AppSelect
                        onChange={e => props.field.onChange(e.target.value)}
                        value={props.field.value}
                        fullWidth>
                        {GENDERS.map(item => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </AppSelect>
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Box display="flex" alignItems="center" height="100%" justifyContent="flex-end">
                  <Controller
                    name="verified"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <AppCheckbox
                            onChange={e => field.onChange(e.target.checked)}
                            color="primary"
                            checked={field.value}
                          />
                        }
                        label="Verified"
                      />
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button size="large" variant="outlined" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              style={{ width: 90 }}
              disabled={loading}
              size="large"
              type="submit"
              color="primary"
              variant="contained">
              <ButtonTitleWithLoading title="Save" loaderVariant="paper" loading={loading} />
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
