export const splitSize = 4;
export const maxBarWidth = 44;

export const widths = {
  bigWidth: 1168,
  midWidth: 696,
  small: 450,
};

export const limits = {
  bigLimit: 9,
  midLimit: 6,
  smallLimit: 3,
  extraSmall: 2,
};

export const barNames = {
  total_basic_hours: 'total_basic_hours',
  total_overtime_hours: 'total_overtime_hours',
};
export type Names = keyof typeof barNames;
export const colors: Record<Names, { lightColor: string; darkColor: string }> = {
  total_basic_hours: { lightColor: '#610BEF', darkColor: '#610BEF' },
  total_overtime_hours: { lightColor: '#EAAC30', darkColor: '#EAAC30' },
};

export const keys = Object.keys(barNames);
export const marginOptions = { top: 50, right: 5, bottom: 50, left: 40 };
