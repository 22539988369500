import React from 'react';

import { Box, FormControl, FormControlLabel, InputLabel, MenuItem, Typography } from '@material-ui/core';

import { AppCheckbox, AppSelect } from '../../../components/inputs';
import { Role } from '../../../types';
import { DetailsIcon } from '../../../components';
import useStyles from '../styles';

interface Props {
  label: string;
  isSelected: boolean;
  setIsSelected: (value: boolean) => void;
  setRole: (role: Role | null) => void;
  selectedRole: Role | null;
  roles: Role[];
}

export const ModulePermissionItem: React.FC<Props> = ({
  label,
  setIsSelected,
  isSelected,
  setRole,
  roles,
  selectedRole,
}) => {
  const classes = useStyles();
  const handleRoleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const id = e.target.value as string;
    setRole(roles.find(role => role.uuid === id) || null);
  };

  return (
    <Box display="flex" alignItems="center" height={56} marginBottom="12px" justifyContent="space-between">
      <FormControlLabel
        className={classes.styledControlLabel}
        control={
          <AppCheckbox
            onChange={e => setIsSelected(e.target.checked)}
            checked={isSelected}
            color="primary"
          />
        }
        label={label}
      />

      <Box width={350} display="flex" alignItems="center">
        <FormControl disabled={!isSelected} fullWidth variant="filled">
          <InputLabel id="company-role">Select the role</InputLabel>
          <AppSelect
            classes={{ root: classes.selectRoot }}
            onChange={handleRoleChange}
            value={selectedRole?.uuid || ''}
            disabled={!isSelected}
            labelId="company-role"
            fullWidth>
            {roles.map(item => (
              <MenuItem className={classes.menuItem} key={item.uuid} value={item.uuid}>
                {item.name}
                <DetailsIcon
                  popoverClass={classes.permissionPopoverClass}
                  content={
                    <>
                      <Typography color="textSecondary" variant="caption">
                        User will be able to:
                      </Typography>
                      {item.permissions.map(item => (
                        <Box marginTop={0.5} key={item.uuid}>
                          <Typography color="textSecondary" variant="caption">
                            • {item.name}
                          </Typography>
                        </Box>
                      ))}
                    </>
                  }
                />
              </MenuItem>
            ))}
          </AppSelect>
        </FormControl>
      </Box>
    </Box>
  );
};
