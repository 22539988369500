import React, { useContext } from 'react';
import { Box } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';

import { SubscriptionProvider } from '@vyce/core/src/contexts';
import { SUBSCRIPTION_TABS } from '@vyce/core/src/modules/subscriptionModule/constants';
import { useSubscriptionContext } from '@vyce/core/src/modules/subscriptionModule/hooks/useSubscriptionContext';
import { DeviceContext } from '@vyce/core/src/contexts';
import { CommonNavBarBlock } from '@vyce/core/src/components';

import { useStyles } from './styles';
import { EmployerSubscriptionPayments } from './EmployerSubscriptionPayments';
import { EmployerSubscription } from './EmployerSubscription';
import { EmployerBillingInfo } from './EmployerBillingInfo';
import { EmployerEditBillingContact } from './EmployerEditBillingContact';
import { EmployerEditCardDetails } from './EmployerEditCardDetails';

export const EmployerSubscriptionAndBilling: React.FC = () => {
  const classes = useStyles();
  const { isMobile } = useContext(DeviceContext);

  const { contextValue, backTo } = useSubscriptionContext();

  return (
    <Box height="100%">
      <Box height="100%" className={classes.moduleContainer}>
        <CommonNavBarBlock tabItems={SUBSCRIPTION_TABS} backTo={backTo} />

        <Box height="100%" padding={isMobile ? 1 : 5}>
          <SubscriptionProvider value={contextValue}>
            <Switch>
              <Route path={'/subscriptions/payments'} component={EmployerSubscriptionPayments} />
              <Route path={'/subscriptions/billing/contact'} component={EmployerEditBillingContact} />
              <Route path={'/subscriptions/billing/payment-details'} component={EmployerEditCardDetails} />
              <Route path={'/subscriptions/billing'} component={EmployerBillingInfo} />
              <Route path={'/subscriptions'} component={EmployerSubscription} />
            </Switch>
          </SubscriptionProvider>
        </Box>
      </Box>
    </Box>
  );
};
