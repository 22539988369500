import React from 'react';

import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from '../styles';

export const QuestionCard = ({
  title,
  subTitle,
  onClick,
}: {
  title: string;
  subTitle: string;
  onClick: () => void;
}) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.buttonCard, classes.questionCard)} onClick={onClick}>
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Typography variant="h6" color="primary">
          {title}
        </Typography>
        <Typography color="primary" variant="subtitle1">
          {subTitle}
        </Typography>
      </Box>
    </Box>
  );
};
