import maleAvatarPlaceholder from '@vyce/core/src/assets/avatar-male-placeholder.png';
import femaleAvatarPlaceholder from '@vyce/core/src/assets/avatar-female-placeholder.png';
import neutralAvatarPlaceholder from '@vyce/core/src/assets/avatar-neutral-placeholder.png';

export const getAvatar = (gender?: string | null) => {
  if (gender === 'male') {
    return maleAvatarPlaceholder;
  }
  if (gender === 'female') {
    return femaleAvatarPlaceholder;
  }
  return neutralAvatarPlaceholder;
};
