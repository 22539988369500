export const splitSize = 4;

export const maxCountOfBars = 9;
export const barBlockCount = 3;

export const widths = {
  bigWidth: 1168,
  midWidth: 696,
};

export const limits = {
  bigLimit: 9,
  midLimit: 6,
  smallLimit: 3,
};
