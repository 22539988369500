import React, { ReactElement, useEffect } from 'react';

import { BiDotsVerticalRounded } from 'react-icons/bi';
import Popover from '@material-ui/core/Popover';
import { Box } from '@material-ui/core';

import { AppIconButton } from './AppIconButton';
import { MAIN_CONTAINER_ID } from '../constants';

interface Props {
  children: ReactElement;
  close?: boolean;
  isSmall?: boolean;
}

export const GridActions: React.FC<Props> = ({ children, close = false, isSmall = false }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (close) {
      handleClose();
    }
  }, [close]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box>
      <AppIconButton
        cy-test-id="grid-action-button"
        isSmall={isSmall}
        aria-describedby={id}
        onClick={handleClick}
        variant="default">
        <BiDotsVerticalRounded size="18px" />
      </AppIconButton>

      <Popover
        id={id}
        container={document.getElementById(MAIN_CONTAINER_ID)}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        {children}
      </Popover>
    </Box>
  );
};
