import React, { useState } from 'react';
import { GridActions } from '@vyce/core/src/components/GridActions';
import { MenuItem, MenuList } from '@material-ui/core';

interface Props {
  onDeleteButtonClick: Function;
  onEditButtonClick: Function;
  onDuplicateButtonClick: Function;
  disabled?: boolean;
}

export const TimesheetLineActions: React.FC<Props> = ({
  onDeleteButtonClick,
  onEditButtonClick,
  onDuplicateButtonClick,
  disabled = false,
}) => {
  const [closeGridAction, setCloseGridAction] = useState<boolean>(false);

  const resetCloseStatus = () => {
    setCloseGridAction(true);
    setTimeout(() => setCloseGridAction(false), 100);
  };

  return (
    <GridActions close={closeGridAction}>
      <MenuList>
        <MenuItem
          onClick={() => {
            resetCloseStatus();
            onEditButtonClick();
          }}>
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            resetCloseStatus();
            onDuplicateButtonClick();
          }}>
          Duplicate
        </MenuItem>
        <MenuItem
          disabled={!!disabled}
          onClick={() => {
            resetCloseStatus();
            onDeleteButtonClick();
          }}>
          Delete
        </MenuItem>
      </MenuList>
    </GridActions>
  );
};
