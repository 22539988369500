import React, { useContext } from 'react';

import { Avatar, Box, Typography } from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

import { SectionTitle } from './SectionTitle';
import useStyles from '../../styles';
import { CompanyPhoto } from '../../../../types';
import { DeviceContext } from '../../../../contexts';
import { useBooleanState } from '../../../../hooks';
import { CompanyPhotosDialog } from './CompanyPhotosDialog';

interface Props {
  photos: CompanyPhoto[];
  companyName?: string;
}

export const CompanyPreviewPhotos: React.FC<Props> = ({ photos, companyName }) => {
  const classes = useStyles();
  const { isMobile, isLargeDesktop, isTablet } = useContext(DeviceContext);
  const [isPhotosDialogOpen, openPhotosDialog, closePhotosDialog] = useBooleanState(false);

  const onViewAllClick = () => {
    openPhotosDialog();
  };

  const getMaxDisplayPhotos = (): number => {
    if (isLargeDesktop) return 7;
    if (isTablet) return 6;
    if (isMobile) return 4;
    return 5;
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="12px">
        <SectionTitle title="Photos" />

        <Typography onClick={onViewAllClick} className={classes.viewAll} color="primary" variant="subtitle1">
          View All
        </Typography>
      </Box>

      <CompanyPhotosDialog
        photos={photos}
        open={isPhotosDialogOpen}
        companyName={companyName}
        handleClose={closePhotosDialog}
      />

      <Box
        className={classes.tileItem}
        marginBottom={5}
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="center"
        padding={2}>
        <AvatarGroup max={getMaxDisplayPhotos()} classes={{ avatar: classes.photo }}>
          {photos.map(photo => (
            <Avatar alt={photo.title} src={photo.url} />
          ))}
        </AvatarGroup>
      </Box>
    </>
  );
};
