import React from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import { Maintenance } from '@vyce/core/src/views/maintenance';

import { ROUTES_WITHOUT_NAVIGATION } from 'src/views/main/constants';
import { TeamsSettings } from 'src/modules';

import { EmployerOnboarding } from '../views/onboarding/employer/EmployerOnboarding';
import { EmployerHiring } from '../views/hiring/EmployerHiring';
import { EmployerCandidates } from '../views/hiring/EmployerCandidates';
import { EmployeeOnboarding } from '../views/onboarding/employee/EmployeeOnboarding';
import { EmployerPositions } from '../views/positions/EmployerPositions';
import { CompanyPreviewWrapper } from '../views/profile/company/CompanyPreviewWrapper';
import { CompanyProfile } from '../views/profile/company/CompanyProfile';
import { EnrollEmployee } from '../views/onboarding/employee/EnrollEmployee';
import { MainDashboard } from '../views/dashboards/MainDashboard';
import { Profile } from '../views/profile/Profile';
import { MainEmailVerification } from '../views/profile/components/MainEmailVerification';
import { EnrollEmployer } from '../views/onboarding/employer/EnrollEmployer';
import { Preview } from '../views/profile/Preview';
import { EmployeePaymentsPage, PublicCompanyPreviewWrapper } from '../views/payroll/employee';
import { EmployeeTimeLogs } from '../views/employeeTimeLogs';
import { EmployeePayroll } from '../views/employeePayroll';
import { GeneralAuth } from '../views/auth/general/GeneralAuth';
import { EmployeeAuth } from '../views/auth/employee/EmployeeAuth';
import { EmployerAuth } from '../views/auth/employer/EmployerAuth';
import { EmployeePreview } from '../views/payroll/components/EmployeePreview';
import { EmployerSubscriptionAndBilling } from '../views/subscriptions/EmployerSubscriptionAndBilling';
import { EmployerUserProfile } from 'src/views/profile/employer/EmployerUserProfile';
import { EmployerPayroll } from '../views/payroll';
import { EmployerPayrollOnboarding } from '../views/payroll/EmployerPayrollOnboarding';
import { EmployerTeams } from '../views/teams';
import { EmployerUserManagement } from '../views/user-management/EmployerUserManagement';
import { EmployerTimeAndAttendance } from '../views/time';
import { TeamMemberPreview } from '../views/teams/components/TeamMemberPreview';
import { ResetPasswordAndLogin } from '../views/auth/employee/ResetPasswordAndLogin';
import { ManageEmailNotification } from '../views/settings/ManageEmailNotification';
import { EmployerProjects } from '../views/projects/EmployerProjects';

import { EmployerOnboardingRoute } from './EmployerOnboardingRoute';
import { UnAuthorizedRoute } from './UnAuthorizedRoute';
import { EmployerRoute } from './EmployerRoute';
import { AuthorizedRoute } from './AuthorizedRoute';
import { AccountRoute } from './AccountRoute';
import { EmployeeRoute } from './EmployeeRoute';
import { EmployerAITool } from '../views/ai/EmployerAITool';
import { EmployeeAITool } from '../views/ai/EmployeeAITool';

const createRoutes = () => (
  <Switch>
    <Route exact path={'/auth/verify_email/:token'} component={MainEmailVerification} />

    <Route exact path={'/maintenance'} component={Maintenance} />

    {/*Authentication*/}
    <UnAuthorizedRoute path="/auth/employer" component={EmployerAuth} />
    <UnAuthorizedRoute
      path="/auth/employee/reset-password/:token/:email/:firstName"
      component={ResetPasswordAndLogin}
    />
    <UnAuthorizedRoute path="/auth/employee" component={EmployeeAuth} />
    <UnAuthorizedRoute path="/auth" component={GeneralAuth} />

    {/*Common*/}
    <EmployerOnboardingRoute exact path="/onboarding" component={EmployerOnboarding} />
    <AuthorizedRoute exact path={'/onboarding/employee/:inviteToken/:type'} component={EmployeeOnboarding} />
    <AuthorizedRoute exact path={'/onboarding/employee'} component={EmployeeOnboarding} />
    <AuthorizedRoute
      isEmployee={true}
      path={'/invites/companies/:companyId/pay_schedules/:payScheduleId/:type/:inviteToken'}
      component={EnrollEmployee}
    />
    <AuthorizedRoute
      isEmployee={true}
      path={'/invites/companies/:companyId/teams/:teamId/:type/:inviteToken'}
      component={EnrollEmployee}
    />
    <AuthorizedRoute isEmployee={true} path={'/invites/:inviteId/:inviteToken'} component={EnrollEmployee} />
    <AuthorizedRoute
      isEmployer={true}
      path={'/management/staff/invite/:staffInviteId/:inviteToken'}
      component={EnrollEmployer}
    />
    <AuthorizedRoute path={'/payroll/onboarding'} component={EmployerPayrollOnboarding} />
    <AuthorizedRoute path={'/payroll/onboarding'} component={EmployerPayrollOnboarding} />
    <AuthorizedRoute path={'/vyce-ai'} component={EmployerAITool} />

    <AccountRoute path={ROUTES_WITHOUT_NAVIGATION.EMAIL_NOTIFICATIONS} component={ManageEmailNotification} />
    <AccountRoute path={'/profile/:tab'} component={Profile} />
    <AccountRoute path={'/profile'} component={Preview} />
    <AccountRoute path={'/dashboard'} component={MainDashboard} />

    {/*Employer*/}
    <Route
      exact
      path="/hiring/live/:job/candidates"
      render={props => <Redirect to={`/hiring/live/${props.match.params.job}/candidates/matched`} />}
    />
    <EmployerRoute
      path={'/user-management/:tab/:user/:tab/:companyId'}
      component={PublicCompanyPreviewWrapper}
    />
    <EmployerRoute exact path={'/user-management/:tab/:id/:tab'} component={EmployerUserProfile} />
    <EmployerRoute exact path={'/user-management/payroll/:id'} component={EmployeePreview} />
    <EmployerRoute exact path={'/user-management/admins/:id'} component={EmployeePreview} />
    <EmployerRoute exact path={'/user-management/teams/:id'} component={TeamMemberPreview} />
    <EmployerRoute path={'/user-management'} component={EmployerUserManagement} />
    <EmployerRoute exact path={'/payroll/users/:id/:tab'} component={EmployerUserProfile} />
    <EmployerRoute exact path={'/payroll/users/:id'} component={EmployeePreview} />
    <EmployerRoute path={'/payroll'} component={EmployerPayroll} />
    <EmployerRoute
      path={'/hiring/live/:job/candidates/:category/:candidate'}
      component={EmployerCandidates}
    />
    <EmployerRoute path={'/hiring/live/:job/candidates/:category'} component={EmployerCandidates} />
    <EmployerRoute path={'/hiring/live/:job/candidates/'} component={EmployerCandidates} />
    <EmployerRoute path={'/hiring/:status/:job'} component={EmployerHiring} />
    <EmployerRoute path={'/hiring/:status'} component={EmployerHiring} />
    <EmployerRoute path={'/companies/:id/:tab'} component={CompanyProfile} />
    <EmployerRoute path={'/companies/:id'} component={CompanyPreviewWrapper} />
    <EmployerRoute path={'/companies'} component={EmployerPositions} />

    <EmployerRoute path={'/time'} component={EmployerTimeAndAttendance} />

    <EmployerRoute path={'/companies'} component={EmployerPositions} />

    <EmployerRoute exact path={'/teams/list/:team/sync_settings'} component={TeamsSettings} />
    <EmployerRoute exact path={'/teams/list/:team/:id'} component={TeamMemberPreview} />
    <EmployerRoute exact path={'/teams/members/:id'} component={TeamMemberPreview} />
    <EmployerRoute exact path={'/teams/members/:id/:tab'} component={EmployerUserProfile} />
    <EmployerRoute exact path={'/teams/dashboard/:id'} component={TeamMemberPreview} />
    <EmployerRoute exact path={'/teams/dashboard/:id/:tab'} component={EmployerUserProfile} />
    <EmployerRoute path={'/teams'} component={EmployerTeams} />
    <EmployerRoute path={'/projects'} component={EmployerProjects} />

    <EmployerRoute path={'/subscriptions'} component={EmployerSubscriptionAndBilling} />

    <Route path="/hiring" render={() => <Redirect to={{ pathname: '/hiring/live' }} />} />

    {/*Employee*/}
    <EmployeeRoute exact path={'/payments'} component={EmployeePaymentsPage} />
    <EmployeeRoute exact path={'/payments/:id'} component={PublicCompanyPreviewWrapper} />
    <EmployeeRoute path={'/employee-payroll'} component={EmployeePayroll} />
    <EmployeeRoute path={'/timelogs'} component={EmployeeTimeLogs} />
    <Route
      path="*"
      render={props => (
        <Redirect to={{ pathname: '/auth/signup', state: { from: props.location.pathname } }} />
      )}
    />
  </Switch>
);

export default createRoutes;
