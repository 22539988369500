import React from 'react';

import { LocationOnboarding } from '@vyce/core/src/views/time/LocationOnboarding';

import { useActions, useTypedSelector } from '../../hooks';

export const EmployerLocationOnboarding: React.FC = () => {
  const { selectedCompany } = useTypedSelector(state => state.helper);
  const { first_name } = useTypedSelector(state => state.user);
  const { fetchCompanyData } = useActions();

  return (
    <LocationOnboarding
      selectedCompany={selectedCompany}
      firstName={first_name}
      fetchCompanyData={fetchCompanyData}
    />
  );
};
