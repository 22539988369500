import React, { ReactNode, useContext, useState } from 'react';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';

import { DeviceContext } from '../../../../contexts';
import { CompanyPhoto } from '../../../../types';
import useStyles from '../../styles';
import { AppIconButton } from '../../../../components';
import { MAIN_CONTAINER_ID } from '../../../../constants';

interface Props {
  open: boolean;
  singleMode?: boolean;
  handleClose: () => void;
  companyName?: string;
  photos: CompanyPhoto[];
}

export const CompanyPhotosDialog: React.FC<Props> = ({
  open,
  handleClose,
  companyName,
  photos,
  singleMode = false,
}) => {
  const { isMobile } = useContext(DeviceContext);
  const [selectedPhoto, setSelectedPhoto] = useState<number>(1);
  const [isPhotoHovered, setPhotoHovered] = useState<boolean>(false);
  const theme = useTheme();
  const classes = useStyles();

  const renderArrowNext = (clickHandler: () => void): ReactNode | null =>
    singleMode ? null : (
      <AppIconButton isLarge variant="paper" onClick={clickHandler} className={classes.carouselButtonNext}>
        <FiChevronRight size="28px" color={theme.palette.primary.main} />
      </AppIconButton>
    );

  const renderArrowPrev = (clickHandler: () => void): ReactNode | null =>
    singleMode ? null : (
      <AppIconButton isLarge variant="paper" onClick={clickHandler} className={classes.carouselButtonPrev}>
        <FiChevronLeft size="28px" color={theme.palette.primary.main} />
      </AppIconButton>
    );

  const handlePhotoChange = (photo: number) => {
    setSelectedPhoto(photo + 1);
  };

  const onClose = () => {
    setPhotoHovered(false);
    setSelectedPhoto(1);
    handleClose();
  };

  return (
    <Dialog
      fullScreen={isMobile}
      container={document.getElementById(MAIN_CONTAINER_ID)}
      maxWidth="lg"
      open={open}
      disableScrollLock
      onClose={onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">
        <Box marginBottom={2} display="flex" justifyContent="space-between">
          <Box>{companyName && `${companyName}'s `}Photos</Box>

          <Box>
            {!singleMode && (
              <Typography>
                {selectedPhoto}/{photos.length}
              </Typography>
            )}
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Carousel
          onChange={handlePhotoChange}
          showThumbs={false}
          useKeyboardArrows={true}
          dynamicHeight={true}
          showIndicators={false}
          showStatus={false}
          showArrows={!singleMode}
          renderArrowNext={renderArrowNext}
          renderArrowPrev={renderArrowPrev}>
          {photos.map(photo => (
            <Box
              position="relative"
              onMouseOver={() => setPhotoHovered(true)}
              onMouseLeave={() => setPhotoHovered(false)}
              bgcolor={theme.palette.background.default}
              borderRadius={24}>
              <img className={classes.carouselPhoto} src={photo.url} alt={photo.title} />
              <Box
                display="flex"
                flexDirection="column"
                alignItems="start"
                className={clsx(classes.carouselLegend, {
                  [classes.carouselLegendHovered]: isPhotoHovered,
                })}>
                <Typography className={classes.bold}>{photo.title}</Typography>

                <Typography style={{ textAlign: 'start' }}>{photo.description}</Typography>
              </Box>
            </Box>
          ))}
        </Carousel>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} size="large" variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
