import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 620,
    flexGrow: 1,
    width: '100%',
  },
  onboardingContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '32px',
    alignItems: 'center',
    marginTop: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  formTitle: {
    marginBottom: '16px',
  },
  bigButtonContainer: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  slide: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  welcomePageContainer: {
    paddingTop: theme.spacing(14),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(8),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(4),
    },
  },
  passportUploaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '32px',
  },
  requirements: {
    marginTop: '16px',
  },
  emailLink: {
    marginLeft: '4px',
    color: theme.palette.primary.main,
  },
  successIcon: {
    marginRight: '8px',
  },
  successText: {
    margin: '16px 0',
  },
  choiceButtonLabel: {
    display: 'flex',
    flexDirection: 'column',
    textTransform: 'none',
  },
  confirmText: {
    margin: '8px 0',
  },
  confirmationCheckboxesContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginTop: '16px',
  },
  errorCheckbox: {
    color: theme.palette.error.main,
  },
  confirmationCheckbox: {
    margin: '8px 0',
  },
  greetingMessage: {
    fontWeight: 700,
  },
}));
