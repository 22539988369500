import React, { useContext, useEffect, useState } from 'react';

import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';

import { DeviceContext } from '@vyce/core/src/contexts';
import {
  AppCreditCard,
  SubscriptionAddressText,
  SubscriptionCardText,
  SubscriptionCardTextProps,
} from '@vyce/core/src/components';
import {
  CardCVCField,
  CardExpiryDateField,
  CardNameField,
  CardNumberField,
} from '@vyce/core/src/components/controlled-inputs';
import { CardForm } from '@vyce/core/src/types';
import { addressFromTitle, cardFromTitle, reactivateCardFromTitle } from '@vyce/core/src/constants/text';

import {
  BillingCityField,
  BillingCountryField,
  BillingLine1Field,
  BillingLine2Field,
  BillingPostcodeField,
  BillingStateField,
} from './controlled-inputs';
import { ButtonTitleWithLoading } from './ButtonTitleWithLoading';
import { MAIN_CONTAINER_ID } from '../constants';

interface Props extends SubscriptionCardTextProps {
  open: boolean;
  loading?: boolean;
  callback: Function;
  needToReset?: boolean;
  onClose: () => void;
}

const defaultValues = {
  cvc: '',
  expiry: '',
  name_on_card: '',
  number: '',
  address: {
    country: '',
    city: '',
    state: '',
    line1: '',
    line2: '',
    postal_code: '',
  },
};

const useStyles = makeStyles(theme =>
  createStyles({
    dialogContainer: {
      '& .MuiDialog-paper': {
        [theme.breakpoints.up('md')]: {
          minWidth: 620,
        },
      },
    },
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    confirmButton: {
      width: '120px',
    },
  })
);

enum FORM_TYPES {
  CARD = 'card',
  ADDRESS = 'address',
}

export const ActivateSubscriptionDialog: React.FC<Props> = ({
  open,
  loading = false,
  needToReset = false,
  subscriptionTextMode,
  wasSubscribed = false,
  callback,
  onClose,
}) => {
  const { isMobile } = useContext(DeviceContext);
  const theme = useTheme();
  const classes = useStyles();
  const methods = useForm<CardForm>({
    defaultValues,
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const [focused, setFocused] = useState<string>('');
  const [currentForm, setCurrentForm] = useState<FORM_TYPES>(FORM_TYPES.CARD);
  const [formTitle, setFormTitle] = useState<string>(
    wasSubscribed ? reactivateCardFromTitle : cardFromTitle
  );
  const {
    reset,
    watch,
    trigger,
    formState: { isValid },
  } = methods;

  const values = watch();

  const onSubmit = (data: CardForm) => {
    callback(data);
  };

  useEffect(() => {
    if (needToReset) reset();
  }, [needToReset]);

  const handleInputFocus = (name: string) => {
    setFocused(name);
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  const handleNextClick = async () => {
    await trigger();
    if (isValid) {
      setCurrentForm(FORM_TYPES.ADDRESS);
      setFormTitle(addressFromTitle);
    }
  };

  const handleBackClick = () => {
    setCurrentForm(FORM_TYPES.CARD);
    setFormTitle(wasSubscribed ? reactivateCardFromTitle : cardFromTitle);
  };

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      open={open}
      onClose={onClose}
      className={classes.dialogContainer}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.formContainer}>
          <DialogTitle>{formTitle}</DialogTitle>

          <DialogContent>
            <DialogContentText>
              {currentForm === FORM_TYPES.CARD ? (
                <SubscriptionCardText
                  subscriptionTextMode={subscriptionTextMode}
                  wasSubscribed={wasSubscribed}
                />
              ) : (
                <SubscriptionAddressText />
              )}
            </DialogContentText>

            {currentForm === FORM_TYPES.CARD && (
              <>
                <Box marginTop={3} marginBottom={1}>
                  <AppCreditCard theme={theme} {...values} focused={focused} />
                </Box>

                <Box display="flex" flexDirection="column" gridGap={16}>
                  <CardNameField margin="normal" handleInputFocus={() => handleInputFocus('name')} />

                  <CardNumberField
                    number={values.number}
                    handleInputFocus={() => handleInputFocus('number')}
                  />

                  <Box display="flex" width="100%" marginTop={1}>
                    <Box marginRight={1} flex={1}>
                      <CardExpiryDateField handleInputFocus={() => handleInputFocus('expiry')} />
                    </Box>

                    <Box marginLeft={1} flex={1}>
                      <CardCVCField handleInputFocus={() => handleInputFocus('cvc')} />
                    </Box>
                  </Box>
                </Box>
              </>
            )}

            {currentForm === FORM_TYPES.ADDRESS && (
              <>
                <BillingLine1Field margin="normal" />

                <BillingLine2Field margin="normal" />

                <BillingCityField margin="normal" />

                <BillingStateField margin="normal" />

                <BillingPostcodeField margin="normal" />

                <BillingCountryField margin="normal" />
              </>
            )}
          </DialogContent>

          <DialogActions>
            <Button size="large" style={{ width: 94 }} variant="outlined" onClick={handleClose}>
              Close
            </Button>
            {currentForm === FORM_TYPES.ADDRESS && (
              <Button
                color="primary"
                size="large"
                style={{ width: 94 }}
                variant="outlined"
                onClick={handleBackClick}>
                Back
              </Button>
            )}
            {currentForm === FORM_TYPES.CARD && (
              <Button
                style={{ width: 94 }}
                size="large"
                variant="contained"
                color="primary"
                onClick={handleNextClick}>
                Next
              </Button>
            )}
            {currentForm === FORM_TYPES.ADDRESS && (
              <Button
                size="large"
                type="submit"
                color="primary"
                variant="contained"
                className={classes.confirmButton}
                disabled={loading}>
                <ButtonTitleWithLoading title="Subscribe" loaderVariant="paper" loading={loading} />
              </Button>
            )}
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
