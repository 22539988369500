import { twoDigits } from './numbers';

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'GBP',
});

/**
 * Formats decimal hours into hours and minutes.
 *
 * @param {number} decimalHours - The decimal hours to format.
 * @return {string} The formatted hours and minutes string.
 */
export const decimalHoursFormatter = (decimalHours: number) => {
  const [hours = 0, mins = 0] = decimalHours.toString().split('.');
  return `${twoDigits(hours)} hrs ${twoDigits(mins)} mins`;
};
