import React from 'react';

import { BackToData, TimeAndAttendanceLocation } from '@vyce/core/src/types';

interface Context {
  backTo: BackToData | null;
  locations: TimeAndAttendanceLocation[];
  setBackTo: Function;
  getLocations: Function;
}

export const TimeModuleContext = React.createContext({} as Context);

export const TimeModuleProvider = TimeModuleContext.Provider;
