import React, { useContext, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import dayjs from 'dayjs';

import { MAIN_CONTAINER_ID } from '../../../../constants';
import { DeviceContext, TimeModuleContext } from '../../../../contexts';
import { ButtonTitleWithLoading } from '../../../../components';
import { Site, TimeAndAttendanceLocation, TimeLogByShiftDateItem } from '../../../../types';
import { formatDate, removeTimeZone } from '../../../../utils/dates';
import { ShiftTime } from '../../../../components/controlled-inputs/ShiftTime';
import {
  addTimePeriodRequest,
  getLookupSiteWorkersRequest,
  getWorkerSitesRequest,
} from '../../../../api/time';
import { siteToLocation } from '../../../../modules/timeModule/utils';
import { ControlledAutocomplete, ControlledSelect } from '../../../../components/controlled-inputs';
import { GetSiteWorkersRequestPayload, WorkerLookupDTO } from '../../../../api/types';
import { AppKeyboardDatePicker } from '../../../../components/inputs';
import { ShiftSelect } from '../../LocationPage/components/ShiftSelect';
import { NotificationContext } from '../../../../contexts/notificationContext';

interface Props {
  handleClose: Function;
  open: boolean;
  userId?: string;
  companyId: string;
  dayLog?: TimeLogByShiftDateItem;
  locationId?: string;
  title: string;
  subtitle?: string;
  periods?: string[];
  getLogs: Function;
}

export interface AddTimeLogForm {
  shift_id: string;
  location: TimeAndAttendanceLocation | null;
  clockInTime: string;
  clockOutTime: string;
  date: string | Date;
  worker: { name: string; id: string } | null;
}

const defaultValues: AddTimeLogForm = {
  shift_id: '',
  date: '',
  worker: null,
  location: null,
  clockInTime: '',
  clockOutTime: '',
};

export const AddTimeLogsDialog: React.FC<Props> = ({
  handleClose,
  open,
  userId,
  companyId,
  dayLog,
  locationId,
  title,
  subtitle,
  periods,
  getLogs,
}) => {
  const { isMobile } = useContext(DeviceContext);
  const { handleServerError } = useContext(NotificationContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { locations } = useContext(TimeModuleContext);
  const [workerLocations, setWorkerLocations] = useState<TimeAndAttendanceLocation[]>([]);
  const [workers, setWorkers] = useState<WorkerLookupDTO[]>([]);

  const methods = useForm<AddTimeLogForm>({
    defaultValues,
  });
  const { setValue, watch, control } = methods;

  const location = watch('location');
  const shiftId = watch('shift_id');
  const worker = watch('worker');
  const clockInTime = watch('clockInTime');
  const clockOutTime = watch('clockOutTime');

  const timeToDate = (time: string, date: string = formatDate(new Date())) => {
    if (!time || !date) {
      return '';
    }
    return dayjs(`${date} ${time}`).format();
  };

  const disableButton = useMemo(
    () =>
      !location?.uuid ||
      !shiftId ||
      !clockInTime ||
      !clockOutTime ||
      dayjs(timeToDate(clockOutTime)).isBefore(dayjs(timeToDate(clockInTime))),
    [location, shiftId, clockInTime, clockOutTime]
  );

  const shifts = useMemo(
    () => locations?.find(loc => loc.uuid === location?.uuid)?.shifts || [],
    [locations, location]
  );

  const handleSubmit = async (data: AddTimeLogForm) => {
    if (!data.worker?.id || !data.location?.uuid) {
      return;
    }

    try {
      const checkInDateTime = timeToDate(data.clockInTime, data.date as string);
      const checkOutDateTime = timeToDate(data.clockOutTime, data.date as string);
      setLoading(true);
      await addTimePeriodRequest(companyId, {
        user_id: data.worker.id,
        workday: formatDate(data.date),
        shift_id: data.shift_id,
        site_id: data.location.uuid,
        check_in: removeTimeZone(checkInDateTime),
        check_out: removeTimeZone(checkOutDateTime),
        check_in_ok: true,
        check_out_ok: true,
        face_in_ok: false,
        face_out_ok: false,
        manual_check_in: true,
        manual_check_out: true,
      });
      setLoading(false);
      getLogs();
      handleClose();
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  };

  const fetchWorkerSitesRequest = async (userId: string) => {
    try {
      const { data } = await getWorkerSitesRequest({
        companyId,
        userId,
        payload: {
          offset: 0,
          limit: 100,
          order_by: [
            {
              field: 'updated_at',
              desc: true,
            },
          ],
        },
      });
      setWorkerLocations(data.items.map((item: Site) => siteToLocation(item)));
      setValue('location', null);
    } catch (e) {
      console.error(e);
    }
  };

  const getWorkers = async (locationId?: string) => {
    setLoading(true);
    try {
      const payload: GetSiteWorkersRequestPayload = {
        offset: 0,
        limit: 3000,
        order_by: [{ field: 'first_name', desc: true }],
        site_id: locationId || undefined,
      };
      const res = await getLookupSiteWorkersRequest({
        companyId,
        payload,
      });
      setWorkers(res.data.items);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (shifts.length === 1 && shifts[0]?.uuid) {
      setValue('shift_id', shifts[0].uuid);
    }
  }, [shifts]);

  useEffect(() => {
    if (locationId) {
      setValue('location', locations?.find(loc => loc.uuid === locationId) || null);
    }
  }, [locationId]);

  useEffect(() => {
    if (dayLog) {
      setValue('date', dayLog.date);
    }
  }, [dayLog]);

  useEffect(() => {
    if (clockInTime && clockOutTime) {
      const shift = shifts.find(item => item.uuid === shiftId);
    }
  }, [clockInTime, clockOutTime, shiftId]);

  useEffect(() => {
    if (!userId) {
      getWorkers(locationId);
    } else {
      setValue('worker', { name: '', id: userId });
    }
  }, []);

  useEffect(() => {
    if (worker && !locationId) {
      fetchWorkerSitesRequest(worker.id);
    }
  }, [locationId, worker]);

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      maxWidth="sm"
      onClose={() => handleClose()}
      open={open}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <DialogTitle>{title}</DialogTitle>

          <DialogContent style={{ minWidth: isMobile ? '100%' : 600 }}>
            <DialogContentText>{subtitle}</DialogContentText>

            {!userId && (
              <ControlledAutocomplete
                name="worker"
                label="Select name"
                rules={{
                  required: 'Name is required',
                }}
                disabled={!workers.length}
                margin="normal"
                items={workers.map(item => ({
                  name: `${item.first_name} ${item.last_name}`,
                  id: item.user_id,
                }))}
              />
            )}

            {!dayLog && (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                  name="date"
                  control={control}
                  render={props => (
                    <AppKeyboardDatePicker
                      value={props.field.value || null}
                      onChange={data => props.field.onChange(formatDate(data))}
                      label="Date"
                      margin="normal"
                      minDate={periods?.length ? dayjs(periods[0]).toDate() : undefined}
                      maxDate={periods?.length ? dayjs(periods[1]).toDate() : undefined}
                      fullWidth
                      id="date-picker-dialog"
                    />
                  )}
                />
              </MuiPickersUtilsProvider>
            )}

            {!locationId && (
              <ControlledSelect
                name="location"
                rules={{
                  required: 'Location is required',
                }}
                disabled={!workerLocations.length}
                label="Select Location"
                margin="normal"
                items={workerLocations || []}
                multiple={false}
              />
            )}

            <ShiftSelect shifts={shifts} />

            <Box display="flex" gridGap={16}>
              <ShiftTime
                disabled={!location || !worker || !shiftId}
                name="clockInTime"
                label="Select Start Time"
                margin="normal"
              />
              <ShiftTime
                disabled={!location || !worker || !shiftId}
                name="clockOutTime"
                label="Select End Time"
                margin="normal"
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button size="large" onClick={() => handleClose()} variant="outlined">
              Close
            </Button>

            <Button
              disabled={disableButton}
              style={{ width: 140 }}
              size="large"
              type="submit"
              color="primary"
              variant="contained">
              <ButtonTitleWithLoading title="Save changes" loaderVariant="paper" loading={loading} />
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
