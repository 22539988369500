import { useCallback, useState, useEffect, useMemo, useContext } from 'react';

import debounce from 'lodash/debounce';
import { v4 as uuid } from 'uuid';

import { getCustomLocationDetailsFields, updateCustomLocationDetailsFields } from '@vyce/core/src/api/time';
import { useBooleanState } from '@vyce/core/src/hooks';

import { ActionType, DragItem, Props } from '../types';
import { defaultDeleteCandidateOptions } from '../config';
import { prepareData } from '../utils';
import { NotificationContext } from '../../../contexts/notificationContext';

export const useData = ({ companyId, setNewSignalForRefreshingId }: Props) => {
  const { handleServerError } = useContext(NotificationContext);
  const [defaultFields, setDefaultFields] = useState<DragItem[]>([]);
  const [customFields, setCustomFields] = useState<DragItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [candidateForDeleting, setCandidateForDeleting] = useState<{ id: string; fieldName: string }>(
    defaultDeleteCandidateOptions
  );
  const [showDeleteDialog, setShowDeleteDialogTrue, setShowDeleteDialogFalse] = useBooleanState(false);

  const getDetailsFields = async () => {
    if (!companyId) {
      return;
    }
    try {
      setLoading(true);
      const { data } = await getCustomLocationDetailsFields({ companyId });
      const preparedData = prepareData(data);
      setDefaultFields(preparedData.default_location_fields);
      setCustomFields(preparedData.custom_location_fields);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  };

  const sendUpdatedFields = useCallback(
    async (fields: DragItem[], type: ActionType) => {
      if (!companyId) {
        return;
      }
      try {
        const preparedFieldsData = fields
          .filter(cf => cf.name?.trim())
          .map((cf, index) => {
            const systemName = cf?.system_name
              ? cf.system_name
              : cf.name?.trim().toLowerCase().split(' ').join('_');
            return { ...cf, position: index + 1, system_name: systemName };
          });
        await updateCustomLocationDetailsFields({ companyId, data: { fields: preparedFieldsData } });
        if (type === 'field_change') setNewSignalForRefreshingId();
      } catch (e) {
        setLoading(false);
        handleServerError(e);
      }
    },
    [companyId]
  );

  const debouncedSendUpdatedFields = useMemo(() => debounce(sendUpdatedFields, 2000), [sendUpdatedFields]);

  const handleUpdateFields = useCallback(
    (type: ActionType, fields?: DragItem[]) => {
      debouncedSendUpdatedFields(fields || customFields, type);
    },
    [customFields]
  );

  const createNewField = useCallback(() => {
    setCustomFields(fields => [
      ...fields,
      {
        id: `custom_${uuid()}`,
        name: '',
        system_name: '',
        position: fields.length,
        is_active: true,
      },
    ]);
  }, []);

  const changeCustomFieldName = useCallback((id: string, value: string) => {
    setCustomFields(prevFields => {
      const copyOfPrevFields = [...prevFields];
      const field = copyOfPrevFields.find(f => f.id === id) as DragItem;
      const fieldIndex = copyOfPrevFields.findIndex(f => f.id === id);

      copyOfPrevFields.splice(fieldIndex, 1, { ...field, name: value });
      return copyOfPrevFields;
    });
  }, []);

  const findCard = useCallback(
    (id: string) => {
      const field = customFields.filter(f => `${f.id}` === id)[0];
      return {
        field,
        index: customFields.indexOf(field),
      };
    },
    [customFields]
  );

  const moveCard = useCallback(
    (dragId: string, hoverIndex: number) => {
      const { index: dragIndex } = findCard(dragId);
      setCustomFields(prevItems => {
        const dubl = [...prevItems];
        dubl.splice(dragIndex, 1);
        dubl.splice(hoverIndex, 0, prevItems[dragIndex]);
        return dubl;
      });
    },
    [findCard]
  );

  const setNewCandidateForDeleting = useCallback(
    (id: string) => {
      const field = customFields.find(f => f.id === id);
      setCandidateForDeleting({ id, fieldName: field?.name ?? '' });
      setShowDeleteDialogTrue();
    },
    [customFields, setShowDeleteDialogTrue]
  );

  const closeDeleteDialog = () => {
    setCandidateForDeleting(defaultDeleteCandidateOptions);
    setShowDeleteDialogFalse();
  };

  const deleteCustomField = useCallback(() => {
    setCustomFields(fields => {
      const editedFields = fields.filter(f => f.id !== candidateForDeleting.id);
      handleUpdateFields('field_change', editedFields);
      return editedFields;
    });
    setShowDeleteDialogFalse();
  }, [candidateForDeleting]);

  useEffect(() => {
    getDetailsFields();
  }, []);

  const contextOptions = useMemo(
    () => ({
      findCard,
      moveCard,
      handleUpdateFields,
      createNewField,
      setNewCandidateForDeleting,
      changeCustomFieldName,
    }),
    [
      findCard,
      moveCard,
      handleUpdateFields,
      createNewField,
      setNewCandidateForDeleting,
      changeCustomFieldName,
    ]
  );

  return {
    loading,
    defaultFields,
    customFields,
    contextOptions,
    showDeleteDialog,
    fieldName: candidateForDeleting.fieldName,
    deleteCustomField,
    closeDeleteDialog,
  };
};
