import React from 'react';

import { EmailVerification } from '@vyce/core/src/components/EmailVerificaion';

import { useActions } from '../../../hooks';

export const MainEmailVerification: React.FC = () => {
  const { me } = useActions();

  return <EmailVerification me={me} />;
};
