import { FilterSection } from '@vyce/core/src/types';

import { transformData } from './transformData';

const getLocationValues = (data: ReturnType<typeof transformData>) => {
  const ids: string[] = [];
  return data
    .filter(item => {
      if (ids.includes(item.site_id)) {
        return false;
      }
      ids.push(item.site_id);
      return true;
    })
    .map(timelog => ({ id: timelog.site_id, name: timelog.site_name }));
};

export const getTimeLogsFilter = (data: ReturnType<typeof transformData>): FilterSection[] => {
  return [
    {
      title: 'Time Logs',
      expanded: true,
      filters: [
        {
          type: 'select',
          multiple: false,
          label: 'Location',
          field: 'location',
          values: ['', ...getLocationValues(data)],
          defaultValue: '',
        },
      ],
    },
  ];
};
