import React, { useContext, useEffect, useState } from 'react';

import { ResponsiveLine } from '@nivo/line';
import { Box, Theme, Typography, useTheme } from '@material-ui/core';

import { DeviceContext, ColorThemeContext } from '@vyce/core/src/contexts';

import { CHART_COLORS, DARK_CHART_COLORS, useStyles } from '../styles';
import { NoWidgetData } from './NoWidgetData';

interface Props {
  data: any;
}

export const LineChart = ({ data }: Props) => {
  const theme = useTheme<Theme>();
  const classes = useStyles();
  const { colorTheme } = useContext(ColorThemeContext);
  const { isMobile } = useContext(DeviceContext);
  const [chartData, setChartData] = useState([]);
  const [isData, setIsData] = useState<boolean>(false);
  const isDarkMode = colorTheme === 'dark';

  useEffect(() => {
    let status = false;
    data.forEach((item: any) => {
      if (item.data?.length) {
        status = true;
      }
    });
    setIsData(status);
    const animation = setTimeout(() => setChartData(data), 300);

    return () => {
      clearTimeout(animation);
    };
  }, [data]);

  return (
    <>
      {isData ? (
        <ResponsiveLine
          data={chartData}
          margin={{ top: 20, right: 30, bottom: 80, left: 60 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 0,
            max: 'auto',
            stacked: true,
            reverse: false,
          }}
          theme={{
            background: theme.palette.background.paper,
            textColor: isDarkMode ? '#ffffff' : '#424242',
          }}
          colors={isDarkMode ? DARK_CHART_COLORS : CHART_COLORS}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          pointSize={8}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={-12}
          useMesh={true}
          isInteractive={true}
          tooltip={e => (
            <Box className={classes.popup}>
              <Typography variant="body2">{e.point.data.y.toString()}</Typography>
            </Box>
          )}
          axisBottom={{
            tickRotation: isMobile ? -45 : 0,
          }}
          legends={[
            {
              anchor: 'top-right',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: -24,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          animate={false}
        />
      ) : (
        <NoWidgetData />
      )}
    </>
  );
};
