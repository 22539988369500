import React from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { BoxWrapper } from './BoxWrapper';
import { ButtonTitleWithLoading } from '../../../../components/ButtonTitleWithLoading';
import { IsVerified } from '../../../../components';
import useStyles from '../../styles';

interface HealthRecordsProps {
  verified: boolean;
  answered: number;
  total: number;
  hasPrivilege: boolean;
  loading?: boolean;
  isShow: boolean;
  isHiring?: boolean;
  onClick?: () => Promise<void>;
}

const getHealthTitle = (answered: number) => {
  if (answered) return 'Medical questionnaire completed.';
  return 'Medical questionnaire not yet completed.';
};

const getButtonText = (answered: number, total: number) => {
  if (answered && total !== 0) return 'View records';
  return '';
};

export const HealthRecords = ({
  verified,
  answered,
  total,
  loading,
  isShow,
  hasPrivilege,
  isHiring = false,
  onClick,
}: HealthRecordsProps) => {
  const classes = useStyles();
  const title = getHealthTitle(answered);
  const buttonText = getButtonText(answered, total);

  if (!isShow) return null;

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Typography color={isHiring ? 'initial' : 'textSecondary'} variant="h6">
        Medical Records
      </Typography>
      <BoxWrapper withGrowing={false} smallBlock isSimpleContainer={isHiring}>
        <Box display="flex" flexDirection="column" gridGap={12} width="100%">
          <Box display="flex" justifyContent="space-between">
            <Typography variant="subtitle1" className={clsx({ [classes.smallBold]: !isHiring })}>
              {title}
            </Typography>
            {verified && <IsVerified verified />}
          </Box>
          {buttonText && hasPrivilege && (
            <Button
              variant="outlined"
              className={classes.customButton}
              size="small"
              fullWidth
              onClick={onClick}
              disabled={loading}>
              <ButtonTitleWithLoading title={buttonText} loaderVariant="primary" loading={!!loading} />
            </Button>
          )}
        </Box>
      </BoxWrapper>
    </Box>
  );
};
