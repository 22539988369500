import React, { useState } from 'react';

import { Box, Typography, useTheme } from '@material-ui/core';
import { BiIdCard } from 'react-icons/bi';
import clsx from 'clsx';

import { Qualifications } from '@vyce/core/src/modules';
import { IconStatusComponent } from '@vyce/core/src/components/Statuses/IconStatusComponent';
import { TextStatusComponent } from '@vyce/core/src/components/Statuses/TextStatusComponent';

import { QualificationType } from '../../types';
import useStyles from '../../styles';

interface Props {
  isHiring?: boolean;
  qualification: QualificationType;
}

export const QualificationItem: React.FC<Props> = ({ qualification, isHiring = false }) => {
  const { issuer, verified = false, source, title } = qualification;
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        onClick={handleOpen}
        alignItems="center"
        width="100%"
        display="flex"
        style={{ cursor: 'pointer' }}>
        <Box className={clsx(classes.iconContainer, { [classes.smallIconContainer]: isHiring })}>
          <BiIdCard size="22px" color={theme.palette.primary.main} />
        </Box>

        <Box marginLeft={2} display="flex" flexDirection="column" width="100%">
          <Typography
            variant="subtitle1"
            className={clsx(classes.bold, classes.ellipcedText, {
              [classes.smallBold]: isHiring,
              [classes.unverifiedText]: !(verified && source !== 'custom'),
            })}>
            {title}
          </Typography>

          <Typography
            variant="subtitle1"
            color="textSecondary"
            className={clsx(
              classes.ellipcedText,
              classes.smallBold,
              classes.smallText,
              classes.grayscaleBodyColor
            )}>
            {issuer}
          </Typography>

          <Box display="flex" alignItems="center" gridGap={8}>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              className={clsx(classes.smallBold, classes.smallText)}>
              Status:
            </Typography>

            <TextStatusComponent
              verified={verified}
              expiry_date={(qualification.type === 'certificate' && qualification.expiry_date) || null}
            />
          </Box>
        </Box>

        <Box className={classes.verifiedLabelWrapper}>
          {qualification.type === 'certificate' && (
            <IconStatusComponent
              verified={verified}
              expiry_date={qualification.expiry_date}
              withVerifiedLabel={false}
              withUnverifiedLabel={false}
              withExpiringLabel={false}
            />
          )}
        </Box>
      </Box>

      <Qualifications.QualificationPreviewDialog
        handleClose={handleClose}
        open={open}
        qualification={qualification}
      />
    </>
  );
};
