import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Box, Fade } from '@material-ui/core';

import { Logo } from '@vyce/core/src/components';

import { RolePicker } from './RolePicker';
import { WorkerQuestions } from './WorkerQuestions';

interface Props {
  onChangePassedByRoleSelection?: (value: boolean) => void;
}

export const RoleSelection = ({ onChangePassedByRoleSelection }: Props) => {
  const history = useHistory();
  const [clickByWorker, setClickByWorker] = useState(false);

  const handleGoToWrongPlacePage = () => {
    history.push('/auth/wrong-place');
  };

  const handleGoToLoginPage = () => {
    history.push('/auth/login');
  };

  const handleWorkerClick = () => {
    setClickByWorker(true);
  };

  const handleEmployerClick = () => {
    onChangePassedByRoleSelection && onChangePassedByRoleSelection(true);
  };

  return (
    <Fade in>
      <>
        <Box display="flex" justifyContent="center">
          <Logo height={48} width={171} />
        </Box>

        {!clickByWorker && (
          <RolePicker onEmployerClick={handleEmployerClick} onWorkerClick={handleWorkerClick} />
        )}
        {clickByWorker && (
          <WorkerQuestions
            onAffirmativeClick={handleGoToLoginPage}
            onNegativeClick={handleGoToWrongPlacePage}
          />
        )}
      </>
    </Fade>
  );
};
