import React, { useContext } from 'react';

import { Box, Button, Card, Grid, useTheme } from '@material-ui/core';
import { GridColDef } from '@mui/x-data-grid';
import { AiOutlinePlus } from 'react-icons/ai';
import { BiTransfer } from 'react-icons/bi';
import clsx from 'clsx';

import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { DeviceContext, TeamModuleContext, TeamsPageContext } from '@vyce/core/src/contexts';
import {
  AppA,
  AppDataGridWithSavedPage,
  AppFormWrapper,
  AppLink,
  ConfirmDialog,
  ImageUploader,
  GridCellWithAvatar,
} from '@vyce/core/src/components';
import { AppSearchInput } from '@vyce/core/src/components/inputs';
import { useHorizontalScrollStyles } from '@vyce/core/src/styles';
import { getAvatar } from '@vyce/core/src/utils/getAvatar';

import { CustomActionCell, TransferEmployeesDialog } from './components';
import { TeamNameField } from '../teams/components/TeamNameField';
import { TeamDescriptionField } from '../teams/components/TeamDescriptionField';
import { TeamPublicLinkDialog } from '../teams/components/TeamPublicLinkDialog';

const buttonPosition = { right: 24 };

interface Props {
  isLegend?: boolean;
}

export const TeamPage: React.FC<Props> = ({ isLegend = false }) => {
  const theme = useTheme();
  const horizontalScrollClasses = useHorizontalScrollStyles();

  const {
    open,
    logo,
    total,
    loading,
    sortModel,
    teamMembers,
    methods,
    teamData,
    pathname,
    setOpen,
    setLogo,
    setOpenDeleteTeamDialog,
    openDeleteTeamDialog,
    publicLink,
    isPublicLinkDialogOpen,
    setOffset,
    handleSearchChange,
    handleSortModelChange,
    handlePageChange,
    handleSubmit,
    deleteTeam,
    closePublicLinkDialog,
    getPublicLink,
  } = useContext(TeamModuleContext);
  const { isMobile } = useContext(DeviceContext);

  const { inviteButton, onOpenUniversalInviteForm } = useContext(TeamsPageContext);

  const { setValue } = methods;

  const columns: GridColDef[] = [
    {
      field: 'first_name',
      headerName: 'Name',
      flex: 2,
      minWidth: 150,
      disableColumnMenu: true,
      renderCell: params => (
        <GridCellWithAvatar
          link={`${pathname}/${params.row?.first_name}_${params.row?.uuid || params.row?.user_id}`}
          avatarUrl={params.row.photo || params.row.avatar}
          avatarPlaceholder={getAvatar(params.row.gender)}
          name={`${params.row.first_name} ${params.row.last_name}`}
        />
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 2,
      minWidth: 240,
      renderCell: params => <AppA content={params.row.email} href={`mailto: ${params.row.email}`} />,
    },
    {
      field: 'phone',
      headerName: 'Phone Number',
      minWidth: 160,
      renderCell: params => <AppA content={params.row.phone} href={`tel: ${params.row.phone}`} />,
    },
    {
      field: '',
      headerName: 'Actions',
      width: 80,
      hideSortIcons: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => <CustomActionCell params={params} />,
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={9}>
          <Box
            gridGap={16}
            mb={2}
            className={clsx(
              horizontalScrollClasses.blockWrapper,
              horizontalScrollClasses.blockWithHideScroll
            )}>
            <Box>
              <AppSearchInput onChange={handleSearchChange} isSmall expanded={!isMobile} />
            </Box>

            <Box display="flex" gridGap={16}>
              <Button
                color="secondary"
                size="small"
                cy-test-id="add-existing-users-to-team-button"
                onClick={() => setOpen(true)}
                startIcon={<BiTransfer />}
                variant="contained">
                Add existing Vyce users
              </Button>

              {inviteButton ? (
                React.createElement(inviteButton)
              ) : (
                <Button
                  onClick={onOpenUniversalInviteForm}
                  size="small"
                  data-tour="invite"
                  startIcon={<AiOutlinePlus />}
                  variant="contained"
                  color="primary">
                  Invite New Members
                </Button>
              )}
            </Box>
          </Box>

          <AppDataGridWithSavedPage
            noPaper
            rows={teamMembers}
            getRowId={row => row.user_id}
            loading={loading}
            columns={columns}
            rowCount={total}
            paginationMode="server"
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            onPageChange={handlePageChange}
            pageSize={GRID_PAGE_SIZE}
            rowsPerPageOptions={[GRID_PAGE_SIZE]}
            disableSelectionOnClick
            unit="team members"
            setOffset={setOffset}
          />
        </Grid>

        <Grid item xs={12} lg={3}>
          <Box position="relative" display="flex" flexDirection="column" gridGap={16}>
            <Card style={{ padding: 24 }} variant="outlined">
              <AppFormWrapper
                methods={methods}
                initialData={teamData}
                handleSubmit={handleSubmit}
                loading={loading}>
                <>
                  <Box marginBottom={1} marginTop={2}>
                    <ImageUploader
                      width={170}
                      height={120}
                      image={logo}
                      isDocument={true}
                      onImageUpload={image => {
                        setLogo(image);
                        setValue('logo', image);
                      }}
                      extraText="team picture"
                      buttonPosition={buttonPosition}
                    />
                  </Box>

                  <TeamNameField />

                  <TeamDescriptionField />

                  {isLegend && getPublicLink && (
                    <Button
                      style={{ marginTop: 16 }}
                      fullWidth
                      variant="outlined"
                      color="primary"
                      onClick={() => getPublicLink()}>
                      Get invite public link
                    </Button>
                  )}

                  <Button
                    size="small"
                    fullWidth
                    cy-test-id="delete-team-button"
                    onClick={() => setOpenDeleteTeamDialog(true)}
                    style={{ color: theme.palette.error.main, marginTop: 16 }}>
                    Delete Team
                  </Button>
                </>
              </AppFormWrapper>
            </Card>
            <Box display="flex" justifyContent="center">
              <AppLink to={`${pathname}/sync_settings`}>Team sync settings</AppLink>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {teamData?.uuid && <TransferEmployeesDialog open={open} setOpen={setOpen} />}

      <ConfirmDialog
        handleClose={() => setOpenDeleteTeamDialog(false)}
        open={openDeleteTeamDialog}
        confirmText="Delete"
        cancelText="Cancel"
        title={`Are you sure you want to delete ${teamData?.name} team?`}
        handleConfirm={deleteTeam}
        loading={loading}
      />

      {publicLink && closePublicLinkDialog && (
        <TeamPublicLinkDialog
          link={publicLink}
          open={!!isPublicLinkDialogOpen}
          handleClose={closePublicLinkDialog}
        />
      )}
    </>
  );
};
