import { useContext } from 'react';

import { TimeModuleContext } from '@vyce/core/src/contexts';

import { useTypedSelector } from 'src/hooks';

export const useModuleData = () => {
  const { selectedCompany } = useTypedSelector(state => state.helper);
  const { locations } = useContext(TimeModuleContext);

  return {
    selectedCompanyId: selectedCompany?.uuid,
    locations,
  };
};
