import React, { useContext, useEffect, useRef, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles, useTheme, Typography, Breadcrumbs } from '@material-ui/core';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { Theme } from '@material-ui/core/styles';

import { Breadcrumb } from '../types';
import { DeviceContext } from '../contexts/deviceContext';
import { AppIconButton } from './AppIconButton';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: '13px',
      cursor: 'pointer',
    },
    capitalize: {
      textTransform: 'capitalize',
    },
  })
);

const urlsWithoutBreadcrumbs = ['/auth/verify_email', '/onboarding', '/invites', '/staff/invite'];
const customNames = [{ url: 'vyce-ai', name: 'Vyce AI' }];

export const AppBreadcrumbs: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const { isDesktop } = useContext(DeviceContext);
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
  const [showMobileBackButton, setShowMObileBackButton] = useState<boolean>(false);
  const mountedRef = useRef(true);

  const onBreadcrumbClick = (breadcrumb: Breadcrumb) => {
    history.push(breadcrumb.route as string);
  };

  const skipBreadcrumbs = (pathname: string): boolean => {
    let skip = false;
    urlsWithoutBreadcrumbs.forEach(url => {
      if (pathname.includes(url)) {
        skip = true;
      }
    });
    return skip;
  };

  const onBackButtonClick = () => {
    const pathname = history.location.pathname;
    const pathnameArr = pathname.split('/');
    pathnameArr.pop();
    history.push(pathnameArr.join('/'));
  };

  useEffect(() => {
    if (!mountedRef.current) return;
    const appPath = window.location.href.split('#')[1];
    updateBreadcrumbs(appPath);
    history.listen(location => {
      if (!mountedRef.current) return;
      const pathname = location.pathname;
      updateBreadcrumbs(pathname);
    });

    return () => {
      mountedRef.current = false;
    };
  }, []);

  const updateBreadcrumbs = (pathname: string) => {
    const skip = skipBreadcrumbs(pathname);
    setShowMObileBackButton(!skip);
    if (skip) {
      return;
    }
    const urlItems = pathname
      .split('?')[0]
      ?.split('/')
      .filter(i => i);
    const breadcrumbs: Breadcrumb[] = urlItems.map((item, index) => {
      const urlItemsCopy = [...urlItems];
      urlItemsCopy.splice(index + 1);
      return {
        name: item?.split('_')[0],
        route: '/' + urlItemsCopy.join('/'),
        level: index,
      };
    });
    setBreadcrumbs(breadcrumbs);
  };

  const formatName = (name: string) => {
    const customName = customNames.find(item => item.url === name);
    if (customName) {
      return customName.name;
    }
    return name?.replace(/%20/g, ' ');
  };

  return (
    <>
      {isDesktop ? (
        <Breadcrumbs classes={{ root: classes.root }} aria-label="breadcrumb">
          {breadcrumbs.map((breadcrumb, index) => {
            return index + 1 === breadcrumbs.length ? (
              <Typography className={classes.capitalize} key={breadcrumb.name}>
                {formatName(breadcrumb.name)}
              </Typography>
            ) : (
              <Typography
                className={classes.capitalize}
                key={breadcrumb.name}
                onClick={() => onBreadcrumbClick(breadcrumb)}>
                {formatName(breadcrumb.name)}
              </Typography>
            );
          })}
        </Breadcrumbs>
      ) : (
        <>
          {showMobileBackButton && (
            <AppIconButton
              variant="paper"
              disableFocusRipple
              disableRipple
              disableTouchRipple
              onClick={onBackButtonClick}>
              <HiOutlineArrowLeft color={theme.palette.text.secondary} />
            </AppIconButton>
          )}
        </>
      )}
    </>
  );
};
