import React from 'react';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Controller, useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';

import { AppKeyboardTimePicker } from '../inputs';
import { FieldProps } from '../../types';
import { shiftTimeStringToDate } from '../../utils/dates';

export const ShiftTime: React.FC<FieldProps> = ({ name = '', label, margin, disabled }) => {
  const { control } = useFormContext();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <AppKeyboardTimePicker
            {...field}
            disabled={disabled}
            minutesStep={15}
            mask="__:__"
            value={
              field.value
                ? typeof field.value === 'string'
                  ? shiftTimeStringToDate(field.value)
                  : field.value
                : null
            }
            onChange={date => field.onChange(date ? dayjs(date).format('HH:mm') : null)}
            margin={margin}
            ampm={false}
            label={label}
            fullWidth
          />
        )}
      />
    </MuiPickersUtilsProvider>
  );
};
