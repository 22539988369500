import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { monochrome } from '../../theme/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    cardDialog: {
      minWidth: '550px',
      [theme.breakpoints.down('xs')]: {
        minWidth: 'auto',
      },
    },

    customModeButton: {
      '& .MuiButton-label': {
        textDecoration: 'underline',
      },
    },

    removeButton: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      top: -12,
      right: -12,
      padding: 8,
      border: `1px solid ${theme.palette.type === 'dark' ? monochrome.dark : monochrome.mediumlight}`,
      borderRadius: 12,
      backgroundColor: theme.palette.type === 'dark' ? monochrome.darker : '#fff',
      cursor: 'pointer',
      color: theme.palette.error.main,
    },

    documentsPreviewWrapper: {
      display: 'grid',
      width: '100%',
      gridTemplateColumns: 'repeat(auto-fill, 120px)',
      gridTemplateRows: 'repeat(auto-fill, 120px)',
      gridGap: '16px',
    },
    confirmButton: {
      width: '202px',
    },
  })
);
