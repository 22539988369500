import React from 'react';

import { Marker, MarkerProps, Tooltip } from 'react-leaflet';
import L from 'leaflet';
import { Typography } from '@material-ui/core';

import meSVG from '../../assets/svg/me.svg';
import { tooltipUseStyles } from './TimeModuleMarker';

const meIcon = new L.Icon({
  iconUrl: meSVG,
  iconRetinaUrl: meSVG,
  popupAnchor: [-0, -0],
  iconSize: [55, 55],
});

interface Props extends MarkerProps {
  picture?: string;
  isMe?: boolean;
  selected?: boolean;
  onMarkerClick?: Function;
  popupText?: string;
  size?: number;
}

export const AppMarker: React.FC<Props> = ({
  picture,
  isMe,
  selected,
  onMarkerClick,
  popupText,
  position,
  draggable,
  size = 80,
}) => {
  const styles = tooltipUseStyles();
  const pictureIcon = new L.Icon({
    iconUrl: picture,
    iconRetinaUrl: picture,
    popupAnchor: [-0, -0],
    iconSize: [size, size],
  });
  const selectedMarkerIcon = new L.Icon({
    iconUrl: picture,
    iconRetinaUrl: picture,
    popupAnchor: [-0, -0],
    iconSize: [60, 60],
  });

  const handleMarkerClick = (e: L.LeafletMouseEvent) => {
    if (onMarkerClick) {
      onMarkerClick();
    }
  };

  const handleMouseOver = (e: L.LeafletMouseEvent) => {
    e.target.openPopup();
  };

  return (
    <div>
      <Marker
        position={position}
        draggable={draggable}
        icon={isMe ? meIcon : selected ? selectedMarkerIcon : pictureIcon}
        eventHandlers={{
          click: handleMarkerClick,
          mouseover: handleMouseOver,
        }}>
        {popupText && (
          <Tooltip direction="top" className={styles.tooltip}>
            <Typography variant="subtitle2" color="primary">
              {popupText}
            </Typography>
          </Tooltip>
        )}
      </Marker>
    </div>
  );
};
