import React, { useContext } from 'react';

import debounce from 'lodash/debounce';
import { useFormContext } from 'react-hook-form';

import { OnboardingStepTitle } from '@vyce/core/src/components/OnboardingStepTitle';
import { ControlledAutocomplete } from '@vyce/core/src/components/controlled-inputs/ControlledAutocomplete';

import { ManualClockContext } from './ManualClockProvider';

const rules = {
  required: 'Name is required',
};
const name = 'selectedWorker';

export const WorkerStep = () => {
  const { workers, action, nextStep } = useContext(ManualClockContext);
  const { watch } = useFormContext();

  const selectedWorker = watch(name);

  const options = workers?.map(item => ({ name: `${item.first_name} ${item.last_name}`, id: item.user_id }));

  const handleChange = (value: string) => {
    if (!selectedWorker) {
      nextStep(action === 'in' ? 3 : 2);
    }
  };
  return (
    <>
      <OnboardingStepTitle text={`Who do you want to manually clock ${action}?`} />

      <ControlledAutocomplete
        name={name}
        label="Select name"
        rules={rules}
        margin="normal"
        items={options}
        onChange={debounce(handleChange, 500)}
      />
    </>
  );
};
