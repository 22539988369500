import { useContext, useState } from 'react';

import { Company, CompanyPhoto } from '../../../types';
import { useBooleanState } from '../../../hooks';
import { CreateCompanyPhotoData } from '../../../api/types';
import { createPhotoRequest, deletePhotosRequest, updatePhotoRequest } from '../../../api/companies';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  company?: Company;
  getCompanyData: Function;
  token: string;
}

export const useCompanyPhoto = ({ company, getCompanyData, token }: Props) => {
  const [isSelectMode, setIsSelectMode] = useState<boolean>(false);
  const [selectedPhotos, setSelectedPhotos] = useState<CompanyPhoto[]>([]);
  const [photoToEdit, setPhotoToEdit] = useState<CompanyPhoto>();
  const [isAddOrEditDialogOpen, openAddOrEditDialog, closeAddOrEditDialog] = useBooleanState(false);
  const { handleServerError } = useContext(NotificationContext);

  const createPhoto = async (data: CreateCompanyPhotoData) => {
    if (!company?.uuid) {
      return;
    }

    try {
      await createPhotoRequest(token, company.uuid, data);
      getCompanyData();
      closeAddOrEditDialog();
    } catch (e) {
      handleServerError(e);
    }
  };

  const updatePhoto = async (data: CreateCompanyPhotoData) => {
    if (!company?.uuid || !photoToEdit?.uuid) {
      return;
    }

    try {
      await updatePhotoRequest(token, company.uuid, photoToEdit.uuid, data);
      getCompanyData();
      closeAddOrEditDialog();
    } catch (e) {
      handleServerError(e);
    }
  };

  const deletePhotos = async (ids: string[]) => {
    if (!company?.uuid) {
      return;
    }

    try {
      await deletePhotosRequest(token, company.uuid, ids);
    } catch (e) {
      handleServerError(e);
    }
  };

  const handleSelectButtonClick = async () => {
    if (isSelectMode) {
      setSelectedPhotos([]);
      await deletePhotos(selectedPhotos.map(photo => photo.uuid));
      getCompanyData();
    }
    setIsSelectMode(value => !value);
  };

  const handlePhotoSelect = (photo: CompanyPhoto) => {
    if (!isSelectMode) return;

    setSelectedPhotos(value => {
      if (value.find(item => item.uuid === photo.uuid)) {
        return value.filter(item => item.uuid !== photo.uuid);
      } else {
        return [...value, photo];
      }
    });
  };

  const handleDeleteClick = async (uuid: string) => {
    await deletePhotos([uuid]);
    getCompanyData();
  };

  const handleEditClick = (photo: CompanyPhoto) => {
    setPhotoToEdit(photo);
    openAddOrEditDialog();
  };

  const handleAddOrEditDialogClose = () => {
    setPhotoToEdit(undefined);
    closeAddOrEditDialog();
  };

  return {
    isAddOrEditDialogOpen,
    openAddOrEditDialog,
    isSelectMode,
    handleSelectButtonClick,
    handleEditClick,
    handleDeleteClick,
    handlePhotoSelect,
    selectedPhotos,
    updatePhoto,
    createPhoto,
    photoToEdit,
    handleAddOrEditDialogClose,
  };
};
