import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { monochrome } from '../../theme/styles';

export const leftInfoDrawerWidth = 440;
const headerHeight = 65;
const bottomButtonHeight = 70;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tilePaper: {
      padding: theme.spacing(2),
    },
    clickableItem: {
      transition: 'all .2s ease-in-out',
      '&:hover': {
        transform: 'scale(1.02)',
      },
      cursor: 'pointer',
    },
    avatar: {
      width: '50px',
      height: '50px',
    },
    bold: {
      fontWeight: 600,
    },
    jobIcon: {
      marginRight: theme.spacing(1),
    },
    companyName: {
      fontWeight: 600,
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
    capitalize: {
      textTransform: 'capitalize',
    },
    jobDataItem: {
      marginTop: `${theme.spacing(2)}px !important`,
    },
    loadMoreButton: {
      width: 200,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    selected: {
      border: `1px solid ${theme.palette.primary.main} !important`,
    },
    chip: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
    },
    greyChip: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.secondary,
    },
    dualBorder: {
      borderBottom: `2px solid ${theme.palette.divider}`,
      borderTop: `2px solid ${theme.palette.divider}`,
    },
    borderTop: {
      borderTop: `2px solid ${theme.palette.divider}`,
    },
    borderBottom: {
      borderBottom: `2px solid ${theme.palette.divider}`,
    },
    controlArea: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
      borderRadius: '12px',
      marginTop: theme.spacing(2),
    },
    drawer: {
      width: leftInfoDrawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: leftInfoDrawerWidth,
      marginTop: headerHeight,
      paddingBottom: headerHeight + bottomButtonHeight,
      [theme.breakpoints.down('sm')]: {
        width: '100vw',
        marginTop: 0,
        paddingBottom: bottomButtonHeight,
      },
    },
    content: {
      flexGrow: 1,
      overflowX: 'hidden',
      height: '100%',
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: 0,
      padding: `0 40px 24px 40px`,
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        minHeight: '100%',
        padding: `0 8px`,
        height: 'auto',
      },
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: leftInfoDrawerWidth,
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
      },
    },
    searchDialog: {
      marginBottom: 0,
      [theme.breakpoints.down('xs')]: {
        marginBottom: '70%',
      },
    },
    searchButton: {
      width: 55,
      height: 55,
      color: theme.palette.text.primary,
      borderRadius: '12px',
      boxShadow: '2px 4px 12px rgba(20, 20, 43, 0.04)',
    },
    textWithIcon: {
      display: 'flex',
      alignItems: 'center',
    },
    ellipsisOverflow: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    iconWithText: {
      marginRight: '4px',
      width: '15px',
      display: 'flex',
      alignItems: 'center',
    },
    closeButton: {
      color: theme.palette.error.main,
      height: 40,
      marginLeft: theme.spacing(1),
    },
    candidatesButton: {
      height: 40,
      marginRight: theme.spacing(1),
    },
    bottomButtonContainer: {
      position: 'fixed',
      display: 'flex',
      bottom: 0,
      backgroundColor: theme.palette.background.paper,
      width: leftInfoDrawerWidth,
      [theme.breakpoints.down('sm')]: {
        width: '100vw',
      },
    },
    pageHeader: {
      backgroundColor: theme.palette.background.paper,
      minHeight: 160,
      margin: -theme.spacing(3),
      marginRight: -theme.spacing(5),
      marginLeft: -theme.spacing(5),
      boxShadow: '2px 4px 12px rgba(20, 20, 43, 0.04)',
      [theme.breakpoints.down('sm')]: {
        margin: -theme.spacing(3),
      },
    },
    addButton: {
      width: 55,
      height: 55,
    },
    mapButton: {
      width: 55,
      height: 55,
    },
    redButton: {
      color: `${theme.palette.error.main} !important`,
    },
    disabledButton: {
      backgroundColor: `${theme.palette.secondary.main} !important`,
    },
    warningButton: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.type === 'dark' ? '#000' : '#fff',
    },
    acceptButton: {
      backgroundColor: `${theme.palette.success.main} !important`,
      marginLeft: theme.spacing(0.5),
      color: theme.palette.type === 'dark' ? '#000 !important' : '#fff !important',
      '&:hover': {
        backgroundColor: theme.palette.success.dark,
      },
    },
    connectedButton: {
      backgroundColor: theme.palette.success.main + ' !important',
    },
    filterButton: {
      height: 40,
      minWidth: 'auto !important',
      borderRadius: '8px !important',
      color: theme.palette.type === 'dark' ? monochrome.mediumlight : monochrome.dark + ' !important',
      fontWeight: 600,
      backgroundColor: theme.palette.background.paper + ' !important',
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
        boxShadow:
          '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
      },
      transition: theme.transitions.create('width') + ' !important',
      padding: '4px 16px !important',
      border: '1px solid transparent',
    },
    selectButton: {
      width: 102,
    },
    selectedButton: {
      width: '166px !important',
      color: theme.palette.primary.main + ' !important',
      border: `1px solid ${theme.palette.primary.main} !important`,
    },
    sortMenuItem: {
      minWidth: 108,
    },
    flexContentWrapper: {
      flex: '1 0 auto',
      display: 'flex',
    },
    moduleName: {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    noJobsContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(6),
      flexDirection: 'column',
    },
    noJobsTitle: {
      textAlign: 'center',
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(2),
    },
    createJobContent: {
      padding: theme.spacing(5),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(11),
      },
    },
    dialogTitle: {
      marginLeft: theme.spacing(2),
      fontWeight: 500,
      flex: 1,
    },
    dialogContainer: {
      backgroundColor: theme.palette.background.default,
    },
    checkboxLabel: {
      fontSize: '14px',
    },
    overPaymentCheckbox: {
      marginTop: '15px',
    },
    buttonGrid: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        position: 'fixed',
        padding: theme.spacing(1),
        bottom: 0,
        left: 0,
        backgroundColor: theme.palette.background.default,
        zIndex: theme.zIndex.mobileStepper,
      },
    },
    jobsListContainer: {
      paddingTop: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        paddingBottom: theme.spacing(7),
      },
    },
    headerTitle: {
      ...theme.typography.button,
    },
    contentIcon: {
      marginRight: theme.spacing(1),
    },
    listItemIcon: {
      minWidth: '36px',
    },
    createJobButton: {
      height: '36px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    jobCard: {
      cursor: 'pointer',
      transition: 'all .2s ease-in-out',
      '&:hover': {
        transform: 'scale(1.02)',
      },
    },
    companyInfoGrid: {
      [theme.breakpoints.down('xs')]: {
        order: 1,
      },
    },
    jobInfoGrid: {
      [theme.breakpoints.down('xs')]: {
        order: 2,
      },
    },
    companyAvatar: {
      height: '80px',
      width: '80px',
      marginBottom: theme.spacing(2),
    },
    companyPaper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    applyButton: {
      marginTop: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
      },
    },
    jobInfoBlock: {
      marginBottom: theme.spacing(2),
    },
    jobInfoSubtitle: {
      marginBottom: theme.spacing(1),
    },
    jobCardHeader: {
      padding: theme.spacing(2),
    },
    jobCardMenuIcon: {
      height: '45px',
      width: '45px',
      margin: '-8px -8px 0 0',
    },
    jobPageContainer: {
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(5),
      },
    },
    budgetType: {
      textAlign: 'end',
    },
    cardActions: {
      padding: `${theme.spacing(2)}px !important`,
    },
    tabsWrapper: {
      backgroundColor: theme.palette.background.paper,
      [theme.breakpoints.down('md')]: {
        marginLeft: '-16px',
        marginRight: '-16px',
      },
    },
  })
);
