import React, { ReactNode } from 'react';

import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { GiPlainCircle } from 'react-icons/gi';
import { IoCheckbox, IoSquare } from 'react-icons/io5';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { Theme, useTheme } from '@material-ui/core';

import { monochrome } from '../../theme/styles';

interface Props extends CheckboxProps {
  variant?: 'circle' | 'rectangle';
  icon?: ReactNode;
  checkedIcon?: ReactNode;
}

export const AppCheckbox = React.forwardRef((props: Props, ref) => {
  const theme = useTheme<Theme>();
  const { variant = 'circle', icon, checkedIcon } = props;
  const iconColor = theme.palette.type === 'dark' ? monochrome.mediumdark : monochrome.mediumlight;

  return (
    <Checkbox
      innerRef={ref}
      icon={
        icon || variant === 'rectangle' ? (
          <IoSquare color={iconColor} size="16px" />
        ) : (
          <GiPlainCircle color={iconColor} />
        )
      }
      checkedIcon={
        checkedIcon || variant === 'rectangle' ? <IoCheckbox size="16px" /> : <IoIosCheckmarkCircle />
      }
      {...props}
    />
  );
});
