import React, { useMemo } from 'react';

import { Box, Button, CircularProgress, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import { BiHide, BiShow } from 'react-icons/bi';
import capitalize from 'lodash/capitalize';

import { useStyles } from './styles';
import { useBillingInfoData } from '../../modules/subscriptionModule/hooks/useBillingInfoData';
import { InfoRow, VerifyYourIdentityDialog } from '../../components';
import { Company, User } from '../../types';

interface Props {
  selectedCompany?: Company;
  token: string;
  user?: User;
  emailToVerifyIdentity?: string;
}

export const BillingInfo: React.FC<Props> = ({ token, user, selectedCompany, emailToVerifyIdentity }) => {
  const classes = useStyles();

  const {
    nextPaymentDate,
    isLoginDialogOpen,
    selectedCompanyName,
    billingContactOptions,
    paymentDetails,
    handleNext,
    closeLoginDialog,
    handleOpenLoginForBillingEdit,
    handleOpenLoginForPaymentEdit,
    gettingDecryptedCard,
    handleShowCardDetailsClick,
    decryptedDetails,
    getCountryName,
  } = useBillingInfoData({ user, token, selectedCompany });

  const cardDetails = useMemo(() => decryptedDetails || paymentDetails, [decryptedDetails, paymentDetails]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} lg={8}>
          <Paper className={classes.paper} variant="outlined">
            <Typography variant="h6">General Info</Typography>

            <InfoRow title="Selected Company" value={selectedCompanyName} />
            {nextPaymentDate && <InfoRow title="Next payment date" value={nextPaymentDate} />}
            <InfoRow title="Country" value="United Kingdom" />
          </Paper>

          <Paper style={{ marginTop: 24 }} className={classes.paper} variant="outlined">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">Payment Details</Typography>

              <Box display="flex" gridGap={24} alignItems="center">
                <IconButton
                  disabled={gettingDecryptedCard}
                  aria-label="toggle card details visibility"
                  onClick={handleShowCardDetailsClick}
                  edge="end">
                  {gettingDecryptedCard ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : decryptedDetails ? (
                    <BiShow />
                  ) : (
                    <BiHide />
                  )}
                </IconButton>

                <Button
                  onClick={handleOpenLoginForPaymentEdit}
                  color="primary"
                  size="small"
                  disabled={!cardDetails}
                  style={{ width: 180 }}
                  variant="contained">
                  Edit details
                </Button>
              </Box>
            </Box>

            <InfoRow title="Name on card" value={cardDetails?.name_on_card} />
            <InfoRow title="Credit card number" value={cardDetails?.number} />
            <InfoRow
              title="Expiry date"
              value={cardDetails?.exp_month ? `${cardDetails?.exp_month}/${cardDetails?.exp_year}` : ''}
            />
            <InfoRow title="Card type" value={capitalize(cardDetails?.brand)} />

            <Box marginTop={4}>
              <Typography variant="h6">Billing Address</Typography>

              <InfoRow title="Address" value={cardDetails?.address?.line1} />
              <InfoRow title="Apartment, Suite, etc" value={cardDetails?.address?.line2} />
              <InfoRow title="City" value={cardDetails?.address?.city} />
              <InfoRow title="Country, Region" value={cardDetails?.address?.state} />
              <InfoRow title="ZIP / Postal Code" value={cardDetails?.address?.postal_code} />
              <InfoRow title="Country" value={getCountryName(cardDetails?.address?.country)} />
            </Box>
          </Paper>

          <Paper style={{ marginTop: 24 }} className={classes.paper} variant="outlined">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">Billing Contact</Typography>

              <Button
                onClick={handleOpenLoginForBillingEdit}
                color="primary"
                size="small"
                disabled={!cardDetails}
                style={{ width: 180 }}
                variant="contained">
                Edit details
              </Button>
            </Box>
            {billingContactOptions.map(item => (
              <InfoRow key={item.title} {...item} />
            ))}
          </Paper>
        </Grid>
      </Grid>

      {emailToVerifyIdentity && (
        <VerifyYourIdentityDialog
          email={emailToVerifyIdentity}
          open={isLoginDialogOpen}
          handleClose={closeLoginDialog}
          handleNext={handleNext}
        />
      )}
    </>
  );
};
