import { Box, Theme, IconButton, useTheme } from '@material-ui/core';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import clsx from 'clsx';

import { monochrome } from '@vyce/core/src/theme/styles';

import { useStyles } from '../styles';

interface Props {
  showDecreaseButton: boolean;
  showIncreaseButton: boolean;
  decreasePage: () => void;
  increasePage: () => void;
}
export const PageButtons = ({
  showDecreaseButton,
  showIncreaseButton,
  increasePage,
  decreasePage,
}: Props) => {
  const theme = useTheme<Theme>();
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" justifyContent="center" gridGap={16}>
      <IconButton
        onClick={decreasePage}
        disabled={!showDecreaseButton}
        className={clsx(classes.pageIconButton, {
          [classes.pageIconButtonDisabled]: !showDecreaseButton,
        })}>
        <FiChevronLeft
          size="20px"
          color={theme.palette.type === 'dark' ? monochrome.lighter : monochrome.dark}
        />
      </IconButton>
      <IconButton
        onClick={increasePage}
        disabled={!showIncreaseButton}
        className={clsx(classes.pageIconButton, {
          [classes.pageIconButtonDisabled]: !showIncreaseButton,
        })}>
        <FiChevronRight
          size="20px"
          color={theme.palette.type === 'dark' ? monochrome.lighter : monochrome.dark}
        />
      </IconButton>
    </Box>
  );
};
