import React, { useState } from 'react';

import { Box } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import Button from '@material-ui/core/Button';

import { ScheduleType } from '../../../../modules/timeModule/constants';
import { OnboardingStepTitle } from '../../../../components';
import { DefaultRateField } from '../../../../components/controlled-inputs';

interface Props {
  type: ScheduleType;
}

export const DefaultRateStep: React.FC<Props> = ({ type }) => {
  const isRegular = type === ScheduleType.REGULAR;
  const rateFieldName = isRegular ? 'basic_amount' : 'overtime_amount';
  const rateName = `shift.${rateFieldName}`;
  const title = `Would you like to set default rates for all workers associated to this ${type} shift? If you do, every worker assigned to this shift will be assigned the default rates. You can customise each individuals rate at any time.`;
  const { setValue } = useFormContext();
  const [disabled, setDisabled] = useState<boolean>(false);

  const handleClick = () => {
    setDisabled(value => !value);
    setValue(rateName, '');
  };

  return (
    <>
      <OnboardingStepTitle text={title} />

      <DefaultRateField
        disabled={disabled}
        margin="normal"
        name={rateName}
        label={`Default ${type} hourly rate (£)`}
      />

      <Box display="flex" justifyContent="flex-end" marginTop={2}>
        <Button
          size="small"
          color="primary"
          style={{ width: 200 }}
          onClick={handleClick}
          variant={disabled ? 'contained' : 'outlined'}>
          No {type} default rates
        </Button>
      </Box>
    </>
  );
};
