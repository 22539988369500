import { TeamsSyncSettingsDTO } from '@vyce/core/src/api/types';

export const getTransformedData = (data: TeamsSyncSettingsDTO) => {
  return {
    active: data.active,
    isTimeSync: !!data.recent_time_periods,
    isPaySync: !!data.recent_pay_periods,
    recent_time_periods: data.recent_time_periods || 1,
    recent_pay_periods: data.recent_pay_periods || 1,
  };
};
