import React, { useContext, useState } from 'react';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import capitalize from 'lodash/capitalize';

import { DeviceContext } from '../../../contexts';
import { PayTagGroup } from '../../../types';
import { MAIN_CONTAINER_ID } from '../../../constants';
import { AppTextField } from '../../../components/inputs';
import { createPayScheduleTagRequest } from '../../../api/pay-schedules';
import { ButtonTitleWithLoading } from '../../../components/ButtonTitleWithLoading';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  open: boolean;
  handleClose: () => void;
  group: PayTagGroup;
  payScheduleId: string;
  companyId: string;
  getTags: Function;
}

export const AddPayTagDialog: React.FC<Props> = ({
  open,
  handleClose,
  group,
  payScheduleId,
  companyId,
  getTags,
}) => {
  const { handleServerError } = useContext(NotificationContext);
  const { isMobile } = useContext(DeviceContext);
  const [name, setName] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const createPayTag = async () => {
    try {
      setLoading(true);
      await createPayScheduleTagRequest({
        payScheduleId,
        name,
        group,
        companyId,
        verified: true,
        byStaff: true,
      });
      getTags();
      setLoading(false);
      handleClose();
      setName('');
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  };

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">Add starter {capitalize(group)}</DialogTitle>

      <DialogContent>
        <Box minWidth={isMobile ? 'auto' : 400}>
          <AppTextField label="Name" value={name} onChange={e => setName(e.target.value)} fullWidth />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button style={{ width: 106 }} size="large" variant="outlined" onClick={handleClose}>
          Close
        </Button>

        <Button
          style={{ width: 106 }}
          size="large"
          color="primary"
          onClick={createPayTag}
          variant="contained">
          <ButtonTitleWithLoading title="Add" loaderVariant="paper" loading={loading} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
