import { useRef, useContext } from 'react';

import { Box, TextField } from '@material-ui/core';
import { RxHamburgerMenu } from 'react-icons/rx';
import { RiCloseFill } from 'react-icons/ri';
import { useDrop, useDrag } from 'react-dnd';

import { ItemTypes } from '../config';
import { DragItem } from '../types';
import { useStyles } from '../styles';
import { CustomDetailsFieldsContext } from './CustomDetailsFieldsContext';

export const Field = ({ id, name = '' }: DragItem) => {
  const classes = useStyles();
  const { moveCard, findCard, handleUpdateFields, setNewCandidateForDeleting, changeCustomFieldName } =
    useContext(CustomDetailsFieldsContext);
  const originalIndex = findCard(id).index;

  const ref = useRef<HTMLDivElement>(null);

  const handleDeleteField = () => {
    setNewCandidateForDeleting(id);
  };

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.FIELD,
      item: { id, originalIndex },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveCard(droppedId, originalIndex);
        } else {
          handleUpdateFields('reorder');
        }
      },
    }),
    [id, originalIndex, moveCard]
  );

  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.FIELD,
      hover({ id: draggedId }: DragItem) {
        if (draggedId !== id) {
          const { index: overIndex } = findCard(id);
          moveCard(draggedId, overIndex);
        }
      },
    }),
    [findCard, moveCard]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (name !== event.target.value) changeCustomFieldName(id, event.target.value);
  };

  const handleBlur = () => {
    handleUpdateFields('field_change');
  };

  drag(drop(ref));

  const opacity = isDragging ? 0 : 1;

  return (
    <div ref={ref} style={{ opacity }}>
      <Box display="flex" maxWidth="60%" gridGap="24px">
        <Box display="flex" alignItems="center" gridGap={8} width="100%">
          <RxHamburgerMenu />
          <TextField
            id="standard-basic"
            className={classes.customInput}
            label=""
            placeholder="Name of field"
            color="primary"
            defaultValue={name}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Box>
        <Box onClick={handleDeleteField} className={classes.deleteButton}>
          <RiCloseFill size="24px" color="#A0A3BD" />
        </Box>
      </Box>
    </div>
  );
};
