import React from 'react';

import { useParams } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';

import config from '@vyce/core/src/assets/config';
import { AuthLayout } from '@vyce/core/src/views/auth/AuthLayout';
import { ForgotPassword } from '@vyce/core/src/views/auth/components/ForgotPassword';
import { LogoIcon } from '@vyce/core/src/components/LogoIcon';

import { useActions } from '../../../hooks';

export const ResetPasswordAndLogin: React.FC = () => {
  const { userLogin } = useActions();
  const { email, firstName } = useParams<{ email: string; firstName: string }>();

  return (
    <AuthLayout rightPictureSrc={config.auth.employee_reset_password}>
      <>
        <LogoIcon height={60} width={60} />

        <Box marginBottom={4} marginTop={4}>
          {!!firstName && (
            <Typography style={{ fontWeight: 700 }} variant="h6">
              Hi {firstName},
            </Typography>
          )}
          <Typography style={{ marginTop: 4, fontWeight: 700 }} variant="h5">
            Welcome to your new profile on Vyce!
          </Typography>
          <Typography style={{ marginTop: 8 }} color="textSecondary" variant="subtitle1">
            To login, please reset your password.
          </Typography>
        </Box>

        <ForgotPassword
          email={email}
          userLogin={userLogin}
          redirectUrl={'/onboarding/employee?general=True'}
          noTitle
          noLogo
          buttonText="Login to Vyce"
        />
      </>
    </AuthLayout>
  );
};
