import { axios } from '@vyce/core/src/api';
import { CardForm, BillingContact, BillingContactResponse, CardDetails } from '@vyce/core/src/types';

import { GetInvoicesPayload } from '../types';

const prepareCardData = (data: CardForm): CardDetails => {
  const { expiry, number, ...rest } = data;
  const [exp_month, exp_year] = expiry.split('/');
  const fullYear = Number(`20${exp_year}`);

  return {
    exp_month: Number(exp_month),
    exp_year: fullYear,
    number: number.replace(/[\s]/g, ''),
    ...rest,
  };
};

export const createSubscriptionRequest = (token: string, companyId: string, data: CardForm) => {
  return axios.post(`/billing/subscriptions/companies/${companyId}`, {
    bank_card: prepareCardData(data),
  });
};

export const resumeSubscriptionRequest = (token: string, companyId: string) => {
  return axios.post(`/billing/subscriptions/companies/${companyId}/resume`);
};

export const cancelSubscriptionRequest = (token: string, companyId: string) => {
  return axios.delete(`/billing/subscriptions/companies/${companyId}`);
};

export const getPaymentDetailsRequest = ({ token, companyId }: { token: string; companyId: string }) => {
  return axios.get(`/billing/bank_cards/companies/${companyId}`, {
    params: { decrypted: true },
  });
};

export const getCardDetailsRequest = ({ token, cardId }: { token: string; cardId: string }) => {
  return axios.get(`/billing/bank_cards/${cardId}`, {
    params: { decrypted: true },
  });
};

export const getBillingContactRequest = ({ token, companyId }: { token: string; companyId: string }) => {
  return axios.get(`/billing/contacts/companies/${companyId}`);
};

export const updateBillingContactRequest = ({
  token,
  companyId,
  requestData,
}: {
  token: string;
  companyId: string;
  requestData: BillingContact;
}) => {
  return axios.put<BillingContactResponse>(`/billing/contacts/companies/${companyId}`, requestData);
};

export const updateCardDetailsRequest = ({
  token,
  companyId,
  requestData,
}: {
  token: string;
  companyId: string;
  requestData: CardForm;
}) => {
  return axios.post<CardDetails>(
    `/billing/subscriptions/companies/${companyId}/bank_cards`,
    prepareCardData(requestData)
  );
};

export const getInvoicesRequest = ({
  token,
  companyId,
  requestData,
}: {
  token: string;
  companyId: string;
  requestData: GetInvoicesPayload;
}) => {
  return axios.post(`/billing/invoices/companies/${companyId}/search`, requestData);
};

export const getBillingDataRequest = ({ token, companyId }: { token: string; companyId: string }) => {
  return axios.get(`/billing/invoices/companies/${companyId}/current_period`);
};

export const getSubscriptionDataRequest = ({ token, companyId }: { token: string; companyId: string }) => {
  return axios.get(`/billing/subscriptions/companies/${companyId}`);
};

export const getSubscriptionStatusRequest = ({ token, companyId }: { token: string; companyId: string }) => {
  return axios.get(`/billing/subscriptions/companies/${companyId}/is_subscribed`);
};
