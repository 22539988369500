import React, { ChangeEvent, useContext } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Typography,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';

import { AppRadioButton, AppTextField } from '@vyce/core/src/components/inputs';
import { UserSurvey } from '@vyce/core/src/types';
import { DeviceContext } from '@vyce/core/src/contexts';

interface Props {
  survey?: UserSurvey;
  setSurvey?: Function;
}

const useStyles = makeStyles<any>((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gridGap: '8px',
    },
    controlWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
    },
    healthQuestionLabel: {
      flexBasis: '55%',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '100%',
      },
    },
    questionButtonControlLabel: {
      marginRight: 0,
      '& .MuiTypography-root': {
        marginLeft: 0,
      },
    },
    questionButton: {
      width: 100,
      paddingRight: 0,
    },
    tabTitle: {
      fontWeight: 600,
      color: theme.palette.text.secondary,
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
        marginBottom: theme.spacing(1),
      },
    },
  })
);

export const HealthAndSafetyQuestions = ({ survey, setSurvey }: Props) => {
  const classes = useStyles();
  const { isMobile } = useContext(DeviceContext);

  const changeAnswer = (yes_no: boolean, questionId: string) => {
    if (!setSurvey) {
      return;
    }
    const updatedSurvey: any = {
      ...survey,
      content: survey?.content.map(item => {
        if (item.question.uuid === questionId) {
          return {
            ...item,
            answer: {
              ...item.answer,
              yes_no,
            },
          };
        }
        return item;
      }),
    };
    setSurvey(updatedSurvey);
  };

  const handleCommentChange = (event: ChangeEvent<any>, questionId?: string) => {
    if (!setSurvey) {
      return;
    }
    const updatedSurvey: any = {
      ...survey,
      content: survey?.content.map(item => {
        if (item.question.uuid === questionId) {
          return {
            ...item,
            answer: {
              ...item.answer,
              comment: event.target.value,
            },
          };
        }
        return item;
      }),
    };
    setSurvey(updatedSurvey);
  };

  return (
    <>
      <Box>
        <Typography className={classes.tabTitle}>Health & Safety Questionnaire</Typography>

        <Typography variant="subtitle2" color="textSecondary">
          This information will be kept in strict confidence. Please make sure it is up to date.
        </Typography>
      </Box>

      <Box marginTop={3} className={classes.wrapper}>
        {survey?.content.map((item, index) => (
          <Box key={'question' + index} className={classes.wrapper}>
            <Box className={classes.controlWrapper}>
              <Box className={classes.healthQuestionLabel}>
                <Typography variant="caption" style={{ fontWeight: 600 }}>
                  {item.question.text}
                </Typography>
              </Box>
              <FormControl component="fieldset">
                <RadioGroup value={item.answer?.yes_no === undefined ? null : item.answer?.yes_no}>
                  <Box display="flex" gridGap={16}>
                    <FormControlLabel
                      value={true}
                      className={classes.questionButtonControlLabel}
                      control={
                        <AppRadioButton
                          onClick={() => changeAnswer(true, item.question?.uuid as string)}
                          className={classes.questionButton}
                          color="primary"
                          name="Yes"
                          size="small"
                        />
                      }
                      label=""
                    />
                    <FormControlLabel
                      value={false}
                      className={classes.questionButtonControlLabel}
                      control={
                        <AppRadioButton
                          onClick={() => changeAnswer(false, item.question?.uuid as string)}
                          className={classes.questionButton}
                          color="primary"
                          name="No"
                        />
                      }
                      label=""
                    />
                  </Box>
                </RadioGroup>
              </FormControl>
            </Box>

            {!!item.answer?.yes_no && (
              <Box>
                <AppTextField
                  onChange={event => handleCommentChange(event, item.question?.uuid)}
                  value={item.answer?.comment}
                  label={
                    isMobile
                      ? 'Please add any details'
                      : 'Please add any details - medical history - medication - dates etc'
                  }
                  fullWidth
                />
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </>
  );
};
