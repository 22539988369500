import React from 'react';

import { Box, FormControl, FormControlLabel, RadioGroup } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';

import { OnboardingStepTitle } from '../../../components';
import { AppRadioButton } from '../../../components/inputs';

export const PayrollFeeStep: React.FC = () => {
  const { control } = useFormContext();

  return (
    <>
      <OnboardingStepTitle
        text="Fee"
        additionalText="The cost of outsourcing your payroll is £15.00 per payment. Please define if your company or the individual contractor being paid should be charged this fee."
      />

      <Controller
        render={({ field }) => (
          <FormControl component="fieldset" style={{ width: '100%' }}>
            <RadioGroup value={field.value} onChange={field.onChange}>
              <Box display="flex" m={1} width="100%" flexWrap="wrap" justifyContent="center">
                <FormControlLabel
                  value="1"
                  control={
                    <AppRadioButton
                      style={{ width: 308 }}
                      color="primary"
                      name="The individual contractor will pay the fee"
                    />
                  }
                  label=""
                />
                <FormControlLabel
                  value=""
                  control={
                    <AppRadioButton
                      style={{ width: 308 }}
                      color="primary"
                      name="We, the company, will pay the fee"
                    />
                  }
                  label=""
                />
              </Box>
            </RadioGroup>
          </FormControl>
        )}
        name="payroll_paid_by_worker"
        control={control}
      />
    </>
  );
};
