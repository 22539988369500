import React, { useEffect, useMemo, useState } from 'react';

import { Route, Switch } from 'react-router-dom';

import { AuthLayout } from '@vyce/core/src/views/auth/AuthLayout';
import { VerifyPhone } from '@vyce/core/src/views/auth/components/VerifyPhone';
import config from '@vyce/core/src/assets/config';
import { AuthProvider } from '@vyce/core/src/contexts';

import { useTypedSelector, useActions } from '../../../hooks';

import { EmployeeLogin } from './EmployeeLogin';
import { EmployeeRegister } from './EmployeeRegister';

export enum EMPLOYEE_AUTH_PATHS {
  SIGNUP = '/auth/employee/signup',
  LOGIN = '/auth/employee/login',
  VERIFY_PHONE = '/auth/employee/verify-phone',
}

export const EmployeeAuth: React.FC = (props: any) => {
  const { userDataLoading } = useTypedSelector(state => state.helper);
  const [fromUrl, setFromUrl] = useState<string>('');
  const { verifyPhone } = useActions();

  useEffect(() => {
    const fromState = props?.location?.state?.from;

    if (fromState) {
      setFromUrl(fromState);
    }
  }, [props?.location?.state]);

  const contextOptions = useMemo(() => ({ redirectUrl: fromUrl }), [fromUrl]);

  return (
    <AuthLayout rightPictureSrc={config.auth.worker}>
      <AuthProvider value={contextOptions}>
        <Switch>
          <Route path={EMPLOYEE_AUTH_PATHS.SIGNUP} component={EmployeeRegister} />
          <Route path={EMPLOYEE_AUTH_PATHS.LOGIN} component={EmployeeLogin} />
          <Route
            path={EMPLOYEE_AUTH_PATHS.VERIFY_PHONE}
            component={() => (
              <VerifyPhone
                loading={userDataLoading}
                redirectUrl={fromUrl || '/onboarding/employee/undefined/undefined'}
                verifyPhone={verifyPhone}
              />
            )}
          />
        </Switch>
      </AuthProvider>
    </AuthLayout>
  );
};
