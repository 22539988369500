// @ts-nocheck
import React, { FocusEventHandler } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import InputMask from 'react-input-mask';

import { AppTextField } from '../inputs';
import { FieldProps } from '../../types';

interface Props extends FieldProps {
  handleInputFocus?: FocusEventHandler<HTMLInputElement>;
}

export const CardCVCField: React.FC<Props> = ({ handleInputFocus, margin }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      rules={{
        required: 'CVC is required',
        minLength: { value: 3, message: 'Must be 3 or 4 digits' },
      }}
      name="cvc"
      render={({ field }) => (
        <InputMask {...field} mask="9999" onFocus={handleInputFocus} maskChar={null}>
          {() => (
            <AppTextField
              margin={margin}
              error={!!errors.cvc?.message}
              label="CVC code"
              type="tel"
              fullWidth
              helperText={errors.cvc?.message || ''}
            />
          )}
        </InputMask>
      )}
    />
  );
};
