import { AppNotification } from '../types';

export const VERIFY_EMAIL_ERROR_OBJECT: AppNotification = {
  message: 'Ooops! We just need to check something first!',
  options: {
    permanent: true,
    variant: 'error',
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    action: {
      type: 'verify_email',
      buttonText: 'Resend verification link',
    },
  },
  description: 'Please validate your email by clicking on the link in the email sent to you on registration',
};
