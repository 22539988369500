import React, { useCallback, useEffect, useState } from 'react';

import { useHistory } from 'react-router';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  FormControlLabel,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';

import { MAIN_CONTAINER_ID } from '../constants';
import { AppCheckbox } from './inputs';
import { getFromLS, saveToLS } from '../utils/local-storage';

interface Props {
  when: boolean;
  onOK: Function;
  onCancel: Function;
  title: string;
  subtitle?: string;
  okText?: string;
  cancelText?: string;
  page?: string;
}

export const RouterPrompt: React.FC<Props> = ({
  when,
  onOK,
  onCancel,
  title,
  subtitle,
  okText = 'Ok',
  cancelText = 'Cancel',
  page,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');
  const [doNotShow, setDoNotShow] = useState<boolean>(false);
  const lsFieldName = `doNotShow${page}RouterPrompt`;

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
      if (doNotShow) {
        saveToLS(lsFieldName, 'true');
      }
      setShowPrompt(false);
    }
  }, [currentPath, history, onOK, doNotShow]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    if (doNotShow) {
      saveToLS(lsFieldName, 'true');
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel, doNotShow]);

  useEffect(() => {
    let notShow = getFromLS(lsFieldName) || doNotShow;
    if (when && !notShow) {
      history.block(prompt => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return 'true';
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when, page, doNotShow]);

  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleCancel();
        }
      }}
      container={document.getElementById(MAIN_CONTAINER_ID)}
      open={showPrompt}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{subtitle}</DialogContentText>

        {page && (
          <Box>
            <FormControlLabel
              classes={{ label: classes.label }}
              control={
                <AppCheckbox
                  checked={doNotShow}
                  onChange={e => setDoNotShow(e.target.checked)}
                  variant="circle"
                  color="primary"
                />
              }
              label="Don't show me this again"
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button size="large" onClick={handleCancel} variant="outlined" color="primary">
          {cancelText}
        </Button>
        <Button size="large" onClick={handleOK} variant="contained" color="primary">
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
      color: theme.palette.text.secondary,
    },
  })
);
