import React from 'react';

import noop from 'lodash/noop';
import { Circle, FeatureGroup } from 'react-leaflet';
import { LatLngLiteral } from 'leaflet';
import { EditControl } from 'react-leaflet-draw';
import { Box, Button, makeStyles, Theme, useTheme } from '@material-ui/core';
import * as GeoJSON from 'geojson';

import { MapView } from '../../../map/MapView';
import { DraggableMarker } from '../../components';
import { SITE_RADIUS } from '../../../../modules/timeModule/constants';
import { useDrawableMap } from '../hooks/useDrawableMap';
import { AiOutlineRedo } from 'react-icons/ai';
import { HiOutlineTrash } from 'react-icons/hi';
import { TbPencil } from 'react-icons/tb';

interface Props {
  centerPosition: LatLngLiteral;
  setValue?: Function;
  polygon?: GeoJSON.Polygon | null;
  height: number;
}

export const DrawableMap: React.FC<Props> = ({ centerPosition, polygon, height, setValue }) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    onDeleteLastPointClick,
    handlePositionChange,
    onMountedRect,
    handleChange,
    onClearClick,
    ref,
    whenCreated,
    editing,
    setEditing,
    drawing,
    toggleDrawing,
    clearPolygon,
  } = useDrawableMap({ setValue, polygon });

  const handleSwitch = () => {
    if (polygon && !drawing) {
      clearPolygon();
    } else {
      toggleDrawing();
    }
  };

  return (
    <Box position="relative" display="flex" justifyContent="center">
      <MapView
        whenCreated={whenCreated}
        scrollWheelZoom={false}
        zoom={16}
        centerPosition={centerPosition}
        height={`${height}px`}
        markers={[]}
        onMarkerClick={noop}>
        <>
          <FeatureGroup ref={ref}>
            <EditControl
              onMounted={onMountedRect}
              position="topright"
              onDrawStop={handleChange}
              draw={{
                rectangle: false,
                circle: false,
                polyline: false,
                polygon: {
                  shapeOptions: {
                    color: theme.palette.primary.main,
                  },
                  showLength: true,
                  repeatMode: true,
                },
                marker: false,
                circlemarker: false,
              }}
            />
          </FeatureGroup>

          {!drawing && !polygon && (
            <>
              <DraggableMarker position={centerPosition} onPositionChange={handlePositionChange} />
              <Circle
                center={centerPosition}
                pathOptions={{ color: theme.palette.primary.main }}
                radius={SITE_RADIUS}
              />
            </>
          )}
        </>
      </MapView>

      {setValue && (
        <Box width={drawing ? 168 : 'auto'} className={classes.controls}>
          {polygon && (
            <Button
              size="small"
              onClick={() => setEditing(value => !value)}
              color="primary"
              startIcon={!editing && <TbPencil />}
              variant="outlined">
              {editing ? 'Save changes' : 'Edit area'}
            </Button>
          )}
          {polygon && (
            <Button
              size="small"
              onClick={onClearClick}
              color="primary"
              startIcon={<AiOutlineRedo />}
              variant="outlined">
              Redo area
            </Button>
          )}
          {!polygon && drawing && (
            <Button
              size="small"
              onClick={onDeleteLastPointClick}
              color="primary"
              startIcon={<HiOutlineTrash />}
              variant="outlined">
              Delete last point
            </Button>
          )}
          <Button onClick={handleSwitch} size="small" color="primary" variant="contained">
            {polygon || drawing ? 'Place a pin' : 'Draw a custom shape'}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export const useStyles = makeStyles((theme: Theme) => ({
  controls: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    bottom: 8,
    right: 8,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderRadius: 16,
    zIndex: 10000,
  },
}));
