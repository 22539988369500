export const getLocationTitle = (inLocationVerified: boolean, outLocationVerified: boolean) => {
  let locationTitle = 'Worker Location Verified';

  if (!inLocationVerified && !outLocationVerified) {
    locationTitle = 'Worker Location Unverified';
  }
  if (!inLocationVerified && outLocationVerified) {
    locationTitle = 'Worker Clock in Location Unverified';
  }
  if (inLocationVerified && !outLocationVerified) {
    locationTitle = 'Worker Clock out Location Unverified';
  }
  return locationTitle;
};
