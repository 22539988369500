import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    moduleContainer: {
      padding: '24px 40px',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
      },
    },
  })
);
