import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { ControlledDateField, ControlledTagAutocomplete } from '@vyce/core/src/components/controlled-inputs';
import { AppTextField } from '@vyce/core/src/components/inputs';
import { Tag } from '@vyce/core/src/types';

import { rules } from '../config';

export const NotListedAcademicQualificationForm = ({
  tags,
  isAdmin,
  setSubstring,
}: {
  tags: Tag[];
  isAdmin: boolean;
  setSubstring: (arg0: string) => void;
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <Controller
        control={control}
        rules={rules}
        name="title"
        render={({ field }) => (
          <AppTextField
            {...field}
            id="type"
            label="Academic Title"
            margin="none"
            fullWidth
            error={!!errors.title?.message}
            helperText={(errors.title?.message as string) || ''}
          />
        )}
      />
      <ControlledTagAutocomplete
        name="extra_text"
        margin="none"
        items={tags}
        label="Institute Name"
        rules={rules}
        setSubstring={setSubstring}
        manualMode={isAdmin}
      />
      <Controller
        control={control}
        name="reference_number"
        render={({ field }) => (
          <AppTextField
            {...field}
            label="Reference number"
            margin="none"
            fullWidth
            error={!!errors.reference_number?.message}
            helperText={(errors.reference_number?.message as string) || ''}
          />
        )}
      />
      <ControlledDateField name="date" label="Date Awarded" disableFuture />
      {/*   TODO will be added later
     <Controller
        control={control}
        name="details"
        render={({ field }) => (
          <AppTextField {...field} id="type" label="Details (optional)" margin="none" fullWidth />
        )}
      /> */}
    </>
  );
};
