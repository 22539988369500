// @ts-nocheck
import React, { ReactElement } from 'react';

import InputMask from 'react-input-mask';
import { Controller, useFormContext } from 'react-hook-form';

import { AppTextField } from '../inputs';
import { Box } from '@material-ui/core';

interface Props {
  name: string;
  label: string;
  children?: ReactElement;
}

export const URLField: React.FC<Props> = ({ name, children, label }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <InputMask
          {...field}
          mask="https://www.********************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************"
          formatChars={{ '*': '.*' }}
          maskChar={null}>
          {() => (
            <AppTextField
              {...field}
              label={label}
              value={field.value || ''}
              fullWidth
              InputProps={{
                endAdornment: (
                  <Box display="flex" alignItems="center" padding={1}>
                    {children || <></>}
                  </Box>
                ),
              }}
            />
          )}
        </InputMask>
      )}
    />
  );
};
