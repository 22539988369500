import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { AppTextField } from '../../../components/inputs';

const MAX_NAME_CHARACTERS = 60;

export const getHelperText = (
  max: number,
  lengthToShowMessage: number,
  value: string,
  errorMessage?: string
): string => {
  if (errorMessage) {
    return errorMessage;
  }
  const length = value?.length || 0;
  const charactersLeft = max - length;
  return charactersLeft <= lengthToShowMessage ? `Characters left: ${charactersLeft}` : '';
};

export const TeamNameField: React.FC = () => {
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext();

  return (
    <Controller
      control={control}
      rules={{
        required: 'Name is required',
        maxLength: MAX_NAME_CHARACTERS,
      }}
      name="name"
      render={({ field }) => (
        <AppTextField
          {...field}
          label="Name"
          margin="normal"
          cy-test-id="team-name"
          fullWidth
          inputProps={{ maxlength: MAX_NAME_CHARACTERS }}
          error={!!errors?.name?.message}
          helperText={getHelperText(
            MAX_NAME_CHARACTERS,
            10,
            getValues('name'),
            errors?.name?.message as string
          )}
        />
      )}
    />
  );
};
