import React from 'react';

import { AiOutlinePlus } from 'react-icons/ai';
import { Button } from '@material-ui/core';

import { getEmployeeListRequest, removeEmployeeFromPayScheduleRequest } from '@vyce/core/src/api/pay';
import { EmployeeList } from '@vyce/core/src/views/payroll/components';
import { ActivateSubscriptionDialog } from '@vyce/core/src/components';

import { useTypedSelector, useInviteButton } from 'src/hooks';
import { UniversalInviteModule } from 'src/modules';

export const EmployerEmployeeList: React.FC = () => {
  const { selectedCompany, access_token, selectedCompanyAppData } = useTypedSelector(state => state.helper);
  const {
    isActivateSubscriptionDialogOpen,
    subscribeLoading,
    isInviteFormOpen,
    closeActivateSubscriptionDialog,
    setCloseInviteForm,
    simpleSubscribe,
    handleInviteButtonClick,
  } = useInviteButton();

  return (
    <>
      <EmployeeList
        token={access_token}
        removeEmployeeFromPayScheduleRequest={removeEmployeeFromPayScheduleRequest}
        companyId={selectedCompany?.uuid}
        paySchedules={selectedCompanyAppData?.paySchedules}
        getEmployeeListRequest={getEmployeeListRequest}
        withProfileButton>
        <Button
          onClick={handleInviteButtonClick}
          size="small"
          data-tour="invite"
          startIcon={<AiOutlinePlus />}
          variant="contained"
          color="primary">
          Invite New Members
        </Button>
      </EmployeeList>
      <UniversalInviteModule isOpen={isInviteFormOpen} onClose={setCloseInviteForm} />
      <ActivateSubscriptionDialog
        open={isActivateSubscriptionDialogOpen}
        onClose={closeActivateSubscriptionDialog}
        callback={simpleSubscribe}
        loading={subscribeLoading}
        subscriptionTextMode="self"
        wasSubscribed={selectedCompany?.subscribed_company}
      />
    </>
  );
};
