import React, { useContext, useEffect, useRef, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core';
import uniqBy from 'lodash/uniqBy';

import {
  ActiveOnboardingStep,
  INVITE_MODULES,
  Job,
  OnboardingStep,
  Tag,
  TagGroups,
} from '@vyce/core/src/types';
import { formatDate } from '@vyce/core/src/utils/dates';
import { getLocationDetails } from '@vyce/core/src/utils/location';
import { createJobRequest, deleteJobRequest, updateJobRequest } from '@vyce/core/src/api/connect';
import { setFormValues } from '@vyce/core/src/utils';
import { AppOnboarding } from '@vyce/core/src/components/AppOnboarding';
import { defaultValues } from '@vyce/core/src/modules/hiringModule/config';
import { ConfirmDialog } from '@vyce/core/src/components';
import { useBooleanState } from '@vyce/core/src/hooks';

import {
  Budget,
  ContactDetails,
  JobDescription,
  JobLocation,
  JobType,
  ProfessionalDetails,
  WorkerType,
} from './fields';
import { HiringContext } from '../../../modules/hiringModule/context/hiringContext';
import { useStyles } from '../styles';
import { MAIN_CONTAINER_ID } from '../../../constants';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  handleClose: Function;
  getJobs?: Function;
  job?: Job | null;
  contactName?: string;
  contactPhone?: string;
  contactEmail?: string;
  companyId?: string;
  token: string;
  setIsJobPostedStatus?: Function;
}

export const CreateJobDialog: React.FC<Props> = ({
  handleClose,
  job,
  getJobs,
  contactName,
  contactPhone,
  contactEmail,
  companyId,
  token,
  setIsJobPostedStatus,
}) => {
  const classes = useStyles();
  const childRef = useRef<any>();
  const history = useHistory();
  const methods = useForm<any>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      ...defaultValues,
      contact_name: contactName,
      contact_phone: contactPhone,
      contact_email: contactEmail,
    },
  });
  const { isJobDialogOpen, isEditMode } = useContext(HiringContext);
  const { handleServerError, showNotification } = useContext(NotificationContext);

  const [isDiscardDialogOpen, openDiscardDialog, closeDiscardDialog] = useBooleanState(false);
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useBooleanState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [createdJob, setCreatedJob] = useState<Job>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isPayOvertime, setIsPayOvertime] = useState<boolean>(false);
  const [isUrgently, setIsUrgently] = useState<boolean>(false);
  const [isContractDetails, setIsContractDetails] = useState<boolean>(false);

  const { reset, watch, setValue } = methods;
  const job_type = watch('job_type', '');
  const urgently = watch('urgently', '');
  const salaryType = watch('salary_type', '');

  const steps: OnboardingStep[] = [
    {
      modules: [INVITE_MODULES.GENERAL, INVITE_MODULES.TEAMS, INVITE_MODULES.PAY],
      component: <WorkerType />,
      name: 'WorkerType',
    },
    {
      modules: [INVITE_MODULES.GENERAL, INVITE_MODULES.TEAMS, INVITE_MODULES.PAY],
      component: <JobLocation />,
      name: 'JobLocation',
    },
    {
      modules: [INVITE_MODULES.GENERAL, INVITE_MODULES.TEAMS, INVITE_MODULES.PAY],
      component: <JobDescription />,
      name: 'JobDescription',
    },
    {
      modules: [INVITE_MODULES.GENERAL, INVITE_MODULES.TEAMS, INVITE_MODULES.PAY],
      component: (
        <Budget salaryType={salaryType} isPayOvertime={isPayOvertime} setIsPayOvertime={setIsPayOvertime} />
      ),
      name: 'Budget',
    },
    {
      modules: [INVITE_MODULES.GENERAL, INVITE_MODULES.TEAMS, INVITE_MODULES.PAY],
      component: <JobType isContractDetails={isContractDetails} isUrgently={isUrgently} />,
      name: 'JobType',
    },
    {
      modules: [INVITE_MODULES.GENERAL, INVITE_MODULES.TEAMS, INVITE_MODULES.PAY],
      component: <ProfessionalDetails />,
      name: 'ProfessionalDetails',
    },
    {
      modules: [INVITE_MODULES.GENERAL, INVITE_MODULES.TEAMS, INVITE_MODULES.PAY],
      component: <ContactDetails />,
      name: 'ContactDetails',
    },
  ];
  const initialStep: ActiveOnboardingStep = { step: steps[0], number: 0 };
  const [activeStep, setActiveStep] = useState<ActiveOnboardingStep>(initialStep);

  const stepsLength = steps.length;

  const handleNext = async (data: any) => {
    if (!companyId) {
      return;
    }

    switch (activeStep.number) {
      case stepsLength - 1:
        try {
          setLoading(true);
          const jobData = await prepareJobData(data);
          let res;
          if (isEditMode && job) {
            res = await updateJobRequest(token, job.uuid, companyId, jobData);
          } else {
            res = await createJobRequest(token, companyId, jobData);
            if (setIsJobPostedStatus) {
              setIsJobPostedStatus();
            }
          }
          setCreatedJob(res?.data);
          setLoading(false);
          setOpenSuccess(true);
        } catch (e) {
          setLoading(false);
          handleServerError(e);
        }
        break;
      default:
        childRef?.current?.nextStep();
        break;
    }
  };

  const prepareJobData = async (data: any, status?: string | null): Promise<Job> => {
    status = status || 'live';
    const address = await getLocationDetails({ fullAddress: data.address });
    const tags = [...data.skills];
    const roleTag = job?.tags?.find((tag: Tag) => tag.group === TagGroups.ROLE);
    if (roleTag) {
      tags.push(roleTag);
    }
    if (data.name?.uuid) {
      tags.push(data.name);
    }
    const duration_type = data.duration_type?.replace('s', '')?.toLowerCase();
    return {
      ...data,
      urgently: !!data.urgently,
      start_date: !!data.urgently ? null : formatDate(data.start_date),
      name: typeof data.name === 'string' ? data.name : data.name?.name,
      tags: uniqBy(tags, 'uuid'),
      duration: data.job_type === 'contract' && data.duration ? +data.duration : undefined,
      duration_type: data.job_type === 'contract' && duration_type ? duration_type : undefined,
      salary_type: data.salary_type?.toLowerCase(),
      salary_from: +data.salary_from,
      salary_to: +data.salary_to,
      overtime_from: data.overtime_from ? +data.overtime_from : undefined,
      overtime_to: data.overtime_to ? +data.overtime_to : undefined,
      overtime_type: data.overtime_type?.toLowerCase() || undefined,
      address,
      job_type: data.job_type,
      status: status as string,
    };
  };

  const saveAsDraft = async () => {
    if (!companyId) {
      return;
    }
    try {
      const data = methods.getValues();
      setLoading(true);
      const jobData = await prepareJobData(data, 'draft');
      await createJobRequest(token, companyId, jobData);
      setLoading(false);
      handleClose();
      showNotification({
        message: 'Your job has been saved. You’ll find it in ‘Draft Jobs’',
        options: {
          variant: 'info',
        },
      });
      reset();
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  };

  const handleDiscard = () => {
    closeDiscardDialog();
    handleClose();
    reset();
    setActiveStep(initialStep);
  };

  const handleDeleteDraft = async () => {
    if (!companyId || !job?.uuid) {
      return;
    }
    try {
      await deleteJobRequest(token, companyId, job.uuid);
      showNotification({
        message: 'Draft has been deleted',
        options: {
          variant: 'info',
        },
      });
      if (getJobs) {
        getJobs();
      }
      closeDiscardDialog();
      handleClose();
      reset();
      setActiveStep(initialStep);
    } catch (e) {
      handleServerError(e);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setIsContractDetails(job_type === 'contract');
    }
    return () => {
      mounted = false;
    };
  }, [job_type]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setIsUrgently(urgently);
    }

    return () => {
      mounted = false;
    };
  }, [urgently]);

  useEffect(() => {
    childRef?.current?.scrollDown();
    if (!isPayOvertime) {
      setValue('overtime_type', undefined);
      setValue('overtime_from', undefined);
      setValue('overtime_to', undefined);
    }
  }, [isPayOvertime, isContractDetails, isUrgently]);

  useEffect(() => {
    if (job) {
      setFormValues(job, methods.setValue, methods.getValues());
      if (job.overtime_type) {
        setIsPayOvertime(true);
      }
    }
  }, [job, methods]);

  useEffect(() => {
    if (isEditMode || job) {
      setActiveStep({ step: steps[steps.length - 1], number: stepsLength });
    }
    if (!isJobDialogOpen) {
      setActiveStep(initialStep);
    }
  }, [isJobDialogOpen, isEditMode]);

  return (
    <Slide direction="up" timeout={200} in={isJobDialogOpen} mountOnEnter unmountOnExit>
      <div>
        <AppBar style={{ position: 'relative', boxShadow: 'none' }}>
          <Toolbar>
            <Typography variant="subtitle1" className={classes.dialogTitle}>
              Promote your first job for free
            </Typography>
          </Toolbar>
        </AppBar>

        <AppOnboarding
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          methods={methods}
          currentMessage={''}
          handleNext={handleNext}
          lastStepButtonLabel={isEditMode ? 'Save' : 'Promote Job'}
          steps={steps}
          loading={loading}
          ref={childRef}>
          <Box display="flex" gridGap={16}>
            {job && job.status === 'draft' && (
              <Button variant="outlined" className={classes.redButton} onClick={openDeleteDialog} fullWidth>
                Delete Draft
              </Button>
            )}
            <Button variant="outlined" onClick={openDiscardDialog} fullWidth color="primary">
              Discard changes
            </Button>

            <Button variant="outlined" fullWidth onClick={saveAsDraft} color="primary">
              Save as Draft
            </Button>
          </Box>

          <ConfirmDialog
            handleClose={closeDiscardDialog}
            open={isDiscardDialogOpen}
            confirmText="Yes"
            cancelText="No, take me back"
            title="Are you sure you don't want to save changes?"
            handleConfirm={handleDiscard}
          />

          <ConfirmDialog
            handleClose={closeDeleteDialog}
            open={isDeleteDialogOpen}
            confirmText="Yes"
            cancelText="No, take me back"
            title="Are you sure you want to delete this Draft?"
            handleConfirm={handleDeleteDraft}
          />

          <Dialog
            open={openSuccess}
            container={document.getElementById(MAIN_CONTAINER_ID)}
            onClose={() => setOpenSuccess(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Cool!</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {isEditMode
                  ? 'Job has been updated'
                  : 'Your job is now actively being promoted. Every candidate matching your criteria has just been notified. ' +
                    'Check them out now and if you like what you see, go ahead and connect with them.'}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                size="large"
                disabled={loading}
                variant="outlined"
                onClick={() => {
                  setOpenSuccess(false);
                  handleClose();
                  reset();
                  setActiveStep(initialStep);
                  history.push(`/hiring/live/${createdJob?.name}_${createdJob?.uuid}/candidates/matched`);
                }}
                color="primary">
                See matched candidates
              </Button>
            </DialogActions>
          </Dialog>
        </AppOnboarding>
      </div>
    </Slide>
  );
};
