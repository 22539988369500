// @ts-nocheck
import React, { FocusEventHandler, useState, useEffect } from 'react';

import InputMask from 'react-input-mask';
import { Controller, useFormContext } from 'react-hook-form';

import { getCardNumberMask } from '../../utils/payments';
import { AppTextField } from '../inputs';
import { FieldProps } from '../../types';

interface Props extends FieldProps {
  number: string;
  handleInputFocus?: FocusEventHandler<HTMLInputElement>;
}

const removeSpaces = (string: string) => {
  return string.replace(/\s/g, '');
};

const validateCardNumber = (number: string, mask: string) => {
  const numberWithoutSpaces = removeSpaces(number);
  const maskWithoutSpaces = removeSpaces(mask);
  return (
    (numberWithoutSpaces.length >= 12 && numberWithoutSpaces.length <= maskWithoutSpaces?.length) ||
    'Invalid format'
  );
};

export const CardNumberField: React.FC<Props> = ({ number, handleInputFocus, margin }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const [mask, setMask] = useState<string>('');

  useEffect(() => {
    setMask(getCardNumberMask(number));
  }, [number]);

  return (
    <Controller
      control={control}
      rules={{
        required: 'Card number is required',
        validate: value => validateCardNumber(value, mask),
      }}
      name="number"
      render={({ field }) => (
        <InputMask {...field} mask={mask} onFocus={handleInputFocus} maskChar={null}>
          {() => (
            <AppTextField
              margin={margin}
              error={!!errors.number?.message}
              label="Card Number"
              type="tel"
              fullWidth
              helperText={errors.number?.message || ''}
            />
          )}
        </InputMask>
      )}
    />
  );
};
