import React, { useContext } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormControlLabel,
} from '@material-ui/core';
import { FormProvider, Controller, useForm } from 'react-hook-form';

import { useStyles } from '../styles';
import { MAIN_CONTAINER_ID } from '../../../../constants';
import { AppCheckbox } from '../../../../components/inputs';
import { DeviceContext } from '../../../../contexts';

const id = 'responsive-dialog-title';

interface Props {
  isOpen: boolean;
  defaultValues: Record<string, boolean>;
  onClose: () => void;
  onClick: (arg0: string[]) => void;
}

export const FilterDialog = ({ isOpen, defaultValues, onClose, onClick }: Props) => {
  const classes = useStyles();
  const { isMobile } = useContext(DeviceContext);
  const methods = useForm<Record<string, boolean>>({
    defaultValues,
  });
  const { control } = methods;

  const onSubmit = async (data: Record<string, boolean>) => {
    onClick(Object.keys(data).filter(item => !data[item]));
  };

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      open={isOpen}
      onClose={onClose}
      aria-labelledby={id}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle id={id}>Which answers should be displayed?</DialogTitle>
          <DialogContent>
            <Box className={classes.settingsWrapper} marginTop={3}>
              {Object.keys(defaultValues).map(item => (
                <Controller
                  key={item}
                  control={control}
                  name={item}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <AppCheckbox
                          onChange={e => {
                            const checked = e.target.checked;
                            field.onChange(checked);
                          }}
                          color="primary"
                          checked={field.value}
                        />
                      }
                      label={item}
                    />
                  )}
                />
              ))}
            </Box>
          </DialogContent>

          <DialogActions>
            <Button size="large" variant="outlined" onClick={onClose}>
              Close
            </Button>
            <Button size="large" type="submit" variant="contained" color="primary">
              Update
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
