import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Typography,
  Button,
} from '@material-ui/core';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { useBooleanState } from '@vyce/core/src/hooks';

import { useStyles } from './styles';
import { useData } from './hooks';
import { Props } from './types';
import { CustomDetailsFieldsProvider, BlockOfFields, DeleteDialog } from './components';

/* 
  Module for Custom Location Details Fields in T&A settings page
*/

export const CustomDetailsFieldsModule = (props: Props) => {
  const classes = useStyles();
  const [expanded, setExpandedTrue, setExpandedFalse, toggleExpanded] = useBooleanState(false);

  const {
    defaultFields,
    customFields,
    contextOptions,
    showDeleteDialog,
    fieldName,
    closeDeleteDialog,
    deleteCustomField,
  } = useData(props);

  return (
    <CustomDetailsFieldsProvider value={contextOptions}>
      <Paper variant="outlined" className={classes.wrapper}>
        <Box marginBottom={3}>
          <Typography variant="h6">Custom Location Details Fields</Typography>
        </Box>

        <Box className={classes.controlsContainer}>
          <Accordion
            expanded={expanded}
            classes={{ expanded: classes.expanded }}
            className={classes.mainAccordionWrapper}>
            <AccordionSummary classes={{ root: classes.accordionSummary }} onClick={toggleExpanded}>
              <Box display="flex" width="100%" justifyContent="space-between" paddingRight="10px">
                <Typography variant="subtitle2">Customised Location Details</Typography>

                <Button>
                  {expanded ? (
                    <FiChevronUp size={20} color="#A0A3BD" />
                  ) : (
                    <FiChevronDown size={20} color="#A0A3BD" />
                  )}
                </Button>
              </Box>
            </AccordionSummary>

            <AccordionDetails>
              <Box width="90%" marginBottom={2}>
                <Typography variant="caption">
                  If you’d like to add custom fields to your Locations, you may do so here. You can then
                  populate these new fields on each ‘Location Details’ section.
                </Typography>
              </Box>
              <DndProvider backend={HTML5Backend}>
                <BlockOfFields defaultFields={defaultFields} customFields={customFields} />
              </DndProvider>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Paper>
      <DeleteDialog
        isOpen={showDeleteDialog}
        fieldName={fieldName}
        onClose={closeDeleteDialog}
        onClick={deleteCustomField}
      />
    </CustomDetailsFieldsProvider>
  );
};
