import React from 'react';
import { Box, Typography, Grid, useTheme } from '@material-ui/core';

import { CopyToClipboard } from '@vyce/core/src/components/CopyToClipboard';
import { DetailItem } from '@vyce/core/src/types';

import useStyles from '../../styles';
import { BoxWrapper } from './BoxWrapper';

interface ContactDetailsProps {
  email: DetailItem;
  phone: DetailItem;
  isHiring?: boolean;
}

const DataRowItem = ({ title, value }: DetailItem) => {
  const classes = useStyles();
  const theme = useTheme();

  if (!value) return null;
  return (
    <Box className={classes.recordWrapper}>
      <Grid item xs={12} sm={6} lg={5}>
        <Typography className={classes.bold}>{title}:</Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={7}>
        <Box display="flex">
          <Typography color="textSecondary" className={classes.bigTextWrap}>
            {value}
          </Typography>
          <CopyToClipboard text={value} color={theme.palette.text.secondary} />
        </Box>
      </Grid>
    </Box>
  );
};

export const ContactDetails = ({ email, phone, isHiring = false }: ContactDetailsProps) => (
  <Box display="flex" flexDirection="column" height="100%">
    <Typography color={isHiring ? 'initial' : 'textSecondary'} variant="h6">
      Contact Details
    </Typography>
    <BoxWrapper gridGap={16}>
      <DataRowItem {...email} />
      <DataRowItem {...phone} />
    </BoxWrapper>
  </Box>
);
