import { useEffect, useState, useContext } from 'react';

import dayjs from 'dayjs';
import { useHistory, useParams } from 'react-router-dom';

import { getUrlItems } from '@vyce/core/src/utils/url';
import { GetEventsRequestPayload } from '@vyce/core/src/api/types';
import { getTimeWorkerRequest, getUserActivityLogEventsRequest } from '@vyce/core/src/api/time';
import { TimeWorker } from '@vyce/core/src/types';
import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import { prepareWorkerLogData } from '../utils';

export const useData = () => {
  const { handleServerError } = useContext(NotificationContext);
  const [worker, setWorker] = useState<TimeWorker>();
  const [loading, setLoading] = useState<boolean>(false);
  const [workerActivityLog, setWorkerActivityLog] = useState<ReturnType<typeof prepareWorkerLogData>>([]);
  const { isMobile } = useContext(DeviceContext);

  const history = useHistory();

  const { date, shiftId, userId, companyId } = useParams<{
    date: string;
    shiftId: string;
    userId: string;
    companyId: string;
  }>();

  const getData = async () => {
    if (!companyId) {
      return;
    }

    try {
      setLoading(true);
      const { id: workerId } = getUrlItems(userId);

      const payload: GetEventsRequestPayload = {
        offset: 0,
        no_zero_lines: true,
        limit: 3000,
        order_by: [],
        user_id: workerId,
        shift_id: shiftId,
        time_period: {
          gte: dayjs(date).format(),
          lte: dayjs(date).add(1, 'day').format(),
        },
      };
      const workerRes = await getTimeWorkerRequest(workerId, companyId);
      const activityLogRes = await getUserActivityLogEventsRequest({ userId: workerId, payload });
      setWorkerActivityLog(prepareWorkerLogData(activityLogRes.data.items[0]));
      setWorker(workerRes.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  };

  const back = () => {
    history.goBack();
  };

  useEffect(() => {
    getData();
  }, [userId, companyId, date]);

  return { worker, loading, workerActivityLog, isMobile, back };
};
