import { Controller, useFormContext } from 'react-hook-form';

import { Box, Typography } from '@material-ui/core';

import { EmailField } from '@vyce/core/src/components/controlled-inputs/EmailField';
import { PhoneNumberField } from '@vyce/core/src/components/controlled-inputs/PhoneNumberField';
import { AppTextField } from '@vyce/core/src/components/inputs';

export const ContactDetails = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Box>
      <Typography variant="subtitle1" color="primary">
        Contact Details
      </Typography>
      <Typography variant="caption" color="textSecondary">
        This information will only be provided to workers who you connect with.
      </Typography>

      <Controller
        control={control}
        rules={{ required: 'Contact Name is required' }}
        name="contact_name"
        render={({ field }) => (
          <AppTextField
            {...field}
            error={!!errors.contact_name?.message}
            id="contact-name"
            label="Contact Name *"
            margin="normal"
            fullWidth
            helperText={(errors.contact_name?.message as string) || ''}
          />
        )}
      />

      <EmailField autoFocus={false} margin="normal" name="contact_email" label="Key contact email *" />

      <PhoneNumberField
        rules={{ required: 'Contact Number is required' }}
        margin="normal"
        name="contact_phone"
        label="Key contact mobile number *"
      />
    </Box>
  );
};
