import React, { useContext, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';

import { CompanyGeneralInfo } from '@vyce/core/src/views/profile/company/CompanyGeneralInfo';
import {
  getCompanyExtendedDataRequest,
  saveLogoRequest,
  updateCompanyRequest,
} from '@vyce/core/src/api/companies';
import { BankAccount, Company, CompanyPhoto, Testimonial } from '@vyce/core/src/types';
import { CompanyBankDetails } from '@vyce/core/src/views/profile/company/CompanyBankDetails';
import { CompanySocials } from '@vyce/core/src/views/profile/company/CompanySocials';
import { CompanyTestimonials } from '@vyce/core/src/views/profile/company/CompanyTestimonials';
import { CompanyCaseStudies } from '@vyce/core/src/views/profile/company/CompanyCaseStudies';
import { CompanyPhotos } from '@vyce/core/src/views/profile/company/CompanyPhotos';
import { PageLoading } from '@vyce/core/src/components';
import {
  createOrUpdateCompanyBankAccountRequest,
  getCompanyBankAccountRequest,
} from '@vyce/core/src/api/pay';
import { getUrlItems } from '@vyce/core/src/utils/url';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import { useTypedSelector } from '../../../hooks';

export const RouteCompanyProfileComponent: React.FC = () => {
  const { handleServerError } = useContext(NotificationContext);
  const { access_token } = useTypedSelector(state => state.helper);

  const [company, setCompany] = useState<Company>();
  const [photos, setPhotos] = useState<CompanyPhoto[]>([]);
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
  const [bankDetails, setBankDetails] = useState<BankAccount>();
  const { tab, id } = useParams<{ tab: string; id: string }>();
  const [loading, setLoading] = useState<boolean>(true);

  const getCompanyData = async () => {
    const urlItems = getUrlItems(id);
    const companyId = urlItems.id;

    if (!companyId) {
      return;
    }

    try {
      const companyRes = await getCompanyExtendedDataRequest(access_token, companyId);
      const bankDetailsRes = await getCompanyBankAccountRequest({
        companyId,
      });

      setBankDetails(bankDetailsRes.data);
      setCompany(companyRes.data?.company);
      setPhotos(companyRes.data?.photos || []);
      setTestimonials(companyRes.data?.testimonials || []);
      setLoading(false);
    } catch (e) {
      handleServerError(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    getCompanyData();
  }, []);

  if (loading)
    return (
      <Box height="70vh" overflow="hidden">
        <PageLoading />
      </Box>
    );

  switch (tab) {
    case 'general':
      return (
        <CompanyGeneralInfo
          company={company}
          setCompany={setCompany}
          token={access_token}
          saveLogoRequest={saveLogoRequest}
          updateCompanyRequest={updateCompanyRequest}
        />
      );
    case 'bank':
      return (
        <CompanyBankDetails
          bankDetails={bankDetails}
          setBankDetails={setBankDetails}
          companyId={company?.uuid}
          createOrUpdateCompanyBankAccountRequest={createOrUpdateCompanyBankAccountRequest}
        />
      );
    case 'socials':
      return (
        <CompanySocials
          setCompany={setCompany}
          company={company}
          token={access_token}
          updateCompanyRequest={updateCompanyRequest}
        />
      );
    case 'testimonials':
      return (
        <CompanyTestimonials
          testimonials={testimonials}
          company={company}
          token={access_token}
          getCompanyData={getCompanyData}
        />
      );
    case 'studies':
      return <CompanyCaseStudies />;
    case 'photos':
      return (
        <CompanyPhotos
          getCompanyData={getCompanyData}
          photos={photos}
          company={company}
          token={access_token}
        />
      );
    default:
      return <></>;
  }
};
