import { axios } from '@vyce/core/src/api';

import { UserDashboards } from '../../types';

export const getUserDashboardsRequest = (token: string) => {
  return axios.get('/dashboards');
};

export const updateUserDashboardsRequest = (token: string, dashboards: UserDashboards) => {
  return axios.put('/dashboards', dashboards);
};

export const getHealthAndSafetyStatsRequest = ({
  companyId,
  body,
  surveyId,
}: {
  companyId: string;
  body: {
    limit: number;
    team_id?: string;
  };
  surveyId: string;
}) => {
  return axios.post(
    `/dashboards/widgets/teams/companies/${companyId}/bar_charts/employees_by_survey_tags_stats/${surveyId}`,
    body
  );
};
