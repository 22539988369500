import React from 'react';

import { usePaymentsData } from '@vyce/core/src/modules/subscriptionModule/hooks/usePaymentsData';
import { SubscriptionPayments } from '@vyce/core/src/views/subscription/SubscriptionPayments';

import { useTypedSelector } from '../../hooks';

export const EmployerSubscriptionPayments: React.FC = () => {
  const { access_token, selectedCompanyAppData, selectedCompany } = useTypedSelector(state => state.helper);

  const {
    invoices,
    loading,
    total,
    sortModel,
    handleSortModelChange,
    handleFilterChange,
    handlePageChange,
    handleViewInvoiceClick,
  } = usePaymentsData({
    token: access_token,
    subscription: selectedCompanyAppData?.subscription,
    companyId: selectedCompany?.uuid,
  });

  return (
    <SubscriptionPayments
      invoices={invoices}
      sortModel={sortModel}
      total={total}
      loading={loading}
      handlePageChange={handlePageChange}
      handleSortModelChange={handleSortModelChange}
      handleFilterChange={handleFilterChange}
      handleViewInvoiceClick={handleViewInvoiceClick}
    />
  );
};
