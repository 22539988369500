import React, { useContext, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Paper, Typography } from '@material-ui/core';

import { useStyles } from '../styles';
import { CardDetails, Company } from '../../../types';
import { defaultPaymentValues } from '../../../modules/subscriptionModule/constants';
import { useCardDetails } from '../../../modules/subscriptionModule/hooks/useCardDetails';
import { SubscriptionContext } from '../../../contexts';
import { AppFormWrapper, PageLoading } from '../../../components';
import {
  BillingCityField,
  BillingCountryField,
  BillingLine1Field,
  BillingLine2Field,
  BillingPostcodeField,
  BillingStateField,
  CardCVCField,
  CardExpiryDateField,
  CardNameField,
  CardNumberField,
} from '../../../components/controlled-inputs';

interface Props {
  selectedCompany?: Company;
  token: string;
}

export const EditCardDetails: React.FC<Props> = ({ selectedCompany, token }: Props) => {
  const classes = useStyles();
  const methods = useForm<CardDetails>({ defaultValues: defaultPaymentValues });
  const { watch } = methods;
  const number = watch('number', '');
  const { initialCardForm, updateCardDetails, isUpdating, getDecryptedCardDetails, paymentDetails } =
    useCardDetails({ token, selectedCompany });
  const { setBackTo } = useContext(SubscriptionContext);
  const history = useHistory();

  const back = () => {
    history.push('/subscriptions/billing');
  };

  useEffect(() => {
    setBackTo({ text: 'Billing Info', back });

    return () => setBackTo(null);
  }, []);

  useEffect(() => {
    if (paymentDetails) {
      getDecryptedCardDetails();
    }
  }, [paymentDetails]);

  return (
    <>
      {!initialCardForm ? (
        <PageLoading />
      ) : (
        <Grid container>
          <Grid item xs={12} lg={8} style={{ marginBottom: 56 }}>
            <AppFormWrapper
              methods={methods}
              initialData={initialCardForm}
              handleSubmit={updateCardDetails}
              loading={isUpdating}>
              <Paper className={classes.paper} variant="outlined">
                <Typography variant="h6">Payment Details</Typography>

                <CardNameField margin="normal" />

                <CardNumberField number={number} margin="normal" />

                <CardExpiryDateField margin="normal" />

                <CardCVCField margin="normal" />

                <Box marginTop={3}>
                  <Typography variant="h6">Billing Address</Typography>

                  <BillingLine1Field margin="normal" />

                  <BillingLine2Field margin="normal" />

                  <BillingCityField margin="normal" />

                  <BillingStateField margin="normal" />

                  <BillingPostcodeField margin="normal" />

                  <BillingCountryField margin="normal" />
                </Box>
              </Paper>
            </AppFormWrapper>
          </Grid>
        </Grid>
      )}
    </>
  );
};
