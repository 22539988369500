import React, { useContext } from 'react';

import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import { createStyles, makeStyles, useTheme } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

import { getColor } from '../theme/styles';
import { ColorThemeContext } from '../contexts';
import { ColorOption } from '../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '45px',
      height: '45px',
      borderRadius: '12px',
      backgroundColor: theme.palette.background.default,
      '&:hover': {
        opacity: 0.5,
        color: 'inherit !important',
      },
    },
  })
);

interface Props extends IconButtonProps {
  variant?: ColorOption;
  isLarge?: boolean;
  isSmall?: boolean;
  isBorder?: boolean;
  borderColor?: string;
  backgroundColor?: string;
}

export const AppIconButton = React.forwardRef((props: Props, ref) => {
  const classes = useStyles();
  const theme = useTheme();
  const { colorTheme } = useContext(ColorThemeContext);
  const { variant, isLarge, isBorder, children, isSmall, borderColor, backgroundColor, ...restProps } =
    props;

  const colors: any = getColor(colorTheme === 'dark');

  return (
    <IconButton
      disableTouchRipple
      classes={classes}
      style={{
        backgroundColor: backgroundColor || colors[`${variant}BG`],
        color: theme.palette.type === 'dark' ? '#fff' : colors[`${variant}`],
        height: isLarge ? '56px' : isSmall ? '32px' : '40px',
        width: isLarge ? '56px' : isSmall ? '32px' : '40px',
        padding: isLarge ? '12px' : isSmall ? '0px' : '6px',
        border: isBorder ? `2px solid ${borderColor || theme.palette.divider}` : 'none',
        borderRadius: isSmall ? '8px' : '12px',
      }}
      innerRef={ref}
      {...restProps}>
      {children}
    </IconButton>
  );
});
