import React, { useMemo } from 'react';

import {
  companyRegistrationRequest,
  createPayScheduleRequest,
  uploadPayScheduleContractWrapperRequest,
} from '@vyce/core/src/api/pay';
import { PayrollOnboarding } from '@vyce/core/src/views/payroll/PayrollOnboarding';

import { useActions, useTypedSelector } from '../../hooks';

export const EmployerPayrollOnboarding: React.FC = () => {
  const { setPosition, updateSelectedCompanySubscription } = useActions();
  const { access_token, selectedPosition, selectedCompany, selectedCompanyAppData } = useTypedSelector(
    state => state.helper
  );
  const { user } = useTypedSelector(state => state);
  const subscribed = useMemo(
    () => selectedCompanyAppData?.subscription?.active ?? false,
    [selectedCompanyAppData?.subscription?.active]
  );

  return (
    <PayrollOnboarding
      uploadPayScheduleContractWrapperRequest={uploadPayScheduleContractWrapperRequest}
      setPosition={setPosition}
      token={access_token}
      selectedPosition={selectedPosition}
      createPayScheduleRequest={createPayScheduleRequest}
      companyRegistrationRequest={companyRegistrationRequest}
      user={user}
      subscribed={subscribed}
      wasSubscribed={selectedCompany?.subscribed_company}
      updateSelectedCompanySubscription={updateSelectedCompanySubscription}
    />
  );
};
