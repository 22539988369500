import React, { useContext, useState } from 'react';

import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';

import useStyles from '../styles';
import { CISStatusData, Employee } from '../../../types';
import { AppA } from '../../../components';
import { IsVerified } from '../../../components';
import { getUKFormattedDate } from '../../../utils/dates';
import { checkEmployeeCISRequest } from '../../../api/legend/pay';
import { ButtonTitleWithLoading } from '../../../components/ButtonTitleWithLoading';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  cisStatus?: CISStatusData;
  employee?: Employee;
  showButton: boolean;
}

export const CISStatus: React.FC<Props> = ({ cisStatus, employee, showButton = false }) => {
  const { showNotification } = useContext(NotificationContext);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const checkCIS = async () => {
    if (!employee?.uuid) {
      return;
    }
    setLoading(true);
    try {
      await checkEmployeeCISRequest(employee.uuid);
      setLoading(false);
      showNotification({ message: 'CIS check has been started', options: { variant: 'success' } });
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <>
      <Box marginRight={1} marginBottom={2}>
        <Typography className={classes.tabTitle}>CIS Status</Typography>
      </Box>

      <Paper className={classes.formPaper} variant="outlined">
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <Box>
              <Box display="flex" alignItems="center">
                <Typography variant="subtitle2">CIS Tax Status</Typography>
                <IsVerified verified={!!employee?.cis_verified} />
              </Box>
              <Typography style={{ textTransform: 'uppercase' }} variant="caption">
                {employee?.display_cis_taxation_status}
              </Typography>
            </Box>
          </Grid>

          {employee?.cis_verification_date && (
            <Grid item xs={12} lg={6}>
              <Typography variant="subtitle2">Verification Date</Typography>
              <Typography variant="caption">
                {getUKFormattedDate(employee?.cis_verification_date)}
              </Typography>
            </Grid>
          )}
        </Grid>

        <Box marginTop={2} display="flex" flexDirection="column">
          {showButton && (
            <Button
              onClick={checkCIS}
              color="primary"
              size="small"
              disabled={loading}
              style={{ width: 150, marginBottom: 8 }}
              variant="contained">
              <ButtonTitleWithLoading title="Re-Verify CIS" loaderVariant="paper" loading={loading} />
            </Button>
          )}
          {cisStatus?.response_doc && (
            <Box marginTop={1}>
              <AppA href={cisStatus.response_doc} content="Response from HMRC" />
            </Box>
          )}
          {cisStatus?.request_doc && (
            <Box marginTop={1}>
              <AppA href={cisStatus.request_doc} content="Raw XML Request to HMRC" />
            </Box>
          )}
          {cisStatus?.acknowledgement_doc && (
            <Box marginTop={1}>
              <AppA href={cisStatus.acknowledgement_doc} content="Acknowledgement" />
            </Box>
          )}
        </Box>
      </Paper>
    </>
  );
};
