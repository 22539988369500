import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { Box } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import type { GridColDef, GridSortModel } from '@mui/x-data-grid';

import { useTable } from '@vyce/core/src/hooks/useTable';
import { GetEmployeeTimesheetsRequest } from '@vyce/core/src/api/types';
import { FilterSection, EnrichedTimesheetLine } from '@vyce/core/src/types';
import { formatTableDate, getTaxPeriod, getTaxYearFilters } from '@vyce/core/src/utils/dates';
import { AppDataGrid, AppLink, AppMobileDataGrid, FilterSystem } from '@vyce/core/src/components';
import type { RowSchemaProps } from '@vyce/core/src/components';
import { currencyFormatter } from '@vyce/core/src/utils';
import { getUrlItems } from '@vyce/core/src/utils/url';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { formatSortModel } from '@vyce/core/src/utils/sorting';
import { DeviceContext } from '@vyce/core/src/contexts';

interface Props {
  getEmployeeTimesheetsRequest: (
    params: GetEmployeeTimesheetsRequest
  ) => Promise<{ data: { items: EnrichedTimesheetLine[]; count: number } }>;
  token: string;
  companyId?: string;
  employeeId?: string;
  unit?: string;
  withProfileButton?: boolean;
  onlySubmitted?: boolean;
}

type EnrichedTimesheetLineProps = EnrichedTimesheetLine & { id: string };

const defaultSortModel: GridSortModel = [{ field: 'payment_date', sort: 'desc' }];

export const EmployeeTimesheets: React.FC<Props> = ({
  getEmployeeTimesheetsRequest,
  companyId,
  employeeId,
  unit,
  withProfileButton,
  onlySubmitted,
}) => {
  const [timesheetLines, setTimesheetLines] = useState<EnrichedTimesheetLineProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [filters, setFilters] = useState<any>();
  const history = useHistory();
  const mountedRef = useRef(true);
  const { id } = useParams<{ id: string }>();
  const { isMobile } = useContext(DeviceContext);
  const { sortModel, offset, total, page, setTotal, handleSortModelChange, handlePageChange } = useTable({
    defaultSortModel,
  });

  const pageCount = Math.ceil(total / GRID_PAGE_SIZE);

  const columns: GridColDef[] = [
    {
      field: 'tax_year_end',
      headerName: 'Period',
      disableColumnMenu: true,
      valueFormatter: params => getTaxPeriod(params.row.tax_week_end, params.row.tax_year_end),
      flex: 0.5,
      minWidth: 140,
    },
    {
      field: 'payment_date',
      headerName: 'Payment Date',
      disableColumnMenu: true,
      valueGetter: params => formatTableDate(params.row?.payment_date, false),
      minWidth: 150,
    },
    {
      field: 'company',
      headerName: 'Company',
      renderCell: params => (
        <Box display="flex" width="100%">
          <AppLink
            to={`${history.location.pathname}/${params.row.company?.name}_${params.row.company?.company_id}`}>
            {params.row?.company?.name}
          </AppLink>
        </Box>
      ),
      flex: 0.8,
      sortable: false,
      disableColumnMenu: true,
      minWidth: 120,
    },
    {
      field: 'basic_units',
      headerName: 'Hours/Days',
      flex: 0.5,
      disableColumnMenu: true,
      minWidth: 140,
    },
    {
      field: 'basic_rate',
      headerName: 'Rate',
      valueFormatter: params => currencyFormatter.format(params.row.basic_rate),
      flex: 0.5,
      disableColumnMenu: true,
      minWidth: 100,
    },
    {
      field: 'overtime_units',
      headerName: 'O/T Hours/Days',
      disableColumnMenu: true,
      minWidth: 150,
    },
    {
      field: 'overtime_rate',
      headerName: 'O/T Rate',
      valueFormatter: params => currencyFormatter.format(params.row.overtime_rate),
      flex: 0.5,
      disableColumnMenu: true,
      minWidth: 120,
    },
    {
      field: 'adjustments',
      headerName: 'Adjustments',
      valueFormatter: params => currencyFormatter.format(params.row.adjustments),
      disableColumnMenu: true,
      minWidth: 140,
    },
    {
      field: 'expenses',
      headerName: 'Expenses',
      valueFormatter: params => currencyFormatter.format(params.row.expenses),
      flex: 0.5,
      disableColumnMenu: true,
      minWidth: 100,
    },
    {
      field: 'miles',
      headerName: 'Mileage',
      flex: 0.5,
      disableColumnMenu: true,
      minWidth: 100,
    },
    {
      field: 'gross',
      headerName: 'Gross Pay',
      valueFormatter: params => currencyFormatter.format(params.row.gross),
      flex: 0.5,
      minWidth: 120,
      disableColumnMenu: true,
    },
  ];

  const mobileOptions: RowSchemaProps<EnrichedTimesheetLineProps>[][] = [
    [
      {
        field: 'tax_year_end',
        titleName: 'Period',
        valueFormatter: row => getTaxPeriod(row.tax_week_end, row.tax_year_end),
      },
      {
        field: 'payment_date',
        titleName: 'Payment Date',
        valueGetter: row => formatTableDate(row?.payment_date, false),
      },
    ],
    [
      {
        field: 'company',
        titleName: 'Company',
        renderCell: row => (
          <Box display="flex" width="100%">
            <AppLink to={`${history.location.pathname}/${row.company?.name}_${row.company?.company_id}`}>
              {row?.company?.name}
            </AppLink>
          </Box>
        ),
      },
    ],
    [
      {
        field: 'basic_units',
        titleName: 'Hours/Days',
      },
      {
        field: 'basic_rate',
        titleName: 'Rate',
        valueFormatter: row => currencyFormatter.format(row.basic_rate),
      },
    ],
    [
      {
        field: 'overtime_units',
        titleName: 'O/T Hours/Days',
      },
      {
        field: 'overtime_rate',
        titleName: 'O/T Rate',
        valueFormatter: row => currencyFormatter.format(row.overtime_rate),
      },
    ],
    [
      {
        field: 'adjustments',
        titleName: 'Adjustments',
        valueFormatter: row => currencyFormatter.format(row.adjustments),
      },
      {
        field: 'expenses',
        titleName: 'Expenses',
        valueFormatter: row => currencyFormatter.format(row.expenses),
      },
    ],
    [
      {
        field: 'miles',
        titleName: 'Mileage',
      },
      {
        field: 'gross',
        titleName: 'Gross Pay',
        valueFormatter: row => currencyFormatter.format(row.gross),
      },
    ],
  ];

  const shortMobileOptions: RowSchemaProps<EnrichedTimesheetLineProps>[] = [
    {
      field: 'tax_year_end',
      valueFormatter: row => getTaxPeriod(row.tax_week_end, row.tax_year_end),
    },
    {
      field: 'basic_units',
      valueFormatter: row => `${row.basic_units} hours`,
    },
  ];

  const mobileColumnNames = [
    { headerName: 'Period', flex: '0 1 49%' },
    { headerName: 'Hours / Days', flex: '0 1 45%' },
    {
      headerName: 'View',
      position: 'absolute',
      top: '7px',
      right: '33px',
    },
  ];

  const filtersSections: FilterSection[] = [
    {
      title: 'Period',
      expanded: true,
      filters: [
        {
          type: 'select',
          multiple: false,
          label: 'Tax Year',
          field: 'tax_year_end',
          values: ['', ...getTaxYearFilters()],
          defaultValue: '',
        },
      ],
    },
  ];

  const getTimesheets = useCallback(async () => {
    try {
      const urlItems = getUrlItems(id);
      const userId = employeeId || urlItems.id;
      if (!mountedRef.current || !userId) return;

      setLoading(true);
      const res = await getEmployeeTimesheetsRequest({
        userId,
        companyId,
        data: {
          offset,
          order_by: formatSortModel<EnrichedTimesheetLineProps>(sortModel),
          tax_year_end: filters?.tax_year_end?.value,
          limit: GRID_PAGE_SIZE,
          pay_run_status: onlySubmitted ? 'submitted' : undefined,
        },
      });
      setLoading(false);

      setTimesheetLines(res.data.items.map((item, i) => ({ ...item, id: String(i) })));
      setTotal(res.data.count);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  }, [companyId, employeeId, filters, id, offset, onlySubmitted, sortModel]);

  const paginationOptions = useMemo(
    () => ({
      page,
      pageSize: GRID_PAGE_SIZE,
      pageCount,
      rowCount: total,
      setPage: handlePageChange,
    }),
    [page, pageCount, total, handlePageChange]
  );

  const handleFilterChange = (newFilters: any) => {
    setFilters(newFilters);
  };

  useEffect(() => {
    if (!mountedRef.current) return;
    getTimesheets();
    return () => {
      mountedRef.current = false;
    };
  }, [getTimesheets]);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="flex-end" mb={2}>
        <FilterSystem filtersSections={filtersSections} onFiltersChange={handleFilterChange} />
      </Box>

      {isMobile ? (
        <AppMobileDataGrid
          rows={timesheetLines}
          rowsSchema={mobileOptions}
          loading={loading}
          shortSchema={shortMobileOptions}
          paginationOptions={paginationOptions}
          columnNames={mobileColumnNames}
        />
      ) : (
        <AppDataGrid
          noPaper
          rows={timesheetLines}
          height={'calc(100vh - 240px)'}
          getRowId={row => row.uuid}
          loading={loading}
          columns={columns}
          rowCount={total}
          paginationMode="server"
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          onPageChange={handlePageChange}
          pageSize={GRID_PAGE_SIZE}
          rowsPerPageOptions={[GRID_PAGE_SIZE]}
          disableSelectionOnClick
          unit={unit}
          withProfileButton={withProfileButton}
        />
      )}
    </>
  );
};
