import { useContext, useEffect, useState } from 'react';

import { ColorThemeContext } from '../../../contexts';
import { DARK, DARK_STYLE_ID, LIGHT, LIGHT_STYLE_ID } from '../constants';
import config from '../../../api/config';

export const useMapStyle = () => {
  const [mapStyleId, setMapStyleId] = useState<string>(LIGHT_STYLE_ID);
  const [freeUrl, setFreeUrl] = useState<string>(LIGHT);
  const { colorTheme } = useContext(ColorThemeContext);

  useEffect(() => {
    const styleId = colorTheme === 'dark' ? DARK_STYLE_ID : LIGHT_STYLE_ID;
    const free = colorTheme === 'dark' ? DARK : LIGHT;
    setFreeUrl(free);
    setMapStyleId(styleId);
  }, [colorTheme]);

  return {
    mapStyleId,
    freeUrl,
    tileLayerUrl: `https://api.mapbox.com/styles/v1/nmaksimtsev/${mapStyleId}/tiles/256/{z}/{x}/{y}@2x?access_token=${config.mapBoxKey}`,
  };
};
