import React, { useMemo } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { FormProvider } from 'react-hook-form';

import { RiGraduationCapLine } from 'react-icons/ri';
import { TbCertificate } from 'react-icons/tb';
import { AiOutlinePlus } from 'react-icons/ai';

import { AppToggle, ButtonTitleWithLoading } from '@vyce/core/src/components';

import { MAIN_CONTAINER_ID, Modes, QualificationTypes, CUSTOM_SOURCE } from './config';
import useStyles from './styles';
import { ListedQualificationForm } from './components/ListedQualificationForm';
import { NotListedIndustryQualificationForm } from './components/NotListedIndustryQualificationForm';
import { NotListedAcademicQualificationForm } from './components/NotListedAcademicQualificationForm';
import { DocumentUploader } from './components/DocumentUploader';
import { useModuleData } from './hooks/useData';
import { Props } from './types';

const toggleOptions = [
  { value: 'industry', title: 'Industry', icon: <TbCertificate size="20px" /> },
  { value: 'academic', title: 'Academic', icon: <RiGraduationCapLine size="20px" /> },
];

export const QualificationDialog = (props: Props) => {
  const {
    isOpen,
    isShowPhotosBlock,
    isManualMode,
    selectedQualificationType,
    selectedQualificationSource,
    methods,
    photos,
    editMode,
    listedPresetFields,
    isQualificationLoading,
    tags,
    isAdmin,
    onSubmit,
    setDialogClose,
    setManualMode,
    setSelectedQualificationType,
    handleAddPhoto,
    handleRemovePhoto,
    setShowPhotosBlockTrue,
    setSubstring,
  } = useModuleData(props);

  const classes = useStyles();
  const theme = useTheme<Theme>();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isIndustry = selectedQualificationType === QualificationTypes.industry;
  const isAcademic = selectedQualificationType === QualificationTypes.academic;
  const isCustomSource = selectedQualificationSource === CUSTOM_SOURCE;
  const showAddPhotoButton =
    !isShowPhotosBlock && ((isManualMode && isIndustry) || isAcademic || isCustomSource);

  const formComponent = useMemo(() => {
    if (isAcademic) {
      return (
        <NotListedAcademicQualificationForm
          tags={tags}
          setSubstring={setSubstring}
          isAdmin={isAdmin}
          key="formComponent"
        />
      );
    }
    if (!isManualMode && selectedQualificationSource !== CUSTOM_SOURCE)
      return <ListedQualificationForm listedPresetFields={listedPresetFields} key="formComponent" />;

    return (
      <NotListedIndustryQualificationForm
        tags={tags}
        setSubstring={setSubstring}
        isAdmin={isAdmin}
        key="formComponent"
      />
    );
  }, [
    isManualMode,
    selectedQualificationSource,
    isAcademic,
    tags,
    isAdmin,
    listedPresetFields,
    setSubstring,
  ]);

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={fullScreen}
      open={isOpen}
      classes={{ paper: classes.cardDialog }}
      onClose={setDialogClose}
      aria-labelledby="responsive-dialog-title">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle id="responsive-dialog-title">
            {editMode === 'update'
              ? `Update ${selectedQualificationType} qualification`
              : 'Add any qualifications'}
          </DialogTitle>
          <DialogContent>
            <Box display="flex" flexDirection="column" gridGap={16}>
              {editMode !== 'update' && (
                <AppToggle options={toggleOptions} onChange={setSelectedQualificationType} />
              )}
              {formComponent}
            </Box>
            <Box marginTop={2}>
              {!isManualMode && editMode !== Modes.update && isIndustry && (
                <Button color="primary" className={classes.customModeButton} onClick={setManualMode}>
                  Add a qualification not listed
                </Button>
              )}

              {showAddPhotoButton && (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AiOutlinePlus />}
                  onClick={setShowPhotosBlockTrue}>
                  Add up to 3 Photos
                </Button>
              )}
              {isShowPhotosBlock && (
                <DocumentUploader
                  photos={photos}
                  handleAddPhoto={handleAddPhoto}
                  handleRemovePhoto={handleRemovePhoto}
                />
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={setDialogClose} variant="outlined" size="large">
              Close
            </Button>
            <Button
              size="large"
              type="submit"
              color="primary"
              variant="contained"
              className={classes.confirmButton}>
              <ButtonTitleWithLoading
                title={editMode === Modes.update ? 'Update Qualification' : 'Add Qualification'}
                loaderVariant="paper"
                loading={isQualificationLoading}
              />
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
