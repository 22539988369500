import React, { memo, useEffect, useState } from 'react';

import { createStyles, makeStyles, Tab, Tabs, Theme, useTheme } from '@material-ui/core';
import { Link, useHistory, useLocation } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';

import { TabItem } from '../types';

const useStyles = makeStyles<any>((theme: Theme) =>
  createStyles({
    tab: {
      textTransform: 'none',
      letterSpacing: 'none',
      minWidth: '120px',
      [theme.breakpoints.down('md')]: {
        minWidth: '90px',
      },
    },
    tabsRoot: {
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    tabsContainer: {
      flexGrow: 1,
      maxWidth: '100%',
      backgroundColor: theme.palette.background.paper,

      '&:not(:has(.MuiTabs-scrollButtons))': {
        paddingLeft: 40,
        [theme.breakpoints.down('sm')]: {
          paddingLeft: 16,
        },
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'none',
        width: '100vw',
      },
    },
    tabsContainerWithMargin: {
      [theme.breakpoints.down('sm')]: {
        marginLeft: '-16px',
        marginRight: '-16px',
      },
    },
  })
);

interface Props {
  tabItems: TabItem[];
  withSearch?: boolean;
  withMargins?: boolean;
}

const AppTabsComponent: React.FC<Props> = ({ tabItems, withSearch, withMargins = false }) => {
  const classes = useStyles();
  const [value, setValue] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const a11yProps = (index: number) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  };

  useEffect(() => {
    for (let i = 0; i < tabItems.length; i++) {
      if (window.location.href.includes(tabItems[i].link)) {
        setValue(i);
        break;
      }
    }
  }, [tabItems]);

  useEffect(() => {
    if (withSearch) {
      setSearch(location.search);
    }
  }, [location]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      return history.listen(location => {
        for (let i = 0; i < tabItems.length; i++) {
          if (location.pathname.includes(tabItems[i].link)) {
            setValue(i);
            break;
          }
        }
      });
    }

    return () => {
      mounted = false;
    };
  }, [tabItems]);
  return (
    <div className={clsx(classes.tabsContainer, { [classes.tabsContainerWithMargin]: withMargins })}>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        scrollButtons={isMobile ? 'on' : 'auto'}
        variant="scrollable"
        className={classes.tabsRoot}>
        {tabItems.map((tab, index) => (
          <Tab
            key={tab.label}
            className={classes.tab}
            disableRipple
            cy-test-id={`${tab.label}-tab`}
            component={Link}
            to={tab.link + search}
            label={tab.label}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
    </div>
  );
};

export const AppTabs = memo(AppTabsComponent);
