import React, { useContext } from 'react';

import { Box } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { CompanyPreview } from '@vyce/core/src/views/preview/company/CompanyPreview';
import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { COMPANY_PERMISSIONS } from '@vyce/core/src/types';
import { getUrlItems } from '@vyce/core/src/utils/url';

import { useTypedSelector } from '../../../hooks';

export const CompanyPreviewWrapper: React.FC = () => {
  const { access_token } = useTypedSelector(state => state.helper);
  const { positions } = useTypedSelector(state => state.user);
  const { isMobile } = useContext(DeviceContext);
  const { id } = useParams<{ id: string }>();
  const urlItems = getUrlItems(id);
  const companyId = urlItems?.id;
  const position = positions?.find(item => item.company.uuid === companyId);

  return (
    <Box padding={isMobile ? 1 : 5} height="100%">
      <CompanyPreview
        editable={!!position?.permissions?.includes(COMPANY_PERMISSIONS.CAN_EDIT)}
        token={access_token}
      />
    </Box>
  );
};
