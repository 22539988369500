import React from 'react';

import { Box, Dialog, DialogContent, useMediaQuery, useTheme } from '@material-ui/core';

import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';

import { SuccessStep } from './SuccessStep';

interface Props {
  open: boolean;
  setOpen: Function;
}

export const SuccessDialog: React.FC<Props> = ({ open, setOpen }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      container={document.getElementById(MAIN_CONTAINER_ID)}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="responsive-dialog-title">
      <DialogContent>
        <Box padding={2}>
          <SuccessStep />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
