import React, { useContext } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { DeviceContext } from '../../../contexts';
import { AppCheckbox, AppKeyboardDatePicker } from '../../../components/inputs';
import { MAIN_CONTAINER_ID, RTW_VALUES } from '../../../constants';
import { RTWVerification } from '../../../types';
import { formatDate } from '../../../utils/dates';
import { ControlledSelect } from '../../../components/controlled-inputs/ControlledSelect';
import { ButtonTitleWithLoading } from '../../../components/ButtonTitleWithLoading';

const RTW_STATUS_OPTIONS = [
  { value: RTW_VALUES.INDEFINITE, name: 'Indefinite' },
  { value: RTW_VALUES.LIMITED, name: 'Limited' },
  { value: RTW_VALUES.REJECTED, name: 'Rejected' },
  { value: RTW_VALUES.NO_RTW, name: 'No RTW' },
];

const getStatusOption = (value: RTW_VALUES = RTW_VALUES.NO_RTW) => {
  return RTW_STATUS_OPTIONS.find(option => option.value === value);
};

interface Form {
  verified: boolean;
  check_date?: string | null;
  expiration_date?: string | null;
  status: { value: RTW_VALUES; name: string };
}

interface Props {
  open: boolean;
  loading: boolean;
  handleClose: Function;
  updateRtw: (data: Partial<RTWVerification>) => void;
  rtwVerification?: RTWVerification;
}

export const RtwManualVerificationDialog: React.FC<Props> = ({
  open,
  handleClose,
  loading,
  rtwVerification,
  updateRtw,
}) => {
  const { isMobile } = useContext(DeviceContext);
  const methods = useForm<Form>({
    defaultValues: {
      verified: !!rtwVerification?.verified,
      check_date: rtwVerification?.check_date || null,
      expiration_date: rtwVerification?.expiration_date || null,
      status: getStatusOption(rtwVerification?.status),
    },
  });
  const { control } = methods;

  const onSubmit = async (data: Partial<RTWVerification>) => {
    updateRtw({
      ...data,
      status: data.status?.value,
      expiration_date: data.expiration_date || null,
      check_date: data.check_date || null,
    });
  };

  return (
    <Dialog
      fullScreen={isMobile}
      container={document.getElementById(MAIN_CONTAINER_ID)}
      open={open}
      onClose={() => handleClose()}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle>Verify Right to Work manually</DialogTitle>

          <DialogContent>
            <Box width={isMobile ? '100%' : 400}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                  name="check_date"
                  control={control}
                  render={props => (
                    <AppKeyboardDatePicker
                      margin="normal"
                      value={props.field.value || null}
                      onChange={data => props.field.onChange(formatDate(data))}
                      label="Date of Check"
                      format="dd/MM/yyyy"
                      fullWidth
                      disableFuture
                    />
                  )}
                />
              </MuiPickersUtilsProvider>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                  name="expiration_date"
                  control={control}
                  render={props => (
                    <AppKeyboardDatePicker
                      margin="normal"
                      value={props.field.value || null}
                      onChange={data => props.field.onChange(formatDate(data))}
                      label="Date of Expiry"
                      format="dd/MM/yyyy"
                      fullWidth
                    />
                  )}
                />
              </MuiPickersUtilsProvider>

              <ControlledSelect
                margin="normal"
                label="Status"
                name="status"
                items={RTW_STATUS_OPTIONS}
                multiple={false}
              />

              <Box display="flex" justifyContent="flex-end" marginTop={1}>
                <Controller
                  name="verified"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <AppCheckbox
                          onChange={e => field.onChange(e.target.checked)}
                          color="primary"
                          checked={field.value}
                        />
                      }
                      label="Verified"
                    />
                  )}
                />
              </Box>
            </Box>
          </DialogContent>

          <DialogActions>
            <Button style={{ width: 106 }} size="large" variant="outlined" onClick={() => handleClose()}>
              Close
            </Button>
            <Button style={{ width: 106 }} size="large" type="submit" color="primary" variant="contained">
              <ButtonTitleWithLoading title="Create" loaderVariant="paper" loading={loading} />
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
