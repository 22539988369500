import React, { ChangeEvent, useContext, useEffect, useState } from 'react';

import { Controller, FormProvider, useForm } from 'react-hook-form';

import { Box, Button, Divider, FormControlLabel, Typography } from '@material-ui/core';
import {
  getEmailUnsubscriptionGroupsRequest,
  subscribeRequest,
  unsubscribeRequest,
} from '@vyce/core/src/api/notifications';
import { EmailSubscriptionGroup } from '@vyce/core/src/types';
import { AppCheckbox } from '@vyce/core/src/components/inputs';
import { monochrome } from '@vyce/core/src/theme/styles';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import { useTypedSelector } from '../../hooks';
import useStyles from './styles';

interface Props {
  setShowFeedbackFrom: Function;
}

export const UnsubscribeForm: React.FC<Props> = ({ setShowFeedbackFrom }) => {
  const classes = useStyles();
  const { handleServerError } = useContext(NotificationContext);
  const [groups, setGroups] = useState<EmailSubscriptionGroup[]>([]);
  const [unsubscribeEntirely, setUnsubscribeEntirely] = useState<boolean>(false);
  const methods = useForm<any>({
    defaultValues: {},
  });
  const { setValue, control } = methods;
  const { access_token } = useTypedSelector(state => state.helper);

  const getGroups = async () => {
    try {
      const res = await getEmailUnsubscriptionGroupsRequest();
      const groups: EmailSubscriptionGroup[] = res.data || [];
      // TODO use 'show' flag when it will be ready on the backend
      const availableToUnsubscribeGroups = groups.filter(
        group => group.name !== 'Important Vyce Account Updates'
      );
      setAll(true, availableToUnsubscribeGroups);
      setGroups(availableToUnsubscribeGroups);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getGroups();
  }, []);

  const onSubmit = async (data: any) => {
    const unsubscribeIds: string[] = [];
    const subscribeIds: string[] = [];
    Object.keys(data).forEach(key => {
      if (data[key]) {
        subscribeIds.push(key);
      } else {
        unsubscribeIds.push(key);
      }
    });
    try {
      if (unsubscribeIds.length) {
        await unsubscribeRequest({ token: access_token, groups: unsubscribeIds });
      }
      if (subscribeIds.length) {
        await subscribeRequest({ token: access_token, groups: subscribeIds });
      }
      setShowFeedbackFrom(true);
    } catch (e) {
      handleServerError(e);
    }
  };

  const handleUnsubscribeEntirelyClick = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    if (checked) {
      setAll(false, groups);
    } else {
      setAll(true, groups);
    }
    setUnsubscribeEntirely(checked);
  };

  const handleCheckboxChange = (checked: boolean, field: any) => {
    field.onChange(checked);
    if (checked) {
      setUnsubscribeEntirely(false);
    }
  };

  const setAll = (value: boolean, groups: EmailSubscriptionGroup[]) => {
    groups.forEach(group => setValue(group.id, value));
  };

  return (
    <>
      <Typography className={classes.title}>Let’s update your email preferences!</Typography>
      <Typography variant="subtitle1" color="textSecondary">
        Pick the ones you’d like to keep below:
      </Typography>

      <Box marginTop={4}>
        {!!groups.length && (
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              {groups.map(group => (
                <Box marginBottom={3} key={group.id} marginLeft="-8px">
                  <Controller
                    name={group.id}
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        classes={{ label: classes.label }}
                        control={
                          <AppCheckbox
                            onChange={e => handleCheckboxChange(e.target.checked, field)}
                            color="primary"
                            checked={field.value}
                          />
                        }
                        label={group.name}
                      />
                    )}
                  />
                </Box>
              ))}

              <Divider style={{ height: 2, backgroundColor: monochrome.mediumlight }} />

              <Box marginTop={3} marginLeft="-8px" marginBottom={5}>
                <FormControlLabel
                  classes={{ label: classes.label }}
                  control={
                    <AppCheckbox
                      onChange={handleUnsubscribeEntirelyClick}
                      color="primary"
                      checked={unsubscribeEntirely}
                    />
                  }
                  label="Unsubscribe entirely"
                />
              </Box>

              <Button type="submit" variant="contained" color="primary" size="large" fullWidth>
                Update
              </Button>
            </form>
          </FormProvider>
        )}
      </Box>
    </>
  );
};
