import React from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { DetailsIcon } from './DetailsIcon';
import { monochrome } from '../theme/styles';

interface Props {
  text: string;
  additionalText?: string;
  helpText?: string;
  popoverClass?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: '18px',
      lineHeight: '32px',
    },
    subtitle: {
      fontSize: '15px',
      lineHeight: '27px',
      color: theme.palette.type === 'dark' ? monochrome.light : monochrome.dark,
    },
    iconWrapper: {
      float: 'right',
      display: 'flex',
      alignItems: 'center',
      height: '24px',
      width: '24px',
      marginTop: '3px',
    },
  })
);

export const OnboardingStepTitle: React.FC<Props> = ({ text, helpText, additionalText, popoverClass }) => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.title}>
        {helpText && (
          <span className={classes.iconWrapper}>
            <DetailsIcon content={<>{helpText}</>} popoverClass={popoverClass} />
          </span>
        )}
        {text}
      </Typography>

      {additionalText && (
        <Typography className={classes.subtitle} color="textSecondary">
          {additionalText}
        </Typography>
      )}
    </>
  );
};
