import { axios } from '@vyce/core/src/api';

export const changeEmailByAdminRequest = (data: {
  recaptcha_token: string;
  user_id: string;
  email: string;
}) => {
  return axios.post(`/auth/change_email_by_admin`, data);
};

export const changePhoneByAdminRequest = (data: {
  recaptcha_token: string;
  user_id: string;
  phone: string;
}) => {
  return axios.post(`/auth/phone/change_phone_by_admin`, data);
};
