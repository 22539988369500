import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useTypedSelector } from '../hooks/useTypedSelector';

export const EmployeeRoute = ({ component, ...rest }: any) => {
  const { user } = useTypedSelector((state) => state);

  const allowed = user.uuid && user.employee;
  const pathname = user.uuid ? '/dashboard' : '/auth/login';

  const routeComponent = (props: any) =>
    allowed ? React.createElement(component, props) : <Redirect to={{ pathname, state: { from: props.location.pathname } }} />;
  return <Route {...rest} render={routeComponent} />;
};
