import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { Box, Dialog } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { createCompanyWithOwnerRequest } from '@vyce/core/src/api/companies';
import { AppOnboarding, HowItWorks, OnboardingStepTitle } from '@vyce/core/src/components';
import { Greetings } from '@vyce/core/src/components/onboarding';
import { DeviceContext } from '@vyce/core/src/contexts';
import { ActiveOnboardingStep, OnboardingStep, RequestPositionDataData } from '@vyce/core/src/types';
import { getLocationDetails } from '@vyce/core/src/utils/location';
import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import { useActions, useTypedSelector } from '../../../hooks';
import { EMPLOYER_STEPS } from '../constants';
import { CompanyStep, JobTitleStep } from './steps';

export interface EmployerOnboardingForm {
  role: string;
  company: any;
}

export const EmployerOnboarding: React.FC = () => {
  const methods = useForm<EmployerOnboardingForm>({
    defaultValues: {
      role: '',
      company: undefined,
    },
  });
  const [loading, setLoading] = useState<boolean>(false);
  const { isMobile } = useContext(DeviceContext);
  const [open, setOpen] = useState(true);
  const { watch } = methods;
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const { access_token } = useTypedSelector(state => state.helper);
  const { first_name } = useTypedSelector(state => state.user);
  const company = watch('company');
  const childRef = useRef<any>();
  const { me, setShowTour } = useActions();
  const history = useHistory();

  const steps: OnboardingStep[] = useMemo(
    () => [
      {
        name: 'Greetings',
        component: (
          <Greetings firstName={first_name} subtitle="Now, let’s quickly create you an awesome profile!" />
        ),
      },
      {
        name: 'JobTitleStep',
        component: <JobTitleStep />,
      },
      {
        name: 'CompanyStep',
        component: (
          <Box>
            <OnboardingStepTitle text="What's your company?" />

            <CompanyStep />
          </Box>
        ),
      },
    ],
    [first_name]
  );
  const [activeStep, setActiveStep] = useState<ActiveOnboardingStep>({ step: steps[0], number: 0 });

  useEffect(() => {
    if (access_token) {
      me();
    }
  }, [access_token]);

  useEffect(() => {
    childRef?.current?.scrollDown();
  }, [company]);

  const createCompanyWithOwner = async (position: RequestPositionDataData) => {
    if (!position.company?.reg_number) {
      return showNotification({
        message: 'Please choose your company from the list of registered companies',
        options: { variant: 'error' },
      });
    }
    try {
      await createCompanyWithOwnerRequest(position);
      await me();
      setShowTour(true);
      history.push('/dashboard');
    } catch (e) {
      handleServerError(e);
    }
  };

  const handleNext = async (data: EmployerOnboardingForm) => {
    if (activeStep.number === steps.length - 1) {
      setLoading(true);
      const position = await preparePositionData(data);
      await createCompanyWithOwner(position);
      setLoading(false);
    } else {
      childRef?.current?.nextStep();
    }
  };

  const preparePositionData = async (data: EmployerOnboardingForm): Promise<RequestPositionDataData> => {
    return {
      role: data.role,
      company: data.company,
    };
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box paddingTop={10}>
      <AppOnboarding
        activeStep={activeStep}
        currentMessage=""
        setActiveStep={setActiveStep}
        methods={methods}
        handleNext={handleNext}
        steps={steps}
        loading={loading}
        ref={childRef}
      />

      <Dialog
        fullScreen={isMobile}
        container={document.getElementById(MAIN_CONTAINER_ID)}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <HowItWorks steps={EMPLOYER_STEPS} handleClose={handleClose} />
      </Dialog>
    </Box>
  );
};
