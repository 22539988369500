import React from 'react';
import { Typography } from '@material-ui/core';

interface Props {
  userName?: string;
}
export const WelcomeStep = ({ userName = '' }: Props) => {
  return (
    <>
      <Typography variant="h5" style={{ fontWeight: 700 }}>
        Hey {userName}!
      </Typography>
      <Typography variant="caption" color="textSecondary">
        Please add your details to get set up.
      </Typography>
    </>
  );
};
