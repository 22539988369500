import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { GetCompanyTeamsDataRequest } from '@vyce/core/src/api/types';
import { getCompanyTeamsRequest } from '@vyce/core/src/api/teams';
import { LIST_STEP } from '@vyce/core/src/constants';
import { INTERFACE_PERMISSIONS, Team } from '@vyce/core/src/types';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import { useTypedSelector, useInviteButton } from 'src/hooks';
import { DEFAULT_TEAMS_LIST_SORTING } from '../config';

export const useTeamsData = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [teams, setTeams] = useState<Team[] | null>(null);
  const [teamsCount, setTeamsCount] = useState(0);
  const [isOpenSuccessDialog, setOpenSuccessDialog] = useState<boolean>(false);
  const {
    isInviteFormOpen,
    subscribeLoading,
    isActivateSubscriptionDialogOpen,
    simpleSubscribe,
    setCloseInviteForm,
    handleInviteButtonClick,
    closeActivateSubscriptionDialog,
  } = useInviteButton();

  const { access_token, selectedCompany, selectedPosition, selectedCompanyAppData } = useTypedSelector(
    state => state.helper
  );
  const { first_name } = useTypedSelector(state => state.user);
  const { pathname } = useLocation();
  const { handleServerError } = useContext(NotificationContext);

  const selectedCompanyId = selectedCompany?.uuid ?? '';

  const hasPermissions = useMemo(
    () => selectedPosition?.permissions.includes(INTERFACE_PERMISSIONS.TEAMS),
    [selectedPosition]
  );

  const hasBillingPermissions = useMemo(
    () => selectedPosition?.permissions.includes(INTERFACE_PERMISSIONS.BILLING),
    [selectedPosition]
  );

  const selectedCompanyIsSubscribed = selectedCompanyAppData?.subscription?.active ?? false;

  const hadSubscription =
    (!!selectedCompanyAppData?.subscription?.uuid && !selectedCompanyAppData.subscription.active) || false;

  const firstIdOfTeam = useMemo(() => {
    const team = (teams?.length && teams[0]) || ({} as Team);
    if (!(team && team.uuid && team.name)) return '';
    return `${team.name}_${team.uuid}`;
  }, [teams]);

  const getTeams = useCallback(
    async (offset = 0, substring = '', sorting = DEFAULT_TEAMS_LIST_SORTING) => {
      if (!selectedCompanyId || !hasPermissions) {
        return;
      }

      setLoading(true);
      const requestData: GetCompanyTeamsDataRequest = {
        offset,
        limit: LIST_STEP,
        substring,
        order_by: [sorting],
      };
      try {
        const {
          data: { items, count },
        } = await getCompanyTeamsRequest({
          companyId: selectedCompanyId,
          requestData,
        });
        setTeams(items);
        setTeamsCount(count);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        handleServerError(e);
      }
    },
    [access_token, selectedCompanyId, hasPermissions]
  );

  useEffect(() => {
    if (hasPermissions && selectedCompanyIsSubscribed) {
      getTeams();
    } else {
      setLoading(false);
    }
  }, [selectedCompany, pathname, selectedCompanyIsSubscribed]);

  const contextValue = useMemo(
    () => ({
      loading,
      teams,
      teamsCount,
      selectedCompanyId,
      getTeams,
      setOpenSuccessDialog,
      onOpenUniversalInviteForm: handleInviteButtonClick,
    }),
    [loading, teams, teamsCount, selectedCompanyId, getTeams]
  );

  return {
    loading,
    contextValue,
    teams,
    teamsCount,
    isOpenSuccessDialog,
    firstIdOfTeam,
    first_name,
    isInviteFormOpen,
    selectedCompanyIsSubscribed,
    hasPermissions,
    hasBillingPermissions,
    subscribeLoading,
    isActivateSubscriptionDialogOpen,
    hadSubscription,
    simpleSubscribe,
    setOpenSuccessDialog,
    getTeams,
    setCloseInviteForm,
    closeActivateSubscriptionDialog,
  };
};
