import React from 'react';

import { Box, Typography, Grid } from '@material-ui/core';
import clsx from 'clsx';

import { ReactComponent as VerifiedSmallLabel } from '../../../../assets/svg/verified-small-label-icon.svg';
import useStyles from '../../styles';
import { BoxWrapper } from './BoxWrapper';

interface DetailItem {
  title: string;
  verified: boolean;
}

interface HiringRightToWorkProps {
  rtw: DetailItem;
  id: DetailItem;
}

const DataRowItem = ({ title, verified }: DetailItem) => {
  const classes = useStyles();
  return (
    <Box className={classes.recordWrapper}>
      <Grid item xs={12} sm={6} lg={5}>
        <Box display="flex" alignItems="center" height="100%">
          <Typography className={classes.bold}>{title}:</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} lg={7}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" flexDirection="column">
            <Typography
              color={verified ? 'primary' : 'error'}
              className={clsx(classes.smallText, {
                [classes.bold]: !verified,
                [classes.smallBold]: verified,
              })}>
              {verified ? 'Provided' : 'Not provided'}
            </Typography>
          </Box>

          {verified && <VerifiedSmallLabel />}
        </Box>
      </Grid>
    </Box>
  );
};

export const HiringRightToWork = ({ rtw, id }: HiringRightToWorkProps) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Typography className={classes.bold} variant="h6">
        ID & Right to Work
      </Typography>
      <BoxWrapper withGrowing={false} smallBlock gridGap={12}>
        <DataRowItem {...rtw} />
        <DataRowItem {...id} />
      </BoxWrapper>
    </Box>
  );
};
