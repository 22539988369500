import { makeStyles, Theme } from '@material-ui/core';
import { monochrome } from '@vyce/core/src/theme/styles';

export const CHART_COLORS = [
  '#610BEF',
  '#18BB0C',
  '#EAAC30',
  '#005BD4',
  '#CA024F',
  '#50C8FC',
  '#A996FF',
  '#067306',
  '#FF75CB',
  '#FFE6B0',
  '#A6F787',
];

export const DARK_CHART_COLORS = [
  '#A996FF',
  '#A6F787',
  '#EAAC30',
  '#50C8FC',
  '#FF75CB',
  '#005BD4',
  '#610BEF',
  '#18BB0C',
  '#CA024F',
  '#FFE6B0',
  '#067306',
];

export const useStyles = makeStyles((theme: Theme) => ({
  popup: {
    padding: '20px 24px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    border: `1px solid ${theme.palette.type === 'dark' ? monochrome.mediumdark : monochrome.mediumlight}`,
    backdropFilter: 'blur(5px)',
    boxShadow: 'none',
  },
  barText: {
    fontSize: 13,
    fontWeight: 500,
    lineHeight: '22px',
    fill: theme.palette.type === 'dark' ? monochrome.lightest : monochrome.darker,
  },
  chartWrapper: {
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
  },
  pageButtonsWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  pageIconButton: {
    border: `1px solid ${theme.palette.type === 'dark' ? monochrome.mediumdark : monochrome.mediumlight}`,
    borderRadius: 6,
    padding: '6px',
    color: theme.palette.type === 'dark' ? monochrome.lighter : monochrome.dark,
  },
  pageIconButtonDisabled: {
    opacity: 0.4,
  },

  widgetContainer: {
    '& > div > div': {
      position: 'absolute !important',
    },
  },
}));
