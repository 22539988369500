import React from 'react';
import { BsCheck } from 'react-icons/bs';
import { RiCloseLine } from 'react-icons/ri';
import { useTheme } from '@material-ui/core';

interface Props {
  verified: boolean;
  checkIconColor?: string;
}

export const IsVerified: React.FC<Props> = ({ verified, checkIconColor }) => {
  const theme = useTheme();
  return (
    <>
      {verified ? (
        <BsCheck size="25px" color={checkIconColor || theme.palette.success.main} />
      ) : (
        <RiCloseLine size="25px" color={theme.palette.error.main} />
      )}
    </>
  );
};
