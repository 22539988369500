import React, { useContext } from 'react';

import { Box, Typography } from '@material-ui/core';

import useStyles from '../../styles';
import { ReactComponent as VerifiedLabel } from '../../../../assets/svg/verified-label.svg';
import { CopyToClipboard } from '../../../../components/CopyToClipboard';
import { DeviceContext } from '../../../../contexts';

interface Props {
  label: string;
  value?: string | number | null;
  verified?: boolean;
  copyToClipboard?: boolean;
}

export const PreviewInfoRaw: React.FC<Props> = ({ label, verified, value, copyToClipboard }) => {
  const classes = useStyles();
  const { isMobile } = useContext(DeviceContext);

  return (
    <Box
      display="flex"
      alignItems={isMobile ? 'start' : 'center'}
      padding={!verified ? '8px 0' : 0}
      flexDirection={isMobile ? 'column' : 'row'}>
      <Typography className={classes.infoItemLabel}>{label}:</Typography>

      <Box flex={1} display="flex" alignItems="center" justifyContent="space-between" width="100%">
        <Box display="flex" gridGap={4}>
          <Typography variant="caption" className={classes.infoItemValue}>
            {value}
          </Typography>
          {copyToClipboard && value && <CopyToClipboard text={value.toString()} />}
        </Box>

        <Box width={76} marginLeft={1}>
          {verified && <VerifiedLabel />}
        </Box>
      </Box>
    </Box>
  );
};
