import React, { useContext, useState } from 'react';

import { Box, Grid, Typography } from '@material-ui/core';

import { DeviceContext } from '@vyce/core/src/contexts';
import { LogoIcon } from '@vyce/core/src/components/LogoIcon';
import config from '@vyce/core/src/assets/config';

import useStyles from './styles';
import { FeedbackForm } from './FeedbackForm';
import { UnsubscribeForm } from './UnsubscribeForm';

export const ManageEmailNotification: React.FC = () => {
  const { isMobile } = useContext(DeviceContext);
  const classes = useStyles();

  const [showFeedbackForm, setShowFeedbackFrom] = useState<boolean>(false);

  return (
    <Grid container className={classes.container}>
      {!isMobile && (
        <Grid item md={6} lg={7}>
          <Box
            className={classes.moduleImage}
            style={{ backgroundImage: `url(${config.settings.cancel_email_subscription})` }}></Box>
        </Grid>
      )}
      <Grid className={classes.formContainer} xs={12} item md={6} lg={5}>
        <Box maxWidth={422} display="flex" flexDirection="column">
          <LogoIcon width={54} height={49} />

          {showFeedbackForm ? (
            <FeedbackForm />
          ) : (
            <UnsubscribeForm setShowFeedbackFrom={setShowFeedbackFrom} />
          )}

          <Box flex={1} />

          <Typography color="textSecondary" className={classes.rights}>
            ©{new Date().getFullYear()} All Rights Reserved. Vyce®
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
