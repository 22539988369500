import React, { useState } from 'react';
import { Box, Container, Theme, Typography, useTheme } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { HowItWorksStep } from '../types';
import { AppMobileStepper } from './AppMobileStepper';
import { IoClose } from 'react-icons/io5';
import { AppIconButton } from './AppIconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';

interface Props {
  steps: HowItWorksStep[];
  handleClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 512,
      flexGrow: 1,
      width: '100%',
      margin: 'auto',
      height: 500,
      display: 'flex',
      flexDirection: 'column',
    },
    onboardingContainer: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: '32px',
      paddingLeft: 32,
      paddingRight: 32,
      alignItems: 'center',
    },
    onboardingContant: {
      flexGrow: 1,
    },
    closeButton: {
      position: 'absolute',
      top: 30,
      right: 30,
    },
    mainTitle: {
      textAlign: 'center',
      fontWeight: 700,
      marginTop: '40px',
      marginBottom: '4px',
    },
    img: {
      height: 248,
      minWidth: 248,
      borderRadius: '50px',
      objectFit: 'cover',
      cursor: 'pointer',
      display: 'block',
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        height: 230,
        minWidth: 230,
      },
    },
  }),
);

export const HowItWorks: React.FC<Props> = (props) => {
  const { steps, handleClose } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    if (activeStep === maxSteps - 1) {
      return handleClose();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const onImgClick = (link: string | undefined) => {
    if (!link) {
      return;
    }
    window.open(link, '_blank');
  };

  return (
    <Container
      component="main"
      maxWidth="md"
      className={classes.onboardingContainer}
    >
      <Box marginTop={9} width="100%">
        <AppIconButton
          onClick={handleClose}
          cy-test-id="close-how-it-works"
          className={classes.closeButton}
        >
          <IoClose size="28px" />
        </AppIconButton>

        <div className={classes.root}>
          <div className={classes.onboardingContant}>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {steps.map((step, index) => (
                <Box display="flex" justifyContent="center" key={step.label}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <img
                      className={classes.img}
                      src={step.imgPath}
                      alt={step.label}
                      onClick={() => onImgClick(step.link)}
                    />
                  ) : null}
                </Box>
              ))}
            </SwipeableViews>

            <Typography
              className={classes.mainTitle}
              align="center"
              variant="h5"
            >
              {steps[activeStep].subtitle}
            </Typography>

            <Typography align="center" color="textSecondary">
              {steps[activeStep].label}
            </Typography>
          </div>

          <AppMobileStepper
            handleNext={handleNext}
            isSkip={true}
            activeStep={activeStep}
            stepsLength={steps.length}
            handleBack={handleBack}
          />
        </div>
      </Box>
    </Container>
  );
};
