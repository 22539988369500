import { CustomControlSet } from '@vyce/core/src/components';

export const MAIN_CONTAINER_ID = 'main-container-id';

export const ItemTypes = {
  QUALIFICATION: 'Qualification',
};

export const LIST_STEP = 6;

export const previewControlSet: CustomControlSet = ['preview', 'download'];

export const dateFields = ['start_date', 'expiry_date', 'exam_date'];
