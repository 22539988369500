import React, { useCallback, useContext } from 'react';

import { useHistory } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import noop from 'lodash/noop';

import { ActivateSubscriptionDialog } from '@vyce/core/src/components/ActivateSubscriptionDialog';
import { TeamListModuleContext } from '@vyce/core/src/contexts';

import { CreateTeamDialog } from './components';
import { HoldingPage } from '../../components';
import config from '../../assets/config';
import { ModuleVideo } from '../../components/ModuleVideo';

interface Props {
  hasPermissions?: boolean;
  hadSubscription?: boolean;
}

export const GetStarted = ({ hasPermissions = true, hadSubscription }: Props) => {
  const {
    needToReset,
    isLoading,
    wasSubscribed,
    isOpenSubscriptionDialog = false,
    openTeamDialog,
    subscribe = noop,
    setSubscriptionDialogOpen = noop,
  } = useContext(TeamListModuleContext);
  const history = useHistory();

  const textInsteadButton = hasPermissions
    ? ''
    : 'Please contact your company Admin to get access to this module';
  const buttonText = hadSubscription ? 'Reactivate your Teams Module now...' : 'Create Team';

  const goToSubscription = () => {
    history.push('/subscriptions?dialog=true');
  };

  const handleCreateTeam = useCallback(() => {
    if (hadSubscription) {
      goToSubscription();
    } else {
      openTeamDialog();
    }
  }, [openTeamDialog, hadSubscription]);

  const handleCloseSubscriptionDialog = useCallback(() => {
    setSubscriptionDialogOpen(false);
  }, []);

  return (
    <>
      <HoldingPage
        title="Teams"
        buttonText={buttonText}
        handleButtonClick={handleCreateTeam}
        image={config.welcome.teams}
        textInsteadButton={textInsteadButton}>
        <Box marginBottom={4}>
          <Box marginTop={2} marginBottom={2}>
            <ModuleVideo
              previewUrl={config.previewVideo.teams}
              url="https://youtu.be/nkkUK0nEMZ4?si=nmScIRZJp_i46RZb"
            />
          </Box>

          <Box marginTop={1} display="flex">
            <Typography>
              Onboard, verify and manage your workforce all in one place with Vyce’s easy to use Teams
              Module.
            </Typography>
          </Box>

          <Box marginTop={3}>
            <ul style={{ paddingLeft: 16 }}>
              <li>
                <Typography>Paperless onboarding</Typography>
              </li>
              <li>
                <Typography>Real time workforce intelligence</Typography>
              </li>
              <li>
                <Typography>Better compliance</Typography>
              </li>
              <li>
                <Typography>Happier teams!</Typography>
              </li>
            </ul>
          </Box>

          <Box marginTop={3}>
            <Typography>Simply create a new Team to get started.</Typography>
          </Box>
        </Box>
      </HoldingPage>

      <CreateTeamDialog />

      <ActivateSubscriptionDialog
        callback={subscribe}
        open={isOpenSubscriptionDialog}
        onClose={handleCloseSubscriptionDialog}
        loading={isLoading}
        needToReset={needToReset}
        wasSubscribed={wasSubscribed}
      />
    </>
  );
};
