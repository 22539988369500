import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';

import { AppSelect } from '../inputs';
import { FieldProps } from '../../types';

export const rateUnits = ['hour', 'day', 'week', 'month'];

export const DefaultRateUnitSelect: React.FC<FieldProps> = ({ name = 'unit', margin, disabled }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={props => (
        <FormControl margin={margin} fullWidth variant="filled">
          <InputLabel id="rateUnits">Per what?</InputLabel>
          <AppSelect
            disabled={disabled}
            onChange={e => props.field.onChange(e.target.value)}
            value={props.field.value}
            labelId="rateUnits"
            fullWidth>
            {rateUnits.map(item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </AppSelect>
        </FormControl>
      )}
    />
  );
};
