import React from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  MenuItem,
  FormControlLabel,
  FormControl,
} from '@material-ui/core';
import clsx from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';

import { AppCheckbox, AppSelect } from '@vyce/core/src/components/inputs';

import { extraText, extraTitle, checkboxValues } from '../config';
import { SelectedModuleType, Options } from '../types';
import { useStyles } from '../styles';

export const ModuleItem = ({
  isSelected,
  type,
  options,
  name,
}: {
  isSelected: boolean;
  type: SelectedModuleType;
  options: Options[];
  name: string;
}) => {
  const classes = useStyles();
  const { control, setValue } = useFormContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(checkboxValues[type], event.target.checked);
  };

  return (
    <Accordion expanded={isSelected} className={classes.accordionWrapper}>
      <AccordionSummary className={classes.accordionSummary}>
        <Box className={classes.moduleItemTitle}>
          <Box flexBasis="70%">
            <label htmlFor={type}>Mirror {extraTitle[type]} module</label>
          </Box>
          <FormControlLabel
            control={<AppCheckbox checked={isSelected} onChange={handleChange} color="primary" />}
            label=""
          />
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box className={classes.moduleItemDetails}>
          <Box>
            <Box marginBottom={1}>
              <Typography className={clsx(classes.smallText, classes.additionalText)}>
                {extraText[type]}
              </Typography>
            </Box>
            <Controller
              control={control}
              name={name}
              render={({ field }) => (
                <FormControl>
                  <AppSelect className={classes.selectRoot} {...field}>
                    {options?.map(item => (
                      <MenuItem key={item.value} value={item.value}>
                        <span>{item.name}</span>
                      </MenuItem>
                    ))}
                  </AppSelect>
                </FormControl>
              )}
            />
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
