import Payment from 'payment';

const defaultCard = '9999 9999 9999 9999999';
const dinersclubCard = '9999 999999 9999';
const amexCard = '9999 999999 99999';
const simpleCard = '9999 9999 9999 9999';

export const getCardNumberMask = (number: string): string => {
  let mask;
  const issuer = Payment.fns.cardType(number);

  switch (issuer) {
    case 'amex':
      mask = amexCard;
      break;
    case 'dinersclub':
      mask = dinersclubCard;
      break;
    case 'visa':
      mask = simpleCard;
      break;
    case 'mastercard':
      mask = simpleCard;
      break;
    default:
      mask = defaultCard;
  }

  return mask;
};
