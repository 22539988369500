import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { Layout, Layouts } from 'react-grid-layout';
import debounce from 'lodash/debounce';

import { FilterSection, UserDashboard, WidgetItem } from '@vyce/core/src/types';
import { DeviceContext, TeamsDashboardModuleContext } from '@vyce/core/src/contexts';
import { generateQueryParamsFromObj } from '@vyce/core/src/utils/url';
import { getTeamsFilter } from '@vyce/core/src/modules/teamMembersModule/utils';
import { postExportQualifications } from '@vyce/core/src/api/teams';
import { useBooleanState } from '@vyce/core/src/hooks';

import { NotificationContext } from '../../../contexts/notificationContext';

export const useTeamsDashboardData = () => {
  const [teamsDashboard, setTeamsDashboard] = useState<UserDashboard>();
  const [filters, setFilters] = useState<any>();
  const { handleServerError } = useContext(NotificationContext);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [selectedTeamId, setSelectedTeamId] = useState('');
  const [isNotifyDialogOpen, openNotifyDialog, closeNotifyDialog] = useBooleanState(false);

  const {
    dashboards,
    first_name,
    companyDataLoading,
    selectedCompanyId,
    teams = [],
    updateUserDashboards,
  } = useContext(TeamsDashboardModuleContext);

  const history = useHistory();

  const { isMobile } = useContext(DeviceContext);

  const filtersSections: FilterSection[] = useMemo(() => [getTeamsFilter(teams)], [teams]);

  const onWidgetStatusChange = useCallback(
    (systemName: string) => {
      const dashboardWidgets = teamsDashboard?.widgets || [];
      if (dashboardWidgets.length === 0) return;
      const newWidgets: WidgetItem[] = dashboardWidgets.map(item => {
        return item.system_name === systemName ? { ...item, is_active: !item.is_active } : item;
      });

      setTeamsDashboard(value => ({ ...value, widgets: newWidgets }));
    },
    [teamsDashboard?.widgets]
  );

  const update = useCallback(
    debounce(dashboard => {
      if (!dashboard) {
        return;
      }
      updateUserDashboards({
        ...dashboards,
        teams: dashboard,
      });
    }, 1000),
    []
  );

  const exportQualifications = useCallback(async () => {
    if (!selectedCompanyId) {
      return;
    }
    try {
      const team_id = teams?.find(t => t.name === filters?.team_name)?.uuid;
      setButtonLoading(true);
      await postExportQualifications({
        company_id: selectedCompanyId,
        data: { team_id, limit: 3000 },
      });
      openNotifyDialog();
      setButtonLoading(false);
    } catch (e) {
      console.error(e);
      setButtonLoading(false);
      handleServerError(e);
    }
  }, [selectedCompanyId, teams, filters]);

  const onLayoutChange = (currentLayout: Layout[], newLayouts: Layouts) => {
    setTeamsDashboard(value => ({ ...value, layouts: newLayouts }));
  };

  const setTeamId = (teamName: string) => {
    const teamId = teams.find(item => item.name === teamName)?.uuid || '';
    setSelectedTeamId(teamId);
  };

  const handleFilterChange = (newFilters: any) => {
    if (newFilters.team_name) {
      setTeamId(newFilters.team_name);
    }
    history.push({
      pathname: history.location.pathname,
      search: generateQueryParamsFromObj(newFilters),
    });
  };

  const goToTeamsList = useCallback(() => history.push('/teams/list'), [history]);

  useEffect(() => {
    if (dashboards?.teams) {
      setTeamsDashboard(dashboards.teams);
    }
  }, [dashboards?.teams]);

  useEffect(() => {
    update(teamsDashboard);
  }, [selectedCompanyId, teamsDashboard, update]);

  useEffect(() => {
    const filters: any = queryString.parse(history.location.search, { parseNumbers: true });
    setFilters(filters);
    if (filters.team_name) setTeamId(filters.team_name);
  }, [history.location.search, teams]);

  return {
    isMobile,
    first_name,
    companyDataLoading,
    teamsDashboard,
    filters,
    teams,
    filtersSections,
    selectedCompanyId,
    selectedTeamId,
    buttonLoading,
    isNotifyDialogOpen,
    closeNotifyDialog,
    exportQualifications,
    handleFilterChange,
    onWidgetStatusChange,
    onLayoutChange,
    goToTeamsList,
  };
};
