import { useMemo } from 'react';

import { useActions, useTypedSelector, useInviteButton } from 'src/hooks';
import { WidgetModule } from 'src/modules';

export const useTimeDashboardData = () => {
  const { dashboards, first_name } = useTypedSelector(state => state.user);
  const { selectedCompanyAppData, selectedCompany, selectedPosition } = useTypedSelector(
    state => state.helper
  );
  const {
    isInviteFormOpen,
    subscribeLoading,
    isActivateSubscriptionDialogOpen,
    simpleSubscribe,
    setCloseInviteForm,
    handleInviteButtonClick,
    closeActivateSubscriptionDialog,
  } = useInviteButton();
  const { updateUserDashboards } = useActions();

  const contextOptions = useMemo(
    () => ({
      dashboards,
      name: first_name,
      selectedCompanyId: selectedCompany?.uuid ?? '',
      permissions: selectedPosition?.permissions ?? [],
      updateUserDashboards,
      widgetComponent: WidgetModule,
      onOpenUniversalInviteForm: handleInviteButtonClick,
    }),
    [
      dashboards,
      first_name,
      selectedCompanyAppData,
      selectedCompany?.uuid,
      selectedPosition?.permissions,
      updateUserDashboards,
    ]
  );
  return {
    isActivateSubscriptionDialogOpen,
    subscribeLoading,
    contextOptions,
    isInviteFormOpen,
    selectedCompany,
    simpleSubscribe,
    setCloseInviteForm,
    closeActivateSubscriptionDialog,
  };
};
