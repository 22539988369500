import React from 'react';

import { Typography } from '@material-ui/core';

import useStyles from '../../styles';

interface Props {
  title: string;
}

export const SectionTitle: React.FC<Props> = ({ title }) => {
  const classes = useStyles();

  return (
    <Typography variant="h6" className={classes.bold} color="textSecondary">
      {title}
    </Typography>
  );
};
