import React from 'react';

import { Box, Typography, Grid, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { DetailItem } from '@vyce/core/src/types';

import useStyles from '../../styles';
import { BoxWrapper } from './BoxWrapper';

interface PaymentsProps {
  payments: DetailItem;
  timesheets: DetailItem;
  agreements: DetailItem;
}

const DataRowItem = ({ title, value }: DetailItem) => {
  const classes = useStyles();

  if (!value) return null;
  return (
    <Box className={classes.recordWrapper} alignItems="center">
      <Grid item xs={12} sm={6} lg={5}>
        <Typography className={classes.bold}>{title}:</Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={7}>
        <Button component={Link} to={value} variant="outlined" className={classes.styledLink} size="small">
          {`View ${title}`}
        </Button>
      </Grid>
    </Box>
  );
};

export const PaymentsAndAgreements = ({ payments, timesheets, agreements }: PaymentsProps) => (
  <Box display="flex" flexDirection="column" height="100%">
    <Typography color="textSecondary" variant="h6">
      Payments & Agreements
    </Typography>
    <BoxWrapper gridGap={16}>
      <DataRowItem {...payments} />
      <DataRowItem {...timesheets} />
      <DataRowItem {...agreements} />
    </BoxWrapper>
  </Box>
);
