import React from 'react';

import noop from 'lodash/noop';
import * as GeoJSON from 'geojson';
import { LatLngExpression } from 'leaflet';

import { MapView } from '../../../map/MapView';
import { LocationAreaShapePreview } from '../../components/LocationAreaShapePreview';

interface Props {
  polygon?: GeoJSON.Polygon | null;
  centerPosition?: LatLngExpression;
  height: number;
}

export const LocationPreviewMap: React.FC<Props> = ({ polygon, centerPosition, height }) => {
  return (
    <MapView
      scrollWheelZoom={false}
      zoom={15}
      centerPosition={centerPosition}
      height={`${height}px`}
      markers={[]}
      onMarkerClick={noop}>
      <LocationAreaShapePreview centerPosition={centerPosition} polygon={polygon} />
    </MapView>
  );
};
