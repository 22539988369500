import React, { useContext, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';

import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { changePhoneRequest, sendVerificationSMSRequest } from '@vyce/core/src/api/auth';
import { VerifyPhoneData } from '@vyce/core/src/types';
import { PhoneNumberField } from '@vyce/core/src/components/controlled-inputs/PhoneNumberField';
import { VerifyPhone } from '../../auth/components/VerifyPhone';
import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';
import { changePhoneByAdminRequest } from '@vyce/core/src/api/legend/auth';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  phone?: string;
  verified: boolean;
  isLegend?: boolean;
  userId: string;
  verifyPhone?: (data: VerifyPhoneData) => void;
  getReCaptchaToken: () => Promise<string>;
}

interface Form {
  phone: string;
}

export const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    overflowY: 'unset',
  },
}));

export const EditPhoneOrVerify: React.FC<Props> = ({
  verified: outerVerified,
  phone: outerPhone,
  isLegend,
  userId,
  verifyPhone,
  getReCaptchaToken,
}) => {
  const { handleServerError } = useContext(NotificationContext);
  const { isMobile } = useContext(DeviceContext);
  const theme = useTheme();
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>(outerPhone ?? '');
  const [verified, setVerified] = useState<boolean>(outerVerified ?? false);
  const [openVerifyPhoneDialog, setOpenVerifyPhoneDialog] = useState<boolean>(false);
  const methods = useForm<Form>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      phone: phone,
    },
  });

  const sendVerificationCode = async () => {
    try {
      const recaptcha_token = await getReCaptchaToken();
      await sendVerificationSMSRequest({ recaptcha_token });
      setOpen(false);
      setOpenVerifyPhoneDialog(true);
    } catch (e) {
      handleServerError(e);
    }
  };

  const onSubmit = async (data: Form, event?: React.BaseSyntheticEvent<object, any, any>) => {
    event?.preventDefault();
    try {
      const recaptcha_token = await getReCaptchaToken();
      if (isLegend) {
        await changePhoneByAdminRequest({ phone: data.phone, user_id: userId, recaptcha_token });
      } else {
        await changePhoneRequest({ phone: data.phone, recaptcha_token });
        sendVerificationCode();
      }
      setPhone(data.phone);
      setVerified(false);
      setOpen(false);
      return false;
    } catch (e) {
      handleServerError(e);
    }
  };

  return (
    <>
      <Box display="flex" height="100%" alignItems="center" justifyContent="space-between">
        <Box marginLeft={1}>
          <Box display="flex">
            <Typography variant="subtitle2">Phone </Typography>
            <Typography
              style={{
                color: !verified ? theme.palette.error.main : theme.palette.success.main,
                marginLeft: 4,
              }}
              variant="subtitle2">
              ({verified ? 'verified' : 'not verified'})
            </Typography>
            <Typography variant="subtitle2">:</Typography>
          </Box>
          <Typography variant="caption">{phone}</Typography>
        </Box>

        <Box display="flex" height="100%">
          {!verified && phone && !isLegend && (
            <Button variant="outlined" size="small" onClick={sendVerificationCode} color="primary">
              Verify
            </Button>
          )}

          <Button
            size="small"
            style={{ marginLeft: 8 }}
            onClick={() => setOpen(true)}
            variant="outlined"
            color="secondary">
            Edit
          </Button>
        </Box>
      </Box>

      {verifyPhone && (
        <Dialog
          container={document.getElementById(MAIN_CONTAINER_ID)}
          open={openVerifyPhoneDialog}
          onClose={() => setOpenVerifyPhoneDialog(false)}>
          <Box padding={4}>
            <VerifyPhone close={() => setOpenVerifyPhoneDialog(false)} verifyPhone={verifyPhone} />
          </Box>
        </Dialog>
      )}

      <Dialog fullScreen={isMobile} open={open} onClose={() => setOpen(false)}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <DialogTitle>Change your Mobile Number</DialogTitle>
            <DialogContent className={classes.dialogContent}>
              Please input your new Mobile Number and we’ll send you a verification code.
              <PhoneNumberField name="phone" autoFocus margin="normal" label="Mobile Number" />
            </DialogContent>
            <DialogActions>
              <Button size="large" variant="outlined" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button size="large" type="submit" color="primary" variant="contained">
                Save
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};
