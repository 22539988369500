import React from 'react';
import { Box, Typography } from '@material-ui/core';

interface TourContentItemProps {
  title?: string;
  text: string;
  className?: string;
}

export const TourContentItem = ({ title, text, className }: TourContentItemProps) => (
  <Box display="flex" flexDirection="column" justifyContent="center">
    {title && (
      <Box marginBottom={1}>
        <Typography color="primary" className={className}>
          {title}
        </Typography>
      </Box>
    )}
    <Typography>{text}</Typography>
  </Box>
);
