import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { AppTextField } from '../inputs/AppTextField';

export const LimitedCompanyNameField: React.FC = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      rules={{ required: 'Limited Company Name is required' }}
      name="company_name"
      render={({ field }) => (
        <AppTextField
          {...field}
          error={!!errors.company_name?.message}
          id="limited-company-name"
          label="Limited Company Name"
          margin="normal"
          fullWidth
          helperText={(errors.company_name?.message as string) || ''}
        />
      )}
    />
  );
};
