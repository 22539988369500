import { useContext, useMemo } from 'react';

import { Box, Typography } from '@material-ui/core';
import Lottie from 'react-lottie';

import { ColorThemeContext, DeviceContext } from '../../../contexts';
import * as animationFile from '../animations/vyce-ai.json';
import * as animationFileDarkMode from '../animations/vyce-ai-dark-mode.json';
import { AIChatContext } from '../context';

export const WelcomeMessage: React.FC = () => {
  const { colorTheme } = useContext(ColorThemeContext);
  const isDarkMode = colorTheme === 'dark';
  const { user } = useContext(AIChatContext);
  const { isMobile } = useContext(DeviceContext);

  const animationSize = isMobile ? 150 : 250;

  const animationOptions = useMemo(() => {
    return {
      loop: false,
      autoplay: true,
      animationData: isDarkMode ? animationFileDarkMode : animationFile,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
  }, [isDarkMode]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      marginTop={isMobile ? 0 : 16}>
      <Box
        style={{
          width: animationSize,
          height: animationSize,
          alignSelf: 'center',
          marginBottom: 24,
        }}>
        <Lottie options={animationOptions} height={animationSize} width={animationSize} />
      </Box>

      <Typography variant="h4">{`Hey ${user?.first_name}`}!</Typography>
      <Typography style={{ fontSize: '18px' }}>How can I help you today?</Typography>
    </Box>
  );
};
