import React from 'react';
import { Box } from '@material-ui/core';
import { OnboardingStepTitle } from '@vyce/core/src/components/OnboardingStepTitle';
import { ControlledDateField } from '@vyce/core/src/components/controlled-inputs/ControlledDateField';

export const StartPaymentDateStep: React.FC = () => {
  return (
    <Box>
      <OnboardingStepTitle text="Please set up your first payment date" />

      <ControlledDateField name="payment_date" margin="normal" label="Start Date" />
    </Box>
  );
};
