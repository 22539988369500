import React, { useContext } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';

import { DeviceContext } from '../../../contexts';
import { AppA } from '../../../components';
import { useSnackbar } from 'notistack';
import { MAIN_CONTAINER_ID } from '../../../constants';
import { PublicLink } from '../../../types';

interface Props {
  open: boolean;
  link: PublicLink;
  handleClose: () => void;
}

export const TeamPublicLinkDialog: React.FC<Props> = ({ open, handleClose, link }) => {
  const { isMobile } = useContext(DeviceContext);
  const { enqueueSnackbar } = useSnackbar();
  const message = `Please fill out this new starter form `;

  const copyToClipboard = async () => {
    const text = `${message}\n\n${link.url}`;
    await navigator?.clipboard?.writeText(text);
    enqueueSnackbar('Copied to clipboard', {
      variant: 'info',
    });
    handleClose();
  };

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">
        Here is your custom invite message and link to this team...
      </DialogTitle>
      <DialogContent>
        <Box marginTop={2}>
          <Typography id="custom-message-to-copy">
            {message}
            <AppA href={link.url} content={'invite link'} />
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button size="large" variant="outlined" onClick={handleClose}>
          Close
        </Button>
        <Button size="large" color="primary" onClick={copyToClipboard} variant="contained">
          Copy
        </Button>
      </DialogActions>
    </Dialog>
  );
};
