import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { monochrome } from '../../theme/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    checkboxLabel: {
      fontWeight: 600,
      fontSize: '15px',
      lineHeight: '24px',
      color: theme.palette.type === 'dark' ? monochrome.light : monochrome.dark,
    },
    disabledLabel: {
      opacity: 0.5,
    },
    dialogPaper: {
      overflowX: 'hidden',
    },
    detailsTitle: {
      color: theme.palette.type === 'dark' ? monochrome.light : monochrome.dark,
      fontSize: '15px',
      fontWeight: 400,
    },

    addButton: {
      color: theme.palette.primary.main,
    },
    divider: {
      flex: 1,
      height: 2,
    },
    or: {
      margin: '0 16px',
      color: theme.palette.type === 'dark' ? monochrome.light : monochrome.dark,
      fontWeight: 600,
      fontSize: '15px',
    },
    more: {
      fontWeight: 600,
      fontSize: '13px',
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    errorText: {
      color: theme.palette.error.main,
      lineHeight: '24px',
      marginTop: '1px',
    },
    moduleName: {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    dialogText: {
      fontSize: '18px',
      color: theme.palette.type === 'dark' ? monochrome.light : monochrome.dark,
    },
  })
);
