import React from 'react';

import { Route, Switch, Redirect } from 'react-router-dom';
import { Box } from '@material-ui/core';

import { CommonNavBarBlock } from '@vyce/core/src/components';

import { EmployeeTimesheetsPage, PublicCompanyPreviewWrapper } from 'src/views/payroll/employee';

import { EMPLOYEE_PAYROLL_TABS } from './config';

export const EmployeePayroll = () => {
  return (
    <Switch>
      <Box>
        <CommonNavBarBlock tabItems={EMPLOYEE_PAYROLL_TABS} />
        <Box height="100%">
          <Route exact path={'/employee-payroll/timesheets'} component={EmployeeTimesheetsPage} />
          <Route exact path={'/employee-payroll/timesheets/:id'} component={PublicCompanyPreviewWrapper} />
          <Route path="*" render={() => <Redirect to={{ pathname: '/employee-payroll/timesheets' }} />} />
        </Box>
      </Box>
    </Switch>
  );
};
