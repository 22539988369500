import React from 'react';

import { useTheme } from '@material-ui/core';

import logo from '../assets/svg/logo-icon.svg';
import logoWhite from '../assets/svg/logo-icon-white.svg';

interface Props {
  width: number;
  height: number;
}

export const LogoIcon: React.FC<Props> = ({ width, height }) => {
  const theme = useTheme();

  return (
    <img
      width={width}
      height={height}
      alt="logo-icon"
      src={theme.palette.type === 'dark' ? logoWhite : logo}
    />
  );
};
