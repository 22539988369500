import React from 'react';

import { Box, makeStyles, Typography, useTheme } from '@material-ui/core';
import { HiOutlineTrash } from 'react-icons/hi';
import { RiEyeLine } from 'react-icons/ri';
import { TbPencil } from 'react-icons/tb';
import { MdCheckBoxOutlineBlank } from 'react-icons/md';
import { IoCheckbox } from 'react-icons/io5';

import { AppIconButton, ConfirmDialog } from '../../../components';
import { CompanyPhoto } from '../../../types';
import { AppCheckbox } from '../../../components/inputs';
import { TileContainer } from '../../hiring/components';
import { useBooleanState } from '../../../hooks';
import { CompanyPhotosDialog } from '../../preview/company/components/CompanyPhotosDialog';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
  },
  selected: {
    border: `1px solid ${theme.palette.primary.main} !important`,
  },
  image: {
    objectFit: 'contain',
    width: '100%',
    height: 176,
  },
  oneLineText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

interface Props {
  photo: CompanyPhoto;
  handleDeleteClick: (uuid: string) => void;
  handlePhotoSelect: (photo: CompanyPhoto) => void;
  handleEditClick: (photo: CompanyPhoto) => void;
  selected: boolean;
  isSelectMode: boolean;
  companyName: string;
}

export const CompanyPhotoTile: React.FC<Props> = ({
  photo,
  selected,
  isSelectMode,
  handlePhotoSelect,
  handleDeleteClick,
  companyName,
  handleEditClick,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] = useBooleanState(false);
  const [isPhotosDialogOpen, setPhotosDialogOpen, setPhotosDialogClose] = useBooleanState(false);

  const handleDeleteConfirm = () => {
    closeConfirmDialog();
    handleDeleteClick(photo.uuid);
  };

  return (
    <TileContainer
      isClickable={isSelectMode}
      selected={selected}
      handleClick={() => handlePhotoSelect(photo)}>
      <>
        <Box marginBottom={2} display="flex" justifyContent="space-between" gridGap={12}>
          <Box marginLeft={-1} marginTop={0.5}>
            {isSelectMode && (
              <AppCheckbox
                checked={selected}
                icon={<MdCheckBoxOutlineBlank size="18px" />}
                checkedIcon={<IoCheckbox size="18px" />}
                color="primary"
                variant="rectangle"
              />
            )}
          </Box>

          <Box display="flex" gridGap={12}>
            <AppIconButton onClick={setPhotosDialogOpen} isSmall variant="default">
              <RiEyeLine color={theme.palette.text.secondary} size="18px" />
            </AppIconButton>

            <AppIconButton isSmall variant="primary" onClick={() => handleEditClick(photo)}>
              <TbPencil color={theme.palette.primary.dark} size="18px" />
            </AppIconButton>

            <AppIconButton onClick={openConfirmDialog} isSmall variant="error">
              <HiOutlineTrash color={theme.palette.error.dark} size="18px" />
            </AppIconButton>
          </Box>
        </Box>

        <Box height={176} bgcolor={theme.palette.background.default} borderRadius={8}>
          <img className={classes.image} src={photo.url} alt={photo.title} />
        </Box>

        <Box className={classes.oneLineText} marginTop={2}>
          <Typography variant="subtitle2">{photo.title}</Typography>
        </Box>

        <Box className={classes.oneLineText} marginTop={0.5}>
          <Typography variant="caption">{photo.description}</Typography>
        </Box>

        <ConfirmDialog
          handleClose={closeConfirmDialog}
          open={isConfirmDialogOpen}
          confirmText="Delete"
          cancelText="Cancel"
          title="Are you sure you want to delete this photo?"
          handleConfirm={handleDeleteConfirm}
        />

        <CompanyPhotosDialog
          photos={[photo]}
          singleMode
          open={isPhotosDialogOpen}
          companyName={companyName}
          handleClose={setPhotosDialogClose}
        />
      </>
    </TileContainer>
  );
};
