import React from 'react';

import { OnboardingStepTitle } from '@vyce/core/src/components';
import { PayTagsField } from '@vyce/core/src/components/controlled-inputs';
import { PayTagGroup } from '@vyce/core/src/types';

interface Props {
  payScheduleIds: string[];
  companyId: string;
}

export const StarterManagerStep = ({ payScheduleIds, companyId }: Props) => {
  return (
    <>
      <OnboardingStepTitle text="What's your line managers name?" />

      <PayTagsField
        group={PayTagGroup.MANAGER}
        companyId={companyId}
        payScheduleIds={payScheduleIds}
        margin="normal"
        multiline={false}
        label="Manager"
        name="pay_starter_manager"
      />
    </>
  );
};
