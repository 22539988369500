import { WorkersWorkedTimeOverview } from '@vyce/core/src/types';

import { EnrichedWorkersWorkedTimeOverview } from '../types';

const transformDate = (date: string) => date.split('-').join('/');

export const transformData = (data: WorkersWorkedTimeOverview[]): EnrichedWorkersWorkedTimeOverview[] =>
  data.map(item => ({
    ...item,
    weekNumber: transformDate(item.start_date),
  }));
