import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    highlights: {
      color: theme.palette.error.main,
      background: 'none',
      fontSize: '13px',
      fontWeight: 500,
    },
    unHighlights: {
      fontSize: '13px',
      fontWeight: 500,
      color: theme.palette.text.secondary,
    },
  })
);
