import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';

import { monochrome } from '../../../theme/styles';
import { ScheduleType } from '../../../modules/timeModule/constants';
import { ShiftSchedule } from '../../../types';
import { WeekHoursDay } from './WeekHoursDay';

const WEEK_DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    fontSize: '15px',
    fontWeight: 500,
    color: theme.palette.type === 'dark' ? theme.palette.text.secondary : monochrome.dark,
  },
}));

interface Props {
  inputLabel: string;
  scheduleDetailsFieldName: string;
  scheduleType: ScheduleType;
  scheduleToCompare: ShiftSchedule;
  setOverlappingDays: Function;
  overlappingDays: string[];
}

export const WeekHours: React.FC<Props> = ({
  inputLabel,
  scheduleDetailsFieldName,
  scheduleType,
  scheduleToCompare,
  overlappingDays,
  setOverlappingDays,
}) => {
  const classes = useStyles();
  const scheduleFieldName = `${scheduleDetailsFieldName}.schedule`;

  return (
    <>
      <Box display="flex" gridGap={16} padding="8px 0">
        <Box flex={1}>
          <Typography className={classes.header}>Day of the week</Typography>
        </Box>

        <Box width={120}>
          <Typography align="center" className={classes.header}>
            {inputLabel} start
          </Typography>
        </Box>

        <Box width={120}>
          <Typography align="center" className={classes.header}>
            {inputLabel} finish
          </Typography>
        </Box>

        <Box width={80}>
          <Typography align="center" className={classes.header}>
            All day
          </Typography>
        </Box>
      </Box>

      {WEEK_DAYS.map((day: string) => (
        <WeekHoursDay
          key={`${day}day`}
          day={day}
          dayToCompare={get(scheduleToCompare, day)}
          setOverlappingDays={setOverlappingDays}
          scheduleFieldName={scheduleFieldName}
        />
      ))}

      {!!overlappingDays.length && (
        <Box maxWidth={400}>
          <Typography variant="subtitle2" color="error">
            Hours set for {overlappingDays.join(', ')} overlap with this shift's{' '}
            {capitalize(
              scheduleType === ScheduleType.REGULAR ? ScheduleType.OVERTIME : ScheduleType.REGULAR
            )}{' '}
            work hours
          </Typography>
        </Box>
      )}
    </>
  );
};
