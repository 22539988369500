import React, { useContext } from 'react';

import { Box } from '@material-ui/core';

import { DeviceContext } from '@vyce/core/src/contexts';

export const ArrowBox = ({ children }: { children: React.ReactNode }) => {
  const { isMobile } = useContext(DeviceContext);

  return (
    <Box
      width={isMobile ? '20px' : '30px'}
      minWidth={isMobile ? '20px' : '30px'}
      height="30px"
      display="flex"
      alignContent="center"
      justifyContent="center">
      {children}
    </Box>
  );
};
