import { axios } from '@vyce/core/src/api';
import {
  Checker,
  CustomCertificate,
  CustomQualificationRequestData,
  QualificationsDTO,
} from '@vyce/core/src/types';

import { TriggerChecker } from '../types';

export const triggerCheckerRequest = ({ task_name, parameters }: TriggerChecker) => {
  const data = {
    task_name,
    parameters,
  };
  return axios.post('/checkers', data);
};

export const getCheckersRequest = () => {
  return axios.get<{ count: number; items: Checker[] }>('/checkers');
};

export const getQualificationsRequest = ({ parameters }: { parameters?: { limit: number } }) => {
  return axios.get<QualificationsDTO>(`/checkers/qualifications`, {
    params: parameters,
  });
};

export const getCertificatesListRequest = ({ parameters }: { parameters?: { limit: number } }) => {
  return axios.get<QualificationsDTO['certificates']>(`/checkers/qualifications/certificates`, {
    params: parameters,
  });
};

export const getTestsListRequest = ({ parameters }: { parameters?: { limit: number } }) => {
  return axios.get<QualificationsDTO['tests']>(`/checkers/qualifications/tests`, {
    params: parameters,
  });
};
export const getCoursesListRequest = ({ parameters }: { parameters?: { limit: number } }) => {
  return axios.get<QualificationsDTO['courses']>(`/checkers/qualifications/courses`, {
    params: parameters,
  });
};

export const getRtwRequest = () => {
  return axios.get(`/checkers/documents/rtw`);
};

export const createCustomQualificationRequest = ({ data }: { data: CustomQualificationRequestData }) => {
  return axios.post<CustomCertificate>(`/checkers/qualifications/certificates`, data);
};

export const updateCustomQualificationRequest = ({
  data,
  certificateId,
}: {
  data: CustomQualificationRequestData;
  certificateId: string;
}) => {
  return axios.patch<CustomCertificate>(`/checkers/qualifications/certificates/${certificateId}`, data);
};

export const deleteCustomQualificationRequest = (certificateId: string) => {
  return axios.delete(`/checkers/qualifications/certificates/${certificateId}`);
};

export const uploadCustomQualificationImageRequest = ({
  file,
  certificateId,
}: {
  file: File;
  certificateId: string;
}) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);

  return axios.post<CustomCertificate>(
    `/checkers/qualifications/certificates/${certificateId}/document`,
    bodyFormData
  );
};

export const deleteCustomQualificationImageRequest = ({
  certificateId,
  documentId,
}: {
  certificateId: string;
  documentId: string;
}) => {
  return axios.delete<CustomCertificate>(
    `/checkers/qualifications/certificates/${certificateId}/document/${documentId}`
  );
};
