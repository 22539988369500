import React, { useContext, useEffect, useMemo, useState } from 'react';

import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';

import { FormProvider, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  FormControlLabel,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import { AiOutlinePlus } from 'react-icons/ai';

import { AddMemberForm, Company, Employee, Shift } from '@vyce/core/src/types';
import { DeviceContext } from '@vyce/core/src/contexts';
import { SearchEmployeeAutocomplete } from '@vyce/core/src/views/payroll/components';
import { DefaultRateField, DefaultRateUnitSelect } from '@vyce/core/src/components/controlled-inputs';
import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';

import { NotificationContext } from '../../../../contexts/notificationContext';
import { AppCheckbox } from '../../../../components/inputs';
import { monochrome } from '../../../../theme/styles';
import { useBooleanState } from '../../../../hooks';
import { DifferentShiftRatesDialog } from './DifferentShiftRatesDialog';

interface Props {
  open: boolean;
  handleClose: Function;
  openAddMemberDialog: Function;
  shifts: Shift[];
  addMember: (data: AddMemberForm, employee?: Employee) => void;
  getEmployeeListRequest: Function;
  selectedCompany?: Company;
}

interface Form {
  employee_id: string;
  shift_ids: string[];
  basic_amount: null | number;
  basic_unit: string;
  overtime_amount: null | number;
  overtime_unit: string;
  custom_rates: ShiftRate[];
}

const defaultValues: Form = {
  employee_id: '',
  shift_ids: [],
  basic_amount: null,
  basic_unit: 'hour',
  overtime_amount: null,
  overtime_unit: 'hour',
  custom_rates: [],
};

export interface ShiftRate {
  basic_amount: number | null;
  basic_unit: string | null;
  overtime_amount: number | null;
  shiftId: string;
  overtime_unit: string | null;
}

export const AddSquadMemberButton: React.FC<Props> = ({
  openAddMemberDialog,
  handleClose,
  open,
  addMember,
  shifts,
  selectedCompany,
  getEmployeeListRequest,
}) => {
  const { isMobile } = useContext(DeviceContext);
  const [loading, setLoading] = useState(false);
  const [differentShiftRates, setDifferentShiftRates] = useState<ShiftRate[]>([]);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const { handleServerError } = useContext(NotificationContext);
  const [isRatesDialogOpen, openRateDialog, closeRatesDialog] = useBooleanState(false);
  const classes = useStyles();

  const hasDifferentRates = useMemo(
    () =>
      !!differentShiftRates.find(
        rate => (rate.basic_amount && rate.basic_unit) || (rate.overtime_amount && rate.overtime_unit)
      ),
    [differentShiftRates]
  );

  const methods = useForm<Form>({
    defaultValues,
  });
  const { setValue, watch } = methods;

  const shiftIds = watch('shift_ids');
  const selectedShifts = shifts?.filter(shift => shiftIds.includes(shift.uuid as string));

  const shiftOptions = useMemo(() => {
    if (shifts?.length > 1) {
      return [{ name: 'All', uuid: 'all' }, ...shifts];
    }
    return shifts;
  }, [shifts]);

  const onSearch = async (search: string) => {
    if (!selectedCompany?.uuid) {
      return;
    }

    try {
      setLoading(true);
      const res = await getEmployeeListRequest({
        companyId: selectedCompany.uuid,
        data: {
          limit: 20,
          substring: search,
          order_by: [
            {
              field_name: 'first_name',
              desc: false,
            },
          ],
        },
      });
      setEmployees(uniqBy(res.data.items as Employee[], 'ni_number'));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  };

  const onDialogClose = () => {
    resetForm();
    handleClose();
  };

  const resetForm = () => {
    setValue('basic_amount', defaultValues.basic_amount);
    setValue('basic_unit', defaultValues.basic_unit);
    setValue('overtime_amount', defaultValues.overtime_amount);
    setValue('overtime_unit', defaultValues.overtime_unit);
    setValue('employee_id', defaultValues.employee_id);
    setValue('custom_rates', defaultValues.custom_rates);
    setDifferentShiftRates([]);
  };

  const handleSubmit = async (data: Form) => {
    const employee = employees.find(item => item.uuid === data.employee_id);
    const shiftIds = data.shift_ids.filter(id => id !== 'all');
    const members: AddMemberForm[] = shiftIds.map(id => {
      if (hasDifferentRates) {
        const rate = differentShiftRates.find(rate => rate.shiftId === id);
        return {
          employee_id: data.employee_id,
          shift_id: id,
          basic_amount: rate?.basic_amount || null,
          basic_unit: rate?.basic_unit || null,
          overtime_amount: rate?.overtime_amount || null,
          overtime_unit: rate?.overtime_unit || null,
        };
      }
      return {
        employee_id: data.employee_id,
        shift_id: id,
        basic_amount: data?.basic_amount || null,
        basic_unit: data?.basic_unit || null,
        overtime_amount: data?.overtime_amount || null,
        overtime_unit: data?.overtime_unit || null,
      };
    });
    members.forEach(member => addMember(member, employee));
    resetForm();
  };

  const handleShiftSelect = (id: string, checked: boolean) => {
    setValue('shift_ids', prepareSelectedIds(shiftIds, id, checked));
  };

  const prepareSelectedIds = (arr: string[], id: string, checked: boolean): string[] => {
    if (id === 'all' && checked) {
      return [id, ...shifts.map(shift => shift.uuid as string)];
    }
    if (id === 'all' && !checked) {
      return [];
    }
    if (checked) {
      const ids = uniq([...arr, id]);
      if (ids.length === shifts.length) {
        ids.push('all');
      }
      return ids;
    }
    return arr.filter(item => item !== 'all').filter(item => item !== id);
  };

  const handleCustomRatesSave = (rates: ShiftRate[]) => {
    closeRatesDialog();
    setDifferentShiftRates(rates);
  };

  useEffect(() => {
    if (shifts.length === 1 && shifts[0]?.uuid) {
      setValue('shift_ids', [shifts[0].uuid]);
    }
  }, [shifts]);

  return (
    <>
      <Button
        onClick={() => openAddMemberDialog()}
        startIcon={<AiOutlinePlus />}
        variant="contained"
        color="primary"
        size="small">
        Add Squad Member
      </Button>

      <Dialog
        maxWidth="md"
        container={document.getElementById(MAIN_CONTAINER_ID)}
        fullScreen={isMobile}
        onClose={onDialogClose}
        open={open}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmit)}>
            <DialogTitle>Add Squad Member</DialogTitle>

            <DialogContent style={{ minWidth: isMobile ? '100%' : 600 }}>
              <SearchEmployeeAutocomplete
                label="Member Name"
                rules={{ required: 'Member Name is required' }}
                margin="normal"
                loading={loading}
                employees={employees}
                onSearch={onSearch}
              />

              <Box marginTop={2}>
                <Typography className={classes.detailsTitle}>Which Shifts?</Typography>

                <Box marginLeft="-6px" display="flex" flexWrap="wrap" marginBottom={3} marginTop={1}>
                  {shiftOptions?.map(shift => (
                    <FormControlLabel
                      key={shift.uuid}
                      classes={{ label: classes.detailsTitle }}
                      control={
                        <AppCheckbox
                          checked={shiftIds.includes(shift.uuid as string)}
                          onChange={e => handleShiftSelect(shift.uuid as string, e.target.checked)}
                          variant="rectangle"
                          color="primary"
                        />
                      }
                      label={shift.name}
                    />
                  ))}
                </Box>
              </Box>

              <Typography className={classes.detailsTitle}>Set their default rates:</Typography>

              {hasDifferentRates ? (
                <Box marginTop={1}>
                  <Typography variant="caption" color="primary">
                    Currently each Shift has a different rate.
                  </Typography>
                </Box>
              ) : (
                <>
                  <Box marginTop={-1} display="flex" gridGap={16}>
                    <DefaultRateField margin="normal" name="basic_amount" label="Default regular rate (£)" />

                    <DefaultRateUnitSelect margin="normal" name="basic_unit" />
                  </Box>

                  <Box display="flex" gridGap={16}>
                    <DefaultRateField
                      margin="normal"
                      name="overtime_amount"
                      label="Default overtime rate (£)"
                    />

                    <DefaultRateUnitSelect margin="normal" name="overtime_unit" />
                  </Box>
                </>
              )}

              {hasDifferentRates && (
                <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gridGap={16} marginTop={2}>
                  <Button
                    style={{ flex: 1 }}
                    onClick={() => setDifferentShiftRates([])}
                    color="primary"
                    variant="outlined"
                    size="small">
                    Set the default rates
                  </Button>

                  <Button
                    style={{ flex: 1 }}
                    onClick={openRateDialog}
                    color="primary"
                    variant="outlined"
                    size="small">
                    Change the different rates
                  </Button>
                </Box>
              )}

              {shiftIds?.length > 1 && !hasDifferentRates && (
                <Box marginTop={1} display="flex" justifyContent="flex-end">
                  <Button onClick={openRateDialog} color="primary" variant="outlined" size="small">
                    Set different rates for each Shift
                  </Button>
                </Box>
              )}
            </DialogContent>

            <DialogActions>
              <Button size="large" onClick={onDialogClose} variant="outlined">
                Cancel
              </Button>

              <Button
                disabled={!shiftIds?.length}
                size="large"
                type="submit"
                color="primary"
                variant="contained">
                Add Member
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>

      <DifferentShiftRatesDialog
        hasDifferentRates={hasDifferentRates}
        open={isRatesDialogOpen}
        handleClose={closeRatesDialog}
        selectedShifts={selectedShifts}
        handleCustomRatesSave={handleCustomRatesSave}
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    detailsTitle: {
      color: theme.palette.type === 'dark' ? monochrome.light : monochrome.dark,
      fontSize: '15px',
      fontWeight: 400,
    },
  })
);
