// @ts-nocheck
import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Box,
  createStyles,
  FilledInput,
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
} from '@material-ui/core';
import InputMask from 'react-input-mask';

import { FieldProps } from '../../types';
import { useAppTextFieldStyles } from '../inputs';
import { DetailsIcon } from '../DetailsIcon';

const utrHint =
  'If you do not supply a Unique Tax Reference Number (UTR), the applicable rate of tax shall be 30% (rather than 20%) in accordance with CIS rules';

const getRules = (required: boolean) => ({
  required: required ? 'UTR is required' : undefined,
  validate: (value: string) => {
    if (!value) return true;
    return value.length === 10 || 'UTR must be 10 digits long';
  },
});
const useStyles = makeStyles(() =>
  createStyles({
    popoverClass: {
      marginLeft: '48px',
      marginTop: '40px',
    },
  })
);

interface Props extends FieldProps {
  showHint?: boolean;
}

export const UTRNumberField: React.FC<Props> = ({
  margin = undefined,
  disabled = false,
  showHint = true,
  required = false,
  label = 'Unique Tax Reference Number',
  name = 'utr',
}) => {
  const inputClasses = useAppTextFieldStyles({});
  const classes = useStyles();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const rules = useMemo(() => getRules(required), [required]);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => (
        <Box display="flex" alignItems="center">
          <InputMask {...field} mask="9999999999" maskChar={null}>
            {() => (
              <FormControl margin={margin} fullWidth variant="filled">
                <InputLabel error={!!errors[name]?.message} htmlFor="utr">
                  {label}
                </InputLabel>
                <FilledInput
                  id="utr"
                  type="tel"
                  disabled={disabled}
                  classes={inputClasses}
                  disableUnderline
                  endAdornment={
                    showHint && <DetailsIcon popoverClass={classes.popoverClass} content={<>{utrHint}</>} />
                  }
                  error={!!errors[name]?.message}
                />
                <FormHelperText error id="limited-company-utr-helper-text">
                  {errors[name]?.message || ''}
                </FormHelperText>
              </FormControl>
            )}
          </InputMask>
        </Box>
      )}
    />
  );
};
