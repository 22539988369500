import React, { useMemo } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import { ImageUploader } from '@vyce/core/src/components';

import { MAIN_CONTAINER_ID, previewControlSet } from '../config';
import useStyles from '../styles';
import { QualificationType } from '../types';
import type { Image } from '../../../types';
import { CommonQualificationContent } from './CommonQualificationContent';
import { CustomQualificationContent } from './CustomQualificationContent';

interface Props {
  qualification?: QualificationType;
  handleClose: Function;
  open: boolean;
}

const commonSize = 120;

export const QualificationPreviewDialog: React.FC<Props> = ({ qualification, handleClose, open }) => {
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const customQualification =
    qualification && qualification.type === 'certificate' && qualification.source === 'custom';

  const previewDocumentsOptions: Image[] | null = useMemo(() => {
    if (qualification?.type === 'certificate' && qualification?.additional_data?.documents) {
      return qualification?.additional_data?.documents.map(item => ({
        url: item.doc_url,
        name: item.doc_s3_key,
      })) as Image[];
    }
    return null;
  }, [qualification, qualification?.type]);

  return (
    <>
      <Dialog
        container={document.getElementById(MAIN_CONTAINER_ID)}
        fullScreen={fullScreen}
        open={open}
        classes={{ paper: classes.cardDialog }}
        onClose={() => handleClose()}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{qualification?.title}</DialogTitle>
        <DialogContent>
          <Box display="flex" gridGap={16}>
            <Box flex="1 0 auto">
              {customQualification && <CustomQualificationContent qualification={qualification} />}
              {!customQualification && qualification && (
                <CommonQualificationContent qualification={qualification} />
              )}
            </Box>
            {previewDocumentsOptions && (
              <Box className={classes.documentsPreviewWrapper}>
                {previewDocumentsOptions.map((item, index) => (
                  <ImageUploader
                    key={index}
                    image={item}
                    extraText="new files"
                    extraSmallSize={true}
                    isDocument={true}
                    type="both"
                    outsideControl
                    customControlSet={previewControlSet}
                    width={commonSize}
                    height={commonSize}
                  />
                ))}
              </Box>
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => handleClose()} variant="outlined" size="large">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
