import { TeamPage as TeamPageComponent } from '@vyce/core/src/views/teamPage';
import { TeamModuleProvider } from '@vyce/core/src/contexts';

import { useTeamPageData } from './hooks';

export const TeamModule = () => {
  const data = useTeamPageData();

  return (
    <TeamModuleProvider value={data}>
      <TeamPageComponent />
    </TeamModuleProvider>
  );
};
