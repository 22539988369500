import React, { useContext } from 'react';
import { Box, Fade, Grid } from "@material-ui/core";
import { DeviceContext } from "../../contexts/deviceContext";
import useStyles from "./styles";

interface Props {
  rightPictureSrc: string;
  children: React.ReactElement;
}

export const AuthLayout: React.FC<Props> = ({ children, rightPictureSrc }) => {
  const classes = useStyles();
  const { isDesktop } = useContext(DeviceContext);

  return (
    <div className={classes.root}>
      <Grid container className={classes.itemsContainer}>
        <Grid item xs={12} md={6} lg={5} className={classes.item}>
          <Box width="100%" className={classes.authContainer}>
            {children}
            <Box className={classes.rights}>
              ©{(new Date()).getFullYear()} All Rights Reserved. Vyce®
            </Box>
          </Box>
        </Grid>
        {isDesktop && <Grid item xs={12} md={6} lg={7} className={classes.item}>
          <Fade in>
            <img className={classes.picture} alt="Login picture" src={rightPictureSrc}/>
          </Fade>
        </Grid>}
      </Grid>
    </div>
  )
}
