import React from 'react';

import { Box, Typography, useTheme } from '@material-ui/core';

import { ColorItem } from './ColorItem';
import { EnrichedItem } from '../types';
import { useStyles } from '../styles';

export const LegendItem = ({ lightColor, darkColor, value, label }: EnrichedItem) => {
  const theme = useTheme();
  const classes = useStyles();

  const color = theme.palette.type === 'dark' ? darkColor : lightColor;

  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" gridGap={8} alignItems="center">
        <ColorItem color={color} />
        <Typography className={classes.legendItemText}>{label}</Typography>
      </Box>
      <Typography className={classes.legendItemCount}>({value})</Typography>
    </Box>
  );
};
