import React from 'react';
import { Devices } from '../types';
export const DeviceContext = React.createContext<Devices>({
  isMobile: false,
  isDesktop: false,
  isLargeDesktop: false,
  isTablet: false,
  iOS: false,
});
export const DeviceProvider = DeviceContext.Provider;
