import React, { useContext } from 'react';

import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { Variant } from '@material-ui/core/styles/createTypography';

import { ColorOption } from '../types';
import { ColorThemeContext } from '../contexts';
import { getColor } from '../theme/styles';

interface Props {
  variant: ColorOption;
  typographyVariant?: Variant;
  label: string | number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '4px 16px',
      borderRadius: '8px',
      width: 'fit-content',
    },
  })
);

export const AccentNumbers: React.FC<Props> = ({ variant, label, typographyVariant = 'h5' }) => {
  const { colorTheme } = useContext(ColorThemeContext);
  const classes = useStyles();

  const colors: any = getColor(colorTheme === 'dark');

  return (
    <Box className={classes.container} style={{ backgroundColor: colors[`${variant}BG`] }}>
      <Typography variant={typographyVariant} style={{ color: colors[`${variant}Dark`], fontWeight: 700 }}>
        {label}
      </Typography>
    </Box>
  );
};
