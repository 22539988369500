import { createContext, Dispatch, SetStateAction } from 'react';

import { CardForm } from '@vyce/core/src/types';
import { CreateTeamData } from '@vyce/core/src/api/types';

interface PaginationOptions {
  page: number;
  pageSize: number;
  pageCount: number;
  rowCount: number;
  setPage: Dispatch<SetStateAction<number>>;
}

interface Context {
  isLoading: boolean;
  isOpenSubscriptionDialog?: boolean;
  isOpenTeamDialog: boolean;
  needToReset: boolean;
  substring: string;
  paginationOptions: PaginationOptions;
  first_name: string;
  isOpenNonSubscribedDialog?: boolean;
  wasSubscribed?: boolean;
  // functions
  openTeamDialog: Function;
  setSubscriptionDialogOpen?: Function;
  setOpenTeamDialog: Function;
  handleSearchChange: (event: any) => void;
  sort: (option: string) => void;
  subscribe?: (data: CardForm) => Promise<void>;
  finishCreateTeam: (data: CreateTeamData) => void;
  createTeam: ({
    formData,
    needToRedirect,
  }: {
    formData: CreateTeamData | null;
    needToRedirect?: boolean | undefined;
  }) => Promise<void>;
  handleCloseNonSubscribedDialog?: () => void;
}

export const TeamListModuleContext = createContext({} as Context);

export const TeamListModuleProvider = TeamListModuleContext.Provider;
