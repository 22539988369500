import React from 'react';

import { Box, Typography, Grid, useTheme } from '@material-ui/core';
import { HiExclamationCircle } from 'react-icons/hi';

import { CopyToClipboard } from '@vyce/core/src/components/CopyToClipboard';
import { DetailItem } from '@vyce/core/src/types';

import useStyles from '../../styles';
import { BoxWrapper } from './BoxWrapper';

interface EmergencyContactProps {
  name: DetailItem;
  phone: DetailItem;
}

const DataRowItem = ({ title, value, alert }: DetailItem & { alert?: boolean }) => {
  const classes = useStyles();
  const theme = useTheme();

  if (!value) return null;
  return (
    <Box className={classes.recordWrapper}>
      <Grid item xs={12} sm={6} lg={5}>
        <Typography className={classes.bold}>{title}:</Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={7}>
        <Box display="flex" alignItems="center" gridGap={20}>
          <Typography color="textSecondary" className={classes.bigTextWrap}>
            {value}
          </Typography>
          {alert ? (
            <HiExclamationCircle size="18px" color={theme.palette.primary.main} />
          ) : (
            <CopyToClipboard text={value} color={theme.palette.text.secondary} />
          )}
        </Box>
      </Grid>
    </Box>
  );
};

export const EmergencyContact = ({ name, phone }: EmergencyContactProps) => (
  <Box display="flex" flexDirection="column" height="100%">
    <Typography color="textSecondary" variant="h6">
      Emergency Contact Details
    </Typography>
    <BoxWrapper gridGap={16}>
      <DataRowItem {...name} alert />
      <DataRowItem {...phone} />
    </BoxWrapper>
  </Box>
);
