import { Box } from '@material-ui/core';

import { TopPerformingHours, TopPerformingWorker } from '@vyce/core/src/types';
import { NoRowsOverlayComponent } from '@vyce/core/src/components';

import { TopWorkers } from './TopWorkers';
import { HoursDescriptionBlock } from './HoursDescriptionBlock';
import { useStyles } from '../styles';

export const TopPerformingContent = ({
  cnaViewWorker,
  topPerformingWorkers,
  topPerformingHours,
}: {
  cnaViewWorker: boolean;
  topPerformingWorkers: TopPerformingWorker[];
  topPerformingHours: TopPerformingHours;
}) => {
  const classes = useStyles();

  return (
    <>
      {topPerformingWorkers.length ? (
        <Box className={classes.contentWrapper}>
          <TopWorkers cnaViewWorker={cnaViewWorker} topPerformingWorkers={topPerformingWorkers} />
          <HoursDescriptionBlock {...topPerformingHours} />
        </Box>
      ) : (
        <NoRowsOverlayComponent />
      )}
    </>
  );
};
