export const getEmptyListTitle = (category: string): string => {
  switch (category) {
    case 'interested':
      return 'Candidates will appear here when they apply or when you send them a connection request from your ‘Matched’ candidate list.';
    case 'connected':
      return 'Candidates will appear here when once you’ve connected with them. Request to connect with relevant candidates now.';
    case 'declined':
      return 'Candidates will appear here when you decline an inbound application. In the mean time, check out your matched candidates.';
    default:
      return '';
  }
};
