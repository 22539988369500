import React from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';

interface Props {
  title: string;
  value?: string;
}

export const InfoRow: React.FC<Props> = ({ title, value }) => {
  const theme = useTheme();

  return (
    <Box
      marginTop={2}
      border={`1px solid ${theme.palette.divider}`}
      borderRadius="8px"
      display="flex"
      padding="8px 16px">
      <Box flex={1}>
        <Typography variant="subtitle2">{title}:</Typography>
      </Box>
      <Box flex={2}>
        <Typography variant="subtitle2">{value}</Typography>
      </Box>
    </Box>
  );
};
