import React, { useMemo } from 'react';

import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { VscChevronDown } from 'react-icons/vsc';

import { RowSchemaProps, GridRowData } from '../types';
import { useStyles } from '../styles';
import { AccordionCellItem } from './AccordionCellItem';

interface Props<T> {
  row: GridRowData<T>;
  schema: RowSchemaProps<T>[][];
  shortSchema: RowSchemaProps<T>[];
  opened: boolean;
  withItemBorder?: boolean;
  onClick: (id: string) => void;
}

export const AccordionItem = <T extends { id: string }>({
  row,
  schema,
  shortSchema,
  opened,
  withItemBorder = false,
  onClick,
}: Props<T>) => {
  const classes = useStyles();

  const schemaWithoutFirstRow = useMemo(() => schema.slice(1), [schema]);
  const headerSchema = opened ? schema[0] : shortSchema;

  const handleClick = () => {
    onClick(row.id);
  };

  return (
    <Box
      className={clsx(classes.accordionItemWrapper, {
        [classes.accordionItemWrapperClosed]: !opened,
        [classes.accordionItemWrapperOpened]: opened,
        [classes.accordionItemWrapperBorder]: withItemBorder,
      })}>
      <Box className={classes.accordionHeaderItemContent}>
        <Box className={classes.accordionItemRowWrapper}>
          {headerSchema.map(item => (
            <AccordionCellItem key={item.field as string} row={row} options={item} />
          ))}
        </Box>
      </Box>

      <Box className={classes.accordionItemContent}>
        {schemaWithoutFirstRow.map(topLevelSchemaItem => (
          <Box className={classes.accordionItemRowWrapper}>
            {topLevelSchemaItem.map(item => (
              <AccordionCellItem key={item.field as string} row={row} options={item} />
            ))}
          </Box>
        ))}
      </Box>

      <Box
        className={clsx(classes.accordionItemButton, { [classes.accordionItemButtonOpened]: opened })}
        onClick={handleClick}>
        <VscChevronDown size={16} />
      </Box>
    </Box>
  );
};
