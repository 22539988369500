import React, { useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  FilledInput,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
} from '@material-ui/core';
import { BiHide, BiShow } from 'react-icons/bi';
import { FieldProps } from '../../types';
import { useAppTextFieldStyles } from '../inputs';

export const PasswordField: React.FC<FieldProps> = ({ margin, label, name = '', rules }) => {
  const classes = useAppTextFieldStyles({});
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      render={({ field }) => (
        <FormControl margin={margin} fullWidth variant="filled">
          <InputLabel error={!!errors[name]?.message} htmlFor={name}>
            {label}
          </InputLabel>
          <FilledInput
            {...field}
            classes={classes}
            disableUnderline
            id={name}
            cy-test-id={name}
            error={!!errors[name]?.message}
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end">
                  {showPassword ? <BiShow /> : <BiHide />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText error>{(errors[name]?.message as string) || ''}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
