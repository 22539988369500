import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

import { ScheduleType } from '../../../../modules/timeModule/constants';
import { OnboardingStepTitle } from '../../../../components';
import { WeekHours } from '../../components/WeekHours';

interface Props {
  type: ScheduleType;
}

export const ScheduleHoursStep: React.FC<Props> = ({ type }) => {
  const scheduleDetailsFieldName = `shift.${type}`;
  const { watch, setValue, getValues } = useFormContext();
  const [overlappingDays, setOverlappingDays] = useState<string[]>([]);

  const startTimeField = `${scheduleDetailsFieldName}.startTime`;
  const endTimeField = `${scheduleDetailsFieldName}.endTime`;
  const startTime = watch(startTimeField);
  const endTime = watch(endTimeField);
  const scheduleFieldName = `shift.${type}.schedule`;
  const scheduleToCompare = watch(
    `shift.${type === ScheduleType.OVERTIME ? ScheduleType.REGULAR : ScheduleType.OVERTIME}.schedule`
  );

  useEffect(() => {
    const newSchedule: any = { ...getValues(scheduleFieldName) };

    Object.keys(newSchedule).forEach(key => {
      if (key !== 'sunday' && key !== 'saturday') {
        if (startTime) {
          newSchedule[key].start = startTime;
        }
        if (endTime) {
          newSchedule[key].end = endTime;
        }
      }
    });

    setValue(scheduleFieldName, newSchedule);
  }, [startTime, endTime]);

  return (
    <>
      <OnboardingStepTitle text={`Let’s customise paid ${type} hours for each day.`} />

      <Box marginTop={2}>
        <WeekHours
          overlappingDays={overlappingDays}
          setOverlappingDays={setOverlappingDays}
          scheduleToCompare={scheduleToCompare}
          scheduleType={type}
          inputLabel="Paid"
          scheduleDetailsFieldName={scheduleDetailsFieldName}
        />
      </Box>
    </>
  );
};
