import React, { useContext, useState } from 'react';

import { Box, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import uniqBy from 'lodash/uniqBy';
import { FormProvider, useForm } from 'react-hook-form';

import { DeviceContext } from '@vyce/core/src/contexts';
import { Employee } from '@vyce/core/src/types';
import { MAIN_CONTAINER_ID, SUBCONTRACTOR_TYPES } from '@vyce/core/src/constants';
import { AppCheckbox } from '@vyce/core/src/components/inputs';
import { HowToEngageEmployee } from '@vyce/core/src/components';
import {
  addEmployeeToPaySchedulesRequestByLegend,
  lookupEmployeeListRequest,
} from '@vyce/core/src/api/legend/pay';

import useStyles from '../../invites/styles';
import { SearchEmployeeAutocomplete } from './SearchEmployeeAutocomplete';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  open: boolean;
  payScheduleId: string;
  companyId: string;
  handleClose: () => void;
}

interface Form {
  employee_id: string;
  pay_scheme: SUBCONTRACTOR_TYPES;
}

export const AddExistingContractorDialog: React.FC<Props> = ({
  open,
  handleClose,
  payScheduleId,
  companyId,
}) => {
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const classes = useStyles();
  const { isMobile } = useContext(DeviceContext);
  const [selectedSubcontractorType, setSelectedSubcontractorType] = useState<string>('');
  const [notifyEmployer, setNotifyEmployer] = useState<boolean>(false);
  const [notifyEmployee, setNotifyEmployee] = useState<boolean>(false);
  const methods = useForm<Form>({
    defaultValues: {
      employee_id: '',
    },
  });
  const { getValues, watch } = methods;
  const employeeId = watch('employee_id');
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState<Employee[]>([]);

  const handleTypeChange = (type: SUBCONTRACTOR_TYPES, checked: boolean) => {
    setSelectedSubcontractorType(checked ? type : '');
  };

  const addEmployee = async () => {
    const data = getValues();
    try {
      const res = await addEmployeeToPaySchedulesRequestByLegend({
        employeeId: data.employee_id,
        payScheduleId,
        companyId,
        payScheme: selectedSubcontractorType,
        notifyEmployee,
        notifyEmployer,
      });
      showNotification({ message: res.data.message, options: { variant: 'success' } });
      handleClose();
    } catch (e) {
      handleServerError(e);
    }
  };

  const onSearch = async (search: string) => {
    try {
      setLoading(true);
      const res = await lookupEmployeeListRequest({
        data: {
          limit: 20,
          substring: search,
          order_by: [
            {
              field_name: 'first_name',
              desc: false,
            },
          ],
        },
      });
      setEmployees(uniqBy(res.data.items as Employee[], 'ni_number'));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  };

  const onClose = () => {
    handleClose();
    setEmployees([]);
    setSelectedSubcontractorType('');
    setNotifyEmployer(false);
    setNotifyEmployee(false);
  };

  return (
    <Dialog
      fullScreen={isMobile}
      container={document.getElementById(MAIN_CONTAINER_ID)}
      maxWidth="sm"
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title">
      <FormProvider {...methods}>
        <form>
          <DialogTitle id="responsive-dialog-title">
            Add an existing Vyce contractor to this pay schedule
          </DialogTitle>

          <DialogContent>
            <SearchEmployeeAutocomplete loading={loading} onSearch={onSearch} employees={employees} />

            <Box marginTop={3}>
              <HowToEngageEmployee
                handleTypeChange={handleTypeChange}
                selectedSubcontractorType={selectedSubcontractorType}
              />
            </Box>

            <Box marginLeft="-6px" display="flex" marginTop={3} flexWrap="wrap">
              <FormControlLabel
                classes={{ label: classes.detailsTitle }}
                style={{ marginTop: '4px' }}
                control={
                  <AppCheckbox
                    checked={notifyEmployer}
                    onChange={e => setNotifyEmployer(e.target.checked)}
                    variant="rectangle"
                    color="primary"
                  />
                }
                label="Send notification email to Employer"
              />
              <FormControlLabel
                classes={{ label: classes.detailsTitle }}
                style={{ marginTop: '4px' }}
                control={
                  <AppCheckbox
                    checked={notifyEmployee}
                    onChange={e => setNotifyEmployee(e.target.checked)}
                    variant="rectangle"
                    color="primary"
                  />
                }
                label="Send notification email to Employee"
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button style={{ width: 102 }} size="large" onClick={onClose} variant="outlined">
              Cancel
            </Button>

            <Button
              disabled={!selectedSubcontractorType || !employeeId}
              size="large"
              color="primary"
              style={{ width: 102 }}
              onClick={addEmployee}
              variant="contained">
              Add
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
