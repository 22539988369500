import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useForm } from 'react-hook-form';

import { AppOnboarding } from '@vyce/core/src/components/AppOnboarding';
import {
  ActiveOnboardingStep,
  Company,
  CreateSite,
  OnboardingLocation,
  OnboardingStep,
  ShiftSchedule,
  TrackingType,
} from '@vyce/core/src/types';
import { getLocationDetails } from '@vyce/core/src/utils/location';
import { createSiteRequest } from '@vyce/core/src/api/time';
import { Greetings } from '@vyce/core/src/components/onboarding';
import { TimeModuleContext } from '@vyce/core/src/contexts';
import { EMPTY_ADDRESS, EMPTY_SHIFT, ScheduleType } from '@vyce/core/src/modules/timeModule/constants';
import { locationToSite, shiftToSiteShift } from '@vyce/core/src/modules/timeModule/utils';
import { isOverlapped } from '@vyce/core/src/utils/dates';

import {
  BreaksStep,
  DefaultRateStep,
  DoYouNeedSpecificLocationStep,
  LocationAreaStep,
  LocationIsSetupDialog,
  LocationNameStep,
  OvertimeScheduleStep,
  PostcodeStep,
  ScheduleHoursStep,
  ShiftStep,
} from './components';
import { TrackingOptionsStep } from './components/TrackingOptionsStep';
import { NotificationContext } from '../../../contexts/notificationContext';

export const isScheduleValid = (
  regularSchedule?: ShiftSchedule,
  overtimeSchedule?: ShiftSchedule
): boolean => {
  let valid = true;
  if (!regularSchedule || !overtimeSchedule) {
    return false;
  }
  // @ts-ignore
  Object.keys(overtimeSchedule).forEach((key: keyof ShiftSchedule) => {
    if (isOverlapped(overtimeSchedule[key], regularSchedule[key])) {
      valid = false;
    }
  });
  return valid;
};

const defaultValues = {
  name: '',
  address: EMPTY_ADDRESS,
  payScheduleId: '',
  teamMembersCount: 0,
  shift: EMPTY_SHIFT,
  approver: '',
  polygon: null,
  tracking_type: { value: TrackingType.BREADCRUMBS, name: 'Breadcrumbs' },
};

interface Props {
  selectedCompany?: Company;
  fetchCompanyData?: Function;
  firstName: string;
}

export const LocationOnboarding: React.FC<Props> = ({ selectedCompany, fetchCompanyData, firstName }) => {
  const initialStep = {
    component: <Greetings subtitle="Let’s have you set up in minutes." firstName={firstName} />,
    name: 'Greetings',
  };
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const [activeStep, setActiveStep] = useState<ActiveOnboardingStep>({ step: initialStep, number: 0 });
  const [steps, setSteps] = useState<OnboardingStep[]>([]);
  const childRef = useRef<any>();
  const [open, setOpen] = useState<boolean>(false);
  const [withLocation, setWithLocation] = useState<boolean>(true);
  const [disableNext, setDisableNext] = useState<boolean>(false);
  const methods = useForm<OnboardingLocation>({
    defaultValues,
  });
  const { getLocations } = useContext(TimeModuleContext);
  const { setValue, watch, getValues, reset } = methods;
  const address = watch('address');
  const overtimeScheduleStart = watch('shift.overtime.startTime');
  const overtimeScheduleEnd = watch('shift.overtime.endTime');

  const nextStep = (jumpTo?: number) => {
    childRef?.current?.nextStep(jumpTo);
  };

  const scrollDown = () => {
    childRef?.current?.scrollDown();
  };

  const removeLocationQuestionSteps = () => {
    const newSteps = [...defaultSteps];
    newSteps.splice(2, 3);
    setSteps(newSteps);
  };

  const handleNoClick = (activeStep: number) => {
    setWithLocation(false);
    removeLocationQuestionSteps();
    if (activeStep < 2) {
      nextStep();
    }
    setValue('address', EMPTY_ADDRESS);
    setDisableNext(false);
  };

  const handleYesClick = (activeStepNumber: number) => {
    setWithLocation(true);
    if (activeStepNumber < 2) {
      nextStep();
    }
    setSteps(defaultSteps);
    setDisableNext(false);
  };

  const defaultSteps: OnboardingStep[] = useMemo(
    () => [
      initialStep,
      {
        component: (
          <DoYouNeedSpecificLocationStep
            withLocation={withLocation}
            handleYesClick={handleYesClick}
            handleNoClick={handleNoClick}
            activeStep={activeStep}
          />
        ),
        name: 'DoYouNeedSpecificLocationStep',
      },
      {
        component: <TrackingOptionsStep />,
        name: 'TrackingOption',
      },
      {
        component: <PostcodeStep />,
        name: 'PostcodeStep',
      },
      {
        component: <LocationAreaStep />,
        name: 'LocationAreaStep',
      },
      {
        component: <ShiftStep />,
        name: 'ShiftStep',
      },
      {
        component: <DefaultRateStep type={ScheduleType.REGULAR} />,
        name: 'DefaultRegularRateStep',
      },
      {
        component: <ScheduleHoursStep type={ScheduleType.REGULAR} />,
        name: 'RegularScheduleStep',
      },
      {
        component: <OvertimeScheduleStep />,
        name: 'OvertimeScheduleStep',
      },
      {
        component: <BreaksStep scrollDown={scrollDown} />,
        name: 'BreaksStep',
      },
      // {
      //   component: <ApproverStep title="Now define who approves your team’s hours for this location." />,
      //   name: 'ApproverStep',
      // },
      // {
      //   component: <AllocateToPayScheduleStep />,
      //   name: 'AllocateToPayScheduleStep',
      // },
      {
        component: <LocationNameStep />,
        name: 'LocationNameStep',
      },
    ],
    [steps]
  );

  useEffect(() => {
    setSteps(defaultSteps);
  }, []);

  // update activeStep props
  useEffect(() => {
    setSteps(steps =>
      steps.map(item => {
        if (item.name === 'DoYouNeedSpecificLocationStep') {
          return {
            component: (
              <DoYouNeedSpecificLocationStep
                withLocation={withLocation}
                handleYesClick={handleYesClick}
                handleNoClick={handleNoClick}
                activeStep={activeStep}
              />
            ),
            name: 'DoYouNeedSpecificLocationStep',
          };
        }
        return item;
      })
    );
  }, [activeStep, withLocation]);

  useEffect(() => {
    if (steps.findIndex(step => step.name === 'OvertimeScheduleHoursStep') !== -1) {
      return;
    }
    if (overtimeScheduleStart && overtimeScheduleEnd) {
      const newSteps = [...steps];
      const overtimeScheduleStepIndex = steps.findIndex(step => step.name === 'OvertimeScheduleStep');
      newSteps.splice(
        overtimeScheduleStepIndex + 1,
        0,
        {
          component: <DefaultRateStep type={ScheduleType.OVERTIME} />,
          name: 'DefaultOvertimeRateStep',
        },
        {
          component: <ScheduleHoursStep type={ScheduleType.OVERTIME} />,
          name: 'OvertimeScheduleHoursStep',
        }
      );
      setSteps(newSteps);
    }
  }, [overtimeScheduleStart, overtimeScheduleEnd]);

  const clear = () => {
    setActiveStep({ step: initialStep, number: 0 });
    reset();
    setSteps(defaultSteps);
    setOpen(false);
  };

  const prepareSite = (location: OnboardingLocation): CreateSite => {
    const site = locationToSite(location);
    const shift = shiftToSiteShift(location.shift);

    return {
      ...site,
      shift,
    };
  };

  const createLocation = async (data: OnboardingLocation) => {
    if (!selectedCompany?.uuid) {
      return;
    }

    const site = prepareSite(data);
    try {
      await createSiteRequest(selectedCompany.uuid, site);
      setOpen(true);
      getLocations(false);

      if (fetchCompanyData) {
        fetchCompanyData(selectedCompany.uuid);
      }
    } catch (e) {
      handleServerError(e);
    }
  };

  const checkAddress = async (address_snippet?: string) => {
    if (!address_snippet) {
      return '';
    }
    const address = await getLocationDetails({
      fullAddress: address_snippet || '',
    });
    if (!address?.lat || !address?.lon) {
      showNotification({ message: 'Location not found...', options: { variant: 'error' } });
      return '';
    } else {
      return address;
    }
  };

  const handleNext = async (data: OnboardingLocation) => {
    switch (activeStep.step.name) {
      case 'LocationNameStep':
        createLocation(data);
        break;
      case 'DoYouNeedSpecificLocationStep':
        if (!data.address?.address_snippet) {
          return nextStep();
        }
        const addressData = await checkAddress(data.address.address_snippet);
        if (addressData) {
          nextStep();
        }
        break;
      case 'RegularScheduleStep':
      case 'OvertimeScheduleStep':
      case 'BreaksStep':
      case 'ShiftStep':
        const regularSchedule: any = getValues('shift.regular.schedule');
        const overtimeSchedule: any = getValues('shift.overtime.schedule');
        if (isScheduleValid(regularSchedule, overtimeSchedule)) {
          nextStep();
        }
        break;
      default:
        nextStep();
        break;
    }
  };

  const setAddressData = async (addressSnippet?: string) => {
    const addressData = await checkAddress(addressSnippet);
    if (addressData) {
      setValue('address', addressData);
    }
  };

  useEffect(() => {
    if (address?.address_snippet && !address?.lat && !address?.lon) {
      setAddressData(address.address_snippet);
    }
  }, [address?.address_snippet]);

  return (
    <>
      <AppOnboarding
        disableNext={disableNext}
        activeStep={activeStep}
        currentMessage=""
        setActiveStep={setActiveStep}
        methods={methods}
        handleNext={handleNext}
        steps={steps}
        loading={false}
        ref={childRef}
      />

      <LocationIsSetupDialog
        setOpen={setOpen}
        open={open}
        name={getValues('name')}
        createNewLocation={clear}
      />
    </>
  );
};
