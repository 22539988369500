import React from 'react';
import { Box, Divider, Fade, FormControlLabel, Typography } from '@material-ui/core';

import { AppCheckbox } from '@vyce/core/src/components/inputs';

import { MODULE_NAMES } from '../config';
import useStyles from '../styles';
import { TimeModuleOptions, CommonOptions } from '../types';
import { ModuleAccordion } from './ModuleAccordion';

const styles = { marginRight: '24px', marginTop: '4px' };

export const TimeBlock = ({
  isLegend,
  expandedModule,
  selectedShiftIds,
  selectedLocationIds,
  timeModuleActive,
  selectedLocations,
  locations,
  handleShiftSelect,
  handleLocationSelect,
  clearTimeSelections,
  collapseAllModules,
  setExpandedModule,
}: TimeModuleOptions & CommonOptions) => {
  const classes = useStyles();

  return (
    <Box marginLeft="-6px" marginTop={1}>
      <ModuleAccordion
        active={timeModuleActive}
        moduleName={MODULE_NAMES.TIME}
        moduleLink="/time"
        moduleItemName={!!selectedLocationIds?.length && !selectedShiftIds.length ? 'Shifts' : 'Locations'}
        clearModuleSelections={clearTimeSelections}
        isModuleItemsSelected={!!selectedShiftIds.length && !!selectedLocationIds?.length}
        isLegend={isLegend}
        moduleExpanded={expandedModule === MODULE_NAMES.TIME}
        collapseAllModules={collapseAllModules}
        expandedModule={expandedModule}
        setExpandedModule={setExpandedModule}>
        {timeModuleActive ? (
          <Box marginLeft="36px">
            <Typography className={classes.detailsTitle}>Which Location?</Typography>
            <Box marginLeft="-6px" display="flex" flexWrap="wrap" marginBottom={2}>
              {locations?.map(loc => (
                <FormControlLabel
                  key={loc.uuid}
                  classes={{ label: classes.detailsTitle }}
                  style={styles}
                  control={
                    <AppCheckbox
                      checked={selectedLocationIds.includes(loc.uuid as string)}
                      onChange={e => handleLocationSelect(loc.uuid as string, e.target.checked)}
                      variant="rectangle"
                      color="primary"
                    />
                  }
                  label={loc.name}
                />
              ))}
            </Box>

            <Fade in={!!selectedLocations?.length}>
              <Box>
                <Divider style={{ height: '1px', marginBottom: 16 }} />

                {selectedLocations?.map(loc => (
                  <>
                    <Typography className={classes.detailsTitle}>Which Shift on {loc.name}?</Typography>

                    <Box marginLeft="-6px" display="flex" flexWrap="wrap" marginBottom={2}>
                      {loc.shifts?.map(shift => (
                        <FormControlLabel
                          key={shift.uuid}
                          classes={{ label: classes.detailsTitle }}
                          style={styles}
                          control={
                            <AppCheckbox
                              checked={selectedShiftIds.includes(shift.uuid as string)}
                              onChange={e => handleShiftSelect(shift.uuid as string, e.target.checked)}
                              variant="rectangle"
                              color="primary"
                            />
                          }
                          label={shift.name}
                        />
                      ))}
                    </Box>
                  </>
                ))}
              </Box>
            </Fade>
          </Box>
        ) : (
          <></>
        )}
      </ModuleAccordion>
    </Box>
  );
};
