import { axios } from '../index';
import { CreatePasswordManually, GetUserData } from '../types';
import { IDVerification } from '../../types';

export const uploadPassportRequest = (passport: File, userId?: string) => {
  const bodyFormData = new FormData();
  bodyFormData.append('scan', passport);

  return axios.post(`/admin/documents/users/${userId}/passports/scan`, bodyFormData);
};

export const uploadSelfieRequest = (selfie: File, userId?: string) => {
  const bodyFormData = new FormData();
  bodyFormData.append('selfie', selfie);

  return axios.post(`/admin/documents/users/${userId}/passports/selfie`, bodyFormData);
};

export const getPassportRequest = ({ userId }: GetUserData) => {
  return axios.get(`/admin/documents/users/${userId}/passports`);
};

export const createIDVerificationRequest = (userId: string, data: IDVerification) => {
  return axios.put(`/admin/documents/users/${userId}/id_verification`, data);
};

export const patchIDVerificationRequest = (userId: string, data: IDVerification) => {
  return axios.patch(`/admin/documents/users/${userId}/id_verification`, data);
};

export const verifyByPassportRequest = (userId: string) => {
  return axios.post(`/admin/documents/users/${userId}/id_verification/by_passport`);
};

export const createPassportManuallyRequest = ({ userId, data }: CreatePasswordManually) => {
  return axios.patch(`/admin/documents/users/${userId}/passports`, data);
};
