import React, { useMemo } from 'react';

import { UniversalInviteDialog } from '@vyce/core/src/views/invites';
import { UniversalInviteProvider } from '@vyce/core/src/contexts';

import { useUniversalInviteModuleData } from './hooks';

interface Props {
  onClose: () => void;
  isOpen: boolean;
}
export const UniversalInviteModule = ({ onClose, isOpen }: Props) => {
  const { contextOptions, selectedCompanyId } = useUniversalInviteModuleData();

  const contextValue = useMemo(
    () => ({
      ...contextOptions,
      open: isOpen,
      onClose,
    }),
    [contextOptions, isOpen, onClose]
  );

  return (
    <UniversalInviteProvider value={contextValue}>
      {selectedCompanyId && <UniversalInviteDialog />}
    </UniversalInviteProvider>
  );
};
