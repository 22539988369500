import React, { useContext, useEffect, useState } from 'react';

import { ControlledTagAutocomplete } from './ControlledTagAutocomplete';
import { AutocompleteProps, FieldProps, Nationality } from '../../types';
import { fetchNationalitiesRequest } from '../../api/handbook';
import { DeviceContext } from '../../contexts';
import { ControlledSelect } from './ControlledSelect';

export const NationalityField: React.FC<FieldProps> = ({ margin, rules }) => {
  const [nationalities, setNationalities] = useState<Nationality[]>([]);
  const { isMobile } = useContext(DeviceContext);

  const nationalityRules = {
    required: 'Nationality is required',
  };

  const nationalitySelectProps: AutocompleteProps = {
    name: 'nationality',
    items: nationalities,
    rules: rules || nationalityRules,
    label: 'Nationality',
    margin,
    multiple: false,
  };

  useEffect(() => {
    let mounted = true;
    fetchNationalitiesRequest().then(result => {
      if (mounted) {
        setNationalities(result.data.items);
      }
    });
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <>
      {isMobile ? (
        <ControlledSelect {...nationalitySelectProps} />
      ) : (
        <ControlledTagAutocomplete {...nationalitySelectProps} />
      )}
    </>
  );
};
