import React from 'react';

import { Box, makeStyles, Theme, Typography } from '@material-ui/core';

import { capitalizeFirstLetter } from '@vyce/core/src/utils';

export interface SubscriptionCardTextProps {
  subscriptionTextMode?: 'self' | 'common';
  wasSubscribed?: boolean;
}

const addressFormText = 'All we need now is the billing address.';

const selfManagedPayrollText =
  'you’ll be charged £2.50 per payment per user for the previous month’s users. You can cancel at any time. All other modules will be charged at £2.50 per user per month. You can review all pricing';

const commonText =
  'you’ll be charged £2.50 per user per month for the previous month’s users. You can cancel at any time. You can review all pricing';

export const useStyles = makeStyles((theme: Theme) => ({
  link: { fontWeight: 600, fontSize: '15px', lineHeight: '1.75' },
}));

export const SubscriptionCardText = ({
  subscriptionTextMode = 'common',
  wasSubscribed,
}: SubscriptionCardTextProps) => {
  const classes = useStyles();

  const text = subscriptionTextMode === 'common' ? commonText : selfManagedPayrollText;
  const transformedText = wasSubscribed ? capitalizeFirstLetter(text) : text;
  return (
    <Box>
      {!wasSubscribed && (
        <>
          <Typography display="inline" variant="subtitle1">
            You won’t be charged anything until your 14 day free trial ends then
          </Typography>{' '}
        </>
      )}
      <Typography display="inline" variant="subtitle1">
        {transformedText}
      </Typography>{' '}
      <a href={'https://www.vyce.io/pricing'} target="_blank" rel="noreferrer" className={classes.link}>
        here
      </a>
      {'. '}
    </Box>
  );
};

export const SubscriptionAddressText = () => <Typography variant="subtitle1">{addressFormText}</Typography>;
