import ShowMoreText from 'react-show-more-text';

import useStyles from './styles';

export const ReadMore = ({ children: text }: { children: string }) => {
  const classes = useStyles();

  return (
    <ShowMoreText
      lines={2}
      more="Read more"
      less="Read less"
      className={classes.showMoreTextContent}
      anchorClass={classes.showMoreTextAnchor}
      expanded={false}
      truncatedEndingComponent={'... '}>
      {text}
    </ShowMoreText>
  );
};
