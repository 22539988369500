import React, { useContext } from 'react';

import { ControlledTagAutocomplete } from './ControlledTagAutocomplete';
import { AutocompleteProps, FieldProps } from '../../types';
import { DeviceContext } from '../../contexts';
import { ControlledSelect } from './ControlledSelect';

const options = [
  { name: 'Male', value: 'male' },
  { name: 'Female', value: 'female' },
];

export const GenderField: React.FC<FieldProps> = ({ margin, token }) => {
  const { isMobile } = useContext(DeviceContext);

  const nationalitySelectProps: AutocompleteProps = {
    name: 'gender',
    items: options,
    label: 'Gender',
    margin,
    multiple: false,
  };

  return (
    <>
      {isMobile ? (
        <ControlledSelect {...nationalitySelectProps} />
      ) : (
        <ControlledTagAutocomplete {...nationalitySelectProps} />
      )}
    </>
  );
};
