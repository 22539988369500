import React, { ReactElement, useMemo, useState } from 'react';

import { WiMoonAltFirstQuarter } from 'react-icons/wi';
import { RiSunLine } from 'react-icons/ri';
import { HiOutlineMoon } from 'react-icons/hi';
import { Box, createStyles, ListItem, makeStyles, Menu, Typography, useTheme } from '@material-ui/core';

import { AppIconButton } from './AppIconButton';
import { monochrome } from '../theme/styles';
import { ThemeType } from '../types';

interface Props {
  changeColorTheme: Function;
  themeType?: ThemeType;
}

interface Option {
  title: string;
  value: ThemeType;
  icon: ReactElement;
}

const useStyles = makeStyles(theme =>
  createStyles({
    menuItemContainer: {
      color: theme.palette.type === 'dark' ? monochrome.light : monochrome.dark,
      cursor: 'pointer',
    },
    menuTitle: {
      fontSize: '13px',
      lineHeight: '14px',
      fontWeight: 600,
    },
    listItem: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      '&:last-child': {
        borderBottom: 'none',
      },
      padding: '12px',
    },
  })
);

const containerId = 'theme-switcher-container';

export const ThemeSwitcher: React.FC<Props> = ({ changeColorTheme, themeType = ThemeType.OS }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const options: Option[] = useMemo(
    () => [
      {
        title: 'OS Theme',
        value: ThemeType.OS,
        icon: <WiMoonAltFirstQuarter size="18px" />,
      },
      {
        title: 'Light',
        value: ThemeType.LIGHT,
        icon: <RiSunLine size="18px" />,
      },
      {
        title: 'Dark',
        value: ThemeType.DARK,
        icon: <HiOutlineMoon size="18px" />,
      },
    ],
    []
  );

  const handleMenuItemClick = (value: ThemeType) => {
    changeColorTheme(value);
    handleClose();
  };

  return (
    <Box id={containerId}>
      <AppIconButton
        size="small"
        data-tour="theme"
        variant="paper"
        aria-label="app-theme"
        onClick={handleClick}>
        {themeType === ThemeType.OS && (
          <WiMoonAltFirstQuarter size="24px" color={theme.palette.primary.main} />
        )}
        {themeType === ThemeType.LIGHT && <RiSunLine size="21px" color={theme.palette.primary.main} />}
        {themeType === ThemeType.DARK && <HiOutlineMoon size="21px" color={theme.palette.primary.main} />}
      </AppIconButton>

      <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        container={document.getElementById(containerId)}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {options.map(option => (
          <ListItem
            onClick={() => handleMenuItemClick(option.value)}
            key={option.title}
            className={classes.listItem}>
            <Box className={classes.menuItemContainer} display="flex" gridGap={8} alignItems="center">
              {option.icon}
              <Typography className={classes.menuTitle} color="textSecondary">
                {option.title}
              </Typography>
            </Box>
          </ListItem>
        ))}
      </Menu>
    </Box>
  );
};
