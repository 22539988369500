import React from 'react';

import { Circle, Polygon } from 'react-leaflet';
import { LatLngExpression } from 'leaflet';
import * as GeoJSON from 'geojson';
import { useTheme } from '@material-ui/core';

import { SITE_RADIUS } from '../../../modules/timeModule/constants';

interface Props {
  polygon?: GeoJSON.Polygon | null;
  centerPosition?: LatLngExpression;
}

export const LocationAreaShapePreview: React.FC<Props> = ({ polygon, centerPosition }) => {
  const theme = useTheme();
  return (
    <>
      {polygon?.coordinates ? (
        <Polygon
          color={theme.palette.primary.main}
          positions={
            polygon.coordinates[0].map(item => ({
              lat: item[1],
              lng: item[0],
            })) as LatLngExpression[]
          }
        />
      ) : (
        <>
          {centerPosition && (
            <Circle
              center={centerPosition}
              pathOptions={{ color: theme.palette.primary.main }}
              radius={SITE_RADIUS}
            />
          )}
        </>
      )}
    </>
  );
};
