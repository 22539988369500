import React, { useContext, useState } from 'react';

import { Box, Paper, Theme, Typography, useTheme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { HiOutlineTrash } from 'react-icons/hi';
import { FiBriefcase } from 'react-icons/fi';
import { TbPencil } from 'react-icons/tb';
import dayjs from 'dayjs';
import clsx from 'clsx';

import { HelperState, Position } from '@vyce/core/src/types';
import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { formatTimeRange, getMonthsBetween } from '@vyce/core/src/utils/dates';

import { PositionDialog } from '../views/profile/components/PositionDialog';
import { AppIconButton } from './AppIconButton';

const useStyles = makeStyles<any>((theme: Theme) =>
  createStyles({
    workExperienceCard: {
      padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    deleteIcon: {
      position: 'relative',
    },
    progress: {
      color: theme.palette.primary.main,
      position: 'absolute',
      top: 7,
      left: 7,
      zIndex: 1,
    },
    iconContainer: {
      borderRadius: theme.shape.borderRadius,
      minWidth: '80px',
      height: '80px',
      display: 'flex',
      backgroundColor: theme.palette.background.default,
      alignItems: 'center',
      justifyContent: 'center',
    },
    smallBold: {
      fontWeight: 500,
    },
  })
);

interface Props {
  position: Position;
  positionIndex: number;
  editable?: boolean;
  deletePosition: Function;
  updatePosition?: Function;
  helper: HelperState;
}

export const ExperienceRange = ({
  start_date,
  end_date,
  smallBold,
}: Pick<Position & { smallBold?: boolean }, 'end_date' | 'start_date' | 'smallBold'>) => {
  const classes = useStyles();

  return (
    <Typography color="textSecondary" variant="caption" className={clsx({ [classes.smallBold]: smallBold })}>
      {dayjs(start_date).format('MMM YYYY')} - {end_date ? dayjs(end_date).format('MMM YYYY') : 'Present'} (
      {formatTimeRange(getMonthsBetween(start_date, end_date || new Date()))})
    </Typography>
  );
};

export const WorkExperienceCard: React.FC<Props> = ({
  position,
  deletePosition,
  updatePosition,
  positionIndex,
  editable = false,
  helper,
}) => {
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const { isMobile } = useContext(DeviceContext);
  const [open, setOpen] = useState<boolean>(false);

  const handleDelete = () => {
    if (positionIndex !== undefined) {
      deletePosition(positionIndex);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Paper variant="outlined" className={classes.workExperienceCard}>
      <div className={classes.iconContainer}>
        <FiBriefcase size="28px" color={theme.palette.primary.main} />
      </div>

      <Box display="flex" justifyContent="space-between" width="100%">
        <Box display="flex" flexDirection="column" marginLeft={2}>
          <Typography>{position?.company?.name}</Typography>
          <Typography variant="caption">{position?.title as string}</Typography>
          <ExperienceRange start_date={position.start_date} end_date={position.end_date} />
        </Box>

        <Box display="flex" alignItems="center" flexDirection={isMobile ? 'column' : 'row'} gridGap={16}>
          {editable && (
            <AppIconButton variant="secondary" onClick={handleOpen}>
              <TbPencil color={theme.palette.secondary.dark} size="18px" />
            </AppIconButton>
          )}

          <AppIconButton variant="error" className={classes.deleteIcon} onClick={handleDelete}>
            <HiOutlineTrash color={theme.palette.error.dark} size="20px" />
          </AppIconButton>
        </Box>

        <PositionDialog
          position={position}
          handleClose={handleClose}
          updatePosition={updatePosition}
          open={open}
          positionIndex={positionIndex}
          helper={helper}
        />
      </Box>
    </Paper>
  );
};
