import React, { useContext, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';

import { DocumentsDialog, ImageUploader, ImageView } from '@vyce/core/src/components';
import { Image, Passport, TaskNames } from '@vyce/core/src/types';
import { useBooleanState } from '@vyce/core/src/hooks';
import { DeviceContext } from '@vyce/core/src/contexts';
import { getUKFormattedDate } from '@vyce/core/src/utils/dates';

import useStyles from '../styles';
import { ManualIDUploadingDialog } from './ManualIDUploadingDialog';
import { ButtonTitleWithLoading } from '../../../components/ButtonTitleWithLoading';
import { MAIN_CONTAINER_ID } from '../../../constants';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  passport?: Passport;
  userId?: string;
  isLegend: boolean;
  uploadPassportRequest: (scan: File, userId?: string) => void;
  uploadSelfieRequest: (selfie: File, userId?: string) => void;
  triggerCheckerRequest: Function;
  setUploadDialogOpen: Function;
  updatePassportManuallyCallback: Function;
  uploadDialogOpen: boolean;
}

export const PICTURES_UPLOADING_RULES =
  'Please upload a clear picture of the photo page of your passport and a clear selfie. Make sure the passport picture captures the entire page and there is no reflection.';

export const PassportVerificationCard: React.FC<Props> = ({
  passport,
  uploadPassportRequest,
  uploadSelfieRequest,
  triggerCheckerRequest,
  userId,
  isLegend,
  uploadDialogOpen,
  setUploadDialogOpen,
  updatePassportManuallyCallback,
}) => {
  const classes = useStyles();
  const { handleServerError } = useContext(NotificationContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [passportImage, setPassportImage] = useState<Image | undefined>({
    url: passport?.document?.url || '',
    file: null,
  });
  const [selfieImage, setSelfieImage] = useState<Image | undefined>({
    url: passport?.selfie?.url || '',
    file: null,
  });
  const { isMobile } = useContext(DeviceContext);
  const [passportState, setPassportState] = useState<Passport | undefined>(passport);
  const [isInfoDialogOpen, setInfoDialogOpen, setInfoDialogClose] = useBooleanState(false);

  const [manualUploadingDialogOpen, setManualUploadingDialogOpen] = useState<boolean>(false);

  const passportImageView = useMemo(
    () => ({
      file: null,
      url: passport?.document?.url,
    }),
    [passport?.document?.url]
  );

  const selfieImageView = useMemo(
    () => ({
      file: null,
      url: passport?.selfie?.url,
    }),
    [passport?.selfie?.url]
  );

  const verifyPassport = async () => {
    setLoading(true);
    try {
      if (passportImage?.file) {
        await uploadPassportRequest(passportImage.file, userId);
      }
      if (selfieImage?.file) {
        await uploadSelfieRequest(selfieImage.file, userId);
      }
      await triggerCheckerRequest({ task_name: TaskNames.CHECK_PASSPORT, userId });
      setUploadDialogOpen(false);
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      handleServerError(e);
    }
  };

  const handleUploadDialogOpen = (e: any) => {
    e.stopPropagation();
    setUploadDialogOpen(true);
  };

  const handleInfoDialogOpen = () => {
    if (passportState) {
      setInfoDialogOpen();
    }
  };

  const handlePassportUpload = (image: Image) => {
    setPassportImage(image);
  };

  const handleSelfieUpload = (image: Image) => {
    setSelfieImage(image);
  };

  return (
    <>
      <Paper
        onClick={handleInfoDialogOpen}
        variant="outlined"
        className={clsx(classes.paper, { [classes.clickable]: !!passportState })}>
        <Box width="100%" display="flex" alignItems="center" height={45} justifyContent="space-between">
          <Typography className={classes.bold} color="primary" variant="h6">
            Passport
          </Typography>
        </Box>

        <Box display="flex" justifyContent="space-between" width="100%" marginTop={2}>
          {!passportState?.verified && (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography variant="subtitle2">
                Upload a photo of your passport and a selfie to verify your identity.
              </Typography>
            </Box>
          )}

          {passportState?.verified && (
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Typography variant="subtitle2">
                {passportState.first_name} {passportState.last_name}
              </Typography>
              <Box display="flex" marginTop={1}>
                <Typography variant="subtitle2" color="textSecondary" style={{ marginRight: '4px' }}>
                  D.O.B:
                </Typography>
                <Typography variant="subtitle2">
                  {getUKFormattedDate(passportState.date_of_birth)}
                </Typography>
              </Box>

              <Box display="flex" marginTop={1}>
                <Typography variant="subtitle2" color="textSecondary" style={{ marginRight: '4px' }}>
                  Nationality:
                </Typography>
                <Typography variant="subtitle2">{passportState.nationality}</Typography>
              </Box>

              <Box display="flex" marginTop={1}>
                <Typography variant="subtitle2" color="textSecondary" style={{ marginRight: '4px' }}>
                  Document Expiring:
                </Typography>
                <Typography variant="subtitle2">
                  {getUKFormattedDate(passportState.expiration_date)}
                </Typography>
              </Box>
            </Box>
          )}

          <Box
            minHeight={126}
            width="30%"
            display="flex"
            alignItems="center"
            flexDirection="column"
            gridGap={8}>
            <ImageView width={50} height={60} url={passportImageView?.url} isDocument={true} />

            <ImageView width={50} height={50} url={selfieImageView?.url} isDocument={false} />
          </Box>
        </Box>

        <Box display="flex" width="100%" marginTop={2} gridGap={16}>
          <Button
            onClick={handleUploadDialogOpen}
            variant="contained"
            color={passportState?.verified ? 'secondary' : 'primary'}
            fullWidth>
            {passportState?.verified ? 'Update' : 'Verify'}
          </Button>

          {isLegend && (
            <Button
              onClick={e => {
                e.stopPropagation();
                setManualUploadingDialogOpen(true);
              }}
              variant="contained"
              color="primary"
              fullWidth>
              Verify Manually
            </Button>
          )}
        </Box>
      </Paper>

      <Dialog
        open={uploadDialogOpen}
        container={document.getElementById(MAIN_CONTAINER_ID)}
        onClose={() => setUploadDialogOpen(false)}
        fullScreen={isMobile}>
        <DialogTitle id="alert-dialog-title">Passport (ID) verification</DialogTitle>
        <DialogContent>
          <Box width={isMobile ? '100%' : 400}>
            <DialogContentText>{PICTURES_UPLOADING_RULES}</DialogContentText>
            <Box position="relative">
              <Typography variant="h6" className={classes.documentUploaderTitle}>
                Passport (ID)
              </Typography>

              <ImageUploader
                width={230}
                height={140}
                image={passportImage}
                isDocument={true}
                onImageUpload={handlePassportUpload}
                extraText="document"
              />
            </Box>

            <Box position="relative" marginTop={2}>
              <Typography variant="h6" className={classes.documentUploaderTitle}>
                Selfie
              </Typography>

              <ImageUploader
                width={112}
                height={112}
                image={selfieImage}
                isDocument={false}
                onImageUpload={handleSelfieUpload}
                extraText="picture"
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button size="large" onClick={() => setUploadDialogOpen(false)} variant="outlined">
            Close
          </Button>
          <Button
            size="large"
            onClick={verifyPassport}
            disabled={loading || (!passportImage?.file && !selfieImage?.file)}
            style={{ width: '90px' }}
            variant="contained"
            color="primary">
            <ButtonTitleWithLoading title="Verify" loaderVariant="paper" loading={loading} />
          </Button>
        </DialogActions>
      </Dialog>

      <DocumentsDialog open={isInfoDialogOpen} onClose={setInfoDialogClose} passport={passport} mode="ID" />

      {userId && (
        <ManualIDUploadingDialog
          updatePassportManuallyCallback={updatePassportManuallyCallback}
          setPassportState={setPassportState}
          userId={userId}
          passport={passport}
          open={manualUploadingDialogOpen}
          setOpen={setManualUploadingDialogOpen}
        />
      )}
    </>
  );
};
