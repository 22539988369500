import React from 'react';

import { Box, Paper, Typography } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';

import {
  AccountsOfficeReferenceField,
  EmailField,
  EmployerPAYEReferenceField,
  PhoneNumberField,
  UTRNumberField,
  ControlledSelect,
} from '@vyce/core/src/components/controlled-inputs';
import { AppTextField } from '@vyce/core/src/components/inputs';
import { SENDER_TYPES } from '@vyce/core/src/constants';

import { useStyles } from '../styles';
import { fullHeight } from '../config';

const lastNameRules = {
  required: 'Last Name is required',
};

const firstNameRules = {
  required: 'First Name is required',
};
const senderRules = {
  required: 'Sender Type is required',
};

const phoneRules = { required: 'Contact Phone is required' };

export const HMRCSettingsEditBlock = () => {
  const classes = useStyles();

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Paper variant="outlined" className={classes.wrapper} style={fullHeight}>
      <Box display="flex" flexDirection="column" gridGap={24}>
        <Typography variant="h6">HMRC Details</Typography>

        <Box display="flex" flexDirection="column" gridGap={16}>
          <Box display="flex" flexDirection="column" gridGap={4}>
            <Typography color="textSecondary" className={classes.smallText}>
              In what capacity will you be processing the payroll? E.G. As an Agent on behalf of the
              employer.
            </Typography>
            <ControlledSelect
              label="Payroll Processing Capacity"
              rules={senderRules}
              name="sender"
              items={SENDER_TYPES}
              multiple={false}
            />
          </Box>

          <EmployerPAYEReferenceField />
          <Box display="flex" gridGap={16}>
            <AccountsOfficeReferenceField />
            <Box width="100%">
              <UTRNumberField label="UTR (E.g. 0123456789)" showHint={false} required />
            </Box>
          </Box>
        </Box>

        <Box display="flex" flexDirection="column">
          <Box marginBottom={0.5}>
            <Typography color="textSecondary" className={classes.smallText}>
              Main Contact for HMRC
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gridGap={16}>
            <Box display="flex" gridGap={16}>
              <Controller
                control={control}
                rules={firstNameRules}
                name="contact_first_name"
                render={({ field }) => (
                  <AppTextField
                    label="First Name"
                    fullWidth
                    {...field}
                    error={!!errors?.contact_first_name?.message}
                    helperText={(errors?.contact_first_name?.message as string) || ''}
                  />
                )}
              />

              <Controller
                control={control}
                rules={lastNameRules}
                name="contact_last_name"
                render={({ field }) => (
                  <AppTextField
                    label="Last Name"
                    fullWidth
                    {...field}
                    error={!!errors?.contact_last_name?.message}
                    helperText={(errors?.contact_last_name?.message as string) || ''}
                  />
                )}
              />
            </Box>

            <Box display="flex" gridGap={16}>
              <EmailField label="HMRC Principal Contact Email" autoFocus={false} name="contact_email" />

              <PhoneNumberField rules={phoneRules} name="contact_telephone" label="Contact Phone" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};
