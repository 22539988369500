import { useCallback, useContext, useEffect, useState } from 'react';

import { GridSortModel } from '@mui/x-data-grid';

import { InvitedUser } from '@vyce/core/src/types';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { formatSortModel } from '@vyce/core/src/utils/sorting';
import { useTable } from '@vyce/core/src/hooks/useTable';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import { InviteProps } from '../types';

const defaultSortModel: GridSortModel = [{ field: 'created_at', sort: 'desc' }];

export const useInvitesData = ({
  selectedCompanyId,
  isLegend = false,
  handleInviteButtonClick,
  deleteInviteRequest,
  getInvitesRequest,
  resendInviteRequest,
}: InviteProps) => {
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const [invitedUsers, setInvitedUsers] = useState<InvitedUser[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    sortModel,
    offset,
    substring,
    total,
    setTotal,
    handleSortModelChange,
    handlePageChange,
    handleSearchChange,
  } = useTable({ defaultSortModel });

  const resendInvite = async (inviteId: string, companyId: string) => {
    if (!companyId) {
      return;
    }
    try {
      await resendInviteRequest(companyId, inviteId);
      await getInvites();
      showNotification({ message: 'The Invitation has been resent', options: { variant: 'success' } });
    } catch (e) {
      handleServerError(e);
    }
  };

  const deleteInvite = async (inviteId: string, companyId: string) => {
    if (!companyId) {
      return;
    }
    try {
      const res = await deleteInviteRequest(companyId, inviteId);
      await getInvites();
      showNotification({ message: res.data.message, options: { variant: 'success' } });
    } catch (e) {
      handleServerError(e);
    }
  };

  const getInvites = useCallback(async () => {
    if (!isLegend && !selectedCompanyId) {
      return;
    }

    setLoading(true);
    try {
      const defaultOptions = {
        limit: GRID_PAGE_SIZE,
        offset,
        substring,
        order_by: formatSortModel<InvitedUser>(sortModel),
      };
      const options = isLegend ? defaultOptions : { ...defaultOptions, companyId: selectedCompanyId };
      const res = await getInvitesRequest(options);
      setInvitedUsers(res.data.items);
      setTotal(res.data.count);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  }, [selectedCompanyId, substring, offset, sortModel]);

  useEffect(() => {
    getInvites();
  }, [getInvites]);

  return {
    loading,
    invitedUsers,
    sortModel,
    total,
    handleInviteButtonClick,
    handleSortModelChange,
    handlePageChange,
    resendInvite,
    deleteInvite,
    handleSearchChange,
  };
};
