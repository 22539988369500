import React from 'react';

import { Box, Typography } from '@material-ui/core';

interface Props {
  image: string;
  label: string;
  description: string;
}

export const TrackOptionHint: React.FC<Props> = ({ image, label, description }) => {
  return (
    <Box display="flex" gridGap={12} alignItems="center" maxWidth={308}>
      <img height={152} width={154} alt="Success" src={image} />

      <Box>
        <Typography style={{ fontSize: '15px', fontWeight: 600, lineHeight: '27px' }}>{label}</Typography>

        <Typography variant="caption">{description}</Typography>
      </Box>
    </Box>
  );
};
