import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import { MAIN_CONTAINER_ID } from '../../../constants';

interface Props {
  open: boolean;
  setOpen: Function;
  sendEmail: Function;
  sendSMS: Function;
}

export const SendPayStatementsDialog: React.FC<Props> = ({ open, setOpen, sendEmail, sendSMS }) => {
  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      onClose={() => setOpen(false)}
      open={open}>
      <DialogTitle>Send Bulk Pay Statements</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Pay statements will be sent by email. Net payments will be sent by SMS. Everyone in this payrun
          will receive an SMS or email unless they have already received one.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button size="large" onClick={() => setOpen(false)} variant="outlined">
          Cancel
        </Button>
        <Button size="large" onClick={() => sendSMS()} color="secondary" variant="contained">
          Send SMS
        </Button>
        <Button size="large" onClick={() => sendEmail()} color="primary" variant="contained">
          Send Email
        </Button>
      </DialogActions>
    </Dialog>
  );
};
