import React from 'react';

import { Box, Typography, useTheme } from '@material-ui/core';
import { MdOutlineSearchOff } from 'react-icons/md';

export const NoWidgetData = () => {
  const theme = useTheme();

  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center">
      <MdOutlineSearchOff size="50px" color={theme.palette.text.secondary} />
      <Box marginTop={2}>
        <Typography variant="subtitle2" color="textSecondary">
          No Data Found
        </Typography>
      </Box>
    </Box>
  );
};
