import { useCallback, useState, useEffect, useMemo, useRef, useContext } from 'react';

import { useForm } from 'react-hook-form';
import debounce from 'lodash/debounce';

import {
  getExportingTimeLogsSettings,
  restoreExportingTimeLogsSettings,
  updateExportingTimeLogsSettings,
} from '@vyce/core/src/api/time';

import { DetailType, DragItem, Props } from '../types';
import { prepareData } from '../utils';
import { FieldItemTypes } from '../config';
import { NotificationContext } from '../../../contexts/notificationContext';

export const useData = ({ companyId, signalForRefreshingId }: Props) => {
  const signalIdRef = useRef<string | null>(null);
  const { handleServerError } = useContext(NotificationContext);
  const [timeLogFields, setTimeLogFields] = useState<DragItem[]>([]);
  const [systemLocationFields, setSystemLocationFields] = useState<DragItem[]>([]);
  const [customLocationFields, setCustomLocationFields] = useState<DragItem[]>([]);
  const [detailedSummaryFields, setDetailedSummaryFields] = useState<DragItem[]>([]);

  const [loading, setLoading] = useState(false);

  const methods = useForm();

  const getDetailsFields = async () => {
    if (!companyId) {
      return;
    }
    try {
      setLoading(true);
      const { data } = await getExportingTimeLogsSettings({ companyId });
      const preparedData = prepareData(data);
      setTimeLogFields(preparedData.time_logs_fields);
      setSystemLocationFields(preparedData.default_location_fields);
      setCustomLocationFields(preparedData.custom_location_fields);
      setDetailedSummaryFields(preparedData.detailed_summary_fields);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  };

  const getRequiredSetOfFields = useCallback(
    (type: DetailType) => {
      if (type === 'location') return customLocationFields;
      if (type === 'time') return timeLogFields;
      if (type === 'detailed_summary') return detailedSummaryFields;
    },
    [customLocationFields, detailedSummaryFields, timeLogFields]
  );

  const getRequiredSetterForFields = (type: DetailType) => {
    if (type === 'location') return setCustomLocationFields;
    if (type === 'time') return setTimeLogFields;
    if (type === 'detailed_summary') return setDetailedSummaryFields;
  };

  const sendUpdatedFields = useCallback(
    async (fields: DragItem[], type: DetailType) => {
      if (!companyId) {
        return;
      }
      try {
        const fieldItemType = FieldItemTypes[type];
        const data = { [fieldItemType]: { fields } };
        await updateExportingTimeLogsSettings({ companyId, data });
      } catch (e) {
        setLoading(false);
        handleServerError(e);
      }
    },
    [companyId]
  );

  const debouncedSendUpdatedFields = useMemo(() => debounce(sendUpdatedFields, 2000), [sendUpdatedFields]);

  const handleUpdateFields = useCallback(
    (type: DetailType, fields?: DragItem[]) => {
      const fieldsFromState = getRequiredSetOfFields(type) as DragItem[];
      debouncedSendUpdatedFields(fields || fieldsFromState, type);
    },
    [debouncedSendUpdatedFields, getRequiredSetOfFields]
  );

  const restoreExportingTimeLogsSettingsRequest = useCallback(async () => {
    if (!companyId) {
      return;
    }
    try {
      setLoading(true);
      const { data } = await restoreExportingTimeLogsSettings({ companyId });
      const preparedData = prepareData(data);
      setTimeLogFields(preparedData.time_logs_fields);
      setSystemLocationFields(preparedData.default_location_fields);
      setCustomLocationFields(preparedData.custom_location_fields);
      setDetailedSummaryFields(preparedData.detailed_summary_fields);

      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  }, [companyId]);

  const changeCustomFieldName = useCallback((id: string, value: boolean, type: DetailType) => {
    const setter = getRequiredSetterForFields(type) as React.Dispatch<React.SetStateAction<DragItem[]>>;
    setter(prevFields => {
      const copyOfPrevFields = [...prevFields];
      const field = copyOfPrevFields.find(f => f.id === id) as DragItem;
      const fieldIndex = copyOfPrevFields.findIndex(f => f.id === id);

      copyOfPrevFields.splice(fieldIndex, 1, { ...field, is_active: value });
      handleUpdateFields(type, copyOfPrevFields);
      return copyOfPrevFields;
    });
  }, []);

  const findCard = useCallback((id: string, fields: DragItem[]) => {
    const field = fields.filter(f => `${f.id}` === id)[0];
    return {
      field,
      index: fields.indexOf(field),
    };
  }, []);

  const findCardHandler = useCallback(
    (id: string, type: DetailType) => {
      const fieldsFromState = getRequiredSetOfFields(type) as DragItem[];
      return findCard(id, fieldsFromState);
    },
    [findCard, getRequiredSetOfFields]
  );

  const moveCard = useCallback(
    (dragId: string, hoverIndex: number, type: DetailType) => {
      const { index: dragIndex } = findCardHandler(dragId, type);
      const setter = getRequiredSetterForFields(type) as React.Dispatch<React.SetStateAction<DragItem[]>>;
      setter((prevItems: DragItem[]) => {
        const dubl = [...prevItems];
        dubl.splice(dragIndex, 1);
        dubl.splice(hoverIndex, 0, prevItems[dragIndex]);
        return dubl.map((item, index) => ({ ...item, position: index + 1 }));
      });
    },
    [findCardHandler]
  );

  useEffect(() => {
    getDetailsFields();
  }, []);

  useEffect(() => {
    if (signalIdRef.current === null) {
      signalIdRef.current = signalForRefreshingId;
      return;
    }
    if (signalIdRef.current !== signalForRefreshingId) {
      getDetailsFields();
      signalIdRef.current = signalForRefreshingId;
    }
  }, [signalForRefreshingId]);

  const contextFunctionOptions = useMemo(
    () => ({
      findCard: findCardHandler,
      moveCard,
      handleUpdateFields,
      changeCustomFieldName,
      restoreData: restoreExportingTimeLogsSettingsRequest,
    }),
    [findCardHandler, moveCard, restoreExportingTimeLogsSettingsRequest, changeCustomFieldName]
  );

  const contextValuesOptions = useMemo(
    () => ({ timeLogFields, customLocationFields, systemLocationFields, detailedSummaryFields }),
    [customLocationFields, systemLocationFields, timeLogFields, detailedSummaryFields]
  );

  return {
    loading,
    timeLogFields,
    customLocationFields,
    systemLocationFields,
    contextFunctionOptions,
    contextValuesOptions,
    methods,
  };
};
