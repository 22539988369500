import React, { useEffect, useState } from 'react';
import { AutocompleteProps, Tag } from '../../types';
import { fetchTagsRequest } from '../../api/handbook';
import { ControlledTagAutocomplete } from './ControlledTagAutocomplete';

interface Props {
  margin?: any;
  name?: string;
  token: string;
}

const GROUP = 'skill';

export const SpecificSkillsField: React.FC<Props> = ({ margin, name = 'specific_skills', token }) => {
  const [skills, setSkills] = useState<Tag[]>([]);

  const rules = {
    validate: (value: string[]) => {
      if (value === undefined) {
        return true;
      }
      return value?.length <= 5 || 'You can select only 5 skills';
    },
  };

  const selectProps: AutocompleteProps = {
    name,
    items: skills,
    rules,
    label: 'Skills, experience or specialisms',
    margin,
    multiple: true,
    creatable: true,
    group: GROUP,
    token,
  };

  useEffect(() => {
    let mounted = true;
    fetchTagsRequest({ group: GROUP }).then(result => {
      if (mounted) {
        setSkills(result.data.items);
      }
    });
    return () => {
      mounted = false;
    };
  }, []);

  return <ControlledTagAutocomplete {...selectProps} />;
};
