import React from 'react';

import { Box, Divider, Typography } from '@material-ui/core';

export const ClockColHeader = () => {
  return (
    <Box display="flex" gridGap={6} justifyContent="center">
      <Box display="flex" flexDirection="column" alignItems="center" gridGap={2}>
        <Typography variant="caption">Clock In</Typography>
        <Typography variant="caption">Time</Typography>
      </Box>

      <Divider style={{ width: 2 }} orientation="vertical" flexItem />

      <Box display="flex" flexDirection="column" alignItems="center" gridGap={2}>
        <Typography variant="caption">Clock Out</Typography>
        <Typography variant="caption">Time</Typography>
      </Box>
    </Box>
  );
};
