import React, { useContext, useEffect, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';

import { DeviceContext } from '../../../../contexts';

import { MAIN_CONTAINER_ID } from '../../../../constants';

interface Props {
  open: boolean;
  onClose: () => void;
  onClick: () => void;
}

export const GoToSettingsDialog = ({ open, onClose, onClick }: Props) => {
  const { isMobile } = useContext(DeviceContext);

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      open={open}
      onClose={onClose}>
      <DialogTitle>Do you want to add custom Location Details?</DialogTitle>

      <DialogContent>
        <Typography variant="caption">
          If you do so, this field will be removed from Location Details of all your Locations. Once you save
          changes, this action cannot be undone.
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button style={{ width: 105 }} size="large" variant="outlined" onClick={onClose}>
          Close
        </Button>

        <Button size="large" style={{ width: 175 }} variant="contained" color="primary" onClick={onClick}>
          Let's go to Settings
        </Button>
      </DialogActions>
    </Dialog>
  );
};
