import { CustomLocationFieldsDetailsDTO } from '@vyce/core/src/api/types';
import { DragItem } from '../types';

export const prepareData = (
  data: CustomLocationFieldsDetailsDTO
): {
  default_location_fields: DragItem[];
  custom_location_fields: DragItem[];
} => {
  return {
    default_location_fields:
      data?.systemic_fields?.items.map(f => ({
        ...f,
        id: f.system_name,
      })) || [],
    custom_location_fields: data?.custom_fields?.items.map(f => ({ ...f, id: f.system_name })) || [],
  };
};
