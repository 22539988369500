import React, { useContext } from 'react';

import { ResponsiveBar } from '@nivo/bar';
import { Box, useTheme } from '@material-ui/core';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { BiAbacus } from 'react-icons/bi';

import { monochrome } from '@vyce/core/src/theme/styles';
import { DeviceContext } from '@vyce/core/src/contexts';
import { PageLoading, AppIconButton } from '@vyce/core/src/components';

import { useStyles } from './styles';
import { useWidgetData } from './hooks';
import { Props } from './types';
import { keys } from './config';
import { Legend, CustomBottomTick, CustomBarComponent, Empty, ArrowBox, FilterDialog } from './components';

const marginOptions = { top: 10, right: 20, bottom: 40, left: 50 };

export const HealthSafetyWidget = (props: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const { isMobile } = useContext(DeviceContext);

  const {
    loading,
    cuttedData,
    splitedData,
    ref,
    showDecreaseButton,
    showIncreaseButton,
    isOpenFilterDialog,
    preparedValuesForSettingForm,
    decreasePage,
    increasePage,
    handleBarClick,
    setFilterDialogOpen,
    setFilterDialogFalse,
    createOrUpdateSurveysSetting,
  } = useWidgetData(props);

  if (loading) {
    return (
      <div ref={ref} className={classes.wrapper}>
        <PageLoading />
      </div>
    );
  }

  if (!cuttedData.length) {
    return (
      <div ref={ref} className={classes.wrapper}>
        <Empty />
      </div>
    );
  }

  return (
    <div ref={ref} className={classes.wrapper}>
      <Box className={classes.filterWrapper}>
        <AppIconButton variant="paper" isSmall isBorder onClick={setFilterDialogOpen}>
          <BiAbacus size="20px" color={theme.palette.text.primary} />
        </AppIconButton>
      </Box>
      <Box height="300px" marginBottom={6}>
        <ResponsiveBar
          data={cuttedData}
          keys={keys}
          indexBy="id"
          margin={marginOptions}
          padding={0.7}
          label={d => String(d.value)}
          colors={[theme.palette.primary.main]}
          animate={false}
          theme={{
            labels: {
              text: {
                fill: theme.palette.type === 'dark' ? monochrome.dark : monochrome.lightest,
                fontSize: 13,
                fontWeight: 500,
                lineHeight: '22px',
              },
            },
            axis: {
              ticks: {
                line: {
                  display: 'none',
                },
                text: {
                  fill: theme.palette.type === 'dark' ? monochrome.lightest : monochrome.dark,
                  fontSize: 13,
                  fontFamily: 'inherit',
                  fontWeight: 500,
                  lineHeight: '22px',
                  display: 'inline-block',
                  textAlign: 'center',
                  width: '100%',
                },
              },
              legend: {
                text: {
                  fontSize: 13,
                  fill: theme.palette.type === 'dark' ? monochrome.lightest : monochrome.dark,
                  fontFamily: 'inherit',
                  fontWeight: 600,
                  lineHeight: '22px',
                },
              },
            },
          }}
          valueScale={{ type: 'linear' }}
          barComponent={CustomBarComponent}
          axisBottom={{
            renderTick: CustomBottomTick,
            legend: 'Health Condition',
            legendPosition: 'middle',
            legendOffset: 30,
          }}
          axisLeft={{
            legendPosition: 'middle',
            legend: 'Number of Workers',
            legendOffset: -40,
            format: e => Math.floor(e) === e && e,
          }}
          axisTop={null}
          axisRight={null}
          labelSkipWidth={3}
          labelSkipHeight={0}
          onClick={handleBarClick}
        />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" gridGap={isMobile ? 10 : 32}>
        <ArrowBox>
          {showDecreaseButton && (
            <FiChevronLeft color={theme.palette.primary.main} size="30px" onClick={decreasePage} />
          )}
        </ArrowBox>
        <Legend data={splitedData} />
        <ArrowBox>
          {showIncreaseButton && (
            <FiChevronRight color={theme.palette.primary.main} size="30px" onClick={increasePage} />
          )}
        </ArrowBox>
      </Box>
      <FilterDialog
        defaultValues={preparedValuesForSettingForm}
        isOpen={isOpenFilterDialog}
        onClose={setFilterDialogFalse}
        onClick={createOrUpdateSurveysSetting}
      />
    </div>
  );
};

HealthSafetyWidget.CustomBarComponent = CustomBarComponent;
