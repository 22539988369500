import { TrackEvent, TrackEventType } from '@vyce/core/src/types';

import { getFaceTitle } from './getFaceTitle';
import { getLocationTitle } from './getLocationTitle';

export const processEventFaces = (events: TrackEvent[]) => {
  const clockIn = events.find(event => event.event_type === TrackEventType.CLOCK_IN);
  const clockOut = events.find(event => event.event_type === TrackEventType.CLOCK_OUT);
  const inFaceVerified = !!clockIn?.face_verified;
  const outFaceVerified = !!clockOut?.face_verified;
  const inLocationVerified = !!clockIn?.within_area;
  const outLocationVerified = !!clockOut?.within_area;
  const faceTitle = getFaceTitle(inFaceVerified, outFaceVerified);
  const locationTitle = getLocationTitle(inLocationVerified, outLocationVerified);

  return {
    clockInIcon: clockIn?.icon_url || '',
    clockOutIcon: clockOut?.icon_url || '',
    clockInFaceVerified: inLocationVerified,
    clockOutFaceVerified: outLocationVerified,
    faceVerificationTitle: faceTitle,
    locationVerificationTitle: locationTitle,
  };
};
