import { Accordion, AccordionDetails, AccordionSummary, Box, Paper, Typography } from '@material-ui/core';

import { AppSwitch } from '@vyce/core/src/components/inputs';
import { TimePeriodSettings } from '@vyce/core/src/types';

import { useStyles } from '../styles';
import { TimeLogsPeriodsAccordion } from './TimeLogsPeriodsAccordion';

export const TimeLogsReporting = ({
  expanded,
  endDay,
  settings,
  setExpanded,
  onPeriodChange,
}: {
  expanded: boolean;
  endDay: string;
  settings: TimePeriodSettings;
  setExpanded: (arg0: boolean) => void;
  onPeriodChange: (settings: TimePeriodSettings) => Promise<void>;
}) => {
  const classes = useStyles();

  return (
    <Paper variant="outlined" className={classes.wrapper}>
      <Box marginBottom={3}>
        <Typography variant="h6">Time Logs Reporting Period</Typography>
      </Box>

      <Box className={classes.controlsContainer}>
        <Accordion
          expanded={expanded}
          classes={{ expanded: classes.expanded }}
          className={classes.mainAccordionWrapper}>
          <AccordionSummary classes={{ root: classes.accordionSummary }}>
            <Box display="flex" width="100%" justifyContent="space-between" paddingRight="10px">
              <Typography variant="subtitle2">Customise reporting period</Typography>

              <AppSwitch checked={expanded} onChange={(e, checked) => setExpanded(checked)} />
            </Box>
          </AccordionSummary>

          <AccordionDetails>
            <Box marginLeft={2} marginTop={3} flexBasis="70%" display="flex" flexDirection="column">
              <Typography className={classes.bold} variant="caption">
                Set report date range
              </Typography>
              <Typography variant="caption">
                How would you like to consolidate your Daily Time Logs?
              </Typography>

              <TimeLogsPeriodsAccordion
                onPeriodChange={onPeriodChange}
                settings={settings}
                endDay={endDay}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Paper>
  );
};
