import { FilterSection } from '@vyce/core/src/types';
import { SALARY_TYPES } from '@vyce/core/src/utils/job';

export const RELEVANT_SORTING: any = [];

export const NEAREST_SORTING = [
  {
    field_name: 'distance',
    desc: false,
  },
];

export const INVALID_DATE_VALUE = 'Invalid Date';
export const LIST_STEP = 6;

export const DEFAULT_CALCULATED_DISTANCE = null;
export const DEFAULT_DISTANCE = 75;
export const MIN_DISTANCE = 15;
const DEFAULT_STEP = 15;
const MAX_DISTANCE = 75;

export const POSSIBLE_DISTANCE = 60;
export const MINUTES_IN_HOUR = 60;
export const MILES_PER_HOUR = 8;

export const NEGOTIABLE = 'negotiable';

export const HIRING_FILTER_OPTIONS: FilterSection[] = [
  {
    title: 'My Jobs',
    filters: [
      {
        type: 'checkbox',
        label: 'My Jobs',
        field: 'mine',
        values: false,
        defaultValue: false,
      },
    ],
  },
  {
    title: 'Contract Type',
    filters: [
      {
        type: 'checkbox',
        label: 'Contract',
        field: 'isContract',
        values: false,
        defaultValue: false,
      },
      {
        type: 'checkbox',
        label: 'Permanent',
        field: 'isPermanent',
        values: false,
        defaultValue: false,
      },
    ],
  },
  {
    title: 'Start Date',
    filters: [
      {
        type: 'checkbox',
        label: 'ASAP',
        field: 'urgently',
        values: false,
        defaultValue: false,
      },
      {
        type: 'date',
        label: 'From',
        field: 'start_date_gte',
        values: null,
        defaultValue: null,
      },
      {
        type: 'date',
        label: 'To',
        field: 'start_date_lte',
        values: null,
        defaultValue: null,
      },
    ],
  },
  {
    title: 'Salary',
    filters: [
      {
        type: 'select',
        multiple: false,
        label: 'Type',
        field: 'salary_type',
        values: SALARY_TYPES,
        defaultValue: 'Hour',
      },
      {
        type: 'input',
        dataType: 'number',
        label: 'From (£)',
        field: 'salary_from',
        values: '',
        defaultValue: '',
      },
      {
        type: 'input',
        dataType: 'number',
        label: 'To (£)',
        field: 'salary_to',
        values: '',
        defaultValue: '',
      },
    ],
  },
];

const markOptions = [
  {
    value: 15,
    label: '15 Min',
  },
  {
    value: 30,
    label: '30 Min',
  },
  {
    value: 45,
    label: '45 Min',
  },
  {
    value: 60,
    label: '60 Min',
  },
  {
    value: 75,
    label: '60+ Min',
  },
];

export const FILTER_OPTIONS: FilterSection[] = [
  {
    title: 'Verified documents',
    filters: [
      {
        type: 'checkbox',
        label: 'ID Verified',
        field: 'id_verified',
        values: false,
        defaultValue: false,
      },
      {
        type: 'checkbox',
        label: 'CSCS Verified',
        field: 'cscs_verified',
        values: false,
        defaultValue: false,
      },
      {
        type: 'checkbox',
        label: 'CPCS Verified',
        field: 'cpcs_verified',
        values: false,
        defaultValue: false,
      },
      {
        type: 'checkbox',
        label: 'RTW Verified',
        field: 'rtw_verified',
        values: false,
        defaultValue: false,
      },
    ],
  },
  {
    title: 'Distance from job',
    filters: [
      {
        type: 'slider',
        label: 'Miles',
        field: 'distance',
        defaultValue: DEFAULT_DISTANCE,
        min: MIN_DISTANCE,
        max: MAX_DISTANCE,
        marks: markOptions,
        step: DEFAULT_STEP,
      },
    ],
  },
];

export const defaultValues = {
  name: undefined,
  number_of_workers: 1,
  description: '',
  address: '',
  post_code: '',
  lon: undefined,
  lat: undefined,
  job_type: 'permanent',
  duration: undefined,
  duration_type: undefined,
  start_date: new Date(),
  urgently: false,

  salary_type: 'Hour',
  salary_from: undefined,
  salary_to: undefined,
  overtime_type: '',
  overtime_from: undefined,
  overtime_to: undefined,

  years_of_experience: 3,
  skills: [],
  qualifications_required: [],
};
