import React from 'react';

import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { ImageView } from '../../ImageView';
import { useStyles } from '../styles';

export const EmptyContent = ({
  extraText,
  uploadText,
  extraSmallSize,
}: {
  extraText: string;
  uploadText?: string;
  extraSmallSize: boolean;
}) => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" alignItems="center" gridGap={8}>
      <ImageView />
      <Box display="flex" flexDirection="column" gridGap={4}>
        <Typography
          color="textSecondary"
          className={clsx(classes.smallText, classes.textCentering, {
            [classes.smallText]: !extraSmallSize,
            [classes.extraSmallText]: extraSmallSize,
          })}
          variant="caption">
          Drag & drop your {extraText} or
        </Typography>
        <Typography
          className={clsx(classes.typography, { [classes.extraSmallText]: extraSmallSize })}
          color="textSecondary"
          variant="caption">
          {uploadText ? uploadText : 'Click here to upload'}
        </Typography>
      </Box>
    </Box>
  );
};
