import React from 'react';

import { Box, Divider, Typography } from '@material-ui/core';

interface Props {
  title: string;
}

export const DayTimeColHeader: React.FC<Props> = ({ title }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" marginLeft="12px">
      <Typography variant="caption" style={{ fontWeight: 500 }}>
        {title}
      </Typography>

      <Box display="flex" gridGap={4}>
        <Typography variant="caption">Reg</Typography>
        <Divider style={{ width: 2 }} orientation="vertical" flexItem />
        <Typography variant="caption">OT</Typography>
      </Box>
    </Box>
  );
};
