import { useRef, useContext } from 'react';

import { Box, FormControlLabel } from '@material-ui/core';
import { RxHamburgerMenu } from 'react-icons/rx';
import { useDrop, useDrag } from 'react-dnd';
import clsx from 'clsx';

import { AppCheckbox } from '@vyce/core/src/components/inputs';

import { ItemTypes } from '../config';
import { DragItem } from '../types';
import { useStyles } from '../styles';
import { FieldsFunctionsContext } from './FieldsFunctionsContext';

export type Item = keyof typeof ItemTypes;
export type ItemValue = typeof ItemTypes[Item];

export const Field = ({
  id,
  name,
  is_active,
  disabled,
  type,
  fieldType,
}: DragItem & { fieldType: ItemValue }) => {
  const classes = useStyles();
  const { moveCard, findCard, handleUpdateFields, changeCustomFieldName } =
    useContext(FieldsFunctionsContext);
  const originalIndex = findCard(id, type).index;

  const ref = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: fieldType,
      item: { id, originalIndex },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveCard(droppedId, originalIndex, type);
        } else {
          handleUpdateFields(type);
        }
      },
    }),
    [id, originalIndex, moveCard]
  );

  const [, drop] = useDrop(
    () => ({
      accept: fieldType,
      hover({ id: draggedId }: DragItem) {
        if (draggedId !== id) {
          const { index: overIndex } = findCard(id, type);
          moveCard(draggedId, overIndex, type);
        }
      },
    }),
    [findCard, moveCard]
  );

  drag(drop(ref));

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    changeCustomFieldName(id, checked, type);
  };

  const opacity = isDragging ? 0 : 1;

  return (
    <div ref={ref} style={{ opacity }}>
      <Box display="flex" alignItems="center" gridGap={8} marginLeft={disabled ? '22px' : 0}>
        {!disabled && <RxHamburgerMenu />}
        <FormControlLabel
          style={{ opacity: is_active ? 1 : 0.5 }}
          control={
            <AppCheckbox
              checked={is_active}
              onChange={handleChangeValue}
              variant="rectangle"
              color="primary"
              disabled={disabled}
              className={clsx({ [classes.defaultCheckbox]: disabled })}
            />
          }
          label={name}
        />
      </Box>
    </div>
  );
};
