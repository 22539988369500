import React, { useState } from 'react';
import { FilledInput, FormControl, InputAdornment, InputLabel, Theme, useTheme } from '@material-ui/core';
import { useAppTextFieldStyles } from './inputs/AppTextField';
import { HiOutlineSearch } from 'react-icons/hi';

interface Props {
  handleSearchChange: any;
  searchText: string;
}

export const InputSearch: React.FC<Props> = ({ handleSearchChange, searchText }) => {
  const inputClasses = useAppTextFieldStyles({});
  const theme = useTheme<Theme>();
  const [text, setText] = useState<string>(searchText);

  return (
    <FormControl fullWidth variant="filled">
      <InputLabel variant="filled" htmlFor="job-search">
        Search
      </InputLabel>

      <FilledInput
        disableUnderline
        value={text}
        onChange={e => {
          setText(e.target.value);
          handleSearchChange(e);
        }}
        style={{ maxWidth: 250 }}
        classes={inputClasses}
        id="job-search"
        endAdornment={
          <InputAdornment position="end">
            <HiOutlineSearch color={theme.palette.text.primary} />
          </InputAdornment>
        }
      />
    </FormControl>
  );
};
