import { useEffect, useState, useCallback, useContext } from 'react';

import type { GridSortModel } from '@mui/x-data-grid';

import { AddMemberForm, AddTimeWorkerData, Company, Employee, TimeMember } from '@vyce/core/src/types';
import { useBooleanState } from '@vyce/core/src/hooks';
import {
  addSiteWorkerRequest,
  deleteShiftWorkerRequest,
  getSiteWorkersRequest,
} from '@vyce/core/src/api/time';
import { GetSiteWorkersRequestPayload } from '@vyce/core/src/api/types';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { formatTimeSortModel } from '@vyce/core/src/utils/sorting';
import { useTable } from '@vyce/core/src/hooks/useTable';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

interface Props {
  siteId?: string;
  selectedCompany?: Company;
}

const defaultArray: TimeMember[] = [];
const defaultSortModel: GridSortModel = [{ field: 'first_name', sort: 'asc' }];

export const useLocationMembersData = ({ siteId, selectedCompany }: Props) => {
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const [loadingTableData, setLoadingTableData] = useState<boolean>(false);
  const [workers, setWorkers] = useState<TimeMember[]>(defaultArray);
  const [isAddMemberDialogOpen, openAddMemberDialog, closeAddMemberDialog] = useBooleanState(false);
  const [closeGridAction, setCloseGridAction] = useState<boolean>(false);

  const {
    sortModel,
    offset,
    substring,
    total,
    setTotal,
    handleSortModelChange,
    handlePageChange,
    handleSearchChange,
  } = useTable({ defaultSortModel });

  const companyId = selectedCompany?.uuid ?? '';

  const removeWorker = async (worker: TimeMember) => {
    if (!companyId || !worker.site_id || !worker.shift_id || !worker.user_id) {
      return;
    }

    try {
      await deleteShiftWorkerRequest(companyId, worker.site_id, worker.shift_id, worker.user_id);
      showNotification({ message: 'Member has been removed', options: { variant: 'success' } });
      setCloseGridAction(true);
      getMembers();
    } catch (e) {
      handleServerError(e);
    }
  };

  const getMembers = useCallback(async () => {
    if (!companyId || !siteId) {
      return setWorkers(defaultArray);
    }

    setLoadingTableData(true);
    try {
      const payload: GetSiteWorkersRequestPayload = {
        offset,
        limit: GRID_PAGE_SIZE,
        substring,
        order_by: formatTimeSortModel<TimeMember>(sortModel),
        site_id: siteId,
      };
      const res = await getSiteWorkersRequest({ companyId, payload });
      setTotal(res.data.count);
      setWorkers(res.data.items);
      setLoadingTableData(false);
    } catch (e) {
      setLoadingTableData(false);
      handleServerError(e);
    }
  }, [offset, sortModel, substring, companyId, siteId]);

  const addMember = async (data: AddMemberForm, employee?: Employee) => {
    if (!employee || !companyId || !siteId) {
      return;
    }

    try {
      const worker: AddTimeWorkerData = {
        basic_amount: data.basic_amount,
        basic_unit: data.basic_unit || null,
        overtime_amount: data.overtime_amount,
        overtime_unit: data.overtime_unit || null,
        user_id: employee.user_id,
      };
      await addSiteWorkerRequest({
        companyId,
        siteId,
        shiftId: data.shift_id,
        worker,
      });
      showNotification({ message: 'Member has been added to the shift', options: { variant: 'success' } });
      closeAddMemberDialog();
      getMembers();
    } catch (e) {
      handleServerError(e);
    }
  };

  useEffect(() => {
    getMembers();
  }, [getMembers]);

  return {
    total,
    workers,
    substring,
    sortModel,
    isAddMemberDialogOpen,
    loadingTableData,
    closeGridAction,
    handlePageChange,
    handleSearchChange,
    addMember,
    getMembers,
    handleSortModelChange,
    removeWorker,
    openAddMemberDialog,
    closeAddMemberDialog,
  };
};
