import { axios } from '../index';

export const uploadPassportRequest = (passport: File) => {
  const bodyFormData = new FormData();
  bodyFormData.append('scan', passport);

  return axios.post(`/documents/passports/scan`, bodyFormData);
};

export const uploadSelfieRequest = (selfie: File) => {
  const bodyFormData = new FormData();
  bodyFormData.append('selfie', selfie);

  return axios.post(`/documents/passports/selfie`, bodyFormData);
};

export const getPassportRequest = () => {
  return axios.get(`/documents/passports`);
};
