import { useCallback, useContext, useEffect, useState } from 'react';

import type { GridSortModel } from '@mui/x-data-grid';

import { Subscription, SubscriptionInvoice } from '@vyce/core/src/types';
import { getInvoicesRequest } from '@vyce/core/src/api/billing';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { useBooleanState } from '@vyce/core/src/hooks';
import { formatSortModel } from '@vyce/core/src/utils/sorting';
import { useTable } from '@vyce/core/src/hooks/useTable';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

interface Props {
  token: string;
  subscription?: Subscription;
  companyId?: string;
}

const defaultSortModel: GridSortModel = [{ field: 'next_payment_attempt', sort: 'desc' }];

export const usePaymentsData = ({ token, subscription, companyId }: Props) => {
  const { handleServerError } = useContext(NotificationContext);
  const [invoices, setInvoices] = useState<SubscriptionInvoice[]>([]);
  const [loading, setLoadingTrue, setLoadingFalse] = useBooleanState();
  const [status, setStatus] = useState<string>('');

  const { sortModel, offset, total, setTotal, handleSortModelChange, handlePageChange } = useTable({
    defaultSortModel,
  });

  const getInvoices = useCallback(async () => {
    if (!companyId || !subscription?.active) {
      return;
    }
    try {
      setLoadingTrue();
      const { data } = await getInvoicesRequest({
        token,
        companyId,
        requestData: {
          offset,
          limit: GRID_PAGE_SIZE,
          order_by: formatSortModel<SubscriptionInvoice>(sortModel),
          status: status || undefined,
        },
      });
      setInvoices(data.items);
      setTotal(data.count);
      setLoadingFalse();
    } catch (e) {
      setLoadingFalse();
      handleServerError(e);
    }
  }, [companyId, offset, sortModel, status]);

  useEffect(() => {
    getInvoices();
  }, [companyId, offset, sortModel, status]);

  const handleViewInvoiceClick = (url: string) => {
    window.open(url, '_blank');
  };

  const handleFilterChange = (newFilters: any) => {
    setStatus(newFilters.status.toLowerCase());
  };

  return {
    invoices,
    loading,
    total,
    sortModel,
    handleSortModelChange,
    handlePageChange,
    handleViewInvoiceClick,
    handleFilterChange,
  };
};
