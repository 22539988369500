import { TimeTotalInfoProps } from '@vyce/core/src/types';

import { allLocationsId } from '../config';

export const getTransformedTotalData = ({
  total_hours_decimals,
  overtime_hours_decimals,
  basic_hours_decimals,
  total_clocked_in_hours_decimals,
  total_workers,
}: TimeTotalInfoProps) => {
  return {
    site_name: 'All locations',
    workers: total_workers,
    clocked_in_hours_decimals: total_clocked_in_hours_decimals,
    basic_hours_decimals,
    overtime_hours_decimals,
    total_hours_decimals,
    status: '-',
    site_id: allLocationsId,
  };
};
