import React from 'react';
import { Box, createStyles, makeStyles, Typography, useTheme } from '@material-ui/core';
import { FiChevronLeft } from 'react-icons/fi';
import { BackToData } from '../types';

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      padding: '20px 50px',
      height: 62,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        padding: '8px 12px',
      },
    },
    backText: {
      marginLeft: '10px',
      fontWeight: 600,
    },
  })
);

interface Props {
  backTo: BackToData;
}

export const BackTo: React.FC<Props> = ({ backTo }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box className={classes.container} onClick={() => backTo.back()}>
      <FiChevronLeft color={theme.palette.text.secondary} size="18px" />
      <Typography color="textSecondary" variant="caption" className={classes.backText}>
        Back To {backTo.text}
      </Typography>
    </Box>
  );
};
