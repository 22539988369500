import { useMemo } from 'react';

import { createInvitesRequest } from '@vyce/core/src/api/invites';

import { useTypedSelector } from 'src/hooks';

export const useUniversalInviteModuleData = () => {
  const { selectedCompany, selectedCompanyAppData, access_token } = useTypedSelector(state => state.helper);

  const { teams, paySchedules, locations, shifts } = selectedCompanyAppData || {};
  const selectedCompanyId = selectedCompany?.uuid ?? '';

  const contextOptions = useMemo(
    () => ({
      token: access_token,
      teams,
      paySchedules,
      locations,
      shifts,
      companyId: selectedCompanyId,
      createInvitesRequest,
    }),
    [access_token, selectedCompanyId, teams, paySchedules]
  );

  return {
    contextOptions,
    selectedCompanyId,
  };
};
