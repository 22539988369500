import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { GridColDef } from '@mui/x-data-grid';
import { Box, Button, Typography, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { BiExport } from 'react-icons/bi';

import { AppDataGrid, ConfirmDialog } from '@vyce/core/src/components';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { getUKFormattedDate } from '@vyce/core/src/utils/dates';
import {
  approveByPeriodRequest,
  getTimeLogsByPeriodRequest,
  postExportTimeLogsRequest,
} from '@vyce/core/src/api/time';
import {
  TIME_INTERFACE_PERMISSIONS,
  TimeLogByPeriod,
  TimeLogBySite,
  TimeLogStatus,
  UserPermission,
} from '@vyce/core/src/types';
import { useBooleanState } from '@vyce/core/src/hooks';
import { generateExcelFileLink } from '@vyce/core/src/utils';

import { useStyles } from './styles';
import { NotificationContext } from '../../../contexts/notificationContext';

export const getStatusTitle = (status: TimeLogStatus) => {
  switch (status) {
    case TimeLogStatus.APPROVED:
      return 'Approved';
    case TimeLogStatus.NOT_APPROVED:
      return 'Awaiting';
    case TimeLogStatus.PARTLY_APPROVED:
      return 'Partly Approved';
    default:
      return status;
  }
};

export const StatusComponent = ({ status }: { status: TimeLogStatus }) => {
  const theme = useTheme();

  return (
    <Typography
      variant="caption"
      style={{
        fontWeight: 500,
        color:
          status === TimeLogStatus.NOT_APPROVED
            ? theme.palette.error.main
            : status === TimeLogStatus.PARTLY_APPROVED
            ? theme.palette.warning.main
            : theme.palette.success.main,
      }}>
      {getStatusTitle(status)}
    </Typography>
  );
};

export const ExportButtonComponent = ({
  onClick,
  log,
}: {
  log: TimeLogByPeriod | TimeLogBySite;
  onClick: Function;
}) => {
  const classes = useStyles();

  const handleClick = () => {
    onClick(log);
  };

  return (
    <Button
      onClick={handleClick}
      startIcon={<BiExport size="15px" />}
      variant="contained"
      disabled={!log.total_hours_decimals}
      className={classes.exportButton}
      size="small"
      color="secondary">
      Export
    </Button>
  );
};

export const ApproveButtonComponent = ({
  log,
  onClick,
}: {
  log: TimeLogByPeriod | TimeLogBySite;
  onClick: Function;
}) => {
  const classes = useStyles();

  const handleClick = () => {
    onClick(log);
  };

  return (
    <>
      {log.status === TimeLogStatus.APPROVED ? (
        <Box display="flex" alignItems="center" justifyContent="center" className={classes.approveButton}>
          <Typography variant="button">Approved</Typography>
        </Box>
      ) : (
        <Button
          size="small"
          onClick={handleClick}
          variant="contained"
          color="primary"
          className={classes.approveButton}>
          Approve All
        </Button>
      )}
    </>
  );
};

interface Props {
  companyId?: string;
  userPermissions?: UserPermission[];
}

export const TimeLogsByPeriod: React.FC<Props> = ({ companyId, userPermissions }) => {
  const history = useHistory();
  const { handleServerError } = useContext(NotificationContext);
  const [timeLogs, setTimeLogs] = useState<TimeLogByPeriod[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [approveLoading, setApproveLoading] = useState<boolean>(false);
  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] = useBooleanState(false);
  const [selectedLog, setSelectedLog] = useState<TimeLogByPeriod>();

  const cnaApproveTimelogs = useMemo(
    // probably can be replaced with another permission when backend will update the manager permission list
    () =>
      userPermissions?.length
        ? userPermissions.includes(TIME_INTERFACE_PERMISSIONS.LOCATIONS_AND_SHIFTS)
        : true,
    [userPermissions]
  );

  const columns: GridColDef[] = [
    {
      field: 'period',
      headerName: 'For Period',
      width: 100,
      flex: 0.15,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <Box
          display="flex"
          style={{ cursor: 'pointer' }}
          flexDirection="column"
          onClick={() => history.push(`/time/timelogs/${row.period?.start} - ${row.period?.end}`)}>
          <Typography color="secondary" variant="caption" style={{ fontWeight: 500 }}>
            {getUKFormattedDate(row.period?.start)}
          </Typography>
          <Typography color="secondary" variant="caption" style={{ fontWeight: 500 }}>
            {getUKFormattedDate(row.period?.end)}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'workers',
      headerName: 'Workers',
      disableColumnMenu: true,
      flex: 0.1,
      minWidth: 100,
    },
    {
      field: 'clocked_in_hours_decimals',
      headerName: 'Clocked Hours',
      disableColumnMenu: true,
      flex: 0.1,
      minWidth: 140,
      renderCell: ({ row }) => (
        <Typography variant="caption" style={{ fontWeight: 500 }}>
          {row.clocked_in_hours_decimals}
        </Typography>
      ),
    },
    {
      field: 'basic_hours_decimals',
      headerName: 'Regular Paid Hours',
      disableColumnMenu: true,
      flex: 0.1,
      minWidth: 160,
      renderCell: ({ row }) => (
        <Typography variant="caption" style={{ fontWeight: 500 }}>
          {row.basic_hours_decimals}
        </Typography>
      ),
    },
    {
      field: 'overtime_hours_decimals',
      headerName: 'OT Paid Hours',
      disableColumnMenu: true,
      flex: 0.1,
      minWidth: 150,
      renderCell: ({ row }) => (
        <Typography variant="caption" style={{ fontWeight: 500 }}>
          {row.overtime_hours_decimals}
        </Typography>
      ),
    },
    {
      field: 'total_hours_decimals',
      headerName: 'Total Paid Hours',
      disableColumnMenu: true,
      flex: 0.1,
      minWidth: 160,
      renderCell: ({ row }) => (
        <Typography variant="caption" style={{ fontWeight: 500 }}>
          {row.total_hours_decimals}
        </Typography>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.1,
      minWidth: 100,
      disableColumnMenu: true,
      renderCell: ({ row }) => <StatusComponent status={row.status} />,
    },
    {
      field: 'controls',
      headerName: '',
      width: 290,
      hideSortIcons: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <Box display="flex" gridGap={16} width="100%">
          <ExportButtonComponent onClick={exportTimelogs} log={row as TimeLogByPeriod} />
          <ApproveButtonComponent log={row as TimeLogByPeriod} onClick={handleApproveButtonClick} />
        </Box>
      ),
    },
  ];

  const permissionBasedColumns = useMemo(() => {
    if (cnaApproveTimelogs) {
      return columns;
    }
    return columns.filter(col => col.field !== 'controls');
  }, [cnaApproveTimelogs]);

  const handleApproveButtonClick = (log: TimeLogByPeriod) => {
    setSelectedLog(log);
    openConfirmDialog();
  };

  const getTimeLogs = useCallback(async () => {
    if (!companyId) {
      return;
    }
    try {
      const res = await getTimeLogsByPeriodRequest(companyId);
      setTimeLogs(res.data.items);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, [companyId]);

  const exportTimelogs = async (log: TimeLogByPeriod) => {
    if (!companyId) {
      return;
    }
    try {
      const res = await postExportTimeLogsRequest({ companyId, data: { period: log.period } });
      generateExcelFileLink(res);
    } catch (e) {
      handleServerError(e);
    }
  };

  const approve = async () => {
    if (!companyId || !selectedLog?.period) {
      return;
    }
    try {
      setApproveLoading(true);
      await approveByPeriodRequest(companyId, selectedLog.period);
      getTimeLogs();
      setApproveLoading(false);
      closeConfirmDialog();
    } catch (e) {
      handleServerError(e);
      setApproveLoading(false);
    }
  };

  useEffect(() => {
    getTimeLogs();
  }, [getTimeLogs]);

  return (
    <>
      <AppDataGrid
        noPaper
        rows={timeLogs}
        loading={loading}
        height="calc(100vh - 185px)"
        getRowId={row => row.period?.start + row.period?.end}
        columns={permissionBasedColumns}
        rowCount={timeLogs.length}
        paginationMode="server"
        sortingMode="server"
        pageSize={GRID_PAGE_SIZE}
        rowsPerPageOptions={[GRID_PAGE_SIZE]}
        disableSelectionOnClick
      />

      <ConfirmDialog
        handleClose={closeConfirmDialog}
        open={isConfirmDialogOpen}
        confirmText="Approve"
        title="Do you want to approve this Time log?"
        loading={approveLoading}
        handleConfirm={approve}
      />
    </>
  );
};
