import { axios } from '@vyce/core/src/api';

import {
  Employee,
  EnrichedPayslips,
  HMRCSetting,
  PayrollPayload,
  PaySchedule,
  ReportPayload,
  TimesheetLine,
} from '../../types';
import {
  CISTimesheetRequest,
  CreateOrUpdateCompanyBankAccountRequest,
  DownloadCISReportRequest,
  GenerateNextPayrun,
  GetAgreementRequest,
  GetAgreementsRequest,
  GetCompanyBankAccountRequest,
  GetEmployeeTimesheetsRequest,
  GetPayrun,
  GetPayslipsRequest,
  GetTimesheetData,
  GetUserData,
  ListEmployeeRequest,
  ListPaySchedulesRequest,
  MarkAsPaidRequest,
  PullTimelogsRequestProps,
  RegisterPayCompanyData,
  RemoveEmployeeFromModuleRequest,
  RemoveEmployeeRequest,
  SendBulkPayStatements,
  SendCISReportRequest,
  SendPayslipsRequest,
  TimesheetByStaffDTO,
  UploadPayScheduleContractWrapperRequest,
} from '../types';

export const companyRegistrationRequest = (
  token: string,
  companyId: string,
  data: RegisterPayCompanyData
) => {
  return axios.post(`/pay/companies/${companyId}`, data);
};

export const getPaySchedulesRequest = ({ companyId, limit }: ListPaySchedulesRequest) => {
  return axios.get(`/pay/company/${companyId}/pay_schedules`, {
    params: { limit },
  });
};

export const getPayScheduleRequest = (companyId: string, payScheduleId: string) => {
  return axios.get(`/pay/company/${companyId}/pay_schedules/${payScheduleId}`);
};

export const updatePaySchedulesRequest = (companyId: string, data: PaySchedule) => {
  return axios.patch(`/pay/company/${companyId}/pay_schedules/${data.uuid}`, data);
};

export const importTimesheetRequest = ({
  token,
  companyId,
  payrunId,
  payScheduleId,
  file,
  mapping,
}: CISTimesheetRequest) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);
  if (mapping) {
    bodyFormData.append('mapping', JSON.stringify(mapping));
  }
  return axios.post(
    `/pay/companies/${companyId}/pay_schedules/${payScheduleId}/pay_runs/${payrunId}/timesheets`,
    bodyFormData
  );
};

export const createEmployeeRequest = (token: string, data: Employee) => {
  return axios.post(`/pay/employees`, data);
};

export const updateEmployeeRequest = (token: string, data: Employee, employeeId: string) => {
  return axios.patch(`/pay/employees/${employeeId}`, data);
};

export const getPayrunsRequest = (companyId: string, data: PayrollPayload) => {
  return axios.post(`/pay/companies/${companyId}/pay_runs`, data);
};

export const getTimesheetsRequest = (companyId: string, data: PayrollPayload) => {
  return axios.post(`/pay/company/${companyId}/timesheets`, data);
};

export const createTimesheetLineRequest = (
  token: string,
  companyId: string,
  data: TimesheetLine,
  payrunId: string
) => {
  return axios.post(
    `/pay/company/${companyId}/pay_run/${payrunId}/employee/${data.employee_id}/timesheet_lines`,
    data
  );
};

export const getTimesheetRequest = (companyId: string, payrunId: string, data: GetTimesheetData) => {
  return axios.post<TimesheetByStaffDTO>(`/pay/companies/${companyId}/timesheets/${payrunId}`, data);
};

export const getPayrunRequest = ({ token, companyId, payrunId, data }: GetPayrun) => {
  return axios.post(`/pay/companies/${companyId}/pay_runs/${payrunId}`, data);
};

export const deleteTimesheetLineRequest = (token: string, companyId: string, timesheetLineId: string) => {
  return axios.delete(`/pay/company/${companyId}/timesheet_lines/${timesheetLineId}`);
};

export const editTimesheetLineRequest = (
  token: string,
  companyId: string,
  timesheetLineId: string,
  data: TimesheetLine
) => {
  return axios.patch(`/pay/company/${companyId}/timesheet_lines/${timesheetLineId}`, data);
};

export const getHMRCSettingsByCompanyRequest = (companyId: string) => {
  return axios.get<HMRCSetting>(`/pay/company/${companyId}/hmrc_settings`);
};

export const patchHMRCSettingsByCompanyRequest = ({
  companyId,
  data: newHMRCSettings,
}: {
  companyId: string;
  data: Partial<HMRCSetting>;
}) => {
  return axios.patch<HMRCSetting>(`/pay/company/${companyId}/hmrc_settings`, newHMRCSettings);
};

export const getBACSFormatsRequest = (companyId: string) => {
  return axios.get(`/pay/company/${companyId}/bacs`);
};

export const createPayScheduleRequest = (
  token: string,
  companyId: string,
  data: any,
  outsourced: boolean
) => {
  return axios.post(`/pay/company/${companyId}/pay_schedules?outsourced=${outsourced}`, data);
};

export const submitTimesheetRequest = (
  companyId: string,
  payScheduleId: string,
  payrunId: string,
  data: GetTimesheetData
) => {
  return axios.post(
    `/pay/companies/${companyId}/pay_schedules/${payScheduleId}/timesheets/${payrunId}/submit`,
    data
  );
};

export const reopenTimesheetRequest = (
  token: string,
  companyId: string,
  payScheduleId: string,
  payrunId: string
) => {
  return axios.post(`/pay/company/${companyId}/pay_schedule/${payScheduleId}/timesheets/${payrunId}/reopen`);
};

export interface LookupEmployees {
  token: string;
  companyId: string;
  payScheduleId: string;
  substring: string;
}

export const lookupEmployeesRequest = ({ token, companyId, payScheduleId, substring }: LookupEmployees) => {
  const query = substring ? `?substring=${substring}&limit=20` : '?limit=20';
  return axios.get(`/pay/company/${companyId}/pay_schedule/${payScheduleId}/employees${query}`);
};

export const getCisReportsRequest = (payload: ReportPayload) => {
  return axios.post(`/hmrc/reports/companies/${payload.company_id}/cis300/search`, payload);
};

export const sendCis300ToHMRCRequest = ({ companyId, taxMonth, taxYear }: SendCISReportRequest) => {
  return axios.post(`/hmrc/reports/companies/${companyId}/cis300/send`, {
    tax_month: taxMonth,
    tax_year: taxYear,
  });
};

export const markPayrunAsPaidRequest = ({
  token,
  companyId,
  payScheduleId,
  payrunId,
}: MarkAsPaidRequest) => {
  return axios.post(
    `/pay/company/${companyId}/pay_schedules/${payScheduleId}/pay_runs/${payrunId}/mark_as_paid`
  );
};

export const markPayrunAsPaidForEmployeeRequest = ({
  token,
  companyId,
  payScheduleId,
  payrunId,
  employeeId,
}: MarkAsPaidRequest) => {
  return axios.post(
    `/pay/companies/${companyId}/pay_schedules/${payScheduleId}/pay_runs/${payrunId}/employees/${employeeId}/mark_as_paid`
  );
};

export const sendPayslipsByEmailRequest = ({ token, companyId, payrunLineId }: SendPayslipsRequest) => {
  return axios.post(`/pay/companies/${companyId}/payslips/${payrunLineId}/send_email`);
};

export const sendPayslipsBySMSRequest = ({ token, companyId, payrunLineId }: SendPayslipsRequest) => {
  return axios.post(`/pay/companies/${companyId}/payslips/${payrunLineId}/send_sms`);
};

export const downloadPayrunBACSRequest = (
  companyId: string,
  payrunId: string,
  data: { bacs_format: string; unpaid: boolean }
) => {
  return axios.post(`/pay/company/${companyId}/pay_runs/${payrunId}/bacs`, data, {
    responseType: 'stream',
  });
};

export const downloadPayrunsBACSRequest = (
  companyId: string,
  data: { bacs_format: string; unpaid: boolean; pay_runs: string[] }
) => {
  return axios.post(`/pay/company/${companyId}/pay_runs/bacs`, data, {
    responseType: 'stream',
  });
};

export const getNextPayPeriodRequest = (companyId: string, payScheduleId: string) => {
  return axios.get(`/pay/company/${companyId}/pay_schedule/${payScheduleId}/next_pay_period`);
};

export const generateNextPayrunRequest = ({
  companyId,
  payScheduleId,
  copyPrevious,
}: GenerateNextPayrun) => {
  const query = copyPrevious ? '?copy_employees_only=true' : '';
  return axios.post(`/pay/company/${companyId}/pay_schedule/${payScheduleId}/generate_next_pay_run${query}`);
};

export const getEmployeeListRequest = ({ companyId, data }: ListEmployeeRequest) => {
  return axios.post(`/pay/company/${companyId}/employees`, data);
};

export const removeEmployeeFromPayScheduleRequest = ({
  token,
  companyId,
  payScheduleId,
  employeeId,
}: RemoveEmployeeRequest) => {
  const query = payScheduleId ? `?pay_schedule_id=${payScheduleId}` : '';
  return axios.delete(`/pay/companies/${companyId}/employees/${employeeId}/pay_schedules${query}`);
};

export const removeEmployeeFromModuleRequest = ({
  token,
  companyId,
  userId,
}: RemoveEmployeeFromModuleRequest) => {
  return axios.delete(`/pay/companies/${companyId}/users/${userId}/pay_schedules`);
};

export const getTimesheetAsFileRequest = (token: string, companyId: string, payrunId: string) => {
  return axios.post(
    `/pay/companies/${companyId}/timesheets/${payrunId}/export_to_xslx`,
    {},
    {
      headers: {
        accept: 'application/json',
      },
      responseType: 'blob',
    }
  );
};

export const getPayrunAsFileRequest = (token: string, companyId: string, payrunId: string) => {
  return axios.post(
    `/pay/companies/${companyId}/pay_runs/${payrunId}/export_to_xslx`,
    {},
    {
      headers: {
        accept: 'application/json',
      },
      responseType: 'blob',
    }
  );
};

export const getEmployeePreviewRequest = ({ userId, companyId }: GetUserData) => {
  return axios.get(`/pay/company/${companyId}/users/${userId}`);
};

export const getPayslipsRequest = ({ data }: GetPayslipsRequest) => {
  return axios.post<{ items: EnrichedPayslips[]; count: number }>(`/pay/payslips`, data);
};

export const getPayslipsPDFRequest = (id: string) => {
  return axios.post(
    `/pay/payslips/${id}`,
    {},
    {
      headers: {
        accept: 'application/json',
      },
      responseType: 'blob',
    }
  );
};

export const getPayslipsPDFByEmployerRequest = (lineId: string, companyId?: string) => {
  return axios.post(
    `/pay/companies/${companyId}/payslips/${lineId}`,
    {},
    {
      headers: {
        accept: 'application/json',
      },
      responseType: 'blob',
    }
  );
};

export const searchUserPayslipsRequest = ({ userId, companyId, data }: GetPayslipsRequest) => {
  return axios.post(`/pay/companies/${companyId}/users/${userId}/payslips/search`, data);
};

export const downloadPayslipsRequestByEmployer = ({ userId, companyId, data }: GetPayslipsRequest) => {
  return axios.post(`/pay/companies/${companyId}/users/${userId}/payslips/xlsx`, data, {
    responseType: 'blob',
  });
};

export const downloadPayslipsRequestByEmployee = ({ data }: GetPayslipsRequest) => {
  return axios.post(`/pay/payslips/xlsx`, data, {
    responseType: 'blob',
  });
};

export const getEmployeeTimesheetsRequest = ({ userId, data }: GetEmployeeTimesheetsRequest) => {
  return axios.post(`/pay/employees/${userId}/timesheet_lines`, data);
};

export const getEmployeeTimesheetsByEmployerRequest = ({
  userId,
  companyId,
  data,
}: GetEmployeeTimesheetsRequest) => {
  return axios.post(`/pay/companies/${companyId}/users/${userId}/timesheet_lines`, data);
};

export const refreshPayrunRequest = (
  token: string,
  companyId: string,
  payScheduleId: string,
  payrunId: string
) => {
  return axios.post(`/pay/company/${companyId}/pay_schedules/${payScheduleId}/pay_runs/${payrunId}/refresh`);
};

export const deleteTimesheetRequest = (companyId: string, payrunId: string) => {
  return axios.delete(`/pay/companies/${companyId}/timesheets/${payrunId}`);
};

export const getTimesheetMappingRequest = (token: string, companyId: string, file: File) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);
  return axios.post(`/pay/companies/${companyId}/timesheet_mappings`, bodyFormData);
};

export const sendPayslipsToEmployeesBySMSRequest = ({
  token,
  companyId,
  payrunId,
}: SendBulkPayStatements) => {
  return axios.post(`/pay/companies/${companyId}/pay_runs/${payrunId}/payslips/send_by_sms`);
};

export const sendPayslipsToEmployeesByEmailRequest = ({
  token,
  companyId,
  payrunId,
}: SendBulkPayStatements) => {
  return axios.post(`/pay/companies/${companyId}/pay_runs/${payrunId}/payslips/send_by_email`);
};

export const getAgreementsRequest = ({
  token,
  substring,
  pay_scheme,
  limit = 1000,
  offset = 0,
  order_by,
}: GetAgreementsRequest) => {
  return axios.post(`/pay/agreements`, { substring, pay_scheme, limit, offset, order_by });
};

export const getAgreementsByEmployerRequest = ({
  token,
  substring,
  pay_scheme,
  limit = 1000,
  offset = 0,
  order_by,
  userId,
  companyId,
}: GetAgreementsRequest) => {
  return axios.post(`/pay/companies/${companyId}/users/${userId}/agreements`, {
    substring,
    pay_scheme,
    limit,
    offset,
    order_by,
  });
};

export const downloadAgreementByEmployerRequest = ({
  token,
  payScheduleId,
  companyId,
  userId,
}: GetAgreementRequest) => {
  return axios.post(
    `/pay/companies/${companyId}/pay_schedules/${payScheduleId}/users/${userId}/agreements`,
    {},
    {
      responseType: 'blob',
    }
  );
};

export const downloadAgreementRequest = ({ token, payScheduleId }: GetAgreementRequest) => {
  return axios.post(
    `/pay/pay_schedules/${payScheduleId}/agreements`,
    {},
    {
      responseType: 'blob',
    }
  );
};

export const downloadHealthAndSafetyPDFRequest = () => {
  return axios.post(
    `/pay/agreements/health_and_safety`,
    {},
    {
      responseType: 'blob',
    }
  );
};

export const getHealthAndSafetyPDFByUserIdRequest = ({
  userId,
  companyId,
}: Omit<GetAgreementsRequest, 'token'>) => {
  return axios.post(
    `/pay/companies/${companyId}/users/${userId}/agreements/health_and_safety`,
    {},
    { responseType: 'blob' }
  );
};

export const uploadPayScheduleContractWrapperRequest = ({
  payScheduleId,
  contract,
  companyId,
}: UploadPayScheduleContractWrapperRequest) => {
  const bodyFormData = new FormData();
  bodyFormData.append('custom_contract', contract);
  return axios.post(
    `/pay/companies/${companyId}/pay_schedules/${payScheduleId}/contract_wrapper`,
    bodyFormData,
    {
      responseType: 'blob',
    }
  );
};

export const getCompanyBankAccountRequest = ({ companyId }: GetCompanyBankAccountRequest) => {
  return axios.get(`/pay/companies/${companyId}/bank_account`);
};

export const createOrUpdateCompanyBankAccountRequest = ({
  companyId,
  bankAccount,
}: CreateOrUpdateCompanyBankAccountRequest) => {
  return axios.put(`/pay/companies/${companyId}/bank_account`, bankAccount);
};

export const downloadCISReportRequest = ({ companyId, reportId }: DownloadCISReportRequest) => {
  return axios.get(`/hmrc/reports/companies/${companyId}/cis300/${reportId}/xlsx`, {
    headers: {
      accept: 'application/json',
    },
    responseType: 'blob',
  });
};

export const getPassportRequest = ({ userId, companyId }: GetUserData) => {
  return axios.get(`/pay/companies/${companyId}/users/${userId}/documents/passport`);
};

export const getRtwRequest = ({ userId, companyId }: GetUserData) => {
  return axios.get(`/pay/companies/${companyId}/users/${userId}/documents/rtw`);
};

export const getPaySchedulesForInvitesRequest = ({ token, companyId }: GetAgreementsRequest) => {
  return axios.post(`/pay/companies/${companyId}/pay_schedules/look_up`, { limit: 100, order_by: [] });
};

export const pullTimeLogsRequest = ({
  payrunId,
  companyId,
  approved,
  strategy,
}: PullTimelogsRequestProps) => {
  return axios.post(`/pay/companies/${companyId}/timesheets/${payrunId}/time_logs/pull`, {
    approved,
    strategy,
  });
};
