import { makeStyles, Theme } from '@material-ui/core';

import { monochrome } from '../../../theme/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  weekDay: {
    fontSize: '14px',
    fontWeight: 500,
    color: theme.palette.type === 'dark' ? monochrome.light : monochrome.dark,
    lineHeight: '21px',
  },
  temperature: {
    fontSize: '26px',
    fontWeight: 600,
    lineHeight: '39px',
  },
  skeleton: {
    borderRadius: 8,
    backgroundColor: theme.palette.background.default,
  },
  locationSelectWrapper: {
    position: 'absolute',
    top: '-56px',
    right: '40px',
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      top: 0,
      right: 0,
      width: '100%',
    },
  },
  daisContainer: {
    overflowX: 'auto',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));
