import React, { useContext, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, Divider } from '@material-ui/core';

import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';
import { DeviceContext } from '@vyce/core/src/contexts';

import { IDEAS } from '../constants';
import { IdeaAccordion } from './IdeaAccordion';

interface Props {
  open: boolean;
  handleClose: Function;
  onIdeaClick: (request: string) => void;
}

export const IdeasDialog: React.FC<Props> = ({ open, handleClose }) => {
  const { isMobile } = useContext(DeviceContext);
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullScreen={isMobile}
      container={document.getElementById(MAIN_CONTAINER_ID)}
      onClose={() => handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle>Ideas for prompts</DialogTitle>

      <DialogContent>
        <Box display="flex" flexWrap="wrap" gridGap={4}>
          {IDEAS.map((item, i) => (
            <Box width="100%" key={item.title}>
              <IdeaAccordion
                index={i}
                expandedIndex={expandedIndex}
                setExpandedIndex={setExpandedIndex}
                closeDialog={handleClose}
                idea={item}
              />

              {i < IDEAS.length - 1 && (
                <Box>
                  <Divider />
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => handleClose()} variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
