// @ts-nocheck
import axiosOriginal from 'axios';
import type { AxiosRequestConfig, AxiosError } from 'axios';

import qs from 'qs';
import config from './config';
import { getFromLS, saveToLS } from '@vyce/core/src/utils/local-storage';

/** --- AXIOS INSTANCE --- */

const formatParams = (params: Record<string, unknown>): string =>
  qs.stringify(params, { indices: false, arrayFormat: 'repeat' });

export const axios = axiosOriginal.create({
  baseURL: config.apiUrl,
  paramsSerializer: formatParams,
});

/** ------- */

/** --- REQUEST INTERCEPTOR --- */

interface RequestConfig extends AxiosRequestConfig {
  headers?: Record<string, string>;
}

/** ------- */

axios.interceptors.request.use(
  async (requestConfig: RequestConfig): Promise<RequestConfig> => {
    const token = getFromLS('token');
    if (token) {
      return {
        ...requestConfig,
        headers: {
          ...requestConfig.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }

    return requestConfig;
  },
  error => Promise.reject(error)
);

/** ------- */

/** --- RESPONSE INTERCEPTOR --- */

axios.interceptors.response.use(
  response => response,
  (error: AxiosError) => {
    if (error?.response?.status === 401) {
      saveToLS('token', '');
    }

    return Promise.reject(error);
  }
);
