import React, { useContext, useState } from 'react';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { AxiosResponse } from 'axios';

import { DeviceContext } from '../../contexts';
import { FormProvider, useForm } from 'react-hook-form';
import { User } from '../../types';
import { ChangePasswordData } from '../../api/types';
import {
  DEFAULT_MESSAGE_OBJ,
  PasswordMessage,
  PasswordsFieldsWithProgress,
} from './PasswordsFieldsWithProgress';
import { useRequest } from '../../hooks/useRequest';
import { ButtonTitleWithLoading } from '../ButtonTitleWithLoading';
import { NewUserPasswordDialog } from './NewUserPasswordDialog';
import { useBooleanState } from '../../hooks';
import { MAIN_CONTAINER_ID } from '../../constants';

interface Props {
  open: boolean;
  setOpen: Function;
  token: string;
  isLegend?: boolean;
  user?: User;
  changePasswordRequest: (
    token: string,
    data: ChangePasswordData,
    userId?: string
  ) => Promise<AxiosResponse>;
}

export const SetNewPasswordDialog: React.FC<Props> = ({
  open,
  setOpen,
  token,
  changePasswordRequest,
  user,
  isLegend,
}) => {
  const { isMobile } = useContext(DeviceContext);
  const methods = useForm<ChangePasswordData>({
    defaultValues: {
      password_1: '',
      password_2: '',
    },
  });
  const { watch, reset } = methods;
  const [messageObj, setMessageObj] = useState<PasswordMessage>(DEFAULT_MESSAGE_OBJ);
  const [passwordToCopy, setPasswordToCopy] = useState<string>('');
  const password_1 = watch('password_1');
  const password_2 = watch('password_2');
  const [isNewUsersPasswordDialogOpen, openNewUsersPasswordDialog, closeNewUsersPasswordDialog] =
    useBooleanState(false);

  const handleClose = () => {
    setOpen(false);
    setMessageObj(DEFAULT_MESSAGE_OBJ);
    if (isLegend) {
      openNewUsersPasswordDialog();
      setPasswordToCopy(password_1);
    }
    reset();
  };

  const [loading, changePassword] = useRequest({
    request: changePasswordRequest,
    successCallback: handleClose,
    showSuccessNotification: true,
    successMessage: 'Password has been changed',
  });

  const onSubmit = async (data: ChangePasswordData) => {
    changePassword(token, data, user?.uuid);
  };

  const handleNewUsersPasswordDialogClose = () => {
    closeNewUsersPasswordDialog();
    setPasswordToCopy('');
  };

  return (
    <>
      <Dialog
        container={document.getElementById(MAIN_CONTAINER_ID)}
        fullScreen={isMobile}
        open={open}
        onClose={handleClose}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <DialogTitle>Set a new password</DialogTitle>

            <DialogContent>
              <Box width={isMobile ? 'auto' : '500px'}>
                <PasswordsFieldsWithProgress
                  password_2={password_2}
                  password_1={password_1}
                  setMessageObj={setMessageObj}
                  messageObj={messageObj}
                />
              </Box>
            </DialogContent>

            <DialogActions>
              <Button size="large" style={{ width: 94 }} variant="outlined" onClick={() => handleClose()}>
                Cancel
              </Button>
              <Button
                size="large"
                cy-test-id="confirm-button"
                style={{ width: 94 }}
                disabled={Math.round(messageObj.progress) !== 100}
                color="primary"
                type="submit"
                variant="contained">
                <ButtonTitleWithLoading title="Save" loaderVariant="paper" loading={loading} />
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>

      {user && (
        <NewUserPasswordDialog
          open={isNewUsersPasswordDialogOpen}
          handleClose={handleNewUsersPasswordDialogClose}
          name={user.first_name}
          email={user.email}
          phone={user.phone}
          password={passwordToCopy}
        />
      )}
    </>
  );
};
