import React from 'react';

import { OnboardingStepTitle } from '@vyce/core/src/components';
import { PayTagsField } from '@vyce/core/src/components/controlled-inputs';
import { PayTagGroup } from '@vyce/core/src/types';

interface Props {
  payScheduleIds: string[];
  companyId: string;
}

export const StarterLocationStep = ({ payScheduleIds, companyId }: Props) => {
  return (
    <>
      <OnboardingStepTitle text="What work location are you starting at?" />

      <PayTagsField
        rules={{ required: 'Location is required' }}
        group={PayTagGroup.LOCATION}
        companyId={companyId}
        payScheduleIds={payScheduleIds}
        margin="normal"
        multiline={false}
        label="Location"
        name="pay_starter_location"
      />
    </>
  );
};
