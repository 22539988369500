import React, { memo, MouseEventHandler, ReactElement } from 'react';

import { Paper } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from '../styles';

interface Props {
  isClickable?: boolean;
  selected?: boolean;
  children: ReactElement;
  handleClick: MouseEventHandler<HTMLElement>;
}

const TileContainerComponent: React.FC<Props> = ({
  children,
  isClickable = true,
  handleClick,
  selected,
}) => {
  const classes = useStyles();

  return (
    <Paper
      onClick={handleClick}
      variant="outlined"
      className={clsx(
        classes.tilePaper,
        { [classes.clickableItem]: isClickable },
        { [classes.selected]: selected }
      )}>
      {children}
    </Paper>
  );
};

export const TileContainer = memo(TileContainerComponent);
