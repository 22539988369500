import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { getBillingContactRequest, updateBillingContactRequest } from '@vyce/core/src/api/billing';
import { useBooleanState } from '@vyce/core/src/hooks';
import { BillingContact, Company, User } from '@vyce/core/src/types';
import { billingContactSuccessMessage } from '@vyce/core/src/modules/subscriptionModule/constants';

import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  selectedCompany?: Company;
  token: string;
  user?: User;
}

export const useBillingContact = ({ selectedCompany, token, user }: Props) => {
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const [isUpdating, setUpdatingTrue, setUpdatingFalse] = useBooleanState(false);
  const [billingContact, setBillingContact] = useState<BillingContact | null>(null);
  const { uuid: selectedCompanyId } = selectedCompany ?? {};

  const firstName = billingContact?.first_name || user?.first_name || '';
  const lastName = billingContact?.last_name || user?.last_name || '';
  const email = billingContact?.email || user?.email || '';
  const phone = billingContact?.phone || user?.phone || '';

  const billingContactOptions = useMemo(
    () => [
      { title: 'First name', value: firstName },
      { title: 'Last name', value: lastName },
      { title: 'Email Address', value: email },
      { title: 'Phone Number', value: phone },
    ],
    [email, firstName, lastName, phone]
  );

  const billingContactDefaultValues = useMemo(
    () => ({ first_name: firstName, last_name: lastName, email, phone }),
    [email, firstName, lastName, phone]
  );

  const getBillingContact = useCallback(async () => {
    if (!selectedCompanyId) {
      return;
    }
    try {
      const { data } = await getBillingContactRequest({ token, companyId: selectedCompanyId });
      setBillingContact(data);
    } catch {
      setBillingContact(null);
    }
  }, [selectedCompanyId, token]);

  const updateBillingContact = useCallback(
    async (newBillingContact: BillingContact) => {
      if (!selectedCompanyId) {
        return;
      }
      try {
        setUpdatingTrue();
        const { data } = await updateBillingContactRequest({
          token,
          companyId: selectedCompanyId,
          requestData: newBillingContact,
        });
        setBillingContact(data);
        setUpdatingFalse();
        showNotification({ message: billingContactSuccessMessage, options: { variant: 'success' } });
      } catch (e) {
        setUpdatingFalse();
        handleServerError(e);
      }
    },
    [selectedCompanyId, token]
  );

  useEffect(() => {
    if (!selectedCompanyId) {
      return;
    }
    getBillingContact();
  }, [selectedCompanyId]);

  return { isUpdating, billingContactDefaultValues, billingContactOptions, updateBillingContact };
};
