import React from 'react';

import { LocationsAndShifts } from '@vyce/core/src/views/time/LocationsAndShifts';

import { useActions, useTypedSelector } from '../../hooks';

export const EmployerLocationsAndShifts: React.FC = () => {
  const { selectedCompany } = useTypedSelector(state => state.helper);
  const { fetchCompanyData } = useActions();

  return <LocationsAndShifts selectedCompany={selectedCompany} fetchCompanyData={fetchCompanyData} />;
};
