import React, { useContext } from 'react';

import { Route, Switch, useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';

import { CommonNavBarBlock } from '@vyce/core/src/components';
import { DeviceContext } from '@vyce/core/src/contexts';
import { TabItem } from '@vyce/core/src/types';
import { getPaddingForContent } from '@vyce/core/src/utils';
import { PreviewButton } from '@vyce/core/src/views/preview/components/PreviewButton';

import { RouteEmployerUserProfileComponent } from './RouteEmployerUserProfileComponent';

export const PROFILE_TABS: TabItem[] = [
  {
    label: 'Payments',
    link: 'payments',
  },
  {
    label: 'Timesheets',
    link: 'timesheets',
  },
  {
    label: 'Agreements',
    link: 'agreements',
  },
];

export const EmployerUserProfile: React.FC = () => {
  const { isMobile } = useContext(DeviceContext);
  const history = useHistory();
  const padding = getPaddingForContent(isMobile);

  const goToPreview = () => {
    const urlItemsArr = history.location.pathname.split('/');
    urlItemsArr.pop();
    history.push(urlItemsArr.join('/'));
  };

  return (
    <Box position="relative">
      <CommonNavBarBlock tabItems={PROFILE_TABS} />

      <PreviewButton goToPreview={goToPreview} />

      <Box padding={padding}>
        <Switch>
          <Route component={RouteEmployerUserProfileComponent} />
        </Switch>
      </Box>
    </Box>
  );
};
