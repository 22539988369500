import React, { useContext } from 'react';

import { Box, Button, Typography, useTheme } from '@material-ui/core';
import { CgFile } from 'react-icons/cg';

import { DeviceContext } from '../../../../contexts';
import useStyles from '../../styles';

interface Props {
  companyName: string;
  openTestimonialDialog: Function;
  testimonialCount: number;
}

export const WriteTestimonial: React.FC<Props> = ({
  companyName,
  openTestimonialDialog,
  testimonialCount,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const { isMobile } = useContext(DeviceContext);

  return (
    <Box
      maxWidth={592}
      marginTop={4}
      marginBottom={3}
      borderRadius={12}
      display="flex"
      flexDirection={isMobile ? 'column' : 'row'}
      gridGap={24}
      bgcolor={theme.palette.background.default}
      padding="18px 24px">
      <Typography variant="caption" className={classes.bold} color="textSecondary">
        {companyName} has gathered{' '}
        <span style={{ color: theme.palette.primary.main }}>{testimonialCount}</span> testimonials. Did you
        have a chance to work with them?
      </Typography>

      <Button
        onClick={() => openTestimonialDialog()}
        variant="contained"
        color="primary"
        style={{ minWidth: 191 }}
        endIcon={<CgFile />}>
        Write a testimonial
      </Button>
    </Box>
  );
};
