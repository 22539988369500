import { useCallback, useContext, useState } from 'react';

import { useHistory } from 'react-router-dom';
import noop from 'lodash/noop';

import { createTeamsRequest, updateLogoRequest } from '@vyce/core/src/api/teams';
import { createSubscriptionRequest } from '@vyce/core/src/api/billing';
import { CreateTeamData } from '@vyce/core/src/api/types';
import { CardForm } from '@vyce/core/src/types';
import { TeamsPageContext } from '@vyce/core/src/contexts';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';
import { VERIFY_EMAIL_ERROR_OBJECT } from '@vyce/core/src/constants';

import { useActions, useTypedSelector } from '../../../hooks';

const defaultPath = '/teams/list';

export const useCreateTeam = () => {
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const [isOpenTeamDialog, setOpenTeamDialog] = useState<boolean>(false);
  const [needToReset, setNeedToReset] = useState<boolean>(false);
  const [teamData, setTeamData] = useState<CreateTeamData | null>(null);
  const [isOpenSubscriptionDialog, setSubscriptionDialogOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { access_token, selectedCompany, selectedCompanyAppData } = useTypedSelector(state => state.helper);
  const { email_verified, phone_verified, first_name } = useTypedSelector(state => state.user);

  const { updateSelectedCompanySubscription, addTeam } = useActions();
  const history = useHistory();
  const { getTeams, setOpenSuccessDialog = noop } = useContext(TeamsPageContext);
  const companyId = selectedCompany?.uuid;
  const isSubscribed = selectedCompanyAppData?.subscription?.active ?? false;

  const createTeam = useCallback(
    async ({
      formData,
      needToRedirect = false,
    }: {
      formData: CreateTeamData | null;
      needToRedirect?: boolean;
    }) => {
      if (!(companyId && formData)) {
        return;
      }
      setIsLoading(true);
      try {
        const { logo, ...restData } = formData;
        const { data } = await createTeamsRequest(access_token, companyId, restData);
        if (logo) {
          await updateLogoRequest(companyId, data?.uuid, logo);
        }
        addTeam(data);

        getTeams(0);
        setOpenTeamDialog(false);
        setIsLoading(false);
        setSubscriptionDialogOpen(false);
        setTeamData(null);
        setNeedToReset(true);
        needToRedirect && history.push(defaultPath);
      } catch (e) {
        console.error(e);
        handleServerError(e);
        setIsLoading(false);
      }
    },
    [companyId, access_token]
  );

  const subscribe = useCallback(
    async (data: CardForm) => {
      if (!companyId) {
        return;
      }
      setIsLoading(true);
      try {
        const res = await createSubscriptionRequest(access_token, companyId, data);
        const subscription = res.data;
        await createTeam({ formData: teamData });
        updateSelectedCompanySubscription(subscription);
        setOpenSuccessDialog(true);
        history.push(defaultPath);
      } catch (e) {
        console.error(e);
        handleServerError(e);
        setIsLoading(false);
      }
    },
    [access_token, companyId, createTeam, teamData]
  );

  const finishCreateTeam = (data: CreateTeamData) => {
    setNeedToReset(false);
    if (!isSubscribed) {
      setTeamData(data);
      setOpenTeamDialog(false);
      setSubscriptionDialogOpen(true);
    } else {
      createTeam({ formData: data, needToRedirect: true });
    }
  };

  const openTeamDialog = useCallback(() => {
    if (!email_verified && !phone_verified) {
      return showNotification(VERIFY_EMAIL_ERROR_OBJECT);
    }
    setOpenTeamDialog(true);
  }, [email_verified, phone_verified]);

  return {
    isLoading,
    isOpenSubscriptionDialog,
    isOpenTeamDialog,
    needToReset,
    first_name,
    selectedCompany,
    subscribe,
    createTeam,
    finishCreateTeam,
    openTeamDialog,
    setSubscriptionDialogOpen,
    setOpenTeamDialog,
  };
};
