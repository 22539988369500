//@ts-nocheck
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { LocationField } from './controlled-inputs/LocationField';
import { AppTextField } from './inputs/AppTextField';

export const LocationForm: React.FC = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <LocationField margin="normal" name="address.address_snippet" />

      <Controller
        control={control}
        name="address.address_line_1"
        rules={{ required: 'House Name/Number is required' }}
        render={({ field }) => (
          <AppTextField
            {...field}
            value={field.value || ''}
            id="house_number"
            label="House Name/Number"
            fullWidth
            error={!!errors?.address?.address_line_1?.message}
            helperText={errors?.address?.address_line_1?.message || ''}
            margin="normal"
          />
        )}
      />

      <Controller
        control={control}
        rules={{ required: 'Street is required' }}
        name="address.address_line_2"
        render={({ field }) => (
          <AppTextField
            {...field}
            value={field.value || ''}
            id="street"
            label="Street"
            fullWidth
            error={!!errors?.address?.address_line_2?.message}
            helperText={errors?.address?.address_line_2?.message || ''}
            margin="normal"
          />
        )}
      />

      <Controller
        control={control}
        rules={{ required: 'Town is required' }}
        name="address.address_line_3"
        render={({ field }) => (
          <AppTextField
            {...field}
            value={field.value || ''}
            id="town"
            label="Town"
            fullWidth
            error={!!errors?.address?.address_line_3?.message}
            helperText={errors?.address?.address_line_3?.message || ''}
            margin="normal"
          />
        )}
      />
    </>
  );
};
