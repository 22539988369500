import React, { useContext, useEffect, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { AppTextField } from '@vyce/core/src/components/inputs/AppTextField';

import { ButtonTitleWithLoading } from '../../../components/ButtonTitleWithLoading';
import { MAIN_CONTAINER_ID } from '../../../constants';

const maxLength = 1000;
const errorMessage = 'You have reached characters limit';

interface Props {
  open: boolean;
  loading: boolean;
  setOpen: Function;
  connect: (message: string) => void;
}

export const ConnectCandidateDialog: React.FC<Props> = ({ open, setOpen, connect, loading }) => {
  const { isMobile } = useContext(DeviceContext);
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (message.length <= maxLength) {
      isError && setIsError(false);
      return;
    }
    !isError && setIsError(true);
  }, [message, isError]);

  const handleClose = () => setOpen(false);
  const handleConnect = () => connect(message);

  return (
    <Dialog
      fullScreen={isMobile}
      container={document.getElementById(MAIN_CONTAINER_ID)}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">
        You can add an optional message to your connection if you’d like...
      </DialogTitle>
      <DialogContent>
        <AppTextField
          id="message"
          multiline
          value={message}
          onChange={e => setMessage(e.target.value)}
          fullWidth
          label="Message"
          placeholder="Write your message here..."
          error={isError}
          helperText={(isError && errorMessage) || ''}
          inputProps={{ maxLength: maxLength + 1 }}
        />
      </DialogContent>
      <DialogActions>
        <Button size="large" variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          size="large"
          style={{ width: 100 }}
          onClick={handleConnect}
          color="primary"
          variant="contained">
          <ButtonTitleWithLoading title="Connect" loaderVariant="paper" loading={loading} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
