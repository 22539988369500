// @ts-nocheck
import React, { MutableRefObject, useEffect, useState } from "react";

export function useFullscreenStatus(elRef: MutableRefObject<any>) {
  const [isFullscreen, setIsFullscreen] = useState<boolean>(
    document[getBrowserFullscreenElementProp()] !== null
  );
  const [isFullScreenSupported, setIsFullscreenSupported] = useState(true);
  const [browserFullscreenElementProp, setBrowserFullscreenElementProp] = useState<string>('');

  const setFullscreen = async () => {
    if (elRef.current == null) return;
    try {
      if (elRef.current.requestFullscreen) await elRef.current.requestFullscreen();
      else if (elRef.current.webkitRequestFullscreen) await elRef.current.webkitRequestFullscreen();
      else if (elRef.current.mozRequestFullScreen) await elRef.current.mozRequestFullScreen();
      else if (elRef.current.msRequestFullscreen) await elRef.current.msRequestFullscreen();
      else if (elRef.current.webkitEnterFullscreen) await elRef.current.webkitEnterFullscreen();
      setIsFullscreen(true);
    } catch (e) {
      setIsFullscreen(false);
    }
  };

  useEffect(() => {
    const prop = getBrowserFullscreenElementProp();
    if (prop) {
      setBrowserFullscreenElementProp(prop);
    } else {
      setIsFullscreenSupported(false);
      setIsFullscreen(false);
    }
  }, []);


  const exitFullscreen = async () => {
    setIsFullscreen(false);
    if (document.exitFullscreen) await document.exitFullscreen();
    else if (document.webkitExitFullscreen) await document.webkitExitFullscreen();
  };

  React.useLayoutEffect(() => {
    if (!isFullScreenSupported) {
      return;
    }
    if (browserFullscreenElementProp === 'webkitFullscreenElement') {
      document.onwebkitfullscreenchange = () =>
        setIsFullscreen(document[browserFullscreenElementProp] !== null);
    } else {
      document.onfullscreenchange = () =>
        setIsFullscreen(document[browserFullscreenElementProp] !== null);
    }
  });

  function getBrowserFullscreenElementProp() {
    if (typeof document.fullscreenElement !== "undefined") {
      return "fullscreenElement";
    } else if (typeof document.mozFullScreenElement !== "undefined") {
      return "mozFullScreenElement";
    } else if (typeof document.msFullscreenElement !== "undefined") {
      return "msFullscreenElement";
    } else if (typeof document.webkitFullscreenElement !== "undefined") {
      return "webkitFullscreenElement";
    } else {
      return undefined;
    }
  }

  return { isFullscreen, setFullscreen, exitFullscreen, isFullScreenSupported };
}
