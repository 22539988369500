import React, { useMemo } from 'react';

import { Route, Switch, Redirect } from 'react-router-dom';
import { Box } from '@material-ui/core';

import { EmployeeTimeLogsProvider } from '@vyce/core/src/contexts';
import { CommonNavBarBlock } from '@vyce/core/src/components';

import { EmployeeTimeLogsPage } from 'src/views/payroll/employee';
import { TimeEmployeeDailyActivity } from 'src/modules';
import { useTypedSelector } from 'src/hooks';

import { EMPLOYEE_TIME_AND_ATTENDANCE_TABS } from './config';

export const EmployeeTimeLogs = () => {
  const { first_name } = useTypedSelector(state => state.user);

  const contextOptions = useMemo(() => ({ first_name }), [first_name]);

  return (
    <EmployeeTimeLogsProvider value={contextOptions}>
      <Switch>
        <Box>
          <CommonNavBarBlock tabItems={EMPLOYEE_TIME_AND_ATTENDANCE_TABS} />
          <Box height="100%">
            <Route exact path={'/timelogs/logs'} component={EmployeeTimeLogsPage} />
            <Route
              exact
              path={'/timelogs/logs/:companyId/:shiftId/:userId/:date'}
              component={TimeEmployeeDailyActivity}
            />
            <Route path="*" render={() => <Redirect to={{ pathname: '/timelogs/logs' }} />} />
          </Box>
        </Box>
      </Switch>
    </EmployeeTimeLogsProvider>
  );
};
