import React from 'react';

import clsx from 'clsx';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { PopoverContentProps } from '@reactour/tour';

import { monochrome } from '../../theme/styles';

interface DotProps
  extends Pick<PopoverContentProps, 'setCurrentStep' | 'disabledActions' | 'disableDotsNavigation'> {
  currentStep: number;
  index: number;
}

const useStyles = makeStyles<any>((theme: Theme) =>
  createStyles({
    dot: {
      counterIncrement: 'dot',
      width: theme.spacing(1),
      height: theme.spacing(1),
      minWidth: theme.spacing(1),
      borderRadius: '100%',
      padding: 0,
      display: 'block',
      margin: 4,
      transition: 'opacity 0.3s, transform 0.3s',
      cursor: 'pointer',
      color: monochrome.medium,
      backgroundColor: monochrome.medium,

      '&:hover': {
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.dark,
      },
    },
    dotCurrent: {
      color: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark,
      transform: 'scale(1.25)',

      '&:hover': {
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.dark,
      },
    },
    dotDisabled: {
      cursor: 'not-allowed',
    },
  })
);

export const Dot = ({
  disabledActions,
  disableDotsNavigation,
  index,
  currentStep,
  setCurrentStep,
}: DotProps) => {
  const classes = useStyles();

  const handleClick = () => {
    if (!disableDotsNavigation && !disabledActions) setCurrentStep(index);
  };

  return (
    <Button
      onClick={handleClick}
      className={clsx(classes.dot, {
        [classes.dotCurrent]: currentStep === index,
        [classes.dotDisabled]: disableDotsNavigation || disabledActions,
      })}
    />
  );
};
