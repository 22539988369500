import React from 'react';
import { Box, FormControlLabel, Typography } from '@material-ui/core';

import { AppCheckbox } from '@vyce/core/src/components/inputs';
import { HowToEngageEmployee } from '@vyce/core/src/components';

import { MODULE_NAMES } from '../config';
import useStyles from '../styles';
import { PayModuleOptions, CommonOptions } from '../types';
import { ModuleAccordion } from './ModuleAccordion';

const styles = { marginRight: '24px', marginTop: '4px' };

export const PayrollBlock = ({
  isLegend,
  expandedModule,
  selectedPayScheduleIds,
  payrollModuleActive,
  paySchedules,
  selectedSubcontractorType,
  handlePayScheduleSelect,
  clearPaySelections,
  handleTypeChange,
  collapseAllModules,
  setExpandedModule,
}: PayModuleOptions & CommonOptions) => {
  const classes = useStyles();

  return (
    <Box marginLeft="-6px" marginTop={1} marginBottom={1}>
      <ModuleAccordion
        active={payrollModuleActive}
        moduleName={MODULE_NAMES.PAYROLL}
        moduleLink="/payroll"
        moduleItemName="Pay Schedules"
        clearModuleSelections={clearPaySelections}
        isModuleItemsSelected={!!selectedPayScheduleIds.length}
        isLegend={isLegend}
        moduleExpanded={expandedModule === MODULE_NAMES.PAYROLL}
        collapseAllModules={collapseAllModules}
        expandedModule={expandedModule}
        setExpandedModule={setExpandedModule}>
        {payrollModuleActive ? (
          <Box marginLeft="36px">
            <Typography className={classes.detailsTitle}>Which Pay Schedule?</Typography>
            <Box marginLeft="-6px" display="flex" flexWrap="wrap" marginBottom={2}>
              {paySchedules?.map(paySchedule => (
                <FormControlLabel
                  key={paySchedule.uuid}
                  classes={{ label: classes.detailsTitle }}
                  style={styles}
                  control={
                    <AppCheckbox
                      checked={selectedPayScheduleIds.includes(paySchedule.uuid as string)}
                      onChange={e => handlePayScheduleSelect(paySchedule.uuid as string, e.target.checked)}
                      variant="rectangle"
                      color="primary"
                    />
                  }
                  label={paySchedule.name}
                />
              ))}
            </Box>

            <HowToEngageEmployee
              handleTypeChange={handleTypeChange}
              selectedSubcontractorType={selectedSubcontractorType}
            />
          </Box>
        ) : (
          <></>
        )}
      </ModuleAccordion>
    </Box>
  );
};
