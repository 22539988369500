import React from 'react';

import { StaffManagementTable } from '@vyce/core/src/views/staff-management/components/StaffManagementTable';
import {
  editPermissionRequest,
  getRolesRequest,
  getStaffRequest,
  sendInviteRequest,
} from '@vyce/core/src/api/management';

import { useTypedSelector } from '../../../hooks';

export const EmployerStaffManagement: React.FC = () => {
  const { selectedCompany } = useTypedSelector(state => state.helper);

  return (
    <StaffManagementTable
      selectedCompanyId={selectedCompany?.uuid || null}
      getStaffRequest={getStaffRequest}
      editPermissionRequest={editPermissionRequest}
      getRolesRequest={getRolesRequest}
      sendInviteRequest={sendInviteRequest}
      unit="admins"
      withProfileButton
    />
  );
};
