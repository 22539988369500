import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useTypedSelector } from '../hooks';

export const UnAuthorizedRoute = ({ component, redirectPath = '/dashboard', ...rest }: any) => {
  const { user } = useTypedSelector(state => state);

  const routeComponent = (props: any) =>
    !user?.uuid ? React.createElement(component, props) : <Redirect to={{ pathname: redirectPath }} />;
  return <Route {...rest} render={routeComponent} />;
};
