import React from 'react';

import { OnboardingStepTitle } from '@vyce/core/src/components';
import { GenderRadio } from '@vyce/core/src/components/controlled-inputs';

import { TitleProps } from './types';

export const GenderStep = ({ title = "What's your gender?" }: TitleProps) => {
  return (
    <>
      <OnboardingStepTitle text={title} />
      <GenderRadio />
    </>
  );
};
