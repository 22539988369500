import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

//TODO Have to do a absolute version or version with 100% height

export const PageLoading = ({ size = 40 }: { size?: number }) => {
  return (
    <Box display="flex" height="100%" alignItems="center" justifyContent="center">
      <CircularProgress size={size} />
    </Box>
  );
};
