import { makeStyles, Theme } from '@material-ui/core';

import { monochrome, backgrounds } from '../../theme/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  accordionCellWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  accordionItemWrapper: {
    backgroundColor: theme.palette.type === 'dark' ? backgrounds.darkModePaper : backgrounds.paper,
    width: '100%',
    borderRadius: 8,
    position: 'relative',
    overflow: 'hidden',
    padding: 16,
    paddingBottom: 8,
    minHeight: 50,
    flex: '1 0 auto',
  },

  accordionItemWrapperBorder: {
    border: `1px solid ${theme.palette.type === 'dark' ? monochrome.light : monochrome.mediumlight}`,
  },
  accordionItemWrapperClosed: {
    maxHeight: 50,
    transition: 'max-height .5s ease-out',
  },
  accordionItemWrapperOpened: {
    maxHeight: 500,
    transition: 'max-height .5s ease-in',
  },

  accordionItemContent: {
    '& > div:not(:last-child)': {
      padding: '8px 0',
    },
    '& > div:last-child': {
      paddingTop: 8,
    },

    '& > div': {
      borderTop: `1px solid ${monochrome.mediumlight} `,
    },
  },

  accordionHeaderItemContent: {
    height: 34,
    paddingBottom: 16,
    display: 'flex',
    alignItems: 'center',
  },

  accordionItemRowWrapper: {
    display: 'flex',
    width: '100%',
  },

  accordionItemButton: {
    position: 'absolute',
    top: 8,
    right: 16,
    cursor: 'pointer',
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  accordionItemButtonOpened: {
    transform: 'rotate(180deg)',
    background: theme.palette.type === 'dark' ? '#14142B' : '#EFF0F6',
    borderRadius: 12,
  },

  text: {
    fontSize: '11px',
    lineHeight: '17px',
    color: theme.palette.text.secondary,
  },

  bold: {
    fontWeight: 600,
  },
}));
