import { useContext, useState } from 'react';

import { Company, Testimonial } from '../../../types';
import {
  createTestimonialRequest,
  deleteTestimonialRequest,
  switchTestimonialRequest,
} from '../../../api/companies';
import { useBooleanState } from '../../../hooks';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  company?: Company;
  getCompanyData: Function;
  token: string;
  closeTestimonialDialog: Function;
  closeConfirmDialog: Function;
}

export const useTestimonialData = ({
  company,
  getCompanyData,
  token,
  closeTestimonialDialog,
  closeConfirmDialog,
}: Props) => {
  const { handleServerError } = useContext(NotificationContext);
  const [selectedTestimonial, setSelectedTestimonial] = useState<Testimonial>();
  const [loading, setLoadingTrue, setLoadingFalse] = useBooleanState(false);

  const createTestimonial = async (data: Testimonial, file?: File | null) => {
    if (!company?.uuid) {
      return;
    }

    try {
      setLoadingTrue();
      await createTestimonialRequest({ token, companyId: company.uuid, testimonial: data, file });
      getCompanyData();
      closeTestimonialDialog();
      setLoadingFalse();
    } catch (e) {
      setLoadingFalse();
      handleServerError(e);
    }
  };

  const deleteTestimonial = async () => {
    if (!company?.uuid || !selectedTestimonial?.uuid) {
      return;
    }

    try {
      setLoadingTrue();
      await deleteTestimonialRequest(token, company.uuid, selectedTestimonial.uuid);
      getCompanyData();
      closeTestimonialDialog();
      setLoadingFalse();
      closeConfirmDialog();
    } catch (e) {
      setLoadingFalse();
      handleServerError(e);
    }
  };

  const togglePublic = async (data: Testimonial) => {
    if (!company?.uuid || !data?.uuid) {
      return;
    }

    try {
      setLoadingTrue();
      await switchTestimonialRequest(token, company.uuid, data.uuid);
      getCompanyData();
      setLoadingFalse();
    } catch (e) {
      setLoadingFalse();
      handleServerError(e);
    }
  };

  return {
    setSelectedTestimonial,
    togglePublic,
    deleteTestimonial,
    createTestimonial,
    loading,
  };
};
