import { createContext } from 'react';

import { Dispatch, Action } from 'redux';

import { UserDashboards, WidgetItem, Team } from '@vyce/core/src/types';

interface Context {
  dashboards?: UserDashboards;
  first_name: string;
  teams?: Team[];
  companyDataLoading: boolean;
  selectedCompanyId: string;
  widgetComponent: (props: { widget: WidgetItem; redirectUrl?: string }) => JSX.Element;
  inviteButtonComponent?: () => JSX.Element;
  updateUserDashboards: (dashboards: UserDashboards) => (dispatch: Dispatch<Action>) => Promise<void>;
  onOpenUniversalInviteForm?: () => void;
}

export const TeamsDashboardModuleContext = createContext({} as Context);

export const TeamsDashboardModuleProvider = TeamsDashboardModuleContext.Provider;
