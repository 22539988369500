import React, { useEffect, useMemo, useState } from 'react';

import { Box, FormControlLabel } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { TbPencil } from 'react-icons/tb';
import capitalize from 'lodash/capitalize';

import { getWeekHoursPopupData } from '../LocationPreview';
import { ScheduleDialog } from './ScheduleDialog';
import { AppCheckbox } from '../../../../components/inputs';
import {
  DEFAULT_OVERTIME_SCHEDULE,
  ScheduleType,
  SHIFTS_FIELD_NAME,
} from '../../../../modules/timeModule/constants';
import { useBooleanState } from '../../../../hooks';
import { DefaultRateField } from '../../../../components/controlled-inputs';
import { AppIconButton, DetailsIcon } from '../../../../components';
import { ColorOption } from '../../../../types';
import { isScheduleValid } from '../../LocationOnboarding';
import { getScheduleSetupStatus } from '../../../../modules/timeModule/utils';

interface Props {
  index: number;
  type: ScheduleType;
  handleSaveChangesClick: Function;
}

export const EditShiftSchedule: React.FC<Props> = ({ index, type, handleSaveChangesClick }) => {
  const { getValues, setValue, watch, resetField } = useFormContext();
  const [isDialogOpen, openDialog, closeDialog] = useBooleanState(false);
  const [isScheduleSetup, setIsScheduleSetup] = useState(false);
  const [isEditButtonHighlighted, setIsHighlighted] = useState(false);
  const shiftField = `${SHIFTS_FIELD_NAME}[${index}]`;
  const scheduleDetailsField = `${shiftField}.${type}`;
  const shift = getValues(shiftField);
  const isRegular = type === ScheduleType.REGULAR;
  const rateFieldName = isRegular ? 'basic_amount' : 'overtime_amount';
  const unitFieldName = isRegular ? 'basic_unit' : 'overtime_unit';
  const scheduleToCompare = watch(
    `${shiftField}.${isRegular ? ScheduleType.OVERTIME : ScheduleType.REGULAR}.schedule`
  );

  const editButtonVariant = useMemo((): ColorOption => {
    if (isEditButtonHighlighted) {
      return 'primary';
    }
    if (isScheduleSetup) {
      return 'secondary';
    }
    return 'default';
  }, [isEditButtonHighlighted, isScheduleSetup]);

  const checkScheduleStatus = () => {
    const schedule = getValues(`${scheduleDetailsField}.schedule`);

    if (schedule) {
      let status = getScheduleSetupStatus(schedule);
      setIsScheduleSetup(status);
      setIsHighlighted(false);
    }
  };

  const handleScheduleDialogClose = () => {
    const regularSchedule = getValues(`${shiftField}.regular.schedule`);
    const overtimeSchedule = getValues(`${shiftField}.overtime.schedule`);
    if (!isScheduleValid(regularSchedule, overtimeSchedule)) {
      resetField(scheduleDetailsField);
    }
    checkScheduleStatus();
    closeDialog();
  };

  const handleCheckboxChange = (status: boolean) => {
    if (isRegular) {
      return;
    }

    if (!status) {
      setValue(`${scheduleDetailsField}.schedule`, DEFAULT_OVERTIME_SCHEDULE);
      checkScheduleStatus();
      setIsHighlighted(false);
    } else {
      setIsHighlighted(true);
      setIsScheduleSetup(status);
    }
  };

  useEffect(() => {
    checkScheduleStatus();
  }, []);

  return (
    <Box display="flex" flexDirection="column" gridGap={16}>
      <Box display="flex" gridGap={12} alignItems="center">
        <Box minWidth={110}>
          <FormControlLabel
            control={
              <AppCheckbox
                onChange={e => handleCheckboxChange(e.target.checked)}
                color={isRegular ? 'default' : 'primary'}
                checked={isScheduleSetup}
              />
            }
            label={capitalize(type)}
          />
        </Box>

        <DefaultRateField name={`${shiftField}.${rateFieldName}`} label="Default hourly rate (£)" />

        <Box display="flex" gridGap={8}>
          <DetailsIcon
            size="31px"
            type="square"
            content={getWeekHoursPopupData(
              shift[type].schedule,
              shift[rateFieldName],
              false,
              shift[unitFieldName]
            )}
          />

          <AppIconButton isSmall onClick={() => openDialog()} variant={editButtonVariant}>
            <TbPencil size="16px" />
          </AppIconButton>
        </Box>
      </Box>

      <ScheduleDialog
        scheduleToCompare={scheduleToCompare}
        scheduleType={type}
        handleSaveChangesClick={handleSaveChangesClick}
        scheduleDetailsFieldName={scheduleDetailsField}
        open={isDialogOpen}
        handleClose={handleScheduleDialogClose}
      />
    </Box>
  );
};
