import React, { ChangeEvent, useEffect, useState } from 'react';

import { Box, Divider, MenuItem, Typography } from '@material-ui/core';
import dayjs from 'dayjs';

import { TimePeriodSettings } from '@vyce/core/src/types';
import { AppSelect } from '@vyce/core/src/components/inputs';
import { formatDate } from '@vyce/core/src/utils/dates';

import { timelogDays, TimeLogsPeriods } from '../config';
import { useStyles } from '../styles';
import { PeriodSettings } from './PeriodSettings';
import { PeriodAccordionItem } from './PeriodAccordionItem';

interface Props {
  settings: TimePeriodSettings;
  endDay: string;
  onPeriodChange: (settings: TimePeriodSettings) => Promise<void>;
}

export const TimeLogsPeriodsAccordion: React.FC<Props> = ({ endDay, onPeriodChange, settings }) => {
  const classes = useStyles();
  const [startDayOptions, setStartDayOptions] = useState<string[]>([]);

  const handleChange = (type: TimeLogsPeriods) => (event: ChangeEvent<{}>, expanded: boolean) => {
    const isMonthly = type === TimeLogsPeriods.MONTHLY;
    const isWeekly = type === TimeLogsPeriods.WEEKLY;
    const weekdayStart = settings.weekday_start || 'monday';
    let periodStartDate;
    if (type === TimeLogsPeriods.BI_WEEKLY) {
      const options = getBiWeeklyDays(weekdayStart);
      setStartDayOptions(options);
      periodStartDate = settings.period_start_date || options[0];
    }

    onPeriodChange({
      ...settings,
      period_type: type,
      weekday_start: !isMonthly ? weekdayStart : null,
      period_start_date: !isMonthly && !isWeekly ? periodStartDate : null,
    });
  };

  const onPeriodStartDayChange = (e: ChangeEvent<any>) => {
    onPeriodChange({
      ...settings,
      period_type: TimeLogsPeriods.BI_WEEKLY,
      period_start_date: e.target.value,
    });
  };

  const onWeekdayChange = (day: string) => {
    let periodStartDate;
    if (settings.period_type === TimeLogsPeriods.BI_WEEKLY) {
      const options = getBiWeeklyDays(day);
      setStartDayOptions(options);
      periodStartDate = options[0];
    }
    onPeriodChange({
      ...settings,
      weekday_start: day,
      period_start_date: periodStartDate,
    });
  };

  const getBiWeeklyDays = (weekday: string): string[] => {
    const targetDayIndex = timelogDays.indexOf(weekday.toLowerCase());
    if (targetDayIndex === -1) return [];

    const today = new Date();
    today.setHours(0);
    const todayDayIndex = today.getDay();
    let daysToAdd = targetDayIndex - todayDayIndex + 1;
    if (daysToAdd < 0) daysToAdd += 7;

    const nextWeekdayDate = new Date(today);
    nextWeekdayDate.setDate(today.getDate() + daysToAdd);

    const weekAfterDate = new Date(nextWeekdayDate);
    weekAfterDate.setDate(nextWeekdayDate.getDate() + 7);

    return [formatDate(nextWeekdayDate), formatDate(weekAfterDate)];
  };

  useEffect(() => {
    if (settings.weekday_start && settings.period_type === TimeLogsPeriods.BI_WEEKLY) {
      setStartDayOptions(getBiWeeklyDays(settings.weekday_start));
    }
  }, [settings]);

  return (
    <>
      <PeriodAccordionItem
        handleChange={handleChange}
        expanded={settings.period_type === TimeLogsPeriods.WEEKLY}
        period={TimeLogsPeriods.WEEKLY}>
        <PeriodSettings settings={settings} endDay={endDay} onWeekdayChange={onWeekdayChange} />
      </PeriodAccordionItem>

      <Box paddingLeft={2} paddingRight={2}>
        <Divider />
      </Box>

      <PeriodAccordionItem
        handleChange={handleChange}
        expanded={settings.period_type === TimeLogsPeriods.BI_WEEKLY}
        period={TimeLogsPeriods.BI_WEEKLY}>
        <Box>
          <PeriodSettings settings={settings} endDay={endDay} onWeekdayChange={onWeekdayChange} />

          <Box marginTop={1} marginBottom={1} display="flex" gridGap={8} alignItems="center">
            <Typography variant="caption" color="textSecondary">
              When do you want the next reporting period to start?
            </Typography>

            <AppSelect
              value={settings.period_start_date}
              onChange={onPeriodStartDayChange}
              className={classes.selectRoot}>
              {startDayOptions?.map(day => (
                <MenuItem key={day} value={day} selected={day === settings.weekday_start}>
                  <span>{dayjs(day).format('dddd DD.MM')}</span>
                </MenuItem>
              ))}
            </AppSelect>
          </Box>

          <Typography variant="caption" color="textSecondary">
            You’ll be able to get started using Vyce Time straight away but this just confirms your reporting
            period going forward.
          </Typography>
        </Box>
      </PeriodAccordionItem>

      <Box paddingLeft={2} paddingRight={2}>
        <Divider />
      </Box>

      <PeriodAccordionItem
        handleChange={handleChange}
        expanded={settings.period_type === TimeLogsPeriods.MONTHLY}
        period={TimeLogsPeriods.MONTHLY}>
        <Typography variant="caption" color="textSecondary">
          The report will include Time Logs from midnight on the 1st day of the month to 23.59 PM on the last
          one.
        </Typography>
      </PeriodAccordionItem>
    </>
  );
};
