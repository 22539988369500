import React from 'react';

import { Box } from '@material-ui/core';

import { OnboardingStepTitle, DetailsIcon } from '@vyce/core/src/components';
import { NiNumberField } from '@vyce/core/src/components/controlled-inputs';

import { TitleProps } from './types';

interface Props extends TitleProps {
  disabled?: boolean;
  detailsInfo?: string;
}

const rules = { required: 'NI Number is required' };

export const NINumberStep = ({ detailsInfo, disabled, title = 'Your National Insurance Number' }: Props) => (
  <>
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <OnboardingStepTitle text={title} />

      {detailsInfo && (
        <DetailsIcon
          content={<>We ask for your NI number so we can validate your industry cards and qualifications.</>}
        />
      )}
    </Box>

    <NiNumberField margin="normal" rules={rules} disabled={disabled} />
  </>
);
