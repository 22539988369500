import React, { useContext, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { HiOutlineArrowLeft } from 'react-icons/hi';

import {
  EmailField,
  FirstNameField,
  LastNameField,
  PasswordFields,
  PhoneNumberField,
} from '@vyce/core/src/components/controlled-inputs';
import { AuthFormValues, CreateUser } from '@vyce/core/src/types';
import { DeviceContext } from '@vyce/core/src/contexts';
import { userExistsRequest } from '@vyce/core/src/api/users';
import { sendEmailResetPasswordRequest } from '@vyce/core/src/api/auth';
import { getAuthPage } from '@vyce/core/src/utils';
import { ButtonTitleWithLoading, Logo, AppIconButton, ReCAPTCHAComponent } from '@vyce/core/src/components';
import { useRecaptcha } from '@vyce/core/src/hooks';

import useStyles from '../styles';
import { ForgotPasswordDialog } from './ForgotPasswordDialog';
import { MAIN_CONTAINER_ID } from '../../../constants';
import { NotificationContext } from '../../../contexts/notificationContext';

const STEPS = 2;

export const formatPhone = (phone?: string): string => {
  if (phone && phone.length < 4) {
    return '';
  }
  return phone ? phone.replace(/[^\d+]/g, '') : '';
};

const stepStyles = { marginTop: 24, fontWeight: 700 };

interface Props {
  setLoading: Function;
  userRegister: (data: CreateUser) => (data: any) => Promise<any>;
  loading?: boolean;
  title?: string;
  subtitle?: string;
  verifyPhoneUrl?: string;
  redirectUrl: string;
}

const StepOne = ({ title, subtitle }: { title: string; subtitle: string | undefined }) => {
  const classes = useStyles();
  const { isDesktop } = useContext(DeviceContext);

  return (
    <>
      <Typography variant="h5" style={stepStyles}>
        {title}
      </Typography>
      {subtitle && (
        <Typography className={classes.subtitle} color="textSecondary" variant="subtitle1">
          {subtitle}
        </Typography>
      )}

      <Grid container spacing={1} wrap="nowrap">
        <Grid item>
          <FirstNameField margin="normal" autoFocus={isDesktop} required />
        </Grid>
        <Grid item>
          <LastNameField margin="normal" required />
        </Grid>
      </Grid>

      <EmailField margin="normal" autoFocus={false} required />

      <PhoneNumberField rules={{}} label="Mobile Number" margin="normal" name="phone" />
    </>
  );
};

const StepTwo = ({ firstName }: { firstName: string }) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h5" style={stepStyles}>
        Welcome {firstName}!
      </Typography>
      <Typography className={classes.subtitle} color="textSecondary" variant="subtitle1">
        Please create a password
      </Typography>

      <PasswordFields margin="normal" />
    </>
  );
};

export const Register: React.FC<Props> = ({
  setLoading,
  userRegister,
  loading,
  title = 'Welcome To Vyce',
  subtitle,
  redirectUrl,
  verifyPhoneUrl,
}) => {
  const classes = useStyles();
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState<number>(1);
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
  const { reCaptchaRef, getReCaptchaToken } = useRecaptcha();
  const history = useHistory();
  const { isMobile } = useContext(DeviceContext);
  const [open, setOpen] = useState<boolean>(false);
  const [openForgotPassportDialog, setOpenForgotPassportDialog] = useState<boolean>(false);
  const methods = useForm<AuthFormValues>({
    defaultValues: {
      email: '',
      phone: '',
      first_name: '',
      last_name: '',
      password_1: '',
      password_2: '',
    },
  });
  const { watch } = methods;
  const email = watch('email');

  const checkUser = async ({ email, phone }: { email?: string; phone?: string }) => {
    setLoading(true);
    try {
      const res = await userExistsRequest(email, formatPhone(phone));
      setLoading(false);
      if (res.data.status) {
        return setOpen(true);
      }
      setActiveStep(activeStep + 1);
    } catch (e) {
      handleServerError(e);
      setLoading(false);
    }
  };

  const handleRegisterStep = async (formData: AuthFormValues) => {
    try {
      const recaptcha_token = await getReCaptchaToken();

      const isPhoneVerify = !!formatPhone(formData.phone);
      let url = isPhoneVerify ? verifyPhoneUrl : redirectUrl;

      const data = {
        ...formData,
        phone: formatPhone(formData.phone),
      };
      await userRegister({
        data,
        redirectUrl: url,
        saveToStore: !isPhoneVerify,
        recaptcha_token,
        handleServerError,
      });
      if (isPhoneVerify) {
        setActiveStep(activeStep + 1);
      }
    } catch (e) {
      handleServerError(e);
    }
  };

  const forgotPasswordSubmit = async (data: { email: string }) => {
    try {
      const recaptcha_token = await getReCaptchaToken();

      const res = await sendEmailResetPasswordRequest({
        email: data.email,
        recaptcha_token,
      });
      showNotification({ message: res.data.message, options: { variant: 'success' } });
      setOpen(false);
    } catch (e) {
      handleServerError(e);
    }
  };

  const handleCheckStep = async () => {
    const phone = methods.getValues('phone') || '';
    const email = methods.getValues('email');
    if (phone && phone.length < 3) {
      methods.setValue('phone', '');
      setActiveStep(activeStep + 1);
    }
    await checkUser({ email, phone });
  };

  const onSubmit = async (formData: AuthFormValues) => {
    switch (activeStep) {
      case 2: {
        await handleRegisterStep(formData);
        break;
      }
      case 1: {
        await handleCheckStep();
        break;
      }
      default: {
        setActiveStep(activeStep + 1);
      }
    }
  };

  return (
    <>
      <Fade in>
        <>
          {activeStep === 1 || activeStep === 3 ? (
            <Box display="flex" justifyContent="center">
              <Logo height={48} width={171} />
            </Box>
          ) : (
            <AppIconButton onClick={() => setActiveStep(activeStep - 1)}>
              <HiOutlineArrowLeft />
            </AppIconButton>
          )}

          <FormProvider {...methods}>
            <form className={classes.form} onSubmit={methods.handleSubmit(onSubmit)}>
              {activeStep === 1 && <StepOne title={title} subtitle={subtitle} />}

              {activeStep === 2 && <StepTwo firstName={methods.getValues('first_name')} />}

              <ReCAPTCHAComponent ref={reCaptchaRef} setRecaptchaLoaded={setRecaptchaLoaded} />

              <Box marginTop={5}>
                <Button
                  type="submit"
                  color="primary"
                  cy-test-id="next-btn"
                  variant="contained"
                  disabled={loading || !recaptchaLoaded}
                  fullWidth>
                  <ButtonTitleWithLoading
                    title={activeStep === STEPS ? 'Sign Up' : 'Next'}
                    loaderVariant="paper"
                    loading={!!loading}
                  />
                </Button>
              </Box>

              {activeStep !== 3 && (
                <Box display="flex" marginTop={3}>
                  Already have a Vyce account?
                  <Box marginLeft="4px" fontWeight={600}>
                    <Link className={classes.helpLink} to={getAuthPage(history.location.pathname, '/login')}>
                      Login
                    </Link>
                  </Box>
                </Box>
              )}
            </form>
          </FormProvider>

          {/*TODO Implement when backend is ready*/}
          {/*{activeStep !== 3 && <SocialButtons title="sign up" />}*/}
        </>
      </Fade>

      <Dialog
        container={document.getElementById(MAIN_CONTAINER_ID)}
        fullScreen={isMobile}
        open={open}
        onClose={() => setOpen(false)}>
        <DialogTitle style={{ color: theme.palette.primary.main }}>Ooops!</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" color="textSecondary">
            This email {methods.getValues('phone') ? 'or phone' : ''} is already registered on Vyce! Please{' '}
            <Link to={getAuthPage(history.location.pathname, '/login')}>login</Link>
          </Typography>
          <br />
          <Typography variant="subtitle1" color="textSecondary">
            Forgot your password? No problem. Click here to{' '}
            <Link to="#" onClick={() => setOpenForgotPassportDialog(true)}>
              reset your password
            </Link>
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button size="large" variant="outlined" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            size="large"
            variant="contained"
            color="secondary"
            onClick={() => setOpenForgotPassportDialog(true)}>
            Reset Password
          </Button>
          <Button
            onClick={() => history.push(getAuthPage(history.location.pathname, '/login'))}
            color="primary"
            size="large"
            variant="contained">
            Login
          </Button>
        </DialogActions>
      </Dialog>

      <ForgotPasswordDialog
        email={email}
        open={openForgotPassportDialog}
        setOpen={setOpenForgotPassportDialog}
        onSubmit={forgotPasswordSubmit}
      />
    </>
  );
};
