import { useCallback, useContext, useEffect, useState } from 'react';

import { useBooleanState } from '../../../hooks';
import { CardDetails, CardForm, Company } from '../../../types';
import {
  getCardDetailsRequest,
  getPaymentDetailsRequest,
  updateCardDetailsRequest,
} from '../../../api/billing';
import { paymentDetailsSuccessMessage } from '../constants';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  selectedCompany?: Company;
  token: string;
}

export const useCardDetails = ({ selectedCompany, token }: Props) => {
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const [isUpdating, setUpdatingTrue, setUpdatingFalse] = useBooleanState(false);
  const [paymentDetails, setPaymentDetails] = useState<CardDetails | null>(null);
  const [decryptedDetails, setDecryptedDetails] = useState<CardDetails | null>(null);
  const [initialCardForm, setInitialCardFrom] = useState<CardForm | null>(null);
  const [gettingDecryptedCard, setGettingDecryptedCardTrue, setGettingDecryptedCardFalse] =
    useBooleanState(false);
  const { uuid: selectedCompanyId } = selectedCompany ?? {};

  const prepareFormData = (data: CardDetails): CardForm => {
    return {
      expiry: `${data.exp_month}/${data.exp_year.toString().substring(2, 4)}`,
      ...data,
    };
  };

  const updateCardDetails = useCallback(
    async (data: CardForm) => {
      if (!selectedCompany?.uuid) {
        return;
      }
      try {
        setUpdatingTrue();
        await updateCardDetailsRequest({
          token,
          companyId: selectedCompany.uuid,
          requestData: data,
        });
        await getDecryptedCardDetails();
        showNotification({ message: paymentDetailsSuccessMessage, options: { variant: 'success' } });
        setUpdatingFalse();
      } catch (e) {
        setUpdatingFalse();
        handleServerError(e);
      }
    },
    [selectedCompanyId, token, paymentDetails]
  );

  const getDecryptedCardDetails = useCallback(async () => {
    if (!selectedCompanyId || !paymentDetails?.uuid) {
      return;
    }
    try {
      setGettingDecryptedCardTrue();
      const { data } = await getCardDetailsRequest({ token, cardId: paymentDetails.uuid });
      setDecryptedDetails(data);
      setInitialCardFrom(prepareFormData(data));
      setGettingDecryptedCardFalse();
    } catch (e) {
      setGettingDecryptedCardFalse();
      handleServerError(e);
    }
  }, [selectedCompanyId, token, paymentDetails]);

  const getPaymentDetails = useCallback(async () => {
    if (!selectedCompanyId) {
      return;
    }
    try {
      const { data } = await getPaymentDetailsRequest({ token, companyId: selectedCompanyId });
      setPaymentDetails(data);
    } catch {
      setPaymentDetails(null);
    }
  }, [selectedCompanyId, token]);

  useEffect(() => {
    if (!selectedCompanyId) {
      return;
    }
    getPaymentDetails();
  }, [selectedCompanyId]);

  return {
    initialCardForm,
    updateCardDetails,
    gettingDecryptedCard,
    getDecryptedCardDetails,
    isUpdating,
    paymentDetails,
    decryptedDetails,
    setDecryptedDetails,
  };
};
