import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { AppTextField } from '../inputs';

export const LocationNameField: React.FC = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      rules={{
        required: 'Name is required',
        validate: value => !!value.trim() || 'Name is required',
      }}
      name="name"
      render={({ field }) => (
        <AppTextField
          label="Location Name, E.G. Vyce New York Office"
          margin="normal"
          fullWidth
          {...field}
          error={!!errors?.name?.message}
          helperText={(errors?.name?.message as string) || ''}
        />
      )}
    />
  );
};
