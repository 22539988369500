import React, { MouseEventHandler } from 'react';

import { Avatar, Box, Button, Grid, Typography, useTheme } from '@material-ui/core';
import { BiTimeFive } from 'react-icons/bi';
import { FcMoneyTransfer } from 'react-icons/fc';
import TimeAgo from 'timeago-react';
import { Theme } from '@material-ui/core/styles';

import { useStyles } from '@vyce/core/src/views/hiring/styles';
import { Job } from '@vyce/core/src/types';
import { getJobSalary } from '@vyce/core/src/utils/job';
import { CloseJobButton } from '@vyce/core/src/views/hiring/components/CloseJobButton';
import { BackButton } from '@vyce/core/src/components/BackButton';
import defaultCompanyPicture from '@vyce/core/src/assets/company-icon.png';

import { JobDetails } from './JobDetails';

interface Props {
  job: Job;
  closeJob: (uuid: string) => void;
  back: MouseEventHandler<HTMLButtonElement>;
  onJobDuplicate?: (job: Job) => void;
  goToCandidates?: (job: Job) => void;
}

export const EmployerJobPage: React.FC<Props> = ({
  job,
  back,
  closeJob,
  onJobDuplicate,
  goToCandidates,
}) => {
  const classes = useStyles();
  const theme = useTheme<Theme>();

  return (
    <Box position="relative">
      <Box display="flex" justifyContent="flex-end" padding={1}>
        <BackButton back={back} />
      </Box>

      <Box paddingLeft={2} paddingRight={2}>
        <Box display="flex" alignItems="center" marginBottom={2}>
          <Avatar className={classes.avatar} src={job?.company?.logo?.url}>
            {!job?.company?.logo && <img height={40} alt="company" src={defaultCompanyPicture} />}
          </Avatar>

          <Box marginLeft={2}>
            <Typography variant="h6" color="primary" className={classes.bold}>
              {job?.name as string}
            </Typography>
            <Typography
              variant="subtitle2"
              className={classes.textWithIcon}
              style={{ color: theme.palette.success.main }}>
              <FcMoneyTransfer size="15px" className={classes.iconWithText} />
              {getJobSalary(job?.salary_from, job?.salary_to, job?.salary_type)}
            </Typography>
            {job?.overtime_type && (
              <Typography
                variant="subtitle2"
                className={classes.textWithIcon}
                style={{ color: theme.palette.success.main }}>
                <FcMoneyTransfer size="15px" className={classes.iconWithText} />
                {getJobSalary(job?.overtime_from, job?.overtime_to, job?.overtime_type)} (overtime)
              </Typography>
            )}
            {job?.updated_at && (
              <Typography variant="subtitle2" color="textSecondary" className={classes.textWithIcon}>
                <BiTimeFive size="15px" className={classes.iconWithText} />
                <TimeAgo datetime={job.updated_at} />
              </Typography>
            )}
          </Box>
        </Box>

        {goToCandidates && (
          <Button
            style={{ marginBottom: 8 }}
            size="medium"
            onClick={() => goToCandidates(job)}
            variant="contained"
            fullWidth
            color="primary">
            View Candidates
          </Button>
        )}
      </Box>

      <JobDetails job={job} />

      <Box padding={2}>
        <Box marginBottom={2} marginTop={1}>
          <Typography variant="h6" className={classes.bold}>
            Job Description
          </Typography>
        </Box>

        <Typography>{job?.description}</Typography>
      </Box>

      <Box padding={2} paddingBottom={3} paddingTop={3} className={classes.borderTop}>
        <Box marginBottom={2}>
          <Typography variant="h6" className={classes.bold}>
            Contact Details
          </Typography>
        </Box>

        <Grid container>
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="textSecondary">
              Contact Name
            </Typography>
            <Typography className={classes.capitalize} color="secondary" variant="subtitle2">
              {job?.contact_name}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="subtitle2" color="textSecondary">
              Phone number
            </Typography>
            <Typography className={classes.capitalize} color="secondary" variant="subtitle2">
              {job?.contact_phone}
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.jobDataItem}>
            <Typography variant="subtitle2" color="textSecondary">
              Email address
            </Typography>
            <Typography variant="subtitle2" color="secondary">
              {job?.contact_email}
            </Typography>
          </Grid>

          <Grid item xs={6} className={classes.jobDataItem}>
            <Typography variant="subtitle2" color="textSecondary">
              Postcode
            </Typography>
            <Typography variant="subtitle2">{job?.address?.address_line_4 || 'Unknown'}</Typography>
          </Grid>
        </Grid>
      </Box>

      <Box padding={2} className={classes.bottomButtonContainer} display="flex" gridGap={16}>
        {onJobDuplicate && (
          <Button
            onClick={() => onJobDuplicate(job)}
            variant="outlined"
            className={classes.candidatesButton}
            fullWidth
            color="primary">
            Duplicate
          </Button>
        )}

        <CloseJobButton job={job} closeJob={closeJob} />
      </Box>
    </Box>
  );
};
