import React, { useContext, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Typography,
} from '@material-ui/core';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { formatDate, getUKFormattedDate } from '@vyce/core/src/utils/dates';
import { IDVerification } from '@vyce/core/src/types';
import { DeviceContext } from '@vyce/core/src/contexts';
import { DOCUMENT_STATUSES_ARR, MAIN_CONTAINER_ID, VYCE_PHONE } from '@vyce/core/src/constants';
import { AppKeyboardDatePicker, AppSelect, AppCheckbox } from '@vyce/core/src/components/inputs';
import {
  createIDVerificationRequest,
  patchIDVerificationRequest,
  verifyByPassportRequest,
} from '@vyce/core/src/api/legend/documents';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';
import { IconStatusComponent } from '@vyce/core/src/components/Statuses/IconStatusComponent';

import useStyles from '../styles';

interface Props {
  idVerification?: IDVerification;
  isLegend?: boolean;
  userId?: string;
  setIDVerification: Function;
  setUploadPassportDialogOpen: (open: boolean) => void;
}

export const IDVerificationCard: React.FC<Props> = ({
  idVerification,
  isLegend,
  userId,
  setIDVerification,
  setUploadPassportDialogOpen,
}) => {
  const { handleServerError } = useContext(NotificationContext);
  const classes = useStyles();
  const { isMobile } = useContext(DeviceContext);
  const [howToVerifyOpen, setHowToVerifyOpen] = useState<boolean>(false);
  const [manualVerificationOpen, setManualVerificationOpen] = useState<boolean>(false);
  const methods = useForm<IDVerification>({
    defaultValues: {
      expiration_date: idVerification?.expiration_date,
      verified: idVerification?.verified,
      status: idVerification?.status,
    },
  });

  const verifyByPassport = async () => {
    if (!userId) {
      return;
    }
    try {
      const res = await verifyByPassportRequest(userId);
      setIDVerification(res.data);
      setManualVerificationOpen(false);
    } catch (e) {
      handleServerError(e);
    }
  };

  const onSubmit = async (data: IDVerification) => {
    if (!userId) {
      return;
    }
    try {
      if (!idVerification) {
        const res = await createIDVerificationRequest(userId, data);
        setIDVerification(res.data);
      } else {
        const res = await patchIDVerificationRequest(userId, data);
        setIDVerification(res.data);
      }
      setManualVerificationOpen(false);
    } catch (e) {
      handleServerError(e);
    }
  };

  const callVYCE = () => {
    window.open(`tel:${VYCE_PHONE}`, '_blank');
  };

  return (
    <>
      <Paper variant="outlined" className={classes.paper}>
        <Box display="flex" height={45} alignItems="center" justifyContent="space-between" width="100%">
          <Typography className={classes.bold} color="primary" variant="h6">
            ID
          </Typography>

          <IconStatusComponent
            verified={idVerification?.verified}
            expiry_date={idVerification?.expiration_date}
          />
        </Box>

        <Box width="100%" minHeight={80} marginTop={1} marginBottom={2} display="flex" alignItems="center">
          <Box display="flex" marginTop={1}>
            <Typography variant="subtitle2" color="textSecondary" style={{ marginRight: '4px' }}>
              Document Expiring:
            </Typography>
            <Typography variant="subtitle2">
              {getUKFormattedDate(idVerification?.expiration_date)}
            </Typography>
          </Box>
        </Box>

        {isLegend ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setManualVerificationOpen(true)}
            fullWidth>
            Verify Manually
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            disabled={idVerification?.verified}
            onClick={() => setHowToVerifyOpen(true)}
            fullWidth>
            Verify
          </Button>
        )}
      </Paper>

      <Dialog
        container={document.getElementById(MAIN_CONTAINER_ID)}
        fullScreen={isMobile}
        open={howToVerifyOpen}
        onClose={() => setHowToVerifyOpen(false)}>
        <DialogTitle>How to verify your ID...</DialogTitle>

        <DialogContent>
          <Typography color="textSecondary">
            Please upload a clear picture of your passport and our automated system will verify it. If you do
            not have a passport, please contact the Vyce team on {VYCE_PHONE}.
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button size="large" variant="outlined" onClick={() => setHowToVerifyOpen(false)}>
            Close
          </Button>
          <Button
            onClick={() => {
              setHowToVerifyOpen(false);
              setUploadPassportDialogOpen(true);
            }}
            size="large"
            color="primary"
            variant="contained">
            Verify Passport
          </Button>
          <Button size="large" onClick={callVYCE} color="secondary" variant="contained">
            Contact Vyce
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={isMobile}
        container={document.getElementById(MAIN_CONTAINER_ID)}
        open={manualVerificationOpen}
        maxWidth="xs"
        onClose={() => setManualVerificationOpen(false)}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <DialogTitle>Manual ID Verification</DialogTitle>

            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Controller
                      name="expiration_date"
                      control={methods.control}
                      render={props => (
                        <AppKeyboardDatePicker
                          margin="normal"
                          value={props.field.value || null}
                          onChange={data => props.field.onChange(formatDate(data))}
                          label="Expiration Date"
                          format="dd/MM/yyyy"
                          fullWidth
                        />
                      )}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controller
                    name="status"
                    control={methods.control}
                    render={props => (
                      <FormControl margin="normal" fullWidth variant="filled">
                        <InputLabel>Manual Status</InputLabel>
                        <AppSelect
                          onChange={e => props.field.onChange(e.target.value)}
                          value={props.field.value}
                          fullWidth>
                          {DOCUMENT_STATUSES_ARR.map(item => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </AppSelect>
                      </FormControl>
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box display="flex" alignItems="center" height="100%" justifyContent="flex-end">
                    <Controller
                      name="verified"
                      control={methods.control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <AppCheckbox
                              onChange={e => field.onChange(e.target.checked)}
                              color="primary"
                              checked={field.value}
                            />
                          }
                          label="Verified"
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button size="large" variant="outlined" onClick={() => setManualVerificationOpen(false)}>
                Close
              </Button>
              <Button size="large" onClick={verifyByPassport} color="primary" variant="contained">
                Verify By Passport
              </Button>
              <Button size="large" type="submit" color="primary" variant="contained">
                Save
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};
