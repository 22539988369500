import React from 'react';
import { AppTextField } from '../inputs';
import { Controller, useFormContext } from 'react-hook-form';
import { FieldProps } from '../../types';

export const BillingLine2Field: React.FC<FieldProps> = ({ margin }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name="address.line2"
      render={({ field }) => (
        <AppTextField
          label="Apartment, Suite, etc (optional)"
          margin={margin}
          fullWidth
          {...field}
          inputProps={{ maxlength: 40 }}
        />
      )}
    />
  );
};
