import React, { useContext, useMemo } from 'react';

import { Box, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { PaySchedule, PeriodData } from '@vyce/core/src/types';
import { formatTableDate } from '@vyce/core/src/utils/dates';
import { DeviceContext } from '@vyce/core/src/contexts';

import { useStyles } from '../styles';
import { StatusComponents } from './StatusComponents';

interface Props {
  companyName?: string;
  status?: string;
  paySchedule?: PaySchedule;
  periodData?: PeriodData;
}

export const PayScheduleInfo: React.FC<Props> = ({ companyName, paySchedule, status, periodData }) => {
  const classes = useStyles();
  const { isMobile } = useContext(DeviceContext);

  const rightPartOfInfo = useMemo(
    () => (
      <Box
        display="flex"
        flexDirection="column"
        alignItems={isMobile ? 'flex-start' : 'flex-end'}
        gridGap={isMobile ? 4 : 8}>
        {status && (
          <Box display="flex" gridGap={4}>
            <Typography variant="subtitle2" cy-test-id="timesheet-status" className={classes.smallText}>
              Status:
            </Typography>
            <StatusComponents
              status={status}
              isGreen={status === 'paid' || status === 'submitted'}
              className={clsx(classes.bold, classes.smallText)}
            />
          </Box>
        )}
        {periodData && (
          <Box display="flex" gridGap={4}>
            <Typography variant="subtitle2" className={classes.smallText}>
              Period:
            </Typography>
            <Typography variant="body2" className={classes.smallText}>
              {periodData?.tax_week_end} {/*({formatTableDate(periodData?.start_date)} -{' '}*/}
              {/*{formatTableDate(periodData?.end_date)})*/}
            </Typography>
          </Box>
        )}
      </Box>
    ),
    [classes.bold, classes.smallText, isMobile, periodData, status]
  );

  return (
    <Paper className={classes.wrapper} variant="outlined">
      <Box minHeight={52} display="flex" height="100%" alignItems="center" justifyContent="space-between">
        <Box display="flex" flexDirection="column" gridGap={isMobile ? 4 : 8}>
          <Box>
            <Typography variant="subtitle1" className={clsx(classes.bold, classes.smallText)}>
              {companyName}
            </Typography>
          </Box>

          {isMobile && rightPartOfInfo}

          {paySchedule?.name && (
            <Box display="flex" gridGap={4}>
              <Typography variant="subtitle2" className={classes.smallText}>
                Pay Schedule:
              </Typography>
              <Typography variant="body2" className={classes.smallText}>
                {paySchedule.name}
              </Typography>
            </Box>
          )}
        </Box>

        {!isMobile && rightPartOfInfo}
      </Box>
    </Paper>
  );
};
