import { WidgetWithData } from '@vyce/core/src/modules';
import { WidgetModuleProvider } from '@vyce/core/src/contexts';

import { WidgetItem } from '@vyce/core/src/types';

import { useWidgetData } from './hooks';
import { useMemo } from 'react';

interface Props {
  widget: WidgetItem;
  redirectUrl?: string;
}

export const WidgetModule = (props: Props) => {
  const data = useWidgetData();

  const contextOptions = useMemo(() => ({ ...data, ...props }), [data, props]);
  return (
    <WidgetModuleProvider value={contextOptions}>
      <WidgetWithData />
    </WidgetModuleProvider>
  );
};
