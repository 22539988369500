import React, { useContext } from 'react';

import { Box, Divider, FormControlLabel, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';
import capitalize from 'lodash/capitalize';

import { Break, PaySchedule, Shift, TimeAndAttendanceLocation } from '@vyce/core/src/types';
import { currencyFormatter } from '@vyce/core/src/utils';
import { DetailsIcon, InfoRow } from '@vyce/core/src/components';
import { AppCheckbox } from '@vyce/core/src/components/inputs';
import { CustomLocationField } from '@vyce/core/src/api/types';

import { useStyles } from '../styles';
import { LocationPreviewMap, ShiftInfoRaw, LocationControlButtons } from './components';
import { DeviceContext } from '../../../contexts';

interface Props {
  location: TimeAndAttendanceLocation;
  paySchedule?: PaySchedule;
  handleClose: Function;
  customLocationFields: CustomLocationField[];
}

export const getWeekHoursPopupData = (
  schedule: any,
  rate?: string | number | null,
  showRate: boolean = true,
  unit?: string
) => (
  <Box>
    {showRate && <Typography variant="subtitle2">Week hours:</Typography>}
    {Object.keys(schedule).map(key => (
      <Box display="flex" key={key} justifyContent="space-between">
        <Box width={85}>{capitalize(key)}</Box>
        <Box width={85} display="flex" justifyContent="center">
          {schedule[key].start && schedule[key].end
            ? `${schedule[key].start} - ${schedule[key].end}`
            : 'N/A'}
        </Box>
      </Box>
    ))}

    {!!rate && unit && showRate && (
      <Box marginTop={3}>
        <Typography variant="subtitle2">Default rate:</Typography>
        {currencyFormatter.format(+rate)} per {unit}
      </Box>
    )}
  </Box>
);

export const LocationPreview: React.FC<Props> = ({
  location,
  paySchedule,
  customLocationFields,
  handleClose,
}) => {
  const classes = useStyles();
  const { isMobile } = useContext(DeviceContext);

  return (
    <Box display="flex" flexDirection="column" gridGap={16}>
      <LocationControlButtons editMode={false} handleClose={handleClose} isLocationChanged={false} />

      <Paper className={classes.paper} variant="outlined">
        <Typography variant="h6">Location Details</Typography>

        <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gridGap={24}>
          <Box flex={1}>
            <InfoRow title="Location Name" value={location?.name || ''} />

            {!!location?.address?.address_snippet && (
              <InfoRow title="Location Address" value={location.address.address_snippet} />
            )}

            {!!paySchedule && <InfoRow title="Pay Schedule" value={paySchedule.name} />}

            {customLocationFields.map(cf => (
              <InfoRow title={cf.name} value={cf.value} />
            ))}
          </Box>

          {location.center && (
            <Box width={isMobile ? '100%' : 401} marginTop={2}>
              <LocationPreviewMap centerPosition={location.center} polygon={location.polygon} height={202} />
            </Box>
          )}
        </Box>
      </Paper>

      {!!location?.shifts?.length && (
        <Paper className={classes.paper} variant="outlined">
          <Typography variant="h6">Assigned Shifts & Breaks</Typography>

          {location.shifts.map((shift: Shift, index) => (
            <Box
              display="flex"
              key={shift.name + index}
              className={clsx(classes.shiftContainer, classes.shiftContainerLastChild)}>
              <Box flex={1}>
                <ShiftInfoRaw
                  leftText={shift.name}
                  middleElements={['Regular', 'Overtime']}
                  rightElements={[
                    <DetailsIcon
                      size="18px"
                      title="Details"
                      content={getWeekHoursPopupData(
                        shift.regular?.schedule,
                        shift.basic_amount,
                        true,
                        shift.basic_unit
                      )}
                    />,
                    <DetailsIcon
                      size="18px"
                      title="Details"
                      content={getWeekHoursPopupData(
                        shift.overtime?.schedule,
                        shift.overtime_amount,
                        true,
                        shift.overtime_unit
                      )}
                    />,
                  ]}
                />
              </Box>

              <Divider
                className={classes.divider}
                style={{ marginLeft: 16, marginRight: 16 }}
                orientation="vertical"
                flexItem
              />

              <Box flex={1} display="flex" gridGap={16} flexDirection="column">
                {shift.breaks?.length ? (
                  shift.breaks.map((item: Break, index: number) => (
                    <ShiftInfoRaw
                      key={item.name + index}
                      leftText={item.name}
                      middleElements={[`${item.minutes || 0} minutes`]}
                      rightElements={[
                        <FormControlLabel
                          control={
                            <AppCheckbox
                              classes={{ root: classes.breakCheckbox }}
                              color="primary"
                              checked={item.paid}
                            />
                          }
                          label="Paid"
                        />,
                      ]}
                    />
                  ))
                ) : (
                  <ShiftInfoRaw leftText="No breaks" />
                )}
              </Box>
            </Box>
          ))}
        </Paper>
      )}
    </Box>
  );
};
