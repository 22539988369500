import React, { createRef } from 'react';
import { Box, createStyles, makeStyles } from "@material-ui/core";
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { VscClose } from 'react-icons/vsc';
import { AppIconButton } from "./AppIconButton";

const snackBarStyles = {
  color: '#14142B',
  borderRadius: '12px',
  boxShadow: 'none',
  maxWidth: '700px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const useStyles = makeStyles(() =>
  createStyles({
    success: {
      backgroundColor: '#A6F787',
      ...snackBarStyles,
    },
    error: {
      backgroundColor: '#FF75CB',
      ...snackBarStyles,
    },
    warning: {
      backgroundColor: '#FFDF9A',
      ...snackBarStyles,
    },
    info: {
      backgroundColor: '#A996FF',
      ...snackBarStyles,
    },
  })
);

interface Props {
  children: React.ReactElement;
}

export const AppSnackbarProvider: React.FC<Props> = ({ children }) => {
  const notistackRef: any = createRef();
  const classes = useStyles();

  const onClickDismiss = (key: SnackbarKey) => () => {
    notistackRef?.current?.closeSnackbar(key);
  }

  return (
    <SnackbarProvider
      hideIconVariant
      ref={notistackRef}
      maxSnack={3}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
      action={(key) => (
        <Box>
        <AppIconButton onClick={onClickDismiss(key)}>
          <VscClose size="15px" color="#14142B"/>
        </AppIconButton>
        </Box>
      )}>
      {children}
    </SnackbarProvider>
  )
}
