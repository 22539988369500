import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { LatLngLiteral } from 'leaflet';

import { OnboardingStepTitle } from '../../../../components';
import { SITE_RADIUS } from '../../../../modules/timeModule/constants';
import { DrawableMap } from './DrawableMap';

export const LocationAreaStep: React.FC = () => {
  const { watch, setValue } = useFormContext();
  const [centerPosition, setCenterPosition] = useState<LatLngLiteral | null>(null);
  const address = watch('address');
  const polygon = watch('polygon');

  useEffect(() => {
    if (address?.lat && address?.lon) {
      const pos = { lat: address.lat, lng: address.lon };
      setCenterPosition(pos);
    } else {
      setCenterPosition(null);
    }
  }, [address]);

  return (
    <>
      <OnboardingStepTitle
        additionalText={`Your team will only be able to successfully check-in and check-out within ${SITE_RADIUS} metres of this pin. You can also draw the area yourself.`}
        text={
          centerPosition
            ? 'Great! Now move the pin to the place where your employees should be working or checking in.'
            : 'Input an address to be able to setup a place you want your team to clock-in and check-out.'
        }
      />

      <Box marginTop={2}>
        {centerPosition && (
          <DrawableMap setValue={setValue} centerPosition={centerPosition} polygon={polygon} height={377} />
        )}
      </Box>
    </>
  );
};
