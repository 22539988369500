import React, { useEffect, useMemo, useState, useContext } from 'react';

import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { Box, Button, FormControlLabel, Grid, Paper, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import capitalize from 'lodash/capitalize';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

import { PAY_FREQUENCIES, PAYMENT_DAYS, SUBCONTRACTOR_TYPES } from '../../constants';
import { AppFormWrapper, ImageUploader } from '../../components';
import { BankAccountName, BankAccountNumberField, SortCodeField } from '../../components/controlled-inputs';
import { patchHMRCSettingsByCompanyRequest, uploadPayScheduleContractWrapperRequest } from '../../api/pay';
import { CreateOrUpdateCompanyBankAccountRequest, GetCompanyBankAccountRequest } from '../../api/types';
import { getWeekDayName, getWeekDayNumber } from '../../utils/dates';
import { currencyFormatter } from '../../utils';
import { AppCheckbox, AppTextField } from '../../components/inputs';
import { useBooleanState } from '../../hooks';
import { BankAccount, PaySchedule, PayTagGroup, PublicLink } from '../../types';
import { getUrlItems } from '../../utils/url';
import { createPaySchedulePublicLinkRequest, getPaySchedulePublicLinksRequest } from '../../api/invites';
import { PayModuleContext } from '../../contexts';

import {
  NotificationStep,
  PayTagsGrid,
  PaySchedulePublicLinkDialog,
  AddExistingContractorDialog,
  HMRCSettingsEditBlock,
  GovernmentGatewayEditBlock,
} from './components';
import { fullHeight, hmrcKeys } from './config';
import { useStyles } from './styles';
import { NotificationContext } from '../../contexts/notificationContext';

interface Props {
  selectedCompanyName?: string;
  isLegend?: boolean;
  getPayScheduleRequest: Function;
  updatePaySchedulesRequest: Function;
  getCompanyBankAccountRequest: (params: GetCompanyBankAccountRequest) => Promise<AxiosResponse>;
  createOrUpdateCompanyBankAccountRequest: (
    params: CreateOrUpdateCompanyBankAccountRequest
  ) => Promise<AxiosResponse>;
}

interface Form extends PaySchedule {
  bank_account: BankAccount;
  tax_office_info: string;
  accounting_office_ref: string;
  utr: string;
  contact_email: string;
  contact_first_name: string;
  contact_last_name: string;
  contact_telephone: string;
  sender_id: string;
  password: string;
  sender: string;
}

const buttonPosition = { top: 16, right: 16 };

export const PaySchedulePage: React.FC<Props> = ({
  isLegend = false,
  selectedCompanyName,
  getPayScheduleRequest,
  updatePaySchedulesRequest,
  getCompanyBankAccountRequest,
  createOrUpdateCompanyBankAccountRequest,
}) => {
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const [paySchedule, setPaySchedule] = useState<PaySchedule>();
  const { schedule } = useParams<{ schedule: string }>();
  const [companyId, setCompanyId] = useState<string>();
  const [payScheduleId, setPayScheduleId] = useState<string>('');
  const [bankAccount, setBankAccount] = useState<BankAccount>();
  const [loading, setLoading] = useState<boolean>(false);
  const [publicLink, setPublicLink] = useState<PublicLink>();
  const [publicLinks, setPublicLinks] = useState<PublicLink[]>([]);
  const [isAddEmployeeDialogOpen, openAddEmployeeDialog, closeAddEmployeeDialog] = useBooleanState(false);
  const [isPublicLinkDialogOpen, openPublicLinkDialog, closePublicLinkDialog] = useBooleanState(false);
  const { hmrcSettings } = useContext(PayModuleContext);
  const classes = useStyles();
  const methods = useForm<Form>({
    defaultValues: {
      pay_frequency: PAY_FREQUENCIES[0].value as string,
      pay_weekday: '0',
      pay_last_workday: '1',
      email_pay_statement: paySchedule?.email_pay_statement,
      sms_pay_statement: paySchedule?.sms_pay_statement,
      name: '',
      emailWeekday: 'Monday',
      SMSWeekday: 'Monday',
      payroll_fee_base: paySchedule?.payroll_fee_base || undefined,
      payroll_fee_commission: paySchedule?.payroll_fee_commission || undefined,
      bank_account: {
        account_name: '',
        account_number: '',
        sort_code: '',
        building_society_roll_number: undefined,
      },
      tax_office_info: hmrcSettings?.tax_office_number || undefined,
      accounting_office_ref: hmrcSettings?.accounting_office_ref || undefined,
      utr: hmrcSettings?.utr || undefined,
      contact_email: hmrcSettings?.contact_email || undefined,
      contact_first_name: hmrcSettings?.contact_first_name || undefined,
      contact_last_name: hmrcSettings?.contact_last_name || undefined,
      contact_telephone: hmrcSettings?.contact_telephone || undefined,
      sender_id: hmrcSettings?.sender_id || undefined,
      password: hmrcSettings?.password || undefined,
      sender: hmrcSettings?.sender || undefined,
      custom_contract: undefined,
      payroll_paid_by_worker: paySchedule?.payroll_paid_by_worker,
    },
  });

  const { control, setValue, watch } = methods;
  const payroll_paid_by_worker = watch('payroll_paid_by_worker');

  const payrollFee = useMemo((): string => {
    if (paySchedule?.payroll_fee_base !== undefined && paySchedule?.payroll_fee_commission !== undefined) {
      const sum = paySchedule?.payroll_fee_base + paySchedule?.payroll_fee_commission;
      return currencyFormatter.format(sum);
    }
    return '';
  }, [paySchedule]);

  const getPublicLinks = async () => {
    if (!paySchedule?.uuid || !companyId) {
      return;
    }
    try {
      const res = await getPaySchedulePublicLinksRequest({
        companyId,
        payScheduleId: paySchedule.uuid,
      });
      setPublicLinks(res.data);
    } catch (e) {
      handleServerError(e);
    }
  };

  const getPublicLink = async (payScheme: SUBCONTRACTOR_TYPES) => {
    const existingPublicLink = publicLinks.find(link => link.pay_scheme === payScheme);
    if (existingPublicLink) {
      setPublicLink(existingPublicLink);
      openPublicLinkDialog();
      return;
    }

    if (!paySchedule?.uuid || !companyId) {
      return;
    }
    try {
      const res = await createPaySchedulePublicLinkRequest({
        companyId,
        payScheduleId: paySchedule.uuid,
        payScheme,
      });
      setPublicLink(res.data);
      openPublicLinkDialog();
    } catch (e) {
      handleServerError(e);
    }
  };

  const getPaySchedule = async (payScheduleId: string, companyId: string) => {
    try {
      setLoading(true);
      const res = await getPayScheduleRequest(companyId, payScheduleId);
      const bandAccountRes = await getCompanyBankAccountRequest({ companyId });
      setBankAccount(bandAccountRes.data);
      setLoading(false);
      const data = res.data;
      setPaySchedule({
        ...data,
        bank_account: bandAccountRes.data,
        emailWeekday: getWeekDayName(dayjs(data.email_pay_statement).day()),
        SMSWeekday: getWeekDayName(dayjs(data.sms_pay_statement).day()),
      });
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  };

  useEffect(() => {
    const timesheetUrlItems = getUrlItems(schedule);
    const payScheduleId = timesheetUrlItems?.id;
    setPayScheduleId(payScheduleId);
    const companyId = timesheetUrlItems?.additionalId;
    if (companyId) {
      setCompanyId(companyId);
      getPaySchedule(payScheduleId, companyId);
    }
  }, [schedule]);

  const handleSubmit = async (data: Form) => {
    if (!paySchedule?.uuid || !companyId) {
      return;
    }
    try {
      setLoading(true);
      const newHMRCSettings = pick(data, hmrcKeys);
      if (data.custom_contract?.file) {
        await uploadPayScheduleContractWrapperRequest({
          payScheduleId: paySchedule.uuid,
          contract: data.custom_contract.file,
          companyId,
        });
      }
      if (!isEqual(data.bank_account, bankAccount)) {
        await createOrUpdateCompanyBankAccountRequest({
          companyId,
          bankAccount: data.bank_account,
        });
      }
      if (!isEqual(newHMRCSettings, pick(hmrcSettings, hmrcKeys))) {
        await patchHMRCSettingsByCompanyRequest({ companyId, data: newHMRCSettings });
      }
      const res = await updatePaySchedulesRequest(companyId, preparePayScheduleData(data, paySchedule.uuid));
      setPaySchedule({
        ...res.data,
        bank_account: data.bank_account,
      });
      showNotification({ message: 'Pay Schedule has been updated', options: { variant: 'success' } });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  };

  const textField = (text: string) => (
    <Typography variant="subtitle2" style={{ fontSize: '15px' }}>
      {text}:{' '}
    </Typography>
  );

  const publicLinkButton = (payScheme: SUBCONTRACTOR_TYPES, title: string) => (
    <Button onClick={() => getPublicLink(payScheme)} variant="outlined" color="primary">
      {title}
    </Button>
  );

  const preparePayScheduleData = (data: Form, uuid: string): PaySchedule => {
    return {
      uuid,
      name: data.name,
      payroll_fee_base: data.payroll_fee_base,
      payroll_fee_commission: data.payroll_fee_commission,
      payroll_paid_by_worker: !!data.payroll_paid_by_worker,
      email_pay_statement:
        data.email_pay_statement && data.emailWeekday !== undefined
          ? dayjs(data.email_pay_statement).day(getWeekDayNumber(data.emailWeekday)).format()
          : null,
      sms_pay_statement:
        data.sms_pay_statement && data.SMSWeekday !== undefined
          ? dayjs(data.sms_pay_statement).day(getWeekDayNumber(data.SMSWeekday)).format()
          : null,
    };
  };

  const getPaymentDay = (): string => {
    if (paySchedule?.pay_frequency === 'monthly') {
      return paySchedule?.pay_last_workday ? 'Last day of the month' : '28th of every month';
    }
    return PAYMENT_DAYS[paySchedule?.pay_weekday];
  };

  const getAgreementObject = (paySchedule: PaySchedule) => {
    const url = paySchedule?.custom_contract?.body_url;
    return { file: null, url, name: url ? 'agreement.pdf' : '' };
  };

  useEffect(() => {
    getPublicLinks();
  }, [companyId, paySchedule?.uuid]);

  return (
    <>
      <AppFormWrapper
        methods={methods}
        initialData={paySchedule}
        handleSubmit={handleSubmit}
        loading={loading}>
        {paySchedule && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              <Paper variant="outlined" className={classes.wrapper} style={fullHeight}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="name"
                      render={({ field }) => (
                        <AppTextField
                          {...field}
                          fullWidth
                          value={field.value}
                          onChange={field.onChange}
                          label="Name"
                          inputProps={{ 'cy-test-id': 'pay-schedule-name-field' }}
                        />
                      )}
                    />
                  </Grid>

                  {paySchedule?.payroll_fee_base !== undefined && (
                    <Grid item md={6} xs={12}>
                      <Controller
                        control={control}
                        name="payroll_fee_base"
                        render={({ field }) => (
                          <AppTextField
                            {...field}
                            fullWidth
                            type="number"
                            value={field.value}
                            onChange={field.onChange}
                            label="Base Fee"
                          />
                        )}
                      />
                    </Grid>
                  )}
                  {paySchedule?.payroll_fee_commission !== undefined && (
                    <Grid item md={6} xs={12}>
                      <Controller
                        control={control}
                        name="payroll_fee_commission"
                        render={({ field }) => (
                          <AppTextField
                            {...field}
                            fullWidth
                            type="number"
                            value={field.value}
                            onChange={field.onChange}
                            label="Commission Fee"
                          />
                        )}
                      />
                    </Grid>
                  )}
                </Grid>

                <NotificationStep noTitle={true} />
              </Paper>
            </Grid>
            <Grid item xs={12} md={5}>
              {isLegend && paySchedule?.uuid && companyId && (
                <Box marginBottom={2}>
                  <Button onClick={openAddEmployeeDialog} variant="outlined" color="primary" fullWidth>
                    Add existing contractor to this pay schedule
                  </Button>

                  <AddExistingContractorDialog
                    open={isAddEmployeeDialogOpen}
                    handleClose={closeAddEmployeeDialog}
                    companyId={companyId}
                    payScheduleId={paySchedule.uuid}
                  />
                </Box>
              )}

              <Paper variant="outlined" className={classes.wrapper}>
                <Box display="flex">
                  <Box flex={1}>{textField('Payroll processed via')}</Box>
                  <Box flex={1}>
                    <Typography style={{ fontSize: '15px' }} color="textSecondary">
                      {paySchedule?.outsourced
                        ? 'Vyce HMRC Settings'
                        : `${paySchedule?.company?.name || selectedCompanyName} HMRC Settings`}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex" marginTop={2} marginBottom={2}>
                  <Box flex={1}>{textField('Pay frequency')}</Box>
                  <Box flex={1}>
                    <Typography style={{ fontSize: '15px' }} color="textSecondary">
                      {capitalize(paySchedule?.pay_frequency)}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box flex={1}>{textField('Payment Day')}</Box>
                  <Box flex={1}>
                    <Typography style={{ fontSize: '15px' }} color="textSecondary">
                      {getPaymentDay()}
                    </Typography>
                  </Box>
                </Box>
              </Paper>

              <Paper variant="outlined" className={classes.wrapper} style={{ marginTop: 16 }}>
                <Box flex={1}>{textField('New starter invite links for this pay schedule')}</Box>

                <Box marginTop={2} display="flex" flexDirection="column" gridGap={16}>
                  {publicLinkButton(
                    SUBCONTRACTOR_TYPES.CIS_SELF_EMPLOYED,
                    'Get link for CIS Self Employed Contractors'
                  )}
                  {publicLinkButton(
                    SUBCONTRACTOR_TYPES.SELF_EMPLOYED,
                    'Get link for Self Employed Contractors'
                  )}
                </Box>

                {publicLink && (
                  <PaySchedulePublicLinkDialog
                    companyName={selectedCompanyName || ''}
                    link={publicLink}
                    open={isPublicLinkDialogOpen}
                    handleClose={closePublicLinkDialog}
                  />
                )}
              </Paper>

              {paySchedule?.outsourced && (
                <Paper variant="outlined" className={classes.wrapper} style={{ marginTop: 16 }}>
                  <Typography variant="subtitle2" style={{ fontSize: '15px' }}>
                    {`Payer of the ${payrollFee} Payroll Fee:`}
                  </Typography>

                  <Box marginLeft="-6px" marginTop="11px" display="flex" flexDirection="column">
                    <FormControlLabel
                      classes={{ label: classes.checkboxLabel }}
                      control={
                        <AppCheckbox
                          onChange={() => setValue('payroll_paid_by_worker', false)}
                          color="primary"
                          cy-test-id="paid-by-company"
                          checked={!payroll_paid_by_worker}
                        />
                      }
                      label="Us, the company"
                    />

                    <FormControlLabel
                      classes={{ label: classes.checkboxLabel }}
                      control={
                        <AppCheckbox
                          onChange={() => setValue('payroll_paid_by_worker', true)}
                          cy-test-id="paid-by-worker"
                          color="primary"
                          checked={!!payroll_paid_by_worker}
                        />
                      }
                      label="The individual contractor"
                    />
                  </Box>
                </Paper>
              )}
            </Grid>
            {!paySchedule?.outsourced && (
              <Grid item xs={12} md={8}>
                <Paper variant="outlined" className={classes.wrapper} style={fullHeight}>
                  <Typography style={{ marginBottom: 8 }} variant="h6">
                    Bank Details
                  </Typography>

                  <Typography variant="body2">
                    These bank details are used to produce your BACS file. Please add the bank details that
                    match the account that you are processing your payments through. These bank details are
                    not used for anything else.
                  </Typography>

                  <BankAccountName margin="normal" />

                  <Box display="flex">
                    <Box flex={1} marginRight={1}>
                      <BankAccountNumberField margin="normal" />
                    </Box>

                    <Box flex={1} marginLeft={1}>
                      <SortCodeField margin="normal" />
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            )}
            {!paySchedule?.outsourced && (
              <Grid item xs={12} md={4}>
                <Paper variant="outlined" className={classes.wrapper} style={fullHeight}>
                  <Box display="flex" flexDirection="column" gridGap={16} height="100%">
                    <Typography style={{ fontWeight: 600 }} variant="body1">
                      Custom Agreement
                    </Typography>

                    <ImageUploader
                      width={200}
                      height={150}
                      image={getAgreementObject(paySchedule)}
                      isDocument={true}
                      onImageUpload={image => setValue('custom_contract', image)}
                      extraText="document"
                      type="pdf"
                      withEditModeButton
                      buttonPosition={buttonPosition}
                    />
                  </Box>
                </Paper>
              </Grid>
            )}
            {!paySchedule?.outsourced && (
              <Grid item xs={12} md={8}>
                <HMRCSettingsEditBlock />
              </Grid>
            )}
            {!paySchedule?.outsourced && (
              <Grid item xs={12} md={4}>
                <GovernmentGatewayEditBlock />
              </Grid>
            )}
          </Grid>
        )}
      </AppFormWrapper>

      {payScheduleId && companyId && (
        <>
          <PayTagsGrid
            subtitle="When a new starter registers they can confirm which location they are starting at. Please add any job
        locations that they can choose from here."
            payScheduleId={payScheduleId}
            companyId={companyId}
            group={PayTagGroup.LOCATION}
          />

          <PayTagsGrid
            companyId={companyId}
            subtitle="When a new starter registers they can confirm who their Line Manager is. Please add any Line Managers names that they can choose from here."
            payScheduleId={payScheduleId}
            group={PayTagGroup.MANAGER}
          />
        </>
      )}
    </>
  );
};
