import { axios } from '@vyce/core/src/api';

import { Job, RequestWorkerData } from '../../types';

// Links

export const applyJobRequest = (token: string, jobId: string, message: string) => {
  return axios.put(`/connect/links/jobs/${jobId}/apply`, { message });
};

export const applyWorkerRequest = (
  token: string,
  companyId: string,
  userId: string,
  jobId: string,
  message: string
) => {
  return axios.put(`/connect/links/companies/${companyId}/jobs/${jobId}/users/${userId}/apply`, { message });
};

export const cancelApplicationRequest = (token: string, jobId: string) => {
  return axios.delete(`/connect/links/jobs/${jobId}/cancel`);
};

export const declineApplicationRequest = (
  token: string,
  companyId: string,
  jobId: string,
  userId: string
) => {
  return axios.post(`/connect/links/companies/${companyId}/jobs/${jobId}/users/${userId}/decline`);
};

export const declineConnectionRequest = (token: string, jobId: string) => {
  return axios.post(`/connect/links/jobs/${jobId}/decline`);
};

export const acceptWorkerApplicationRequest = (
  token: string,
  companyId: string,
  jobId: string,
  userId: string
) => {
  return axios.post(`/connect/links/companies/${companyId}/jobs/${jobId}/users/${userId}/accept`);
};

export const acceptEmployerApplicationRequest = (token: string, jobId: string) => {
  return axios.post(`/connect/links/jobs/${jobId}/accept`);
};

export const cancelEmployerDeclinationRequest = (
  token: string,
  companyId: string,
  jobId: string,
  userId: string
) => {
  return axios.delete(`/connect/links/companies/${companyId}/jobs/${jobId}/users/${userId}/revert`);
};

export const cancelWorkerDeclinationRequest = (token: string, jobId: string) => {
  return axios.delete(`/connect/links/jobs/${jobId}/revert`);
};

export const cancelConnectionRequest = (token: string, companyId: string, userId: string, jobId: string) => {
  return axios.delete(`/connect/links/companies/${companyId}/jobs/${jobId}/users/${userId}/cancel`, {
    data: {},
  });
};

// Jobs

export const createJobRequest = (token: string, companyId: string, data: Job) => {
  return axios.post(`/connect/jobs/companies/${companyId}/jobs`, data);
};

export const deleteJobRequest = (token: string, companyId: string, jobId: string) => {
  return axios.delete(`/connect/jobs/companies/${companyId}/jobs/${jobId}`);
};

export const getJobsRequest = (token: string, data: any) => {
  return axios.post(`/connect/jobs/list`, data);
};

export const getJobRequest = (token: string, jobId: string) => {
  return axios.get(`/connect/jobs/${jobId}`);
};

export const getCompanyJobsRequest = (
  token: string,
  data: any,
  companyId: string,
  limit?: number,
  offset?: number
) => {
  return axios.post(`/connect/jobs/companies/${companyId}/jobs/list`, { ...data, limit, offset });
};

export const updateJobRequest = (token: string, jobId: string, companyId: string, data: any) => {
  return axios.patch(`/connect/jobs/companies/${companyId}/jobs/${jobId}`, data);
};

// Workers

export const createWorkerRequest = (token: string, data: RequestWorkerData) => {
  return axios.put(`/connect/workers`, data);
};

export const updateWorkerRequest = (token: string, uuid: string, data: RequestWorkerData) => {
  return axios.patch(`/connect/workers/${uuid}`, data);
};

// Matching

export const getCandidatesRequest = (companyId: string, jobId: string, data: any) => {
  return axios.post(`/connect/matching/companies/${companyId}/jobs/${jobId}`, data);
};

export const getCandidateRequest = (companyId: string, userId: string, jobId: string) => {
  return axios.get(`/connect/matching/companies/${companyId}/jobs/${jobId}/users/${userId}`);
};

export const getVacanciesRequest = (token: string, data: any) => {
  return axios.post(`/connect/matching/jobs`, data);
};

export const getVacancyRequest = (token: string, jobId: string) => {
  return axios.get(`/connect/matching/jobs/${jobId}`);
};
