import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100%',
  },
  moduleName: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginLeft: '4px',
  },
  customButton: {
    color: theme.palette.primary.main,
    background: theme.palette.type === 'dark' ? 'none' : theme.palette.background.paper,
  },
  smallText: {
    fontSize: '13px !important',
    lineHeight: '22px',
  },
  controlArea: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderRadius: '12px',
  },
  bold: {
    fontWeight: 600,
  },
}));
