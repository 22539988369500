import React from 'react';

import { createStyles, makeStyles, Theme, Slider, SliderProps } from '@material-ui/core';
import { ControllerRenderProps } from 'react-hook-form';

import { monochrome } from '../../theme/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiSlider-thumb': {
        width: theme.spacing(2),
        height: theme.spacing(2),
        marginTop: '-7px',
      },
      '& .MuiSlider-mark': {
        width: theme.spacing(1),
        height: theme.spacing(1),
        background: theme.palette.primary.light,
        borderRadius: '50%',
        transform: 'translate(-1px, -3px)',
        opacity: 1,
      },
      '& .MuiSlider-markActive': {
        background: theme.palette.primary.main,
      },
      '& .MuiSlider-markLabel': {
        color: theme.palette.type === 'dark' ? monochrome.light : monochrome.dark,
        fontWeight: 500,
        fontSize: '13px',
        lineHeight: '22px',
      },
    },
  })
);

export const AppSlider = (props: ControllerRenderProps<any, string> & SliderProps) => {
  const classes = useStyles();
  const { onChange } = props;

  const handleChange = (_event: React.ChangeEvent<{}>, value: number | number[]) => onChange(value);

  return <Slider {...props} onChange={handleChange} className={classes.root} />;
};
