import {
  Address,
  Break,
  Shift,
  ShiftSchedule,
  TabItem,
  TIME_INTERFACE_PERMISSIONS,
  TrackingType,
  TrackingTypeOption,
} from '../../types';

export const TIME_AND_ATTENDANCE_TABS: TabItem[] = [
  {
    label: 'Dashboard',
    link: '/time/dashboard',
  },
  {
    label: 'Locations & Shifts',
    link: '/time/locations-and-shifts',
    permission: TIME_INTERFACE_PERMISSIONS.LOCATIONS_AND_SHIFTS,
  },
  {
    label: 'Time Logs',
    link: '/time/timelogs',
  },
  {
    label: 'Users',
    link: '/time/users',
    permission: TIME_INTERFACE_PERMISSIONS.USERS,
  },
  {
    label: 'Manual Clock In/Out',
    link: '/time/manual',
  },
  {
    label: 'Settings',
    link: '/time/settings',
  },
];

export const DEFAULT_REGULAR_SCHEDULE: ShiftSchedule = {
  monday: {
    start: '08:00',
    end: '16:00',
  },
  tuesday: {
    start: '08:00',
    end: '16:00',
  },
  wednesday: {
    start: '08:00',
    end: '16:00',
  },
  thursday: {
    start: '08:00',
    end: '16:00',
  },
  friday: {
    start: '08:00',
    end: '16:00',
  },
  saturday: {
    start: '',
    end: '',
  },
  sunday: {
    start: '',
    end: '',
  },
};

export const DEFAULT_OVERTIME_SCHEDULE: ShiftSchedule = {
  monday: {
    start: '',
    end: '',
  },
  tuesday: {
    start: '',
    end: '',
  },
  wednesday: {
    start: '',
    end: '',
  },
  thursday: {
    start: '',
    end: '',
  },
  friday: {
    start: '',
    end: '',
  },
  saturday: {
    start: '',
    end: '',
  },
  sunday: {
    start: '',
    end: '',
  },
};

export enum ScheduleType {
  REGULAR = 'regular',
  OVERTIME = 'overtime',
}

export const EMPTY_BREAK: Break = {
  name: '',
  minutes: 0,
  paid: false,
};

export const SHIFTS_FIELD_NAME = 'shifts';

export const BREAKS_FIELD_NAME = 'breaks';

export const EMPTY_SHIFT: Shift = {
  basic_amount: undefined,
  basic_unit: 'hour',
  overtime_amount: undefined,
  overtime_unit: 'hour',
  regular: {
    startTime: '08:00',
    endTime: '16:00',
    schedule: DEFAULT_REGULAR_SCHEDULE,
  },
  overtime: {
    startTime: undefined,
    endTime: undefined,
    schedule: DEFAULT_OVERTIME_SCHEDULE,
  },
  name: '',
  breaks: [],
};

export const EMPTY_ADDRESS: Address = {
  address_line_1: '',
  address_line_2: '',
  address_line_3: '',
  address_line_4: '',
  address_snippet: '',
  country: '',
  lat: 0,
  lon: 0,
};

export const SITE_RADIUS = 125;

export const TRACKING_OPTIONS = {
  breadcrumbs: {
    value: TrackingType.BREADCRUMBS,
    name: 'Breadcrumbs',
    description:
      'Live track location of your workers the entire time they’re clocked-in. Requires workers’ permission to track their phone.',
  },
  inOut: {
    value: TrackingType.IN_OUT,
    name: 'In & Out',
    description: 'Only track workers’ clock-in and clock-out locations.',
  },
  none: {
    value: TrackingType.NO_TRACKING,
    name: 'None',
    description: 'Do not track your workers’ location.',
  },
};

export const TRACKING_TYPES: TrackingTypeOption[] = [
  TRACKING_OPTIONS.breadcrumbs,
  TRACKING_OPTIONS.inOut,
  TRACKING_OPTIONS.none,
];
