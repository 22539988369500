import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  useTheme,
} from '@material-ui/core';
import { HiOutlineTrash } from 'react-icons/hi';
import { Theme } from '@material-ui/core/styles';

import { AppIconButton } from '@vyce/core/src/components/AppIconButton';
import { useStyles } from '@vyce/core/src/views/hiring/styles';
import { Job } from '@vyce/core/src/types';

import { MAIN_CONTAINER_ID } from '../../../constants';

interface Props {
  isIconButton?: boolean;
  job: Job;
  closeJob: (uuid: string) => void;
}

export const CloseJobButton: React.FC<Props> = ({ isIconButton, job, closeJob }) => {
  const theme = useTheme<Theme>();
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles();

  return (
    <>
      {isIconButton ? (
        <Tooltip title="Close">
          <Box marginRight={2}>
            <AppIconButton onClick={() => setOpen(true)} variant="error">
              <HiOutlineTrash size="19px" />
            </AppIconButton>
          </Box>
        </Tooltip>
      ) : (
        <Button onClick={() => setOpen(true)} variant="outlined" fullWidth className={classes.closeButton}>
          Close Job
        </Button>
      )}

      <Dialog
        open={open}
        container={document.getElementById(MAIN_CONTAINER_ID)}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to close this '{job.name as string}' job?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Closing a job will stop any new applications and the job will be moved to your 'Old Jobs' tab.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            size="large"
            variant="outlined"
            style={{ color: theme.palette.error.main }}
            onClick={() => {
              closeJob(job.uuid);
              setOpen(false);
            }}>
            Yes, close job
          </Button>

          <Button size="large" onClick={() => setOpen(false)} variant="outlined" color="primary">
            No, take me back
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
