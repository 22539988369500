import { AxisTickProps } from '@nivo/axes';

import { useStyles } from '../styles';

export const CustomBottomTick = (tick: AxisTickProps<string>) => {
  const classes = useStyles();
  return (
    <g transform={`translate(${tick.x},${tick.y + 16})`}>
      <text textAnchor="middle" dominantBaseline="middle" className={classes.barText}>
        {tick.value}
      </text>
    </g>
  );
};
