import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import { FieldProps } from '../../types';
import { formatDate } from '../../utils/dates';
import { AppKeyboardDatePicker } from '../inputs/AppKeyboardDatePicker';

dayjs.extend(isSameOrAfter);

const getDefaultRules = (maxDate?: number | null) => {
  return {
    required: 'Date is required',
    validate: (value: string) => {
      const isSameOrAfter = maxDate ? dayjs(maxDate).isSameOrAfter(dayjs(value)) : true;
      const isValid = dayjs(value).isValid();
      return (isValid && isSameOrAfter) || 'Invalid Date';
    },
  };
};

export const ControlledDateField: React.FC<FieldProps> = ({
  margin,
  name = '',
  rules,
  label,
  disableFuture = false,
  disablePast = false,
  maxDate,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const fieldRules = useMemo(() => (rules ? rules : getDefaultRules(maxDate)), [rules, maxDate]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Controller
        name={name}
        rules={fieldRules}
        control={control}
        render={({ field }) => (
          <AppKeyboardDatePicker
            {...field}
            error={!!errors[name]?.message}
            value={field.value || null}
            onChange={data => field.onChange(formatDate(data))}
            label={label}
            autoOk
            margin={margin}
            format="dd/MM/yyyy"
            fullWidth
            helperText={(errors[name]?.message as string) || ''}
            disableFuture={disableFuture}
            disablePast={disablePast}
            maxDate={maxDate}
          />
        )}
      />
    </MuiPickersUtilsProvider>
  );
};
