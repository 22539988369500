import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FieldProps } from '../../types';
import {
  LOWER_CASE_REGEX,
  ONE_DIGIT_REGEX,
  SPECIAL_CHARACTER_REGEX,
  UPPER_CASE_REGEX,
} from '../ChangePasswordField';
import { PasswordField } from './PasswordField';

export const PasswordFields: React.FC<FieldProps> = ({ margin }) => {
  const { watch } = useFormContext();
  const password = watch('password_1', '');

  return (
    <>
      <PasswordField
        name="password_1"
        margin={margin}
        label="Password"
        rules={{
          required: 'Password is required',
          minLength: { value: 8, message: 'Must be at least 8 characters' },
          validate: {
            upperCase: (value: string) =>
              UPPER_CASE_REGEX.test(value) || 'Must be at least one upper case letter',
            lowerCase: (value: string) =>
              LOWER_CASE_REGEX.test(value) || 'Must be at least one lower case letter',
            oneDigit: (value: string) => ONE_DIGIT_REGEX.test(value) || 'Must be at least one digit',
            specialCharacter: (value: string) =>
              SPECIAL_CHARACTER_REGEX.test(value) || 'Must be at least one special character',
          },
        }}
      />

      <PasswordField
        name="password_2"
        margin={margin}
        label="Confirm Password"
        rules={{
          required: 'Confirm Password is required',
          validate: (value: string) => value === password || 'The passwords do not match',
        }}
      />
    </>
  );
};
