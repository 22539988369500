import React, { useContext } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { CopyToClipboard } from '@vyce/core/src/components/CopyToClipboard';
import { Candidate, CompanyPosition, INTERFACE_PERMISSIONS } from '@vyce/core/src/types';

import { MAIN_CONTAINER_ID } from '../../../constants';

interface Props {
  open: boolean;
  handleClose: Function;
  selectedPosition?: CompanyPosition;
  candidate: Candidate;
}

export const SuccessConnectionDialog: React.FC<Props> = ({
  open,
  handleClose,
  candidate,
  selectedPosition,
}) => {
  const { isMobile } = useContext(DeviceContext);
  const theme = useTheme();
  const history = useHistory();

  const handleInvite = () => {
    const email = `&email=${encodeURIComponent(candidate.email)}`;
    const phone = candidate.phone ? `&phone=${encodeURIComponent(candidate.phone)}` : '';
    history.push(`/payroll/users?invite=true${email}${phone}`);
  };

  return (
    <Dialog
      fullScreen={isMobile}
      container={document.getElementById(MAIN_CONTAINER_ID)}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleClose();
        }
      }}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">
        Great! You’ve connected to {candidate.first_name}!
      </DialogTitle>

      <DialogContent>
        <DialogContentText>Contact them now.</DialogContentText>

        <Box display="flex" alignItems="center">
          <Typography color="primary" align="center" variant="subtitle1">
            Contact Name: {candidate.first_name} {candidate.last_name}
          </Typography>
          <CopyToClipboard
            text={`${candidate.first_name} ${candidate.last_name}`}
            color={theme.palette.primary.main}
          />
        </Box>

        <Box display="flex" alignItems="center" marginTop={1}>
          <Typography color="primary" align="center" variant="subtitle1">
            E-mail them: {candidate.email}
          </Typography>
          <CopyToClipboard text={candidate.email} color={theme.palette.primary.main} />
        </Box>

        {candidate.phone && (
          <Box display="flex" alignItems="center" marginTop={1}>
            <Typography color="primary" align="center" variant="subtitle1">
              Call them: {candidate.phone}
            </Typography>
            <CopyToClipboard text={candidate.phone} color={theme.palette.primary.main} />
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button size="large" style={{ width: 150 }} onClick={() => handleClose()} variant="outlined">
          Ok, close
        </Button>
        {selectedPosition?.permissions?.includes(INTERFACE_PERMISSIONS.PAYROLL) && (
          <Button onClick={handleInvite} size="large" color="primary" variant="contained">
            Invite {candidate.first_name} to Payroll
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
