import React, { useEffect } from 'react';

import { Box, Fade } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

import { OnboardingStepTitle } from '@vyce/core/src/components';
import {
  YesNoRadio,
  LimitedCompanyNameField,
  LimitedCompanyNumberField,
  UTRNumberField,
} from '@vyce/core/src/components/controlled-inputs';

import { TitleProps } from './types';

export const LimitedCompanyStep = ({ title = 'Are you in a Limited Company?' }: TitleProps) => {
  const { watch, setValue } = useFormContext();
  const isLimitedCompany = watch('isLimitedCompany');

  useEffect(() => {
    if (isLimitedCompany === 'no') {
      setValue('company_name', null);
      setValue('company_reg_number', null);
    }
  }, [isLimitedCompany]);

  return (
    <>
      <OnboardingStepTitle text={title} />

      <YesNoRadio name="isLimitedCompany" />

      {isLimitedCompany === 'yes' && (
        <Fade timeout={500} in={true}>
          <Box style={{ width: '100%' }}>
            <LimitedCompanyNameField />

            <LimitedCompanyNumberField />

            <UTRNumberField
              showHint={false}
              margin="normal"
              label="Limited Company UTR"
              name="company_utr"
            />
          </Box>
        </Fade>
      )}
    </>
  );
};
