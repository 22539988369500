import { useEffect, useMemo, useState, useRef, useContext } from 'react';

import debounce from 'lodash/debounce';

import { useBooleanState } from '@vyce/core/src/hooks';
import { postWorkersWorkedTimeOverviewRequest } from '@vyce/core/src/api/time';

import { widths, limits } from '../config';
import { transformData } from '../utils';
import { EnrichedWorkersWorkedTimeOverview, Props } from '../types';
import { NotificationContext } from '../../../../contexts/notificationContext';

export const useChartData = ({ selectedCompanyId }: Props) => {
  const { handleServerError } = useContext(NotificationContext);
  const [fullData, setFullData] = useState<EnrichedWorkersWorkedTimeOverview[]>([]);
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [limit, setLimit] = useState(limits.bigLimit);
  const ref = useRef<HTMLDivElement>(null);
  const [loading, setLoadingTrue, setLoadingFalse] = useBooleanState(true);

  const cuttedData = useMemo(() => {
    if (page * limit > fullData.length) return fullData.slice(-limit);
    return fullData.slice((page - 1) * limit, page * limit);
  }, [fullData, page, limit]);

  const decreasePage = () => setPage(page - 1);
  const increasePage = () => setPage(page + 1);

  const fetchData = async () => {
    if (!selectedCompanyId) return;
    setLoadingTrue();

    try {
      const { data } = await postWorkersWorkedTimeOverviewRequest({
        companyId: selectedCompanyId,
        payload: { approved: true },
      });
      const enrichedData = transformData(data.items);
      setFullData(enrichedData.reverse());
      setLoadingFalse();
    } catch (e) {
      handleServerError(e);

      setLoadingFalse();
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedCompanyId]);

  useEffect(() => {
    const newMaxPage = fullData.length > limit ? Math.ceil(fullData.length / limit) : 0;
    setMaxPage(newMaxPage);
    setPage(newMaxPage);
  }, [limit, fullData]);

  useEffect(() => {
    const observeTarget = ref.current;
    if (!observeTarget) return;

    const debouncedCallback = debounce(entry => {
      const { width } = entry.contentRect;
      if (width >= widths.bigWidth) {
        setLimit(limits.bigLimit);
        return;
      }
      if (width >= widths.midWidth) {
        setLimit(limits.midLimit);
        return;
      }
      if (width >= widths.small) {
        setLimit(limits.smallLimit);
        return;
      }
      setLimit(limits.extraSmall);
    }, 300);

    const resizeObserver = new ResizeObserver(entries => {
      if (!Array.isArray(entries) || !entries.length) return;

      const entry = entries[0];
      debouncedCallback(entry);
    });

    resizeObserver.observe(observeTarget);

    return () => resizeObserver.unobserve(observeTarget);
  }, [ref]);

  return {
    loading,
    ref,
    cuttedData,
    showDecreaseButton: page > 1,
    showIncreaseButton: page !== maxPage,
    increasePage,
    decreasePage,
  };
};
