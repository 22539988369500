import React from 'react';
import { BACSFormat, Company, HMRCSetting } from '@vyce/core/src/types';
import noop from 'lodash/noop';

interface Context {
  hmrcSettings?: HMRCSetting;
  bacsFormats?: BACSFormat[];
  fetchPaySchedules?: (companyId: string) => void;
  companies?: Company[];
  selectedCompanyId?: string;
}

export const PayModuleContext = React.createContext<Context>({
  bacsFormats: [],
  fetchPaySchedules: noop,
  hmrcSettings: undefined,
  companies: [],
  selectedCompanyId: undefined,
});
export const PayModuleProvider = PayModuleContext.Provider;
