import React, { MouseEvent, useMemo } from 'react';

import { BarItemProps } from '@nivo/bar';
import { useTheme } from '@material-ui/core';
import { useTooltip } from '@nivo/tooltip';
import { useHistory } from 'react-router-dom';

import { transformSalary } from '@vyce/core/src/utils/job';
import { formatDate } from '@vyce/core/src/utils/dates';

import { colors, barNames, maxBarWidth, Names } from '../config';
import { Tooltip } from './Tooltip';

const style = { cursor: 'pointer' };

export const CustomBarComponent = ({ bar: { x, y, ...restProps }, onClick }: BarItemProps<any>) => {
  const theme = useTheme();
  const history = useHistory();
  const { showTooltipFromEvent, hideTooltip } = useTooltip();
  const { data, width, height } = restProps;
  const {
    average_hours = 0,
    total_workers = 0,
    total_overtime_hours = 0,
    total_basic_hours = 0,
    total_pay = 0,
    end_date = '',
    start_date = '',
  } = data.data;

  const id = (data?.id as Names) || barNames.total_basic_hours;

  const {
    lightColor = colors.total_basic_hours.lightColor,
    darkColor = colors.total_basic_hours.darkColor,
  } = colors[id];
  const newWidth = width > maxBarWidth ? maxBarWidth : width;

  const handleClick = (e: any) => {
    onClick && onClick({ ...data, color: restProps.color }, e);
    history.push(
      `/time/timelogs/${formatDate(start_date, undefined, 'DD-MM-YYYY')} - ${formatDate(
        end_date,
        undefined,
        'DD-MM-YYYY'
      )}`
    );
  };

  const tooltipOptions = useMemo(
    () => [
      {
        label: 'Total Labour Cost',
        value: `£${transformSalary(total_pay, 2)}`,
      },
      {
        label: 'Regular Hours',
        value: transformSalary(total_basic_hours, 2),
      },
      {
        label: 'Overtime Hours',
        value: transformSalary(total_overtime_hours, 2),
      },
      {
        label: 'Av. Hours worked PP',
        value: average_hours,
      },
      {
        label: 'Total Workers',
        value: total_workers,
      },
    ],
    [average_hours, total_basic_hours, total_overtime_hours, total_pay, total_workers]
  );

  const handleTooltip = (event: MouseEvent<SVGRectElement>) => {
    showTooltipFromEvent(<Tooltip options={tooltipOptions} />, event, 'left');
  };

  return (
    <g
      onClick={handleClick}
      style={style}
      onMouseEnter={handleTooltip}
      onMouseMove={handleTooltip}
      onMouseLeave={hideTooltip}>
      <rect
        x={x + width / 2 - newWidth / 2}
        y={y}
        width={newWidth}
        height={height}
        fill={theme.palette.type === 'dark' ? darkColor : lightColor}
      />
    </g>
  );
};
