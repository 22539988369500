import React, { ChangeEvent, ReactElement } from 'react';
import { Box, FormControlLabel } from '@material-ui/core';
import clsx from 'clsx';
import { AppCheckbox } from './inputs/AppCheckbox';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    checkboxContainer: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      padding: '0 16px',
      backgroundColor: 'theme.palette.background.paper',
      '&:first-child': {
        borderRadius: '8px 8px 0 0',
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    selected: {
      backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.default : '#EBECFE',
    },
    last: {
      borderBottom: 'none',
      borderRadius: '0 0 8px 8px',
    },
    label: {
      fontSize: 13,
      fontWeight: 500,
      color: theme.palette.text.primary,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'pre-wrap',
      width: '100%',
    },
    rootLabel: {
      width: '100%',
      padding: '10px 0',
    },
  })
);

interface Props {
  children?: ReactElement;
  checked: boolean;
  handleChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  label: string;
  last?: boolean;
}

export const AppCheckboxRow: React.FC<Props> = ({ children, checked, label, handleChange, last }) => {
  const classes = useStyles();

  return (
    <Box
      width="100%"
      className={clsx(classes.checkboxContainer, {
        [classes.selected]: checked,
        [classes.last]: last,
      })}>
      <Box display="flex" alignItems="center" width="100%">
        <FormControlLabel
          classes={{ label: classes.label, root: classes.rootLabel }}
          control={<AppCheckbox classes={{}} color="primary" checked={checked} onChange={handleChange} />}
          label={label}
        />
      </Box>

      {children}
    </Box>
  );
};
