import { TabItem } from '@vyce/core/src/types';

export const PREVIEW_URL = '/profile';

export const PREVIEW_TAB = {
  label: 'Preview',
  link: PREVIEW_URL,
};

export const PROFILE_TABS: TabItem[] = [
  {
    label: 'Personal',
    link: '/profile/personal',
  },
  {
    label: 'Professional',
    link: '/profile/professional',
  },
  {
    label: 'Employment',
    link: '/profile/employment',
  },
  {
    label: 'ID & RTW',
    link: '/profile/documents',
  },
  {
    label: 'Qualifications',
    link: '/profile/qualifications',
  },
  {
    label: 'Medical',
    link: '/profile/medical',
  },
  {
    label: 'Payments',
    link: '/profile/payments',
  },
  {
    label: 'Timesheets',
    link: '/profile/timesheets',
  },
  {
    label: 'Agreements',
    link: '/profile/agreements',
  },
  PREVIEW_TAB,
];
