import React, { useContext } from 'react';

import { Register } from '@vyce/core/src/views/auth/components/Register';
import { AuthContext } from '@vyce/core/src/contexts';

import { useActions, useTypedSelector } from '../../../hooks';
import { EMPLOYER_AUTH_PATHS } from './EmployerAuth';

export const EmployerRegister: React.FC = () => {
  const title = 'Welcome To Vyce';
  const subtitle = 'Sign up to manage your contractor workforce';
  const { setLoading, userRegister } = useActions();
  const { userDataLoading } = useTypedSelector(state => state.helper);
  const { redirectUrl } = useContext(AuthContext);

  return (
    <Register
      title={title}
      verifyPhoneUrl={EMPLOYER_AUTH_PATHS.VERIFY_PHONE}
      redirectUrl={redirectUrl || '/onboarding'}
      subtitle={subtitle}
      loading={userDataLoading}
      setLoading={setLoading}
      userRegister={userRegister}
    />
  );
};
