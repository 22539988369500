import React from 'react';

import { Box, Divider, Typography } from '@material-ui/core';

import { Day } from '../index';

interface Props {
  day: Day;
}

export const DayTimeValue: React.FC<Props> = ({ day }) => {
  const { basic, overtime } = day;

  return (
    <Box width={80} display="flex" gridGap={4} alignItems="center" justifyContent="center">
      <Box width={28} display="flex" justifyContent="center">
        <Typography variant="caption" style={{ fontWeight: 500 }}>
          {basic}
        </Typography>
      </Box>

      <Divider style={{ width: 2, margin: '0 4px' }} orientation="vertical" flexItem />

      <Box width={28} display="flex" justifyContent="center">
        <Typography variant="caption" style={{ fontWeight: 500 }}>
          {overtime}
        </Typography>
      </Box>
    </Box>
  );
};
