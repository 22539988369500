import React, { ChangeEvent, useContext } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Theme,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { BiCheckCircle, BiErrorCircle } from 'react-icons/bi';

import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { AppSelect } from '@vyce/core/src/components/inputs/AppSelect';
import { MappingItem } from '@vyce/core/src/types';

import { MAIN_CONTAINER_ID } from '../../../constants';

interface Props {
  open: boolean;
  closeDialog: Function;
  saveMapping: Function;
  setMappingItems: Function;
  mappingItems: MappingItem[];
  detectedColumns: string[];
}

export const MappingDialog: React.FC<Props> = ({
  open,
  closeDialog,
  detectedColumns,
  saveMapping,
  setMappingItems,
  mappingItems,
}) => {
  const { isMobile } = useContext(DeviceContext);
  const theme = useTheme<Theme>();

  const onColumnChange = (e: ChangeEvent<any>, prop: any) => {
    setMappingItems((items: MappingItem[]) => {
      return items.map(item => {
        if (item.value === prop.value) {
          return {
            ...item,
            matchedValue: e.target.value,
          };
        }
        return item;
      });
    });
  };

  return (
    <>
      {mappingItems?.length ? (
        <Dialog
          fullScreen={isMobile}
          container={document.getElementById(MAIN_CONTAINER_ID)}
          open={open}
          maxWidth="sm"
          onClose={(event, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
              closeDialog();
            }
          }}>
          <DialogTitle>Please match the columns from your Exel file.</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography variant="caption">
                'Worker Reference Number', 'Hours' and 'Rate' are required, another properties are optional.
                Please select an option only if you are sure that they match.
              </Typography>
            </DialogContentText>
            <Box>
              {mappingItems.map(prop => (
                <Box key={prop.value} marginBottom={1} display="flex" alignItems="center">
                  <Box maxWidth="270px" minWidth="270px">
                    <FormControl fullWidth variant="filled">
                      <InputLabel id={'select-label' + prop.value}>Columns</InputLabel>
                      <AppSelect
                        onChange={e => onColumnChange(e, prop)}
                        value={prop.matchedValue}
                        id={'select-label' + prop.value}
                        labelId={'select-label' + prop.value}
                        fullWidth>
                        <MenuItem value="">
                          <em>Do not include</em>
                        </MenuItem>
                        {detectedColumns?.map(col => (
                          <MenuItem key={col} value={col}>
                            {col}
                          </MenuItem>
                        ))}
                      </AppSelect>
                    </FormControl>
                  </Box>

                  <Box key={prop.value} display="flex" marginLeft={2}>
                    <Box marginRight={2} display="flex" alignItems="center">
                      {prop.required && !prop.matchedValue ? (
                        <BiErrorCircle size="20px" color={theme.palette.error.main} />
                      ) : (
                        <BiCheckCircle
                          size="20px"
                          color={
                            prop.matchedValue ? theme.palette.success.main : theme.palette.text.secondary
                          }
                        />
                      )}
                    </Box>
                    <Typography variant="body1">{prop.label}</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button size="large" variant="outlined" onClick={() => closeDialog()}>
              Cancel
            </Button>
            <Button
              size="large"
              disabled={!!mappingItems.find(item => !item.matchedValue && item.required)}
              color="primary"
              variant="contained"
              onClick={() => saveMapping()}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <></>
      )}
    </>
  );
};
