import { axios } from '@vyce/core/src/api';

import { Answer } from '../../types';

export const getSurveysRequest = (substring?: string) => {
  const query = substring ? `?substring=${substring}` : '';
  return axios.get<{ count: number; items: { uuid: string; name: string }[] }>(`/surveys${query}`);
};

export const getSurveyAnswers = (uuid: string) => {
  return axios.get(`/surveys/${uuid}`);
};

export const saveSurveyAnswers = (uuid: string, data: Answer[]) => {
  return axios.post(`/surveys/${uuid}`, data);
};

export const getSurveyList = () => {
  return axios.post<{ items: { uuid: string; name: string }[] }>(`/surveys/questions/tags/search`, {
    offset: 0,
    limit: 1000,
  });
};

export const getSurveysSettings = ({ companyId, surveyId }: { companyId: string; surveyId: string }) => {
  return axios.get<{ data: { excluded_tags: string[] } }>(
    `/surveys/companies/${companyId}/surveys/${surveyId}/tags/settings`
  );
};

export const createOrUpdateSurveysSettingRequests = ({
  companyId,
  surveyId,
  excludedTags = [],
}: {
  companyId: string;
  surveyId: string;
  excludedTags?: string[];
}) => {
  return axios.put(`/surveys/companies/${companyId}/surveys/${surveyId}/tags/settings`, {
    excluded_tags: excludedTags,
  });
};
