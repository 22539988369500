import React, { useContext, useEffect, useMemo } from 'react';

import { useHistory } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';

import { HoldingPage } from '@vyce/core/src/components/HoldingPage';
import config from '@vyce/core/src/assets/config';
import { ModuleVideo } from '@vyce/core/src/components/ModuleVideo';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';
import { VERIFY_EMAIL_ERROR_OBJECT } from '@vyce/core/src/constants';

import { useTypedSelector } from '../../../hooks';

interface Props {
  hasPermissions?: boolean;
}

export const GetStarted: React.FC<Props> = ({ hasPermissions = true }) => {
  const { email_verified, phone_verified } = useTypedSelector(state => state.user);
  const history = useHistory();
  const { showNotification } = useContext(NotificationContext);

  const noPermissionMessage = useMemo(
    () => (!hasPermissions ? 'Please contact your company Admin to get access to this module' : ''),
    [hasPermissions]
  );

  const goToOnboarding = () => {
    if (!email_verified && !phone_verified) {
      return showNotification(VERIFY_EMAIL_ERROR_OBJECT);
    }
    history.push('/payroll/onboarding');
  };

  useEffect(() => {
    if (!email_verified && !phone_verified) {
      showNotification(VERIFY_EMAIL_ERROR_OBJECT);
    }
  }, [email_verified, phone_verified]);

  return (
    <HoldingPage
      title="Payroll"
      buttonText="Let's start"
      handleButtonClick={goToOnboarding}
      image={config.welcome.payroll}
      textInsteadButton={noPermissionMessage}>
      <Box marginBottom={4}>
        <Box marginTop={2} marginBottom={2}>
          <ModuleVideo
            previewUrl={config.previewVideo.payroll}
            url="https://youtu.be/KmZMCPHy-ug?si=tBD2gO9Gu-QhBHo7"
          />
        </Box>

        <Box marginTop={1}>
          <Typography>
            Outsource the employment obligations to Vyce or use our tech powered payroll systems to process
            your own payroll.
          </Typography>
        </Box>

        <Box marginTop={3}>
          <Typography>
            Get insightful payroll analysis & reporting. Schedule automated payment notifications via email
            and SMS.
          </Typography>
        </Box>

        <Box marginTop={3}>
          <Typography>Set up and customise your way of working in minutes...</Typography>
        </Box>
      </Box>
    </HoldingPage>
  );
};
