import React, { useContext, useState } from 'react';

import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { TaskNames } from '@vyce/core/src/types';
import { DeviceContext } from '@vyce/core/src/contexts';
import { AppKeyboardDatePicker, AppTextField } from '@vyce/core/src/components/inputs';
import { AppA } from '@vyce/core/src/components';
import { formatDate } from '@vyce/core/src/utils/dates';

import { MAIN_CONTAINER_ID } from '../../../constants';

const shareCodeLink = 'https://www.gov.uk/prove-right-to-work/get-a-share-code-online';
interface Props {
  open: boolean;
  handleClose: Function;
  triggerCheckerRequest: Function;
  shareCode?: string;
  dateOfBirth?: string;
  userId?: string;
}

export const ShareCodeDialog: React.FC<Props> = ({
  open,
  handleClose,
  shareCode,
  dateOfBirth,
  triggerCheckerRequest,
  userId,
}) => {
  const { isMobile } = useContext(DeviceContext);
  const [code, setCode] = useState<string | undefined>(shareCode);
  const [birthday, setBirthday] = useState<string | undefined>(dateOfBirth);

  const verify = async () => {
    const parameters = {
      date_of_birth: birthday,
      rtw_share_code: code,
    };
    await triggerCheckerRequest({ task_name: TaskNames.CHECK_RTW, parameters, userId });
    handleClose();
  };

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      open={open}
      onClose={() => handleClose()}>
      <DialogTitle>Right to Work verification</DialogTitle>

      <DialogContent>
        <Box width={isMobile ? '100%' : 400}>
          <AppTextField
            fullWidth
            margin="normal"
            value={code}
            onChange={e => setCode(e.target.value.toUpperCase())}
            label="Share Code"
          />

          <Box>
            <Typography display="inline" style={{ fontSize: 13 }}>
              Don't have a share code?
            </Typography>{' '}
            <AppA href={shareCodeLink} content="Click here and follow the instructions to get one." />
          </Box>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <AppKeyboardDatePicker
              value={birthday}
              onChange={date => setBirthday(formatDate(date))}
              label="Date of Birth"
              autoOk
              margin="normal"
              format="dd/MM/yyyy"
              fullWidth
            />
          </MuiPickersUtilsProvider>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button size="large" variant="outlined" onClick={() => handleClose()}>
          Close
        </Button>
        <Button size="large" onClick={verify} disabled={!code} color="primary" variant="contained">
          Verify
        </Button>
      </DialogActions>
    </Dialog>
  );
};
