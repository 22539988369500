import React, { useContext } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  useTheme,
  Divider,
} from '@material-ui/core';

import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';
import { DeviceContext } from '@vyce/core/src/contexts';

import { TIPS } from '../constants';

interface Props {
  open: boolean;
  handleClose: Function;
}

export const TipsDialog: React.FC<Props> = ({ open, handleClose }) => {
  const theme = useTheme();
  const { isMobile } = useContext(DeviceContext);

  const iconSize = isMobile ? 24 : 28;
  const gap = isMobile ? 1 : 2;

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullScreen={isMobile}
      container={document.getElementById(MAIN_CONTAINER_ID)}
      onClose={() => handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle>To get the best out of Vyce AI:</DialogTitle>

      <DialogContent>
        <Box marginTop={2} display="flex" flexWrap="wrap">
          {TIPS.map((item, i) => (
            <Box key={item.title} width="100%">
              <Box display="flex" gridGap={16} alignItems="flex-start">
                <Box width={iconSize}>
                  <item.icon size={iconSize} color={theme.palette.primary.main} />
                </Box>

                <Box display="flex" flexDirection="column" gridGap={8}>
                  <Typography style={{ fontWeight: 600, fontSize: isMobile ? 15 : 18 }}>
                    {item.title}
                  </Typography>
                  <Typography style={{ fontSize: isMobile ? 13 : 15 }}>{item.text}</Typography>
                </Box>
              </Box>

              {i < TIPS.length - 1 && (
                <Box marginTop={gap} marginBottom={gap}>
                  <Divider />
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => handleClose()} variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
