import { EmployeeActivityLog } from '@vyce/core/src/types';
import { ActivityLogs } from '@vyce/core/src/views/time/ActivityLogs';

export const prepareWorkerLogData = (log: EmployeeActivityLog) => {
  if (!log) return [];

  return log.periods.map(period => {
    const clockIn = period.check_in;
    const clockOut = period.check_out;
    const inFaceVerified = !!clockIn?.face_verified;
    const outFaceVerified = !!clockOut?.face_verified;
    const inLocationVerified = !!clockIn?.within_area;
    const outLocationVerified = !!clockOut?.within_area;
    const faceTitle = ActivityLogs.getFaceTitle(inFaceVerified, outFaceVerified);
    const locationTitle = ActivityLogs.getLocationTitle(inLocationVerified, outLocationVerified);

    return {
      locationName: period.site?.name ?? '',
      basic_hours: period.basic_hours,
      basic_minutes: period.basic_minutes,
      overtime_hours: period.overtime_hours,
      overtime_minutes: period.overtime_minutes,
      check_in_timestamp: period.check_in.timestamp,
      check_out_timestamp: period.check_out.timestamp,
      check_in_within_area: period.check_in.within_area,
      check_out_within_area: period.check_out.within_area,
      clockInIcon: clockIn?.icon_url || '',
      clockOutIcon: clockOut?.icon_url || '',
      clockInFaceVerified: inLocationVerified,
      clockOutFaceVerified: outLocationVerified,
      faceVerificationTitle: faceTitle,
      locationVerificationTitle: locationTitle,
    };
  });
};
