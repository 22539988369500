import React, { useContext } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';

import { useStyles } from '../styles';
import { MAIN_CONTAINER_ID } from '../../../constants';
import { TimesheetLine } from '../../../types';
import { DeviceContext } from '../../../contexts';
import { AccentNumbers } from '../../../components';
import { ErrorsList } from './LastErrorsDialog';

interface Props {
  open: boolean;
  closeDialog: Function;
  validLines: TimesheetLine[];
  linesWithErrors: TimesheetLine[];
}

export const TimesheetUploadDialog: React.FC<Props> = ({
  open,
  closeDialog,
  validLines,
  linesWithErrors,
}) => {
  const { isMobile } = useContext(DeviceContext);
  const classes = useStyles();

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      maxWidth="xs"
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          closeDialog();
        }
      }}>
      <DialogTitle>Let’s check what was uploaded for you...</DialogTitle>
      <DialogContent>
        <Box display="flex">
          <Box className={classes.resultWrapper}>
            <AccentNumbers variant="success" label={validLines?.length?.toString()} />
            <Box marginTop={1}>
              <Typography variant="subtitle2" color="textSecondary">
                Valid Rows
              </Typography>
            </Box>
          </Box>

          <Box marginLeft={1} className={classes.resultWrapper}>
            <AccentNumbers variant="error" label={linesWithErrors?.length?.toString()} />
            <Box marginTop={1}>
              <Typography variant="subtitle2" color="textSecondary">
                Rows with errors
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box marginTop={2} maxHeight={400} overflow="auto">
          <Typography variant="subtitle2" color="textSecondary">
            Rows with Errors:
          </Typography>

          <ErrorsList linesWithErrors={linesWithErrors} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button size="large" color="primary" variant="outlined" onClick={() => closeDialog()}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
