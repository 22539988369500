import { useCallback, useContext, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { BillingData, CardForm, Subscription } from '@vyce/core/src/types';
import { useBooleanState, useQuery } from '@vyce/core/src/hooks';
import {
  cancelSubscriptionRequest,
  createSubscriptionRequest,
  getBillingDataRequest,
} from '@vyce/core/src/api/billing';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import { useActions, useTypedSelector } from '../../../hooks';

export const useSubscriptionData = () => {
  const { selectedCompany, access_token, selectedCompanyAppData } = useTypedSelector(state => state.helper);
  const { email } = useTypedSelector(state => state.user);
  const { updateSelectedCompanySubscription } = useActions();
  const { handleServerError } = useContext(NotificationContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSuccessDialogOpen, openSuccessDialog, closeSuccessDialog] = useBooleanState(false);
  const [isManageDialogOpen, openManageDialog, closeManageDialog] = useBooleanState(false);
  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] = useBooleanState(false);
  const [isCancelReasonDialogOpen, openCancelReasonDialog, closeCancelReasonDialog] = useBooleanState(false);
  const [isActivateSubscriptionDialogOpen, openActivateSubscriptionDialog, closeActivateSubscriptionDialog] =
    useBooleanState(false);
  const [isLoginDialogOpen, openLoginDialog, closeLoginDialog] = useBooleanState(false);
  const history = useHistory();
  const [billingData, setBillingData] = useState<BillingData>();
  const query = useQuery();

  const subscribe = useCallback(
    async (data: CardForm) => {
      if (!selectedCompany?.uuid) {
        return;
      }
      setLoading(true);
      try {
        const res = await createSubscriptionRequest(access_token, selectedCompany.uuid, data);
        const subscription: Subscription = res.data;
        setLoading(false);
        closeActivateSubscriptionDialog();
        if (updateSelectedCompanySubscription) {
          updateSelectedCompanySubscription(subscription);
        }
        openSuccessDialog();
      } catch (e) {
        handleServerError(e);
        setLoading(false);
      }
    },
    [selectedCompany, access_token, updateSelectedCompanySubscription]
  );

  const getBillingData = async () => {
    if (!selectedCompany?.uuid) {
      return;
    }
    try {
      const res = await getBillingDataRequest({ token: access_token, companyId: selectedCompany.uuid });
      setBillingData(res.data);
    } catch (e) {
      console.error(e);
      setBillingData(undefined);
    }
  };

  const cancelSubscription = useCallback(async () => {
    if (!selectedCompany?.uuid) {
      return;
    }
    try {
      const res = await cancelSubscriptionRequest(access_token, selectedCompany.uuid);
      const subscription: Subscription = res.data;
      if (updateSelectedCompanySubscription) {
        updateSelectedCompanySubscription(subscription);
      }
      closeConfirmDialog();
      openCancelReasonDialog();
    } catch (e) {
      handleServerError(e);
    }
  }, [selectedCompany, access_token]);

  const handleNext = () => {
    history.push('/subscriptions/billing/payment-details');
  };

  useEffect(() => {
    if (selectedCompany) {
      getBillingData();
      if (query.has('dialog')) {
        openActivateSubscriptionDialog();
      }
    }
  }, [selectedCompany]);

  return {
    handleNext,
    cancelSubscription,
    subscribe,
    openActivateSubscriptionDialog,
    openManageDialog,
    isSuccessDialogOpen,
    closeSuccessDialog,
    closeConfirmDialog,
    isConfirmDialogOpen,
    closeCancelReasonDialog,
    isCancelReasonDialogOpen,
    isActivateSubscriptionDialogOpen,
    closeActivateSubscriptionDialog,
    loading,
    openConfirmDialog,
    isManageDialogOpen,
    openLoginDialog,
    closeManageDialog,
    isLoginDialogOpen,
    closeLoginDialog,
    selectedCompany,
    email,
    selectedCompanyAppData,
    billingData,
  };
};
