//@ts-nocheck
import React from 'react';
import { AppTextField } from '../inputs';
import { Controller, useFormContext } from 'react-hook-form';
import { FieldProps } from '../../types';

export const BillingPostcodeField: React.FC<FieldProps> = ({ margin }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      rules={{
        required: 'ZIP / Postal Code is required',
      }}
      name="address.postal_code"
      render={({ field }) => (
        <AppTextField
          label="ZIP / Postal Code"
          margin={margin}
          fullWidth
          {...field}
          error={!!errors?.address?.postal_code?.message}
          helperText={errors?.address?.postal_code?.message || ''}
        />
      )}
    />
  );
};
