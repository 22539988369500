import React from 'react';
import { Box, Typography, Grid, useTheme } from '@material-ui/core';

import { CopyToClipboard } from '@vyce/core/src/components/CopyToClipboard';
import { DetailItem } from '@vyce/core/src/types';

import useStyles from '../../styles';
import { BoxWrapper } from './BoxWrapper';

interface BankDetailsProps {
  account_name: DetailItem;
  sort_code: DetailItem;
  account_number: DetailItem;
  building_society_roll_number: DetailItem;
}

const DataRowItem = ({ title, value, copy = false }: DetailItem & { copy?: boolean }) => {
  const classes = useStyles();
  const theme = useTheme();

  if (!value) return null;
  return (
    <Box className={classes.recordWrapper}>
      <Grid item xs={12} sm={6} lg={5}>
        <Typography className={classes.bold}>{title}:</Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={7}>
        <Box display="flex">
          <Typography color="textSecondary" className={classes.bigTextWrap}>
            {value}
          </Typography>
          {copy && <CopyToClipboard text={value} color={theme.palette.text.secondary} />}
        </Box>
      </Grid>
    </Box>
  );
};

export const BankDetails = ({
  account_name,
  sort_code,
  account_number,
  building_society_roll_number,
}: BankDetailsProps) => (
  <Box display="flex" flexDirection="column" height="100%">
    <Typography color="textSecondary" variant="h6">
      Bank Details
    </Typography>
    <BoxWrapper gridGap={16}>
      <DataRowItem {...account_name} />
      <DataRowItem {...sort_code} />
      <DataRowItem {...account_number} copy />
      <DataRowItem {...building_society_roll_number} />
    </BoxWrapper>
  </Box>
);
