import React from 'react';

import { FiCopy } from 'react-icons/fi';
import { Box, Tooltip, useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';

interface Props {
  text: string;
  color?: string;
  type?: string;
}

export const CopyToClipboard: React.FC<Props> = ({ text, color, type = 'text/plain' }) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const successNotification = () => {
    enqueueSnackbar('Copied to clipboard', {
      variant: 'info',
    });
  };

  const copyToClipboard = async () => {
    if (navigator.clipboard && navigator.clipboard.write) {
      try {
        const blob = new Blob([text], { type });
        const data = [new ClipboardItem({ [type]: blob })];
        await navigator.clipboard.write(data);
        successNotification();
      } catch (e) {
        console.error(e);
      }
    } else {
      // for old browsers
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = text;
      document.body.appendChild(tempDiv);

      const range = document.createRange();
      range.selectNodeContents(tempDiv);

      const selection = window.getSelection();
      selection?.removeAllRanges();
      selection?.addRange(range);

      try {
        document.execCommand('copy');
        successNotification();
      } catch (e) {
        console.error(e);
      }

      document.body.removeChild(tempDiv);
      selection?.removeAllRanges();
    }
  };

  return (
    <Tooltip title="Copy">
      <Box marginLeft={1} display="flex" alignItems="center" justifyContent="center">
        <FiCopy
          style={{ cursor: 'pointer' }}
          onClick={copyToClipboard}
          size="16px"
          color={(color = theme.palette.text.secondary)}
        />
      </Box>
    </Tooltip>
  );
};
