import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { AppTextField } from '../inputs/AppTextField';
import { FieldProps } from '../../types';

const REGEX = /[ABCEGHJKLMNOPRSTWXYZ]{2}[0-9]{6}[A-D ]?$/;
const DEFAULT_RULES = {
  validate: (value: string) => {
    if (!value) return true;
    return REGEX.test(value) || 'Invalid NI Number';
  },
};
const NAME = 'ni_number';

export const NiNumberField: React.FC<FieldProps> = ({ margin = undefined, disabled, rules }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const niNumberRules = rules ? { ...rules, ...DEFAULT_RULES } : DEFAULT_RULES;

  return (
    <Controller
      control={control}
      rules={niNumberRules}
      name={NAME}
      render={({ field }) => (
        <AppTextField
          {...field}
          onChange={e => field.onChange(e.target.value.toUpperCase())}
          id="ni-number"
          label="NI Number"
          disabled={disabled}
          fullWidth
          error={!!errors[NAME]?.message}
          inputProps={{
            maxLength: '9',
          }}
          helperText={(errors[NAME]?.message as string) || ''}
          margin={margin}
        />
      )}
    />
  );
};
