import React, { useState } from 'react';

import { Badge, Dialog, DialogActions, DialogContent, Theme, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { HiOutlineSearch } from 'react-icons/hi';

import { useStyles } from '../views/hiring/styles';
import { AppIconButton } from './AppIconButton';
import { InputSearch } from './InputSearch';
import { ColorOption } from '../types';
import { MAIN_CONTAINER_ID } from '../constants';

interface Props {
  handleSearchChange: any;
  searchText: string;
  variant?: ColorOption;
}

export const IconButtonSearch: React.FC<Props> = ({ handleSearchChange, searchText, variant = 'paper' }) => {
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Badge overlap="rectangular" badgeContent={!!searchText ? 1 : 0} color="primary">
        <AppIconButton variant={variant} onClick={() => setOpen(true)} className={classes.searchButton}>
          <HiOutlineSearch size="22px" color={theme.palette.text.primary} />
        </AppIconButton>
      </Badge>

      <Dialog
        open={open}
        container={document.getElementById(MAIN_CONTAINER_ID)}
        className={classes.searchDialog}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <InputSearch handleSearchChange={handleSearchChange} searchText={searchText} />
        </DialogContent>
        <DialogActions>
          <Button size="large" onClick={() => setOpen(false)} color="primary">
            Back to Results
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
