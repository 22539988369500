import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { FilledInput, FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import trim from 'lodash/trim';

import { useAppTextFieldStyles } from '../inputs';
import { FieldProps } from '../../types';
import { DetailsIcon } from '../DetailsIcon';

const hint =
  'An optional default rate for all workers associated to this shift. Each individual’s rate may be customised at any time.';

interface Props extends FieldProps {
  showHint?: boolean;
}

export const DefaultRateField: React.FC<Props> = ({ name = 'rate', label, margin, showHint, disabled }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const inputClasses = useAppTextFieldStyles({});

  return (
    <Controller
      control={control}
      rules={{ validate: value => trim(value) !== '0' || 'Can not be 0' }}
      name={name}
      render={({ field }) => (
        <FormControl fullWidth variant="filled" margin={margin}>
          <InputLabel htmlFor="default-rate">{label}</InputLabel>
          <FilledInput
            {...field}
            type="number"
            error={!!errors[name]?.message}
            disabled={disabled}
            classes={inputClasses}
            disableUnderline
            id="default-rate"
            endAdornment={showHint && <DetailsIcon content={<>{hint}</>} />}
          />
          <FormHelperText error>{(errors[name]?.message as string) || ''}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
