import React from 'react';

import { Box, Button, Paper, Typography } from '@material-ui/core';
import { AiOutlinePlus } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';

import { useStyles } from '@vyce/core/src/views/payroll/styles';

import { useTypedSelector } from '../../../hooks';
import { PayScheduleItem } from './PayScheduleItem';

export const PaySchedules: React.FC = () => {
  const classes = useStyles();
  const { selectedCompanyAppData } = useTypedSelector(state => state.helper);
  const history = useHistory();

  const handleAddPayScheduleClick = () => {
    history.push('/payroll/create-pay-schedule');
  };

  return (
    <Box>
      <Paper variant="outlined" className={classes.wrapper}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography className={classes.bold} variant="h6">
            Pay Schedules
          </Typography>

          <Button
            onClick={handleAddPayScheduleClick}
            startIcon={<AiOutlinePlus />}
            variant="contained"
            size="small"
            color="primary">
            Add Pay Schedule
          </Button>
        </Box>

        {selectedCompanyAppData?.paySchedules?.map(schedule => (
          <PayScheduleItem key={schedule.uuid} schedule={schedule} />
        ))}
      </Paper>
    </Box>
  );
};
