import React, { useContext, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, useTheme } from '@material-ui/core';

import { DeviceContext } from '@vyce/core/src/contexts';
import { AppNotification, VerifyPhoneData } from '@vyce/core/src/types';
import { sendEmailVerificationRequest, sendVerificationSMSRequest } from '@vyce/core/src/api/auth';
import { useRecaptcha } from '@vyce/core/src/hooks';
import { ReCAPTCHAComponent } from '@vyce/core/src/components';

import { VerifyPhone } from '../views/auth/components/VerifyPhone';
import { MAIN_CONTAINER_ID } from '../constants';
import { NotificationContext } from '../contexts/notificationContext';

interface Props {
  userId: string;
  verifyPhone?: (data: VerifyPhoneData) => void;
}

export const MainNotifications: React.FC<Props> = ({ userId, verifyPhone }) => {
  const { handleServerError, notification, hideNotification, showNotification } =
    useContext(NotificationContext);
  const { enqueueSnackbar } = useSnackbar();
  const { isMobile } = useContext(DeviceContext);
  const [open, setOpen] = useState<boolean>(false);
  const theme = useTheme();
  const history = useHistory();
  const [customNotification, setCustomNotification] = useState<AppNotification>();
  const [openVerifyPhoneDialog, setOpenVerifyPhoneDialog] = useState<boolean>(false);
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
  const { reCaptchaRef, getReCaptchaToken } = useRecaptcha();

  useEffect(() => {
    if (notification) {
      const { message, options } = notification;
      if (options?.action) {
        setCustomNotification(notification);
        setOpen(true);
      } else {
        enqueueSnackbar(message, {
          variant: options?.variant,
          anchorOrigin: options?.anchorOrigin,
          persist: options?.permanent,
        });
      }
      hideNotification();
    }
  }, [notification]);

  const getAction = async (type: string | null | undefined) => {
    const recaptcha_token = await getReCaptchaToken();

    switch (type) {
      case 'verify_email':
        try {
          await sendEmailVerificationRequest({ userId, recaptcha_token });
          setOpen(false);
          showNotification({ message: 'Verification link has been sent', options: { variant: 'success' } });
        } catch (e) {
          handleServerError(e);
        }
        break;
      case 'subscription':
        history.push('/subscriptions?dialog=true');
        setOpen(false);
        break;
      case 'verify_phone':
        try {
          await sendVerificationSMSRequest({ recaptcha_token });
          setOpen(false);
          setOpenVerifyPhoneDialog(true);
        } catch (e) {
          handleServerError(e);
        }
        break;
    }
  };

  return (
    <>
      <Dialog
        container={document.getElementById(MAIN_CONTAINER_ID)}
        fullScreen={isMobile}
        open={open}
        onClose={() => setOpen(false)}>
        <DialogTitle style={{ color: theme.palette.primary.main }}>
          {customNotification?.message}
        </DialogTitle>

        <DialogContent>
          <Box dangerouslySetInnerHTML={{ __html: customNotification?.description || '' }} />
        </DialogContent>

        <DialogActions>
          <Button size="large" variant="outlined" onClick={() => setOpen(false)}>
            OK
          </Button>
          {customNotification?.options?.action?.buttonText && (
            <Button
              size="large"
              onClick={() => getAction(customNotification?.options?.action?.type)}
              color="primary"
              variant="contained"
              disabled={!recaptchaLoaded}>
              {customNotification.options.action.buttonText}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {verifyPhone && (
        <Dialog
          fullScreen={isMobile}
          open={openVerifyPhoneDialog}
          onClose={() => setOpenVerifyPhoneDialog(false)}>
          <Box padding={4}>
            <VerifyPhone close={() => setOpenVerifyPhoneDialog(false)} verifyPhone={verifyPhone} />
          </Box>
        </Dialog>
      )}
      <ReCAPTCHAComponent ref={reCaptchaRef} setRecaptchaLoaded={setRecaptchaLoaded} />
    </>
  );
};
