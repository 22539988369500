import React from 'react';

import ShowMoreText from 'react-show-more-text';
import { Box, useTheme } from '@material-ui/core';

import { Testimonial } from '../../../../types';
import { TestimonialAuthor } from './TestimonialAuthor';

interface Props {
  testimonialItem: Testimonial;
}

export const TestimonialItem: React.FC<Props> = ({ testimonialItem }) => {
  const theme = useTheme();
  const { testimonial, author_company, author_name, author_position, photo } = testimonialItem;

  return (
    <Box>
      <Box marginBottom={1}>
        <TestimonialAuthor
          avatar={photo.url || null}
          author={author_name}
          position={author_position}
          company={author_company}
        />
      </Box>

      <ShowMoreText
        lines={2}
        more="Show more"
        less="Show less"
        className="show-more-text-content-testimonial"
        anchorClass={theme.palette.type === 'dark' ? 'my-anchor-css-class-dark' : 'my-anchor-css-class'}
        expanded={false}
        truncatedEndingComponent={'... '}>
        "{testimonial}"
      </ShowMoreText>
    </Box>
  );
};
