import React, { useCallback, useMemo } from 'react';

import { Box, Typography, Button } from '@material-ui/core';
import clsx from 'clsx';
import { AiOutlineEye } from 'react-icons/ai';
import { BiCloudDownload, BiPencil } from 'react-icons/bi';

import { pdfType, controlTypes } from '../config';
import { useStyles } from '../styles';
import { monochrome } from '../../../theme/styles';

interface Props {
  fileName: string;
  type: 'pdf' | 'img';
  editMode?: boolean;
  customControlSet?: Array<keyof typeof controlTypes>;
  openPreviewDialog: (event: React.MouseEvent<HTMLButtonElement>) => void;
  openUploadWindow: () => void;
  downloadPdf: () => void;
}

interface PreviewControlButtonProps {
  id: keyof typeof controlTypes;
  icon: JSX.Element;
  bgColor: string;
  callback: (arg0: React.MouseEvent<HTMLButtonElement>, arg1: keyof typeof controlTypes) => void;
}

const previewControlButtonSettings = {
  id: controlTypes.preview,
  icon: <AiOutlineEye size="16px" color={monochrome.mediumdark} />,
  bgColor: monochrome.lighter,
};

const downloadControlButtonSettings = {
  id: controlTypes.download,
  icon: <BiCloudDownload size="16px" color="#005BD4" />,
  bgColor: '#E3FEFF',
};

const uploadControlButtonSettings = {
  id: controlTypes.upload,
  icon: <BiPencil size="16px" color="#610BEF" />,
  bgColor: '#EBECFE',
};

const mappedButtonSettings = {
  [controlTypes.download]: downloadControlButtonSettings,
  [controlTypes.preview]: previewControlButtonSettings,
  [controlTypes.upload]: uploadControlButtonSettings,
};

const defaultSetOfPdf = [controlTypes.download, controlTypes.preview, controlTypes.upload];
const defaultSetOfImg = [controlTypes.preview, controlTypes.upload];

const OptionButton = ({ id, icon, bgColor, callback }: PreviewControlButtonProps) => {
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    callback(event, id);
  };

  return (
    <Button
      startIcon={icon}
      className={classes.buttonWrapper}
      style={{ backgroundColor: bgColor }}
      onClick={handleClick}
    />
  );
};

export const ControlComponent = ({
  fileName,
  type,
  editMode = false,
  customControlSet,
  openPreviewDialog,
  openUploadWindow,
  downloadPdf,
}: Props) => {
  const classes = useStyles();

  const onClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, type: keyof typeof controlTypes) => {
      if (type === controlTypes.preview) {
        openPreviewDialog(event);
      }
      if (type === controlTypes.download) {
        downloadPdf();
      }
      if (type === controlTypes.upload) {
        openUploadWindow();
      }
    },
    [openPreviewDialog, openUploadWindow, downloadPdf]
  );

  const options = useMemo(() => {
    if (customControlSet?.length) {
      return customControlSet.map(item => ({ ...mappedButtonSettings[item], callback: onClick }));
    }
    return type === pdfType
      ? defaultSetOfPdf.map(item => ({ ...mappedButtonSettings[item], callback: onClick }))
      : defaultSetOfImg.map(item => ({ ...mappedButtonSettings[item], callback: onClick }));
  }, [customControlSet, type, onClick]);

  return (
    <Box
      className={clsx(classes.hiddenControlBlock, 'hiddenControlBlock', {
        [classes.showingHiddenControlBlock]: editMode,
      })}>
      {fileName && (
        <Box className={classes.fileNameTextContainer}>
          <Typography style={{ fontSize: '13px' }}>{fileName}</Typography>
        </Box>
      )}
      <Box display="flex" gridGap={8}>
        {(options as PreviewControlButtonProps[]).map(item => (
          <OptionButton key={item.id} {...item} />
        ))}
      </Box>
    </Box>
  );
};
