import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Box } from '@material-ui/core';

import { ControlledTagAutocomplete } from '@vyce/core/src/components/controlled-inputs';
import { AppTextField } from '@vyce/core/src/components/inputs';
import { CheckerOptionField } from '@vyce/core/src/types';

import { rules } from '../config';

interface Props {
  listedPresetFields: CheckerOptionField[];
}

export const ListedQualificationForm = ({ listedPresetFields }: Props) => {
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext();

  register('scheme_id', {
    validate: value => {
      if (value?.uuid === '-1') {
        return 'Select a value from the list';
      }
    },
  });

  return (
    <>
      {listedPresetFields &&
        listedPresetFields?.map(fieldItem => (
          <Box key={fieldItem.name}>
            {fieldItem.options?.length ? (
              <ControlledTagAutocomplete
                name={fieldItem.name}
                items={fieldItem.options}
                label={fieldItem.display_name}
                rules={rules}
              />
            ) : (
              <Controller
                control={control}
                rules={rules}
                name={fieldItem.name}
                render={({ field }) => (
                  <AppTextField
                    {...field}
                    id={fieldItem.name}
                    error={!!errors[fieldItem.name]?.message}
                    value={field.value}
                    label={fieldItem.display_name}
                    helperText={(errors[fieldItem.name]?.message as string) || ''}
                    fullWidth
                  />
                )}
              />
            )}
          </Box>
        ))}
    </>
  );
};
