import { KeyboardEvent, useContext, useEffect, useMemo, useState } from 'react';

import { useHistory } from 'react-router-dom';
import uniq from 'lodash/uniq';
import { useForm } from 'react-hook-form';

import { DeviceContext, UniversalInviteContext } from '@vyce/core/src/contexts';
import { Team, TimeAndAttendanceLocation, UniversalInvite } from '@vyce/core/src/types';
import { SUBCONTRACTOR_TYPES } from '@vyce/core/src/constants';

import { useBooleanState, useQuery } from '../../../hooks';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Form {
  email: string;
  phone: string;
}

const defaultAllOption = { uuid: 'all', name: 'All' };

export const useUniversalInviteData = () => {
  const methods = useForm<Form>({
    mode: 'all',
    defaultValues: {
      email: '',
      phone: '',
    },
  });
  const { handleServerError } = useContext(NotificationContext);
  const [emails, setEmails] = useState<string[]>([]);
  const [phones, setPhones] = useState<string[]>([]);
  const [expandedModule, setExpandedModule] = useState<string | null>(null);
  const [isSuccessDialogOpen, openSuccessDialog, closeSuccessDialog] = useBooleanState(false);
  const [selectedTeamIds, setSelectedTeamIds] = useState<string[]>([]);
  const [selectedPayScheduleIds, setSelectedPayScheduleIds] = useState<string[]>([]);
  const [selectedLocationIds, setSelectedLocationIds] = useState<string[]>([]);
  const [selectedSubcontractorType, setSelectedSubcontractorType] = useState<string>('');
  const [selectedShiftIds, setSelectedShiftIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const query = useQuery();
  const history = useHistory();
  const {
    reset,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = methods;
  const { isMobile } = useContext(DeviceContext);

  const {
    open,
    teams,
    paySchedules,
    locations,
    shifts,
    token,
    isLegend = false,
    companyId,
    disabledContactInput = false,
    onClose,
    createInvitesRequest,
  } = useContext(UniversalInviteContext);

  const payrollModuleActive = !!paySchedules?.length;
  const timeModuleActive = !!shifts?.length;
  const teamsModuleActive = !!teams?.length;
  const enrichedTeams = useMemo(() => [defaultAllOption as Team, ...(teams ?? [])], [teams]);
  const enrichedLocation = useMemo(
    () => [defaultAllOption as TimeAndAttendanceLocation, ...(locations ?? [])],
    [locations]
  );

  const disableInviteButton =
    (!selectedPayScheduleIds?.length && !selectedTeamIds?.length && !selectedLocationIds?.length) ||
    loading ||
    (!!selectedPayScheduleIds?.length && !selectedSubcontractorType);
  const selectedLocations = locations?.filter(loc => selectedLocationIds.includes(loc.uuid || '')) || [];

  const resetForm = () => {
    reset();
    setExpandedModule(null);
    setEmails([]);
    setPhones([]);
    clearPaySelections();
    clearTeamsSelections();
    clearTimeSelections();
    if (query.has('invite')) {
      clearInviteQuery();
    }
  };
  const handleClose = () => {
    onClose();
    resetForm();
  };

  const clearTeamsSelections = () => {
    setSelectedTeamIds([]);
  };

  const clearPaySelections = () => {
    setSelectedPayScheduleIds([]);
    setSelectedSubcontractorType('');
  };

  const clearTimeSelections = () => {
    setSelectedLocationIds([]);
    setSelectedShiftIds([]);
  };

  const clearInviteQuery = () => {
    history.push({ pathname: history.location.pathname });
  };

  const addEmailToList = async () => {
    await trigger('email');
    const email = getValues('email');
    if (errors?.email?.message || !email) {
      return;
    }
    setEmails(value => [...value, email]);
    setValue('email', '');
  };

  const addPhoneToList = async () => {
    await trigger('phone');
    const phone = getValues('phone');
    if (errors?.phone?.message || !phone) {
      return;
    }
    setPhones(value => [...value, phone]);
    setValue('phone', '');
  };

  const handleEmailFieldKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addEmailToList();
    }
  };

  const handlePhoneFieldKeyDown = async (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addPhoneToList();
    }
  };

  const deleteContact = (contact: string) => {
    setPhones(value => value.filter(i => i !== contact));
    setEmails(value => value.filter(i => i !== contact));
  };

  const handleTeamSelect = (id: string, checked: boolean) => {
    let preparedIds = prepareSelectedIds(selectedTeamIds, id, checked);
    // select 'all' option
    if (id === defaultAllOption.uuid) {
      setSelectedTeamIds(checked ? enrichedTeams?.map(item => item.uuid) ?? [] : []);
      return;
    }
    // if all options was selected manually set to 'all' option checked status
    if (teams?.every(item => preparedIds.includes(item.uuid))) {
      setSelectedTeamIds(enrichedTeams?.map(item => item.uuid));
      return;
    }
    //reset 'all' option
    if (selectedTeamIds.includes(defaultAllOption.uuid) && id !== defaultAllOption.uuid) {
      preparedIds = preparedIds.filter(item => item !== defaultAllOption.uuid);
    }

    setSelectedTeamIds(preparedIds);
  };

  const handlePayScheduleSelect = (id: string, checked: boolean) => {
    setSelectedPayScheduleIds(value => prepareSelectedIds(value, id, checked));
  };

  const handleLocationSelect = (id: string, checked: boolean) => {
    let preparedIds = prepareSelectedIds(selectedLocationIds, id, checked);
    // select 'all' option
    if (id === defaultAllOption.uuid) {
      setSelectedLocationIds(checked ? (enrichedLocation?.map(item => item.uuid) as string[]) ?? [] : []);
      return;
    }

    // if all options was selected manually set to 'all' option checked status
    if (locations?.every(item => preparedIds.includes(item.uuid as string))) {
      setSelectedLocationIds(enrichedLocation?.map(item => item.uuid as string));
      return;
    }

    //reset 'all' option
    if (selectedLocationIds.includes(defaultAllOption.uuid) && id !== defaultAllOption.uuid) {
      preparedIds = preparedIds.filter(item => item !== defaultAllOption.uuid);
    }

    setSelectedLocationIds(preparedIds);
  };

  const handleShiftSelect = (id: string, checked: boolean) => {
    setSelectedShiftIds(value => prepareSelectedIds(value, id, checked));
  };

  const prepareSelectedIds = (arr: string[], id: string, checked: boolean) => {
    if (checked) {
      return uniq([...arr, id]);
    }
    return arr.filter(item => item !== id);
  };

  const handleTypeChange = (type: SUBCONTRACTOR_TYPES, checked: boolean) => {
    setSelectedSubcontractorType(checked ? type : '');
  };

  const prepareInviteData = (): UniversalInvite => {
    const payload: UniversalInvite = {
      pay: [],
      teams: [],
      time: [],
    };
    selectedPayScheduleIds.forEach(id => {
      emails.forEach(email => {
        payload.pay.push({
          email,
          pay_schedule_id: id,
          pay_scheme: selectedSubcontractorType,
        });
      });
      phones.forEach(phone => {
        payload.pay.push({
          phone,
          pay_schedule_id: id,
          pay_scheme: selectedSubcontractorType,
        });
      });
    });
    selectedTeamIds.forEach(id => {
      if (id === 'all') {
        return;
      }
      emails.forEach(email => {
        payload.teams.push({
          email,
          team_id: id,
        });
      });
      phones.forEach(phone => {
        payload.teams.push({
          phone,
          team_id: id,
        });
      });
    });
    selectedLocationIds.forEach(siteId => {
      const site = locations?.find(item => item.uuid === siteId);
      selectedShiftIds.forEach(shiftId => {
        if (!!site?.shifts?.find(shift => shift.uuid === shiftId)) {
          emails.forEach(email => {
            payload.time.push({
              email,
              site_id: siteId,
              shift_id: shiftId,
            });
          });
          phones.forEach(phone => {
            payload.time.push({
              phone,
              site_id: siteId,
              shift_id: shiftId,
            });
          });
        }
      });
    });
    return payload;
  };

  const invite = async () => {
    try {
      setLoading(true);
      const payload = prepareInviteData();
      await createInvitesRequest({ token, companyId, payload });
      openSuccessDialog();
      setLoading(false);
      handleClose();
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  };

  const collapseAllModules = () => {
    setExpandedModule(null);
  };

  useEffect(() => {
    const email = query.get('email');
    const phone = query.get('phone');
    if (phone) {
      setValue('phone', phone);
      return;
    }
    if (email) {
      setValue('email', email);
    }
  }, [query, setValue]);

  return {
    loading,
    isLegend,
    isMobile,
    expandedModule,
    emails,
    phones,
    open,
    disabledContactInput,
    disableInviteButton,
    methods,
    isSuccessDialogOpen,
    timeModuleOptions: {
      selectedShiftIds,
      selectedLocationIds,
      timeModuleActive,
      selectedLocations,
      locations: enrichedLocation,
      handleShiftSelect,
      handleLocationSelect,
      clearTimeSelections,
    },
    payModuleOptions: {
      selectedPayScheduleIds,
      payrollModuleActive,
      paySchedules,
      selectedSubcontractorType,
      handlePayScheduleSelect,
      clearPaySelections,
      handleTypeChange,
    },
    teamModuleOptions: {
      teams: enrichedTeams,
      selectedTeamIds,
      teamsModuleActive,
      clearTeamsSelections,
      handleTeamSelect,
    },
    closeSuccessDialog,
    setExpandedModule,
    collapseAllModules,
    deleteContact,
    addPhoneToList,
    addEmailToList,
    handleEmailFieldKeyDown,
    handlePhoneFieldKeyDown,
    handleClose,
    invite,
  };
};
