import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@material-ui/core';

import { AppChip } from '../../../components';
import useStyles from '../styles';

interface Props {
  contacts: string[];
  limit: number;
  deleteContact: Function;
  moreClickCallback: Function;
}

export const ContactList: React.FC<Props> = ({ contacts, limit, deleteContact, moreClickCallback }) => {
  const classes = useStyles();
  const [contactsToDisplay, setContactsToDisplay] = useState<string[]>([]);
  const [showAll, setShowAll] = useState<boolean>(false);
  const more = contacts.length - limit;

  const handleMoreClick = () => {
    if (!showAll) {
      moreClickCallback();
    }
    setShowAll(value => !value);
  };

  useEffect(() => {
    const toDisplay = showAll ? contacts : [...contacts].splice(0, limit);
    setContactsToDisplay(toDisplay);
  }, [contacts, showAll]);

  return (
    <Box display="flex" flexWrap="wrap" alignItems="center" marginTop={3} marginBottom={1} gridGap={12}>
      {contactsToDisplay.map((contact, index) => (
        <Box key={contact + index}>
          <AppChip label={contact} onDelete={() => deleteContact(contact)} />
        </Box>
      ))}

      {more > 0 && (
        <Typography onClick={handleMoreClick} className={classes.more}>
          {showAll ? 'less' : `+ ${more} more`}
        </Typography>
      )}
    </Box>
  );
};
