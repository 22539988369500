import React from 'react';

import { TimeLogsByShift } from '@vyce/core/src/views/time/TimeLogsByShift/index';

import { useTypedSelector } from '../../hooks';

export const EmployerTimeLogsByShift: React.FC = () => {
  const { selectedCompany } = useTypedSelector(state => state.helper);

  return <TimeLogsByShift companyId={selectedCompany?.uuid} />;
};
