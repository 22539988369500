import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  approveButton: {
    width: 140,
  },
  alreadyApproved: {
    width: 140,
    fontSize: 16,
    fontWeight: 600,
  },
  exportButton: {
    width: 95,
  },
}));
