import { useEffect, useRef, useState } from 'react';

import L, { FeatureGroup, LatLngLiteral } from 'leaflet';
import { EditControl } from 'react-leaflet-draw';
import * as GeoJSON from 'geojson';
import { useTheme } from '@material-ui/core';

import { getLocationDetailsByCoordinates } from '../../../../utils/location';

interface Props {
  setValue?: Function;
  polygon?: GeoJSON.Polygon | null;
}

export const useDrawableMap = ({ setValue, polygon }: Props) => {
  const theme = useTheme();
  const editRef = useRef<any>(null);
  const ref = useRef<FeatureGroup>(null);
  const [drawing, setDrawing] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);

  const toggleDrawing = () => {
    if (drawing) {
      setDrawing(false);
      disableDrawing();
    } else {
      setDrawing(true);
      enableDrawing();
    }
  };

  const handleChange = () => {
    const polygon = getPolygonData();
    if (polygon) {
      setValue?.('polygon', polygon);
      editRef?.current?._toolbars?.draw?._modes?.polygon?.handler?.completeShape();
      disableDrawing();
    }
  };

  const getPolygonData = (): GeoJSON.Geometry | null => {
    const geo = ref.current?.toGeoJSON();
    const features = (geo as GeoJSON.FeatureCollection<GeoJSON.Geometry, any>)?.features || [];
    return features[0] ? features[0].geometry : null;
  };

  const onMountedRect = (e: EditControl) => {
    editRef.current = e;
  };

  const handlePositionChange = async (position: LatLngLiteral) => {
    try {
      const address = await getLocationDetailsByCoordinates(position);
      setValue?.('address', address);
    } catch (e) {
      console.error(e);
    }
  };

  const onDeleteLastPointClick = () => {
    editRef?.current?._toolbars?.draw?._modes?.polygon?.handler?.deleteLastVertex();
  };

  const onClearClick = () => {
    disableDrawing();
    clearPolygon();
    setDrawing(true);
    setTimeout(() => enableDrawing());
  };

  const disableDrawing = () => {
    editRef?.current?._toolbars?.draw?._modes?.polygon?.handler?.disable();
  };

  const enableDrawing = () => {
    editRef?.current?._toolbars?.draw?._modes?.polygon?.handler?.enable();
  };

  const disableEditing = () => {
    editRef?.current?._toolbars?.edit?._modes?.edit?.handler?.disable();
  };

  const enableEditing = () => {
    editRef?.current?._toolbars?.edit?._modes?.edit?.handler?.enable();
  };

  const saveEditing = () => {
    editRef?.current?._toolbars?.edit?._modes?.edit?.handler?.save();
    const polygon = getPolygonData();
    setValue?.('polygon', polygon);
  };

  const enableRemoving = () => {
    editRef?.current?._toolbars?.edit?._modes?.remove?.handler?.enable();
  };

  const disableRemoving = () => {
    editRef?.current?._toolbars?.edit?._modes?.remove?.handler?.disable();
  };

  const clearPolygon = () => {
    enableRemoving();
    editRef?.current?._toolbars?.edit?._modes?.remove?.handler?.removeAllLayers();
    disableRemoving();
    setValue?.('polygon', null);
  };

  const whenCreated = (map: any) => {
    if (ref?.current && ref.current?.getLayers().length === 0 && polygon) {
      const geojson: GeoJSON.FeatureCollection = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: polygon,
          },
        ],
      };

      L.geoJSON(geojson).eachLayer(layer => {
        if (layer instanceof L.Polygon) {
          layer.setStyle({ color: theme.palette.primary.main });
          ref.current?.addLayer(layer);
        }
      });
    }
  };

  useEffect(() => {
    if (drawing && editRef?.current) {
      enableDrawing();
    }

    if (!drawing && editRef?.current) {
      disableDrawing();
      clearPolygon();
    }
  }, [drawing, editRef]);

  useEffect(() => {
    if (editing && editRef?.current) {
      enableEditing();
    }

    if (!editing && editRef?.current) {
      saveEditing();
    }
  }, [editRef, editing]);

  return {
    onDeleteLastPointClick,
    ref,
    handlePositionChange,
    onMountedRect,
    handleChange,
    editRef,
    onClearClick,
    disableEditing,
    enableEditing,
    whenCreated,
    drawing,
    editing,
    setEditing,
    toggleDrawing,
    clearPolygon,
  };
};
