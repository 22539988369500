import React, { useContext, useEffect, useState } from 'react';

import { Avatar, Box, Typography, useTheme } from '@material-ui/core';
import dayjs from 'dayjs';
import { useHistory, useParams } from 'react-router-dom';

import { TimePeriod, TimeWorker } from '@vyce/core/src/types';
import { getAvatar } from '@vyce/core/src/utils/getAvatar';
import { BackTo, EmptyList, PageLoading } from '@vyce/core/src/components';
import { getUrlItems } from '@vyce/core/src/utils/url';
import { GetTimePeriodsRequestPayload } from '@vyce/core/src/api/types';
import { getTimePeriodsRequest, getTimeWorkerRequest } from '@vyce/core/src/api/time';
import config from '@vyce/core/src/assets/config';
import { useQuery } from '@vyce/core/src/hooks';
import { formatDate } from '@vyce/core/src/utils/dates';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import { ClockInOutPeriod } from './components';

interface Props {
  companyId?: string;
  canViewLocation?: boolean;
}

export const DailyActivityComponent: React.FC<
  Props & { userId: string; date: string; shiftId: string; siteName: string; withControls?: boolean }
> = ({ companyId, canViewLocation, userId, date, shiftId, siteName, withControls = true }) => {
  const theme = useTheme();
  const { handleServerError } = useContext(NotificationContext);
  const [worker, setWorker] = useState<TimeWorker>();
  const [loading, setLoading] = useState<boolean>(false);
  const [periods, setPeriods] = useState<TimePeriod[]>([]);
  const history = useHistory();

  const getData = async () => {
    if (!companyId) {
      return;
    }
    try {
      setLoading(true);
      const endDate = formatDate(dayjs(date).add(1, 'day').format());

      const payload: GetTimePeriodsRequestPayload = {
        user_id: userId,
        shift_id: shiftId,
        start_date: date,
        end_date: endDate,
      };
      const workerRes = await getTimeWorkerRequest(userId, companyId);
      const periodsRes = await getTimePeriodsRequest({ companyId, payload });
      const items: TimePeriod[] = periodsRes.data.items || [];
      setPeriods(items.filter(item => !item.unpaid_break));
      setWorker(workerRes.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  };

  const back = () => {
    history.goBack();
  };

  useEffect(() => {
    if (shiftId) {
      getData();
    }
  }, [userId, companyId, shiftId, date]);

  if (loading) {
    return (
      <Box height="100%">
        <PageLoading />
      </Box>
    );
  }

  if (!worker || !periods?.length) {
    return (
      <Box height="calc(100vh - 200px)" display="flex" alignItems="center" justifyContent="center">
        <EmptyList title="No data found..." image={config.icons3D.lightning} />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" gridGap={16}>
      {withControls && (
        <Box margin="-22px -40px 0 -40px">
          <BackTo backTo={{ text: 'Logs', back }} />
          <Box
            display="flex"
            alignItems="center"
            paddingRight={5}
            paddingLeft={5}
            paddingBottom={3}
            bgcolor={theme.palette.background.paper}>
            <Avatar src={worker?.avatar || getAvatar(worker?.gender ?? null)} />
            <Box marginLeft={2}>
              <Typography variant="h6">
                {worker?.first_name} {worker?.last_name}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}

      <Box display="flex" flexDirection="column" gridGap={24}>
        {periods.map((period, index) => (
          <ClockInOutPeriod
            canViewLocation={canViewLocation}
            companyId={companyId}
            key={'period' + index}
            period={period}
            worker={worker}
            userId={worker.user_id}
            siteName={siteName}
            shiftId={shiftId}
          />
        ))}
      </Box>
    </Box>
  );
};

export const DailyActivity: React.FC<Props> = props => {
  const { userId, date, siteId } = useParams<{ userId: string; date: string; siteId: string }>();
  const query = useQuery();
  const { id: workerId, additionalId: shift_id = '' } = getUrlItems(userId);
  const siteIdUrlItems = getUrlItems(siteId);

  const shiftId = query.get('shiftId') ?? shift_id;

  return (
    <DailyActivityComponent
      {...props}
      userId={workerId}
      date={date}
      shiftId={shiftId}
      siteName={siteIdUrlItems.name}
    />
  );
};
