import dayjs from 'dayjs';

import { DailySchedule, Shift, ShiftSchedule } from '@vyce/core/src/types';

import { TransformedShift, TimestampType } from '../types';

const days: (keyof ShiftSchedule)[] = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export const getCurrentDay = () => {
  return days[new Date().getDay()];
};

export const defineDateInTheRange = ({
  startHour,
  endHour,
  value,
}: {
  startHour: number;
  endHour: number;
  value?: string;
}) => {
  return dayjs(value).isAfter(dayjs().hour(startHour)) && dayjs(value).isBefore(dayjs().hour(endHour));
};

export const filterShifts = (shifts: Shift[], timestamp: TimestampType): TransformedShift[] => {
  const date = timestamp === 'now' ? new Date().toString() : timestamp?.toString();

  const currentDay = getCurrentDay();

  return shifts
    .map(item => {
      const regularSchedule: DailySchedule = item.regular.schedule[currentDay];
      const overtimeSchedule: DailySchedule = item.overtime.schedule[currentDay];

      return { id: item.uuid ?? '', name: item.name, regular: regularSchedule, overtime: overtimeSchedule };
    })
    .filter(item => item.regular.start && item.regular.end && item.id)
    .map(item => {
      return {
        ...item,
        inRange: defineDateInTheRange({
          startHour: parseInt(item.regular.start.split(':')[0], 10),
          endHour: parseInt(item.regular.end.split(':')[0], 10),
          value: date,
        }),
      };
    })
    .sort((a, b) => Number(b.inRange) - Number(a.inRange));
};
