import { createContext } from 'react';
import { DetailType, DragItem } from '../types';

interface Context {
  findCard: (
    id: string,
    type: DetailType
  ) => {
    field: DragItem;
    index: number;
  };
  moveCard: (dragId: string, hoverIndex: number, type: DetailType) => void;
  restoreData: () => void;
  handleUpdateFields: (type: DetailType) => void;
  changeCustomFieldName: (id: string, value: boolean, type: DetailType) => void;
}

export const FieldsFunctionsContext = createContext({} as Context);

export const FieldsFunctionsProvider = FieldsFunctionsContext.Provider;
