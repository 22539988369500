import React, { useContext, useEffect } from 'react';

import { Autocomplete } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Typography } from '@material-ui/core';
import { FiChevronDown } from 'react-icons/fi';
import { IoClose } from 'react-icons/io5';
import debounce from 'lodash/debounce';

import { Employee } from '@vyce/core/src/types';
import { AppTextField } from '@vyce/core/src/components/inputs/AppTextField';
import { appAutocompleteUseStyles } from '@vyce/core/src/components/controlled-inputs/ControlledAutocomplete';
import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { IsVerified } from '@vyce/core/src/components/IsVerified';

interface Props {
  employee?: Partial<Employee>;
  employees: Partial<Employee>[];
  loading?: boolean;
  onSearch: (substring: string) => void;
  margin?: any;
  label?: string;
  rules?: any;
}

export const SearchEmployeeAutocomplete: React.FC<Props> = ({
  employee,
  employees,
  onSearch,
  loading,
  margin,
  label = 'Employees',
  rules = { required: 'Employee is required' },
}) => {
  const classes = appAutocompleteUseStyles();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { isMobile } = useContext(DeviceContext);

  const handleSearch = debounce((event: any) => {
    const value = event.target.value;
    onSearch(value);
  }, 500);

  useEffect(() => {
    onSearch('');
  }, []);

  return (
    <Controller
      control={control}
      name="employee_id"
      rules={rules}
      render={props => (
        <Autocomplete
          onChange={(e, newValue) => {
            props.field.onChange(newValue?.uuid);
          }}
          fullWidth
          disablePortal={isMobile}
          popupIcon={<FiChevronDown size="25px" color="#A0A3BD" />}
          closeIcon={<IoClose size="25px" color="#A0A3BD" />}
          defaultValue={employee}
          noOptionsText="Not Found"
          classes={classes}
          getOptionSelected={(option, value) => option.ni_number === value.ni_number}
          getOptionLabel={option => `${option.first_name} ${option.last_name}`}
          options={employees}
          filterOptions={options => options}
          renderOption={option => (
            <Box display="flex" cy-test-id="search-employee" width="100%" justifyContent="space-between">
              <Typography variant="subtitle2">
                {option.first_name} {option.last_name} {option.ni_number && `(${option.ni_number})`}
              </Typography>
              <Box display="flex">
                <Box display="flex" alignItems="center">
                  <Typography variant="caption">RTW - </Typography>
                  <IsVerified verified={!!option?.rtw_verified} />
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography variant="caption">ID - </Typography>
                  <IsVerified verified={!!option?.id_verified} />
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography variant="caption">CIS - </Typography>
                  <IsVerified verified={!!option?.cis_verified} />
                </Box>
              </Box>
            </Box>
          )}
          loading={loading}
          renderInput={params => (
            <AppTextField
              {...params}
              margin={margin}
              error={!!errors.employee_id?.message}
              helperText={(errors.employee_id?.message as string) || ''}
              onChange={handleSearch}
              placeholder="Start typing"
              label={label}
              cy-test-id="search-employees"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <Box position="absolute" right={80} top={18}>
                        <CircularProgress color="secondary" size={20} />
                      </Box>
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};
