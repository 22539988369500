import React from 'react';

import { TimeSettings } from '@vyce/core/src/views/time/TimeSettings';

import { useTypedSelector } from '../../hooks';

export const EmployerTimeSettings: React.FC = () => {
  const { selectedCompany } = useTypedSelector(state => state.helper);

  return <TimeSettings companyId={selectedCompany?.uuid} />;
};
