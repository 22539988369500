import React, { useContext } from 'react';

import { Box, Typography, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import { LocationSelect } from './components/LocationSelect';
import { useStyles } from './styles';
import { DEFAULT_LOCATION } from './constants';
import { SelectAnotherPlaceDialog } from './components/SelectAnotherPlaceDialog';
import { AppSwitch } from '../../../components/AppSwitch';
import { TemperatureLineChart } from './components/TemperatureLineChart';
import { ChanceOfRainBarChart } from './components/ChanceOfRainBarChart';
import { TemperatureText } from './components/TemperatureText';
import { useWeatherWidgetData } from './hooks/useWeatherWidgetData';
import { monochrome } from '../../../theme/styles';
import { DeviceContext } from '../../../contexts';
import { RainAndWind } from './components/RainAndWind';

export const WeatherWidget: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const borderColor = theme.palette.type === 'dark' ? monochrome.dark : monochrome.mediumlight;
  const {
    handleDayClick,
    openSelectAnotherPlaceDialog,
    locations,
    selectedLocation,
    handleMenuItemClick,
    dayWeatherLoading,
    dayWeather,
    isRainChart,
    setIsRainChart,
    getWeatherDescription,
    chanceOfRainChartData,
    temperatureChartData,
    forecastLoading,
    dailyForecast,
    selectedDay,
    isSelectAnotherPlaceDialogOpen,
    closeSelectAnotherPlaceDialog,
    handleAnotherPlaceSelect,
    handleChevronClick,
    deleteLocation,
    selectedDayIndex,
  } = useWeatherWidgetData();
  const { isMobile } = useContext(DeviceContext);

  return (
    <>
      <Box position="relative" width="100%" height="100%">
        <Box className={classes.locationSelectWrapper}>
          <LocationSelect
            deleteLocation={deleteLocation}
            handleSelectAnotherPlaceItemClick={openSelectAnotherPlaceDialog}
            menuItems={locations}
            selectedLocationName={selectedLocation?.name || DEFAULT_LOCATION.name}
            handleMenuItemClick={handleMenuItemClick}
          />
        </Box>

        {dayWeatherLoading ? (
          <Skeleton className={classes.skeleton} variant="rect" width="100%" height={232} />
        ) : (
          <Box
            borderRadius={8}
            width="100%"
            height={isMobile ? 189 : 232}
            display="flex"
            flexDirection="column"
            bgcolor={theme.palette.background.default}>
            {!dayWeather && (
              <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                <Typography color="textSecondary" variant="h6">
                  Weather Service is not available...
                </Typography>
              </Box>
            )}
            {dayWeather && (
              <>
                <Box
                  flex={1}
                  padding="12px"
                  display="flex"
                  flexDirection={isMobile ? 'column' : 'row'}
                  justifyContent={isMobile ? 'unset' : 'space-between'}>
                  {isMobile && (
                    <Box textAlign="center" marginBottom="18px" position="relative">
                      {!!selectedDayIndex && (
                        <Box onClick={() => handleChevronClick('left')} position="absolute" left={0} top={0}>
                          <FiChevronLeft
                            size="19px"
                            color={theme.palette.type === 'dark' ? monochrome.light : monochrome.dark}
                          />
                        </Box>
                      )}

                      <Typography className={classes.weekDay}>{dayWeather.time}</Typography>

                      {selectedDayIndex !== 6 && (
                        <Box
                          onClick={() => handleChevronClick('right')}
                          position="absolute"
                          right={0}
                          top={0}>
                          <FiChevronRight
                            size="19px"
                            color={theme.palette.type === 'dark' ? monochrome.light : monochrome.dark}
                          />
                        </Box>
                      )}
                    </Box>
                  )}

                  <Box
                    padding="10px"
                    justifyContent={isMobile ? 'center' : 'unset'}
                    marginBottom={1}
                    display="flex">
                    <Box width={isMobile ? 73 : 58} height={isMobile ? 73 : 58}>
                      <img
                        src={getWeatherDescription(dayWeather.weatherCode).icon}
                        alt="icon"
                        height={isMobile ? 73 : 58}
                      />
                    </Box>

                    <Box marginLeft={2}>
                      <Box
                        display="flex"
                        flexDirection={isMobile ? 'column' : 'row'}
                        alignItems="center"
                        marginBottom={1}>
                        <Box width={40} marginRight={isMobile ? 0 : 3}>
                          <TemperatureText value={dayWeather.currentTemperature} />
                        </Box>

                        <Typography variant="h6">
                          {getWeatherDescription(dayWeather.weatherCode).label}
                        </Typography>
                      </Box>

                      {!isMobile && (
                        <RainAndWind
                          precipitationProbability={dayWeather.precipitationProbability}
                          windSpeed={dayWeather.windSpeed}
                        />
                      )}
                    </Box>
                  </Box>

                  {isMobile && (
                    <RainAndWind
                      precipitationProbability={dayWeather.precipitationProbability}
                      windSpeed={dayWeather.windSpeed}
                    />
                  )}

                  {!isMobile && (
                    <Box display="flex" alignItems="flex-end" flexDirection="column">
                      <Box marginBottom={1}>
                        <AppSwitch
                          width={255}
                          labelOffset={17}
                          checked={isRainChart}
                          variant="paper"
                          size="small"
                          onChange={e => setIsRainChart(e.target.checked)}
                          leftLabel="Temperature"
                          rightLabel="Chance of rain"
                        />
                      </Box>

                      <Typography className={classes.weekDay}>{dayWeather.time}</Typography>
                    </Box>
                  )}
                </Box>

                {!isMobile && (
                  <Box flex={1}>
                    <Box height={100}>
                      {isRainChart ? (
                        <ChanceOfRainBarChart data={chanceOfRainChartData} />
                      ) : (
                        <TemperatureLineChart data={temperatureChartData} />
                      )}
                    </Box>
                  </Box>
                )}
              </>
            )}
          </Box>
        )}

        {forecastLoading ? (
          <Skeleton
            className={classes.skeleton}
            style={{ marginTop: 16 }}
            variant="rect"
            width="100%"
            height={126}
          />
        ) : (
          <Box
            borderRadius={8}
            marginTop={2}
            width="100%"
            height={126}
            display="flex"
            className={classes.daisContainer}
            style={{ cursor: 'pointer' }}
            bgcolor={theme.palette.background.default}>
            {dailyForecast.map((item, index) => (
              <Box
                flex={1}
                key={item.weekDay + index}
                padding={1}
                onClick={() => handleDayClick(item)}
                display="flex"
                border={`2px solid ${
                  item.time === selectedDay?.time || (!selectedDay && index === 0)
                    ? borderColor
                    : 'transparent'
                }`}
                flexDirection="column"
                alignItems="center"
                borderRadius="8px"
                height="100%">
                <Box minHeight={48} marginBottom={0.5}>
                  <img src={getWeatherDescription(item.weatherCode).icon} alt="icon" />
                </Box>

                <TemperatureText value={item.temperatureMax} />

                <Typography className={classes.weekDay}>{item.weekDay}</Typography>
              </Box>
            ))}
          </Box>
        )}
      </Box>

      <SelectAnotherPlaceDialog
        handleSubmit={handleAnotherPlaceSelect}
        open={isSelectAnotherPlaceDialogOpen}
        onClose={closeSelectAnotherPlaceDialog}
      />
    </>
  );
};
