import React, { useContext, useEffect, useMemo, useState } from 'react';

import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  createStyles,
  Theme,
  DialogContentText,
} from '@material-ui/core';
import { TbSend } from 'react-icons/tb';

import { DeviceContext } from '../../../contexts';
import { EmailTemplate, SMSTemplate } from '../../../types';
import { MAIN_CONTAINER_ID } from '../../../constants';
import { useBooleanState } from '../../../hooks';
import {
  getEmailTemplatesRequest,
  getSMSTemplatesRequest,
  sendEmailRequest,
  sendSMSRequest,
} from '../../../api/legend/notifications';
import { AppCheckboxRow, AppSwitch, ButtonTitleWithLoading } from '../../../components';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  userId: string;
  userName: string;
}

const DOCUMENT_EMAIL_TEMPLATE_IDS = [
  'd-55641ba16af74f4586377a25116935e6',
  'd-5082a6a8c6d049619fee9c82a4ff72d6',
  'd-e2f2d7ed9c3b4fe3a4075c66273d6104',
];
const DOCUMENT_SMS_IDS = ['9dbd0ac8-acdf-43f1-bfd4-b831e26f0c24', '4cf4b058-48e5-4069-96cf-77ffe889af11'];

export const MessageUserAboutDocs: React.FC<Props> = ({ userId, userName }) => {
  const classes = useStyles();
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const { isMobile } = useContext(DeviceContext);
  const [isOpen, setOpenTrue, setOpenFalse] = useBooleanState(false);
  const [isSMS, setIsSMS] = useState<boolean>(false);
  const [loading, setLoadingTrue, setLoadingFalse] = useBooleanState(false);
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);
  const [smsTemplates, setSMSTemplates] = useState<SMSTemplate[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplate | SMSTemplate | null>(null);

  const templates = useMemo(
    () => (isSMS ? smsTemplates : emailTemplates),
    [emailTemplates, smsTemplates, isSMS]
  );

  const getEmailTemplates = async () => {
    try {
      const res = await getEmailTemplatesRequest();
      const items: EmailTemplate[] = res.data.items;
      setEmailTemplates(items.filter(item => DOCUMENT_EMAIL_TEMPLATE_IDS.includes(item.template_id)));
    } catch (e) {
      handleServerError(e);
    }
  };

  const getSMSTemplates = async () => {
    try {
      const res = await getSMSTemplatesRequest();
      const items: SMSTemplate[] = res.data.items;
      setSMSTemplates(items.filter(item => DOCUMENT_SMS_IDS.includes(item.uuid || '')));
    } catch (e) {
      handleServerError(e);
    }
  };

  const onSend = async () => {
    if (!selectedTemplate?.uuid) {
      return;
    }
    try {
      setLoadingTrue();
      const payload = {
        users: [userId],
        templateId: selectedTemplate.uuid,
      };
      if (isSMS) {
        await sendSMSRequest(payload);
      } else {
        await sendEmailRequest(payload);
      }
      closeDialog();
      setLoadingFalse();
      showNotification({
        message: `${isSMS ? 'SMS' : 'Email'} has been sent`,
        options: { variant: 'success' },
      });
    } catch (e) {
      setOpenFalse();
      setLoadingFalse();
      handleServerError(e);
    }
  };

  const handleCheckboxChange = (template: EmailTemplate | SMSTemplate, checked: boolean) => {
    setSelectedTemplate(checked ? template : null);
  };

  const closeDialog = () => {
    setOpenFalse();
    setSelectedTemplate(null);
  };

  useEffect(() => {
    if (isOpen && !emailTemplates?.length) {
      getEmailTemplates();
    }
  }, [isOpen, emailTemplates]);

  useEffect(() => {
    if (isSMS && !smsTemplates?.length) {
      getSMSTemplates();
    }
  }, [isSMS, smsTemplates]);

  return (
    <>
      <Button onClick={setOpenTrue} startIcon={<TbSend />} color="secondary" variant="contained">
        Message user
      </Button>

      <Dialog
        container={document.getElementById(MAIN_CONTAINER_ID)}
        fullScreen={isMobile}
        maxWidth="md"
        disableScrollLock
        open={isOpen}
        onClose={closeDialog}>
        <DialogTitle>Message {userName}</DialogTitle>

        <DialogContent>
          <Box display="flex" justifyContent="center" marginTop={1} marginBottom={2}>
            <AppSwitch
              width={isMobile ? 300 : 500}
              size="small"
              labelOffset={isMobile ? 40 : 90}
              checked={isSMS}
              onChange={() => setIsSMS(value => !value)}
              leftLabel="Send Email"
              rightLabel="Send SMS"
            />
          </Box>
          <DialogContentText>
            Selected {!isSMS ? 'email' : 'SMS'} template will be sent to the user.
          </DialogContentText>

          <Box height={166}>
            <Box marginTop={3} className={classes.container} minWidth={!isMobile && 500} minHeight={110}>
              {templates.map((template, index) => (
                <AppCheckboxRow
                  key={index}
                  last={index === templates.length - 1}
                  handleChange={e => handleCheckboxChange(template, e.target.checked)}
                  label={template.name}
                  checked={selectedTemplate?.uuid === template.uuid}></AppCheckboxRow>
              ))}
            </Box>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button size="large" variant="outlined" onClick={closeDialog}>
            Cancel
          </Button>

          <Button style={{ width: 160 }} size="large" onClick={onSend} color="primary" variant="contained">
            <ButtonTitleWithLoading title="Send message" loaderVariant="paper" loading={loading} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '8px',
      flex: 1,
    },
  })
);
