import React, { useMemo, useState } from 'react';

import { Box, Button, FormControlLabel, Paper, Typography } from '@material-ui/core';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';


import { AppCustomTable, ButtonTitleWithLoading } from '@vyce/core/src/components';
import {
  AddTimeWorkerData,
  CurrentRates,
  Shift,
  TimeAndAttendanceLocation,
  TimeWorker,
  TimeWorkerShift,
} from '@vyce/core/src/types';
import { AppCheckbox } from '@vyce/core/src/components/inputs';
import {
  addSiteWorkerRequest,
  deleteShiftWorkerRequest,
  updateSiteWorkerRequest,
} from '@vyce/core/src/api/time';

import { useStyles } from '../styles';
import { RateCell } from './components';

const DEFAULT_RATES: CurrentRates = {
  basic: {
    unit: 'hour',
    rate: null,
  },
  overtime: {
    unit: 'hour',
    rate: null,
  },
};

interface Props {
  locations: TimeAndAttendanceLocation[];
  shits: TimeWorkerShift[];
  companyId?: string;
  worker: TimeWorker | undefined;
  getUser: () => Promise<void>;
}

export const LocationsAndShiftsSettings: React.FC<Props> = ({
  locations,
  companyId,
  worker,
  shits,
  getUser,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [shitsToEdit, setShiftToEdit] = useState<TimeWorkerShift[]>(shits);

  const isChanged = useMemo(() => {
    return !isEqual(
      sortBy(
        worker?.shifts?.map(item => ({ shift: item.shift, current_rates: item.current_rates })),
        'shift_id'
      ),
      sortBy(
        shitsToEdit?.map(item => ({ shift: item.shift, current_rates: item.current_rates })),
        'shift_id'
      )
    );
  }, [worker, shitsToEdit]);

  const handleShiftCheckboxChange = (checked: boolean, shift?: TimeWorkerShift) => {
    if (!shift) {
      return;
    }
    setShiftToEdit(value => {
      if (checked) {
        return [...value, shift];
      }
      return value.filter(item => item.shift.shift_id !== shift.shift.shift_id);
    });
  };

  const handleRateChange = ({
    amount,
    type,
    shiftId,
  }: {
    amount: string;
    type: 'basic' | 'overtime';
    shiftId: string;
  }) => {
    setShiftToEdit(value => {
      return value.map(item => {
        if (item.shift.shift_id === shiftId) {
          return {
            ...item,
            current_rates: {
              ...item.current_rates,
              [type]: {
                // @ts-ignore
                ...item.current_rates[type],
                amount: +amount === 0 ? null : +amount,
              },
            },
          };
        }
        return item;
      });
    });
  };

  const columns = useMemo(
    () => [
      {
        name: 'name',
        title: 'Location',
        flex: 0.3,
      },
      {
        name: 'shifts',
        title: 'Shift',
        flex: 0.3,
        renderCell: (row: any) => (
          <Box display="flex" flexDirection="column" width="100%" gridGap={12}>
            {row.shifts?.map((shift: Shift) => (
              <Box height={56} key={shift.uuid} display="flex" alignItems="center">
                <FormControlLabel
                  classes={{ label: classes.tableCheckboxLabel }}
                  control={
                    <AppCheckbox
                      checked={!!shitsToEdit.find(item => item.shift.shift_id === shift.uuid)}
                      onChange={(e, checked) =>
                        handleShiftCheckboxChange(checked, {
                          company_id: companyId,
                          shift: {
                            shift_id: shift.uuid as string,
                            name: shift.name,
                          },
                          site: {
                            site_id: row.uuid as string,
                            name: row.name,
                          },
                          current_rates: DEFAULT_RATES,
                        })
                      }
                      variant="rectangle"
                      color="primary"
                    />
                  }
                  label={shift.name}
                />
              </Box>
            ))}
          </Box>
        ),
      },
      {
        name: 'rate',
        title: 'Basic Hourly Rate (£)',
        flex: 0.4,
        renderCell: (row: any) => (
          <Box display="flex" flexDirection="column" width="100%" gridGap={12}>
            {row.shifts?.map((shift: any, index: number) => (
              <RateCell
                key={'basic-rate' + shift.uuid + index}
                disabled={!shitsToEdit.find(item => item.shift.shift_id === shift.uuid)}
                rateType="basic"
                label="Basic Hourly Rate (£)"
                shiftId={shift.uuid}
                onChange={handleRateChange}
                defaultValue={
                  shitsToEdit.find(item => item.shift.shift_id === shift.uuid)?.current_rates?.basic
                    ?.amount || ''
                }
              />
            ))}
          </Box>
        ),
      },
      {
        name: 'rate',
        title: 'Overtime Hourly Rate (£)',
        flex: 0.4,
        renderCell: (row: any) => (
          <Box display="flex" flexDirection="column" width="100%" gridGap={12}>
            {row.shifts?.map((shift: any, index: number) => (
              <RateCell
                key={'overtime-rate' + shift.uuid + index}
                disabled={!shitsToEdit.find(item => item.shift.shift_id === shift.uuid)}
                rateType="overtime"
                label="OT Hourly Rate (£)"
                onChange={handleRateChange}
                shiftId={shift.uuid}
                defaultValue={
                  shitsToEdit.find(item => item.shift.shift_id === shift.uuid)?.current_rates?.overtime
                    ?.amount || ''
                }
              />
            ))}
          </Box>
        ),
      },
    ],
    [shitsToEdit, companyId]
  );

  const handeSave = async () => {
    if (!companyId) {
      return;
    }
    setLoading(true);
    try {
      for (const shiftToEdit of shitsToEdit) {
        const currentShit = worker?.shifts?.find(item => item.shift.shift_id === shiftToEdit.shift.shift_id);
        if (worker) {
          const timeWorker: AddTimeWorkerData = {
            user_id: worker.user_id,
            basic_amount: shiftToEdit.current_rates?.basic?.amount || null,
            basic_unit: shiftToEdit.current_rates?.basic?.unit,
            overtime_amount: shiftToEdit.current_rates?.overtime?.amount || null,
            overtime_unit: shiftToEdit.current_rates?.overtime?.unit,
          };

          if (!currentShit) {
            await addSiteWorkerRequest({
              companyId,
              siteId: shiftToEdit.site.site_id,
              shiftId: shiftToEdit.shift.shift_id,
              worker: timeWorker,
            });
          }
          if (currentShit && !isEqual(currentShit.current_rates, shiftToEdit.current_rates)) {
            await updateSiteWorkerRequest({
              companyId,
              siteId: shiftToEdit.site.site_id,
              shiftId: shiftToEdit.shift.shift_id,
              worker: timeWorker,
            });
          }
        }
      }

      for (const shift of worker?.shifts || []) {
        if (worker && !shitsToEdit?.find(item => item.shift.shift_id === shift.shift.shift_id)) {
          await deleteShiftWorkerRequest(
            companyId,
            shift.site.site_id,
            shift.shift.shift_id,
            worker?.user_id
          );
        }
      }

      await getUser();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  return (
    <Box>
      <Paper className={classes.paper} variant="outlined">
        <Typography variant="h6">Locations & Shifts</Typography>

        <Box marginTop={2} marginBottom={2} display="flex" flexDirection="column" gridGap={8}>
          <Typography className={classes.subtitle}>
            Define which locations this team member can clock in and clock out and then associate them to the
            relevant shift.
          </Typography>
          <Typography className={classes.subtitle}>
            Define the rate that this member should be allocated to for their time worked. You can associate
            a single rate or specific rates for specific locations and shifts. You will need to define the
            number of working hours that you define as regular hours that make up a normal work
            day/week/month.
          </Typography>
        </Box>

        <AppCustomTable columns={columns} data={locations} />

        <Box marginTop={2} display="flex" justifyContent="flex-end">
          <Button
            style={{ width: 150 }}
            onClick={() => handeSave()}
            disabled={!isChanged || loading}
            size="large"
            variant="contained"
            color="primary">
            <ButtonTitleWithLoading title="Save changes" loaderVariant="primary" loading={loading} />
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};
