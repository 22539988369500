import { Tag, TagGroups } from '../types';
import { SEARCH_STATUSES } from '../constants';

export const setFormValues = (targetObject: any, setValue: Function, oldValues: any) => {
  if (!oldValues || !targetObject) {
    return;
  }

  Object.keys(oldValues).forEach(key => {
    let value: any = targetObject[key] || '';
    if (key === 'date_of_birth') {
      value = targetObject[key] ? new Date(targetObject[key]) : null;
    }
    if (key === 'roles') {
      value = targetObject?.tags?.filter((tag: Tag) => tag.group === TagGroups.ROLE);
    }
    if (key === 'qualifications') {
      value = targetObject?.tags?.filter((tag: Tag) => tag.group === TagGroups.QUALIFICATION);
    }
    if (key === 'skills') {
      value = targetObject?.tags?.filter((tag: Tag) => tag.group === TagGroups.SKILL);
    }
    if (key === 'search_status') {
      value = SEARCH_STATUSES.find(status => status.group === value);
    }
    if (key === 'address') {
      value = targetObject[key]?.address_snippet || targetObject[key];
    }
    if (value) {
      setValue(key, value);
    }
  });
};
