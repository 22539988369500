import React, { useEffect, useMemo, useState } from 'react';

import {
  CompanyStep,
  CongratsDialog,
  GovernmentGatewayStep,
  HMRCContacts,
  HMRCSettingsStep,
  HMRCSettingsTypeStep,
  NotificationStep,
  PayFrequencyStep,
  PaymentDayStep,
  PayrollFeeStep,
  PayScheduleNameStep,
  SenderStep,
  StartPaymentDateStep,
  UploadAgreementStep,
} from '@vyce/core/src/views/payroll/components';
import { ActiveOnboardingStep, Company, OnboardingStep } from '@vyce/core/src/types';
import { AppOnboarding, ActivateSubscriptionDialog } from '@vyce/core/src/components';
import { BankDetailsStep } from '@vyce/core/src/components/onboarding';

import { HMRC_SETTINGS_TYPE } from '../../constants';

import { usePayrollOnboardingData } from './hooks';
import { PayrollProps } from './types';

const payScheduleSteps: OnboardingStep[] = [
  {
    name: 'HMRCSettingsTypeStep',
    component: <HMRCSettingsTypeStep />,
  },
  {
    name: 'PayFrequencyStep',
    component: <PayFrequencyStep />,
  },
  {
    name: 'PaymentDayStep',
    component: <PaymentDayStep />,
  },
  {
    name: 'StartPaymentDateStep',
    component: <StartPaymentDateStep />,
  },
  {
    name: 'NotificationStep',
    component: <NotificationStep />,
  },
  {
    name: 'PayScheduleNameStep',
    component: <PayScheduleNameStep />,
  },
];

export const initialStep: ActiveOnboardingStep = {
  step: payScheduleSteps[0],
  number: 0,
};

const getHmrcDetailsSteps = (company?: Company): OnboardingStep[] => [
  {
    name: 'HMRCSettingsStep',
    component: <HMRCSettingsStep selectedCompany={company} />,
  },
  {
    name: 'GovernmentGatewayStep',
    component: <GovernmentGatewayStep />,
  },
  {
    name: 'SenderStep',
    component: <SenderStep companyName={company?.name} />,
  },
  {
    name: 'BankDetailsStep',
    component: (
      <BankDetailsStep title="In order to produce BACS for you, we need the bank details you will be using to process your payments." />
    ),
  },
  {
    name: 'HMRCContacts',
    component: <HMRCContacts />,
  },
];

export const PayrollOnboarding = ({
  selectedPosition,
  token,
  user,
  subscribed,
  wasSubscribed,
  setPosition,
  companyRegistrationRequest,
  createPayScheduleRequest,
  uploadPayScheduleContractWrapperRequest,
  updateSelectedCompanySubscription,
}: PayrollProps) => {
  const [onboardingSteps, setOnboardingSteps] = useState<OnboardingStep[]>(payScheduleSteps);

  const {
    company,
    loading,
    isCongratsDialogOpen,
    childRef,
    hmrcSettings,
    hmrcSettingsType,
    methods,
    isActivateSubscriptionDialogOpen,
    setCloseCongratsDialog,
    handleCompanyChange,
    setAgreement,
    handleNext,
    handleCloseSubscriptionDialog,
    subscribe,
    activeStep,
    setActiveStep,
  } = usePayrollOnboardingData({
    selectedPosition,
    token,
    user,
    subscribed,
    onboardingSteps: onboardingSteps.length,
    companyRegistrationRequest,
    createPayScheduleRequest,
    uploadPayScheduleContractWrapperRequest,
    setPosition,
    updateSelectedCompanySubscription,
  });

  const hmrcDetailsSteps = useMemo(() => getHmrcDetailsSteps(company), [company]);

  useEffect(() => {
    const steps = [...payScheduleSteps];
    const isCompanySettings = hmrcSettingsType === HMRC_SETTINGS_TYPE.COMPANY_SETTINGS;
    if (isCompanySettings && !user.is_superuser) {
      steps.push({
        component: <UploadAgreementStep setAgreement={setAgreement} />,
        name: 'UploadAgreementStep',
      });
    }
    if (!isCompanySettings && !user.is_superuser) {
      steps.push({
        component: <PayrollFeeStep />,
        name: 'PayrollFeeStep',
      });
    }
    if (isCompanySettings && !hmrcSettings) {
      steps.push(...hmrcDetailsSteps);
    }
    if (user.is_superuser) {
      steps.splice(1, 0, {
        component: <CompanyStep onCompanyChange={handleCompanyChange} />,
        name: 'CompanyStep',
      });
    }
    setOnboardingSteps(steps);
  }, [user, hmrcSettingsType, hmrcSettings]);

  return (
    <>
      <AppOnboarding
        currentMessage=""
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        methods={methods}
        handleNext={handleNext}
        steps={onboardingSteps}
        loading={loading}
        ref={childRef}
      />

      <CongratsDialog open={isCongratsDialogOpen} onClose={setCloseCongratsDialog} />

      <ActivateSubscriptionDialog
        open={isActivateSubscriptionDialogOpen}
        onClose={handleCloseSubscriptionDialog}
        callback={subscribe}
        subscriptionTextMode="self"
        wasSubscribed={wasSubscribed}
      />
    </>
  );
};
