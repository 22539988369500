import { useContext, useEffect, useMemo, useState } from 'react';

import { useBooleanState } from '@vyce/core/src/hooks';
import { TIME_INTERFACE_PERMISSIONS, TopPerformingHours, TopPerformingWorker } from '@vyce/core/src/types';
import { getTopPerformingTeamMembersLatWeekRequest } from '@vyce/core/src/api/time';

import { Props } from '../types';
import { defaultWidgetHours } from '../config';
import { NotificationContext } from '../../../../contexts/notificationContext';

export const useWidgetData = ({ selectedCompanyId, userPermissions }: Props) => {
  const [loading, setLoadingTrue, setLoadingFalse] = useBooleanState(true);
  const [topPerformingWorkers, setTopPerformingWorkers] = useState<TopPerformingWorker[]>([]);
  const [topPerformingHours, setTopPerformingHours] = useState<TopPerformingHours>(defaultWidgetHours);
  const { handleServerError } = useContext(NotificationContext);

  const cnaViewWorker = useMemo(
    () => (userPermissions?.length ? userPermissions.includes(TIME_INTERFACE_PERMISSIONS.USERS) : true),
    [userPermissions]
  );

  const fetchData = async () => {
    if (!selectedCompanyId) return;
    setLoadingTrue();

    try {
      const { data } = await getTopPerformingTeamMembersLatWeekRequest({
        companyId: selectedCompanyId,
      });

      const { top_performing_workers, ...restData } = data;

      setTopPerformingWorkers(top_performing_workers);
      setTopPerformingHours({ ...defaultWidgetHours, ...restData });

      setLoadingFalse();
    } catch (e) {
      handleServerError(e);
      setLoadingFalse();
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedCompanyId]);

  return {
    loading,
    topPerformingWorkers,
    topPerformingHours,
    cnaViewWorker,
  };
};
