import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: 600,
    },

    smallText: {
      fontSize: '13px !important',
      lineHeight: '26px',
    },
  })
);
