import React, { useContext, useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { Box, Button, Fade, Typography, useTheme } from '@material-ui/core';

import { AuthFormValues } from '@vyce/core/src/types';
import { DeviceContext } from '@vyce/core/src/contexts';
import { useQuery, useRecaptcha } from '@vyce/core/src/hooks';
import { getAuthPage } from '@vyce/core/src/utils';
import { sendEmailResetPasswordRequest } from '@vyce/core/src/api/auth';
import { EmailField, PasswordField, PhoneNumberField } from '@vyce/core/src/components/controlled-inputs';
import { ButtonTitleWithLoading, Logo, ReCAPTCHAComponent } from '@vyce/core/src/components';

import useStyles from '../styles';
import { ForgotPasswordDialog } from './ForgotPasswordDialog';
import { formatPhone } from './Register';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  userLogin: Function;
  loading?: boolean;
  title?: string;
  subtitle?: string;
  redirectUrl: string;
  hideSignUpLink?: boolean;
}

export const Login: React.FC<Props> = ({
  userLogin,
  loading,
  title = 'Welcome to Vyce',
  subtitle,
  redirectUrl,
  hideSignUpLink = false,
}) => {
  const classes = useStyles();
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const theme = useTheme();
  const { isDesktop, isMobile } = useContext(DeviceContext);
  const [onlyMobile, setOnlyMobile] = useState<boolean>(isMobile);
  const { reCaptchaRef, getReCaptchaToken } = useRecaptcha();
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
  const query = useQuery();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const methods = useForm<AuthFormValues>({
    defaultValues: {
      email: '',
      password_1: '',
      phone: '',
      rememberMe: false,
    },
  });
  const { setValue, watch } = methods;
  const email = watch('email');

  const onSubmit = async (formData: AuthFormValues) => {
    const recaptcha_token = await getReCaptchaToken();

    await userLogin({
      username: onlyMobile ? formatPhone(formData.phone) : formData.email,
      password: formData.password_1,
      redirectUrl,
      recaptcha_token,
      handleServerError,
      showNotification,
    });
  };

  const forgotPasswordSubmit = async ({ email }: { email: string }) => {
    try {
      const recaptcha_token = await getReCaptchaToken();

      const res = await sendEmailResetPasswordRequest({
        email,
        recaptcha_token,
      });
      showNotification({ message: res.data.message, options: { variant: 'success' } });
      setOpen(false);
    } catch (e) {
      handleServerError(e);
    }
  };

  const resetPhone = () => {
    setValue('phone', '');
  };

  useEffect(() => {
    const mobile = !!query.get('mobile');
    setOnlyMobile(mobile);
  }, [query]);
  return (
    <Fade in>
      <>
        <Box display="flex" justifyContent="center">
          <Logo height={48} width={171} />
        </Box>

        <Box>
          <Typography variant="h5" style={{ marginTop: theme.spacing(3), fontWeight: 700 }}>
            {title}
          </Typography>
          {subtitle && (
            <Typography className={classes.subtitle} color="textSecondary" variant="subtitle1">
              {subtitle}
            </Typography>
          )}

          <FormProvider {...methods}>
            <form className={classes.form} onSubmit={methods.handleSubmit(onSubmit)}>
              {onlyMobile ? (
                <PhoneNumberField name="phone" label="Mobile Number" autoFocus={isDesktop} margin="normal" />
              ) : (
                <EmailField margin="normal" autoFocus={isDesktop} />
              )}

              <PasswordField
                name="password_1"
                margin="normal"
                label="Password"
                rules={{ required: 'Password is required' }}
              />

              <Box display="flex" marginTop={1} alignItems="center">
                <Link
                  className={classes.helpLink}
                  onClick={() => setOpen(true)}
                  style={{ fontSize: '14px' }}
                  to="#">
                  Forgot password?
                </Link>
              </Box>

              <ReCAPTCHAComponent ref={reCaptchaRef} setRecaptchaLoaded={setRecaptchaLoaded} />

              <Box marginTop={3}>
                <Button
                  disabled={loading || !recaptchaLoaded}
                  fullWidth
                  type="submit"
                  cy-test-id="login"
                  variant="contained"
                  color="primary">
                  <ButtonTitleWithLoading title="Login" loaderVariant="paper" loading={!!loading} />
                </Button>
              </Box>

              <Box marginBottom={1} marginTop={1}>
                {!onlyMobile ? (
                  <Link
                    cy-test-id="login-with-phone"
                    onClick={resetPhone}
                    className={classes.switchLink}
                    to={`${history.location.pathname}?mobile=true`}>
                    Or login with your mobile number
                  </Link>
                ) : (
                  <Link onClick={resetPhone} className={classes.switchLink} to={history.location.pathname}>
                    Or login with your email
                  </Link>
                )}
              </Box>

              {!hideSignUpLink && (
                <Box display="flex" marginTop={3}>
                  Don't have an account?
                  <Box marginLeft="4px" fontWeight={600}>
                    <Link
                      className={classes.helpLink}
                      to={getAuthPage(history.location.pathname, '/signup')}>
                      Sign Up
                    </Link>
                  </Box>
                </Box>
              )}
            </form>
          </FormProvider>
        </Box>
        {/*TODO Implement when backend is ready*/}
        {/*<Box marginTop={4}>*/}
        {/*  <SocialButtons title="Login" />*/}
        {/*</Box>*/}

        <ForgotPasswordDialog email={email} open={open} setOpen={setOpen} onSubmit={forgotPasswordSubmit} />
      </>
    </Fade>
  );
};
