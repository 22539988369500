import React, { useState } from 'react';

import { Box, createStyles, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useFormContext } from 'react-hook-form';

import { OnboardingStepTitle } from '../../../../components';
import { ShiftTime } from '../../../../components/controlled-inputs/ShiftTime';

const useStyles = makeStyles(() =>
  createStyles({
    popoverClass: {
      marginLeft: '40px',
      marginTop: '28px',
    },
  })
);

export const OvertimeScheduleStep: React.FC = () => {
  const classes = useStyles();
  const { setValue } = useFormContext();
  const startTimeName = 'shift.overtime.startTime';
  const endTimeName = 'shift.overtime.endTime';
  const [disabled, setDisabled] = useState<boolean>(false);

  const handleNoHoursClick = () => {
    setDisabled(value => !value);
    setValue(startTimeName, null);
    setValue(endTimeName, null);
  };

  return (
    <>
      <OnboardingStepTitle
        popoverClass={classes.popoverClass}
        helpText="Team members that ‘check-out’ during these hours will be allocated overtime hours at your defined overtime hourly rate."
        text="Would you like to add Overtime hours to this shift?"
      />

      <ShiftTime disabled={disabled} name={startTimeName} label="Select Start Time" margin="normal" />

      <ShiftTime disabled={disabled} name={endTimeName} label="Select End Time" margin="normal" />

      <Box display="flex" justifyContent="flex-end" marginTop={2}>
        <Button
          size="small"
          style={{ width: 155 }}
          color="primary"
          onClick={handleNoHoursClick}
          variant={disabled ? 'contained' : 'outlined'}>
          No overtime hours
        </Button>
      </Box>
    </>
  );
};
