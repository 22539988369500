import { createContext } from 'react';
import { BackToData } from '../types';

interface Context {
  backTo: BackToData | null;
  setBackTo: Function;
}

export const SubscriptionContext = createContext({ backTo: null, setBackTo: () => {} } as Context);

export const SubscriptionProvider = SubscriptionContext.Provider;
