import { fetchAddressDataByCoordinatesRequest, fetchAddressDataRequest } from '../api/geocode';
import { Address, Shift, Site, TimeAndAttendanceLocation } from '../types';
import L, { LatLngLiteral } from 'leaflet';
import markerLight from '../assets/svg/marker.svg';
import markerDark from '../assets/svg/marker-dark-mode.svg';
import { siteToLocation } from '../modules/timeModule/utils';

interface GetLocationDetails {
  fullAddress: string;
  street?: string;
  houseNumber?: string;
  town?: string;
  postCode?: string;
}

export const getLocationDetails = async ({
  fullAddress,
  street,
  houseNumber,
  town,
  postCode,
}: GetLocationDetails): Promise<Address> => {
  const result = await fetchAddressDataRequest(fullAddress);
  const details = parseGoogleMapResult(result);

  return {
    ...details,
    address_line_1: houseNumber || details.address_line_1,
    address_line_2: street || details.address_line_2,
    address_line_3: town || details.address_line_3,
    address_line_4: postCode || details.address_line_4,
    address_snippet: fullAddress || details.address_snippet,
  };
};

const parseGoogleMapResult = (result: any, position?: LatLngLiteral): Address => {
  const details = result.data?.results[0];
  const addressComponents = details?.address_components || [];
  const fullAddress = details?.formatted_address;
  const geometryLocation = position || details?.geometry?.location;
  let postCode: string = '';
  let country: string = '';
  let town = '';
  let street = '';
  let houseNumber = '';
  addressComponents.forEach((component: any) => {
    if (component.types?.includes('postal_code')) {
      postCode = component.long_name;
    }
    if (component.types?.includes('street_number')) {
      houseNumber = component.long_name;
    }
    if (component.types?.includes('route')) {
      street = component.long_name;
    }
    if (component.types?.includes('country')) {
      country = component.long_name;
    }
    if (component.types?.includes('locality')) {
      town = component.long_name;
    }
    if (component.types?.includes('postal_town')) {
      town = component.long_name;
    }
  });

  return {
    address_line_1: houseNumber,
    address_line_2: street,
    address_line_3: town,
    address_line_4: postCode,
    address_snippet: fullAddress,
    country,
    lon: geometryLocation?.lng,
    lat: geometryLocation?.lat,
  };
};

export const getLocationDetailsByCoordinates = async (position: LatLngLiteral): Promise<Address> => {
  const result = await fetchAddressDataByCoordinatesRequest(position.lat, position.lng);
  return parseGoogleMapResult(result, position);
};

export const getMarkerIcon = (isDarkTheme: boolean) =>
  new L.Icon({
    iconUrl: isDarkTheme ? markerDark : markerLight,
    iconRetinaUrl: isDarkTheme ? markerDark : markerLight,
    popupAnchor: [-0, -0],
    iconSize: [60, 60],
  });

export const siteConverter = (sites: Site[]) => {
  let locations: TimeAndAttendanceLocation[] = [];
  let shifts: Shift[] = [];
  locations = sites?.filter((item: Site) => !item.deleted_at)?.map((item: Site) => siteToLocation(item));
  locations.forEach((loc: TimeAndAttendanceLocation) =>
    shifts.push(...(loc?.shifts?.filter((item: Shift) => !item.deleted_at) || []))
  );

  return { locations, shifts };
};
