import React from 'react';

import { ActivateSubscriptionDialog } from '@vyce/core/src/components';
import { Invites } from '@vyce/core/src/modules';
import { deleteInviteRequest, getInvitesRequest, resendInviteRequest } from '@vyce/core/src/api/invites';

import { useTypedSelector, useInviteButton } from 'src/hooks';
import { UniversalInviteModule } from 'src/modules';

export const EmployerInviteList: React.FC = () => {
  const { selectedCompany } = useTypedSelector(state => state.helper);

  const {
    isInviteFormOpen,
    subscribeLoading,
    isActivateSubscriptionDialogOpen,
    simpleSubscribe,
    setCloseInviteForm,
    handleInviteButtonClick,
    closeActivateSubscriptionDialog,
  } = useInviteButton();

  return (
    <>
      <Invites
        selectedCompanyId={selectedCompany?.uuid}
        handleInviteButtonClick={handleInviteButtonClick}
        deleteInviteRequest={deleteInviteRequest}
        getInvitesRequest={getInvitesRequest}
        resendInviteRequest={resendInviteRequest}
      />
      <UniversalInviteModule isOpen={isInviteFormOpen} onClose={setCloseInviteForm} />
      <ActivateSubscriptionDialog
        open={isActivateSubscriptionDialogOpen}
        onClose={closeActivateSubscriptionDialog}
        callback={simpleSubscribe}
        loading={subscribeLoading}
        wasSubscribed={selectedCompany?.subscribed_company}
      />
    </>
  );
};
