import React, { useContext, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Paper,
  RadioGroup,
  Typography,
} from '@material-ui/core';

import useStyles from '../styles';
import { EmployeePaySchedule } from '../../../types';
import { changeEmployeePaySchemeRequest, getEmployeePaySchedulesRequest } from '../../../api/legend/pay';
import { getUKFormattedDate } from '../../../utils/dates';
import { AppRadioButton } from '../../../components/inputs';
import {
  CIS_SELF_EMPLOYED_LABEL,
  MAIN_CONTAINER_ID,
  SELF_EMPLOYED_LABEL,
  SUBCONTRACTOR_TYPES,
} from '../../../constants';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  token: string;
  employeeId: string;
}

export const AssociatedPaySchedules: React.FC<Props> = ({ token, employeeId }) => {
  const classes = useStyles();
  const { handleServerError } = useContext(NotificationContext);
  const [open, setOpen] = useState<boolean>(false);
  const [paySchedules, setPaySchedules] = useState<EmployeePaySchedule[]>([]);
  const [selectedPayScheme, setSelectedPayScheme] = useState<string>('');
  const [selectedPaySchedule, setSelectedPaySchedule] = useState<EmployeePaySchedule | undefined>();

  const getPaySchedules = async () => {
    try {
      const res = await getEmployeePaySchedulesRequest(token, employeeId);
      setPaySchedules(res.data.items);
    } catch (e) {
      handleServerError(e);
    }
  };

  const changePayScheme = async () => {
    if (!selectedPaySchedule?.pay_schedule || !selectedPayScheme) {
      return;
    }
    try {
      await changeEmployeePaySchemeRequest(
        token,
        selectedPaySchedule.pay_schedule.pay_schedule_id,
        employeeId,
        selectedPayScheme
      );
      setOpen(false);
      getPaySchedules();
    } catch (e) {
      handleServerError(e);
    }
  };

  useEffect(() => {
    getPaySchedules();
  }, []);

  const getPaySchemeLabel = (scheme: string) => {
    switch (scheme) {
      case SUBCONTRACTOR_TYPES.SELF_EMPLOYED:
        return SELF_EMPLOYED_LABEL;
      case SUBCONTRACTOR_TYPES.CIS_SELF_EMPLOYED:
        return CIS_SELF_EMPLOYED_LABEL;
    }
  };

  const handleEditClick = (paySchedule: EmployeePaySchedule) => {
    setOpen(true);
    setSelectedPayScheme(paySchedule.pay_scheme);
    setSelectedPaySchedule(paySchedule);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPayScheme('');
  };

  const handleRadioChange = (e: any) => {
    setSelectedPayScheme(e.target.value);
  };

  return (
    <>
      <Box marginRight={1} marginBottom={2}>
        <Typography className={classes.tabTitle}>Associated Payroll Pay Schedules</Typography>
      </Box>

      <Paper className={classes.formPaper} variant="outlined">
        <Grid container spacing={1}>
          <>
            <Grid item md={4} xs={6}>
              <Typography variant="subtitle2">Company</Typography>
            </Grid>
            <Grid item md={3} xs={6}>
              <Typography variant="subtitle2">Start Date</Typography>
            </Grid>
            <Grid item md={5} xs={12}>
              <Typography variant="subtitle2">Type of Engagement</Typography>
            </Grid>
          </>
          {paySchedules.map(schedule => (
            <React.Fragment key={schedule.pay_schedule.pay_schedule_id}>
              <Grid className={classes.gridItem} item md={4} xs={6}>
                {schedule.company.name}
              </Grid>
              <Grid className={classes.gridItem} item md={3} xs={6}>
                {getUKFormattedDate(schedule.start_date)}
              </Grid>
              <Grid className={classes.gridItem} item md={5} xs={12}>
                <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
                  {getPaySchemeLabel(schedule.pay_scheme)}

                  <Button
                    onClick={() => handleEditClick(schedule)}
                    variant="contained"
                    color="primary"
                    size="small">
                    Edit
                  </Button>
                </Box>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Paper>

      <Dialog container={document.getElementById(MAIN_CONTAINER_ID)} open={open} onClose={handleClose}>
        <DialogTitle>How is this person engaged?</DialogTitle>
        <DialogContent>
          <RadioGroup aria-label="gender" value={selectedPayScheme} onChange={handleRadioChange}>
            <FormControlLabel
              value={SUBCONTRACTOR_TYPES.CIS_SELF_EMPLOYED}
              control={
                <AppRadioButton style={{ width: '100%' }} color="primary" name={CIS_SELF_EMPLOYED_LABEL} />
              }
              label=""
            />
            <FormControlLabel
              value={SUBCONTRACTOR_TYPES.SELF_EMPLOYED}
              control={
                <AppRadioButton style={{ width: '100%' }} color="primary" name={SELF_EMPLOYED_LABEL} />
              }
              label=""
            />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size="large" variant="outlined">
            Cancel
          </Button>
          <Button
            disabled={selectedPayScheme === selectedPaySchedule?.pay_scheme}
            onClick={changePayScheme}
            size="large"
            color="primary"
            variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
