import { Box, Theme, Typography, useTheme } from '@material-ui/core';

import { useStyles } from '../styles';
import { colors } from '../config';

interface LegendItemProps {
  color: { lightColor: string; darkColor: string };
  label: string;
}

const options = [
  {
    color: colors.total_basic_hours,
    label: 'Regular Paid Hours',
  },
  {
    color: colors.total_overtime_hours,
    label: 'Overtime Paid Hours',
  },
];

const LegendItem = ({ label, color }: LegendItemProps) => {
  const classes = useStyles();
  const theme = useTheme<Theme>();

  return (
    <Box display="flex" alignItems="center" gridGap={8}>
      <Box
        className={classes.legendColorExample}
        style={{ backgroundColor: theme.palette.type === 'dark' ? color.lightColor : color.darkColor }}
      />
      <Typography>{label}</Typography>
    </Box>
  );
};
export const Legend = () => {
  return (
    <Box display="flex" gridGap={16}>
      {options.map(item => (
        <LegendItem key={item.label} {...item} />
      ))}
    </Box>
  );
};
