import { useContext } from 'react';

import { Box, Typography } from '@material-ui/core';

import { LocationField } from '@vyce/core/src/components/controlled-inputs/LocationField';

import { HiringContext } from '../../../../modules/hiringModule/context/hiringContext';

export const JobLocation = () => {
  const { isEditMode } = useContext(HiringContext);

  return (
    <Box>
      <Typography variant="subtitle1" color="primary">
        Job Location
      </Typography>

      <LocationField disabled={isEditMode} name="address" margin="normal" />
    </Box>
  );
};
