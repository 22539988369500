import { useMemo, useContext } from 'react';

import { TeamsPageContext } from '@vyce/core/src/contexts';

import { useActions, useTypedSelector, useInviteButton } from 'src/hooks';
import { WidgetModule } from 'src/modules';

export const useTeamsDashboardData = () => {
  const { dashboards, first_name } = useTypedSelector(state => state.user);
  const { selectedCompanyAppData, selectedCompany } = useTypedSelector(state => state.helper);
  const { loading, teams, selectedCompanyId = '' } = useContext(TeamsPageContext);
  const {
    isInviteFormOpen,
    subscribeLoading,
    isActivateSubscriptionDialogOpen,
    simpleSubscribe,
    setCloseInviteForm,
    handleInviteButtonClick,
    closeActivateSubscriptionDialog,
  } = useInviteButton();
  const { updateUserDashboards } = useActions();

  const contextOptions = useMemo(
    () => ({
      dashboards,
      first_name,
      companyDataLoading: loading,
      teams: teams ?? [],
      selectedCompanyId,
      updateUserDashboards,
      widgetComponent: WidgetModule,
      onOpenUniversalInviteForm: handleInviteButtonClick,
    }),
    [dashboards, first_name, selectedCompanyAppData, selectedCompanyId, loading, teams, updateUserDashboards]
  );
  return {
    isActivateSubscriptionDialogOpen,
    subscribeLoading,
    contextOptions,
    isInviteFormOpen,
    selectedCompany,
    simpleSubscribe,
    setCloseInviteForm,
    closeActivateSubscriptionDialog,
  };
};
