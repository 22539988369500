import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    popoverClass: {
      marginLeft: '48px',
      marginTop: '4px',
    },
    menuItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    permissionPopoverClass: {
      marginLeft: '82px',
      marginTop: '4px',
    },
    selectRoot: {
      display: 'flex',
      position: 'relative',
      justifyContent: 'space-between',
      '& .details-icon': {
        position: 'absolute',
        right: '40px',
        top: '18px',
      },
    },
    styledControlLabel: {
      flexBasis: '30%',
      '& .MuiFormControlLabel-label': {
        whiteSpace: 'unset',
      },
    },
  })
);
