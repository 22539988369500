import React from 'react';
import { Box, Checkbox } from '@material-ui/core';
import { CheckboxProps } from '@material-ui/core/Checkbox';
import { useStyles } from './AppRadio';

export const AppButtonCheckbox = React.forwardRef((props: CheckboxProps, ref) => {
  const classes = useStyles();

  return (
    <Checkbox
      innerRef={ref}
      disableTouchRipple
      disableRipple
      classes={{ root: classes.root }}
      disableFocusRipple
      checkedIcon={<Box className={classes.chosen}>{props.name}</Box>}
      icon={<Box className={classes.empty}>{props.name}</Box>}
      {...props}
    />
  );
});
