import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    buttonWrapper: {
      width: '40px',
      height: '40px',
      padding: 12,
      minWidth: 'max-content !important',
      borderRadius: 12,
      '& .MuiButton-startIcon': {
        marginRight: 0,
        marginLeft: 0,
      },
    },
    closeButton: {
      position: 'absolute',
      top: 20,
      right: 20,
    },
  })
);
