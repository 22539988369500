import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {},
    previewIcon: {
      position: 'absolute',
      right: theme.spacing(5),
      top: '5px',
      color: theme.palette.primary.main,
    },
    paper: {
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      position: 'relative',
    },
    clickable: {
      cursor: 'pointer',
      transition: 'all .2s ease-in-out',
      '&:hover': {
        transform: 'scale(1.02)',
      },
    },
    infoContainer: {
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row-reverse',
      },
    },
    formPaper: {
      padding: '24px',
      width: '100%',
    },
    mobileContainer: {
      [theme.breakpoints.down('xs')]: {
        marginBottom: '56px',
      },
    },
    genderGrid: {
      display: 'flex',
      alignItems: 'center',
    },
    buttonGrid: {
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('xs')]: {
        position: 'fixed',
        width: '100vw',
        padding: theme.spacing(1),
        bottom: 0,
        left: 0,
        backgroundColor: theme.palette.background.default,
        zIndex: theme.zIndex.mobileStepper,
      },
    },
    titleContainer: {
      [theme.breakpoints.down('xs')]: {
        alignItems: 'flex-start',
      },
    },
    tabTitle: {
      fontWeight: 600,
      color: theme.palette.text.secondary,
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
        marginBottom: theme.spacing(1),
      },
    },
    linkButton: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    saveButtonContainer: {
      backgroundColor: theme.palette.background.paper,
    },
    saveButton: {
      minWidth: '155px',
      height: '56px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    constrolWrapper: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    confirmButtonsContainer: {
      zIndex: 1,
      backgroundColor: theme.palette.background.paper,
      position: 'sticky',
      bottom: 0,
      paddingTop: '16px',
      paddingBottom: '16px',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
      },
    },
    pdfButton: {
      width: '138px',
      height: '56px',
      [theme.breakpoints.down('xs')]: {
        minWidth: '138px',
        width: '100%',
      },
    },
    bioPaper: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
    },
    uploadInput: {
      display: 'none',
    },
    uploadButton: {
      margin: '16px 0 8px',
    },
    name: {
      marginTop: '8px',
      fontWeight: 600,
    },
    profession: {
      color: theme.palette.text.secondary,
    },
    documentUploaderTitle: {
      margin: '8px 0 16px',
      justifyContent: 'flex-start',
      display: 'flex',
      textAlign: 'center',
      textTransform: 'capitalize',
    },
    selfieTitle: {
      margin: '-16px 0 16px',
    },
    imageButton: {
      margin: '0 8px',
      width: '70px',
      height: '32px',
    },

    staticTilePaper: {
      padding: theme.spacing(3),
      height: '250px',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    },

    cardDialog: {
      minWidth: '500px',
      [theme.breakpoints.down('xs')]: {
        minWidth: 'auto',
      },
    },
    testTitle: {
      fontWeight: 'bold',
    },
    dialog: {
      minWidth: '500px',
      [theme.breakpoints.down('xs')]: {
        minWidth: 'auto',
      },
    },
    documentWrapper: {
      borderRadius: '12px',
      width: '100%',
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      justifyContent: 'center',
    },
    positionPaper: {
      padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      transition: 'all .2s ease-in-out',
      '&:hover': {
        transform: 'scale(1.02)',
      },
      cursor: 'pointer',
      border: `1px solid ${theme.palette.primary.main}`,
    },
    bold: {
      fontWeight: 600,
    },
    questionButtonControlLabel: {
      marginRight: 0,
      '& .MuiTypography-root': {
        marginLeft: 0,
      },
    },
    questionButton: {
      width: 100,
      paddingRight: 0,
    },
    docImage: {
      height: 250,
      maxWidth: 200,
      objectFit: 'contain',
    },
    gridItem: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);
