import React, { useContext, useMemo } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Box } from '@material-ui/core';

import { DeviceContext } from '@vyce/core/src/contexts';
import { Passport, RTWVerification, IDVerification, RTWProps } from '@vyce/core/src/types';

import { getIDDetailsData, getRTWDetailsData } from './utils';
import { IDDetails, RTWDetails } from './components';
import { isIDVerified } from '../../views/preview/utils';
import { MAIN_CONTAINER_ID } from '../../constants';

interface Props {
  open: boolean;
  passport?: Passport;
  idVerification?: IDVerification;
  rtwVerification?: RTWVerification;
  rtw?: RTWProps;
  mode?: 'both' | 'ID' | 'RTW';
  onClose: () => void;
}

export const DocumentsDialog: React.FC<Props> = ({
  open,
  passport,
  idVerification,
  rtw,
  rtwVerification,
  mode = 'both',
  onClose,
}) => {
  const { isMobile } = useContext(DeviceContext);

  const IDDetailsData = useMemo(
    () => getIDDetailsData(passport || ({} as Passport), idVerification || ({} as IDVerification)),
    [passport, idVerification]
  );
  const RTWDetailsData = useMemo(() => getRTWDetailsData(rtw, rtwVerification), [rtw, rtwVerification]);
  const { url: passportUrl = '' } = passport?.document ?? {};
  const { url: selfieUrl = '' } = passport?.selfie ?? {};
  const { url: rtwPhotoUrl = '' } = rtw?.photo ?? {};
  const { verified: idVerified = false } = idVerification ?? {};
  const { verified: rtwVerified = false } = rtwVerification ?? {};

  const isShowRTWButton = rtw && (mode === 'RTW' || mode === 'both') && rtw?.document?.url;

  const downloadPDF = () => {
    window.open(rtw?.document?.url, '_blank');
  };

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      maxWidth="xl"
      open={open}
      onClose={onClose}>
      <DialogContent>
        <Box display="flex" flexDirection="column" gridGap={36}>
          {(mode === 'ID' || mode === 'both') && (
            <IDDetails
              {...IDDetailsData}
              passportUrl={passportUrl}
              selfieUrl={selfieUrl}
              verified={isIDVerified(idVerification?.status)}
            />
          )}
          {rtw && (mode === 'RTW' || mode === 'both') && (
            <RTWDetails {...RTWDetailsData} rtwPhotoUrl={rtwPhotoUrl} verified={rtwVerified} />
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        {isShowRTWButton && (
          <Button size="large" variant="outlined" color="primary" onClick={downloadPDF}>
            {mode === 'RTW' ? 'View Document' : 'View RTW Document'}
          </Button>
        )}
        <Button size="large" variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
