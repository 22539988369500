import React, { useMemo } from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';

import { AppDataGrid, FilterSystem } from '../../components';
import { GRID_PAGE_SIZE } from '../../constants';
import { FilterSection, SubscriptionInvoice } from '../../types';
import { PAYMENT_STATUSES } from '../../modules/subscriptionModule/constants';
import { formatTableDate } from '../../utils/dates';
import { currencyFormatter } from '../../utils';
import { PaymentStatus } from './components/PaymentStatus';

interface Props {
  invoices: SubscriptionInvoice[];
  loading: boolean;
  total: number;
  sortModel: GridSortModel;
  handleSortModelChange: (newModel: GridSortModel) => void;
  handlePageChange: (newPage: number) => void;
  handleFilterChange: (filters: any) => void;
  handleViewInvoiceClick: Function;
}

export const SubscriptionPayments: React.FC<Props> = ({
  invoices,
  loading,
  total,
  sortModel,
  handleSortModelChange,
  handleFilterChange,
  handlePageChange,
  handleViewInvoiceClick,
}) => {
  const filtersSections: FilterSection[] = [
    {
      title: 'Statuses',
      expanded: true,
      filters: [
        {
          type: 'select',
          multiple: false,
          label: 'Status',
          field: 'status',
          values: [
            '',
            PAYMENT_STATUSES.draft.name,
            PAYMENT_STATUSES.open.name,
            PAYMENT_STATUSES.paid.name,
            PAYMENT_STATUSES.uncollectible.name,
            PAYMENT_STATUSES.void.name,
          ],
          defaultValue: '',
        },
      ],
    },
  ];

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'next_payment_attempt',
        headerName: 'Payment Date',
        flex: 0.35,
        disableColumnMenu: true,
        valueFormatter: params => formatTableDate(params.row?.next_payment_attempt, false),
        minWidth: 140,
      },
      {
        field: 'amount',
        headerName: 'Payment Amount',
        flex: 0.3,
        disableColumnMenu: true,
        valueFormatter: params => currencyFormatter.format(params.row.amount),
        minWidth: 160,
      },
      {
        field: 'status',
        headerName: 'Status',
        disableColumnMenu: true,
        renderCell: params => <PaymentStatus status={params.row.status} />,
        width: 200,
      },
      {
        field: '',
        headerName: '',
        disableColumnMenu: true,
        sortable: false,
        flex: 0.3,
        renderCell: params => (
          <Box display="flex" width="100%" justifyContent="flex-end">
            <Button
              onClick={() => handleViewInvoiceClick(params.row.invoice_pdf)}
              variant="outlined"
              color="primary"
              fullWidth
              style={{ height: 32, width: 200 }}
              size="small">
              View Invoice
            </Button>
          </Box>
        ),
        minWidth: 200,
      },
    ],
    [handleViewInvoiceClick]
  );

  return (
    <>
      <Box display="flex" marginBottom={3} justifyContent="space-between">
        <Typography variant="h6">Payments</Typography>

        <FilterSystem filtersSections={filtersSections} onFiltersChange={handleFilterChange} />
      </Box>

      <AppDataGrid
        noPaper
        rows={invoices}
        getRowId={row => row.uuid}
        loading={loading}
        columns={columns}
        rowCount={total}
        paginationMode="server"
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        onPageChange={handlePageChange}
        pageSize={GRID_PAGE_SIZE}
        rowsPerPageOptions={[GRID_PAGE_SIZE]}
        disableSelectionOnClick
        withProfileButton
        unit="payments"
      />
    </>
  );
};
