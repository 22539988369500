import { createContext } from 'react';

import { Team } from '@vyce/core/src/types';

interface Context {
  loading: boolean;
  teams?: Team[] | null;
  teamsCount?: number;
  selectedCompanyId?: string;
  selectedCompanyIsSubscribed?: boolean;
  inviteButton?: () => JSX.Element;
  getTeams: (offset?: number, substring?: string, sorting?: any) => void;
  setOpenSuccessDialog?: (open: boolean) => void;
  onOpenUniversalInviteForm?: () => void;
}

export const TeamsPageContext = createContext({} as Context);

export const TeamsPageProvider = TeamsPageContext.Provider;
