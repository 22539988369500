import React, { useContext } from 'react';

import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';

import { useStyles } from '../styles';
import { DeviceContext } from '../../../contexts';
import { ButtonTitleWithLoading } from '../../../components/ButtonTitleWithLoading';
import { MAIN_CONTAINER_ID } from '../../../constants';

interface Props {
  open: boolean;
  loading: boolean;
  setOpen: Function;
  declineApplication: () => void;
}

export const DeclineApplicationDialog: React.FC<Props> = ({
  open,
  setOpen,
  declineApplication,
  loading,
}) => {
  const classes = useStyles();
  const { isMobile } = useContext(DeviceContext);

  return (
    <Dialog
      fullScreen={isMobile}
      container={document.getElementById(MAIN_CONTAINER_ID)}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">Are you sure you want to decline application?</DialogTitle>
      <DialogActions>
        <Button size="large" variant="outlined" onClick={() => setOpen(false)}>
          No, take me back
        </Button>
        <Button
          size="large"
          style={{ width: 182 }}
          className={classes.redButton}
          onClick={declineApplication}
          variant="outlined">
          <ButtonTitleWithLoading title="Yes, I want to decline" loaderVariant="primary" loading={loading} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
