import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@material-ui/core';
import { FiChevronDown } from 'react-icons/fi';

import { AppCheckbox } from '@vyce/core/src/components/inputs';
import useStyles from '../styles';

interface Props {
  moduleExpanded: boolean;
  collapseAllModules: Function;
  setExpandedModule: Function;
  clearModuleSelections: Function;
  children: React.ReactElement;
  moduleName: string;
  moduleLink: string;
  active: boolean;
  isLegend?: boolean;
  isModuleItemsSelected: boolean;
  moduleItemName: string;
  expandedModule: string | null;
}

export const ModuleAccordion: React.FC<Props> = ({
  moduleExpanded,
  collapseAllModules,
  setExpandedModule,
  children,
  moduleName,
  active,
  moduleLink,
  isLegend = false,
  isModuleItemsSelected,
  clearModuleSelections,
  moduleItemName,
  expandedModule,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [moduleChecked, setModuleChecked] = useState<boolean>(false);

  const activateModuleClickHandler = () => {
    history.push(moduleLink);
  };

  useEffect(() => {
    setModuleChecked(isModuleItemsSelected);
  }, [isModuleItemsSelected]);

  const handleModuleCheck = () => {
    if (moduleChecked) {
      clearModuleSelections();
      setExpandedModule(null);
    } else {
      setExpandedModule(moduleName);
    }
    setModuleChecked(value => !value);
  };

  const handleModuleCheckboxClick = (e: any) => {
    e.stopPropagation();
  };

  const handleAccordionChange = () => {
    if (moduleName === expandedModule) {
      return collapseAllModules();
    }
    setExpandedModule(moduleName);
  };

  return (
    <>
      {active ? (
        <Accordion variant="outlined" expanded={moduleExpanded} onChange={handleAccordionChange}>
          <AccordionSummary
            classes={{
              content: classes.summaryContent,
              root: classes.summaryRoot,
              expandIcon: classes.expandIcon,
            }}
            expandIcon={<FiChevronDown size="25px" />}
            aria-controls="accordion-content"
            id="accordion-header">
            <Box display="flex" alignItems="center" gridGap={8}>
              <AppCheckbox
                checked={moduleChecked}
                onChange={handleModuleCheck}
                onClick={handleModuleCheckboxClick}
                disabled={!active}
                variant="rectangle"
                color="primary"
              />
              <Typography className={classes.checkboxLabel}>{moduleName}</Typography>
              {!moduleExpanded && !isModuleItemsSelected && moduleChecked && (
                <Typography className={classes.errorText} variant="caption">
                  No {moduleItemName} Selected
                </Typography>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          paddingTop={0.5}
          paddingBottom={0.5}>
          <Box display="flex" alignItems="center" gridGap={8}>
            <AppCheckbox disabled={true} variant="rectangle" color="primary" />
            <Typography color="textSecondary" className={clsx(classes.checkboxLabel, classes.disabledLabel)}>
              {moduleName}
            </Typography>
          </Box>

          {isLegend ? (
            <Typography variant="caption" color="textSecondary">
              Company hasn't activated {moduleName}{' '}
            </Typography>
          ) : (
            <Typography onClick={activateModuleClickHandler} className={classes.activateModuleText}>
              Activate Module
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};
