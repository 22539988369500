import { useRef } from 'react';

import ReCAPTCHA from 'react-google-recaptcha';

import { CAPTCHA_AVOIDER } from '@vyce/core/src/api/config';

export const useRecaptcha = () => {
  const reCaptchaRef = useRef<ReCAPTCHA>(null);

  const getReCaptchaToken = async () => {
    if (window.location.hostname === 'localhost') {
      return CAPTCHA_AVOIDER;
    }
    const recaptcha_token = (await reCaptchaRef?.current?.executeAsync()) ?? '';
    reCaptchaRef?.current?.reset();
    return recaptcha_token;
  };

  return { reCaptchaRef, getReCaptchaToken };
};
