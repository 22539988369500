import React from 'react';

import { Box, Typography, useTheme } from '@material-ui/core';
import { FiLock } from 'react-icons/fi';
import clsx from 'clsx';

import useStyles from '../../styles';

export const CloseInfo = () => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Box className={classes.closeIconContainer} marginBottom={1}>
        <FiLock size="22px" color={theme.palette.primary.main} />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="subtitle1" color="primary" className={clsx(classes.bold)}>
          Private Information
        </Typography>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          className={clsx(classes.smallBold, classes.smallText)}>
          Connect to view
        </Typography>
      </Box>
    </Box>
  );
};
