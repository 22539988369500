import React, { useState } from 'react';

import { getTeamMembersRequest } from '@vyce/core/src/api/management';
import { GetUserManagementItemsPayload } from '@vyce/core/src/api/types';
import { UserManagementItem } from '@vyce/core/src/types';
import { deleteTeamMembersFromModuleRequest } from '@vyce/core/src/api/teams';
import { ConfirmDialog } from '@vyce/core/src/components';
import { useBooleanState } from '@vyce/core/src/hooks';
import { useRequest } from '@vyce/core/src/hooks/useRequest';

import { UserList } from './UserList';
import { useTypedSelector } from '../../../hooks';

export const TeamMembers: React.FC = () => {
  const [total, setTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserManagementItem[]>([]);
  const [userToRemove, setUserToRemove] = useState<UserManagementItem>();
  const { selectedCompany } = useTypedSelector(state => state.helper);
  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] = useBooleanState(false);

  const removeFromModuleCallback = () => {
    closeConfirmDialog();
    setUsers(value => value.filter(user => user.user_id !== userToRemove?.user_id));
  };
  const [employeesLoading, getEmployeesRequest] = useRequest({
    request: getTeamMembersRequest,
  });
  const [removeLoading, removeFromModuleRequest] = useRequest({
    request: deleteTeamMembersFromModuleRequest,
    successCallback: removeFromModuleCallback,
    showSuccessNotification: true,
  });

  const getEmployees = async (payload: GetUserManagementItemsPayload) => {
    if (!selectedCompany?.uuid) {
      return;
    }
    const res = await getEmployeesRequest(selectedCompany.uuid, payload);
    if (res) {
      setTotal(res.data.count);
      setUsers(res.data.items);
    }
  };

  const removeFromModule = async () => {
    if (!selectedCompany?.uuid || !userToRemove?.user_id) {
      return;
    }
    await removeFromModuleRequest(selectedCompany.uuid, {
      members: [userToRemove.user_id],
    });
  };

  const handleRemoveTeamMemberClick = (user: UserManagementItem) => {
    setUserToRemove(user);
    openConfirmDialog();
  };

  return (
    <>
      <UserList
        moduleName="Teams"
        users={users}
        loading={employeesLoading}
        getUsers={getEmployees}
        total={total}
        handleRemoveUserClick={handleRemoveTeamMemberClick}
      />

      <ConfirmDialog
        handleClose={closeConfirmDialog}
        open={isConfirmDialogOpen}
        confirmText="Remove"
        cancelText="Cancel"
        title={`Are you sure you want to remove ${userToRemove?.first_name} ${userToRemove?.last_name} from Teams module?`}
        handleConfirm={removeFromModule}
        loading={removeLoading}
      />
    </>
  );
};
