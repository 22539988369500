import React from 'react';

import AIChatContextProvider from './context';
import { AIChat } from './AIChat';
import { User } from '../../types';

interface Props {
  user?: User;
  model?: string;
}

export const AITool: React.FC<Props> = ({ user, model }) => {
  return (
    <AIChatContextProvider user={user} model={model}>
      <AIChat />
    </AIChatContextProvider>
  );
};
