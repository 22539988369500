import React from 'react';

import { Box, Grid, Paper, Typography, useTheme, Button } from '@material-ui/core';
import { FormProvider } from 'react-hook-form';
import { TbPencil } from 'react-icons/tb';
import { AiOutlinePlus } from 'react-icons/ai';

import { TRACKING_OPTIONS, TRACKING_TYPES } from '@vyce/core/src/modules/timeModule/constants';
import { AppIconButton, DetailsIcon, RouterPrompt } from '@vyce/core/src/components';
import { ControlledSelect, LocationNameField } from '@vyce/core/src/components/controlled-inputs';
import breadcrumbsImg from '@vyce/core/src/assets/tracking-options/breadcrumbs.png';
import breadcrumbsImgDark from '@vyce/core/src/assets/tracking-options/breadcrumbs-dark.png';
import inOutImg from '@vyce/core/src/assets/tracking-options/in-out.png';
import inOutImgDark from '@vyce/core/src/assets/tracking-options/in-out-dark.png';
import noTracking from '@vyce/core/src/assets/tracking-options/no-tracking.png';
import noTrackingDark from '@vyce/core/src/assets/tracking-options/no-tracking-dark.png';

import { useStyles } from '../styles';
import { ClearableLocationField } from '../components';
import { TrackOptionHint } from '../LocationOnboarding/components/TrackingOptionHint';

import {
  LocationControlButtons,
  LocationPreviewMap,
  EditShifts,
  EditLocationAreaDialog,
  GoToSettingsDialog,
  CustomField,
} from './components';
import { useEditLocationData } from './hooks';
import { Props } from './types';

export const EditLocation = (props: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDarkMode = theme.palette.type === 'dark';

  const {
    methods,
    handleClose,
    isLocationChanged,
    addressSnippet,
    centerPosition,
    polygon,
    openEditAreaDialog,
    openGoToSettingsDialog,
    isMobile,
    paySchedules,
    customLocationFields,
    setGoToSettingsDialogClose,
    setValue,
    setGoToSettingsDialogOpen,
    saveChanges,
    handleSubmit,
    createShift,
    deleteShift,
    goToSettings,
    setEditAreaDialogOpen,
    setEditAreaDialogClose,
    saveLocationChanges,
    location,
  } = useEditLocationData(props);

  return (
    <Box display="flex" flexDirection="column" gridGap={16}>
      <LocationControlButtons
        editMode={true}
        handleClose={handleClose}
        isLocationChanged={isLocationChanged}
        handleSaveChangesClick={handleSubmit(saveChanges)}
      />

      <FormProvider {...methods}>
        <form>
          <Box display="flex" flexDirection="column" gridGap="16px">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Paper
                  className={classes.paper}
                  variant="outlined"
                  style={{ minHeight: 452, height: '100%' }}>
                  <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
                    <Box flex="1 0 auto">
                      <Typography variant="h6">Location Details</Typography>

                      <LocationNameField />

                      <ClearableLocationField />

                      {/*TODO: finish when backend will be ready*/}
                      {/*<PaySchedulesSelect paySchedules={paySchedules} />*/}
                      {customLocationFields.map(cf => (
                        <CustomField key={cf.system_name} {...cf} />
                      ))}
                    </Box>

                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        startIcon={<AiOutlinePlus />}
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={setGoToSettingsDialogOpen}>
                        Add custom fields
                      </Button>
                    </Box>
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper className={classes.paper} style={{ minHeight: 452 }} variant="outlined">
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="h6">Clock-In Spot</Typography>

                    {addressSnippet && (
                      <AppIconButton
                        onClick={setEditAreaDialogOpen}
                        isSmall
                        variant="paper"
                        disableRipple
                        isBorder
                        disableTouchRipple
                        disableFocusRipple>
                        <TbPencil size="22px" />
                      </AppIconButton>
                    )}
                  </Box>

                  <EditLocationAreaDialog
                    centerPosition={centerPosition}
                    setValue={setValue}
                    polygon={polygon}
                    handleClose={setEditAreaDialogClose}
                    open={openEditAreaDialog}
                  />

                  <Typography className={classes.editPositionSubtitle}>
                    {addressSnippet
                      ? 'Move the pin to a place you want your team to clock-in and check-out at (within a 125 metres radius) or draw the area yourself'
                      : 'Input an address to be able to setup a place you want your team to clock-in and check-out.'}
                  </Typography>

                  <Box marginTop="12px">
                    {addressSnippet && (
                      <LocationPreviewMap height={224} centerPosition={centerPosition} polygon={polygon} />
                    )}
                  </Box>

                  <ControlledSelect
                    margin="normal"
                    label="Tracking workers’ location"
                    name="tracking_type"
                    items={TRACKING_TYPES}
                    multiple={false}
                    endAdornment={
                      <Box paddingRight={6}>
                        <DetailsIcon
                          content={
                            <Box display="flex" flexDirection="column" gridGap={24}>
                              <Typography style={{ maxWidth: 308 }}>
                                There are three ways of tracking location of your workers.
                              </Typography>

                              <TrackOptionHint
                                label={TRACKING_OPTIONS.breadcrumbs.name}
                                image={isDarkMode ? breadcrumbsImgDark : breadcrumbsImg}
                                description={TRACKING_OPTIONS.breadcrumbs.description}
                              />
                              <TrackOptionHint
                                label={TRACKING_OPTIONS.inOut.name}
                                image={isDarkMode ? inOutImgDark : inOutImg}
                                description={TRACKING_OPTIONS.inOut.description}
                              />
                              <TrackOptionHint
                                label={TRACKING_OPTIONS.none.name}
                                image={isDarkMode ? noTrackingDark : noTracking}
                                description={TRACKING_OPTIONS.none.description}
                              />
                            </Box>
                          }
                        />
                      </Box>
                    }
                  />
                </Paper>
              </Grid>
            </Grid>

            {!!location?.uuid && (
              <EditShifts
                companyId={props.companyId}
                siteId={location.uuid}
                createShift={createShift}
                deleteShift={deleteShift}
                handleSaveChangesClick={handleSubmit(saveChanges)}
              />
            )}

            <RouterPrompt
              when={isLocationChanged}
              title="Before you go..."
              subtitle="Do you want to save the changes you've made?"
              okText={isMobile ? 'Yes' : 'Yes, save my changes'}
              cancelText={isMobile ? 'No' : 'No, ignore my changes'}
              onOK={async () => {
                await saveLocationChanges(methods.getValues());
                return true;
              }}
              onCancel={() => true}
            />
          </Box>
        </form>
      </FormProvider>
      <GoToSettingsDialog
        open={openGoToSettingsDialog}
        onClose={setGoToSettingsDialogClose}
        onClick={goToSettings}
      />
    </Box>
  );
};
