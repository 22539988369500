import React, { forwardRef, useEffect, useState, LegacyRef } from 'react';

import { makeStyles } from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTheme } from '@material-ui/core';
import noop from 'lodash/noop';

import config from '@vyce/core/src/api/config';

interface Props {
  setRecaptchaLoaded?: (arg0: boolean) => void;
}

const useStyles = makeStyles(() => ({
  hiddenRecaptcha: {
    '& .grecaptcha-badge': { left: '-270px !important' },
  },
}));

export const ReCAPTCHAComponent = forwardRef(
  ({ setRecaptchaLoaded = noop }: Props, ref: LegacyRef<ReCAPTCHA>) => {
    const theme = useTheme();
    const classes = useStyles();
    const [loaded, setLoaded] = useState(false);

    const asyncScriptOnLoad = () => {
      setRecaptchaLoaded(true);
    };

    useEffect(() => {
      setLoaded(true);
    }, []);

    return (
      <>
        {loaded && (
          <ReCAPTCHA
            badge="bottomleft"
            theme={theme.palette.type}
            size="invisible"
            ref={ref}
            sitekey={config.recaptchaSiteKey}
            asyncScriptOnLoad={asyncScriptOnLoad}
            className={classes.hiddenRecaptcha}
          />
        )}
      </>
    );
  }
);
