import React from 'react';
import { Box, FormControlLabel, Typography } from '@material-ui/core';

import { AppCheckbox } from '@vyce/core/src/components/inputs';

import { MODULE_NAMES } from '../config';
import useStyles from '../styles';
import { TeamModuleOptions, CommonOptions } from '../types';
import { ModuleAccordion } from './ModuleAccordion';

export const TeamsBlock = ({
  isLegend,
  expandedModule,
  teams,
  selectedTeamIds,
  teamsModuleActive,
  clearTeamsSelections,
  handleTeamSelect,
  collapseAllModules,
  setExpandedModule,
}: TeamModuleOptions & CommonOptions) => {
  const classes = useStyles();

  return (
    <Box marginLeft="-6px" marginTop={1} marginBottom={1}>
      <ModuleAccordion
        moduleName={MODULE_NAMES.TEAMS}
        isModuleItemsSelected={!!selectedTeamIds.length}
        moduleLink="/teams"
        moduleItemName="Teams"
        isLegend={isLegend}
        clearModuleSelections={clearTeamsSelections}
        active={teamsModuleActive}
        collapseAllModules={collapseAllModules}
        expandedModule={expandedModule}
        setExpandedModule={setExpandedModule}
        moduleExpanded={expandedModule === MODULE_NAMES.TEAMS}>
        {teamsModuleActive ? (
          <Box marginLeft="36px">
            <Typography className={classes.detailsTitle}>Which Teams?</Typography>
            <Box marginLeft="-6px" display="flex" flexWrap="wrap">
              {teams?.map(team => (
                <FormControlLabel
                  key={team.uuid}
                  classes={{ label: classes.detailsTitle }}
                  style={{ marginRight: '24px', marginTop: '4px' }}
                  control={
                    <AppCheckbox
                      checked={selectedTeamIds.includes(team.uuid)}
                      onChange={e => handleTeamSelect(team.uuid, e.target.checked)}
                      variant="rectangle"
                      color="primary"
                    />
                  }
                  label={team.name}
                />
              ))}
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </ModuleAccordion>
    </Box>
  );
};
