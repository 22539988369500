import React, { useCallback } from 'react';

import { PopoverContentProps } from '@reactour/tour';
import { Button } from '@material-ui/core';

interface ButtonProps extends Pick<PopoverContentProps, 'setCurrentStep' | 'setIsOpen' | 'steps'> {
  currentStep: number;
  className?: string;
}

export const PrevButton = ({ currentStep, setCurrentStep, ...rest }: ButtonProps) => {
  const isFirst = currentStep === 0;

  const handleClick = useCallback(() => {
    if (!isFirst) setCurrentStep(currentStep - 1);
  }, [isFirst, setCurrentStep, currentStep]);

  return (
    <Button color="primary" variant="outlined" onClick={handleClick} {...rest}>
      Prev
    </Button>
  );
};

export const NextButton = ({ currentStep, steps, setCurrentStep, setIsOpen, ...rest }: ButtonProps) => {
  const isLast = currentStep === steps.length - 1;

  const handleClick = useCallback(() => {
    if (isLast) {
      setIsOpen(false);
    } else {
      setCurrentStep(currentStep + 1);
    }
  }, [isLast, setIsOpen, setCurrentStep, currentStep]);

  return (
    <Button color="primary" variant="contained" onClick={handleClick} {...rest}>
      {isLast ? " Ok let's go!" : 'Next'}
    </Button>
  );
};

export const CloseButton = ({ setIsOpen, ...rest }: ButtonProps) => {
  const handleClick = () => setIsOpen(false);
  return (
    <Button variant="outlined" onClick={handleClick} {...rest}>
      Close
    </Button>
  );
};
