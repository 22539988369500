import React, { forwardRef } from 'react';

import { Box, Button, Typography, useTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { IoCalendarOutline } from 'react-icons/io5';

import { INTERFACE_PERMISSIONS, UserPermission } from '@vyce/core/src/types';
import { BOOK_TIME_LINK } from '@vyce/core/src/constants';
import { AppCheckboxRow } from '@vyce/core/src/components';
import config from '@vyce/core/src/api/config';

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '8px',
      flex: 1,
    },
    bookCallContainer: {
      borderTop: `1px solid ${theme.palette.divider}`,
      padding: '12px 16px 12px 21px',
      borderRadius: '0 0 8px 8px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gridGap: '16px',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },

    bookLink: {
      width: '120px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        '& > a': {
          width: 'inherit',
        },
      },
    },
  })
);

interface ItemProps {
  checked: boolean;
  label: string;
  link: string;
}

interface Props {
  isTeamCreated: boolean;
  isPayrollRegistered: boolean;
  isJobPosted?: boolean;
  isTimeRegistered: boolean;
  permissions: UserPermission[];
}

const BookLink = forwardRef((props: any, ref) => (
  <a href={BOOK_TIME_LINK} target="_blank" rel="noreferrer" {...props} />
));

const Item = ({ checked, label, link }: ItemProps) => {
  return (
    <AppCheckboxRow checked={checked} label={label} handleChange={() => {}}>
      {!checked ? (
        <Button
          component={Link}
          to={link}
          style={{ width: 100 }}
          size="small"
          variant="contained"
          color="primary">
          Let's try!
        </Button>
      ) : (
        <></>
      )}
    </AppCheckboxRow>
  );
};

const comingSoon = config.comingSoon;

export const WelcomeWidget = ({
  isPayrollRegistered,
  isTeamCreated,
  isJobPosted,
  isTimeRegistered,
  permissions,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box width="100%" height="100%" display="flex" flexDirection="column">
      <Typography color="textSecondary">What would you like to do?</Typography>

      <Box marginTop={1} className={classes.container} display="flex" flexDirection="column">
        {permissions.includes(INTERFACE_PERMISSIONS.TEAMS) && (
          <Item checked={isTeamCreated} label="Create a Team" link="/teams" />
        )}
        {(permissions.includes(INTERFACE_PERMISSIONS.PAYROLL) ||
          permissions.includes(INTERFACE_PERMISSIONS.PAYROLL_OUTSOURCED)) && (
          <Item checked={isPayrollRegistered} label="Set up Payroll" link="/payroll" />
        )}
        {permissions.includes(INTERFACE_PERMISSIONS.CONNECT) && !comingSoon && (
          <Item checked={!!isJobPosted} label="Post a Job" link="/hiring" />
        )}

        {permissions.includes(INTERFACE_PERMISSIONS.TIME) && (
          <Item checked={isTimeRegistered} label="Set up Time & Attendance" link="/time" />
        )}

        <Box flex={1} />

        <Box className={classes.bookCallContainer}>
          <Box display="flex" alignItems="center" gridGap={16}>
            <Box>
              <IoCalendarOutline size="20px" color={theme.palette.text.secondary} />
            </Box>

            <Box>
              <Typography variant="subtitle2">
                Book an appointment with a Vyce team member to get help set up
              </Typography>
            </Box>
          </Box>
          <Box className={classes.bookLink}>
            <Button component={BookLink} size="small" variant="outlined" color="primary">
              Book a meeting
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
