import { useMemo } from 'react';

import { TeamMembers } from '@vyce/core/src/views/teamMembers';
import { TeamMembersModuleProvider } from '@vyce/core/src/contexts';
import { useTeamMembersData } from '@vyce/core/src/modules/teamMembersModule/hooks/useTeamMembersData';
import { getAllTeamMembersRequest } from '@vyce/core/src/api/teams';

import { useTypedSelector } from '../../hooks';

export const TeamMembersModule = () => {
  const { selectedCompany, selectedCompanyAppData } = useTypedSelector(state => state.helper);
  const hookData = useTeamMembersData({
    companyId: selectedCompany?.uuid,
    teams: selectedCompanyAppData?.teams || [],
    getAllTeamMembersRequest,
  });

  const contextValues = useMemo(() => ({ ...hookData, withProfileButton: true }), [hookData]);

  return (
    <TeamMembersModuleProvider value={contextValues}>
      <TeamMembers />
    </TeamMembersModuleProvider>
  );
};
