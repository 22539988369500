import React from 'react';

import { Box, FormControlLabel, Typography, createStyles, makeStyles, Theme } from '@material-ui/core';

import { AppCheckbox } from '@vyce/core/src/components/inputs';
import { CIS_SELF_EMPLOYED_LABEL, SELF_EMPLOYED_LABEL, SUBCONTRACTOR_TYPES } from '@vyce/core/src/constants';
import { monochrome } from '@vyce/core/src/theme/styles';

interface Props {
  selectedSubcontractorType: string;
  handleTypeChange: Function;
}

const styles = { marginRight: '24px', marginTop: '4px' };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    detailsTitle: {
      color: theme.palette.type === 'dark' ? monochrome.light : monochrome.dark,
      fontSize: '15px',
      fontWeight: 400,
    },
  })
);

export const HowToEngageEmployee: React.FC<Props> = ({ selectedSubcontractorType, handleTypeChange }) => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.detailsTitle}>How to engage them?</Typography>
      <Box marginLeft="-6px" display="flex" flexWrap="wrap">
        <FormControlLabel
          classes={{ label: classes.detailsTitle }}
          style={styles}
          control={
            <AppCheckbox
              checked={selectedSubcontractorType === SUBCONTRACTOR_TYPES.SELF_EMPLOYED}
              onChange={e => handleTypeChange(SUBCONTRACTOR_TYPES.SELF_EMPLOYED, e.target.checked)}
              variant="rectangle"
              color="primary"
            />
          }
          label={SELF_EMPLOYED_LABEL}
        />
        <FormControlLabel
          classes={{ label: classes.detailsTitle }}
          style={styles}
          control={
            <AppCheckbox
              checked={selectedSubcontractorType === SUBCONTRACTOR_TYPES.CIS_SELF_EMPLOYED}
              onChange={e => handleTypeChange(SUBCONTRACTOR_TYPES.CIS_SELF_EMPLOYED, e.target.checked)}
              variant="rectangle"
              color="primary"
            />
          }
          label={CIS_SELF_EMPLOYED_LABEL}
        />
      </Box>
    </>
  );
};
