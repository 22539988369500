import React from 'react';

import { Box, Button } from '@material-ui/core';

import { OnboardingStepTitle } from '../../../../components';
import { ActiveOnboardingStep } from '../../../../types';

interface Props {
  handleYesClick: Function;
  handleNoClick: Function;
  activeStep: ActiveOnboardingStep;
  withLocation: boolean;
}

const styles = { width: 120, marginRight: 8 };

export const DoYouNeedSpecificLocationStep: React.FC<Props> = ({
  handleYesClick,
  handleNoClick,
  activeStep,
  withLocation,
}) => {
  return (
    <>
      <OnboardingStepTitle text="Do you need your team to check in to a specific location?" />

      <Box display="flex" justifyContent="center" marginTop={2}>
        <Button
          onClick={() => handleYesClick(activeStep.number)}
          size="large"
          style={styles}
          variant={withLocation && activeStep.number > 1 ? 'contained' : 'outlined'}
          color="primary">
          Yes
        </Button>

        <Button
          onClick={() => handleNoClick(activeStep.number)}
          size="large"
          style={styles}
          variant={!withLocation && activeStep.number > 1 ? 'contained' : 'outlined'}
          color="primary">
          No
        </Button>
      </Box>
    </>
  );
};
