import React, { useEffect, useState } from 'react';

import { ControlledTagAutocomplete } from './ControlledTagAutocomplete';
import { AutocompleteProps, FieldProps, PayTagGroup } from '../../types';
import { getPayScheduleTagsRequest } from '../../api/pay-schedules';

interface Props extends FieldProps {
  saveInDatabase?: boolean;
  group: PayTagGroup;
  payScheduleIds: string[];
  companyId: string;
}

export const PayTagsField: React.FC<Props> = ({
  margin,
  label = '',
  name = '',
  multiple = false,
  disabled = false,
  rules,
  token = '',
  group,
  payScheduleIds,
  companyId,
}) => {
  const [selectProps, setSelectProps] = useState<AutocompleteProps>({
    name,
    items: [],
    rules,
    label,
    margin,
    multiple,
    disabled,
    token,
    creatable: true,
    saveInDatabase: false,
    noOptionsText: 'Add new location',
  });

  const fetchTags = async () => {
    try {
      const res = await getPayScheduleTagsRequest({
        group,
        payScheduleIds,
        companyId,
      });
      setSelectProps(value => ({ ...value, disabled: false, items: res.data.items }));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setSelectProps(value => ({ ...value, disabled: true }));
    if (!disabled) {
      fetchTags();
    }
  }, [companyId]);

  return <ControlledTagAutocomplete {...selectProps} />;
};
