import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { AppTextField } from '@vyce/core/src/components/inputs/AppTextField';
import { EmailField } from '@vyce/core/src/components/controlled-inputs/EmailField';
import { PhoneNumberField } from '@vyce/core/src/components/controlled-inputs/PhoneNumberField';
import { OnboardingStepTitle } from '@vyce/core/src/components/OnboardingStepTitle';

export const HMRCContacts: React.FC = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <OnboardingStepTitle text="Please confirm your main contact for HMRC..." />

      <Controller
        control={control}
        rules={{
          required: 'First Name is required',
        }}
        name="contact_first_name"
        render={({ field }) => (
          <AppTextField
            label="First Name"
            margin="normal"
            fullWidth
            {...field}
            error={!!errors?.contact_first_name?.message}
            helperText={(errors?.contact_first_name?.message as string) || ''}
          />
        )}
      />

      <Controller
        control={control}
        rules={{
          required: 'Last Name is required',
        }}
        name="contact_last_name"
        render={({ field }) => (
          <AppTextField
            label="Last Name"
            margin="normal"
            fullWidth
            {...field}
            error={!!errors?.contact_last_name?.message}
            helperText={(errors?.contact_last_name?.message as string) || ''}
          />
        )}
      />

      <EmailField
        label="HMRC Principal Contact Email"
        autoFocus={false}
        name="contact_email"
        margin="normal"
      />

      <PhoneNumberField
        rules={{ required: 'Contact Phone is required' }}
        name="contact_telephone"
        label="Contact Phone"
        margin="normal"
      />
    </>
  );
};
