// @ts-nocheck
import React, { forwardRef, useEffect, useState } from 'react';

import { InputProps, styled } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import InputMask from 'react-input-mask';
import { Controller, useFormContext } from 'react-hook-form';
import { numbersToTime } from '../../utils/dates';

const Input = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '8px',
  border: `2px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    backgroundColor: theme.palette.background.paper,
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  height: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1),
    height: '100%',
    width: '100%',
    fontWeight: 600,
    textAlign: 'center',
  },
}));

const checkTimeInput = (time: string) => {
  if (!time) {
    return '';
  }
  const timeItems = time.split(':');
  if (+timeItems[0] > 24) {
    timeItems[0] = `0${timeItems[0].substring(0, 1)}`;
  }
  if (+timeItems[1] > 60) {
    timeItems[1] = `0${timeItems[1].substring(0, 1)}`;
  }
  return timeItems.join(':');
};

const formatValue = (time: string): string => {
  if (!time) {
    return '';
  }
  const timeItems = time.split(':');
  const hours = +timeItems[0];
  const minutes = +timeItems[1];

  return numbersToTime(hours, minutes);
};

interface Props extends InputProps {
  name: string;
}

export const AppManualTimeInput = forwardRef((props: Props, ref) => {
  const [mask, setMask] = useState<string>('N/A');
  const { control, watch } = useFormContext();
  const value = watch(props.name);

  useEffect(() => {
    setMask(value ? '99:99' : 'N/A');
  }, [value]);

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field }) => (
        <InputMask
          {...field}
          onFocus={() => setMask('99:99')}
          onBlur={() => field.onChange(formatValue(field.value))}
          onChange={e => field.onChange(checkTimeInput(e.target.value))}
          value={field.value || 'N/A'}
          mask={mask}
          maskChar={null}>
          {() => (
            <Input>
              <StyledInputBase inputProps={{ 'aria-label': 'search' }} innerRef={ref} {...props} />
            </Input>
          )}
        </InputMask>
      )}
    />
  );
});
