import { axios } from '@vyce/core/src/api';

import {
  CreateInvitesRequest,
  CreatePaySchedulePublicLinkRequest,
  GetInvitesRequest,
  GetPaySchedulePublicLinkRequest,
  TeamPublicLinkRequest,
} from '../types';
import { Starters } from '../../types';

export const createInvitesRequest = ({ token, companyId, payload }: CreateInvitesRequest) => {
  return axios.post(`/invites/companies/${companyId}`, payload);
};

export const getInvitesRequest = ({ companyId, limit, offset, order_by, substring }: GetInvitesRequest) => {
  return axios.post(`/invites/companies/${companyId}/search`, { limit, offset, substring, order_by });
};

export const resendInviteRequest = (companyId: string, inviteId: string) => {
  return axios.post(`/invites/${inviteId}/companies/${companyId}/resend`);
};

export const deleteInviteRequest = (companyId: string, inviteId: string) => {
  return axios.delete(`/invites/${inviteId}/companies/${companyId}`);
};

export const redeemInviteTokenRequest = (token: string, inviteToken: string, starters: Starters) => {
  return axios.post(`/invites/redeem_token/${inviteToken}`, {
    pay_starter_location: starters.location,
    pay_starter_manager: starters.manager,
  });
};

export const redeemPublicInviteTokenRequest = (token: string, inviteToken: string, starters: Starters) => {
  return axios.post(`/invites/public_links/redeem_token/${inviteToken}`, {
    pay_starter_location: starters.location,
    pay_starter_manager: starters.manager,
  });
};

export const redeemPublicInviteToTeamTokenRequest = (inviteToken: string) => {
  return axios.post(`/invites/public_links/teams/redeem_token/${inviteToken}`);
};

export const createPaySchedulePublicLinkRequest = ({
  companyId,
  payScheme,
  payScheduleId,
}: CreatePaySchedulePublicLinkRequest) => {
  return axios.put(
    `/invites/companies/${companyId}/pay_schedules/${payScheduleId}/public_links?data=${payScheme}`
  );
};

export const getPaySchedulePublicLinksRequest = ({
  companyId,
  payScheduleId,
}: GetPaySchedulePublicLinkRequest) => {
  return axios.get(`/invites/companies/${companyId}/pay_schedules/${payScheduleId}/public_links`);
};

export const getUserInviteRequest = (inviteToken: string) => {
  return axios.get(`/invites/tokens/${inviteToken}`);
};

export const createTeamPublicLinkRequest = ({ companyId, teamId }: TeamPublicLinkRequest) => {
  return axios.put(`/invites/companies/${companyId}/teams/${teamId}/public_links`);
};

export const getTeamPublicLinksRequest = ({ companyId, teamId }: TeamPublicLinkRequest) => {
  return axios.get(`/invites/companies/${companyId}/teams/${teamId}/public_links`);
};
