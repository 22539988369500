import React, { Dispatch, MouseEventHandler, SetStateAction, useContext } from 'react';
import { Box, createStyles, makeStyles, SwipeableDrawer, Theme } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import { DeviceContext } from '../contexts';
import { NavDrawer } from './NavDrawer';
import { NavItem } from '../types';
import { drawerWidth } from './Header';
import { ExpandIcon } from './ExpandIcon';
import { Logo } from './Logo';

interface Props {
  handleDrawerToggle: MouseEventHandler<HTMLButtonElement>;
  open: boolean;
  mobileOpen: boolean;
  setMobileOpen: Dispatch<SetStateAction<boolean>>;
  navItems: NavItem[];
  logout: MouseEventHandler<HTMLDivElement>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      zIndex: theme.zIndex.drawer + 1,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: '63px',
    },
    toolbar: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      marginLeft: '16px',
      justifyContent: 'space-between',
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
  })
);

export const Sidebar: React.FC<Props> = ({
  handleDrawerToggle,
  open,
  mobileOpen,
  setMobileOpen,
  navItems,
  logout,
}) => {
  const classes = useStyles();
  const { iOS, isDesktop, isMobile } = useContext(DeviceContext);

  const handleMobileDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      {isMobile && (
        <SwipeableDrawer
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          anchor="left"
          open={mobileOpen}
          onOpen={handleMobileDrawerToggle}
          onClose={handleMobileDrawerToggle}>
          <Box padding={2}>
            <Logo width={104} height={32} />
          </Box>

          <NavDrawer logout={logout} setMobileOpen={setMobileOpen} navItems={navItems} />
        </SwipeableDrawer>
      )}

      {!isMobile && (
        <Drawer
          elevation={5}
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: isDesktop && open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: isDesktop && open,
              [classes.drawerClose]: !open,
            }),
          }}>
          <div className={classes.toolbar}>
            <Logo width={104} height={32} />

            <ExpandIcon open={open} handleDrawerToggle={handleDrawerToggle} />
          </div>

          <NavDrawer logout={logout} setMobileOpen={setMobileOpen} navItems={navItems} />
        </Drawer>
      )}
    </>
  );
};
