import React, { useContext } from 'react';

import { Box } from '@material-ui/core';

import { EmployeeTimeLogs } from '@vyce/core/src/modules';
import { useStyles } from '@vyce/core/src/views/payroll/styles';
import { DeviceContext } from '@vyce/core/src/contexts';

import { useTypedSelector } from '../../../hooks';

export const EmployeeTimeLogsPage = () => {
  const classes = useStyles();
  const { uuid } = useTypedSelector(state => state.user);
  const { selectedCompany } = useTypedSelector(state => state.helper);
  const { isMobile } = useContext(DeviceContext);

  return (
    <Box className={classes.moduleContainer} height={isMobile ? 'calc(100vh - 115px)' : 'unset'}>
      <EmployeeTimeLogs userId={uuid} selectedCompanyId={selectedCompany?.uuid || ''} />
    </Box>
  );
};
