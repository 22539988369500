import React from 'react';

import { Grid } from '@material-ui/core';

import { PieChartDataItem } from '@vyce/core/src/types';

import { LegendItem } from './LegendItem';

interface Props {
  legendData: PieChartDataItem[];
}

export const PieLegend = ({ legendData }: Props) => {
  return (
    <Grid container spacing={1}>
      {legendData.map((ld, index) => (
        <Grid item xs={12} key={index}>
          <LegendItem data={ld} />
        </Grid>
      ))}
    </Grid>
  );
};
