import React from 'react';

import { OnboardingStep } from '@vyce/core/src/types';

import {
  DoYouWantToClockStep,
  LocationStep,
  LocationVerifiedStep,
  ShiftStep,
  WhenClockStep,
  WorkerStep,
} from '../components';
import { stepNames } from '../config';

export const defaultSteps: OnboardingStep[] = [
  {
    component: <DoYouWantToClockStep />,
    name: stepNames.DOYOUWANTTOCLOCK_STEP,
    active: true,
  },
  {
    component: <WorkerStep />,
    name: stepNames.WORKER_NAME_STEP,
    active: true,
  },
  {
    component: <WhenClockStep />,
    name: stepNames.WHEN_CLOCK_STEP,
    active: true,
  },
  {
    component: <LocationStep />,
    name: stepNames.LOCATION_STEP,
    active: true,
  },
  {
    component: <LocationVerifiedStep />,
    name: stepNames.LOCATION_VERIFIED_STEP,
    active: true,
  },
  {
    component: <ShiftStep />,
    name: stepNames.SHIFT_STEP,
    active: true,
  },

  /*   {
      component: <AddPhotoOfWorkerStep />,
      name: 'AddPhotoOfWorkerStep',
      active: true,
    },
    {
      component: <UploadPictureStep />,
      name: stepNames.UPLOAD_PICTURE_STEP,
      active: true,
    }, */
];
