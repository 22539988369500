import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: '24px',
    maxWidth: '752px',
    borderRadius: '12px',
  },
  controlsContainer: {
    border: '1px solid #EFF0F7',
    borderRadius: '8px !important',
  },
  accordionSummary: {
    minHeight: 'auto',
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0,
      minHeight: 'auto',
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
  },
  additionalText: {
    color: theme.palette.text.secondary,
  },
  mainAccordionWrapper: {
    padding: '12px 16px',
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 'auto',
      margin: 0,
    },
  },
  expanded: {
    margin: `0 !important`,
  },
  bold: {
    fontWeight: 500,
  },
  selectRoot: {
    borderRadius: '8px',
    '& .MuiSelect-root': {
      height: '32px',
      padding: '0px 16px 0px',
      display: 'flex',
      alignItems: 'center',
      width: '120px',
      fontSize: '13px',
    },
  },
  radioContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    cursor: 'pointer',
  },
}));
