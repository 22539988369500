import React from 'react';

import { FiBriefcase } from 'react-icons/fi';
import { Theme } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { Avatar, Box, Button, Grid, Typography, useTheme } from '@material-ui/core';

import { Candidate, CONNECT_EVENTS, TagGroups } from '@vyce/core/src/types';
import { useStyles } from '@vyce/core/src/views/hiring/styles';
import { TileContainer } from '@vyce/core/src/views/hiring/components/TileContainer';
import { getUrlItems } from '@vyce/core/src/utils/url';

interface Props {
  currentCandidate: Candidate;
  goToCandidate: (id: number | string, name: string) => void;
  openConnectDialog: (event: React.MouseEvent<any>) => void;
  openCancelConnectionDialog: (event: React.MouseEvent<any>) => void;
  openDeclineConnectionDialog: (event: React.MouseEvent<any>) => void;
  openAcceptDialog: (event: React.MouseEvent<any>) => void;
  openCancelDeclinationDialog: (event: React.MouseEvent<any>) => void;
}

export const CandidateTile: React.FC<Props> = ({
  currentCandidate,
  goToCandidate,
  openConnectDialog,
  openCancelConnectionDialog,
  openDeclineConnectionDialog,
  openAcceptDialog,
  openCancelDeclinationDialog,
}) => {
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const { candidate } = useParams<{ candidate: string }>();
  const candidateUrlItems = getUrlItems(candidate);
  const id = candidateUrlItems?.id;
  const roles: string[] = currentCandidate.tags
    ?.filter(tag => tag.group === TagGroups.ROLE)
    .map(tag => tag.name);

  return (
    <TileContainer
      selected={id === currentCandidate.user_id}
      handleClick={() =>
        goToCandidate(
          currentCandidate.user_id,
          `${currentCandidate.first_name} ${currentCandidate.last_name}`
        )
      }>
      <>
        <Box display="flex" alignItems="center" marginBottom={2}>
          <Avatar className={classes.avatar} src={currentCandidate.photo?.url} />

          <Box marginLeft={2} overflow="hidden">
            <Typography variant="h6" color="primary" className={classes.bold}>
              {`${currentCandidate.first_name} ${currentCandidate.last_name}`}
            </Typography>
            <Box className={classes.textWithIcon}>
              <Box className={classes.iconWithText}>
                <FiBriefcase size="15px" color={theme.palette.primary.main} />
              </Box>
              <Typography className={classes.ellipsisOverflow} variant="subtitle2" color="primary">
                {roles?.join(', ')}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Grid container>
          <Grid item xs={6} className={classes.jobDataItem}>
            <Typography variant="subtitle2" color="textSecondary">
              Distance
            </Typography>
            {currentCandidate?.computed?.distance?.value && (
              <Typography variant="subtitle2">
                {currentCandidate.computed.distance.value} mile
                {currentCandidate.computed?.distance?.value === 1 ? '' : 's'}
              </Typography>
            )}
          </Grid>

          <Grid item xs={6} className={classes.jobDataItem}>
            <Typography variant="subtitle2" color="textSecondary">
              Endorsements
            </Typography>
            <Typography className={classes.capitalize} variant="subtitle2">
              0
            </Typography>
          </Grid>
        </Grid>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={classes.controlArea}>
          {currentCandidate.computed?.connection?.event === CONNECT_EVENTS.applied_by_worker && (
            <Box display="flex" width="100%" gridGap={16}>
              <Button
                fullWidth
                variant="outlined"
                onClick={event => openDeclineConnectionDialog(event)}
                className={classes.redButton}>
                Decline
              </Button>
              <Button
                onClick={event => openAcceptDialog(event)}
                fullWidth
                variant="contained"
                className={classes.acceptButton}>
                Accept
              </Button>
            </Box>
          )}
          {!currentCandidate.computed?.connection?.event && (
            <>
              <Typography variant="subtitle2" color="textSecondary">
                Not connected
              </Typography>

              <Button variant="contained" onClick={event => openConnectDialog(event)} color="primary">
                Connect
              </Button>
            </>
          )}

          {currentCandidate.computed?.connection?.event === CONNECT_EVENTS.applied_by_employer && (
            <>
              <Typography variant="subtitle2" color="textSecondary">
                Pending...
              </Typography>
              <Button
                onClick={event => openCancelConnectionDialog(event)}
                color="secondary"
                style={{ backgroundColor: theme.palette.warning.main }}
                variant="contained">
                Cancel connection
              </Button>
            </>
          )}

          {(currentCandidate.computed?.connection?.event === CONNECT_EVENTS.declined_by_employer ||
            currentCandidate.computed?.connection?.event === CONNECT_EVENTS.declined_by_worker) && (
            <>
              <Typography variant="subtitle2" color="error" style={{ lineHeight: '40px' }}>
                Declined
              </Typography>
              {currentCandidate.computed?.connection?.event === CONNECT_EVENTS.declined_by_employer && (
                <Button
                  onClick={event => openCancelDeclinationDialog(event)}
                  variant="contained"
                  className={classes.warningButton}>
                  Cancel Declination
                </Button>
              )}
            </>
          )}

          {(currentCandidate.computed?.connection?.event === CONNECT_EVENTS.connected_by_employer ||
            currentCandidate.computed?.connection?.event === CONNECT_EVENTS.connected_by_worker) && (
            <>
              <Typography variant="subtitle2" color="primary" style={{ lineHeight: '40px' }}>
                Connected
              </Typography>
            </>
          )}
        </Box>
      </>
    </TileContainer>
  );
};
