import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { MAIN_CONTAINER_ID } from '../../../constants';

interface Props {
  onClose: () => void;
  open: boolean;
}

export const CongratsDialog: React.FC<Props> = ({ open, onClose }) => {
  const history = useHistory();

  const goToTimesheets = () => history.push('/payroll/timesheets');
  const goToCreateUser = () => history.push('/payroll/users?invite=true');
  const handleClose = () => {
    goToTimesheets();
    onClose();
  };

  return (
    <Dialog container={document.getElementById(MAIN_CONTAINER_ID)} onClose={handleClose} open={open}>
      <DialogTitle>Congrats!</DialogTitle>
      <DialogContent>
        <Box marginBottom={1}>
          <Typography variant="subtitle1">
            Your pay schedule is all set up. You can add or upload your timesheets as soon as they are ready.
            Once you upload your timesheet you will receive an invoice from Vyce which should be paid and
            cleared well in advance of the payment date. No payments will be made to individual contractors
            without cleared funds.
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle1">
            You can go ahead and add your team to this pay schedule.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button size="large" onClick={goToTimesheets} color="primary" variant="outlined">
          Skip for now
        </Button>
        <Button size="large" onClick={goToCreateUser} color="primary" variant="contained">
          Add team members
        </Button>
      </DialogActions>
    </Dialog>
  );
};
