import React, { useContext } from 'react';

import clsx from 'clsx';
import { Avatar, Box, Paper, Typography, useTheme } from '@material-ui/core';
import dayjs from 'dayjs';

import { monochrome } from '@vyce/core/src/theme/styles';
import { getTime, getUKFormattedDate } from '@vyce/core/src/utils/dates';
import { getAvatar } from '@vyce/core/src/utils/getAvatar';
import { DeviceContext } from '@vyce/core/src/contexts';

import { useStyles } from '../../styles';
import { VerificationRow } from './VerificationRow';
import { WorkerLogProps } from '../types';

export const WorkerLog = ({
  clockInFaceVerified,
  clockInIcon = '',
  clockOutFaceVerified,
  clockOutIcon = '',
  faceVerificationTitle,
  locationVerificationTitle,
  locationName,
  first_name,
  last_name,
  gender = null,
  clockedHours,
  overtimeHours,
  check_in_timestamp,
  check_out_timestamp,
  check_in_within_area,
  check_out_within_area,
  auto_clock_out,
}: WorkerLogProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { isMobile } = useContext(DeviceContext);

  return (
    <Paper className={classes.paper} variant="outlined">
      <Box display="flex" height={32} alignItems="center" marginBottom="12px">
        <Typography variant="h6">
          {first_name} {last_name}
        </Typography>
      </Box>

      <Typography className={classes.subtitle}>
        Log for {dayjs(check_in_timestamp).format('dddd DD/MM/YYYY')}{' '}
      </Typography>

      <Box border={`1px solid ${theme.palette.divider}`} borderRadius={8} marginTop="12px">
        <Box
          borderRadius={8}
          padding="12px 16px"
          display="flex"
          bgcolor={theme.palette.type === 'light' ? monochrome.lightest : monochrome.darkest}>
          <Avatar className={classes.activityAvatar} src={clockInIcon || getAvatar(gender)} />

          <Box flex={1} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
            <Typography
              className={clsx(classes.subtitle, classes.bold500, classes.textCenter, {
                [classes.textSize14]: isMobile,
              })}>
              {first_name} was on location for
            </Typography>

            <Typography color="primary" variant="h6" className={classes.lineHeight30}>
              {clockedHours} hours
            </Typography>

            {!!overtimeHours && (
              <Typography
                color="primary"
                variant="body1"
                className={clsx(classes.lineHeight30, classes.bold500)}>
                Paid OT Hours: {overtimeHours}
              </Typography>
            )}
          </Box>

          <Avatar className={classes.activityAvatar} src={clockOutIcon || getAvatar(gender)} />
        </Box>

        <VerificationRow
          leftVerified={clockInFaceVerified}
          rightVerified={clockOutFaceVerified}
          titles={[faceVerificationTitle]}
        />
        <VerificationRow
          leftVerified={check_in_within_area}
          rightVerified={check_out_within_area}
          titles={
            isMobile
              ? [locationName]
              : [`${locationVerificationTitle}${locationName ? ', ' + locationName : ''}`]
          }
        />
        <VerificationRow
          leftVerified={true}
          rightVerified={true}
          titles={[getUKFormattedDate(check_in_timestamp), getUKFormattedDate(check_out_timestamp)]}
        />
        <VerificationRow
          leftVerified={true}
          rightVerified={!auto_clock_out}
          rightTooltip={auto_clock_out ? 'Automated Clock Out' : ''}
          titles={[getTime(check_in_timestamp), getTime(check_out_timestamp)]}
        />
      </Box>
    </Paper>
  );
};
