import React from 'react';

import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/react';

import config, { ENVIRONMENTS } from '@vyce/core/src/api/config';
import { Fallback } from '@vyce/core/src/components';
import '@vyce/core/src/index.css';

import { App } from './App';
import reportWebVitals from './reportWebVitals';

if (config.environment === ENVIRONMENTS.PROD) {
  ReactGA.initialize(config.gaMeasurementIdProdWorkApp);

  Sentry.init({
    dsn: 'https://c705c2bd68fa49b984a108d2be344b15@o4505159221248000.ingest.sentry.io/4505159511965696',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactDOM.render(
  <ErrorBoundary FallbackComponent={Fallback}>
    <App />
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (config.environment === ENVIRONMENTS.PROD) {
  const SendAnalytics = () => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
    });
  };
  reportWebVitals(SendAnalytics);
}
