import React from 'react';

import clsx from 'clsx';
import { Box, makeStyles, Theme, createStyles } from '@material-ui/core';
import Markdown from 'react-markdown';

import { AIChatFile, AIChatMessage } from '@vyce/core/src/types';

import { CopyToClipboard } from '../../../components/CopyToClipboard';
import { FileCard } from './FileCard';

interface Props {
  message: AIChatMessage;
  file?: AIChatFile;
}

const boldRegex = /\*\*(.*?)\*\*/g;

export const Message: React.FC<Props> = ({ message, file }) => {
  const classes = useStyles();
  const isUserMessage = message.role === 'user';
  const content = message.content.replace(/\n/g, '<br/>').replace(boldRegex, '<strong>$1</strong>');

  return (
    <>
      <Box
        display="flex"
        alignItems="flex-end"
        flexDirection="column"
        gridGap={4}
        style={{ alignSelf: isUserMessage ? 'end' : undefined }}>
        {file && <FileCard borderRadius="16px 16px 0  16px" file={file} />}
        <Box
          className={clsx(
            classes.container,
            { [classes.userMessage]: isUserMessage },
            { [classes.systemMessage]: !isUserMessage }
          )}>
          <Markdown>{message.content}</Markdown>
        </Box>
        {!isUserMessage && (
          <CopyToClipboard
            type="text/html"
            text={`${content}
        <br/>
        <br/>
        <i style="color:blue;text-align:center;"">Powered by <a href="https://www.vyce.io/">Vyce.io</a> AI Assistant</i>`}
          />
        )}
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: 16,
      paddingRight: 16,
      borderRadius: 16,
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 12,
        paddingRight: 12,
        borderRadius: 12,
      },
    },
    userMessage: {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF',
      borderBottomRightRadius: 0,
      width: 'fit-content',
    },
    systemMessage: {
      backgroundColor: theme.palette.background.paper,
      borderBottomLeftRadius: 0,
    },
  })
);
