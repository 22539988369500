import React, { useMemo, useState } from 'react';

import { Box, Paper, Tooltip, Typography } from '@material-ui/core';
import { HiOutlineTrash } from 'react-icons/hi';
import { IoMdInformationCircleOutline } from 'react-icons/io';

import useStyles from '../styles';
import { AdditionalDocument } from '../../../types';
import { DOC_TYPES } from '../../../constants';
import { AppA, AppIconButton, ConfirmDialog } from '../../../components';
import { deleteAdditionalDocumentRequest } from '../../../api/legend/checkers';
import config from '../../../assets/config';

interface Props {
  document: AdditionalDocument;
  getAdditionalDocuments: Function;
}

export const AdditionalDocumentCard: React.FC<Props> = ({ document, getAdditionalDocuments }) => {
  const classes = useStyles();
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  const isFile = useMemo(() => {
    const extensions = ['.pdf', '.doc', '.docx'];
    return extensions.some(ext => document.document.url.includes(ext));
  }, [document.document.url]);

  const getDocTypeTitle = (type: string): string => {
    return DOC_TYPES.find(item => item.value === type)?.title || '';
  };

  const deleteDocument = async () => {
    try {
      await deleteAdditionalDocumentRequest(document.user_id, document.uuid);
      getAdditionalDocuments();
      setOpenConfirmDialog(false);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Paper variant="outlined" className={classes.paper}>
        <Box display="flex" height={45} alignItems="center" justifyContent="space-between" width="100%">
          <Typography className={classes.bold} color="primary" variant="h6">
            {getDocTypeTitle(document.doc_type)}
          </Typography>

          <Box display="flex">
            {document.description && (
              <Box marginRight={1}>
                <Tooltip title={document.description}>
                  <AppIconButton>
                    <IoMdInformationCircleOutline size="15px" />
                  </AppIconButton>
                </Tooltip>
              </Box>
            )}

            <Tooltip title="Delete">
              <AppIconButton onClick={() => setOpenConfirmDialog(true)} variant="error">
                <HiOutlineTrash size="15px" />
              </AppIconButton>
            </Tooltip>
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-start" width="100%">
          <Typography className={classes.bold} color="textSecondary">
            {document.title}
          </Typography>
        </Box>

        {isFile ? (
          <Box
            height={250}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center">
            <img height={120} alt={getDocTypeTitle(document.doc_type)} src={config.icons3D.document} />

            <AppA href={document.document?.url} content="Click to download" />
          </Box>
        ) : (
          <AppA
            href={document.document?.url}
            content={
              <img
                alt={getDocTypeTitle(document.doc_type)}
                className={classes.docImage}
                src={document.document?.url}
              />
            }
          />
        )}
      </Paper>

      <ConfirmDialog
        handleClose={() => setOpenConfirmDialog(false)}
        open={openConfirmDialog}
        confirmText="Delete"
        cancelText="Cancel"
        title={`Are you sure you want to delete ${getDocTypeTitle(document.doc_type)}?`}
        handleConfirm={() => deleteDocument()}
      />
    </>
  );
};
