import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core';

export const useDeviceData = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isTablet = useMediaQuery(theme.breakpoints.up('sm')) && !isDesktop;
  const isLargeDesktop = useMediaQuery('(min-width:1440px)');
  const iOS = (window.process as any)?.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return { isMobile, isDesktop, isLargeDesktop, iOS, isTablet };
};
