import { combineReducers } from 'redux';
import userReducer from './userReducer';
import helperReducer from './helperReducer';

const reducers = combineReducers({
  user: userReducer,
  helper: helperReducer,
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
