import React, { useMemo } from 'react';

import { CircularProgress, useTheme } from '@material-ui/core';

interface Props {
  loading: boolean;
  title: string;
  loaderVariant: 'primary' | 'paper';
}

export const ButtonTitleWithLoading: React.FC<Props> = ({ loading, title, loaderVariant }) => {
  const theme = useTheme();

  const color = useMemo(() => {
    switch (loaderVariant) {
      case 'paper':
        return theme.palette.background.paper;
      case 'primary':
        return theme.palette.primary.main;
    }
  }, [loaderVariant]);

  return <>{loading ? <CircularProgress size={26.25} style={{ color }} /> : title}</>;
};
