import React, { ReactElement } from 'react';

import { LatLngExpression } from 'leaflet';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';

import { AppMarker } from './AppMarker';
import { MapMarker } from '../../types';
import { useMapStyle } from './hooks/useMapStyle';
import { LONDON_POSITION, MAP_BOX_ATTRIBUTION } from './constants';

interface Props {
  markers: MapMarker[];
  selectedMarker?: MapMarker;
  onMarkerClick: (id: string, name: string) => void;
  myPosition?: {
    position: LatLngExpression | undefined;
    popupText: string;
  };
  centerPosition?: LatLngExpression;
  height: string;
  zoom?: number;
  children?: ReactElement;
  scrollWheelZoom?: boolean;
  clustered?: boolean;
  whenCreated?: (map: any) => void;
}

export const ChangeView = ({ center, zoom }: { center?: LatLngExpression; zoom: number }) => {
  if (center) {
    const map = useMap();
    map.setView(center, zoom);
  }

  return null;
};

export const MapView: React.FC<Props> = ({
  markers = [],
  onMarkerClick,
  myPosition,
  height,
  selectedMarker,
  zoom = 8,
  centerPosition,
  children,
  scrollWheelZoom = true,
  clustered = true,
  whenCreated,
}) => {
  const { mapStyleId, freeUrl, tileLayerUrl } = useMapStyle();

  const getMarkerComponent = (marker: MapMarker) => (
    <AppMarker
      picture={marker.picture}
      popupText={marker.name}
      draggable={!!marker.draggable}
      eventHandlers={marker.eventHandlers}
      size={marker.size}
      onMarkerClick={() => {
        if (marker.id && marker.name) {
          onMarkerClick(marker.id, marker.name);
        }
      }}
      key={marker.id}
      position={marker.lat && marker.lon ? [marker.lat, marker.lon] : LONDON_POSITION}
    />
  );

  return (
    <>
      <MapContainer
        whenCreated={whenCreated}
        key={mapStyleId}
        scrollWheelZoom={scrollWheelZoom}
        style={{ height, width: '100%', borderRadius: '15px' }}
        center={centerPosition || myPosition?.position || LONDON_POSITION}
        zoom={zoom}>
        <ChangeView center={centerPosition} zoom={zoom} />
        <TileLayer
          url={tileLayerUrl}
          // url={freeUrl}
          // attribution={CARTO_BOX_ATTRIBUTION}
          attribution={MAP_BOX_ATTRIBUTION}
        />
        {clustered ? (
          <MarkerClusterGroup chunkedLoading>
            {markers.map(marker => getMarkerComponent(marker))}
          </MarkerClusterGroup>
        ) : (
          markers.map(marker => getMarkerComponent(marker))
        )}

        {myPosition?.position && (
          <AppMarker popupText={myPosition.popupText} isMe={true} position={myPosition?.position} />
        )}
        {selectedMarker?.lat && selectedMarker?.lon && (
          <AppMarker
            picture={selectedMarker.picture}
            selected={true}
            draggable={!!selectedMarker.draggable}
            eventHandlers={selectedMarker.eventHandlers}
            position={[selectedMarker.lat, selectedMarker.lon]}
          />
        )}
        {children}
      </MapContainer>
    </>
  );
};
