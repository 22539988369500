import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { UserPersonalInfo } from '@vyce/core/src/views/profile/user/UserPersonalInfo';
import { EmploymentInfo } from '@vyce/core/src/views/profile/user/EmploymentInfo';
import { Documents } from '@vyce/core/src/views/profile/user/Documents';
import { HealthAndSafety, SEARCH_HEALTH_SURVEY_SUBSTRING } from '@vyce/core/src/views/profile/user/H&S';
import { Qualifications } from '@vyce/core/src/modules';
import { Employee, UserSurvey, Worker } from '@vyce/core/src/types';
import {
  downloadAgreementRequest,
  downloadHealthAndSafetyPDFRequest,
  downloadPayslipsRequestByEmployee,
  getAgreementsRequest,
  getEmployeeTimesheetsRequest,
  getPayslipsPDFRequest,
  getPayslipsRequest,
  updateEmployeeRequest,
} from '@vyce/core/src/api/pay';
import { getTagListRequest } from '@vyce/core/src/api/handbook';
import {
  createCustomQualificationRequest,
  deleteCustomQualificationImageRequest,
  deleteCustomQualificationRequest,
  getCheckersRequest,
  getQualificationsRequest,
  getRtwRequest,
  triggerCheckerRequest,
  updateCustomQualificationRequest,
  uploadCustomQualificationImageRequest,
} from '@vyce/core/src/api/checkers';
import {
  uploadPassportRequest,
  uploadSelfieRequest,
  getPassportRequest,
} from '@vyce/core/src/api/documents';
import { getSurveyAnswers, getSurveysRequest, saveSurveyAnswers } from '@vyce/core/src/api/survey';
import { WorkerProfessionalInfo } from '@vyce/core/src/views/profile/user/WorkerProfessionalInfo';
import { Agreements } from '@vyce/core/src/views/profile/user/Agreements';
import { EmployeePayments } from '@vyce/core/src/views/payroll/components/EmployeePayments';
import { EmployeeTimesheets } from '@vyce/core/src/views/payroll/components/EmployeeTimesheets';
import { changePasswordRequest } from '@vyce/core/src/api/auth';

import { useActions, useTypedSelector } from '../../hooks';
import { PROFILE_TABS } from './constants';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

export const RouteProfileComponent: React.FC = () => {
  const { updateUser, uploadUserAvatar, me, verifyPhone, updateWorker } = useActions();
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const { user, helper } = useTypedSelector(state => state);
  const tab = PROFILE_TABS.find(tab => window.location.href.includes(tab.link));
  const [survey, setSurvey] = useState<UserSurvey>();
  const mountedRef = useRef(true);
  const { userDataLoading, access_token, selectedCompany } = helper;

  const updateEmployee = async (data: Employee, employeeId: string) => {
    try {
      await updateEmployeeRequest(access_token, data, employeeId);
      await me();
      showNotification({
        message: 'Data has been updated',
        options: { variant: 'success' },
      });
    } catch (e) {
      handleServerError(e);
    }
  };

  const getSurveys = useCallback(async () => {
    if (!mountedRef.current) return;
    try {
      const surveysRes = await getSurveysRequest(SEARCH_HEALTH_SURVEY_SUBSTRING);
      const surveyRes = surveysRes.data?.items[0];
      if (!surveyRes) {
        return showNotification({
          message: 'Health and Safety Questionnaire not found',
          options: { variant: 'error' },
        });
      }
      const res = await getSurveyAnswers(surveyRes?.uuid);
      setSurvey(res.data);
    } catch (e) {
      handleServerError(e);
    }
  }, [access_token]);

  useEffect(() => {
    getSurveys();
    return () => {
      mountedRef.current = false;
    };
  }, [getSurveys]);

  switch (tab?.label) {
    case 'Personal':
      return (
        <UserPersonalInfo
          changePasswordRequest={changePasswordRequest}
          verifyPhone={verifyPhone}
          updateUser={updateUser}
          uploadUserAvatar={uploadUserAvatar}
          token={access_token}
          userDataLoading={userDataLoading}
          user={user}
        />
      );
    case 'Professional':
      return (
        <WorkerProfessionalInfo
          updateWorker={updateWorker}
          helper={helper}
          userDataLoading={userDataLoading}
          worker={user.worker as Worker}
        />
      );
    case 'Employment':
      return (
        <EmploymentInfo
          userDataLoading={userDataLoading}
          updateEmployee={updateEmployee}
          helper={helper}
          employee={user.employee}
          email={user.email}
          token={access_token}
        />
      );
    case 'ID & RTW':
      return (
        <Documents
          getUser={me as any}
          getRtwRequest={getRtwRequest}
          getPassportRequest={getPassportRequest}
          uploadSelfieRequest={uploadSelfieRequest}
          uploadPassportRequest={uploadPassportRequest}
          triggerCheckerRequest={triggerCheckerRequest}
          user={user}
        />
      );
    case 'Qualifications':
      return (
        <Qualifications
          getQualificationsRequest={getQualificationsRequest}
          getCheckersRequest={getCheckersRequest}
          triggerCheckerRequest={triggerCheckerRequest}
          createCustomQualificationRequest={createCustomQualificationRequest}
          updateCustomQualificationRequest={updateCustomQualificationRequest}
          deleteCustomQualificationRequest={deleteCustomQualificationRequest}
          uploadCustomQualificationImageRequest={uploadCustomQualificationImageRequest}
          deleteCustomQualificationImageRequest={deleteCustomQualificationImageRequest}
          getTagListRequest={getTagListRequest}
        />
      );
    case 'Medical':
      return (
        <HealthAndSafety
          downloadHealthAndSafetyPDFRequest={downloadHealthAndSafetyPDFRequest}
          survey={survey}
          setSurvey={setSurvey}
          saveSurveyAnswers={saveSurveyAnswers}
        />
      );
    case 'Payments':
      return (
        <EmployeePayments
          getPayslipsPDFRequest={getPayslipsPDFRequest}
          downloadUserPayslipsRequest={downloadPayslipsRequestByEmployee}
          searchPayslipsRequest={getPayslipsRequest}
          isLegend={false}
          selectedCompany={selectedCompany}
          unit="payments"
          withProfileButton
        />
      );
    case 'Timesheets':
      return (
        <EmployeeTimesheets
          onlySubmitted
          token={access_token}
          employeeId={user.employee?.uuid}
          getEmployeeTimesheetsRequest={getEmployeeTimesheetsRequest}
          unit="timesheets"
          withProfileButton
        />
      );
    case 'Agreements':
      return (
        <Agreements
          downloadAgreementRequest={downloadAgreementRequest}
          getAgreementsRequest={getAgreementsRequest}
          token={access_token}
          unit="agreements"
          withProfileButton
        />
      );
    default:
      return <></>;
  }
};
