import React from 'react';

import {
  BankAccountName,
  BankAccountNumberField,
  SortCodeField,
  BuildingSocietyRollNumberField,
} from '@vyce/core/src/components/controlled-inputs';
import { OnboardingStepTitle } from '@vyce/core/src/components';

interface Props {
  title?: string;
}

export const BankDetailsStep: React.FC<Props> = ({ title = 'Bank Details' }) => {
  return (
    <>
      <OnboardingStepTitle text={title} />

      <BankAccountName margin="normal" />

      <BankAccountNumberField margin="normal" />

      <SortCodeField margin="normal" />

      <BuildingSocietyRollNumberField margin="normal" />
    </>
  );
};
