import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { backgrounds, monochrome } from '../../theme/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '100%',
      height: '100%',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    uploaderContainer: {
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      gridGap: '8px',
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
      width: '100%',
      cursor: 'pointer',
      position: 'relative',
      overflow: 'hidden',
      [theme.breakpoints.up('md')]: {
        '&:hover': {
          '& .hiddenControlBlock': {
            display: 'flex',
          },
        },
      },
    },

    uploaderContainerPaddings: {
      padding: '12px',
    },
    uploaderContainerBordered: {
      border: `1px dashed  ${monochrome.dark}`,
    },
    typography: {
      textAlign: 'center',
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    smallText: {
      fontWeight: 500,
    },
    extraSmallText: {
      fontSize: '11px',
    },
    fileNameTextContainer: {
      height: 16,
      maxWidth: '100%',
      color: theme.palette.text.secondary,

      '& > p': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },

    smallPdfPreview: {
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      borderRadius: 12,
    },
    blured: {
      filter: 'blur(2px)',
    },
    smallPreviewContainer: {
      cursor: 'pointer',
    },
    hiddenControlBlock: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      background: theme.palette.type === 'dark' ? backgrounds.darkModePaper : backgrounds.paper,
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
      display: 'none',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      gridGap: 16,
      padding: '12px',
      userSelect: 'none',
    },

    showingHiddenControlBlock: {
      display: 'flex',
    },

    buttonWrapper: {
      width: '40px',
      height: '40px',
      padding: 12,
      minWidth: 'max-content !important',

      '& .MuiButton-startIcon': {
        marginRight: 0,
        marginLeft: 0,
      },
    },

    editButtonWrapper: {
      width: '32px',
      height: '32px',
      padding: 8,
      minWidth: 'max-content !important',
      position: 'absolute',
      display: 'none',
      backgroundColor: '#EBECFE !important',

      '& .MuiButton-startIcon': {
        marginRight: 0,
        marginLeft: 0,
      },

      [theme.breakpoints.down('md')]: {
        display: 'unset',
      },
    },

    selectedImage: {
      maxWidth: '100%',
      objectFit: 'contain',
    },

    dialogActions: {
      position: 'sticky',
      bottom: 0,
      backgroundColor: theme.palette.type === 'dark' ? monochrome.darker : '#fff',
    },

    textCentering: {
      textAlign: 'center',
    },
  })
);
