import { CustomControlSet } from '@vyce/core/src/components';

export const MAIN_CONTAINER_ID = 'main-container-id';

export const CUSTOM_SOURCE = 'custom';
export const CHECKER_NAME = 'check_qualifications';

export const QualificationTypes = {
  industry: 'industry',
  academic: 'academic',
} as const;

export const Modes = {
  create: 'create',
  update: 'update',
} as const;

export const Types = {
  inside: 'inside',
  outside: 'outside',
} as const;

export const ItemTypes = {
  QUALIFICATION: 'Qualification',
};

export const rules = { required: 'This field is required' };

export const notRequiredRules = { required: false };

export const previewControlSet: CustomControlSet = ['preview', 'download'];

export const dateFields = ['start_date', 'expiry_date'];

export const listedDefaultValues = {
  checker_name: '',
  selected_option: '',
};

export const notListedValues = {
  title: '',
  extra_text: '',
  date: '',
  details: '',
};
