import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { AppTextField } from '@vyce/core/src/components/inputs/AppTextField';
import { OnboardingStepTitle } from '@vyce/core/src/components/OnboardingStepTitle';
import { PasswordField } from '@vyce/core/src/components/controlled-inputs/PasswordField';

const idRules = {
  required: 'Government Gateway ID is required',
};

const passwordRules = {
  required: 'Government Gateway password is required',
};

export const GovernmentGatewayStep = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <OnboardingStepTitle text="Please provide your Government Gateway ID and password..." />

      <Controller
        control={control}
        rules={idRules}
        name="sender_id"
        render={({ field }) => (
          <AppTextField
            label="Government Gateway ID"
            margin="normal"
            fullWidth
            {...field}
            error={!!errors?.sender_id?.message}
            helperText={(errors?.sender_id?.message as string) || ''}
          />
        )}
      />

      <PasswordField
        name="password"
        margin="normal"
        label="Government Gateway Password"
        rules={passwordRules}
      />
    </>
  );
};
