import React, { useContext } from 'react';

import { Box } from '@material-ui/core';

import { DeviceContext } from '@vyce/core/src/contexts';
import { EnrichedItem } from '../types';
import { LegendItem } from './LegendItem';
import { useStyles } from '../styles';

export const Legend = ({ data }: { data: EnrichedItem[][] }) => {
  const classes = useStyles();
  const { isMobile } = useContext(DeviceContext);

  return (
    <Box display="flex" justifyContent="center" className={classes.legendWrapper} minHeight="144px">
      {data.map((block, index) => (
        <Box
          key={index}
          display="flex"
          gridGap={16}
          flexDirection="column"
          maxWidth={isMobile ? '240px' : '500px'}
          minWidth={isMobile ? '240px' : '300px'}>
          {block.map(item => (
            <LegendItem key={item.id} {...item} />
          ))}
        </Box>
      ))}
    </Box>
  );
};
