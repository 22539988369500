import React from 'react';

import { Box } from '@material-ui/core';
import clsx from 'clsx';

import { DetailItem } from '@vyce/core/src/types';

import useStyles from '../styles';
import { DataRowItem } from './DataRowItem';
import { DetailsHeader } from './DetailsHeader';

interface Props {
  full_name: DetailItem;
  status: DetailItem;
  check_date: DetailItem;
  ref_number: DetailItem;
  expiration_date: DetailItem;
  rtwPhotoUrl: string;
  verified: boolean;
  isShow: boolean;
}

export const RTWDetails = ({
  full_name,
  status,
  check_date,
  expiration_date,
  ref_number,
  rtwPhotoUrl,
  verified,
  isShow,
}: Props) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" gridGap={8}>
      <DetailsHeader title="Right to Work Details" verified={verified} />

      <Box display="flex" gridGap={16}>
        {isShow && (
          <Box className={clsx(classes.blockWrapper, classes.informationBlock)}>
            <DataRowItem {...full_name} />
            <DataRowItem {...status} />
            <DataRowItem {...check_date} />
            <DataRowItem {...expiration_date} />
            <DataRowItem {...ref_number} />
          </Box>
        )}

        {rtwPhotoUrl && (
          <Box
            display="flex"
            flexDirection="column"
            gridGap={16}
            className={clsx(classes.blockWrapper, classes.photoBlock)}>
            <img alt="document" className={classes.RTWImage} src={rtwPhotoUrl} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
