import React, { MouseEventHandler, ReactElement, useContext } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { HiOutlineMenu } from 'react-icons/hi';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Box, createStyles, makeStyles, Theme, useTheme } from '@material-ui/core';
import clsx from 'clsx';

import { DeviceContext } from '../contexts';
import { CompanyPosition, ThemeType, User } from '../types';
import { AppIconButton } from './AppIconButton';
import { AppBreadcrumbs } from './AppBreadcrumbs';
import { ThemeSwitcher } from './ThemeSwitcher';

export const drawerWidth = 224;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: theme.palette.background.paper,
      paddingLeft: 64,
      boxShadow: `-1px 1px 0px ${theme.palette.type === 'dark' ? 'gba(255, 255, 255, 0.12)' : '#D9DBE9'}`,
      color: theme.palette.text.primary,
      transition: theme.transitions.create(['width', 'margin', 'padding'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
      },
    },
    appBarShift: {
      marginLeft: drawerWidth,
      paddingLeft: 1,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin', 'padding'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    spacer: {
      flexGrow: 1,
    },
    userInfo: {
      cursor: 'pointer',
    },
    avatar: {
      height: '30px',
      width: '30px',
      backgroundColor: theme.palette.primary.main,
      marginLeft: theme.spacing(2),
    },
  })
);

interface Props {
  handleDrawerToggle: MouseEventHandler<HTMLButtonElement>;
  changeColorTheme: (type: ThemeType) => void;
  loading: boolean;
  open: boolean;
  setShowTour?: Function;
  setPosition?: Function;
  selectedPosition?: CompanyPosition;
  user: User;
  children: ReactElement;
  themeType?: ThemeType;
}

export const Header: React.FC<Props> = ({
  setShowTour,
  changeColorTheme,
  open,
  handleDrawerToggle,
  user,
  children,
  themeType,
}) => {
  const theme = useTheme<Theme>();
  const classes = useStyles();
  const { isDesktop } = useContext(DeviceContext);

  const isAppBarShift = isDesktop && open;

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: isAppBarShift,
      })}>
      <Toolbar>
        {!isDesktop && (
          <AppIconButton
            size="small"
            variant="paper"
            disableTouchRipple
            disableFocusRipple
            onClick={handleDrawerToggle}>
            <HiOutlineMenu data-tour="navigation-menu" size="25px" color={theme.palette.text.secondary} />
          </AppIconButton>
        )}

        <AppBreadcrumbs />

        <div className={classes.spacer} />

        <ThemeSwitcher changeColorTheme={changeColorTheme} themeType={themeType} />

        {user?.uuid && (
          <Box marginLeft={1} display="flex" alignItems="center">
            {setShowTour && (
              <AppIconButton size="small" onClick={() => setShowTour(true)} variant="paper">
                <AiOutlineInfoCircle data-tour="show-tour" size="21px" color={theme.palette.primary.main} />
              </AppIconButton>
            )}

            <Box marginLeft={2}>{children}</Box>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};
