import React, { useMemo } from 'react';

import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { RowSchemaProps, GridRowData } from '../types';
import { useStyles } from '../styles';

export const AccordionCellItem = <T,>({
  row,
  options,
}: {
  row: GridRowData<T>;
  options: RowSchemaProps<T>;
}) => {
  const classes = useStyles();
  const { titleName, field, renderCell, valueGetter, valueFormatter, extraComponent, flex = 0.5 } = options;

  const valueCompoent = useMemo(() => {
    if (renderCell && typeof renderCell === 'function') {
      return renderCell(row);
    }
    if (valueGetter && typeof valueGetter === 'function') {
      return <Typography className={clsx(classes.text, classes.bold)}>{valueGetter(row)}</Typography>;
    }
    if (valueFormatter && typeof valueFormatter === 'function') {
      return <Typography className={clsx(classes.text, classes.bold)}>{valueFormatter(row)}</Typography>;
    }
    return <Typography className={clsx(classes.text, classes.bold)}>{row[field] ?? ''}</Typography>;
  }, [field, renderCell, row, valueGetter]);

  const extra = useMemo(() => {
    if (extraComponent && typeof extraComponent === 'function') {
      return extraComponent(row);
    }
  }, [extraComponent, row]);

  return (
    <Box className={classes.accordionCellWrapper} flex={flex}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          {titleName && <Typography className={classes.text}>{titleName}</Typography>}
          {valueCompoent}
        </Box>
        {extra && <Box>{extra}</Box>}
      </Box>
    </Box>
  );
};
