import React, { MouseEvent } from 'react';

import { BarItemProps } from '@nivo/bar';
import { useTheme, Box } from '@material-ui/core';
import clsx from 'clsx';
import { BasicTooltip, useTooltip } from '@nivo/tooltip';

import { useStyles } from '../styles';
import { minBarHeight, maxBarWidth, defaultColor } from '../config';

const style = { cursor: 'pointer' };

export const CustomBarComponent = ({ bar: { x, y, ...restProps }, onClick }: BarItemProps<any>) => {
  const theme = useTheme();
  const classes = useStyles();
  const { showTooltipFromEvent, hideTooltip } = useTooltip();
  const { data, width, height } = restProps;
  const { lightColor = defaultColor.lightColor, darkColor = defaultColor.darkColor } = data.data;
  const outsideTextPosition = height <= minBarHeight;
  const newHeight = outsideTextPosition ? y - 10 : y + height / 2;
  const newWidth = width > maxBarWidth ? maxBarWidth : width;

  const handleClick = (e: any) => {
    onClick && onClick({ ...data, color: restProps.color }, e);
  };

  const handleTooltip = (event: MouseEvent<SVGRectElement>) => {
    showTooltipFromEvent(
      <Box className={classes.tooltipWrapper}>
        <BasicTooltip id={data.indexValue} />
      </Box>,
      event
    );
  };

  return (
    <g
      onClick={handleClick}
      style={style}
      onMouseEnter={handleTooltip}
      onMouseMove={handleTooltip}
      onMouseLeave={hideTooltip}>
      <rect
        x={x + width / 2 - newWidth / 2}
        y={y}
        width={newWidth}
        height={height}
        fill={theme.palette.type === 'dark' ? darkColor : lightColor}
      />
      <text
        x={x + width / 2}
        y={newHeight}
        className={clsx(classes.barText, {
          [classes.outsideText]: outsideTextPosition,
          [classes.insideText]: !outsideTextPosition,
        })}
        textAnchor="middle"
        dominantBaseline="middle">
        {data.formattedValue === '0' ? null : data.formattedValue}
      </text>
    </g>
  );
};
