import React from 'react';

import { Box, MenuItem, Typography } from '@material-ui/core';
import capitalize from 'lodash/capitalize';

import { AppSelect } from '@vyce/core/src/components/inputs';
import { TimePeriodSettings } from '@vyce/core/src/types';

import { timelogDays } from '../config';
import { useStyles } from '../styles';

interface Props {
  settings: TimePeriodSettings;
  endDay: string;
  onWeekdayChange: (day: string) => void;
}

export const PeriodSettings: React.FC<Props> = ({ settings, onWeekdayChange, endDay }) => {
  const classes = useStyles();

  return (
    <>
      <Box display="flex" alignItems="center" gridGap={8}>
        <Typography variant="caption" color="textSecondary">
          The report will include Time Logs from midnight on
        </Typography>

        <AppSelect
          value={settings.weekday_start}
          onChange={e => onWeekdayChange(e.target.value as string)}
          className={classes.selectRoot}>
          {timelogDays?.map(day => (
            <MenuItem key={day} value={day} selected={day === settings.weekday_start}>
              <span>{capitalize(day)}</span>
            </MenuItem>
          ))}
        </AppSelect>
      </Box>

      <Typography variant="caption" color="textSecondary">
        through to 23.59 on {capitalize(endDay)}.
      </Typography>
    </>
  );
};
