import React from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';
import { Box, Button, useTheme } from '@material-ui/core';

import { OnboardingStepTitle } from '../../../../components';
import { BREAKS_FIELD_NAME, EMPTY_BREAK } from '../../../../modules/timeModule/constants';
import { Break } from '../../../../types';
import { EditBreak } from '../../components';

interface Props {
  scrollDown: Function;
}

const PARENT_FIELD = 'shift';

export const BreaksStep: React.FC<Props> = ({ scrollDown }) => {
  const theme = useTheme();
  const { control, watch } = useFormContext();
  const fieldName = `${PARENT_FIELD}.${BREAKS_FIELD_NAME}`;
  const breaks: Break[] = watch(fieldName);
  const { append, remove } = useFieldArray({
    control,
    name: fieldName,
  });

  const addAnotherBreak = () => {
    append(EMPTY_BREAK);
    setTimeout(scrollDown, 0);
  };

  return (
    <>
      <OnboardingStepTitle text="Do you want to add any breaks during shifts?" />

      {breaks?.map((item, index) => (
        <Box
          key={index}
          borderTop={index > 0 ? `1px solid ${theme.palette.divider}` : 'none'}
          paddingBottom={2}
          paddingTop={2}>
          <EditBreak index={index} parentField={fieldName} remove={remove} isCloseButtonTransparent={true} />
        </Box>
      ))}

      <Box display="flex" justifyContent="flex-end" marginTop={2}>
        <Button
          onClick={addAnotherBreak}
          size="small"
          color="primary"
          style={{ width: 182 }}
          variant="outlined">
          Add {breaks.length ? 'another' : 'a'} break
        </Button>
      </Box>
    </>
  );
};
