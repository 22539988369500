import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { AppTextField } from '../inputs/AppTextField';

const rules = {
  required: 'Limited Company Number is required',
};

export const LimitedCompanyNumberField: React.FC = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      rules={rules}
      name="company_reg_number"
      render={({ field }) => (
        <AppTextField
          {...field}
          inputProps={{
            maxLength: '15',
          }}
          error={!!errors.company_reg_number?.message}
          id="limited-company-number"
          label="Limited Company Number"
          margin="normal"
          fullWidth
          helperText={(errors.company_reg_number?.message as string) || ''}
        />
      )}
    />
  );
};
