import React from 'react';

import { Box, Typography, MenuItem, MenuList } from '@material-ui/core';
import type { GridColDef } from '@mui/x-data-grid';
import { FormProvider } from 'react-hook-form';
import noop from 'lodash/noop';

import { AppSelect } from '@vyce/core/src/components/inputs';
import { AppDataGrid, ConfirmDialog, GridActions, GridCellWithAvatar } from '@vyce/core/src/components';
import { ControlledAutocomplete } from '@vyce/core/src/components/controlled-inputs';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { getAvatar } from '@vyce/core/src/utils/getAvatar';
import { getUKFormattedDate, getTime } from '@vyce/core/src/utils/dates';

import { useWidgetData } from './hooks';
import { ExtendedLocationUserDTO, Props } from './types';
import { useStyles } from './styles';
import { statusesEnum, statusOptions, statuses } from './config';
import { SelectorCustomValue } from './components/SelectorCustomValue';

/* Location Users Widget */
export const LocationUsersWidget = (props: Props) => {
  const classes = useStyles();

  const {
    locationUsers,
    locationSelectOptions,
    total,
    loading,
    selectedStatusId,
    methods,
    isConfirmDeleteDialogOpen,
    candidateToRemove,
    closeGridAction,
    handlePageChange,
    setSelectedStatusId,
    handleRemoveUser,
    closeConfirmDialog,
    removeUserFromLocation,
  } = useWidgetData(props);

  const columns: GridColDef[] = [
    {
      field: 'full_name',
      headerName: 'Name',
      flex: 1,
      disableColumnMenu: true,
      minWidth: 150,
      sortable: false,
      renderCell: params => (
        <GridCellWithAvatar
          avatarUrl={params.row.photo_url}
          avatarPlaceholder={getAvatar(params.row?.gender)}
          name={`${params.row.full_name}`}
          link={`/time/users/${params.row?.full_name.split(' ')[0]}_${params.row?.user_id}/logs`}
          primaryBorder={params.row.status === statusesEnum.clocked_in}
          neutralBorder={params.row.status === statusesEnum.clocked_out}
          warningBorder={params.row.status === statusesEnum.not_clocked_in}
        />
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.8,
      disableColumnMenu: true,
      minWidth: 100,
      sortable: false,
      valueGetter: params => statuses[params.row.status as keyof typeof statuses],
    },
    {
      field: 'location',
      headerName: 'Location',
      flex: 0.8,
      disableColumnMenu: true,
      minWidth: 100,
      sortable: false,
      valueGetter: params => params.row.location || '-',
    },
    {
      field: 'last_clock_event',
      headerName: 'Last Clock Event',
      flex: 0.8,
      disableColumnMenu: true,
      minWidth: 100,
      sortable: false,
      valueGetter: params =>
        `${getTime(params.row?.last_clock_event)} ${getUKFormattedDate(params.row?.last_clock_event)}`,
    },

    {
      field: '',
      headerName: 'Actions',
      width: 80,
      hideSortIcons: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => (
        <Box display="flex" width="100%">
          <GridActions close={closeGridAction}>
            <MenuList>
              <MenuItem
                disabled={params.row.status === statusesEnum.not_clocked_in}
                onClick={() => handleRemoveUser(params.row as ExtendedLocationUserDTO, params.row.location)}>
                Remove from this Location
              </MenuItem>
              <MenuItem onClick={() => handleRemoveUser(params.row as ExtendedLocationUserDTO, 'all')}>
                Remove from all Locations
              </MenuItem>
            </MenuList>
          </GridActions>
        </Box>
      ),
    },
  ];

  const handleStatusChange = (event: any) => {
    setSelectedStatusId(event.target.value);
  };

  return (
    <Box width="100%" height="100%" display="flex" flexDirection="column" gridGap={12}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(noop)}>
          <Box width="100%" display="grid" gridTemplateColumns="1fr 350px" gridGap={12}>
            <Box display="flex" width="100%" alignItems="center" gridGap={8}>
              <ControlledAutocomplete
                name="location"
                margin="none"
                items={locationSelectOptions}
                label="Location"
              />
            </Box>

            <Box display="flex" width="100%" alignItems="center" gridGap={8}>
              <Typography>with</Typography>
              <AppSelect
                onChange={handleStatusChange}
                value={selectedStatusId}
                labelId="select-status"
                fullWidth
                className={classes.selector}>
                {statusOptions.map(item => (
                  <MenuItem key={item.value} value={item.value}>
                    <Box display="flex" flexDirection="column">
                      <SelectorCustomValue statusValue={item.value as keyof typeof statusesEnum} />
                    </Box>
                  </MenuItem>
                ))}
              </AppSelect>
            </Box>
          </Box>
        </form>
      </FormProvider>
      <AppDataGrid
        rows={locationUsers}
        getRowId={row => row.locationId}
        columns={columns}
        noPaper
        rowHeight={80}
        height="calc(100% - 50px)"
        rowCount={total}
        pageSize={GRID_PAGE_SIZE}
        paginationMode="server"
        sortingMode="server"
        rowsPerPageOptions={[GRID_PAGE_SIZE]}
        onPageChange={handlePageChange}
        disableSelectionOnClick
        loading={loading}
      />
      <ConfirmDialog
        handleClose={closeConfirmDialog}
        open={isConfirmDeleteDialogOpen}
        confirmText="Yes, remove"
        title="Are you sure?"
        cancelText="Cancel"
        subtitle={`Do you want to remove ${candidateToRemove?.user.full_name} from ${
          candidateToRemove?.location === 'all' ? 'all Locations' : candidateToRemove?.user.location ?? '-'
        }? This action cannot be undone.`}
        handleConfirm={removeUserFromLocation}
      />
    </Box>
  );
};
