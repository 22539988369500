import React from 'react';

import { Box, Divider, Typography } from '@material-ui/core';
import { AiOutlineClockCircle } from 'react-icons/ai';

export const DayTimeValuePlug = () => {
  return (
    <Box width={80} display="flex" gridGap={4} alignItems="center" justifyContent="center">
      <Box width={28} display="flex" justifyContent="center">
        <AiOutlineClockCircle size="20px" />
      </Box>

      <Divider style={{ width: 2, margin: '0 4px' }} orientation="vertical" flexItem />

      <Box width={28} display="flex" justifyContent="center">
        <Typography variant="caption" style={{ fontWeight: 500 }}>
          -
        </Typography>
      </Box>
    </Box>
  );
};
