import React, { useContext } from 'react';

import { Register } from '@vyce/core/src/views/auth/components/Register';
import { AuthContext } from '@vyce/core/src/contexts';

import { useActions, useTypedSelector } from '../../../hooks';
import { EMPLOYEE_AUTH_PATHS } from './EmployeeAuth';

export const EmployeeRegister: React.FC = () => {
  const title = 'Welcome To Vyce';
  const subtitle = 'Sign up to join your team on Vyce';
  const { setLoading, userRegister } = useActions();
  const { userDataLoading } = useTypedSelector(state => state.helper);
  const { redirectUrl } = useContext(AuthContext);

  return (
    <Register
      title={title}
      verifyPhoneUrl={EMPLOYEE_AUTH_PATHS.VERIFY_PHONE}
      redirectUrl={redirectUrl || '/onboarding/employee/undefined/undefined'}
      subtitle={subtitle}
      loading={userDataLoading}
      setLoading={setLoading}
      userRegister={userRegister}
    />
  );
};
