import React, { useContext } from 'react';

import { Box, Typography, useTheme, Tooltip } from '@material-ui/core';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { BiSolidErrorCircle } from 'react-icons/bi';

import { DeviceContext } from '@vyce/core/src/contexts';

import { useStyles } from '../../styles';

interface Props {
  titles: string[];
  leftVerified: boolean;
  rightVerified: boolean;
  rightTooltip?: string;
}

export const VerificationRow = ({ titles, leftVerified, rightVerified, rightTooltip = '' }: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const { isMobile } = useContext(DeviceContext);

  return (
    <Box
      padding="12px 16px"
      display="flex"
      alignItems="center"
      borderTop={`1px solid ${theme.palette.divider}`}>
      <Box width={isMobile ? 'unset' : 64} display="flex" alignItems="center" justifyContent="center">
        {leftVerified ? (
          <IoIosCheckmarkCircle size="20px" color={theme.palette.success.main} />
        ) : (
          <BiSolidErrorCircle size="20px" color={theme.palette.error.main} />
        )}
      </Box>

      <Box flex={1} display="flex" alignItems="center" justifyContent="space-around" flexWrap="wrap">
        {titles
          .filter(i => !!i)
          .map((title, index) => (
            <Typography key={title + index} variant="caption" align="center" className={classes.bold500}>
              {title}
            </Typography>
          ))}
      </Box>

      <Tooltip title={rightTooltip}>
        <Box width={isMobile ? 'unset' : 64} display="flex" alignItems="center" justifyContent="center">
          {rightVerified ? (
            <IoIosCheckmarkCircle size="20px" color={theme.palette.success.main} />
          ) : (
            <BiSolidErrorCircle size="20px" color={theme.palette.error.main} />
          )}
        </Box>
      </Tooltip>
    </Box>
  );
};
