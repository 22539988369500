import axios from 'axios';
import config from '../config';

const URL = `${config.apiUrl}/scrapers/companieshouse/companies/search`;

export const searchCompanies = (query: string) => {
  return axios.post(
    URL,
    {
      limit: 20,
      substring: query,
    },
    {
      auth: {
        username: config.nojsScrapersUser as string,
        password: config.nojsScrapersPassword as string,
      },
    }
  );
};
