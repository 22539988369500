import React from 'react';
import { Box, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { AppKeyboardTimePicker } from '@vyce/core/src/components/inputs/AppKeyboardTimePicker';
import { Controller, useFormContext } from 'react-hook-form';
import { useStyles } from '../styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { AppButtonCheckbox } from '@vyce/core/src/components/inputs/AppButtonCheckbox';
import { ControlledSelect } from '@vyce/core/src/components/controlled-inputs/ControlledSelect';
import { OnboardingStepTitle } from '@vyce/core/src/components/OnboardingStepTitle';
import { PAYMENT_DAYS, PAYMENT_TIME_SETTINGS } from '../../../constants';

interface Props {
  noTitle?: boolean;
}

export const NotificationStep: React.FC<Props> = ({ noTitle }) => {
  const classes = useStyles();
  const { control, watch, setValue } = useFormContext();

  return (
    <>
      {!noTitle && (
        <OnboardingStepTitle
          text="By default payees will get a text confirming the net payment being made to them at 8am on the morning of your
        chosen payment day and will receive a pay statement via email by midday. You can customise these settings now..."
        />
      )}

      <Box>
        {PAYMENT_TIME_SETTINGS.map(settings => (
          <Box marginTop={2} key={settings.field}>
            <Typography variant="caption" color="secondary">
              {settings.label}
            </Typography>
            <Grid spacing={1} key={settings.label} className={classes.gridContainer} container>
              {watch('pay_frequency') !== 'monthly' && (
                <Grid item xs={12} md={4}>
                  <Box display="flex" flexDirection="column">
                    <ControlledSelect
                      label="Weekday"
                      disabled={!watch(settings.field)}
                      name={settings.weekDayField}
                      items={PAYMENT_DAYS}
                      multiple={false}
                    />
                  </Box>
                </Grid>
              )}
              <Grid item xs={8} md={5}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    name={settings.field}
                    control={control}
                    render={props => (
                      <AppKeyboardTimePicker
                        ampm={false}
                        mask="__:__"
                        disabled={!watch(settings.field)}
                        value={props.field.value || null}
                        onChange={props.field.onChange}
                        label="Payment Time"
                        fullWidth
                      />
                    )}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={4} md={3}>
                <Box display="flex" alignItems="center" justifyContent="flex-start" marginTop={0.5}>
                  <FormControlLabel
                    control={
                      <AppButtonCheckbox
                        onChange={() =>
                          setValue(settings.field, !watch(settings.field) ? settings.defaultValue : null)
                        }
                        color="primary"
                        name="Do Not Send"
                        checked={!watch(settings.field)}
                      />
                    }
                    label=""
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Box>
    </>
  );
};
