import React from 'react';

import { useTheme } from '@material-ui/core';

export const AppA = React.forwardRef((props: { href: string; content: any; style?: any }, ref) => {
  const theme = useTheme();
  const { style, content, href } = props;

  return (
    <a
      style={{
        color: theme.palette.primary.main,
        display: typeof content !== 'string' ? 'flex' : undefined,
        alignItems: 'center',
        justifyContent: 'center',
        ...style,
      }}
      target="_blank"
      href={href}
      rel="noreferrer">
      {content}
    </a>
  );
});
