import React from 'react';

import { TimeDashboardModule as CoreTimeDashboardModule } from '@vyce/core/src/modules';
import { TimeDashboardModuleProvider } from '@vyce/core/src/contexts';
import { ActivateSubscriptionDialog } from '@vyce/core/src/components';

import { UniversalInviteModule } from 'src/modules';
import { useTimeDashboardData } from './hooks';

export const TimeDashboardModule = () => {
  const {
    isActivateSubscriptionDialogOpen,
    subscribeLoading,
    contextOptions,
    isInviteFormOpen,
    selectedCompany,
    simpleSubscribe,
    setCloseInviteForm,
    closeActivateSubscriptionDialog,
  } = useTimeDashboardData();

  return (
    <TimeDashboardModuleProvider value={contextOptions}>
      <CoreTimeDashboardModule />
      <UniversalInviteModule onClose={setCloseInviteForm} isOpen={isInviteFormOpen} />
      <ActivateSubscriptionDialog
        open={isActivateSubscriptionDialogOpen}
        onClose={closeActivateSubscriptionDialog}
        callback={simpleSubscribe}
        loading={subscribeLoading}
        wasSubscribed={selectedCompany?.subscribed_company}
      />
    </TimeDashboardModuleProvider>
  );
};
