import React from 'react';
import { Typography, useTheme } from '@material-ui/core';

interface Props {
  status: string;
  isGreen: boolean;
  className?: string;
}

export const StatusComponents: React.FC<Props> = ({ status, isGreen, ...rest }) => {
  const theme = useTheme();
  return (
    <Typography
      variant="subtitle2"
      style={{ color: isGreen ? theme.palette.success.main : theme.palette.warning.main }}
      {...rest}>
      {status?.toUpperCase()?.replace(/_/g, ' ')}
    </Typography>
  );
};
