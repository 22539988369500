import React, { useState } from 'react';

import { Box, Button, Paper, Typography } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

import { CreateNewShiftDialog } from './CreateNewShiftDialog';
import { EditShift } from './EditShift';
import { Shift } from '../../../../types';
import { useStyles } from '../../styles';

interface Props {
  createShift: (shift: Shift) => void;
  deleteShift: (shiftId: string) => void;
  handleSaveChangesClick: Function;
  siteId: string;
  companyId: string;
}

const Component: React.FC<Props> = ({
  createShift,
  deleteShift,
  handleSaveChangesClick,
  siteId,
  companyId,
}) => {
  const classes = useStyles();
  const [openShiftDialog, setOpenShiftDialog] = useState<boolean>(false);
  const { watch } = useFormContext();

  const shifts = watch('shifts');

  const handleCreateNewShiftClick = () => {
    setOpenShiftDialog(true);
  };

  return (
    <>
      <Paper className={classes.paper} variant="outlined">
        <Typography variant="h6">Assigned Shifts & Breaks</Typography>

        {shifts?.map((shift: Shift, index: number) => (
          <EditShift
            siteId={siteId}
            companyId={companyId}
            handleSaveChangesClick={handleSaveChangesClick}
            shiftId={shift.uuid}
            deleteShift={deleteShift}
            key={`shift${index}`}
            shiftIndex={index}
          />
        ))}

        <Box marginTop={2} display="flex" justifyContent="flex-end">
          <Button onClick={handleCreateNewShiftClick} size="small" variant="contained" color="primary">
            Create {shifts.length ? 'another' : 'a'} Shift
          </Button>
        </Box>
      </Paper>

      <CreateNewShiftDialog open={openShiftDialog} setOpen={setOpenShiftDialog} createShift={createShift} />
    </>
  );
};

export const EditShifts = React.memo(Component);
