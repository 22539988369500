import React, { useContext, useState, useCallback } from 'react';

import { getEmployeesRequest } from '@vyce/core/src/api/management';
import { GetUserManagementItemsPayload } from '@vyce/core/src/api/types';
import { UserManagementItem } from '@vyce/core/src/types';
import { useBooleanState } from '@vyce/core/src/hooks';
import { ConfirmDialog } from '@vyce/core/src/components';
import { removeEmployeeFromModuleRequest } from '@vyce/core/src/api/pay';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import { useTypedSelector } from '../../../hooks';
import { UserList } from './UserList';

export const Employees: React.FC = () => {
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoadingTrue, setLoadingFalse] = useBooleanState(false);
  const [users, setUsers] = useState<UserManagementItem[]>([]);
  const [userToRemove, setUserToRemove] = useState<UserManagementItem>();
  const { access_token, selectedCompany } = useTypedSelector(state => state.helper);
  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] = useBooleanState(false);

  const getEmployees = async (payload: GetUserManagementItemsPayload) => {
    if (!selectedCompany?.uuid) {
      return;
    }
    try {
      setLoadingTrue();
      const res = await getEmployeesRequest(access_token, selectedCompany.uuid, payload);
      setTotal(res.data.count);
      setUsers(res.data.items);
      setLoadingFalse();
    } catch (e) {
      setLoadingFalse();
      setUsers([]);
    }
  };

  const removeFromModule = useCallback(async () => {
    if (!selectedCompany?.uuid || !userToRemove?.user_id) {
      return;
    }
    try {
      setLoadingTrue();
      const res = await removeEmployeeFromModuleRequest({
        token: access_token,
        userId: userToRemove.user_id,
        companyId: selectedCompany.uuid,
      });
      setUsers(value => value.filter(user => user.user_id !== userToRemove.user_id));
      closeConfirmDialog();
      showNotification({ message: res.data.message, options: { variant: 'success' } });
      setLoadingFalse();
    } catch (e) {
      setLoadingFalse();
      handleServerError(e);
    }
  }, []);

  const handleRemoveUserClick = useCallback(async (user: UserManagementItem) => {
    setUserToRemove(user);
    openConfirmDialog();
  }, []);

  return (
    <>
      <UserList
        moduleName="Payroll"
        users={users}
        loading={loading}
        getUsers={getEmployees}
        total={total}
        handleRemoveUserClick={handleRemoveUserClick}
      />
      <ConfirmDialog
        handleClose={closeConfirmDialog}
        open={isConfirmDialogOpen}
        confirmText="Remove"
        cancelText="Cancel"
        title={`Are you sure you want to remove ${userToRemove?.first_name} ${userToRemove?.last_name} from Payroll module?`}
        handleConfirm={removeFromModule}
        loading={loading}
      />
    </>
  );
};
