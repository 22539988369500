import React from 'react';

import { Redirect, Route } from 'react-router-dom';

import { useTypedSelector } from '../hooks';

export const EmployerRoute = ({ component, ...rest }: any) => {
  const { user } = useTypedSelector(state => state);

  const allowed = user.uuid && (user.positions?.length || user.is_superuser || user.is_staff);
  const pathname = user.uuid ? '/onboarding' : '/auth/login';

  const routeComponent = (props: any) =>
    allowed ? (
      React.createElement(component, props)
    ) : (
      <Redirect to={{ pathname, state: { from: props.location.pathname } }} />
    );
  return <Route {...rest} render={routeComponent} />;
};
