import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { monochrome } from '../../theme/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pdfWrapper: {
      '& .react-pdf__Page': {
        minHeight: 'unset !important',
        minWidth: 'unset !important',
      },

      '& .react-pdf__Page__canvas': {
        width: '100% !important',
      },
      '& .react-pdf__Page__textContent': {
        display: 'none',
      },
      '& .react-pdf__Page__annotations': {
        display: 'none',
      },
    },
    document: {
      '&:hover': {
        '& > div:last-child': {
          opacity: 1,
        },
      },
    },
    smallPreview: {
      '& .react-pdf__Page__canvas': {
        height: 'unset !important',
      },
    },

    selectedImage: {
      maxWidth: '100%',
      objectFit: 'contain',
    },

    dialogActions: {
      position: 'sticky',
      bottom: 0,
      backgroundColor: theme.palette.type === 'dark' ? monochrome.darker : '#fff',
    },
    pageControl: {
      position: 'absolute',
      bottom: '12%',
      left: '50%',
      background: 'white',
      opacity: '0',
      transform: 'translate(-50%)',
      transition: 'opacity ease-in-out .2s',
      boxShadow: 'rgba(16, 36, 94, 0.2) 0px 30px 40px 0px',
      borderRadius: 8,
      zIndex: 2,
      padding: '8px',
    },
  })
);
