import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { ReadMore } from '../../../../components/ReadMore';
import useStyles from '../../styles';

interface Props {
  bio: string;
  withTitle?: boolean;
}

export const Bio: React.FC<Props> = ({ bio, withTitle = false }) => {
  const classes = useStyles();

  return (
    <Box width="100%">
      {withTitle && (
        <Box marginBottom={1.5}>
          <Typography className={classes.bold} color="textSecondary" variant="h6">
            About Me
          </Typography>
        </Box>
      )}
      <Box>
        <ReadMore>{bio}</ReadMore>
      </Box>
    </Box>
  );
};
