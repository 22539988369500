import React from 'react';
import ReactPlayer from 'react-player';

interface Props {
  url: string;
  previewUrl: string;
}

export const ModuleVideo: React.FC<Props> = ({ url, previewUrl }) => {
  return <ReactPlayer light={previewUrl} width="100%" height={400} controls url={url} />;
};
