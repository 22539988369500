import React, { MouseEventHandler } from 'react';

import { BsGrid3X2Gap } from 'react-icons/bs';
import { IoMapOutline } from 'react-icons/io5';
import { Button } from '@material-ui/core';

import { useStyles } from '../styles';

interface Props {
  onMapModeChange: MouseEventHandler<HTMLButtonElement>;
  mapMode: boolean;
}

export const MapButton: React.FC<Props> = ({ mapMode, onMapModeChange }) => {
  const classes = useStyles();

  return (
    <Button
      size="small"
      style={{ minWidth: 90 }}
      className={classes.filterButton}
      startIcon={!mapMode ? <IoMapOutline /> : <BsGrid3X2Gap />}
      onClick={onMapModeChange}>
      {!mapMode ? 'Map' : 'Grid'}
    </Button>
  );
};
