import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { AxiosResponse } from 'axios';

import useStyles from '../styles';
import { ProfileAvatarUploader } from '../components/ProfileAvatarUploader';
import { Company, Image } from '../../../types';
import { AppFormWrapper } from '../../../components';
import { AppTextField } from '../../../components/inputs';
import { EmailField, PhoneNumberField } from '../../../components/controlled-inputs';
import { DeviceContext } from '../../../contexts';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  company?: Company;
  token: string;
  saveLogoRequest: Function;
  setCompany: Function;
  updateCompanyRequest: (token: string, companyId: string, data: Company) => Promise<AxiosResponse>;
}

export const CompanyGeneralInfo: React.FC<Props> = ({
  company,
  setCompany,
  token,
  saveLogoRequest,
  updateCompanyRequest,
}) => {
  const [loading, setLoading] = useState<boolean>();
  const [showAvatarButtons, setShowAvatarButtons] = useState<boolean>(false);
  const { isMobile } = useContext(DeviceContext);
  const { handleServerError, showNotification } = useContext(NotificationContext);

  const methods = useForm<Company & { logo: Image }>({
    defaultValues: {
      description: company?.description || '',
      gov_url: company?.gov_url || '',
      trading_name: company?.trading_name || '',
      email: company?.email || '',
      phone: company?.phone || '',
      workforce: company?.workforce || '',
      turnover: company?.turnover || '',
      logo: company?.logo,
    },
  });
  const { control, watch, setValue, getValues } = methods;
  const classes = useStyles();

  const logo = watch('logo');

  const uploadLogo = async () => {
    if (!logo?.file || !company?.uuid) {
      return;
    }
    try {
      const res = await saveLogoRequest(logo.file, token, company.uuid);
      await updateCompanyRequest(token, company.uuid, { logo: res.data.url });
    } catch (e) {
      handleServerError(e);
    }
  };

  const setLogo = useCallback(
    (newLogo: Image) => {
      const oldLogo = getValues('logo');
      if (!newLogo && !oldLogo) {
        return;
      }
      setValue('logo', omitBy({ ...oldLogo, file: newLogo?.file, url: newLogo?.url }, isNil));
    },
    [setValue]
  );

  useEffect(() => {
    if (company) {
      setLogo(company?.logo);
      setCompany(company);
    }
  }, [company]);

  const handleSubmit = async ({ logo, ...data }: Company) => {
    if (!company?.uuid) {
      return;
    }
    const updatedFields: Company = {
      trading_name: data.trading_name || null,
      description: data.description || null,
      turnover: data.turnover || null,
      workforce: data.workforce || null,
      phone: data.phone || null,
      email: data.email || null,
    };
    try {
      setLoading(true);
      await uploadLogo();
      const res = await updateCompanyRequest(token, company.uuid, updatedFields);
      setCompany(res.data);
      setLoading(false);
      showNotification({ message: 'Company info has been updated', options: { variant: 'success' } });
      setShowAvatarButtons(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  };

  return (
    <AppFormWrapper methods={methods} initialData={company} handleSubmit={handleSubmit} loading={loading}>
      <Grid container spacing={3} className={classes.infoContainer}>
        <Grid item xs={12} lg={4}>
          <ProfileAvatarUploader
            avatar={logo}
            label="Upload company picture"
            oldAvatarUrl={company?.logo?.url}
            setAvatar={setLogo}
            loading={loading}
            title={company?.trading_name || company?.name}
            subtitle={company?.address?.address_snippet}
            showAvatarButtons={showAvatarButtons}
            setShowAvatarButtons={setShowAvatarButtons}
          />
        </Grid>

        <Grid item xs={12} lg={8}>
          <Paper variant="outlined" className={classes.formPaper}>
            <Typography variant="h6">General Info</Typography>

            <Controller
              control={control}
              name="trading_name"
              render={({ field }) => (
                <AppTextField
                  {...field}
                  label="Trading Name"
                  value={field.value || ''}
                  multiline
                  margin="normal"
                  fullWidth
                />
              )}
            />

            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <AppTextField
                  {...field}
                  margin="normal"
                  label="Description"
                  value={field.value || ''}
                  multiline
                  fullWidth
                />
              )}
            />

            <Box display="flex" gridGap={24} marginTop={2} flexDirection={isMobile ? 'column' : 'row'}>
              <Controller
                control={control}
                name="workforce"
                render={({ field }) => (
                  <AppTextField
                    {...field}
                    label="Size of business by employees"
                    value={field.value || ''}
                    fullWidth
                    type="number"
                  />
                )}
              />

              <Controller
                control={control}
                name="turnover"
                render={({ field }) => (
                  <AppTextField
                    {...field}
                    label="Size of business by turnover (£)"
                    value={field.value || ''}
                    fullWidth
                    type="number"
                  />
                )}
              />
            </Box>

            <Box marginTop={4}>
              <Typography variant="h6">Contact Details</Typography>
            </Box>

            <Box display="flex" gridGap={24} marginTop={2} flexDirection={isMobile ? 'column' : 'row'}>
              <EmailField rules={{}} autoFocus={false} />

              <PhoneNumberField rules={{}} name="phone" label="Phone" />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </AppFormWrapper>
  );
};
