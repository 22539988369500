import { TimePicker, TimePickerProps } from '@material-ui/pickers';
import { Box } from '@material-ui/core';
import { timePickerUseStyles } from './AppKeyboardTimePicker';

export const AppClock = ({ value, onChange }: TimePickerProps) => {
  const componentClasses = timePickerUseStyles();

  return (
    <Box position="relative" className={componentClasses.dialog}>
      <TimePicker autoOk variant="static" openTo="hours" value={value} onChange={onChange} />
    </Box>
  );
};
