import React, { useMemo } from 'react';

import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import { AiFillFacebook, AiFillLinkedin, AiOutlineInstagram, AiOutlineTwitter } from 'react-icons/ai';

import { AppA } from '../../../../components';
import useStyles from '../../styles';
import { Company } from '../../../../types';

interface Props {
  company: Company;
}

export const CompanySocialLinks: React.FC<Props> = ({ company }) => {
  const classes = useStyles();
  const theme = useTheme();

  const socialLinks = useMemo(
    () => [
      {
        link: company?.instagram,
        icon: <AiOutlineInstagram color={theme.palette.primary.main} size="30px" />,
      },
      {
        link: company?.linkedin,
        icon: <AiFillLinkedin color={theme.palette.primary.main} size="30px" />,
      },
      {
        link: company?.facebook,
        icon: <AiFillFacebook color={theme.palette.primary.main} size="30px" />,
      },
      {
        link: company?.twitter,
        icon: <AiOutlineTwitter color={theme.palette.primary.main} size="30px" />,
      },
    ],
    [company, theme.palette.type]
  );

  return (
    <Grid item xs={12} md={6}>
      <Typography className={classes.bold} color="textSecondary" variant="h6">
        Links
      </Typography>

      <Box
        className={classes.tileItem}
        marginTop={2}
        paddingTop={2}
        display="flex"
        justifyContent="center"
        flexWrap="wrap">
        {socialLinks
          .filter(item => !!item.link)
          .map(link => (
            <Box className={classes.socialIconContainer}>
              <AppA href={link.link as string} content={link.icon} />
            </Box>
          ))}
      </Box>
    </Grid>
  );
};
