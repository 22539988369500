import React from 'react';

import { IoCloseOutline } from 'react-icons/io5';

import Chip, { ChipProps } from '@material-ui/core/Chip';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '3px 8px',
      borderRadius: '8px',
      height: 28,
    },
    label: {
      padding: 0,
      marginRight: 8,
      fontWeight: 500,
      fontSize: '13px',
    },
    deleteIcon: {
      height: 18,
      width: 18,
      marginRight: 0,
    },
  })
);

export const AppChip = React.forwardRef((props: ChipProps, ref) => {
  const classes = useStyles();
  return (
    <Chip
      classes={classes}
      innerRef={ref}
      deleteIcon={<IoCloseOutline size="14px" color="#4700AB" />}
      {...props}
    />
  );
});
