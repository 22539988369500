import React, { useContext, useEffect } from 'react';

import { Box } from '@material-ui/core';
import type { GridColDef, GridSortModel } from '@mui/x-data-grid';

import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { AppA, AppDataGrid } from '@vyce/core/src/components';
import { TeamModuleContext } from '@vyce/core/src/contexts';
import { useTable } from '@vyce/core/src/hooks/useTable';
import { AppSearchInput } from '@vyce/core/src/components/inputs';

const columns: GridColDef[] = [
  {
    field: 'first_name',
    headerName: 'Name',
    flex: 2,
    minWidth: 120,
    disableColumnMenu: true,
    valueGetter: params => `${params.row.first_name} ${params.row.last_name}`,
  },
  {
    field: 'email',
    headerName: 'Email #',
    flex: 1.5,
    minWidth: 270,
    disableColumnMenu: true,
    renderCell: params => (
      <AppA cy-test-id="user-email" content={params.row.email} href={`mailto: ${params.row.email}`} />
    ),
  },
  {
    field: 'phone',
    headerName: 'Phone Number',
    disableColumnMenu: true,
    minWidth: 180,
    renderCell: params => <AppA content={params.row.phone} href={`tel: ${params.row.phone}`} />,
  },
];

const defaultSortModel: GridSortModel = [{ field: 'first_name', sort: 'desc' }];

export const EmployeesForTransferList = () => {
  const { sortModel, offset, substring, handleSortModelChange, handlePageChange, handleSearchChange } =
    useTable({ defaultSortModel });

  const {
    selectionModel,
    setSelectionModel,
    selectedCompanyId,
    teamId,
    employees,
    transferTotal: total,
    transferLoading: loading,
    getNonMembers,
  } = useContext(TeamModuleContext);

  useEffect(() => {
    getNonMembers({ offset, substring, sortModel });
  }, [substring, offset, sortModel, selectedCompanyId]);

  if (!(selectedCompanyId && teamId)) return null;

  return (
    <Box>
      <Box mb={2}>
        <AppSearchInput cypressId="search-existing-users" expanded isBorder onChange={handleSearchChange} />
      </Box>

      <AppDataGrid
        noPaper={true}
        rows={employees}
        getRowId={row => row.user_id}
        columns={columns}
        height="calc(100vh - 380px)"
        rowCount={total}
        loading={loading}
        paginationMode="server"
        pageSize={GRID_PAGE_SIZE}
        onPageChange={handlePageChange}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        checkboxSelection
        selectionModel={selectionModel}
        onSelectionModelChange={newSelectionModel => setSelectionModel(newSelectionModel)}
        disableSelectionOnClick
      />
    </Box>
  );
};
