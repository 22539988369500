import React, { useMemo } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import {
  FilledInput,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  Box,
  CircularProgress,
  Typography,
  useTheme,
} from '@material-ui/core';
import { RiCloseLine } from 'react-icons/ri';

import { ReactComponent as VerifiedLabel } from '@vyce/core/src/assets/svg/verified-label.svg';

import { useAppTextFieldStyles } from '../inputs/AppTextField';

interface Props {
  verified?: boolean;
  value?: string;
  loading?: boolean;
}

const PendingStatus = () => {
  const theme = useTheme();

  return (
    <Box display="flex" height="100%" alignItems="center" justifyContent="center" gridGap={10}>
      <Typography
        style={{ color: theme.palette.warning.main, fontWeight: 500, lineHeight: 24, fontSize: '13px' }}>
        Pending
      </Typography>
      <CircularProgress size={13} style={{ color: theme.palette.warning.main }} />
    </Box>
  );
};

const ErrorStatus = () => {
  const theme = useTheme();

  return (
    <Box display="flex" height="100%" alignItems="center" justifyContent="center" gridGap={10}>
      <Typography style={{ color: theme.palette.error.main }}>Not found</Typography>
      <RiCloseLine size={24} color={theme.palette.error.main} />
    </Box>
  );
};

const VerifiedStatus = () => {
  return (
    <Box display="flex" height="100%" alignItems="center" justifyContent="center">
      <VerifiedLabel />
    </Box>
  );
};

export const VatNumberField: React.FC<Props> = ({ value, loading = false, verified }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const inputClasses = useAppTextFieldStyles({});

  const selectedStatus = useMemo(() => {
    if (!value && !loading) return <></>;
    if (loading) return <PendingStatus />;

    if (verified) return <VerifiedStatus />;

    if (!verified) return <ErrorStatus />;

    return <></>;
  }, [value, loading]);

  return (
    <Controller
      control={control}
      rules={{ required: 'VAT Number is required' }}
      name="vat_reg_number"
      render={({ field }) => (
        <FormControl ref={ref => field.ref(ref)} fullWidth variant="filled">
          <InputLabel error={!!errors.vat_reg_number?.message} htmlFor="vat-reg-number">
            VAT Number
          </InputLabel>
          <FilledInput
            value={field.value}
            onChange={field.onChange}
            classes={inputClasses}
            disableUnderline
            id="vat-reg-number"
            error={!!errors.vat_reg_number?.message}
            endAdornment={<InputAdornment position="end">{selectedStatus}</InputAdornment>}
          />
          <FormHelperText error>{(errors.vat_reg_number?.message as string) || ''}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
