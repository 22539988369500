import React, { useEffect } from 'react';
import { Box, FormControl, FormControlLabel, RadioGroup } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { AppRadioButton } from '@vyce/core/src/components/inputs/AppRadio';
import { OnboardingStepTitle } from '@vyce/core/src/components/OnboardingStepTitle';
import { MONTH_PAYMENT_DAYS, PAY_FREQUENCIES, PAYMENT_DAYS } from '../../../constants';

export const PaymentDayStep: React.FC = () => {
  const { control, watch, setValue } = useFormContext();
  const pay_frequency = watch('pay_frequency');

  useEffect(() => {
    if (pay_frequency === 'monthly') {
      setValue('pay_weekday', null);
      setValue('pay_last_workday', '1');
    } else {
      setValue('pay_last_workday', null);
      setValue('pay_weekday', '0');
    }
  }, [pay_frequency]);

  return (
    <>
      <OnboardingStepTitle
        text={`And what day would you like to pay your team ${
          PAY_FREQUENCIES.find(i => i.value === pay_frequency)?.label
        }?`}
      />

      {pay_frequency === 'monthly' ? (
        <Controller
          render={({ field }) => (
            <FormControl component="fieldset" style={{ width: '100%' }}>
              <RadioGroup value={field.value} onChange={field.onChange}>
                <Box display="flex" m={1} width="100%" flexWrap="wrap">
                  {MONTH_PAYMENT_DAYS.map(day => (
                    <FormControlLabel
                      key={day.label}
                      value={day.value}
                      control={<AppRadioButton color="primary" name={day.label} />}
                      label=""
                    />
                  ))}
                </Box>
              </RadioGroup>
            </FormControl>
          )}
          name="pay_last_workday"
          control={control}
        />
      ) : (
        <Controller
          render={({ field }) => (
            <FormControl component="fieldset" style={{ width: '100%' }}>
              <RadioGroup
                value={field.value}
                onChange={(event, value) => {
                  field.onChange(value);
                  setValue('SMSWeekday', PAYMENT_DAYS[+value]);
                  setValue('emailWeekday', PAYMENT_DAYS[+value]);
                }}>
                <Box display="flex" m={1} width="100%" flexWrap="wrap">
                  {PAYMENT_DAYS.map((day, index) => (
                    <FormControlLabel
                      key={day}
                      value={index.toString()}
                      control={<AppRadioButton color="primary" name={day} />}
                      label=""
                    />
                  ))}
                </Box>
              </RadioGroup>
            </FormControl>
          )}
          name="pay_weekday"
          control={control}
        />
      )}
    </>
  );
};
