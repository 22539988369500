import { saveAs } from 'file-saver';

export const generateFileLink = (res: any, contentType?: string) => {
  const filename = res.headers['content-disposition']?.split('filename=')[1]?.split(';')[0];
  const blob = new Blob([res.data], {
    type: contentType || res.headers['content-type'],
  });
  return { filename, blob };
};

export const generateFileLinkAndSave = (res: any, contentType?: string) => {
  const { filename, blob } = generateFileLink(res, contentType);
  saveAs(blob, filename);
};

export const generateFileLinkAndOpen = (res: any, contentType?: string) => {
  const { blob } = generateFileLink(res, contentType);
  const url = window.URL.createObjectURL(
    new Blob([blob], {
      type: 'application/pdf',
    })
  );
  if (url) window.open(url, '_blank');
};

export const generateExcelFileLink = (res: any) => {
  const filename = res.headers['content-disposition']?.split('filename=')[1]?.split(';')[0];
  saveAs(res.data, filename);
};
