import React from 'react';

import { Box, Button } from '@material-ui/core';

import {
  ActivateSubscriptionDialog,
  ConfirmDialog,
  SuccessSubscriptionDialog,
  VerifyYourIdentityDialog,
} from '@vyce/core/src/components';
import { SubscriptionInfo } from '@vyce/core/src/views/subscription/components/SubscriptionInfo';

import { useStyles } from './styles';
import { CancelSubscriptionReasonDialog } from './components/CancelSubscriptionReaasonDialog';
import { ManageSubscriptionDialog } from './components/ManageSubscriptionDialog';
import { useSubscriptionData } from '../../modules/subscriptionInfoModule/hooks/useSubscriptionData';

export const EmployerSubscription: React.FC = () => {
  const classes = useStyles();
  const {
    selectedCompany,
    isSuccessDialogOpen,
    isConfirmDialogOpen,
    isCancelReasonDialogOpen,
    isActivateSubscriptionDialogOpen,
    isLoginDialogOpen,
    loading,
    isManageDialogOpen,
    email,
    selectedCompanyAppData,
    billingData,
    closeSuccessDialog,
    closeConfirmDialog,
    cancelSubscription,
    closeCancelReasonDialog,
    closeActivateSubscriptionDialog,
    subscribe,
    openConfirmDialog,
    openLoginDialog,
    closeManageDialog,
    closeLoginDialog,
    handleNext,
    openActivateSubscriptionDialog,
    openManageDialog,
  } = useSubscriptionData();

  const { subscription } = selectedCompanyAppData || {};

  return (
    <>
      {selectedCompany && (
        <SubscriptionInfo
          billingData={billingData}
          companyName={selectedCompany.name}
          loading={selectedCompanyAppData?.companyDataLoading}
          subscription={selectedCompanyAppData?.subscription}>
          <Box display="flex" justifyContent="flex-end">
            {!subscription?.active && (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => openActivateSubscriptionDialog()}
                className={classes.subscribeButton}>
                Subscribe Now
              </Button>
            )}
            {subscription?.active && !subscription?.ended && (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ width: 177 }}
                onClick={() => openManageDialog()}>
                Manage subscription
              </Button>
            )}
          </Box>
        </SubscriptionInfo>
      )}

      <SuccessSubscriptionDialog
        open={isSuccessDialogOpen}
        setOpen={closeSuccessDialog}
        withPayroll
        withTeams
        withTime
      />

      <ConfirmDialog
        handleClose={() => closeConfirmDialog()}
        open={isConfirmDialogOpen}
        confirmText="Yes, cancel"
        cancelText="No, take me back"
        title="Are you sure you want to cancel your Subscription?"
        subtitle={`We’d hate to see you leave but if you do choose to cancel, your subscription will continue until the end of this calendar month. You will not be able to add users to your modules. Your final payment of ${'£0'} for this month will be taken on the 1st of the next month. You will no longer have access to your Vyce data thereafter.`}
        handleConfirm={() => cancelSubscription()}
      />

      <CancelSubscriptionReasonDialog onClose={closeCancelReasonDialog} open={isCancelReasonDialogOpen} />

      <ActivateSubscriptionDialog
        open={isActivateSubscriptionDialogOpen}
        onClose={closeActivateSubscriptionDialog}
        callback={subscribe}
        loading={loading}
        wasSubscribed={selectedCompany?.subscribed_company}
      />

      <ManageSubscriptionDialog
        handleCancelClick={openConfirmDialog}
        open={isManageDialogOpen}
        handleUpdatePaymentsClick={openLoginDialog}
        handleClose={closeManageDialog}
      />

      {email && (
        <VerifyYourIdentityDialog
          email={email}
          open={isLoginDialogOpen}
          handleClose={closeLoginDialog}
          handleNext={handleNext}
        />
      )}
    </>
  );
};
