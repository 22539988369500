import { useCallback, useState, useContext, useEffect } from 'react';

import { Layout, Layouts } from 'react-grid-layout';
import debounce from 'lodash/debounce';

import { UserDashboard, WidgetItem } from '@vyce/core/src/types';

import { DeviceContext } from '@vyce/core/src/contexts';

import { useActions, useTypedSelector, useInviteButton } from 'src/hooks';

export const usePayrollDashboardData = () => {
  const [payDashboard, setPayDashboard] = useState<UserDashboard>();

  const { dashboards, first_name } = useTypedSelector(state => state.user);
  const { selectedCompany } = useTypedSelector(state => state.helper);

  const { updateUserDashboards } = useActions();
  const {
    subscribeLoading,
    isInviteFormOpen,
    isActivateSubscriptionDialogOpen,
    closeActivateSubscriptionDialog,
    handleInviteButtonClick,
    setCloseInviteForm,
    simpleSubscribe,
  } = useInviteButton();
  const { isMobile } = useContext(DeviceContext);

  const update = useCallback(
    debounce(dashboard => {
      const userDashboards = {
        ...dashboards,
        pay: dashboard,
      };
      updateUserDashboards(userDashboards);
    }, 300),
    []
  );

  useEffect(() => {
    if (dashboards?.pay) {
      setPayDashboard(dashboards.pay);
    }
  }, [dashboards?.pay]);

  useEffect(() => {
    update(payDashboard);
  }, [payDashboard]);

  const onWidgetStatusChange = (systemName: string) => {
    const dashboardWidgets = payDashboard?.widgets || [];
    const newWidgets: WidgetItem[] = dashboardWidgets.map(item => {
      if (item.system_name === systemName) {
        return { ...item, is_active: !item.is_active };
      }
      return item;
    });
    setPayDashboard(value => ({ ...value, widgets: newWidgets }));
  };

  const onLayoutChange = (currentLayout: Layout[], newLayouts: Layouts) => {
    setPayDashboard(value => ({ ...value, layouts: newLayouts }));
  };

  return {
    isMobile,
    first_name,
    loading: subscribeLoading,
    isActivateSubscriptionDialogOpen,
    payDashboard,
    isInviteFormOpen,
    selectedCompany,
    onLayoutChange,
    onWidgetStatusChange,
    closeActivateSubscriptionDialog,
    simpleSubscribe,
    handleInviteButtonClick,
    setCloseInviteForm,
  };
};
