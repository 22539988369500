import { createContext } from 'react';
import { AxiosResponse } from 'axios';

import { CreateInvitesRequest } from '@vyce/core/src/api/types';
import { Team, PaySchedule, TimeAndAttendanceLocation, Shift } from '@vyce/core/src/types';

interface Context {
  token: string;
  teams?: Team[];
  paySchedules?: PaySchedule[];
  locations?: TimeAndAttendanceLocation[];
  shifts?: Shift[];
  companyId: string;
  open: boolean;
  disabledContactInput?: boolean;
  isLegend?: boolean;
  onClose: () => void;
  createInvitesRequest: ({ token, companyId, payload }: CreateInvitesRequest) => Promise<AxiosResponse<any>>;
}

export const UniversalInviteContext = createContext({} as Context);

export const UniversalInviteProvider = UniversalInviteContext.Provider;
