import React from 'react';

import { Box, Typography } from '@material-ui/core';

import { ImageUploader } from '@vyce/core/src/components';
import { Image } from '@vyce/core/src/types';
import { DocumentComponent } from './DocumentComponent';
import useStyles from '../styles';

const commonSize = 127;
const maxCountOfPhotos = 3;

export const DocumentUploader = ({
  photos,
  handleAddPhoto,
  handleRemovePhoto,
}: {
  photos: Image[];
  handleAddPhoto: (photo: Image) => void;
  handleRemovePhoto: (arg0: { image: Image; index: number }) => void;
}) => {
  const classes = useStyles();
  const handleAddPhotoFn = (image: Image) => {
    image && handleAddPhoto(image);
  };

  return (
    <Box display="flex" flexDirection="column" gridGap={16}>
      <Typography>Assigned Photos</Typography>
      <Box className={classes.documentsPreviewWrapper}>
        {photos.length !== maxCountOfPhotos && (
          <ImageUploader
            extraText="new files"
            uploadText="Click to upload"
            extraSmallSize={true}
            width={commonSize}
            height={commonSize}
            isDocument={true}
            uploaderWithoutPreview={true}
            onImageUpload={handleAddPhotoFn}
            type="both"
          />
        )}
        {photos.map((item, index) => (
          <DocumentComponent
            key={item.name}
            image={item}
            width={commonSize}
            height={commonSize}
            orderNumber={index}
            onRemove={handleRemovePhoto}
            onImageUpload={handleAddPhotoFn}
          />
        ))}
      </Box>
    </Box>
  );
};
