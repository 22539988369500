import React from 'react';

import { Box, Typography, Grid } from '@material-ui/core';

import { ReactComponent as VerifiedLabel } from '@vyce/core/src/assets/svg/verified-label.svg';
import { DetailItem } from '@vyce/core/src/types';

import useStyles from '../../styles';
import { BoxWrapper } from './BoxWrapper';

interface LimitedCompanyProps {
  company_name: DetailItem;
  company_reg_number: DetailItem;
  company_utr: DetailItem;
  vat_reg_number: DetailItem;
}

const DataRowItem = ({ title, value, verified }: DetailItem) => {
  const classes = useStyles();

  if (!value) return null;
  return (
    <Box className={classes.recordWrapper}>
      <Grid item xs={12} sm={6} lg={5}>
        <Typography className={classes.bold}>{title}:</Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={7}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography color="textSecondary" className={classes.bigTextWrap}>
            {value}
          </Typography>
          {verified && <VerifiedLabel />}
        </Box>
      </Grid>
    </Box>
  );
};

export const LimitedCompany = ({
  company_name,
  company_reg_number,
  company_utr,
  vat_reg_number,
}: LimitedCompanyProps) => {
  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Typography color="textSecondary" variant="h6">
        Limited Company
      </Typography>
      <BoxWrapper gridGap={16}>
        <DataRowItem {...company_name} />
        <DataRowItem {...company_reg_number} />
        <DataRowItem {...company_utr} />
        <DataRowItem {...vat_reg_number} />
      </BoxWrapper>
    </Box>
  );
};
