import React from 'react';

import { Box, Button } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

import { EMPTY_ADDRESS } from '../../../modules/timeModule/constants';
import { LocationField } from '../../../components/controlled-inputs';

export const ClearableLocationField: React.FC = () => {
  const { watch, setValue } = useFormContext();
  const address = watch('address');

  return (
    <Box display="flex" gridGap={16} marginTop={2} marginBottom={1}>
      <LocationField
        rules={{}}
        allCountries
        disabled={!!address?.address_snippet}
        label="Post code or zip code"
        name="address.address_snippet"
      />

      <Button
        style={{ height: 56 }}
        size="medium"
        variant="outlined"
        disabled={!address?.address_snippet}
        color="primary"
        onClick={() => {
          setValue('address', EMPTY_ADDRESS);
          setValue('polygon', null);
        }}>
        Clear
      </Button>
    </Box>
  );
};
