import React, { useContext } from 'react';
import { OnboardingStepTitle } from '@vyce/core/src/components/OnboardingStepTitle';
import { CompanyFilter } from '@vyce/core/src/components/CompanyFilter';
import { PayModuleContext } from '../../../contexts/payrollModule';
import { Box } from '@material-ui/core';

interface Props {
  onCompanyChange: Function;
}

export const CompanyStep: React.FC<Props> = ({ onCompanyChange }) => {
  const { companies } = useContext(PayModuleContext);

  return (
    <>
      <OnboardingStepTitle text="Please choose a company" />

      <Box marginTop={2}>
        <CompanyFilter companies={companies || []} onCompanyChange={onCompanyChange} />
      </Box>
    </>
  );
};
