import React, { useContext } from 'react';

import { Box, Typography } from '@material-ui/core';

import { greyRain, wind } from '../../../../assets/svg/weather-icons';
import { DeviceContext } from '../../../../contexts';

interface Props {
  precipitationProbability: number;
  windSpeed: number;
}

export const RainAndWind: React.FC<Props> = ({ precipitationProbability, windSpeed }) => {
  const { isMobile } = useContext(DeviceContext);

  return (
    <Box display="flex" width="100%" justifyContent={isMobile ? 'center' : 'unset'}>
      <Box width={54} marginRight={2} display="flex" gridGap={5} alignItems="center">
        <img src={greyRain} alt="icon" height={18} />
        <Typography variant="caption">{precipitationProbability}%</Typography>
      </Box>

      <Box display="flex" gridGap={5} alignItems="center">
        <img src={wind} alt="icon" height={18} />
        <Typography variant="caption">{windSpeed} km/h</Typography>
      </Box>
    </Box>
  );
};
