import { useEffect, useState } from 'react';
import { ColorThemeType, ThemeType } from '../types';
import getTheme from '../theme/AppTheme';
import { getFromLS, saveToLS } from '../utils/local-storage';
import { Theme } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core';

export const useAppTheme = () => {
  const defaultTheme = useTheme();
  const [themeType, setThemeType] = useState<ThemeType>();
  const [colorTheme, setColorTheme] = useState<ColorThemeType>(ThemeType.LIGHT);
  const [theme, setTheme] = useState<Theme>(defaultTheme);

  const changeColorTheme = (type: ThemeType) => {
    saveToLS('colorTheme', type);
    setThemeType(type);
  };

  useEffect(() => {
    const themeType = getFromLS('colorTheme');
    const colorTheme = themeType || getBrowserTheme();
    setThemeType(themeType || ThemeType.OS);
    setColorTheme(colorTheme);
  }, []);

  // listen browser dark mode
  useEffect(() => {
    if (themeType === ThemeType.OS) {
      const browserTheme = getBrowserTheme();
      setColorTheme(browserTheme);
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      const handleOSThemeChange = (e: MediaQueryListEvent) => {
        setColorTheme(e?.matches ? 'dark' : 'light');
      };
      if (mediaQuery?.addEventListener) {
        mediaQuery.addEventListener('change', handleOSThemeChange);
      }

      return () => {
        mediaQuery.removeEventListener('change', handleOSThemeChange);
      };
    } else if (themeType) {
      setColorTheme(themeType);
      return;
    }
  }, [themeType]);

  useEffect(() => {
    setTheme(getTheme(colorTheme === 'dark'));
  }, [colorTheme]);

  const getBrowserTheme = (): ColorThemeType => {
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  };

  return { themeType, colorTheme, theme, changeColorTheme };
};
