import React, { useContext, useState } from 'react';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import { ImageUploader } from '../../../components';
import { Image } from '../../../types';
import { DeviceContext } from '../../../contexts';
import { createRTWDocumentRequest } from '../../../api/legend/checkers';
import { useRequest } from '../../../hooks/useRequest';
import { ButtonTitleWithLoading } from '../../../components/ButtonTitleWithLoading';
import { MAIN_CONTAINER_ID } from '../../../constants';

const buttonPosition = { top: 32, right: 32 };
interface Props {
  open: boolean;
  handleClose: Function;
  setRtw: Function;
  getUser: () => Promise<void>;
  userId?: string;
}

export const UploadRtwPdfDialog: React.FC<Props> = ({ open, handleClose, setRtw, userId, getUser }) => {
  const { isMobile } = useContext(DeviceContext);
  const [doc, setDoc] = useState<Image | null>(null);

  const successCallback = async () => {
    await getUser();
    onClose();
  };

  const [loading, uploadPDF] = useRequest({
    request: createRTWDocumentRequest,
    successCallback,
  });

  const handleUploadClick = async () => {
    if (!userId || !doc?.file) {
      return;
    }
    const res = await uploadPDF({ file: doc.file, userId });
    setRtw(res?.data);
  };

  const onClose = () => {
    setDoc(null);
    handleClose();
  };

  return (
    <Dialog
      fullScreen={isMobile}
      container={document.getElementById(MAIN_CONTAINER_ID)}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title">
      <Box position="relative">
        <DialogTitle id="responsive-dialog-title">Upload RTW PDF</DialogTitle>

        <DialogContent>
          <Box width={isMobile ? '100%' : 320}>
            <ImageUploader
              extraText="agreement"
              width={250}
              height={150}
              isDocument={true}
              image={doc}
              onImageUpload={(doc: Image) => setDoc(doc)}
              buttonPosition={buttonPosition}
              type="pdf"
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button size="large" style={{ minWidth: 106 }} variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <Button
            size="large"
            cy-test-id="confirm-button"
            style={{ minWidth: 106 }}
            color="primary"
            onClick={handleUploadClick}
            disabled={!doc}
            variant="contained">
            <ButtonTitleWithLoading title="Upload" loaderVariant="paper" loading={loading} />
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
