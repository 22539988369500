export const splitSize = 4;
export const maxBarWidth = 48;
export const minBarHeight = 24;

export const maxCountOfBars = 12;
export const barBlockCount = 4;

export const widths = {
  bigWidth: 1168,
  midWidth: 696,
};

export const limits = {
  bigLimit: 12,
  midLimit: 8,
  smallLimit: 4,
};

export const defaultColor = { lightColor: '#610BEF', darkColor: '#610BEF' };

export const colors = [
  { lightColor: '#610BEF', darkColor: '#610BEF' },
  { lightColor: '#FF75CB', darkColor: '#FF75CB' },
  { lightColor: '#50C8FC', darkColor: '#50C8FC' },
  { lightColor: '#008A00', darkColor: '#A6F787' },
  { lightColor: '#4700AB', darkColor: '#BFBEFC' },
  { lightColor: '#005BD4', darkColor: '#005BD4' },
  { lightColor: '#EAAC30', darkColor: '#EAAC30' },
  { lightColor: '#CA024F', darkColor: '#FFABE8' },
  { lightColor: '#946300', darkColor: '#8DE9FF' },
  { lightColor: '#A996FF', darkColor: '#A996FF' },
  { lightColor: '#FFDF9A', darkColor: '#FFDF9A' },
  { lightColor: '#6E7191', darkColor: '#EFF0F6' },
];

export const keys = ['value'];
