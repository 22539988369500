import { axios } from '@vyce/core/src/api';

import { Company, RequestPositionDataData, Testimonial } from '../../types';
import { CreateCompanyPhotoData, CreateTestimonial } from '../types';

export const createCompanyWithOwnerRequest = (data: RequestPositionDataData) => {
  return axios.post('/companies', data);
};

export const getCompanyRequest = (token: string, companyId: string) => {
  return axios.get(`/companies/${companyId}?short=false`);
};

export const getCompanyExtendedDataRequest = (token: string, companyId: string) => {
  return axios.get(`/companies/${companyId}/extended`);
};

export const updateCompanyRequest = (token: string, companyId: string, data: Company) => {
  return axios.patch(`/companies/${companyId}`, data);
};

export const saveLogoRequest = (file: File, token: string, companyId: string) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);
  return axios.post(`/companies/${companyId}/logo`, bodyFormData);
};

export const getPassportByUserIdRequest = (token: string, companyId: string, userId: string) => {
  return axios.get(`/companies/${companyId}/users/${userId}/documents/passport`);
};

export const createPhotoRequest = (token: string, companyId: string, data: CreateCompanyPhotoData) => {
  const bodyFormData = new FormData();
  const { file, title, description } = data;
  if (file) {
    bodyFormData.append('file', file);
  }
  bodyFormData.append('title', title);
  if (description) {
    bodyFormData.append('description', description);
  }
  return axios.post(`/companies/${companyId}/photos_album/photos`, bodyFormData);
};

export const updatePhotoRequest = (
  token: string,
  companyId: string,
  photoId: string,
  data: CreateCompanyPhotoData
) => {
  const bodyFormData = new FormData();
  const { file, title, description } = data;
  if (file) {
    bodyFormData.append('file', file);
  }
  if (title) {
    bodyFormData.append('title', title);
  }
  if (description) {
    bodyFormData.append('description', description);
  }
  return axios.patch(`/companies/${companyId}/photos_album/photos/${photoId}`, bodyFormData);
};

export const deletePhotosRequest = (token: string, companyId: string, photoIds: string[]) => {
  return axios.delete(`/companies/${companyId}/photos_album/photos/bulk_delete`, {
    data: { photo_ids: photoIds },
  });
};

export const createTestimonialRequest = ({ companyId, token, testimonial, file }: CreateTestimonial) => {
  const bodyFormData = new FormData();
  if (file) {
    bodyFormData.append('file', file);
  }
  bodyFormData.append('testimonial', testimonial.testimonial);
  bodyFormData.append('public', 'true');
  bodyFormData.append('author_name', testimonial.author_name);
  bodyFormData.append('author_position', testimonial.author_position);
  bodyFormData.append('author_company', testimonial.author_company);

  return axios.post(`/companies/${companyId}/testimonials`, bodyFormData);
};

export const updateTestimonialRequest = (
  token: string,
  companyId: string,
  testimonial: Partial<Testimonial>
) => {
  return axios.patch(`/companies/${companyId}/testimonials/${testimonial.uuid}`, {
    public: testimonial.public,
  });
};

export const switchTestimonialRequest = (token: string, companyId: string, testimonialId: string) => {
  return axios.patch(`/companies/${companyId}/testimonials/${testimonialId}/switch`);
};

export const deleteTestimonialRequest = (token: string, companyId: string, id: string) => {
  return axios.delete(`/companies/${companyId}/testimonials/${id}`);
};
