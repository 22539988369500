import React from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { PhoneNumberField } from '@vyce/core/src/components/controlled-inputs/PhoneNumberField';
import { OnboardingStepTitle } from '@vyce/core/src/components/OnboardingStepTitle';

const useStyles = makeStyles<any>((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        marginTop: 0,
      },
    },
  })
);

export const PhoneNumberStep: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.form}>
      <OnboardingStepTitle text="Your mobile number?" />

      <PhoneNumberField label="Phone Number" margin="normal" name="phone" />
    </div>
  );
};
