import { LatLngLiteral } from 'leaflet';

export const MAP_MODE_LIMIT = 1000;
export const LONDON_POSITION: LatLngLiteral = { lat: 51.509865, lng: -0.118092 };
export const LIGHT_STYLE_ID = 'clp8bp2kf00ey01rc8v9m4h7l';
export const DARK_STYLE_ID = 'clp8bykt600ih01r70z4dgvng';

export const DARK = 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png';
export const LIGHT = 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png';
export const MAP_BOX_ATTRIBUTION =
  '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://www.mapbox.com/">Mapbox</a>';
export const CARTO_BOX_ATTRIBUTION =
  '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>';
