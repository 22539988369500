import React, { useContext, useState } from 'react';

import { Box, Button, Fade, Typography, useTheme } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

import { resetPasswordRequest } from '@vyce/core/src/api/auth';
import {
  DEFAULT_MESSAGE_OBJ,
  PasswordMessage,
  PasswordsFieldsWithProgress,
} from '@vyce/core/src/components/ChangePasswordField/PasswordsFieldsWithProgress';
import { ChangePasswordData } from '@vyce/core/src/api/types';
import { AppTextField } from '@vyce/core/src/components/inputs';
import { ButtonTitleWithLoading, LogoIcon, ReCAPTCHAComponent } from '@vyce/core/src/components';
import { useRecaptcha } from '@vyce/core/src/hooks';

import { NotificationContext } from '../../../contexts/notificationContext';

interface Form {
  password_1: string;
  password_2: string;
}

interface Props {
  noLogo?: boolean;
  noTitle?: boolean;
  buttonText?: string;
  email?: string;
  redirectUrl?: string;
  userLogin?: Function;
}

export const ForgotPassword: React.FC<Props> = ({
  noLogo,
  noTitle,
  buttonText = 'Done',
  email,
  redirectUrl,
  userLogin,
}) => {
  const methods = useForm<ChangePasswordData>({
    defaultValues: {
      password_1: '',
      password_2: '',
    },
  });
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const { watch } = methods;
  const { reCaptchaRef, getReCaptchaToken } = useRecaptcha();
  const [messageObj, setMessageObj] = useState<PasswordMessage>(DEFAULT_MESSAGE_OBJ);
  const password_1 = watch('password_1');
  const password_2 = watch('password_2');
  const { token } = useParams<{ token: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const theme = useTheme();

  const onSubmit = async (data: Form) => {
    setLoading(true);
    try {
      const recaptcha_token = await getReCaptchaToken();

      const res = await resetPasswordRequest({
        ...data,
        token,
        recaptcha_token,
      });
      if (email && userLogin) {
        await userLogin({
          username: email,
          password: data.password_1,
          redirectUrl,
          recaptcha_token,
          handleServerError,
          showNotification,
        });
      } else {
        showNotification({ message: res.data.message, options: { variant: 'success' } });
        history.push('/auth/login');
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  };

  return (
    <Fade in>
      <>
        {!noLogo && <LogoIcon height={60} width={60} />}
        {!noTitle && (
          <Typography
            variant="h6"
            style={{
              marginTop: theme.spacing(3),
              marginBottom: theme.spacing(1),
              fontWeight: 700,
            }}>
            Please enter your new password...
          </Typography>
        )}

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {!!email && (
              <Box marginBottom={2}>
                <AppTextField fullWidth disabled value={email} label="Email" />
              </Box>
            )}

            <PasswordsFieldsWithProgress
              password_2={password_2}
              password_1={password_1}
              setMessageObj={setMessageObj}
              messageObj={messageObj}
            />

            <ReCAPTCHAComponent ref={reCaptchaRef} />

            <Box marginTop={2}>
              <Button
                fullWidth
                disabled={Math.round(messageObj.progress) !== 100 || loading}
                variant="contained"
                color="primary"
                type="submit">
                <ButtonTitleWithLoading title={buttonText} loaderVariant="paper" loading={loading} />
              </Button>
            </Box>
          </form>
        </FormProvider>
      </>
    </Fade>
  );
};
