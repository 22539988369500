import { createContext } from 'react';

export interface HiringContextProps {
  token: string;
  isEditMode?: boolean;
  isJobDialogOpen: boolean;
}

export const HiringContext = createContext({} as HiringContextProps);

export const HiringProvider = HiringContext.Provider;
