import React, { FocusEventHandler } from 'react';

import { PropTypes } from '@material-ui/core';

import { Controller, useFormContext } from 'react-hook-form';
import InputMask from 'react-input-mask';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import { AppTextField } from '../inputs/AppTextField';
import { FieldProps } from '../../types';

interface Props extends FieldProps {
  handleInputFocus?: FocusEventHandler<HTMLInputElement>;
  margin?: PropTypes.Margin;
}

dayjs.extend(isSameOrAfter);

const DATE_WITH_MASK_REG_EXP = /(\d{2}\/?){3}/;

const rules = {
  required: 'Expiry date is required',
  validate: (value: string) => {
    const enrichedDate = value.split('/');
    const month = Number(enrichedDate[0]);
    const isCorrectMonth = month <= 12 && month > 0;

    if (!isCorrectMonth) return 'Invalid month';

    enrichedDate.splice(1, 0, '01');
    const transformedValue = enrichedDate.join('/');
    const isSameOrAfter = dayjs(transformedValue).isSameOrAfter(dayjs(new Date()));
    const isValid = DATE_WITH_MASK_REG_EXP.test(transformedValue) && dayjs(transformedValue).isValid();

    return (isValid && isSameOrAfter) || 'The card must be active';
  },
};

export const CardExpiryDateField: React.FC<Props> = ({ handleInputFocus, margin }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      rules={rules}
      name="expiry"
      render={({ field }) => (
        <InputMask {...field} mask="99/99" onFocus={handleInputFocus} maskChar={null}>
          {
            // @ts-ignore
            () => (
              <AppTextField
                margin={margin}
                error={!!errors.expiry?.message}
                label="Expiry date"
                type="tel"
                fullWidth
                helperText={(errors.expiry?.message as string) || ''}
              />
            )
          }
        </InputMask>
      )}
    />
  );
};
