import React, { useContext } from 'react';

import { Box } from '@material-ui/core';

import {
  downloadPayslipsRequestByEmployee,
  getPayslipsPDFRequest,
  getPayslipsRequest,
} from '@vyce/core/src/api/pay';
import { EmployeePayments } from '@vyce/core/src/views/payroll/components/EmployeePayments';
import { useStyles } from '@vyce/core/src/views/payroll/styles';
import { DeviceContext } from '@vyce/core/src/contexts';

import { useTypedSelector } from '../../../hooks';

export const EmployeePaymentsPage: React.FC = () => {
  const classes = useStyles();
  const { selectedCompany } = useTypedSelector(state => state.helper);
  const { isMobile } = useContext(DeviceContext);

  return (
    <Box className={classes.moduleContainer} height={isMobile ? 'calc(100vh - 115px)' : 'unset'}>
      <EmployeePayments
        getPayslipsPDFRequest={getPayslipsPDFRequest}
        downloadUserPayslipsRequest={downloadPayslipsRequestByEmployee}
        searchPayslipsRequest={getPayslipsRequest}
        isLegend={false}
        selectedCompany={selectedCompany}
      />
    </Box>
  );
};
