import noop from 'lodash/noop';
import { Typography, useTheme } from '@material-ui/core';
import { Polyline } from 'react-leaflet';

import { MapView } from '../../../map/MapView';
import { LocationAreaShapePreview } from '../../components/LocationAreaShapePreview';
import { WorkerMapProps } from '../types';

export const WorkerMap = ({ markers, location, lineCoords }: WorkerMapProps) => {
  const theme = useTheme();

  const { lat, lon } = markers[0] ?? {};
  const markersExist = !!lat && !!lon;

  return (
    <>
      {markersExist ? (
        <MapView
          scrollWheelZoom={false}
          zoom={15}
          clustered={false}
          centerPosition={[lat, lon]}
          height="100%"
          markers={markers}
          onMarkerClick={noop}>
          <>
            {location && (
              <LocationAreaShapePreview centerPosition={location.center} polygon={location.polygon} />
            )}
            <Polyline
              pathOptions={{
                color: theme.palette.primary.main,
                dashArray: '5,20',
                weight: 5,
              }}
              positions={lineCoords}
            />
          </>
        </MapView>
      ) : (
        <Typography>No Location found...</Typography>
      )}
    </>
  );
};
