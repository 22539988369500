import { DEFAULT_TEAMS_LIST_SORTING } from '../config';

export const getTeamsListSortingObject = (sortLabel: string) => {
  switch (sortLabel) {
    case 'By name':
      return {
        field_name: 'name',
        desc: false,
      };
    case 'Created at':
      return {
        field_name: 'created_at',
        desc: true,
      };
    case 'Updated at':
      return {
        field_name: 'updated_at',
        desc: true,
      };

    default:
      return DEFAULT_TEAMS_LIST_SORTING;
  }
};
