import React, { memo, ReactElement } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';

import { CustomPagination, CustomPaginationProps } from '@vyce/core/src/components/CustomPagination';

import { PageLoading } from '../../../components';

interface Props {
  loading?: boolean;
  paginationOptions: CustomPaginationProps;
  children: ReactElement;
  emptyComponent?: ReactElement;
}

export const useStyles = makeStyles(() => ({
  flexContentWrapper: {
    flex: '1 0 auto',
  },
}));

export const TileList: React.FC<Props> = memo(
  ({ children, loading = true, paginationOptions, emptyComponent }) => {
    const classes = useStyles();

    const { rowCount } = paginationOptions;

    if (!loading && !rowCount) return <>{emptyComponent}</>;

    return (
      <Box display="flex" width="100%" flex={1} flexDirection="column">
        <Box className={classes.flexContentWrapper} mb={2}>
          {loading ? (
            <PageLoading />
          ) : (
            <Grid container spacing={2}>
              {children}
            </Grid>
          )}
        </Box>

        <CustomPagination {...paginationOptions} />
      </Box>
    );
  }
);
