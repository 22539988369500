import React, { ReactElement, useContext } from 'react';

import { Box, Button, createStyles, Grid, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import Lottie, { Options } from 'react-lottie';

import { DeviceContext } from '../contexts';
import { monochrome } from '../theme/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      height: '100%',
    },
    bold700: {
      fontWeight: 700,
    },
    moduleImage: {
      height: '100%',
      backgroundSize: 'cover',
      backgroundPositionX: 'center',
    },
    accentText: {
      fontWeight: 600,
      color: theme.palette.primary.main,
    },
    animationContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
  })
);

interface Props {
  image?: string;
  animationOptions?: Options;
  animationSize?: number;
  title: string;
  children: ReactElement;
  buttonText: string;
  handleButtonClick: Function;
  disabledButton?: boolean;
  textInsteadButton?: string;
}

export const HoldingPage: React.FC<Props> = ({
  image,
  title,
  children,
  buttonText,
  handleButtonClick,
  disabledButton,
  textInsteadButton,
  animationOptions,
  animationSize,
}) => {
  const classes = useStyles();
  const { isMobile } = useContext(DeviceContext);
  const theme = useTheme<Theme>();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} md={6}>
        <Box height="100%" display="flex" alignItems="center" justifyContent="center">
          <Box padding={isMobile ? 2 : 10}>
            <Typography className={classes.bold700} variant={classes ? 'h5' : 'h4'}>
              {title}
            </Typography>

            <Box color={theme.palette.type === 'dark' ? monochrome.light : monochrome.dark}>{children}</Box>

            {textInsteadButton ? (
              <Typography variant="body1" className={classes.accentText}>
                {textInsteadButton}
              </Typography>
            ) : (
              <Button
                size="large"
                variant="contained"
                color="primary"
                cy-test-id="holding-page-button"
                onClick={() => handleButtonClick()}
                disabled={disabledButton}
                fullWidth>
                {buttonText}
              </Button>
            )}
          </Box>
        </Box>
      </Grid>

      {!isMobile && (
        <Grid item md={6}>
          {image && <Box className={classes.moduleImage} style={{ backgroundImage: `url(${image})` }}></Box>}
          {animationOptions && animationSize && (
            <Box display="flex" className={classes.animationContainer}>
              <Lottie options={animationOptions} height={animationSize} width={animationSize} />
            </Box>
          )}
        </Grid>
      )}
    </Grid>
  );
};
