import React, { useEffect } from 'react';

import Highlighter from 'react-highlight-words';
import uniq from 'lodash/uniq';

import { PasswordField } from '../controlled-inputs';
import { Box } from '@material-ui/core';
import { AppLinearProgress } from '../AppLinearProgress';
import useStyles from './styles';
import { LOWER_CASE_REGEX, ONE_DIGIT_REGEX, SPECIAL_CHARACTER_REGEX, UPPER_CASE_REGEX } from './index';
import { ChangePasswordData } from '../../api/types';

const STEP = 100 / 7;

const MESSAGE =
  'Use at least 6 characters, one upper case letter, one lower case letter, one number and one special character.';
const CONFIRM_MESSAGE = 'Remember to confirm it!';
const NOT_MATCH_MESSAGE = 'Passwords do not match!';
const STRONG_PASSWORD_MESSAGE = 'Great! Now that’s a strong password.';

export interface PasswordMessage {
  highlights: string[];
  progress: number;
  message: string;
}

export const DEFAULT_MESSAGE_OBJ: PasswordMessage = {
  highlights: [],
  progress: STEP,
  message: MESSAGE,
};

interface Props {
  messageObj: PasswordMessage;
  setMessageObj: Function;
  password_1: string;
  password_2: string;
}

export const PasswordsFieldsWithProgress: React.FC<Props> = ({
  messageObj,
  setMessageObj,
  password_2,
  password_1,
}) => {
  const classes = useStyles();

  const getMessageObj = ({ password_1, password_2 }: ChangePasswordData): PasswordMessage => {
    const obj: PasswordMessage = { ...DEFAULT_MESSAGE_OBJ };
    let highlights: string[] = [];
    const length = password_1.length;
    if (length > 0 && length >= 6) {
      obj.progress += STEP;
    } else {
      highlights.push('6 characters');
    }
    if (UPPER_CASE_REGEX.test(password_1)) {
      obj.progress += STEP;
    } else {
      highlights.push('one upper case letter');
    }
    if (LOWER_CASE_REGEX.test(password_1)) {
      obj.progress += STEP;
    } else {
      highlights.push('one lower case letter');
    }
    if (ONE_DIGIT_REGEX.test(password_1)) {
      obj.progress += STEP;
    } else {
      highlights.push('one number');
    }
    if (SPECIAL_CHARACTER_REGEX.test(password_1)) {
      obj.progress += STEP;
    } else {
      highlights.push('one special character');
    }
    const isSixthStep = obj.progress === STEP * 6;
    if (isSixthStep && !password_2) {
      obj.message = `${MESSAGE} ${CONFIRM_MESSAGE}`;
    }
    if (isSixthStep && password_2 && password_1 !== password_2) {
      obj.message = `${MESSAGE} ${NOT_MATCH_MESSAGE}`;
      highlights.push('Passwords do not match!');
    }
    if (isSixthStep && password_2 && password_1 === password_2) {
      obj.progress += STEP;
    }
    if (Math.round(obj.progress) === 100) {
      obj.message = STRONG_PASSWORD_MESSAGE;
    }
    if (password_1.length === 0) {
      highlights = [];
    }
    return {
      ...obj,
      highlights: uniq(highlights),
    };
  };

  useEffect(() => {
    const obj = getMessageObj({ password_1, password_2 });
    setMessageObj(obj);
  }, [password_1, password_2]);

  return (
    <Box>
      <PasswordField name="password_1" label="New password" rules={{ required: 'Password is required' }} />
      <PasswordField
        name="password_2"
        margin="normal"
        label="Confirm new password"
        rules={{ required: 'Password is required' }}
      />

      <Box marginTop={2}>
        <AppLinearProgress variant="determinate" value={messageObj.progress} />
      </Box>
      <Highlighter
        highlightClassName={classes.highlights}
        unhighlightClassName={classes.unHighlights}
        searchWords={messageObj.highlights}
        autoEscape={true}
        textToHighlight={messageObj.message}
      />
    </Box>
  );
};
