import React from 'react';
import { Divider, Typography } from '@material-ui/core';

import { PayrollBlock, TeamsBlock, TimeBlock } from './components';
import { Props } from './types';

const dividerStyles = { height: '2px' };

export const ModulesStep = ({
  isLegend,
  expandedModule,
  payModuleOptions,
  teamModuleOptions,
  timeModuleOptions,
  collapseAllModules,
  setExpandedModule,
}: Props) => {
  return (
    <>
      <Typography variant="h6">Which modules would you like to add them to?</Typography>

      <TeamsBlock
        {...teamModuleOptions}
        isLegend={isLegend}
        expandedModule={expandedModule}
        collapseAllModules={collapseAllModules}
        setExpandedModule={setExpandedModule}
      />

      <Divider style={dividerStyles} />

      <PayrollBlock
        {...payModuleOptions}
        isLegend={isLegend}
        expandedModule={expandedModule}
        collapseAllModules={collapseAllModules}
        setExpandedModule={setExpandedModule}
      />

      <Divider style={dividerStyles} />

      <TimeBlock
        {...timeModuleOptions}
        isLegend={isLegend}
        expandedModule={expandedModule}
        collapseAllModules={collapseAllModules}
        setExpandedModule={setExpandedModule}
      />
    </>
  );
};
