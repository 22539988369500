import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { monochrome } from '@vyce/core/src/theme/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    moduleImage: {
      height: '100%',
      backgroundSize: 'cover',
      backgroundPositionX: 'center',
    },
    container: {
      height: '100%',
    },
    title: {
      fontWeight: 700,
      lineHeight: '48px',
      fontSize: '36px',
      marginTop: 60,
      marginBottom: 8,
      [theme.breakpoints.down('xs')]: {
        fontWeight: 600,
        lineHeight: '28px',
        fontSize: '24px',
        marginTop: 16,
      },
    },
    formContainer: {
      padding: '60px 16px 32px',
      [theme.breakpoints.down('xs')]: {
        padding: '32px 16px',
      },
      display: 'flex',
      justifyContent: 'center',
    },
    label: {
      fontSize: '15px',
      fontWeight: 400,
      color: theme.palette.type === 'dark' ? monochrome.light : monochrome.dark,
    },
    rights: {
      fontWeight: 600,
      fontSize: '13px',
      marginTop: 32,
    },
  })
);
