import React, { useEffect, useMemo, useState } from 'react';

import { Route, Switch } from 'react-router-dom';

import { AuthLayout } from '@vyce/core/src/views/auth/AuthLayout';
import { VerifyPhone } from '@vyce/core/src/views/auth/components/VerifyPhone';
import config from '@vyce/core/src/assets/config';
import { AuthProvider } from '@vyce/core/src/contexts';

import { useTypedSelector } from '../../../hooks';
import { useActions } from '../../../hooks';
import { EmployerRegister } from './EmployerRegister';
import { EmployerLogin } from './EmployerLogin';

export enum EMPLOYER_AUTH_PATHS {
  SIGNUP = '/auth/employer/signup',
  LOGIN = '/auth/employer/login',
  VERIFY_PHONE = '/auth/employer/verify-phone',
}

export const EmployerAuth: React.FC = (props: any) => {
  const { userDataLoading } = useTypedSelector(state => state.helper);
  const [fromUrl, setFromUrl] = useState<string>('');
  const { verifyPhone } = useActions();

  useEffect(() => {
    const from = props?.location?.state?.from;
    if (from) {
      setFromUrl(from);
    }
  }, [props?.location?.state?.from]);

  const contextOptions = useMemo(() => ({ redirectUrl: fromUrl }), [fromUrl]);

  return (
    <AuthLayout rightPictureSrc={config.auth.employer}>
      <AuthProvider value={contextOptions}>
        <Switch>
          <Route path={EMPLOYER_AUTH_PATHS.SIGNUP} component={EmployerRegister} />
          <Route path={EMPLOYER_AUTH_PATHS.LOGIN} component={EmployerLogin} />
          <Route
            path={EMPLOYER_AUTH_PATHS.VERIFY_PHONE}
            component={() => (
              <VerifyPhone
                loading={userDataLoading}
                redirectUrl={fromUrl || '/onboarding'}
                verifyPhone={verifyPhone}
              />
            )}
          />
        </Switch>
      </AuthProvider>
    </AuthLayout>
  );
};
