import React, { useContext, useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { redeemStaffInviteTokenRequest } from '@vyce/core/src/api/management';
import { PageLoading } from '@vyce/core/src/components/PageLoading';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import { useActions } from '../../../hooks/useActions';
import { useTypedSelector } from '../../../hooks/useTypedSelector';

export const EnrollEmployer: React.FC = () => {
  const {
    helper: { access_token },
  } = useTypedSelector(state => state);
  const { staffInviteId, inviteToken } = useParams<{ staffInviteId: string; inviteToken: string }>();
  const { me, setShowTour } = useActions();
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const history = useHistory();

  const processEnrollment = async () => {
    try {
      await redeemStaffInviteTokenRequest(access_token, staffInviteId, inviteToken);
      await me();
      showNotification({ message: 'Welcome to VYCE', options: { variant: 'success' } });
      setShowTour(true);
      history.push('/dashboard');
    } catch (e) {
      handleServerError(e);
    }
  };

  useEffect(() => {
    if (access_token) {
      processEnrollment();
    }
  }, [access_token]);

  return <PageLoading />;
};
