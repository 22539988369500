import React, { useContext, useEffect } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';

import { DeviceContext, TeamListModuleContext } from '@vyce/core/src/contexts';
import { Image } from '@vyce/core/src/types';
import { ImageUploader } from '@vyce/core/src/components';
import { CreateTeamData } from '@vyce/core/src/api/types';

import { defaultValues } from '../config';
import { TeamNameField } from './TeamNameField';
import { TeamDescriptionField } from './TeamDescriptionField';
import { ButtonTitleWithLoading } from '../../../components/ButtonTitleWithLoading';
import { MAIN_CONTAINER_ID } from '../../../constants';

const buttonPosition = { top: 32, right: 32 };

export const CreateTeamDialog = () => {
  const { isMobile } = useContext(DeviceContext);

  const {
    needToReset = false,
    isLoading: loading,
    isOpenTeamDialog: open,
    setOpenTeamDialog: setOpen,
    finishCreateTeam: createTeamCallback,
  } = useContext(TeamListModuleContext);

  const methods = useForm<CreateTeamData>({
    defaultValues,
  });
  const { handleSubmit, reset, watch, setValue } = methods;

  const logo = watch('logo');

  const handleSetLogo = (image: Image) => setValue('logo', image);

  const onSubmit = async (data: CreateTeamData) => {
    createTeamCallback(data);
  };

  useEffect(() => {
    if (needToReset) reset();
  }, [needToReset]);

  return (
    <Dialog
      fullScreen={isMobile}
      container={document.getElementById(MAIN_CONTAINER_ID)}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="responsive-dialog-title">
      <FormProvider {...methods}>
        <Box position="relative">
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle id="responsive-dialog-title">Create New Team</DialogTitle>

            <DialogContent style={{ paddingBottom: 16 }}>
              <Box marginBottom={1}>
                <ImageUploader
                  width={112}
                  height={112}
                  image={logo as Image}
                  onImageUpload={handleSetLogo}
                  extraText="team picture"
                  buttonPosition={buttonPosition}
                />
              </Box>

              <TeamNameField />

              <TeamDescriptionField />
            </DialogContent>

            <DialogActions>
              <Button size="large" variant="outlined" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                size="large"
                cy-test-id="create-team-button"
                fullWidth
                disabled={loading}
                style={{ width: 120 }}
                variant="contained">
                <ButtonTitleWithLoading title="Create" loaderVariant="paper" loading={loading} />
              </Button>
            </DialogActions>
          </form>
        </Box>
      </FormProvider>
    </Dialog>
  );
};
