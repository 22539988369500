import { useState, useContext, useRef, useCallback } from 'react';

import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import {
  Company,
  CompanyPosition,
  CardForm,
  Subscription,
  ActiveOnboardingStep,
} from '@vyce/core/src/types';
import { createSubscriptionRequest } from '@vyce/core/src/api/billing';
import { useBooleanState } from '@vyce/core/src/hooks';
import { NotificationContext } from '../../../contexts/notificationContext';

import { HMRC_SETTINGS_TYPE } from '../../../constants';
import { PayModuleContext } from '../../../contexts';
import { PayrollProps, Form } from '../types';
import { preparePayScheduleData, prepareHMRCSetting } from '../utils';
import { defaultValues } from '../config';
import { initialStep } from '../PayrollOnboarding';

export const usePayrollOnboardingData = ({
  selectedPosition,
  token,
  user,
  subscribed,
  onboardingSteps,
  companyRegistrationRequest,
  createPayScheduleRequest,
  uploadPayScheduleContractWrapperRequest,
  setPosition,
  updateSelectedCompanySubscription,
}: Pick<
  PayrollProps,
  | 'selectedPosition'
  | 'token'
  | 'user'
  | 'companyRegistrationRequest'
  | 'createPayScheduleRequest'
  | 'uploadPayScheduleContractWrapperRequest'
  | 'setPosition'
  | 'updateSelectedCompanySubscription'
> & { onboardingSteps: number; subscribed: boolean }) => {
  const history = useHistory();
  const { handleServerError } = useContext(NotificationContext);
  const [agreement, setAgreement] = useState<File | null>(null);
  const [company, setCompany] = useState<Company | undefined>(selectedPosition?.company);
  const [loading, setLoading] = useState<boolean>(false);
  const [isCongratsDialogOpen, setOpenCongratsDialog, setCloseCongratsDialog] = useBooleanState(false);
  const [
    isActivateSubscriptionDialogOpen,
    setOpenActivateSubscriptionDialog,
    setCloseActivateSubscriptionDialog,
  ] = useBooleanState(false);
  const childRef = useRef<any>();
  const { hmrcSettings, fetchPaySchedules } = useContext(PayModuleContext);
  const [activeStep, setActiveStep] = useState<ActiveOnboardingStep>(initialStep);

  const methods = useForm<Form>({
    defaultValues: {
      contact_first_name: user.first_name,
      contact_last_name: user.last_name,
      contact_email: user.email,
      contact_telephone: user.phone,
      bank_account: {
        account_name: company?.name,
        account_number: '',
        sort_code: '',
      },
      ...defaultValues,
    },
  });
  const { watch } = methods;
  const hmrcSettingsType = watch('hmrcSettingsType');

  const openRightModal = () => {
    if (hmrcSettingsType === HMRC_SETTINGS_TYPE.OUTSOURCE) {
      setOpenCongratsDialog();
      return;
    }

    if (hmrcSettingsType === HMRC_SETTINGS_TYPE.COMPANY_SETTINGS && subscribed) {
      setOpenCongratsDialog();
      return;
    }

    setOpenActivateSubscriptionDialog();
  };

  const registerCompany = async (data: Form) => {
    try {
      if (!company?.uuid) {
        return;
      }
      setLoading(true);
      const outsourced = data.hmrcSettingsType === HMRC_SETTINGS_TYPE.OUTSOURCE;
      const newCompanyHMRCSettings = !outsourced && !hmrcSettings ? prepareHMRCSetting(data) : null;
      const pay_schedule = preparePayScheduleData(data);
      let payScheduleId;
      openRightModal();

      if (!company.pay_company || newCompanyHMRCSettings) {
        const res = await companyRegistrationRequest(token, company.uuid, {
          hmrc_settings: newCompanyHMRCSettings,
          pay_schedule,
          pay_run: {
            payment_date: data.payment_date,
          },
          bank_account: {
            ...data.bank_account,
            account_name: data.bank_account?.account_name || company.name || '',
          },
        });
        payScheduleId = res.data?.pay_schedules[0]?.uuid;
      } else {
        const res = await createPayScheduleRequest(
          token,
          company.uuid,
          { pay_schedule, payment_date: data.payment_date },
          outsourced
        );
        payScheduleId = res.data?.uuid;
      }

      if (payScheduleId && agreement) {
        uploadPayScheduleContractWrapperRequest({
          payScheduleId,
          companyId: company.uuid,
          contract: agreement,
        });
      }
      setLoading(false);
      openRightModal();
      if (fetchPaySchedules) {
        fetchPaySchedules(company.uuid);
      }
      if (!user.is_superuser && !company.pay_company && setPosition) {
        setPosition({
          ...selectedPosition,
          company: { ...selectedPosition?.company, pay_company: true },
        } as CompanyPosition);
      }
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  };

  const subscribe = useCallback(
    async (data: CardForm) => {
      if (!company?.uuid) {
        return;
      }
      setLoading(true);
      try {
        const res = await createSubscriptionRequest(token, company.uuid, data);
        const subscription: Subscription = res.data;
        setLoading(false);
        if (!user.is_superuser) {
          updateSelectedCompanySubscription(subscription);
        }
        history.push('/payroll/timesheets');
      } catch (e) {
        handleServerError(e);
        setLoading(false);
      }
    },
    [company?.uuid, token, history]
  );

  const handleCloseSubscriptionDialog = () => {
    setCloseActivateSubscriptionDialog();
    history.push('/payroll/timesheets');
  };

  const handleNext = (data: Form) => {
    if (activeStep.number !== onboardingSteps - 1) {
      childRef?.current?.nextStep();
      return;
    }
    registerCompany(data);
  };

  const handleCompanyChange = (e: any, company: Company) => {
    setCompany(company);
  };

  return {
    agreement,
    company,
    loading,
    isCongratsDialogOpen,
    childRef,
    hmrcSettings,
    hmrcSettingsType,
    methods,
    isActivateSubscriptionDialogOpen,
    setCloseCongratsDialog,
    registerCompany,
    setCompany,
    handleCompanyChange,
    setAgreement,
    handleNext,
    handleCloseSubscriptionDialog,
    subscribe,
    activeStep,
    setActiveStep,
  };
};
