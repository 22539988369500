import { Controller, useFormContext } from 'react-hook-form';

import { Box, Typography } from '@material-ui/core';

import { AppTextField } from '@vyce/core/src/components/inputs/AppTextField';

const maxLength = 2000;

const rules = {
  validate: (value: string) => value.length <= maxLength || 'You have reached characters limit',
};

export const JobDescription = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Box>
      <Typography variant="subtitle1" color="primary">
        Job Description
      </Typography>

      <Controller
        control={control}
        name="description"
        rules={rules}
        render={({ field }) => (
          <AppTextField
            {...field}
            type="string"
            multiline
            margin="normal"
            label="Short description"
            error={!!errors.description?.message}
            helperText={(errors.description?.message as string) || ''}
            fullWidth
            inputProps={{ maxLength: maxLength + 1 }}
          />
        )}
      />
    </Box>
  );
};
