import React, { ReactElement } from 'react';

import { Box, Button, Drawer, Grid, LinearProgress, Typography } from '@material-ui/core';
import { AiOutlinePlus } from 'react-icons/ai';
import clsx from 'clsx';

import { MapButton, SelectButton, Sorting, TileList } from './components';
import {
  AppIconButton,
  CommonNavBarBlock,
  ConfirmDialog,
  EmptyList,
  FilterSystem,
  HoldingPage,
} from '../../components';
import { CreateJobDialog, EmployerJobPage, EmployerJobTile } from './components';
import { useHorizontalScrollStyles } from '../../styles';
import { EMPLOYER_HIRING_TABS } from '../profile/config';
import { AppSearchInput } from '../../components/inputs';
import { HIRING_FILTER_OPTIONS } from '../../modules/hiringModule/config';
import { MapView } from '../map/MapView';
import { useHiringData } from '../../modules/hiringModule/hooks/useHiringData';
import { HiringProvider } from '../../modules/hiringModule/context/hiringContext';
import { useStyles } from './styles';
import config from '../../assets/config';

interface Props {
  token: string;
  contactEmail?: string;
  contactPhone?: string;
  contactName?: string;
  companyId?: string;
  isJobPosted?: boolean;
  companyDataLoading?: boolean;
  setIsJobPostedStatus?: Function;
  children?: ReactElement;
  comingSoon?: boolean;
}

export const Hiring: React.FC<Props> = ({
  token,
  companyId,
  isJobPosted,
  companyDataLoading,
  contactEmail,
  contactPhone,
  contactName,
  setIsJobPostedStatus,
  children,
  comingSoon,
}) => {
  const classes = useStyles();
  const horizontalScrollClasses = useHorizontalScrollStyles();

  const {
    contextProps,
    jobsCount,
    mapMode,
    isMobile,
    markers,
    selectedMarker,
    jobs,
    loading,
    jobOpen,
    duplicatedJob,
    selectedJob,
    jobDialogOpen,
    paginationOptions,
    hiringRuleOptions,
    sort,
    goToJob,
    back,
    closeJob,
    goToCandidates,
    onJobDuplicate,
    handleSearchChange,
    debouncedHandleFilter,
    setMapMode,
    handleJobDialogOpen,
    handleJobDialogClose,
    getJobs,
    status,
    handleSelectButtonClick,
    isSelectMode,
    handleDraftSelect,
    selectedDrafts,
    closeDeleteDialog,
    isDeleteDialogOpen,
    deleteDrafts,
  } = useHiringData({ token, companyId });

  if (companyDataLoading) return <LinearProgress color="primary" />;

  if (jobDialogOpen)
    return (
      <HiringProvider value={contextProps}>
        <Box>
          <CreateJobDialog
            token={token}
            contactEmail={contactEmail}
            contactPhone={contactPhone}
            contactName={contactName}
            companyId={companyId}
            getJobs={getJobs}
            job={duplicatedJob}
            handleClose={handleJobDialogClose}
            setIsJobPostedStatus={setIsJobPostedStatus}
          />
        </Box>
      </HiringProvider>
    );

  if (comingSoon || !isJobPosted)
    return (
      <HoldingPage
        title="Hiring"
        buttonText={comingSoon ? 'Coming soon...' : 'Post a Job for free'}
        disabledButton={comingSoon}
        handleButtonClick={handleJobDialogOpen}
        image={config.welcome.hiring}>
        <Box marginTop={1} marginBottom={4}>
          <Box>
            <Typography>
              Promote your jobs for free and connect with relevant, verified workers near your jobs in
              minutes with Vyce’s Hiring Module.
            </Typography>
          </Box>

          <Box marginTop={3}>
            <Typography>
              Our smart matching system instantly matches you to the most relevant professionals on Vyce and
              a wider network of candidates who are actively searching for jobs across Google and our
              network.
            </Typography>
          </Box>

          <Box marginTop={3}>
            <Typography>
              When a candidate applies you can contact and engage them however you want (and we can help with
              that bit too!).
            </Typography>
          </Box>

          <Box marginTop={3}>
            <Typography>Promote your jobs for free now.</Typography>
          </Box>
        </Box>
      </HoldingPage>
    );

  return (
    <HiringProvider value={contextProps}>
      <Box height="calc(100% - 64px)">
        <CommonNavBarBlock tabItems={EMPLOYER_HIRING_TABS}>{children}</CommonNavBarBlock>
        <Box
          className={clsx(classes.content, {
            [classes.contentShift]: jobOpen,
          })}
          display="flex"
          flexDirection="column">
          <Box marginTop={3} marginBottom={3}>
            <Box
              gridGap={16}
              className={clsx(
                horizontalScrollClasses.blockWrapper,
                horizontalScrollClasses.blockWithHideScroll
              )}>
              {!isMobile && (
                <Typography color="textSecondary" variant="subtitle2">
                  Found {`${jobsCount} result${jobsCount > 1 ? 's' : ''}`}
                </Typography>
              )}

              <Box display="flex" gridGap={16}>
                <AppSearchInput onChange={handleSearchChange} isSmall />

                <MapButton mapMode={mapMode} onMapModeChange={() => setMapMode(mode => !mode)} />

                <Sorting
                  sort={sort}
                  sortOptions={['Recent', 'Highest salary', 'Starts soon', 'Starts late']}
                />

                <FilterSystem
                  filtersSections={HIRING_FILTER_OPTIONS}
                  onFiltersChange={debouncedHandleFilter}
                  rules={hiringRuleOptions}
                />

                {status === 'draft' && !!jobs?.length && !loading && (
                  <>
                    <SelectButton selected={isSelectMode} handleClick={handleSelectButtonClick} />
                    <ConfirmDialog
                      handleClose={closeDeleteDialog}
                      open={isDeleteDialogOpen}
                      confirmText="Delete"
                      cancelText="Cancel"
                      title="Are you sure you want to delete Draft(s)?"
                      handleConfirm={deleteDrafts}
                    />
                  </>
                )}

                {isMobile ? (
                  <AppIconButton
                    className={classes.addButton}
                    variant="paper"
                    onClick={handleJobDialogOpen}
                    color="primary">
                    <AiOutlinePlus />
                  </AppIconButton>
                ) : (
                  <Button
                    onClick={handleJobDialogOpen}
                    fullWidth={isMobile}
                    startIcon={<AiOutlinePlus />}
                    size="small"
                    color="primary"
                    variant="contained">
                    Create New Job
                  </Button>
                )}
              </Box>
            </Box>
          </Box>

          <Box className={classes.flexContentWrapper}>
            {mapMode ? (
              <MapView
                height="calc(100vh - 270px)"
                markers={markers}
                selectedMarker={selectedMarker}
                onMarkerClick={goToJob}
              />
            ) : (
              <TileList
                loading={loading}
                paginationOptions={paginationOptions}
                emptyComponent={
                  <EmptyList
                    image={config.icons3D.lightning}
                    title={`No ${status === 'draft' ? 'drafts' : 'jobs'} here yet!`}
                    buttonText={status !== 'draft' ? 'Promote a job for free now' : ''}
                    callback={status !== 'draft' ? handleJobDialogOpen : undefined}
                  />
                }>
                <>
                  {jobs.map((job, index) => (
                    <Grid key={index} item xs={12} md={jobOpen ? 12 : 6} lg={jobOpen ? 6 : 4}>
                      <EmployerJobTile
                        selected={!!selectedDrafts?.find(draft => draft.uuid === job.uuid)}
                        handleDraftSelect={handleDraftSelect}
                        goToJob={goToJob}
                        isSelectMode={status === 'draft' && isSelectMode}
                        goToCandidates={goToCandidates}
                        currentJob={job}
                        onJobDuplicate={onJobDuplicate}
                      />
                    </Grid>
                  ))}
                </>
              </TileList>
            )}
          </Box>

          {selectedJob && (
            <Drawer
              className={classes.drawer}
              variant="persistent"
              anchor="right"
              open={jobOpen}
              classes={{
                paper: classes.drawerPaper,
              }}>
              <EmployerJobPage
                job={selectedJob}
                back={back}
                goToCandidates={goToCandidates}
                closeJob={closeJob}
                onJobDuplicate={onJobDuplicate}
              />
            </Drawer>
          )}
        </Box>
      </Box>
    </HiringProvider>
  );
};
