import React, { useContext } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { LatLngLiteral } from 'leaflet';
import * as GeoJSON from 'geojson';

import { DeviceContext } from '../../../../contexts';
import { MAIN_CONTAINER_ID } from '../../../../constants';
import { DrawableMap } from '../../LocationOnboarding/components/DrawableMap';

interface Props {
  open: boolean;
  handleClose: Function;
  setValue: Function;
  centerPosition: LatLngLiteral;
  polygon?: GeoJSON.Polygon | null;
}

export const EditLocationAreaDialog: React.FC<Props> = ({
  open,
  handleClose,
  setValue,
  centerPosition,
  polygon,
}) => {
  const { isMobile } = useContext(DeviceContext);

  return (
    <Dialog
      maxWidth="lg"
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      open={open}
      onClose={() => handleClose()}>
      <DialogTitle>Clock-In Spot</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Set an area where your team will be able to clock in and clock out. You can do so by placing a pin
          with a radius of 125 metres or by drawing the area yourself.
        </DialogContentText>

        <DrawableMap setValue={setValue} centerPosition={centerPosition} polygon={polygon} height={500} />
      </DialogContent>

      <DialogActions>
        <Button size="large" variant="outlined" onClick={() => handleClose()}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
