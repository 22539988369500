import React, { MouseEventHandler, useContext } from 'react';

import { Box, Button, Tooltip } from '@material-ui/core';
import { RiEyeLine } from 'react-icons/ri';

import { AppIconButton } from '@vyce/core/src/components';
import { DeviceContext } from '@vyce/core/src/contexts';
import useStyles from '@vyce/core/src/views/profile/styles';

interface Props {
  goToPreview: MouseEventHandler<HTMLButtonElement>;
}

export const PreviewButton: React.FC<Props> = ({ goToPreview }) => {
  const { isMobile } = useContext(DeviceContext);
  const classes = useStyles();

  return (
    <>
      <Tooltip title="Preview" placement="left">
        {isMobile ? (
          <Box width="100%" padding={1}>
            <Button fullWidth color="primary" variant="contained" onClick={goToPreview}>
              Preview
            </Button>
          </Box>
        ) : (
          <AppIconButton variant="paper" onClick={goToPreview} className={classes.previewIcon}>
            <RiEyeLine />
          </AppIconButton>
        )}
      </Tooltip>
    </>
  );
};
