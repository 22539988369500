import React, { useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { Controller, useFormContext } from 'react-hook-form';

import { AppRadioButton } from '@vyce/core/src/components/inputs/AppRadio';
import { OnboardingStepTitle } from '@vyce/core/src/components/OnboardingStepTitle';
import { HMRC_SETTINGS_TYPE, MAIN_CONTAINER_ID, VYCE_PHONE } from '@vyce/core/src/constants';

import { useStyles } from '../styles';

export const HMRCSettingsTypeStep: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState<boolean>(false);
  const { control } = useFormContext();

  const callVYCE = () => {
    window.open(`tel:${VYCE_PHONE}`, '_blank');
  };

  return (
    <Box>
      <Typography color="primary" className={classes.bold}>
        Great! We’ll have you set up in minutes.
      </Typography>

      <Box marginTop={3}>
        <OnboardingStepTitle text="To customise your experience, please tell us a little more about how you want to pay your team." />
      </Box>

      <Box marginTop={3}>
        <OnboardingStepTitle text=" Do you want to outsource the payroll to Vyce (so we are the employer of record) or do you want to just use our easy to use software to process your own payroll?" />
      </Box>

      <Controller
        render={({ field }) => (
          <FormControl component="fieldset" style={{ width: '100%' }}>
            <RadioGroup value={field.value || null} onChange={field.onChange}>
              <Box display="flex" justifyContent="center" m={1} width="100%">
                <FormControlLabel
                  value={HMRC_SETTINGS_TYPE.OUTSOURCE}
                  control={<AppRadioButton color="primary" name="Outsource To VYCE" />}
                  label=""
                />
                <FormControlLabel
                  value={HMRC_SETTINGS_TYPE.COMPANY_SETTINGS}
                  control={<AppRadioButton color="primary" name="Just Use VYCE System" />}
                  label=""
                />
              </Box>
            </RadioGroup>
          </FormControl>
        )}
        name="hmrcSettingsType"
        control={control}
      />

      <Box display="flex" justifyContent="center">
        <Typography
          onClick={() => setOpen(true)}
          className={classes.openDialogText}
          variant="caption"
          color="primary">
          What's the difference?
        </Typography>
      </Box>

      <Dialog
        fullScreen={fullScreen}
        container={document.getElementById(MAIN_CONTAINER_ID)}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">
          What’s the difference between outsourcing to Vyce versus just using Vyce system?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Very little! Both options give you access to our tech powered payroll software and reporting. The
            key difference is in regard to the submissions we make to HMRC. If you outsource to Vyce, we
            shall engage all of your team directly, have them sign an appropriate contract and process all
            their payments and taxes as required. If you choose to just use Vyce’s software, you will be the
            ‘employer of record’ and will be responsible for all engagement obligations and reporting. To
            answer any more of your questions, speak with one of our friendly team - call us on {VYCE_PHONE}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="large" onClick={callVYCE} variant="outlined" color="primary">
            Call VYCE
          </Button>

          <Button size="large" variant="contained" onClick={() => setOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
