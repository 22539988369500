import React, { MouseEventHandler } from 'react';
import { Button, createStyles, makeStyles, MobileStepper, Theme, } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobileStepperContainer: {
      display: 'flex',
      marginTop: '24px',
      justifyContent: 'space-between',
    },
    root: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      padding: 0,
      [theme.breakpoints.down('xs')]: {
        position: 'fixed',
        width: '100vw',
        padding: '16px',
        bottom: 0,
        left: 0,
        zIndex: theme.zIndex.mobileStepper,
      },
    },
    dot: {
      margin: '0 8px'
    },
    dotActive: {
      width: '16px',
      borderRadius: '4px',
    },
    controlButtons: {
      display: 'flex',
    },
    skipButton: {
      marginRight: '8px',
    },
  })
);

interface Props {
  stepsLength: number;
  activeStep: number;
  handleBack: MouseEventHandler<HTMLButtonElement>;
  isSkip?: boolean;
  handleNext?: MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
}

export const AppMobileStepper: React.FC<Props> = ({
                                                    stepsLength,
                                                    activeStep,
                                                    handleBack,
                                                    handleNext,
                                                  }) => {
  const classes = useStyles();

  const nextButton =
    <Button
      style={{ width: '100px' }}
      color="primary"
      variant="contained"
      onClick={handleNext}>
      {stepsLength === activeStep + 1 ? 'Let’s go!' : 'Next'}
    </Button>

  return (
    <div className={classes.mobileStepperContainer}>
      <MobileStepper
        classes={{ root: classes.root, dot: classes.dot, dotActive: classes.dotActive }}
        variant="dots"
        steps={stepsLength}
        position="static"
        activeStep={activeStep}
        nextButton={nextButton}
        backButton={
          <Button color="primary" variant="outlined" disabled={activeStep === 0} onClick={handleBack}>
            Previous
          </Button>
        }
      />
    </div>
  );
};
