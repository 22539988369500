import React from 'react';

import { Box, Typography, useTheme } from '@material-ui/core';
import { CgRadioCheck } from 'react-icons/cg';
import { RiRadioButtonLine } from 'react-icons/ri';

import { AppCheckbox } from '../../../../components/inputs';

interface Props {
  image: string;
  checked: boolean;
  label: string;
  description: string;
}

export const TrackingOption: React.FC<Props> = ({ image, checked, label, description }) => {
  const theme = useTheme();

  return (
    <Box display="flex" maxWidth={154} flexDirection="column" alignItems="flex-start">
      <img
        style={{
          outline: checked ? `2px solid ${theme.palette.primary.main}` : undefined,
          borderRadius: '8px',
        }}
        height={152}
        alt="Success"
        src={image}
      />

      <Box marginTop={1} marginLeft={-1} display="flex" alignItems="center">
        <AppCheckbox
          disableRipple
          disableFocusRipple
          disableTouchRipple
          variant="circle"
          icon={<CgRadioCheck size={24} />}
          checkedIcon={<RiRadioButtonLine size={24} />}
          color="primary"
          checked={checked}
        />

        <Typography style={{ fontSize: '15px', fontWeight: 600 }}>{label}</Typography>
      </Box>

      <Typography variant="caption">{description}</Typography>
    </Box>
  );
};
