import { useEffect, useMemo, useState, useRef } from 'react';

import { BarDatum } from '@nivo/bar';
import debounce from 'lodash/debounce';

import { widths, limits } from '../config';

export const useChartData = (data: BarDatum[]) => {
  const [fullData, setFullData] = useState<BarDatum[]>(data);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(limits.bigLimit);
  const [axisBottom, setAxisBottom] = useState('');
  const ref = useRef<HTMLDivElement>(null);

  const cuttedData = useMemo(
    () => fullData.slice(page * limit, (page + 1) * limit),
    [fullData, page, limit]
  );

  const maxPage = useMemo(() => Math.floor(fullData.length / limit) - 1, [limit, fullData]);

  const decreasePage = () => setPage(page - 1);
  const increasePage = () => setPage(page + 1);

  useEffect(() => setPage(0), [limit, fullData]);

  useEffect(() => {
    const observeTarget = ref.current;
    if (!observeTarget) return;

    const debouncedCallback = debounce(entry => {
      const { width } = entry.contentRect;
      if (width >= widths.bigWidth) {
        setLimit(limits.bigLimit);
        return;
      }
      if (width >= widths.midWidth) {
        setLimit(limits.midLimit);
        return;
      }
      setLimit(limits.smallLimit);
    }, 300);

    const resizeObserver = new ResizeObserver(entries => {
      if (!Array.isArray(entries) || !entries.length) return;

      const entry = entries[0];
      debouncedCallback(entry);
    });

    resizeObserver.observe(observeTarget);

    return () => resizeObserver.unobserve(observeTarget);
  }, [ref]);

  useEffect(() => {
    let animation = setTimeout(() => setFullData(data), 300);
    if (data[0]?.axisBottom) {
      setAxisBottom(data[0]?.axisBottom as string);
    }
    return () => {
      clearTimeout(animation);
    };
  }, [data]);

  return {
    ref,
    cuttedData,
    axisBottom,
    showDecreaseButton: page !== 0,
    showIncreaseButton: maxPage > 0 && page !== maxPage,
    increasePage,
    decreasePage,
  };
};
