import { RTWProps, RTWVerification } from '@vyce/core/src/types';
import { getUKFormattedDate } from '@vyce/core/src/utils/dates';

export const getRTWDetailsData = (rtw?: RTWProps, rtwVerification?: RTWVerification) => {
  const { full_name, check_date, ref_number } = rtw || {};
  const { expiration_date, status } = rtwVerification || {};
  return {
    full_name: { title: 'Name', value: full_name },
    status: { title: 'Status', value: status },
    check_date: { title: 'Date of check', value: getUKFormattedDate(check_date) },
    expiration_date: { title: 'RTW Expiring', value: expiration_date || 'N/A' },
    ref_number: { title: 'Reference Number', value: ref_number },
    isShow: !!(full_name || status || check_date || expiration_date || ref_number),
  };
};
