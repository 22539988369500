import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { AppTextField } from '@vyce/core/src/components/inputs/AppTextField';
import { OnboardingStepTitle } from '@vyce/core/src/components/OnboardingStepTitle';

export const JobTitleStep: React.FC = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <OnboardingStepTitle text="What's your job title?" />

      <Controller
        control={control}
        rules={{ required: 'Job Title is required' }}
        name="role"
        render={({ field }) => (
          <AppTextField
            {...field}
            error={!!errors.job_title?.message}
            id="job-title"
            cy-test-id="employer-role"
            label="Job Title"
            margin="normal"
            fullWidth
            helperText={(errors.job_title?.message as string) || ''}
          />
        )}
      />
    </>
  );
};
