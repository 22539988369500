import { Typography, Box } from '@material-ui/core';

import { useStyles } from '../styles';

interface TooltipItemProps {
  label: string;
  value: string | number;
}

interface TooltipProps {
  options: TooltipItemProps[];
}

const TooltipItem = ({ value, label }: TooltipItemProps) => {
  const classes = useStyles();

  return (
    <Box display="flex" gridGap={4} justifyContent="space-between">
      <Typography variant="body2" className={classes.smallText}>
        {label}:
      </Typography>
      <Typography variant="subtitle2" className={classes.smallText}>
        {value}
      </Typography>
    </Box>
  );
};
export const Tooltip = ({ options }: TooltipProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.tooltipWrapper}>
      {options.map(item => (
        <TooltipItem key={item.label} {...item} />
      ))}
    </Box>
  );
};
