import React, { ReactElement, useContext, useEffect, useState } from 'react';

import { FormProvider } from 'react-hook-form';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import noop from 'lodash/noop';

import { SaveButton } from '../views/profile/components/SaveButton';
import { setFormValues } from '../utils';
import { RouterPrompt } from './RouterPrompt';
import { DeviceContext } from '../contexts';

interface Props {
  methods: any;
  initialData?: any;
  handleSubmit: Function;
  children?: ReactElement;
  loading?: boolean;
  prePopulateValues?: boolean;
  isDisabled?: boolean;
  changeShowButton?: (arg0: boolean) => void;
}

let originalPersonalInfoData: any;

export const AppFormWrapper: React.FC<Props> = ({
  prePopulateValues = true,
  methods,
  initialData,
  handleSubmit,
  loading,
  children,
  isDisabled = false,
  changeShowButton = noop,
}) => {
  const [showChangeButton, setShowChangeButton] = useState<boolean>(false);
  const { getValues, setValue, watch } = methods;
  const { isMobile } = useContext(DeviceContext);

  useEffect(() => changeShowButton(showChangeButton), [showChangeButton, changeShowButton]);

  useEffect(() => {
    if (initialData && prePopulateValues) {
      const oldValues = getValues();
      setFormValues(initialData, setValue, oldValues);
    }
    setShowChangeButton(false);
    originalPersonalInfoData = cloneDeep(getValues());
    const subscription = watch((values: any) => {
      if (values) {
        const isChanged = !isEqual(values, originalPersonalInfoData);
        setShowChangeButton(isChanged);
      }
    });
    return () => subscription.unsubscribe();
  }, [initialData, watch]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          {children}
          {showChangeButton && <SaveButton loading={loading} isDisabled={isDisabled} />}
        </form>
      </FormProvider>

      <RouterPrompt
        when={showChangeButton}
        title="Before you go..."
        subtitle="Do you want to save the changes you've made?"
        okText={isMobile ? 'Yes' : 'Yes, save my changes'}
        cancelText={isMobile ? 'No' : 'No, ignore my changes'}
        onOK={async () => {
          await methods.handleSubmit(handleSubmit)();
          return true;
        }}
        onCancel={() => true}
      />
    </>
  );
};
