import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100%',
  },
  bold: {
    fontWeight: 600,
  },
  regularBold: {
    fontWeight: 400,
  },
  smallText: {
    fontSize: '13px',
    lineHeight: '22px',
  },
  moduleName: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginLeft: '4px',
  },
  bigTitle: {
    fontWeight: 600,
    color: '#fff',
  },
  openDialogText: {
    cursor: 'pointer',
  },
  gridContainer: {
    alignItems: 'center',
  },
  addButton: {
    height: 57,
    width: 57,
    marginLeft: theme.spacing(1),
  },
  inviteLink: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  schedule: {
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
    borderRadius: '8px',
  },
  wrapper: {
    padding: theme.spacing(3),
    minHeight: 86,
    borderRadius: '8px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  separator: {
    borderRight: `1px solid ${theme.palette.text.secondary}`,
    height: '15px',
    margin: '3px 16px 0',
  },
  input: {
    display: 'none',
  },
  resultWrapper: {
    border: '1px solid #EFF0F7',
    borderRadius: '8px',
    width: '150px',
    padding: theme.spacing(2),
  },
  errorButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
  errorBadge: {
    backgroundColor: theme.palette.error.main,
  },
  redButton: {
    color: theme.palette.error.main,
  },
  moduleContainer: {
    padding: '24px 40px',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
  },
  filterWrapper: {
    marginRight: '40px',
    maxWidth: '320px',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.background.paper,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
      marginRight: 'unset',
      padding: '16px',
    },
  },
  tabsWrapper: {
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('md')]: {
      marginLeft: '-16px',
      marginRight: '-16px',
    },
  },
  checkboxLabel: {
    fontWeight: 500,
    fontSize: '13px',
    color: theme.palette.text.secondary,
  },
}));
