import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { RegisterOptions } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import debounce from 'lodash/debounce';

import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import {
  deleteJobRequest,
  getCompanyJobsRequest,
  getJobRequest,
  updateJobRequest,
} from '@vyce/core/src/api/connect';
import { Job, MapMarker } from '@vyce/core/src/types';
import defaultMarkerPicture from '@vyce/core/src/assets/company-icon.png';
import { DEFAULT_JOBS_SORTING, getJobsSortingObject } from '@vyce/core/src/utils/sorting';
import { getUrlItems } from '@vyce/core/src/utils/url';
import { useBooleanState } from '@vyce/core/src/hooks';

import { DEFAULT_CALCULATED_DISTANCE, INVALID_DATE_VALUE, LIST_STEP } from '../config';
import { getSalaryToRules } from '../utils';
import { MAP_MODE_LIMIT } from '../../../views/map/constants';
import { NotificationContext } from '../../../contexts/notificationContext';

interface FilterOptions {
  mine: boolean;
  isContract: boolean;
  isPermanent: boolean;
  urgently: boolean | null;
  start_date_gte: string | null;
  start_date_lte: string | null;
  salary_type: string;
  salary_from?: number;
  salary_to?: number;
}

interface InitialFilterOptions extends Omit<FilterOptions, 'salary_from' | 'salary_to'> {
  salary_from?: string;
  salary_to?: string;
}

interface Props {
  token: string;
  companyId?: string;
}

export const useHiringData = ({ token, companyId }: Props) => {
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const [selectedMarker, setSelectedMarker] = useState<MapMarker>();
  const [markers, setMarkers] = useState<MapMarker[]>([]);
  const [filters, setFilters] = useState<FilterOptions>();
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [sorting, setSorting] = useState<any>(DEFAULT_JOBS_SORTING);
  const [jobs, setJobs] = useState<Job[]>([]);
  const [duplicatedJob, setDuplicatedJob] = useState<Job | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedJob, setSelectedJob] = useState<Job>();
  const [mapMode, setMapMode] = useState<boolean>(false);
  const [jobsCount, setJobsCount] = useState<number>(0);
  const [jobDialogOpen, setJobDialogOpen] = useState<boolean>(false);
  const [jobOpen, setJobOpen] = useState<boolean>(false);
  const [isSelectMode, setIsSelectMode] = useState<boolean>(false);
  const [selectedDrafts, setSelectedDrafts] = useState<Job[]>([]);
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useBooleanState(false);

  const history = useHistory();
  const { isMobile } = useContext(DeviceContext);
  const { job, status } = useParams<{ job: string; status: string }>();

  const pageCount = Math.ceil(jobsCount / LIST_STEP);

  const contextProps = useMemo(
    () => ({
      token,
      isJobDialogOpen: jobDialogOpen,
    }),
    [token, jobDialogOpen]
  );

  const hiringRuleOptions = useMemo(() => {
    const salaryToRules = getSalaryToRules(filters?.salary_from) as RegisterOptions;
    return { salary_to: salaryToRules };
  }, [filters?.salary_from]);

  const deleteDrafts = () => {
    if (!companyId || !selectedDrafts.length) {
      return;
    }
    setIsSelectMode(false);
    // TODO use api call to delete list of job when it will be ready
    selectedDrafts.forEach(draft => {
      deleteJobRequest(token, companyId, draft.uuid);
    });
    setJobs(value => value.filter(job => !selectedDrafts.find(draft => draft.uuid === job.uuid)));
    setJobsCount(value => value - selectedDrafts.length);
    setSelectedDrafts([]);
    closeDeleteDialog();
  };

  const handleSelectButtonClick = () => {
    if (isSelectMode && selectedDrafts.length) {
      return openDeleteDialog();
    }
    setIsSelectMode(value => !value);
  };

  const getJobs = useCallback(async () => {
    if (!companyId) {
      return;
    }
    try {
      setLoading(true);

      const data = {
        keywords: search,
        companies: [companyId],
        distance_type: 'mile',
        distance: DEFAULT_CALCULATED_DISTANCE,
        status,
        ...filters,
        order_by: [sorting],
        salary_type: filters?.salary_type?.toLowerCase() || 'hour',
      };
      const limit = mapMode ? MAP_MODE_LIMIT : LIST_STEP;
      const newOffset = mapMode ? 0 : page * LIST_STEP;

      const {
        data: { items, count },
      } = await getCompanyJobsRequest(token, data, companyId, limit, newOffset);
      setJobs(items);
      setJobsCount(count);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  }, [token]);

  const closeJob = useCallback(
    async (uuid: string) => {
      if (!companyId) {
        return;
      }
      try {
        await updateJobRequest(token, uuid, companyId, {
          status: 'old',
        });
        getJobs();
        setJobOpen(false);
        showNotification({
          message: 'Your job has been closed. You’ll find it in ‘Old Jobs’',
          options: { variant: 'info' },
        });
      } catch (e) {
        handleServerError(e);
      }
    },
    [token]
  );

  const getJob = useCallback(
    async (jobId: string) => {
      if (!companyId) {
        return;
      }
      try {
        const res = await getJobRequest(token, jobId);
        setSelectedJob(res.data);
      } catch (e) {
        handleServerError(e);
      }
    },
    [token]
  );

  const handleFilterChange = useCallback((newFilter: InitialFilterOptions) => {
    const {
      isContract,
      isPermanent,
      salary_from = '',
      salary_to = '',
      start_date_gte = '',
      start_date_lte = '',
      urgently,
      mine,
    } = newFilter;

    const newFilters = {
      ...filters,
      ...newFilter,
      urgently: urgently || null,
      salary_from: !isNaN(+salary_from) && salary_from ? +salary_from : undefined,
      salary_to: !isNaN(+salary_to) && salary_to ? +salary_to : undefined,
      start_date_gte: start_date_gte !== INVALID_DATE_VALUE && start_date_gte ? start_date_gte : null,
      start_date_lte: start_date_lte !== INVALID_DATE_VALUE && start_date_lte ? start_date_lte : null,
      job_type:
        (isContract && isPermanent) || (!isContract && !isPermanent)
          ? ''
          : isContract
          ? 'contract'
          : 'permanent',
      mine,
    };
    setFilters(newFilters);
  }, []);

  const debouncedHandleFilter = useMemo(() => debounce(handleFilterChange, 500), []);

  const handleJobDialogClose = () => {
    setJobDialogOpen(false);
    setDuplicatedJob(null);
    setPage(0);
  };

  const handleJobDialogOpen = () => {
    setJobDialogOpen(true);
  };

  const handleDraftSelect = (draft: Job, checked: boolean) => {
    setSelectedDrafts(value => {
      if (checked) {
        return [...value, draft];
      }
      return value.filter(item => item.uuid !== draft.uuid);
    });
  };

  const handleSearchChange = debounce((event: any) => {
    const search = event.target.value;
    setSearch(search);
    setPage(0);
  }, 1000);

  const back = () => history.push(`/hiring/${status}`);

  const onJobDuplicate = (job: Job) => {
    setDuplicatedJob(job);
    handleJobDialogOpen();
  };

  const goToCandidates = (job: Job) => {
    history.push(`/hiring/${status}/${job.name}_${job.uuid}/candidates/matched`);
  };

  const sort = (option: string) => {
    const sortData = getJobsSortingObject(option);
    setSorting(sortData);
  };

  const goToJob = (id: number | string, name: string) => {
    if (status === 'live') {
      history.push(`/hiring/live/${name}_${id}`);
    }
  };

  useEffect(() => {
    const selectedMarker: MapMarker | undefined = selectedJob?.address
      ? {
          lat: selectedJob.address.lat,
          lon: selectedJob.address.lon,
          id: selectedJob.uuid,
          name: selectedJob.name as string,
          picture: selectedJob.company?.logo?.url || defaultMarkerPicture,
        }
      : undefined;

    let markers = jobs.map(job => ({
      lat: job.address?.lat,
      lon: job.address?.lon,
      name: job.name as string,
      id: job.uuid,
      picture: job.company?.logo?.url || defaultMarkerPicture,
    }));

    if (selectedMarker) {
      markers = markers.filter(marker => marker.id !== selectedMarker.id);
    }

    setMarkers(markers);
    setSelectedMarker(selectedMarker);
  }, [selectedJob, jobs]);

  useEffect(() => {
    const jobUrlItems = getUrlItems(job);
    const id = jobUrlItems?.id;
    setJobOpen(!!id);
    if (id) {
      getJob(id);
    } else {
      setSelectedJob(undefined);
    }
  }, [job, getJob]);

  useEffect(() => {
    getJobs();
  }, [mapMode, page]);

  useEffect(() => {
    if (!mapMode && jobs.length > LIST_STEP) {
      setJobs(jobs.slice(0, LIST_STEP));
    }
  }, [jobs, mapMode]);

  useEffect(() => {
    if (page === 0) {
      getJobs();
    } else {
      setPage(0);
    }
  }, [status, filters, sorting, search, companyId]);

  return {
    contextProps,
    jobsCount,
    mapMode,
    isMobile,
    markers,
    selectedMarker,
    jobs,
    loading,
    jobOpen,
    duplicatedJob,
    selectedJob,
    jobDialogOpen,
    hiringRuleOptions,
    paginationOptions: {
      page,
      pageSize: LIST_STEP,
      pageCount,
      rowCount: jobsCount,
      setPage,
    },
    sort,
    goToJob,
    back,
    closeJob,
    goToCandidates,
    onJobDuplicate,
    handleSearchChange,
    debouncedHandleFilter,
    setMapMode,
    handleJobDialogOpen,
    handleJobDialogClose,
    getJobs,
    status,
    handleSelectButtonClick,
    isSelectMode,
    handleDraftSelect,
    selectedDrafts,
    isDeleteDialogOpen,
    closeDeleteDialog,
    deleteDrafts,
  };
};
