import React, { useContext } from 'react';

import { Box } from '@material-ui/core';

import { CompanyPreview } from '@vyce/core/src/views/preview/company/CompanyPreview';
import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';

import { useTypedSelector } from '../../../hooks';

interface Props {
  backUrl: string;
}

export const PublicCompanyPreviewWrapper: React.FC<Props> = ({ backUrl }) => {
  const { access_token } = useTypedSelector(state => state.helper);
  const { isMobile } = useContext(DeviceContext);

  return (
    <Box padding={isMobile ? 1 : 2} paddingTop={2} height="100%">
      <CompanyPreview token={access_token} editable={false} backUrl={backUrl} />
    </Box>
  );
};
