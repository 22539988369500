import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Box, LinearProgress } from '@material-ui/core';
import { Redirect, Route, Switch } from 'react-router-dom';

import { DeviceContext, PayModuleProvider } from '@vyce/core/src/contexts';
import { CommonNavBarBlock } from '@vyce/core/src/components';
import { BACSFormat, HMRCSetting, INTERFACE_PERMISSIONS, TabItem } from '@vyce/core/src/types';
import { getBACSFormatsRequest, getHMRCSettingsByCompanyRequest } from '@vyce/core/src/api/pay';
import { getPaddingForContent } from '@vyce/core/src/utils';

import { PayrollDashboardModule } from 'src/modules';
import { useActions, useTypedSelector } from 'src/hooks';

import { OUTSOURCED_PAYROLL_TABS, PAYROLL_TABS } from './constants';
import { Timesheets } from './Timesheets';
import { Payruns } from './Payruns';
import { EmployerTimesheetPage } from './EmployerTimesheetPage';
import { PayrunPage } from './PayrunPage';
import { EmployerReports } from './EmployerReports';
import { EmployerPaySchedulePage } from './EmployerPaySchedulePage';
import { EmployerEmployeeList } from './EmployerEmployeeList';
import { EmployerPayrollOnboarding } from './EmployerPayrollOnboarding';
import { PaySchedules } from './components/PaySchedules';
import { GetStarted } from './components/GetStarted';

export const EmployerPayroll: React.FC = () => {
  const { selectedCompany, selectedPosition } = useTypedSelector(state => state.helper);
  const [hmrcSettings, setHMRCSettings] = useState<HMRCSetting>();
  const [bacsFormats, setBacsFormats] = useState<BACSFormat[]>();
  const [tabs, setTabs] = useState<TabItem[]>(OUTSOURCED_PAYROLL_TABS);
  const [loading, setLoading] = useState<boolean>(true);
  const { isMobile } = useContext(DeviceContext);
  const { fetchPaySchedules } = useActions();
  const { permissions = [] } = selectedPosition || {};
  const padding = getPaddingForContent(isMobile);

  const hasPermissions = useMemo(
    () =>
      permissions.includes(INTERFACE_PERMISSIONS.PAYROLL) ||
      permissions.includes(INTERFACE_PERMISSIONS.PAYROLL_OUTSOURCED),
    [selectedPosition]
  );

  const getHMRCSettings = async (companyId: string) => {
    try {
      const res = await getHMRCSettingsByCompanyRequest(companyId);
      setHMRCSettings(res.data);
    } catch (e) {
      throw e;
    }
  };

  const getBacsFormats = async (companyId: string) => {
    try {
      const res = await getBACSFormatsRequest(companyId);
      setBacsFormats(res.data);
    } catch (e) {
      throw e;
    }
  };

  const getPayrollData = async (companyId: string) => {
    try {
      await getBacsFormats(companyId);
      await getHMRCSettings(companyId);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCompany?.uuid && hasPermissions) {
      getPayrollData(selectedCompany.uuid);
    }
    const tabs = permissions.includes(INTERFACE_PERMISSIONS.PAYROLL)
      ? PAYROLL_TABS
      : OUTSOURCED_PAYROLL_TABS;
    setTabs(tabs);
  }, [selectedPosition, selectedCompany]);

  if (!hasPermissions) {
    return <GetStarted hasPermissions={hasPermissions} />;
  }

  if (loading) {
    return (
      <Box height="100%">
        <LinearProgress color="primary" />
      </Box>
    );
  }

  if (!selectedCompany?.pay_company) {
    return (
      <Box height="100%">
        <GetStarted />
      </Box>
    );
  }

  return (
    <Box height="100%">
      <Box>
        <CommonNavBarBlock tabItems={tabs} />

        <PayModuleProvider
          value={{
            fetchPaySchedules,
            hmrcSettings,
            bacsFormats,
          }}>
          <Box padding={padding}>
            <Switch>
              <Route path={'/payroll/create-pay-schedule'} component={EmployerPayrollOnboarding} />
              <Route path={'/payroll/dashboard'} component={PayrollDashboardModule} />
              <Route path={'/payroll/users'} component={EmployerEmployeeList} />
              <Route path={'/payroll/timesheets/:id'} component={EmployerTimesheetPage} />
              <Route path={'/payroll/timesheets'} component={Timesheets} />
              <Route path={'/payroll/payruns/:id'} component={PayrunPage} />
              <Route path={'/payroll/payruns'} component={Payruns} />
              <Route path={'/payroll/reports'} component={EmployerReports} />
              <Route path={'/payroll/settings/:schedule'} component={EmployerPaySchedulePage} />
              <Route path={'/payroll/settings'} component={PaySchedules} />
              <Route path="/payroll" render={() => <Redirect to={{ pathname: '/payroll/dashboard' }} />} />
            </Switch>
          </Box>
        </PayModuleProvider>
      </Box>
    </Box>
  );
};
