import React from 'react';

import { Box, FormControl, FormControlLabel, FormHelperText, RadioGroup } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';

import { FieldProps } from '../../types';
import { AppRadioButton } from '../inputs';

export const GenderRadio: React.FC<FieldProps> = ({ disabled = false }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Box display="flex" width="100%">
      <Controller
        render={({ field }) => (
          <FormControl {...field} component="fieldset">
            <RadioGroup aria-label="gender" value={field.value || null} onChange={field.onChange}>
              <Box display="flex" flexWrap="wrap">
                <FormControlLabel
                  disabled={disabled}
                  value="male"
                  control={<AppRadioButton style={{ width: 140 }} color="primary" name="Male" />}
                  label=""
                />
                <FormControlLabel
                  disabled={disabled}
                  value="female"
                  control={<AppRadioButton style={{ width: 140 }} color="primary" name="Female" />}
                  label=""
                />
                <FormControlLabel
                  disabled={disabled}
                  value="rather_not_say"
                  control={<AppRadioButton style={{ width: 140 }} color="primary" name="Rather not say" />}
                  label=""
                />
              </Box>
            </RadioGroup>
            <FormHelperText error id="select-helper-text">
              {(errors.gender?.message as string) || ''}
            </FormHelperText>
          </FormControl>
        )}
        name="gender"
        control={control}
      />
    </Box>
  );
};
