import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { monochrome } from '../../theme/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    titleContainer: {
      [theme.breakpoints.down('xs')]: {
        alignItems: 'flex-start',
      },
    },
    cardDialog: {
      minWidth: '550px',
      [theme.breakpoints.down('xs')]: {
        minWidth: 'auto',
      },
    },

    qualificationTypeIcon: {
      padding: '12px',
      backgroundColor: theme.palette.type === 'dark' ? '#14142B' : '#F4F6F9',
      borderRadius: '8px',
    },

    buttonWrapper: {
      width: '40px',
      height: '40px',
      padding: 12,
      minWidth: 'max-content !important',
      borderRadius: 12,
      '& .MuiButton-startIcon': {
        marginRight: 0,
        marginLeft: 0,
      },
    },
    smallText: {
      fontSize: '13px',
      fontWeight: 500,
      lineHeight: '22px',
    },
    thinText: {
      fontWeight: 400,
    },
    boldText: {
      fontWeight: 600,
    },
    dndBlockWrapper: {
      overflowY: 'auto',
      flex: '1 1 auto',
    },
    qualificationListWrapper: {
      '& > div:not(:last-of-type)': {
        marginBottom: '16px',
      },
    },
    dragIcon: {
      cursor: 'move',
    },
    previewItem: {
      userSelect: 'none',
    },
    documentsPreviewWrapper: {
      display: 'grid',
      width: '100%',
      gridTemplateColumns: 'repeat(auto-fill, 120px)',
      gridTemplateRows: 'repeat(auto-fill, 120px)',
      gridGap: '16px',
    },

    verified: {
      color: theme.palette.success.main,
    },
    unverified: {
      color: theme.palette.error.main,
    },
  })
);
