import React, { useState } from 'react';

import { Document, Page } from 'react-pdf';
import clsx from 'clsx';
import { Box, Typography } from '@material-ui/core';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import { AppIconButton } from '@vyce/core/src/components';
import { monochrome } from '@vyce/core/src/theme/styles';

import { useStyles } from '../styles';

const PageControl = ({
  currentPage,
  pages,
  increasePage,
  decreasePage,
}: {
  currentPage: number;
  pages: number;
  increasePage: () => void;
  decreasePage: () => void;
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.pageControl}>
      <Box display="flex" alignItems="center" gridGap={8}>
        <AppIconButton
          variant="paper"
          disableFocusRipple
          disableRipple
          disableTouchRipple
          disabled={currentPage === 1}
          onClick={decreasePage}>
          <FiChevronLeft color={monochrome.mediumdark} />
        </AppIconButton>
        <Typography>
          {currentPage} of {pages}
        </Typography>
        <AppIconButton
          variant="paper"
          disableFocusRipple
          disableRipple
          disableTouchRipple
          disabled={currentPage === pages}
          onClick={increasePage}>
          <FiChevronRight color={monochrome.mediumdark} />
        </AppIconButton>
      </Box>
    </Box>
  );
};

export const PDFPreview = ({ url, bigPreview = false }: { url: string; bigPreview?: boolean }) => {
  const classes = useStyles();
  const [file] = useState({
    url,
    responseType: 'arraybuffer',
  });
  const [page, setPage] = useState<number>(1);
  const [numPages, setNumPages] = useState<number>(0);

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }: PDFDocumentProxy) => {
    setNumPages(nextNumPages);
  };

  const decreasePage = () => setPage(page - 1);
  const increasePage = () => setPage(page + 1);

  return (
    <Box className={clsx(classes.pdfWrapper, { [classes.smallPreview]: !bigPreview })}>
      <Document
        file={file}
        className={classes.document}
        renderMode="canvas"
        onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={page} />
        {bigPreview && (
          <PageControl
            currentPage={page}
            pages={numPages}
            increasePage={increasePage}
            decreasePage={decreasePage}
          />
        )}
      </Document>
    </Box>
  );
};
