import config from '@vyce/core/src/assets/config';
import { HowItWorksStep } from '@vyce/core/src/types';

export const DOCUMENT_REQUIREMENTS: string[] = [
  'clearly visible',
  'no glare or hidden parts',
  'includes all of the photo page',
];

export const CIS_DOCUMENTS = [
  'National Insurance Number',
  'Bank details',
  'UTR Number',
  'Valid proof of identification (passport)',
  'Proof of Right to Work (for non UK passport holders)',
  'If you do not have a British passport you will need a valid \'Share Code\' in order to check your right to work status - if you do not have an up to date one, please get one from here: <a target="_blank" href="https://www.gov.uk/view-prove-immigration-status">https://www.gov.uk/view-prove-immigration-status</a>',
];

export const PAYE_DOCUMENTS = [
  'National Insurance Number',
  'Bank details',
  'P45',
  'Tax Code\n',
  'Valid proof of identification (passport)',
  'Proof of Right to Work (for non UK passport holders)',
  'If you do not have a British passport you will need a valid \'Share Code\' in order to check your right to work status - if you do not have an up to date one, please get one from here: <a target="_blank" href="https://www.gov.uk/view-prove-immigration-status">https://www.gov.uk/view-prove-immigration-status</a>',
];

export const EMPLOYER_STEPS: HowItWorksStep[] = [
  {
    label:
      'Vyce helps you manage your workforce better. It frees up your time and helps improve transparency, communication and productivity. Here’s how...',
    subtitle: 'Welcome!',
    imgPath: config.how_it_works.welcome,
  },
  {
    label:
      'Post your jobs for free and instantly get matched to relevant verified professionals near your jobs.',
    subtitle: 'Hiring',
    imgPath: config.how_it_works.hiring,
  },
  {
    label:
      'Virtually onboard and verify new starters for a safer & more compliant workforce. Make informed, data driven decisions and manage your teams all in one place with your real time team dashboard.',
    subtitle: 'Teams',
    imgPath: config.how_it_works.teams,
  },
  {
    label:
      'Create and manage project workflows with teams. Keep all project documents in a central hub available 24/7 to relevant team members. Track tasks, project progress and get an ‘At a glance’ snapshot of your projects in one place.',
    subtitle: 'Projects',
    imgPath: config.how_it_works.projects,
  },
  {
    label:
      'Track your teams on site productive time more accurately with Vyce Time. No hardware, mobile powered time tracking, approvals and reporting.',
    subtitle: 'Time & Attendance',
    imgPath: config.how_it_works.time_and_attendance,
  },
  {
    label:
      'Tech powered payroll for your contractor workforce. Single invoice for all contractors and all compliance and contractual obligations taken care of.',
    subtitle: 'Payroll',
    imgPath: config.how_it_works.payroll,
  },
];

export const TERMS_AND_CONDITIONS_LINK = 'https://www.vyce.io/terms-conditions';
