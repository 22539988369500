import React, { memo } from 'react';

import { Box } from '@material-ui/core';
import clsx from 'clsx';
import noop from 'lodash/noop';

import { PreviewDocumentDialog } from '../../PreviewDocumentDialog';
import { ImageView } from '../../ImageView';
import { pdfType } from '../config';
import { useStyles } from '../styles';

interface Props {
  chosenType: 'pdf' | 'img';
  url: string;
  isDocument?: boolean;
  height?: number;
  width?: number | string;
  isBlured?: boolean;
  handleOpenPreviewDialog?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}
export const SmallPreviewContent = memo(
  ({
    chosenType,
    url,
    isDocument,
    height,
    width,
    isBlured = false,
    handleOpenPreviewDialog = noop,
  }: Props) => {
    const classes = useStyles();

    return (
      <Box
        onClick={handleOpenPreviewDialog}
        height={height}
        width={width}
        className={classes.smallPreviewContainer}>
        {chosenType === pdfType ? (
          <Box className={clsx(classes.smallPdfPreview, { [classes.blured]: isBlured })}>
            <PreviewDocumentDialog.PDFPreview url={url} />
          </Box>
        ) : (
          <ImageView url={url} isDocument={isDocument} height={height} width={width} />
        )}
      </Box>
    );
  }
);
