import React, { useEffect, useMemo, useState } from 'react';

import { Box, Paper, Typography, Button } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { RiEyeLine } from 'react-icons/ri';
import { AiOutlinePlus } from 'react-icons/ai';
import { TbPencil } from 'react-icons/tb';

import { getAvatar } from '@vyce/core/src/utils/getAvatar';
import { getTimeLogsByShiftRequest, getTimeWorkerRequest } from '@vyce/core/src/api/time';
import { getUrlItems } from '@vyce/core/src/utils/url';
import { AppCustomTable, AppIconButton, PageLoading, GridCellWithAvatar } from '@vyce/core/src/components';

import { useStyles } from '../styles';

import {
  TimeLogByShift,
  TimeLogByShiftDateItem,
  TimeLogByShiftDateItemLog,
  TimeTotalInfoProps,
  TimeWorker,
} from '@vyce/core/src/types';
import { formatDate, getTime, getUKFormattedDate } from '@vyce/core/src/utils/dates';
import { useBooleanState } from '@vyce/core/src/hooks';
import { EditSingleTimeLogWorkerDialog } from '@vyce/core/src/components/EditWorkerHoursDialog/EditSingleTimeLogWorkerDialog';
import { TimeLogWarningCell, WorkerHoursCell } from '@vyce/core/src/components/EditWorkerHoursDialog';

import { TimeTotalInfo } from '../components';
import { AddTimeLogsDialog } from './components/AddTimeLogsDialog';

interface Props {
  companyId?: string;
}

export const TimeLogsByShift: React.FC<Props> = ({ companyId }) => {
  const history = useHistory();
  const classes = useStyles();
  const { userId, siteId, period } = useParams<{ userId: string; siteId: string; period: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [workerId, setWorkerId] = useState<string>('');
  const [locationName, setLocationName] = useState<string>('');
  const [locationId, setLocationId] = useState<string>('');
  const [log, setLog] = useState<TimeLogByShift>();
  const [dateItemsToEdit, setDateItemsToEdit] = useState<TimeLogByShiftDateItem[]>([]);
  const [selectedLog, setSelectedLog] = useState<TimeLogByShiftDateItemLog | null>(null);
  const [selectedDayLog, setSelectedDayLog] = useState<TimeLogByShiftDateItem | null>(null);
  const [worker, setWorker] = useState<TimeWorker>();
  const [totals, setTotals] = useState<TimeTotalInfoProps>();
  const [isEditDialogOpen, openEditDialog, closeEditDialog] = useBooleanState(false);
  const [isNewLineDialogOpen, openNewLineDialog, closeNewLineDialog] = useBooleanState(false);

  const periods = period?.split(' - ');

  const getData = async () => {
    if (!companyId || !workerId) {
      return;
    }
    try {
      const urlItems = getUrlItems(siteId);
      setLoading(true);
      const { data } = await getTimeLogsByShiftRequest(companyId, workerId, {
        start_date: periods[0],
        end_date: periods[1],
        site_id: urlItems.id,
      });
      setLog(data);
      setDateItemsToEdit(data.date_items || []);
      setTotals(data.totals);
      const workerRes = await getTimeWorkerRequest(workerId, companyId);
      setWorker(workerRes.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const goToActivityLogs = (date: string, shiftId: string) => {
    history.push(`${history.location.pathname}/${date}?shiftId=${shiftId}`);
  };

  const handleEditClick = (log: TimeLogByShiftDateItemLog, dayLog: TimeLogByShiftDateItem) => {
    setSelectedLog(log);
    setSelectedDayLog(dayLog);
    openEditDialog();
  };

  const handleAddNewLineClick = (dayLog: TimeLogByShiftDateItem) => {
    setSelectedDayLog(dayLog);
    openNewLineDialog();
  };

  const handleEditDialogClose = () => {
    closeEditDialog();
    setSelectedLog(null);
    setSelectedDayLog(null);
  };

  const handleNewLineDialogClose = () => {
    closeNewLineDialog();
    setSelectedLog(null);
    setSelectedDayLog(null);
  };

  useEffect(() => {
    if (userId) {
      const urlItems = getUrlItems(userId);
      setWorkerId(urlItems.id);
    }
  }, [userId]);

  useEffect(() => {
    getData();
  }, [workerId]);

  useEffect(() => {
    if (siteId) {
      const urlItems = getUrlItems(siteId);
      setLocationName(urlItems.name);
      setLocationId(urlItems.id);
    }
  }, [siteId]);

  const columns = useMemo(
    () => [
      {
        name: 'date',
        title: 'Date',
        flex: 0.3,
        renderCell: (row: TimeLogByShiftDateItem) => (
          <Box display="flex" alignItems="center" height={70} lineHeight="48px">
            {formatDate(row?.date, 'dddd D MMMM')}
          </Box>
        ),
      },
      {
        name: 'time_logs',
        title: 'Shift',
        flex: 0.2,
        renderCell: (row: TimeLogByShiftDateItem) => (
          <Box>
            {row.time_logs?.map((log: TimeLogByShiftDateItemLog, index: number) => (
              <Box display="flex" alignItems="center" height={70} key={'shift' + index}>
                {log.shift_name}
              </Box>
            ))}
          </Box>
        ),
      },
      {
        name: 'time_logs',
        title: 'Clock In Time',
        flex: 0.15,
        renderCell: (row: TimeLogByShiftDateItem) => (
          <Box>
            {row.time_logs?.map((log: TimeLogByShiftDateItemLog, index: number) => (
              <Box
                display="flex"
                alignItems="center"
                style={{ fontWeight: 600 }}
                height={70}
                key={`clock-in${index}`}>
                <>
                  {log.checked_in ? (
                    <TimeLogWarningCell check_in_ok={log.check_in_ok} face_in_ok={log.face_in_ok}>
                      <>{getTime(log.checked_in)}</>
                    </TimeLogWarningCell>
                  ) : (
                    '-'
                  )}
                </>
              </Box>
            ))}
          </Box>
        ),
      },
      {
        name: 'time_logs',
        title: 'Clock Out Time',
        flex: 0.15,
        renderCell: (row: TimeLogByShiftDateItem) => (
          <Box>
            {row.time_logs?.map((log: TimeLogByShiftDateItemLog, index: number) => (
              <Box
                display="flex"
                alignItems="center"
                style={{ fontWeight: 600 }}
                height={70}
                key={`clock-out${index}`}>
                <>
                  {log.checked_out ? (
                    <TimeLogWarningCell
                      check_out_ok={log.check_out_ok}
                      face_out_ok={log.face_out_ok}
                      auto_clock_out={log.auto_clock_out}
                      manual_clock_out={log.manual_clock_out}>
                      <>{getTime(log.checked_out)}</>
                    </TimeLogWarningCell>
                  ) : (
                    '-'
                  )}
                </>
              </Box>
            ))}
          </Box>
        ),
      },
      {
        name: 'time_logs',
        title: 'Clocked Hours',
        flex: 0.15,
        renderCell: (row: TimeLogByShiftDateItem) => (
          <Box>
            {row.time_logs?.map((log: TimeLogByShiftDateItemLog, index: number) => (
              <WorkerHoursCell
                key={`clocked-hours${index}`}
                decimals={log.clocked_in_hours_decimals}
                amendmentDecimals={0}
              />
            ))}
          </Box>
        ),
      },
      {
        name: 'time_logs',
        title: 'Regular Paid Hours',
        flex: 0.15,
        renderCell: (row: TimeLogByShiftDateItem) => (
          <Box>
            {row.time_logs?.map((log: TimeLogByShiftDateItemLog, index: number) => (
              <WorkerHoursCell
                key={`regular-hours${index}`}
                decimals={log.basic_hours_decimals}
                amendmentDecimals={log.basic_amendment_hours_decimals}
              />
            ))}
          </Box>
        ),
      },
      {
        name: 'time_logs',
        title: 'Overtime Paid Hours',
        flex: 0.15,
        renderCell: (row: TimeLogByShiftDateItem) => (
          <Box>
            {row.time_logs?.map((log: TimeLogByShiftDateItemLog, index: number) => (
              <WorkerHoursCell
                key={`ot-hours${index}`}
                decimals={log.overtime_hours_decimals}
                amendmentDecimals={log.overtime_amendment_hours_decimals}
              />
            ))}
          </Box>
        ),
      },
      {
        name: 'time_logs',
        title: 'View',
        justifyContent: 'center',
        width: 96,
        flex: 0.01,
        renderCell: (row: TimeLogByShiftDateItem) => (
          <Box display="flex" gridGap={16}>
            <Box width={40}>
              {row.time_logs?.map((log: TimeLogByShiftDateItemLog, index: number) => (
                <Box key={`edit-${index}`}>
                  {!log.approved ? (
                    <Box display="flex" alignItems="center" justifyContent="center" height={70}>
                      <AppIconButton onClick={() => handleEditClick(log, row)}>
                        <TbPencil size={18} />
                      </AppIconButton>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              ))}
              {!row.time_logs?.length && (
                <Box display="flex" alignItems="center" justifyContent="center" height={70}>
                  <AppIconButton onClick={() => handleAddNewLineClick(row)}>
                    <AiOutlinePlus size={18} />
                  </AppIconButton>
                </Box>
              )}
            </Box>
            <Box display="flex" gridGap={16}>
              <Box width={40}>
                {!!row.time_logs?.length &&
                  row.time_logs?.map(
                    (log: TimeLogByShiftDateItemLog, index: number) =>
                      log.checked_out && (
                        <Box
                          key={`view-${index}`}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          height={70}>
                          <AppIconButton onClick={() => goToActivityLogs(row.date, log.shift_id)}>
                            <RiEyeLine size={18} />
                          </AppIconButton>
                        </Box>
                      )
                  )}
              </Box>
            </Box>
          </Box>
        ),
      },
    ],
    []
  );

  if (loading) {
    return (
      <Box height="calc(100vh - 300px)">
        <PageLoading />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" gridGap={16}>
      {totals && (
        <TimeTotalInfo
          basic={totals.basic_hours_decimals}
          overtime={totals.overtime_hours_decimals}
          totalPaid={totals.total_hours_decimals}
          totalClockedHours={totals.total_clocked_in_hours_decimals}
          totalAdjustments={totals.total_amendments_decimals}
          workers={totals.total_workers}
          period={`${getUKFormattedDate(periods[0])} - ${getUKFormattedDate(periods[1])}`}
        />
      )}
      <Paper variant="outlined" className={classes.paper}>
        <Box display="flex" gridGap={4} alignItems="center">
          <Typography variant="h6">
            Time Log for {worker?.first_name} {worker?.last_name} - Week {log?.week} of {log?.year} -{' '}
            {locationName}
          </Typography>
        </Box>

        <Box marginTop={3} overflow="auto">
          <AppCustomTable columns={columns} data={dateItemsToEdit || []} />
        </Box>

        {selectedLog && selectedDayLog && (
          <EditSingleTimeLogWorkerDialog
            onSuccess={getData}
            userId={workerId}
            log={selectedLog}
            open={isEditDialogOpen}
            handleClose={handleEditDialogClose}
            date={selectedDayLog.date}
            renderAvatar={() => (
              <GridCellWithAvatar
                avatarUrl={worker?.avatar}
                avatarPlaceholder={getAvatar(worker?.gender)}
                name={`${worker?.first_name} ${worker?.last_name}`}
              />
            )}
          />
        )}

        {selectedDayLog && companyId && (
          <AddTimeLogsDialog
            getLogs={getData}
            title={`Add a Time Log - ${formatDate(selectedDayLog.date, 'dddd D')}`}
            userId={workerId}
            companyId={companyId}
            locationId={locationId}
            dayLog={selectedDayLog}
            open={isNewLineDialogOpen}
            handleClose={handleNewLineDialogClose}
          />
        )}
      </Paper>
    </Box>
  );
};
