import React, { useContext, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  IconButton,
  withStyles,
} from '@material-ui/core';
import { BiTrash } from 'react-icons/bi';
import { IoCloseOutline } from 'react-icons/io5';

import { TimeLogByShiftDateItemLog } from '@vyce/core/src/types';
import { formatDate, numbersToTime, timeToSeconds } from '@vyce/core/src/utils/dates';
import { editWorkerHoursRequest, deleteTimeLogRequest } from '@vyce/core/src/api/time';
import { ButtonTitleWithLoading, ConfirmDialog } from '@vyce/core/src/components';
import { DeviceContext } from '@vyce/core/src/contexts';
import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';
import { monochrome } from '@vyce/core/src/theme/styles';
import { useBooleanState } from '@vyce/core/src/hooks';

import useStyles from './styles';
import { AMENDMENT_INPUT_LENGTH, AmendmentInput } from './components/AmendmentInput';

interface Props {
  handleClose: Function;
  open: boolean;
  userId: string;
  log: TimeLogByShiftDateItemLog;
  onSuccess: Function;
  date: string;
  renderAdditionalButton?: () => React.ReactNode;
  renderAvatar: () => React.ReactNode;
  renderNextWorkerButton?: (props: {
    saveFn: () => void;
    loading: boolean;
    disabled: boolean;
  }) => React.ReactNode;
}

const StyledDialog = withStyles({
  root: {
    //@ts-ignore
    zIndex: '1260 !important',
  },
})(Dialog);

export const EditSingleTimeLogWorkerDialog: React.FC<Props> = ({
  open,
  handleClose,
  log,
  userId,
  date,
  onSuccess,
  renderNextWorkerButton,
  renderAvatar,
  renderAdditionalButton,
}) => {
  const classes = useStyles();

  const [isConfirmDeleteDialogOpen, openConfirmDeleteDialog, closeConfirmDeleteDialog] =
    useBooleanState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isNegativeTotal, setIsNegativeTotal] = useState<boolean>(false);
  const [basicTotal, setBasicTotal] = useState<number>(0);
  const [overtimeTotal, setOvertimeTotal] = useState<number>(0);
  const { isMobile } = useContext(DeviceContext);
  const [isBasicMinus, setIsBasicMinus] = useState<boolean>(false);
  const [isOvertimeMinus, setIsOvertimeMinus] = useState<boolean>(false);
  const [basicValue, setBasicValue] = useState<string>('00:00');
  const [overtimeValue, setOvertimeValue] = useState<string>('00:00');

  const isExceeded = useMemo(() => {
    return overtimeTotal + basicTotal > 24 * 60 * 60;
  }, [overtimeTotal, basicTotal]);

  const save = async () => {
    try {
      setLoading(true);
      await editWorkerHoursRequest({
        companyId: log.company_id,
        siteId: log.site_id,
        shiftId: log.shift_id,
        day: date,
        userId,
        amendments: {
          basic_amendment: timeToSeconds(basicValue, isBasicMinus),
          overtime_amendment: timeToSeconds(overtimeValue, isOvertimeMinus),
        },
      });
      setLoading(false);
      onSuccess();
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const deleteTimeLog = async () => {
    try {
      setLoading(true);
      await deleteTimeLogRequest(log.company_id, date, userId);
      setLoading(false);
      onSuccess();
      handleClose();
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const handleDeleteClick = () => {
    openConfirmDeleteDialog();
  };

  useEffect(() => {
    setIsBasicMinus(log.basic_amendment_hours < 0 || log.basic_amendment_minutes < 0);
    setIsOvertimeMinus(log.overtime_amendment_hours < 0 || log.overtime_amendment_minutes < 0);
    setBasicValue(numbersToTime(log.basic_amendment_hours, log.basic_amendment_minutes));
    setOvertimeValue(numbersToTime(log.overtime_amendment_hours, log.overtime_amendment_minutes));
  }, [log]);

  return (
    <Box>
      <StyledDialog
        container={document.getElementById(MAIN_CONTAINER_ID)}
        fullScreen={isMobile}
        maxWidth="md"
        onClose={() => handleClose()}
        open={open}>
        <DialogTitle>
          <Box display="flex" flexDirection="column" gridGap={8}>
            <Box>Edit Hours</Box>
            <Box display="flex" gridGap={8} alignItems="center">
              <Box width="max-content">{renderAvatar?.()}</Box>
              <Typography variant="body1">
                on {formatDate(log?.checked_in, 'dddd DD MMMM')}, {log?.shift_name}
              </Typography>
            </Box>
          </Box>
          <IconButton onClick={() => handleClose()} className={classes.closeButton}>
            <IoCloseOutline size={30} />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Box display="flex" gridGap={16} alignItems="center">
            <Box
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              gridGap={32}
              paddingTop={2}
              marginBottom={1}>
              <AmendmentInput
                setIsNegativeTotal={setIsNegativeTotal}
                trackedHours={log.basic_hours}
                trackedMinutes={log.basic_minutes}
                title="Regular Hours"
                value={basicValue}
                handleIconClick={() => setIsBasicMinus(value => !value)}
                isMinus={isBasicMinus}
                setValue={setBasicValue}
                setTotal={setBasicTotal}
              />

              {!isMobile && <Divider orientation="vertical" flexItem />}

              <AmendmentInput
                setIsNegativeTotal={setIsNegativeTotal}
                trackedHours={log.overtime_hours}
                trackedMinutes={log.overtime_minutes}
                title="Overtime Hours"
                value={overtimeValue}
                handleIconClick={() => setIsOvertimeMinus(value => !value)}
                isMinus={isOvertimeMinus}
                setValue={setOvertimeValue}
                setTotal={setOvertimeTotal}>
                <Button
                  startIcon={<BiTrash size="16px" color={monochrome.mediumdark} />}
                  className={classes.buttonWrapper}
                  style={{ backgroundColor: monochrome.lighter }}
                  onClick={handleDeleteClick}
                />
              </AmendmentInput>
            </Box>
          </Box>
          {isExceeded && (
            <Typography variant="caption" color="error">
              Worked hours can’t exceed 24 hours
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          <Box display="flex" width="100%" justifyContent={renderAdditionalButton ? 'space-between' : 'end'}>
            {renderAdditionalButton?.()}

            <Box display="flex" gridGap={8}>
              <Button size="large" onClick={() => handleClose()} variant="outlined">
                Cancel
              </Button>
              {renderNextWorkerButton?.({
                saveFn: save,
                loading,
                disabled:
                  basicValue.length < AMENDMENT_INPUT_LENGTH ||
                  overtimeValue.length < AMENDMENT_INPUT_LENGTH ||
                  isNegativeTotal ||
                  isExceeded,
              })}

              <Button
                style={{ width: 152 }}
                size="large"
                disabled={
                  basicValue.length < AMENDMENT_INPUT_LENGTH ||
                  overtimeValue.length < AMENDMENT_INPUT_LENGTH ||
                  isNegativeTotal ||
                  isExceeded
                }
                onClick={async () => {
                  save();
                  handleClose();
                }}
                color="primary"
                variant="contained">
                <ButtonTitleWithLoading title="Edit Hours" loaderVariant="primary" loading={loading} />
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </StyledDialog>

      <ConfirmDialog
        handleClose={closeConfirmDeleteDialog}
        open={isConfirmDeleteDialogOpen}
        confirmText="Yes, delete it"
        cancelText="No, cancel"
        title="Delete this Time Log?"
        subtitle="Are you sure you want to delete this Time Log? This action cannot be undone."
        handleConfirm={deleteTimeLog}
      />
    </Box>
  );
};
