import React from 'react';

import { ResponsiveBar } from '@nivo/bar';
import { useTheme } from '@material-ui/core';
import { monochrome } from '../../../../theme/styles';

interface Props {
  data: any[];
}

export const ChanceOfRainBarChart: React.FC<Props> = ({ data }) => {
  const theme = useTheme();

  return (
    <ResponsiveBar
      data={data}
      keys={['value']}
      indexBy="time"
      margin={{ top: 0, right: 0, bottom: 39, left: 0 }}
      padding={0}
      label={d => `${d.value}%`}
      labelTextColor={monochrome.dark}
      colors={[theme.palette.type === 'dark' ? '#8DE9FF' : '#E3FEFF']}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 16,
        tickRotation: 0,
      }}
      axisLeft={null}
      enableGridY={false}
      labelSkipWidth={10}
      labelSkipHeight={10}
      isInteractive={false}
      animate={false}
      theme={{
        fontSize: 13,
        textColor: theme.palette.text.primary,
        labels: {},
      }}
    />
  );
};
