import { Box } from '@material-ui/core';

import { TopPerformingWorker } from '@vyce/core/src/types';
import { getAvatar } from '@vyce/core/src/utils/getAvatar';
import { GridCellWithAvatar } from '@vyce/core/src/components';

import { useStyles } from '../styles';
import { HourNumber } from './HourNumber';

export const TopWorker = ({
  uuid,
  clocked_hours,
  full_name,
  gender,
  avatar,
  cnaViewWorker,
}: TopPerformingWorker) => {
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="space-between" width="100%" className={classes.border} padding={1}>
      <GridCellWithAvatar
        avatarUrl={avatar}
        avatarPlaceholder={getAvatar(gender)}
        link={cnaViewWorker ? `/time/users/${full_name.split(' ')[0]}_${uuid}/profile` : undefined}
        name={full_name}
      />
      <HourNumber bgColor="#E3FEFF" textColor="#005BD4" label={`${clocked_hours ?? 0}h`} />
    </Box>
  );
};

export const TopWorkers = ({
  topPerformingWorkers,
  cnaViewWorker,
}: {
  topPerformingWorkers: TopPerformingWorker[];
  cnaViewWorker: boolean;
}) => {
  return (
    <Box display="flex" flexDirection="column" gridGap={8} width="100%">
      {topPerformingWorkers.map(item => (
        <TopWorker cnaViewWorker={cnaViewWorker} key={item.uuid} {...item} />
      ))}
    </Box>
  );
};
