//@ts-nocheck
import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import MuiPhoneInput from 'material-ui-phone-number';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { FieldProps } from '../../types';
import { appTextFieldRootStyle } from '../inputs';
import { formatPhone } from '../../views/auth/components/Register';

const onlyCountries = [
  'gb',
  'lv',
  'pl',
  'lt',
  'ro',
  'rs',
  'sk',
  'si',
  'hr',
  'de',
  'al',
  'ad',
  'at',
  'by',
  'be',
  'ba',
  'bg',
  'cy',
  'cz',
  'dk',
  'ee',
  'fo',
  'fi',
  'fr',
  'gi',
  'gr',
  'hu',
  'is',
  'ie',
  'it',
  'li',
  'lu',
  'mk',
  'mt',
  'md',
  'mc',
  'me',
  'nl',
  'no',
  'pt',
  'ru',
  'sm',
  'es',
  'se',
  'ch',
  'tr',
  'ua',
  'va',
  'us',
  'am',
];

export const validatePhone = (value: string = '') => {
  // validate UK format
  if (value.includes('+44') && value[3] === '0') {
    return 'Please remove the first 0';
  }

  return !value.length || value.length > 8 || 'Invalid Mobile Number';
};

export const mobileNumberRules = {
  required: 'Phone Number is required',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: appTextFieldRootStyle(theme),
    input: {
      padding: '27px 0px 10px',
    },
    focused: {},
  })
);

export const PhoneNumberField: React.FC<FieldProps> = ({
  margin = undefined,
  label = 'Phone Number',
  name = '',
  rules = mobileNumberRules,
  autoFocus = false,
  disabled = false,
  onKeyDown,
}) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const classes = useStyles();

  const handleChange = (value: string) => {
    const formattedValue = formatPhone(value);
    setValue(name, formattedValue);
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={{ ...rules, validate: validatePhone }}
      render={({ field }) => (
        <MuiPhoneInput
          {...field}
          variant="filled"
          error={!!errors[name]?.message}
          helperText={errors[name]?.message || ''}
          label={label}
          onChange={handleChange}
          fullWidth
          cy-test-id="phone"
          autoFocus={autoFocus}
          disabled={disabled}
          disableAreaCodes
          countryCodeEditable={false}
          margin={margin}
          autoComplete="phone"
          enableLongNumbers={true}
          onlyCountries={onlyCountries}
          defaultCountry="gb"
          InputProps={
            { classes, disableUnderline: true, autoComplete: 'none' } as Partial<OutlinedInputProps>
          }
          onKeyDown={onKeyDown}
        />
      )}
    />
  );
};
