import React, { useContext } from 'react';

import { Box } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';

import { DeviceContext } from '@vyce/core/src/contexts';
import { CommonNavBarBlock } from '@vyce/core/src/components';
import { getPaddingForContent } from '@vyce/core/src/utils';

import { USER_MANAGEMENT_TABS } from './constants';
import { EmployerStaffManagement, EmployerInviteList, Employees, TeamMembers } from './components';

export const EmployerUserManagement: React.FC = () => {
  const { isMobile } = useContext(DeviceContext);
  const padding = getPaddingForContent(isMobile);

  return (
    <>
      <CommonNavBarBlock tabItems={USER_MANAGEMENT_TABS} />

      <Box padding={padding}>
        <Switch>
          <Route path={'/user-management/payroll'} component={Employees} />
          <Route path={'/user-management/teams'} component={TeamMembers} />
          <Route path={'/user-management/admins'} component={EmployerStaffManagement} />
          <Route path={'/user-management/company-invites'} component={EmployerInviteList} />
        </Switch>
      </Box>
    </>
  );
};
