import React, { useContext, useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import dayjs from 'dayjs';

import { DeviceContext } from '@vyce/core/src/contexts';
import { AppClock } from '@vyce/core/src/components/inputs';

import { Action } from '../types';
import { MAIN_CONTAINER_ID } from '../../../constants';

const styles = { minWidth: 85 };

interface Props {
  isOpen: boolean;
  action?: Action;
  value: Date | null;
  clockInTimestamp: string | null;
  onClose: () => void;
  onChange: (date: Date | null) => void;
}

const timeHeader = 'Set the hour to clock-';
const id = 'responsive-time-dialog-title';

const validateValue = ({ value }: { value: Date }) => {
  return dayjs(value) <= dayjs();
};

export const TimeDialog = ({ isOpen, action, value, onClose, onChange }: Props) => {
  const [date, setDate] = useState<Date | null>(null);
  const { isMobile } = useContext(DeviceContext);

  const isValid = validateValue({
    value: date as Date,
  });

  const handleChange = (date: Date | null) => {
    setDate(date);
  };

  const sendDate = () => {
    onChange(date ? date : new Date());
    onClose();
  };

  useEffect(() => {
    if (value) {
      setDate(value);
      return;
    }
    setDate(null);
  }, [value]);

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      open={isOpen}
      onClose={onClose}
      aria-labelledby={id}>
      <DialogTitle id={id}>{`${timeHeader}${action}`}</DialogTitle>
      <DialogContent style={{ minWidth: isMobile ? '100%' : 440, minHeight: isMobile ? '100%' : 413 }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <AppClock value={date} onChange={handleChange} />
        </MuiPickersUtilsProvider>
        {!isValid && <Typography color="error">Please set the current time or an earlier hour.</Typography>}
      </DialogContent>
      <DialogActions>
        <Box width="100%" display="flex" justifyContent="space-between">
          <Button size="large" variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button
            size="large"
            disabled={!isValid}
            color="primary"
            variant="contained"
            onClick={sendDate}
            style={styles}>
            Done
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
