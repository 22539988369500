import React, { useContext } from 'react';

import { Box, Button } from '@material-ui/core';

import { OnboardingStepTitle } from '@vyce/core/src/components/OnboardingStepTitle';

import { ManualClockContext } from './ManualClockProvider';

const styles = { width: 120 };

export const DoYouWantToClockStep = () => {
  const { action, onChangeAction } = useContext(ManualClockContext);

  const handleClockIn = () => {
    onChangeAction('in');
  };

  const handleClockOut = () => {
    onChangeAction('out');
  };

  return (
    <>
      <OnboardingStepTitle text="Do you want to clock someone in or out?" />

      <Box display="flex" justifyContent="center" marginTop={2} gridGap={16}>
        <Button
          onClick={handleClockIn}
          size="large"
          style={styles}
          variant={action === 'in' ? 'contained' : 'outlined'}
          color="primary">
          Clock in
        </Button>
        <Button
          onClick={handleClockOut}
          size="large"
          style={styles}
          variant={action === 'out' ? 'contained' : 'outlined'}
          color="primary">
          Clock out
        </Button>
      </Box>
    </>
  );
};
