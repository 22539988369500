import { formatDate } from '@vyce/core/src/utils/dates';

import {
  DEFAULT_EMAIL_NOTIFICATION_DATE,
  DEFAULT_SMS_NOTIFICATION_DATE,
  HMRC_SETTINGS_TYPE,
  PAY_FREQUENCIES,
} from '../../../constants/main';

export const defaultValues = {
  hmrcSettingsType: HMRC_SETTINGS_TYPE.OUTSOURCE,
  pay_frequency: PAY_FREQUENCIES[0].value as string,
  pay_weekday: '0',
  pay_last_workday: '1',
  email_pay_statement: DEFAULT_EMAIL_NOTIFICATION_DATE,
  sms_pay_statement: DEFAULT_SMS_NOTIFICATION_DATE,
  doNotSendEmailStatement: false,
  doNotSendSMSStatement: false,
  name: '',
  tax_office_info: '',
  accounting_office_ref: '',
  utr: '',
  payment_date: formatDate(new Date()),
  sender: '',
  sender_id: '',
  contact_fax: '',
  company_id: undefined,
  password: '',
  emailWeekday: 'Monday',
  SMSWeekday: 'Monday',
};

export const fullHeight = { height: '100%' };

export const hmrcKeys = [
  'tax_office_info',
  'accounting_office_ref',
  'utr',
  'contact_email',
  'contact_first_name',
  'contact_last_name',
  'contact_telephone',
  'sender_id',
  'password',
  'sender',
];
