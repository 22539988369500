import React from 'react';

import { OnboardingStepTitle } from '@vyce/core/src/components';
import { JobsField } from '@vyce/core/src/components/controlled-inputs';

import { TitleProps } from './types';

const rules = { required: 'Job Title is required' };

export const EmployeeJobTitleStep = ({ title = 'What is your job title?' }: TitleProps) => (
  <>
    <OnboardingStepTitle text={title} />
    <JobsField label="Job Title" rules={rules} multiple={false} name="job_title" margin="normal" />
  </>
);
