import React, { useContext } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Divider,
  FormControlLabel,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';

import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';
import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { AppCheckbox } from '@vyce/core/src/components/inputs/AppCheckbox';
import { ButtonTitleWithLoading } from '@vyce/core/src/components/ButtonTitleWithLoading';

import { useStyles } from '../styles';
const FilterBlock = ({
  title,
  type,
  options,
}: {
  title: string;
  type: 'locations' | 'roles';
  options: { id: string; name: string }[];
}) => {
  const { control } = useFormContext();
  return (
    <Box display="flex" flexDirection="column" gridGap={8} overflow="auto">
      <Typography variant="subtitle2">{title}</Typography>
      {options.map(item => (
        <Controller
          key={item?.id}
          name={`${type}.${item?.id}`}
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <AppCheckbox
                  onChange={e => field.onChange(e.target.checked)}
                  color="primary"
                  variant="rectangle"
                  checked={field.value === undefined ? true : field.value}
                />
              }
              label={item?.name}
            />
          )}
        />
      ))}
    </Box>
  );
};

export const FiltersDialog = ({
  isOpen,
  locations,
  roles,
  loading,
  onSubmit,
  onClose,
  restoreFilters,
}: {
  isOpen: boolean;
  locations: { id: string; name: string }[];
  roles: { id: string; name: string }[];
  loading: boolean;
  onClose: () => void;
  onSubmit: () => void;
  restoreFilters: () => void;
}) => {
  const { isMobile } = useContext(DeviceContext);
  const classes = useStyles();
  return (
    <Dialog
      fullScreen={isMobile}
      container={document.getElementById(MAIN_CONTAINER_ID)}
      open={isOpen}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography>Didn't Clock Out - Filters</Typography>
          <Button variant="outlined" color="primary" onClick={restoreFilters}>
            Restore to default
          </Button>
        </Box>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Box display="flex" gridGap={16} height="calc(100vh - 300px)" marginTop={2}>
          <FilterBlock title="Which Locations should be displayed?" options={locations} type="locations" />
          <Divider orientation="vertical" />
          <FilterBlock title="Which roles should be displayed?" options={roles} type="roles" />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button size="large" variant="outlined" onClick={onClose}>
          Close
        </Button>
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={() => onSubmit()}
          disabled={loading}>
          <ButtonTitleWithLoading title="Filter" loaderVariant="paper" loading={!!loading} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
