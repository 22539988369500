import React from 'react';

import { Box, Typography, useTheme } from '@material-ui/core';

import { getUKFormattedDate } from '@vyce/core/src/utils/dates';

import useStyles from '../styles';
import type { Certificate } from '../../../types';

import { dateFields } from '../config';
import { CertificateOptions } from '../types';

const academicOptions: CertificateOptions[] = [
  { fieldName: 'title', title: 'Academic Title' },
  { fieldName: 'issuer', title: 'Institute Name' },
  { fieldName: 'start_date', title: 'Date Awarded' },
];

const industryOptions: CertificateOptions[] = [
  { fieldName: 'title', title: 'Qualification Name' },
  { fieldName: 'issuer', title: 'Issuing Body' },
  { fieldName: 'expiry_date', title: 'Expiration Date' },
];

const CustomQualificationContentItem = ({
  fieldName,
  title,
  value,
}: {
  fieldName: string;
  title: string;
  value: string;
}) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column">
      <Typography
        className={classes.smallText}
        style={{ color: theme.palette.type === 'dark' ? '#A0A3BD' : '#6E7191' }}>
        {title}
      </Typography>
      <Typography className={classes.smallText}>
        {dateFields.includes(fieldName) ? getUKFormattedDate(value) : value}
      </Typography>
      {fieldName === 'expiry_date' && !value && (
        <Typography className={classes.smallText}>No Expiry</Typography>
      )}
    </Box>
  );
};

export const CustomQualificationContent = ({
  qualification,
}: {
  qualification: Certificate & { type: 'certificate' };
}) => {
  const options = qualification.crt_type === 'academic' ? academicOptions : industryOptions;

  return (
    <Box display="flex" flexDirection="column" gridGap={16} marginTop={2}>
      {options.map(({ fieldName, title }) => (
        <CustomQualificationContentItem
          key={fieldName}
          fieldName={fieldName}
          title={title}
          value={(qualification[fieldName] as string) ?? ''}
        />
      ))}
      {qualification?.additional_data?.reference_number && (
        <CustomQualificationContentItem
          fieldName="reference_number"
          title="Reference number"
          value={qualification.additional_data.reference_number}
        />
      )}
    </Box>
  );
};
