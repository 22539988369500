import React from 'react';

import { Box } from '@material-ui/core';

import { AccentNumbers } from '@vyce/core/src/components';

interface Props {
  data: any;
}

export const NumberWidget = ({ data }: Props) => {
  return (
    <Box>
      {data?.map((number: any) => (
        <AccentNumbers key={number.id} variant="success" label={number.value} />
      ))}
    </Box>
  );
};
