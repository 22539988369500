import React from 'react';

import { TimeLogsByPeriod } from '@vyce/core/src/views/time/TimeLogsByPeriod';

import { useTypedSelector } from '../../hooks';

export const EmployerTimeLogsByPeriod: React.FC = () => {
  const { selectedCompany, selectedPosition } = useTypedSelector(state => state.helper);

  return (
    <TimeLogsByPeriod userPermissions={selectedPosition?.permissions} companyId={selectedCompany?.uuid} />
  );
};
