import React, { useRef } from 'react';

import { Box, Button, Paper, Typography, useTheme } from '@material-ui/core';
import { TbCertificate } from 'react-icons/tb';
import noop from 'lodash/noop';
import { AiOutlineEye } from 'react-icons/ai';
import { BiPencil, BiTrash } from 'react-icons/bi';
import { RiGraduationCapLine } from 'react-icons/ri';
import { useDrag, useDrop } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';

import { monochrome } from '@vyce/core/src/theme/styles';
import { ReactComponent as VerifiedBigLabel } from '@vyce/core/src/assets/svg/verified-big-label-icon.svg';
import { getUKFormattedDate, isDateExpired, isDateExpiring } from '@vyce/core/src/utils/dates';

import { ItemTypes } from '../config';
import useStyles from '../styles';
import { QualificationType } from '../types';

interface DragItem {
  index: number;
  id: string;
  type: string;
}

interface Props {
  data: QualificationType;
  index: number;
  isAdmin: boolean;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  handleDeleteByPreviewButton: (id: string) => void;
  handleOpenPreviewDialog: (id: string) => void;
  handleOpenByEditButton: (id: string) => void;
}

export const QualificationPreviewItem = ({
  data,
  index,
  isAdmin,
  moveCard,
  handleDeleteByPreviewButton,
  handleOpenPreviewDialog,
  handleOpenByEditButton,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);

  const showVerifyLabel =
    data.type === 'certificate' &&
    data.source !== 'custom' &&
    (!data.expiry_date || !isDateExpired(data.expiry_date));

  const isCustomCertificate = data.type === 'certificate' && data.source === 'custom';

  const options = [
    {
      id: '1',
      icon: <AiOutlineEye size="16px" color={monochrome.mediumdark} />,
      bgColor: theme.palette.type === 'dark' ? monochrome.darkest : monochrome.lighter,
      callback: () => handleOpenPreviewDialog(data.uuid),
      show: false,
    },
    {
      id: '2',
      icon: <BiPencil size="16px" color="#610BEF" />,
      bgColor: theme.palette.primary.light,
      callback: (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        handleOpenByEditButton(data.uuid);
      },
      show: isCustomCertificate,
    },
    {
      id: '3',
      icon: <BiTrash size="16px" color="#E61B00" />,
      bgColor: theme.palette.type === 'dark' ? '#FF75CB' : '#FFF2F1',
      callback: (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        handleDeleteByPreviewButton(data.uuid);
      },
      show: isAdmin || isCustomCertificate,
    },
  ];

  const getStatus = () => {
    if (data.type === 'certificate' && data.source !== 'custom') {
      if (showVerifyLabel) {
        return (
          <>
            <Typography color="primary" className={classes.boldText}>
              Vyce Verified
            </Typography>
            {data.expiry_date && <Typography>Expiring {getUKFormattedDate(data.expiry_date)}</Typography>}
          </>
        );
      }
      return (
        <Typography color="error" className={classes.boldText}>
          Expired
        </Typography>
      );
    }

    if (data.type === 'certificate' && data.crt_type === 'industry') {
      if (!data.expiry_date) {
        return (
          <Typography
            style={{ color: theme.palette.type === 'dark' ? '#A6F787' : '#008A00' }}
            className={classes.boldText}>
            No expiry
          </Typography>
        );
      }

      if (isDateExpired(data.expiry_date)) {
        return (
          <Box display="flex" alignItems="center" gridGap={4}>
            <Typography color="error" className={classes.boldText}>
              Expired
            </Typography>
            <Typography>{getUKFormattedDate(data.expiry_date)}</Typography>
          </Box>
        );
      }

      if (isDateExpiring(data.expiry_date)) {
        return (
          <Box display="flex" alignItems="center" gridGap={4}>
            <Typography style={{ color: '#EAAC30' }} className={classes.boldText}>
              Expiring
            </Typography>
            <Typography>{getUKFormattedDate(data.expiry_date)}</Typography>
          </Box>
        );
      }

      return (
        <Box display="flex" alignItems="center" gridGap={4}>
          <Typography
            style={{ color: theme.palette.type === 'dark' ? '#A6F787' : '#008A00' }}
            className={classes.boldText}>
            Valid
          </Typography>
          <Typography>to {getUKFormattedDate(data.expiry_date)}</Typography>
        </Box>
      );
    }

    if (data.type === 'certificate' && data.start_date) {
      return <Typography>{getUKFormattedDate(data.start_date)}</Typography>;
    }
  };

  const handlePreviewClick = () => {
    handleOpenPreviewDialog(data.uuid);
  };

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.QUALIFICATION,
    item: () => {
      return { id: data?.uuid, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  });

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ItemTypes.QUALIFICATION,
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  drag(drop(ref));

  return (
    <div ref={preview} className={classes.previewItem}>
      <Paper variant="outlined">
        <Box
          padding={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          minHeight="104px"
          onClick={handlePreviewClick}>
          <Box display="flex" alignItems="center" gridGap={16}>
            {/*      //TODO uncomment when backend create reorder functional */}
            {/*    <div  ref={ref} className={classes.dragIcon} data-handler-id={handlerId}>
              <RxHamburgerMenu size={16} color="#A0A3BD" />
            </div> */}
            <Box className={classes.qualificationTypeIcon} display="flex" alignItems="center">
              {data.type === 'certificate' && data.crt_type === 'academic' ? (
                <RiGraduationCapLine
                  size={32}
                  color={theme.palette.type === 'dark' ? '#A996FF' : '#610BEF'}
                />
              ) : (
                <TbCertificate size={32} color={theme.palette.type === 'dark' ? '#A996FF' : '#610BEF'} />
              )}
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography variant="h6" className={classes.thinText}>
                {data.title}
              </Typography>
              <Typography
                className={classes.smallText}
                style={{ color: theme.palette.type === 'dark' ? '#EFF0F6' : '#4E4B66' }}>
                {data.issuer}
              </Typography>
              <Box
                display="flex"
                className={classes.smallText}
                style={{ color: theme.palette.type === 'dark' ? '#A0A3BD' : '#6E7191' }}>
                {data.type === 'certificate' && data.crt_type === 'academic' && (
                  <Box marginRight="4px" display="flex">
                    <Typography>Awarded:</Typography>
                  </Box>
                )}
                <Box display="flex">{getStatus()}</Box>
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" gridGap={16}>
            {showVerifyLabel && (
              <Box marginRight={2}>
                <VerifiedBigLabel />
              </Box>
            )}

            {options.map(({ id, icon, bgColor, show, callback = noop }) =>
              show ? (
                <Button
                  key={id}
                  startIcon={icon}
                  className={classes.buttonWrapper}
                  style={{ backgroundColor: bgColor }}
                  onClick={callback}
                />
              ) : null
            )}
          </Box>
        </Box>
      </Paper>
    </div>
  );
};
