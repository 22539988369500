import { WeatherLocation } from '../../../types';

export const DEFAULT_LOCATION: WeatherLocation = {
  latitude: 51.509865,
  longitude: -0.118092,
  name: 'London',
};

export const YOUR_LOCATION_NAME = 'Your location';
export const NUMBER_OF_DAYS = 7;
