import React, { useContext, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { DeviceContext } from '../../../contexts';
import { Image } from '../../../types';
import { ImageUploader } from '../../../components';
import { AppSelect, AppTextField } from '../../../components/inputs';
import { createAdditionalDocumentRequest } from '../../../api/legend/checkers';
import { DOC_TYPES, MAIN_CONTAINER_ID } from '../../../constants';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  open: boolean;
  setOpen: Function;
  getAdditionalDocuments: Function;
  userId: string;
}

const buttonPosition = { top: 32, right: 32 };

export const AddDocumentDialog: React.FC<Props> = ({ open, setOpen, userId, getAdditionalDocuments }) => {
  const { handleServerError } = useContext(NotificationContext);
  const { isMobile } = useContext(DeviceContext);
  const [documentImage, setDocumentImage] = useState<Image>();
  const methods = useForm({
    defaultValues: {
      description: '',
      title: '',
      doc_type: '',
    },
  });

  const onSubmit = async (data: any) => {
    if (!documentImage?.file) {
      return;
    }
    try {
      await createAdditionalDocumentRequest({ document: documentImage.file, userId, data });
      await getAdditionalDocuments();
      setOpen(false);
      methods.reset();
      setDocumentImage(undefined);
    } catch (e) {
      handleServerError(e);
    }
  };

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      maxWidth="xs"
      disableScrollLock
      open={open}
      onClose={() => setOpen(false)}>
      <FormProvider {...methods}>
        <Box position="relative">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <DialogTitle>Add Document</DialogTitle>

            <DialogContent>
              <ImageUploader
                width={250}
                height={150}
                image={documentImage}
                isDocument={true}
                onImageUpload={image => setDocumentImage(image)}
                extraText="documents"
                type="both"
                buttonPosition={buttonPosition}
              />

              <Controller
                control={methods.control}
                name="title"
                render={({ field }) => (
                  <AppTextField
                    {...field}
                    margin="normal"
                    onChange={e => field.onChange(e.target.value)}
                    label="Title"
                    fullWidth
                  />
                )}
              />

              <Controller
                name="doc_type"
                control={methods.control}
                render={props => (
                  <FormControl margin="normal" fullWidth variant="filled">
                    <InputLabel>Document Type</InputLabel>
                    <AppSelect
                      onChange={e => props.field.onChange(e.target.value)}
                      value={props.field.value}
                      fullWidth>
                      {DOC_TYPES.map(item => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </AppSelect>
                  </FormControl>
                )}
              />

              <Controller
                control={methods.control}
                name="description"
                render={({ field }) => (
                  <AppTextField
                    {...field}
                    margin="normal"
                    multiline
                    onChange={e => field.onChange(e.target.value)}
                    label="Description"
                    fullWidth
                  />
                )}
              />
            </DialogContent>

            <DialogActions>
              <Button size="large" variant="outlined" onClick={() => setOpen(false)}>
                Close
              </Button>
              <Button
                size="large"
                type="submit"
                color="primary"
                disabled={!documentImage?.file}
                variant="contained">
                Save
              </Button>
            </DialogActions>
          </form>
        </Box>
      </FormProvider>
    </Dialog>
  );
};
