import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Avatar, Box, Grid, Paper, Tooltip, Typography, useTheme } from '@material-ui/core';
import { HiOutlineChevronLeft, HiOutlinePencil } from 'react-icons/hi';
import { IoMapOutline } from 'react-icons/io5';
import { Theme } from '@material-ui/core/styles';
import { FiBriefcase } from 'react-icons/fi';
import ShowMoreText from 'react-show-more-text';
import { TfiWorld } from 'react-icons/tfi';
import { useHistory, useParams } from 'react-router-dom';
import noop from 'lodash/noop';

import defaultCompanyPicture from '../../../assets/company-icon.png';
import { CaseStudy, Company, CompanyPhoto, Testimonial } from '../../../types';
import { AppA, AppIconButton, PageLoading } from '../../../components';
import useStyles from '../styles';
import { DeviceContext } from '../../../contexts';
import { getCompanyExtendedDataRequest } from '../../../api/companies';
import { getUrlItems } from '../../../utils/url';
import { getUKFormattedDate } from '../../../utils/dates';
import { RandomLogo } from '../../../components/RandomLogo';
import { PreviewInfoRaw } from './components/PreviewInfoRaw';
import { ExpandableViews } from '../components/ExpandableViews';
import { ExpandableViewItem } from '../types';
import { TestimonialItem } from './components/TestimonialItem';
import { CaseStudyItem } from './components/CaseStudyItem';
import { SectionTitle } from './components/SectionTitle';
import { CompanyPreviewPhotos } from './components/CompanyPreviewPhotos';
import { AddTestimonialDialog } from '../../profile/components/AddTestimonialDialog';
import { useTestimonialData } from '../../profile/hooks/useTestimonialData';
import { useBooleanState } from '../../../hooks';
import { WriteTestimonial } from './components/WriteTestimonial';
import { CompanySocialLinks } from './components/CompanySocialLinks';

interface Props {
  token: string;
  editable?: boolean;
  backUrl?: string;
}

const CASE_STUDIES = [
  {
    logo: null,
    title: 'London Eye',
    location: 'London, London Eye',
    dateFrom: '05.04.2022',
    dateTo: '05.04.2023',
  },
];

export const CompanyPreview: React.FC<Props> = ({ token, editable, backUrl }) => {
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const { isMobile } = useContext(DeviceContext);
  const [company, setCompany] = useState<Company>();
  const [photos, setPhotos] = useState<CompanyPhoto[]>([]);
  const [testimonials, setTestimonials] = useState([]);
  const [caseStudies, setCaseStudies] = useState(CASE_STUDIES);
  const [loading, setLoading] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [isTestimonialDialogOpen, openTestimonialDialog, closeTestimonialDialog] = useBooleanState(false);

  const getCompany = async () => {
    const urlItems = getUrlItems(id);
    const companyId = urlItems?.id;
    if (!companyId) {
      return;
    }

    try {
      const res = await getCompanyExtendedDataRequest(token, companyId);
      setCompany(res.data.company);
      setPhotos(res.data.photos || []);
      setTestimonials(res.data.testimonials?.filter((item: Testimonial) => item.public) || []);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const { createTestimonial } = useTestimonialData({
    company,
    getCompanyData: getCompany,
    token,
    closeTestimonialDialog,
    closeConfirmDialog: noop,
  });

  const isLinksBlockShow = useMemo(
    () => company?.instagram || company?.linkedin || company?.facebook || company?.twitter,
    [company]
  );

  useEffect(() => {
    getCompany();
  }, []);

  const goToPreview = () => {
    history.push(`/companies/${company?.name}_${company?.uuid}/general`);
  };

  const goToCompanyList = () => {
    if (backUrl) {
      history.push(backUrl);
    } else {
      history.goBack();
    }
  };

  const getTestimonialItem = (item: ExpandableViewItem) => {
    return <TestimonialItem testimonialItem={item as Testimonial} />;
  };

  const getCaseStudyItem = (item: ExpandableViewItem) => {
    return <CaseStudyItem caseStudy={item as CaseStudy} />;
  };

  return (
    <>
      {loading || !company ? (
        <PageLoading />
      ) : (
        <Paper className={classes.paper} variant="outlined">
          <Tooltip title="Back" placement="right">
            <AppIconButton className={classes.backIcon} onClick={goToCompanyList}>
              <HiOutlineChevronLeft />
            </AppIconButton>
          </Tooltip>

          {editable && (
            <Tooltip title="Edit" placement="left">
              <AppIconButton className={classes.editIcon} onClick={goToPreview}>
                <HiOutlinePencil />
              </AppIconButton>
            </Tooltip>
          )}
          <div className={classes.poster} />

          {company.logo?.url ? (
            <Avatar className={classes.avatar} src={company.logo?.url}>
              {!company.logo && <img height={40} alt="company" src={defaultCompanyPicture} />}
            </Avatar>
          ) : (
            <RandomLogo height={110} width={110} className={classes.avatar} name={company?.name || ''} />
          )}

          <Box className={classes.infoContainer}>
            <Typography color="primary" variant="h5" className={classes.name} align="center">
              {company.trading_name || company.name}
            </Typography>

            {company.sic && (
              <Box display="flex" alignItems="center" justifyContent="center">
                {!isMobile && (
                  <FiBriefcase
                    style={{ marginRight: theme.spacing(0.5) }}
                    size="16px"
                    color={theme.palette.primary.main}
                  />
                )}
                <Typography className={classes.bold} color="primary" align="center" variant="subtitle1">
                  {company.sic}
                </Typography>
              </Box>
            )}

            {company.address?.address_snippet && (
              <Box display="flex" marginTop={0.5} alignItems="center" justifyContent="center">
                {!isMobile && (
                  <IoMapOutline
                    style={{ marginRight: theme.spacing(0.5) }}
                    size="16px"
                    color={theme.palette.text.secondary}
                  />
                )}
                <Typography
                  className={classes.bold}
                  color="textSecondary"
                  align="center"
                  variant="subtitle1">
                  {company.address?.address_snippet}
                </Typography>
              </Box>
            )}

            {company.website && (
              <Box display="flex" marginTop={1} justifyContent="center" alignItems="center">
                <TfiWorld
                  style={{ marginRight: theme.spacing(0.5) }}
                  size="16px"
                  color={theme.palette.primary.main}
                />

                <AppA
                  style={{ fontWeight: 600, fontSize: 15, textDecoration: 'none' }}
                  href={company.website}
                  content={company.website.replace(/(^\w+:|^)\/\//, '')}
                />
              </Box>
            )}

            {editable && (
              <WriteTestimonial
                companyName={company?.name || ''}
                openTestimonialDialog={openTestimonialDialog}
                testimonialCount={testimonials.length}
              />
            )}

            <Box marginBottom={isMobile ? 3 : 5} className={classes.separator}></Box>

            {company.description && (
              <Box marginBottom={isMobile ? 3 : 5} width="100%">
                <Box marginBottom={isMobile ? 0 : '12px'}>
                  <SectionTitle title="About US" />
                </Box>

                <ShowMoreText
                  lines={isMobile ? 2 : 4}
                  more="Show more"
                  less="Show less"
                  className="show-more-text-content"
                  anchorClass={
                    theme.palette.type === 'dark' ? 'my-anchor-css-class-dark' : 'my-anchor-css-class'
                  }
                  expanded={false}
                  truncatedEndingComponent={'... '}>
                  {company.description}
                </ShowMoreText>
              </Box>
            )}

            <Box width="100%" marginBottom="12px">
              <SectionTitle title="Company Info" />
            </Box>

            <Box
              className={classes.tileItem}
              marginBottom={isMobile ? 3 : 5}
              flexDirection={isMobile ? 'column' : 'row'}
              display="flex"
              width="100%"
              padding={'8px 16px'}>
              <Box flex={1}>
                <PreviewInfoRaw label="Registered Name" value={company.name} />
                <PreviewInfoRaw
                  label="Registration Number"
                  value={company.reg_number}
                  verified
                  copyToClipboard
                />
                <PreviewInfoRaw label="Incorporated on" value={getUKFormattedDate(company.creation_date)} />
              </Box>

              {!isMobile && <Box className={classes.verticalSeparator}></Box>}

              <Box flex={1}>
                <PreviewInfoRaw label="Size of business by employees" value={company.workforce} />
                <PreviewInfoRaw label="Size of business by turnover (£)" value={company.turnover} />
                <PreviewInfoRaw label="Company type" value={company.cmp_type} />
              </Box>
            </Box>

            <Grid container spacing={isMobile ? 3 : 5}>
              {!!testimonials?.length && (
                <Grid item xs={12} md={6}>
                  <ExpandableViews
                    getDisplayedItem={getTestimonialItem}
                    title="Testimonials"
                    items={testimonials as any[]}
                  />
                </Grid>
              )}

              {/*TODO: Implement when backend will be ready*/}
              {/*{!!caseStudies?.length && (*/}
              {/*  <Grid item xs={12} md={6}>*/}
              {/*    <ExpandableViews*/}
              {/*      getDisplayedItem={getCaseStudyItem}*/}
              {/*      title="Case Studies"*/}
              {/*      items={caseStudies as any[]}*/}
              {/*    />*/}
              {/*  </Grid>*/}
              {/*)}*/}

              {(company.email || company.phone) && (
                <Grid item xs={12} md={6}>
                  <Typography className={classes.bold} color="textSecondary" variant="h6">
                    Contact Details
                  </Typography>

                  <Box marginTop={2} className={classes.tileItem} padding={'8px 16px'}>
                    <PreviewInfoRaw label="Email" value={company.email} copyToClipboard />
                    <PreviewInfoRaw label="Phone" value={company.phone} copyToClipboard />
                  </Box>
                </Grid>
              )}

              {isLinksBlockShow && company && <CompanySocialLinks company={company} />}

              {!!photos?.length && (
                <Grid item xs={12} md={6}>
                  <CompanyPreviewPhotos photos={photos} companyName={company.name} />
                </Grid>
              )}
            </Grid>
          </Box>
        </Paper>
      )}

      <AddTestimonialDialog
        createTestimonial={createTestimonial}
        open={isTestimonialDialogOpen}
        handleClose={closeTestimonialDialog}
      />
    </>
  );
};
