import React, { useContext } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';

interface Props {
  open: boolean;
  handleClose: Function;
  handleCancelClick: Function;
  handleUpdatePaymentsClick: Function;
}

export const ManageSubscriptionDialog: React.FC<Props> = ({
  open,
  handleClose,
  handleCancelClick,
  handleUpdatePaymentsClick,
}) => {
  const { isMobile } = useContext(DeviceContext);

  const handleDialogClose = () => handleClose();

  const handleCancel = () => {
    handleClose();
    handleCancelClick();
  };

  const handleUpdate = () => {
    handleClose();
    handleUpdatePaymentsClick();
  };

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      open={open}
      onClose={handleDialogClose}>
      <DialogTitle>Update your Subscription!</DialogTitle>

      <DialogContent>
        <DialogContentText>What would you like to do?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button size="large" onClick={handleDialogClose} variant="outlined">
          Close
        </Button>
        <Button size="large" onClick={handleCancel} variant="outlined" color="primary">
          {isMobile ? 'Cancel' : 'Cancel Subscription'}
        </Button>
        <Button size="large" color="primary" onClick={handleUpdate} variant="contained">
          {isMobile ? 'Update' : 'Update Payment Details'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
