import React, { useContext } from 'react';

import { Box } from '@material-ui/core';

import { useStyles } from '@vyce/core/src/views/payroll/styles';
import { getEmployeeTimesheetsRequest } from '@vyce/core/src/api/pay';
import { DeviceContext } from '@vyce/core/src/contexts';
import { EmployeeTimesheets } from '@vyce/core/src/views/payroll/components/EmployeeTimesheets';

import { useTypedSelector } from '../../../hooks';

export const EmployeeTimesheetsPage: React.FC = () => {
  const classes = useStyles();
  const { isMobile } = useContext(DeviceContext);
  const { access_token, selectedCompany } = useTypedSelector(state => state.helper);
  const { employee } = useTypedSelector(state => state.user);

  return (
    <Box className={classes.moduleContainer} height={isMobile ? 'calc(100vh - 115px)' : 'unset'}>
      <EmployeeTimesheets
        onlySubmitted
        token={access_token}
        employeeId={employee?.uuid}
        companyId={selectedCompany?.uuid}
        getEmployeeTimesheetsRequest={getEmployeeTimesheetsRequest}
      />
    </Box>
  );
};
