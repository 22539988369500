import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    selectedPosition: {
      border: `1px solid ${theme.palette.primary.main} !important`,
    },
    wrapper: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(3),
      borderRadius: '8px',
      height: '100%',
    },
    position: {
      border: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(2),
      borderRadius: '8px',
    },
    bold: {
      fontWeight: 600,
    }
  })
);
