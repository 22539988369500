import React, { useEffect, useState } from 'react';
import { ControlledTagAutocomplete } from './ControlledTagAutocomplete';
import { AutocompleteProps, FieldProps } from '../../types';
import { fetchTagsRequest } from '../../api/handbook';

export const jobRules = {
  required: 'Roles are required',
  validate: (value: string[]) => value?.length <= 3 || 'You can select only 3 roles',
};

const GROUP = 'role';

export const JobsField: React.FC<FieldProps> = ({
  margin,
  label = 'Roles',
  name = 'jobs',
  multiple = true,
  disabled = false,
  rules = jobRules,
}) => {
  const [selectProps, setSelectProps] = useState<AutocompleteProps>({
    name,
    items: [],
    rules,
    label,
    margin,
    multiple,
    disabled,
    group: GROUP,
    creatable: true,
  });

  const fetchTags = async () => {
    try {
      const res = await fetchTagsRequest({ group: GROUP, short: false });
      setSelectProps(value => ({ ...value, disabled: false, items: res.data.items }));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    let mounted = true;
    setSelectProps(value => ({ ...value, disabled: true }));
    if (!disabled) {
      fetchTags();
    }
    return () => {
      mounted = false;
    };
  }, []);

  return <ControlledTagAutocomplete {...selectProps} />;
};
