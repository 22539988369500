import { UserAction } from '../actions/user';
import { ActionType } from '../action-types';
import { User } from '@vyce/core/src/types';

const initialState: User = {
  uuid: '',
  first_name: '',
  last_name: '',
  email: '',
};

const reducer = (state: User = initialState, action: UserAction): User => {
  switch (action.type) {
    case ActionType.SET_USER_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case ActionType.SET_INITIAL_USER_STATE:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
