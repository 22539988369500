import { useContext } from 'react';

import { Box, Typography } from '@material-ui/core';
import { useDrop } from 'react-dnd';
import { RxPlus } from 'react-icons/rx';

import { Hint } from '@vyce/core/src/components';

import { ItemTypes } from '../config';
import { DragItem } from '../types';
import { useStyles } from '../styles';
import { Field } from './Field';
import { CustomDetailsFieldsContext } from './CustomDetailsFieldsContext';

export const BlockOfFields = ({
  defaultFields,
  customFields,
}: {
  defaultFields: DragItem[];
  customFields: DragItem[];
}) => {
  const [, drop] = useDrop(() => ({ accept: ItemTypes.FIELD }));
  const { createNewField } = useContext(CustomDetailsFieldsContext);

  const classes = useStyles();
  return (
    <div ref={drop}>
      <Box display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2">Your Location Details:</Typography>

          <Hint>
            <>
              <Typography variant="caption">
                The default fields cannot be edited or removed as they are necessary for the module’s
                features’ smooth performance. However, you may change the order in which they appear in
                Location Details. If you want to exclude any field from your exported Time Logs file, you may
                do so in{' '}
                <Typography variant="subtitle2" display="inline">
                  Exporting Time Logs
                </Typography>{' '}
                section.
              </Typography>
            </>
          </Hint>
        </Box>
        <Box display="flex" flexDirection="column" gridGap={8}>
          {customFields.map(item => (
            <Field key={item.id} {...item} />
          ))}

          <Box
            display="flex"
            alignItems="center"
            gridGap={8}
            className={classes.addFieldButton}
            onClick={createNewField}>
            <RxPlus />
            <Typography variant="caption">Add a new field</Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
