import React, { ReactElement, useCallback, useMemo } from 'react';

import { Box, createStyles, makeStyles, Paper } from '@material-ui/core';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import { Theme } from '@material-ui/core/styles';

import { GridCustomPagination } from './CustomPagination';
import { NoRowsOverlayComponent } from './NoRowsOverlayComponent';
import { usePageSaverForTables } from '../hooks';

export const HEADER_HEIGHT = 56;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: 600,
    },
    wrapper: {
      padding: theme.spacing(3),
      display: 'flex',
      borderRadius: '8px 8px 0 0',
      flexDirection: 'column',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2),
      },
      boxShadow: '2px 4px 12px rgba(20, 20, 43, 0.04)',
    },
    noPaper: {
      width: '100%',
      padding: 0,
      border: 'none',
      borderRadius: '8px 8px 0 0',
      boxShadow: '2px 4px 12px rgba(20, 20, 43, 0.04)',
    },
    root: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '8px 8px 0 0',
      '& .MuiIconButton-root': {
        color: theme.palette.text.secondary,
      },
      '& .MuiCheckbox-colorPrimary.Mui-checked': {
        color: theme.palette.primary.main,
      },
      '& .MuiDataGrid-selectedRowCount': {
        display: 'none',
      },
    },
    row: {
      border: `1px solid ${theme.palette.divider}`,
      borderLeft: 'none',
    },
    columnHeader: {
      color: theme.palette.text.secondary,
      fontSize: '13px',
      backgroundColor: 'transparent !important',
      '&:first-child': {
        paddingLeft: '16px',
      },
    },
    cell: {
      '&:first-child': {
        paddingLeft: '16px',
      },
      fontWeight: 500,
    },
  })
);

interface Props extends DataGridProps {
  height?: string;
  children?: ReactElement | null | undefined;
  noPaper?: boolean;
  unit?: string;
  withProfileButton?: boolean;
}

export const AppDataGrid = (props: Props) => {
  const classes = useStyles();

  const { withProfileButton = false, unit = '', rowHeight = 64, ...restProps } = props;

  const getNoRowComponent = useCallback(
    () => <NoRowsOverlayComponent unit={unit} withProfileButton={withProfileButton} />,
    [unit, withProfileButton]
  );

  const components = useMemo(
    () => ({
      NoRowsOverlay: getNoRowComponent,
      NoResultsOverlay: getNoRowComponent,
      Pagination: GridCustomPagination,
    }),
    [getNoRowComponent]
  );

  const height = props.height || 'calc(100vh - 280px)';

  return (
    <Paper
      variant="outlined"
      style={{ height }}
      className={props.noPaper ? classes.noPaper : classes.wrapper}>
      {props.children}

      <Box height="100%" width="100%">
        <DataGrid
          {...restProps}
          classes={{
            root: classes.root,
            row: classes.row,
            columnHeader: classes.columnHeader,
            cell: classes.cell,
          }}
          rowHeight={rowHeight}
          components={components}
        />
      </Box>
    </Paper>
  );
};

export const AppDataGridWithSavedPage = ({
  setOffset,
  onPageChange,
  ...restProps
}: Props & { setOffset: (offset: number) => void }) => {
  const { page, setNewPage } = usePageSaverForTables({ pageSize: restProps.pageSize, setOffset });

  const handlePageChange = (newPage: number) => {
    setNewPage(newPage);
    onPageChange && onPageChange(newPage);
  };

  return <AppDataGrid {...restProps} page={page} onPageChange={handlePageChange} />;
};
