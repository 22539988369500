import { useParams } from 'react-router-dom';

import { getTeamsSyncSettingsRequest, updateTeamsSyncSettingsRequest } from '@vyce/core/src/api/teams';
import { getUrlItems } from '@vyce/core/src/utils/url';

import { useTypedSelector } from '../../../hooks';

export const useTeamSettingsData = () => {
  const { team } = useParams<{ team: string }>();
  const { selectedCompany } = useTypedSelector(state => state.helper);

  const teamUrlItems = getUrlItems(team);
  const teamId = teamUrlItems?.id;
  const companyId = selectedCompany?.uuid ?? '';

  return {
    teamId,
    selectedCompanyId: companyId,
    updateTeamsSyncSettingsRequest,
    getTeamsSyncSettingsRequest,
  };
};
