//@ts-nocheck
import React from 'react';
import { AppSelect } from '../inputs';
import { Controller, useFormContext } from 'react-hook-form';
import { FieldProps } from '../../types';
import { FormControl, FormHelperText, InputLabel, MenuItem } from '@material-ui/core';
import { COUNTRIES } from '../../constants';

export const BillingCountryField: React.FC<FieldProps> = ({ margin }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name="address.country"
      rules={{
        required: 'Country is required',
      }}
      render={({ field }) => (
        <FormControl margin={margin} fullWidth variant="filled">
          <InputLabel>Country</InputLabel>
          <AppSelect onChange={e => field.onChange(e.target.value)} value={field.value} fullWidth>
            {COUNTRIES.map(item => (
              <MenuItem key={item.name} value={item.code}>
                {item.name}
              </MenuItem>
            ))}
          </AppSelect>
          <FormHelperText error>{errors?.address?.country?.message || ''}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
