import React from 'react';

import { AppOnboarding, ConfirmDialog } from '@vyce/core/src/components';

import { TimeDialog } from './components';
import { useManualClockData } from './hooks';
import { ManualClockProvider } from './components/ManualClockProvider';
import { Props } from './types';
import { clockInText, clockOutText } from './config';

export const CoreManualClockModule = (props: Props) => {
  const {
    disableNext,
    activeStep,
    isDialogOpen,
    action,
    timestamp,
    methods,
    filteredSteps,
    childRef,
    contextOption,
    clockInTimestamp,
    isSuccessDialogOpen,
    clockedWorkerName,
    previousAction,
    setSuccessDialogClose,
    handleNext,
    setActiveStep,
    setDialogClose,
    onChangeDate,
  } = useManualClockData(props);

  return (
    <ManualClockProvider value={contextOption}>
      <AppOnboarding
        disableNext={disableNext}
        activeStep={activeStep}
        currentMessage=""
        setActiveStep={setActiveStep}
        methods={methods}
        handleNext={handleNext}
        steps={filteredSteps}
        loading={false}
        ref={childRef}
      />
      <TimeDialog
        isOpen={isDialogOpen}
        action={action}
        value={timestamp === 'now' ? null : timestamp}
        onClose={setDialogClose}
        onChange={onChangeDate}
        clockInTimestamp={clockInTimestamp}
      />

      <ConfirmDialog
        handleClose={setSuccessDialogClose}
        open={isSuccessDialogOpen}
        confirmText="OK"
        title="Done!"
        showCancelText={false}
        subtitle={`${clockedWorkerName} ${previousAction === 'in' ? clockInText : clockOutText}`}
        handleConfirm={setSuccessDialogClose}
      />
    </ManualClockProvider>
  );
};
