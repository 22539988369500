import React from 'react';
import { Box, Typography, useTheme } from "@material-ui/core";
import { IsVerified } from "./IsVerified";
import { getUKFormattedDate } from "../utils/dates";

interface Props {
  verified: boolean;
  idExpiring?: {
    expired: boolean;
    expiring: boolean;
    expiring_date: string;
    status: string;
  }
}

export const RTWStatus: React.FC<Props> = ({ verified, idExpiring }) => {
  const theme = useTheme();
  const formattedStatus = idExpiring?.expiring && idExpiring?.expiring_date ? `Exp. ${getUKFormattedDate(idExpiring.expiring_date)}` : idExpiring?.status;

  return <Box display="flex" alignItems="center">
    <IsVerified verified={verified} checkIconColor={idExpiring?.expiring ? theme.palette.warning.main : undefined}/>
    {verified && <Box marginLeft={1}>
      <Typography variant="caption" color="textSecondary">{formattedStatus}</Typography>
    </Box>}
  </Box>
}
