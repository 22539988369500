import React, { useContext, useState } from 'react';

import { useForm } from 'react-hook-form';
import { AxiosResponse } from 'axios';
import { Box, Grid, Paper, Typography } from '@material-ui/core';

import { AppFormWrapper } from '../../../components';
import { BankAccount } from '../../../types';
import { CreateOrUpdateCompanyBankAccountRequest } from '../../../api/types';
import useStyles from '../styles';
import {
  BankAccountName,
  BankAccountNumberField,
  BuildingSocietyRollNumberField,
  SortCodeField,
} from '../../../components/controlled-inputs';
import { DeviceContext } from '../../../contexts';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  bankDetails?: BankAccount;
  setBankDetails: (bankDetails: BankAccount) => void;
  createOrUpdateCompanyBankAccountRequest: (
    params: CreateOrUpdateCompanyBankAccountRequest
  ) => Promise<AxiosResponse>;
  companyId?: string;
}

export const CompanyBankDetails: React.FC<Props> = ({
  bankDetails,
  setBankDetails,
  companyId,
  createOrUpdateCompanyBankAccountRequest,
}) => {
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const classes = useStyles();
  const methods = useForm<BankAccount>({
    defaultValues: {
      account_name: bankDetails?.account_name || '',
      account_number: bankDetails?.account_number || '',
      sort_code: bankDetails?.sort_code || '',
      building_society_roll_number: bankDetails?.building_society_roll_number || '',
    },
  });
  const [loading, setLoading] = useState<boolean>(false);
  const { isMobile } = useContext(DeviceContext);

  const handleSubmit = async (data: BankAccount) => {
    if (!companyId) {
      return;
    }
    try {
      setLoading(true);
      const res = await createOrUpdateCompanyBankAccountRequest({
        companyId,
        bankAccount: data,
      });
      setBankDetails(res.data);
      setLoading(false);
      showNotification({ message: 'Pay Schedule has been updated', options: { variant: 'success' } });
    } catch (e) {
      handleServerError(e);
      setLoading(false);
    }
  };

  return (
    <AppFormWrapper
      methods={methods}
      initialData={bankDetails}
      handleSubmit={handleSubmit}
      loading={loading}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={8}>
          <Paper variant="outlined" className={classes.formPaper}>
            <Typography variant="h6">Bank Details</Typography>

            <Box display="flex" gridGap={24} marginTop={2} flexDirection={isMobile ? 'column' : 'row'}>
              <BankAccountName name="account_name" />

              <BankAccountNumberField name="account_number" />
            </Box>

            <Box display="flex" gridGap={24} marginTop={2} flexDirection={isMobile ? 'column' : 'row'}>
              <SortCodeField name="sort_code" />

              <BuildingSocietyRollNumberField name="building_society_roll_number" />
            </Box>
          </Paper>

          <Box marginTop={1}>
            <Typography variant="caption" color="textSecondary">
              This information will not be visible on the company profile.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </AppFormWrapper>
  );
};
