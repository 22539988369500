import React, { useState, useCallback } from 'react';

import { Box } from '@material-ui/core';

import { CustomPagination, CustomPaginationProps, PageLoading } from '@vyce/core/src/components';

import { AccordionItem } from './components/AccordionItem';
import { HeaderItem } from './components';
import { GridRowData, RowSchemaProps, HeaderItemProps } from './types';
export * from './types';

interface Props<T> {
  rows: GridRowData<T>[];
  rowsSchema: RowSchemaProps<T>[][];
  shortSchema: RowSchemaProps<T>[];
  loading?: boolean;
  paginationOptions?: CustomPaginationProps;
  columnNames: HeaderItemProps[];
  withItemBorder?: boolean;
}

export const AppMobileDataGrid = <T extends { id: string }>({
  rows,
  rowsSchema,
  shortSchema,
  loading,
  paginationOptions,
  columnNames,
  withItemBorder,
}: Props<T>) => {
  const [openedAccordionItemIds, setOpenedAccordionItemIds] = useState<string[]>([]);

  const handleClickOnAccordionItem = useCallback(
    (id: string) => {
      if (openedAccordionItemIds.includes(id)) {
        setOpenedAccordionItemIds(openedAccordionItemIds.filter(item => item !== id));
        return;
      }
      setOpenedAccordionItemIds([...openedAccordionItemIds, id]);
    },
    [openedAccordionItemIds]
  );

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%" gridGap={24}>
      {loading ? (
        <PageLoading />
      ) : (
        <Box display="flex" flexDirection="column" flexGrow={1} overflow="hidden">
          <Box display="flex" padding="8px 16px" position="relative">
            {columnNames.map((item, index) => (
              <HeaderItem key={index} {...item} />
            ))}
          </Box>
          <Box display="flex" flexDirection="column" gridGap={8} overflow="auto">
            {rows.map(row => (
              <AccordionItem
                key={row.id}
                row={row}
                schema={rowsSchema}
                shortSchema={shortSchema}
                opened={openedAccordionItemIds.includes(row.id)}
                onClick={handleClickOnAccordionItem}
                withItemBorder={withItemBorder}
              />
            ))}
          </Box>
        </Box>
      )}
      {paginationOptions && <CustomPagination {...paginationOptions} />}
    </Box>
  );
};
