import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Route, Switch } from 'react-router-dom';
import { Box } from '@material-ui/core';

import { DeviceContext } from '@vyce/core/src/contexts';
import { BackToData, INTERFACE_PERMISSIONS, Site, TimeAndAttendanceLocation } from '@vyce/core/src/types';
import { CommonNavBarBlock, PageLoading } from '@vyce/core/src/components';
import { getSitesRequest } from '@vyce/core/src/api/time';
import { getPaddingForContent } from '@vyce/core/src/utils';
import { TimeModuleProvider } from '@vyce/core/src/contexts';
import { GetStarted } from '@vyce/core/src/views/time/components/GetStarted';
import { TIME_AND_ATTENDANCE_TABS } from '@vyce/core/src/modules/timeModule/constants';
import { siteToLocation } from '@vyce/core/src/modules/timeModule/utils';

import { ManualClockModule, TimeDashboardModule } from '../../modules';
import { useTypedSelector } from '../../hooks';
import { EmployerLocationPage } from './EmployerLocationPage';
import { LocationsUsers } from './EmployerLocationWorkers';
import { EmployerLocationOnboarding } from './EmployerLocationOnboarding';
import { EmployerLocationsAndShifts } from './EmployerLocationsAndShifts';
import { EmployerDailyActivity } from './EmployerDailyActivity';
import { EmployerWorkerDetails } from './EmployerWorkerDetails';
import { EmployerTimeLogsByPeriod } from './EmployerTimeLogsByPeriod';
import { EmployerTimeLogsByLocation } from './EmployerTimeLogsByLocation';
import { EmployerTimeLogsByWorker } from './EmployerTimeLogsByWorker';
import { EmployerTimeLogsByShift } from './EmployerTimeLogsByShift';
import { EmployerTimeSettings } from './EmployerTimeSettings';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

export const EmployerTimeAndAttendance: React.FC = () => {
  const { selectedCompany, selectedCompanyAppData, selectedPosition } = useTypedSelector(
    state => state.helper
  );
  const { handleServerError } = useContext(NotificationContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { isMobile } = useContext(DeviceContext);
  const [locations, setLocations] = useState<TimeAndAttendanceLocation[]>([]);
  const [backTo, setBackTo] = useState<BackToData | null>(null);
  const padding = getPaddingForContent(isMobile);
  const hadSubscription =
    (!!selectedCompanyAppData?.subscription?.uuid && !selectedCompanyAppData.subscription.active) || false;
  const hasBillingPermissions = selectedPosition?.permissions.includes(INTERFACE_PERMISSIONS.BILLING);
  const selectedCompanyIsSubscribed = selectedCompanyAppData?.subscription?.active ?? false;
  const selectedCompanyId = selectedCompany?.uuid ?? '';

  const tabs = useMemo(
    () =>
      TIME_AND_ATTENDANCE_TABS.filter(tab =>
        tab.permission ? selectedPosition?.permissions.includes(tab.permission) : true
      ),
    [selectedPosition]
  );

  const getLocations = useCallback(
    async (showLoader: boolean = true) => {
      if (!selectedCompanyId || !selectedCompanyIsSubscribed) {
        return;
      }

      if (showLoader) {
        setLoading(true);
      }

      try {
        const res = await getSitesRequest(selectedCompanyId, { limit: 500, order_by: [] });
        const locations = res.data.items?.map((item: Site) => siteToLocation(item));
        setLocations(locations);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        handleServerError(e);
      }
    },
    [selectedCompanyId, selectedCompanyIsSubscribed]
  );

  const contextOptions = useMemo(
    () => ({
      backTo,
      locations,
      setBackTo,
      getLocations,
    }),
    [backTo, getLocations, locations]
  );

  useEffect(() => {
    if (selectedCompany?.uuid && selectedCompanyIsSubscribed) {
      getLocations();
    }
  }, [selectedCompany?.uuid, selectedCompanyIsSubscribed]);

  if (loading || !selectedCompany || selectedCompanyAppData?.subscription === undefined) {
    return <PageLoading />;
  }
  if (!selectedCompanyIsSubscribed && hasBillingPermissions) {
    return (
      <TimeModuleProvider value={contextOptions}>
        <GetStarted hadSubscription={hadSubscription} hasSubscription={selectedCompanyIsSubscribed} />
      </TimeModuleProvider>
    );
  }

  return (
    <TimeModuleProvider value={contextOptions}>
      <Switch>
        <Route path={'/time/create-location'} component={EmployerLocationOnboarding} />
        {!locations?.length ? (
          <GetStarted hadSubscription={hadSubscription} hasSubscription={selectedCompanyIsSubscribed} />
        ) : (
          <Box>
            <CommonNavBarBlock tabItems={tabs} backTo={backTo} />

            <Box padding={padding} height="100%">
              <Route exact path={'/time/dashboard'} component={TimeDashboardModule} />
              <Route exact path={'/time/locations-and-shifts/:id'} component={EmployerLocationPage} />
              <Route exact path={'/time/locations-and-shifts'} component={EmployerLocationsAndShifts} />
              <Route exact path={'/time/timelogs'} component={EmployerTimeLogsByPeriod} />
              <Route exact path={'/time/timelogs/:period'} component={EmployerTimeLogsByLocation} />
              <Route exact path={'/time/timelogs/:period/:siteId'} component={EmployerTimeLogsByWorker} />
              <Route
                exact
                path={'/time/timelogs/:period/:siteId/:userId'}
                component={EmployerTimeLogsByShift}
              />
              <Route
                exact
                path={'/time/timelogs/:period/:siteId/:userId/:date'}
                component={EmployerDailyActivity}
              />
              <Route exact path={'/time/users/:id/:tag'} component={EmployerWorkerDetails} />
              <Route path={'/time/users/:userId/logs/:date'} component={EmployerDailyActivity} />
              <Route exact path={'/time/users'} component={LocationsUsers} />
              <Route path={'/time/add-new-location'} component={EmployerLocationOnboarding} />
              <Route path={'/time/manual'} component={ManualClockModule} />
              <Route path={'/time/settings'} component={EmployerTimeSettings} />
            </Box>
          </Box>
        )}
      </Switch>
    </TimeModuleProvider>
  );
};
