import React from 'react';

import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from '../../styles';
import { BoxWrapper } from './BoxWrapper';

interface SkillsProps {
  skills: string[];
  isHiring?: boolean;
}

export const Skills = ({ skills, isHiring = false }: SkillsProps) => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Typography
        color="textSecondary"
        variant="h6"
        className={clsx(classes.bold, { [classes.smallBold]: isHiring, [classes.smallText]: isHiring })}>
        Skills
      </Typography>
      <BoxWrapper row isSimpleContainer={isHiring}>
        {skills?.map(skill => (
          <Box
            className={clsx(classes.skill, {
              [classes.hiringSkill]: isHiring,
              [classes.smallText]: isHiring,
              [classes.smallBold]: isHiring,
            })}
            key={skill}>
            {skill}
          </Box>
        ))}
      </BoxWrapper>
    </Box>
  );
};
