import React, { useCallback, useState } from 'react';

import { Box, Button } from '@material-ui/core';
import { pdfjs } from 'react-pdf';
import { useDropzone, Accept } from 'react-dropzone';
import { BiPencil } from 'react-icons/bi';
import { AiOutlineEye } from 'react-icons/ai';
import clsx from 'clsx';

import { useBooleanState } from '../../hooks';
import { Image } from '../../types';
import { PreviewDocumentDialog } from '../PreviewDocumentDialog';
import { compressFile } from './utils';
import { controlTypes, imageType, pdfType } from './config';
import { EmptyContent, ControlComponent, SmallPreviewContent } from './components';
import { useStyles } from './styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const imageTypes = ['.jpeg', '.jpg', '.png', '.gif'];
const pdfTypes = ['.pdf'];
const accepts = {
  img: { 'image/*': imageTypes },
  pdf: { 'application/pdf': pdfTypes },
};

interface Props {
  width?: number | string;
  height?: number;
  isDocument?: boolean;
  image?: Image | null;
  extraText: string;
  uploadText?: string;
  type?: 'pdf' | 'img' | 'both';
  buttonPosition?: { top?: string | number; right?: string | number };
  outsideControl?: boolean;
  uploaderWithoutPreview?: boolean;
  extraSmallSize?: boolean;
  customControlSet?: Array<keyof typeof controlTypes>;
  withEditModeButton?: boolean;
  onImageUpload?: (image: Image) => void;
}

export type CustomControlSet = Array<keyof typeof controlTypes>;

export const ImageUploader = ({
  image,
  isDocument,
  extraText,
  uploadText,
  type = 'img',
  height,
  width,
  buttonPosition,
  outsideControl = false,
  uploaderWithoutPreview = false,
  extraSmallSize = false,
  customControlSet,
  withEditModeButton = false,
  onImageUpload,
}: Props) => {
  const classes = useStyles();
  const [file, setFile] = useState<Image | null>(null);
  const [isImageModalOpen, setImageModalOpen, setImageModalClose] = useBooleanState(false);
  const [isEditMode, _setIsEditModeTrue, _setIsEditModeFalse, setIsEditModeToggle] = useBooleanState(false);
  const accept: Accept = type === 'both' ? { ...accepts.img, ...accepts.pdf } : accepts[type];
  const url = outsideControl ? image?.url || '' : file?.url || image?.url || '';
  const fileName = outsideControl ? image?.name || '' : file?.name || image?.name || '';
  const isEmpty = !url;
  const isImage = imageTypes.some(item => fileName.includes(item));
  const chosenType = type === imageType || type === pdfType ? type : isImage ? imageType : pdfType;
  const { top: buttonTop = 0, right: buttonRight = 0 } = buttonPosition ?? {};

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      await acceptedFiles.map(async fileItem => {
        try {
          const reader = new FileReader();
          const file = await compressFile(fileItem);
          reader.onloadend = async () => {
            if (!onImageUpload) return;
            const url = reader.result as string;
            const newImage = { file, url: url, name: file.name };
            onImageUpload(newImage);
            setFile(newImage);
          };

          if (file) {
            reader?.readAsDataURL(file);
          }
        } catch (e) {
          console.error(e);
        }
      });
    },

    [onImageUpload]
  );

  const handleOpenPreviewDialog = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setImageModalOpen();
    },
    [setImageModalOpen]
  );

  const handleDownloadPdf = useCallback(() => {
    const link = document.createElement('a');
    link.download = `agreement.pdf`;
    link.href = url;
    link.click();
  }, [url]);

  const { open, getRootProps, getInputProps } = useDropzone({
    onDrop,
    noDragEventsBubbling: true,
    accept,
    multiple: false,
    noClick: !isEmpty && !uploaderWithoutPreview,
  });

  return (
    <>
      <Box {...getRootProps({ className: classes.wrapper })}>
        <input {...getInputProps()} />

        <Box
          className={clsx(classes.uploaderContainer, {
            [classes.uploaderContainerBordered]: !isEmpty,
            [classes.uploaderContainerPaddings]: isEmpty,
          })}>
          {isEmpty || uploaderWithoutPreview ? (
            <EmptyContent extraText={extraText} uploadText={uploadText} extraSmallSize={extraSmallSize} />
          ) : (
            <SmallPreviewContent
              handleOpenPreviewDialog={handleOpenPreviewDialog}
              chosenType={chosenType}
              url={url}
              isDocument={isDocument}
              height={height}
              width={width}
            />
          )}

          {!isEmpty && !uploaderWithoutPreview && (
            <ControlComponent
              fileName={fileName}
              type={chosenType}
              editMode={isEditMode}
              customControlSet={customControlSet}
              openUploadWindow={open}
              openPreviewDialog={handleOpenPreviewDialog}
              downloadPdf={handleDownloadPdf}
            />
          )}
        </Box>
      </Box>

      {isImageModalOpen && (
        <PreviewDocumentDialog
          url={url}
          type={chosenType}
          isOpen={isImageModalOpen}
          bigPreview
          onClose={setImageModalClose}
        />
      )}
      {!isEmpty && !uploaderWithoutPreview && withEditModeButton && (
        <Button
          startIcon={
            isEditMode ? (
              <AiOutlineEye size="18px" color="#610BEF" />
            ) : (
              <BiPencil size="18px" color="#610BEF" />
            )
          }
          className={classes.editButtonWrapper}
          style={{ top: buttonTop, right: buttonRight }}
          onClick={setIsEditModeToggle}
        />
      )}
    </>
  );
};

ImageUploader.SmallPreviewContent = SmallPreviewContent;
