import React, { useContext, useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import { Shift } from '@vyce/core/src/types';
import { DeviceContext } from '@vyce/core/src/contexts';
import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';

import { DefaultRateField, DefaultRateUnitSelect } from '../../../../components/controlled-inputs';
import { ShiftRate } from './AddSquadMemberButton';

interface Props {
  open: boolean;
  hasDifferentRates: boolean;
  handleClose: Function;
  selectedShifts: Shift[];
  handleCustomRatesSave: (rates: ShiftRate[]) => void;
}

interface Form {
  different_rates: ShiftRate[];
}

const defaultValues: Form = {
  different_rates: [],
};
const DIFFERENT_RATES_FIELD_NAME = 'different_rates';

export const DifferentShiftRatesDialog: React.FC<Props> = ({
  handleClose,
  open,
  hasDifferentRates,
  selectedShifts,
  handleCustomRatesSave,
}) => {
  const { isMobile } = useContext(DeviceContext);
  const methods = useForm<Form>({
    defaultValues,
  });
  const { setValue } = methods;

  useEffect(() => {
    if (selectedShifts.length && !hasDifferentRates && open) {
      const rates: ShiftRate[] = selectedShifts.map(shift => ({
        basic_amount: null,
        basic_unit: null,
        overtime_amount: null,
        overtime_unit: null,
        shiftId: shift.uuid as string,
      }));
      setValue(DIFFERENT_RATES_FIELD_NAME, rates);
    }
  }, [selectedShifts, hasDifferentRates, open]);

  const handleSubmit = (data: Form) => {
    handleCustomRatesSave(data.different_rates);
  };

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      maxWidth="lg"
      onClose={() => handleClose()}
      open={open}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <DialogTitle>Set different rates for each Shift</DialogTitle>

          <DialogContent style={{ minWidth: isMobile ? '100%' : 750 }}>
            {selectedShifts.map((shift, index) => (
              <Box
                key={shift.uuid}
                display="flex"
                marginBottom={2}
                flexDirection={isMobile ? 'column' : 'row'}>
                <Box flex={1} marginTop={3}>
                  <Typography style={{ fontSize: 15 }} variant="subtitle2">
                    {shift.name}
                  </Typography>
                </Box>

                <Box flex={2}>
                  <Box marginTop={-1} display="flex" gridGap={16}>
                    <DefaultRateField
                      margin="normal"
                      name={`${DIFFERENT_RATES_FIELD_NAME}[${index}].basic_amount`}
                      label="Default regular rate (£)"
                    />

                    <DefaultRateUnitSelect
                      margin="normal"
                      name={`${DIFFERENT_RATES_FIELD_NAME}[${index}].basic_unit`}
                    />
                  </Box>

                  <Box display="flex" gridGap={16}>
                    <DefaultRateField
                      name={`${DIFFERENT_RATES_FIELD_NAME}[${index}].overtime_amount`}
                      margin="normal"
                      label="Default overtime rate (£)"
                    />

                    <DefaultRateUnitSelect
                      margin="normal"
                      name={`${DIFFERENT_RATES_FIELD_NAME}[${index}].overtime_unit`}
                    />
                  </Box>
                </Box>
              </Box>
            ))}
          </DialogContent>

          <DialogActions>
            <Button size="large" onClick={() => handleClose()} variant="outlined">
              Cancel
            </Button>

            <Button size="large" type="submit" color="primary" variant="contained">
              Save
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
