import React from 'react';
import { Company } from '../types';

export const UserManagementContext = React.createContext<{
  selectedCompanyId: string | null;
  companies?: Company[];
}>({
  selectedCompanyId: null,
  companies: [],
});
export const UserManagementProvider = UserManagementContext.Provider;
