import { axios } from '@vyce/core/src/api';

import { ChangePasswordData, LoginData, ResetPassword } from '../types';

export const checkApiRequest = () => {
  return axios.get('/');
};

export const loginRequest = (data: LoginData) => {
  const bodyFormData = new FormData();
  bodyFormData.append('username', data.username);
  bodyFormData.append('password', data.password);
  bodyFormData.append('client_secret', data.client_secret);
  return axios.post(`/auth/access-token`, bodyFormData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const verifyEmailRequest = (token: string) => {
  return axios.get(`/auth/verify_email/${token}`);
};

export const sendEmailVerificationRequest = ({
  userId,
  ...rest
}: {
  userId: string;
  recaptcha_token: string;
}) => {
  return axios.post(`/auth/users/${userId}/emails/verification`, rest);
};

export const sendVerificationSMSRequest = (data: { recaptcha_token: string }) => {
  return axios.post(`/auth/phone/send_verification_sms`, data);
};

export const redeemVerificationCodeRequest = (code: number) => {
  return axios.post(`/auth/phone/redeem_verification_code`, { code });
};

export const sendEmailResetPasswordRequest = ({
  email,
  recaptcha_token,
}: {
  email: string;
  recaptcha_token: string;
}) => {
  return axios.post(`/auth/send_email_reset_password?email=${encodeURIComponent(email)}`, {
    recaptcha_token,
  });
};

export const resetPasswordRequest = (data: ResetPassword) => {
  return axios.post(`/auth/reset_password`, data);
};

export const changePasswordRequest = (token: string, data: ChangePasswordData) => {
  return axios.post(`/auth/change_password`, data);
};

export const changePasswordByLegendRequest = (token: string, data: ChangePasswordData, userId?: string) => {
  return axios.post(`/auth/users/${userId}/change_password`, data);
};

export const changeEmailRequest = (data: { recaptcha_token: string; email: string }) => {
  return axios.post(`/auth/change_email`, data);
};

export const changePhoneRequest = (data: { recaptcha_token: string; phone: string }) => {
  return axios.post(`/auth/phone/change_phone`, data);
};
