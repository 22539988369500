import React from 'react';

import { Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { FormProvider } from 'react-hook-form';

import {
  EmailField,
  emailPattern,
  PhoneNumberField,
  validatePhone,
} from '../../components/controlled-inputs';
import { ButtonTitleWithLoading } from '../../components';
import { ModulesStep } from '../../components/onboarding';
import { ContactDivider, ContactList, SuccessDialog } from './components';
import { useUniversalInviteData } from './hooks';
import useStyles from './styles';
import { MAIN_CONTAINER_ID } from '../../constants';

interface Props {
  children?: React.ReactElement;
}

export const UniversalInviteDialog = ({ children }: Props) => {
  const classes = useStyles();
  const {
    loading,
    isLegend,
    isMobile,
    emails,
    phones,
    open,
    disableInviteButton,
    expandedModule,
    methods,
    isSuccessDialogOpen,
    teamModuleOptions,
    timeModuleOptions,
    payModuleOptions,
    collapseAllModules,
    closeSuccessDialog,
    deleteContact,
    addPhoneToList,
    addEmailToList,
    handlePhoneFieldKeyDown,
    handleEmailFieldKeyDown,
    handleClose,
    invite,
    setExpandedModule,
  } = useUniversalInviteData();

  return (
    <>
      <Dialog
        container={document.getElementById(MAIN_CONTAINER_ID)}
        classes={{ paper: classes.dialogPaper }}
        fullScreen={isMobile}
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            handleClose();
          }
        }}>
        <DialogTitle>Invite new members to your Vyce account!</DialogTitle>

        <FormProvider {...methods}>
          <form>
            <DialogContent style={{ width: isMobile ? '100%' : 600 }}>
              <Box marginTop={2}>{children}</Box>

              <Box marginBottom={2} marginTop={2} alignItems="center">
                <Box display="flex" gridGap={16} marginBottom={1}>
                  <EmailField
                    name="email"
                    rules={{ pattern: emailPattern }}
                    onKeyDown={handleEmailFieldKeyDown}
                  />

                  <Button onClick={addEmailToList} className={classes.addButton} variant="outlined">
                    Add
                  </Button>
                </Box>

                {!!emails?.length && (
                  <ContactList
                    contacts={emails}
                    limit={2}
                    deleteContact={deleteContact}
                    moreClickCallback={collapseAllModules}
                  />
                )}

                <ContactDivider />

                <Box display="flex" gridGap={16} marginBottom={2} marginTop={1}>
                  <PhoneNumberField
                    rules={{
                      validate: validatePhone,
                    }}
                    name="phone"
                    onKeyDown={handlePhoneFieldKeyDown}
                  />

                  <Button onClick={addPhoneToList} className={classes.addButton} variant="outlined">
                    Add
                  </Button>
                </Box>

                {!!phones?.length && (
                  <ContactList
                    contacts={phones}
                    limit={3}
                    deleteContact={deleteContact}
                    moreClickCallback={collapseAllModules}
                  />
                )}
              </Box>

              <Collapse in={!!emails.length || !!phones.length}>
                <ModulesStep
                  isLegend={isLegend}
                  expandedModule={expandedModule}
                  payModuleOptions={payModuleOptions}
                  teamModuleOptions={teamModuleOptions}
                  timeModuleOptions={timeModuleOptions}
                  collapseAllModules={collapseAllModules}
                  setExpandedModule={setExpandedModule}
                />
              </Collapse>
            </DialogContent>
          </form>
        </FormProvider>

        <DialogActions>
          <Button size="large" variant="outlined" onClick={handleClose}>
            Cancel
          </Button>

          <Button
            size="large"
            disabled={disableInviteButton}
            style={{ width: 95 }}
            color="primary"
            onClick={invite}
            variant="contained">
            <ButtonTitleWithLoading title="Invite" loaderVariant="paper" loading={loading} />
          </Button>
        </DialogActions>
      </Dialog>

      <SuccessDialog open={isSuccessDialogOpen} closeDialog={closeSuccessDialog} />
    </>
  );
};
