import React, { useContext } from 'react';

import { Box, Button, Grid, Typography } from '@material-ui/core';
import { AiOutlinePlus } from 'react-icons/ai';

import { SelectButton } from '../../hiring/components';
import { CompanyPhotoTile } from '../components/CompanyPhotoTile';
import { Company, CompanyPhoto } from '../../../types';
import { AddOrEditCompanyPhotoDialog } from '../components/AddOrEditCompanyPhotoDialog';
import { DeviceContext } from '../../../contexts';
import { useCompanyPhoto } from '../hooks/useCompanyPhoto';

interface Props {
  company?: Company;
  photos: CompanyPhoto[];
  getCompanyData: Function;
  token: string;
}

const LIMIT = 20;

export const CompanyPhotos: React.FC<Props> = ({ company, token, photos, getCompanyData }) => {
  const { isMobile } = useContext(DeviceContext);
  const {
    isAddOrEditDialogOpen,
    openAddOrEditDialog,
    isSelectMode,
    handleSelectButtonClick,
    handleEditClick,
    handleDeleteClick,
    handlePhotoSelect,
    selectedPhotos,
    updatePhoto,
    createPhoto,
    photoToEdit,
    handleAddOrEditDialogClose,
  } = useCompanyPhoto({ company, getCompanyData, token });

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={3}>
        <Typography variant="h6">Photos</Typography>

        <Box display="flex" alignItems="center" gridGap={16}>
          <Typography variant="subtitle1">
            {photos.length}/{LIMIT} {!isMobile && 'photos added'}
          </Typography>

          {!isMobile && <SelectButton selected={isSelectMode} handleClick={handleSelectButtonClick} />}

          <Button
            onClick={openAddOrEditDialog}
            size="small"
            disabled={photos.length >= LIMIT}
            variant="contained"
            color="primary"
            startIcon={<AiOutlinePlus />}>
            Add New Photo
          </Button>
        </Box>
      </Box>

      <Grid container spacing={3}>
        {photos.map(photo => (
          <Grid item xs={12} sm={6} md={4} key={photo.uuid}>
            <CompanyPhotoTile
              companyName={company?.name || ''}
              photo={photo}
              handleEditClick={handleEditClick}
              handleDeleteClick={handleDeleteClick}
              handlePhotoSelect={handlePhotoSelect}
              isSelectMode={isSelectMode}
              selected={!!selectedPhotos.find(item => item.uuid === photo.uuid)}
            />
          </Grid>
        ))}
      </Grid>

      <AddOrEditCompanyPhotoDialog
        handlePhotoUpdate={updatePhoto}
        handlePhotoCreate={createPhoto}
        photoToEdit={photoToEdit}
        open={isAddOrEditDialogOpen}
        handleClose={handleAddOrEditDialogClose}
      />
    </>
  );
};
