import React from 'react';

import { Box, Typography, Tooltip, useTheme } from '@material-ui/core';

import { PieChartDataItem } from '@vyce/core/src/types';

interface Props {
  data: PieChartDataItem;
}

const labelStyles = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 600,
} as const;

export const LegendItem = ({ data }: Props) => {
  const { value, color, label, percent } = data;
  const theme = useTheme();

  return (
    <Box display="flex" width="100%" overflow="hidden" bgcolor={theme.palette.background.paper}>
      <Box display="flex" alignItems="center" gridGap={8} flexWrap="nowrap" width="100%">
        <Box width={12} minWidth={12} height={12} style={{ backgroundColor: color, borderRadius: '50%' }} />
        <Tooltip title={label} placement="top">
          <Typography style={labelStyles} variant="caption">
            {label}
          </Typography>
        </Tooltip>
        -
        <Typography style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>
          {percent ? `${percent}% (${value})` : value}
        </Typography>
      </Box>
    </Box>
  );
};
