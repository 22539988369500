import axios from 'axios';
import config from '../config';

const URL = 'https://maps.googleapis.com/maps/api/geocode/';

export const fetchAddressDataRequest = (address: string) => {
  return axios.get(`${URL}json?address=${address}&key=${config.googleSearchApiKey}&language=en`);
};

export const fetchAddressDataByCoordinatesRequest = (lat: number, lon: number) => {
  return axios.get(`${URL}json?latlng=${lat},${lon}&key=${config.googleSearchApiKey}&language=en`);
};
