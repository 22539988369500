import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormHelperText, InputLabel, MenuItem, Box, Typography } from '@material-ui/core';

import { AppSelect } from '../../../../components/inputs';
import { Shift } from '../../../../types';
import { DetailsIcon } from '../../../../components';
import { ShiftsView } from '../../../../modules/manualClockModule/components';
import { filterShifts } from '../../../../modules/manualClockModule/utils';

interface Props {
  shifts: Shift[];
}

export const ShiftSelect: React.FC<Props> = ({ shifts }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      rules={{ required: 'Shift is required' }}
      name="shift_id"
      control={control}
      render={props => (
        <FormControl disabled={!shifts?.length} margin="normal" fullWidth variant="filled">
          <InputLabel id="shift">Shifts</InputLabel>

          <AppSelect
            endAdornment={
              <Box paddingRight={6}>
                {!!shifts?.length && (
                  <DetailsIcon
                    content={<ShiftsView title="Available shifts:" shifts={filterShifts(shifts, null)} />}
                  />
                )}
              </Box>
            }
            onChange={e => props.field.onChange(e.target.value)}
            value={props.field.value}
            labelId="shift"
            fullWidth>
            {shifts.map(item => (
              <MenuItem key={item.name} value={item.uuid}>
                {item.name}
              </MenuItem>
            ))}
          </AppSelect>

          <FormHelperText error>{(errors.shift_id?.message as string) || ''}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
