/**
 * Checks if a given value is null or undefined.
 *
 * @template T - The type of the value to be checked.
 * @param {T} value - The value to be checked.
 * @return {boolean} Returns true if the value is null or undefined, otherwise false.
 */

export const isNil = <T>(value: T): boolean => {
  return value === null || value === undefined;
};
