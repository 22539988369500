import React, { ChangeEvent, useEffect, useState } from 'react';

import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import Switch, { SwitchProps } from '@material-ui/core/Switch';
import clsx from 'clsx';

import { backgrounds, monochrome } from '../theme/styles';

export interface StyleProps {
  width: number;
  labelOffset: number;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    root: {
      height: 56,
      width: props => props.width,
      padding: 0,
      borderRadius: '8px',
      boxShadow: '2px 4px 12px rgba(20, 20, 43, 0.04)',
      border: 'none',
      order: 1,
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: props => `translateX(${props.width / 2}px)`,
      },
    },
    smallRoot: {
      height: 40,
    },
    switchBase: {
      padding: 0,
      color: theme.palette.type === 'dark' ? backgrounds.darkInput : backgrounds.input,
    },
    switchBasePaper: {
      color: theme.palette.background.paper,
    },
    thumb: {
      height: 56,
      width: props => props.width / 2,
      borderRadius: '8px',
      border: 'none',
      boxShadow: 'none',
    },
    track: {
      opacity: `${1} !important`,
      transition: theme.transitions.create(['background-color', 'color']),
      backgroundColor: theme.palette.type === 'dark' ? monochrome.darkest : monochrome.medium,
    },
    label: {
      fontWeight: 600,
      fontSize: '13px',
      position: 'absolute',
      top: 'calc(50% - 10px)',
      zIndex: 1,
      order: 2,
      pointerEvents: 'none',
      color: theme.palette.type === 'dark' ? monochrome.medium : '#fff',
      transition: theme.transitions.create(['color']),
    },
    leftLabel: {
      left: props => `${props.labelOffset}px`,
    },
    rightLabel: {
      right: props => `${props.labelOffset}px`,
    },
    active: {
      color: theme.palette.type === 'dark' ? '#fff' : monochrome.dark,
    },
    paperTrack: {
      backgroundColor: theme.palette.type === 'dark' ? monochrome.dark : monochrome.mediumlight,
    },
    sizeSmall: {
      '& .MuiSwitch-thumb': {
        height: 40,
        width: props => props.width / 2,
      },
      '& .MuiSwitch-switchBase': {
        padding: 0,
      },
    },
  })
);

interface Props extends SwitchProps {
  leftLabel: string;
  rightLabel: string;
  variant?: 'paper' | 'default';
  width: number;
  labelOffset: number;
}

export const AppSwitch = React.forwardRef(
  ({ rightLabel, leftLabel, labelOffset, ...restProps }: Props, ref) => {
    const { checked, onChange, variant = 'default', size, width } = restProps;
    const classes = useStyles({ width, labelOffset });
    const [isChecked, setIsChecked] = useState<boolean>();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const checked = e.target.checked;
      if (onChange) {
        onChange(e, checked);
      }
      setIsChecked(checked);
    };

    useEffect(() => {
      setIsChecked(checked);
    }, [checked]);

    return (
      <Box position="relative">
        <Typography
          className={clsx(classes.label, classes.leftLabel, {
            [classes.active]: !isChecked,
          })}>
          {leftLabel}
        </Typography>
        <Switch
          onChange={handleChange}
          checked={isChecked}
          disableRipple
          classes={{
            root: clsx(classes.root, {
              [classes.smallRoot]: size === 'small',
            }),
            switchBase: clsx(classes.switchBase, {
              [classes.switchBasePaper]: variant === 'paper',
            }),
            thumb: classes.thumb,
            track: clsx(classes.track, {
              [classes.paperTrack]: variant === 'paper',
            }),
            sizeSmall: classes.sizeSmall,
          }}
          color="default"
          innerRef={ref}
          {...restProps}
        />
        <Typography
          className={clsx(classes.label, classes.rightLabel, {
            [classes.active]: isChecked,
          })}>
          {rightLabel}
        </Typography>
      </Box>
    );
  }
);
