import React from 'react';

import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from '../../styles';
import { CopyToClipboard } from '../../../../components/CopyToClipboard';

interface HiringInfoItemProps {
  title: string;
  value: string;
  extra?: string;
  copyToClipboard?: boolean;
}

export const HiringInfoItem = ({ title, value, extra, copyToClipboard }: HiringInfoItemProps) => {
  const classes = useStyles();

  if (!value) return null;
  return (
    <Box display="flex" flexDirection="column">
      <Typography color="textSecondary" className={clsx(classes.smallBold, classes.smallText)}>
        {title}
      </Typography>
      <Box display="flex" alignItems="center" gridGap={16}>
        <Typography variant="subtitle1">{value}</Typography>
        <Typography variant="subtitle1" className={clsx(classes.smallBold)}>
          {extra}
        </Typography>
        {copyToClipboard && <CopyToClipboard text={value} />}
      </Box>
    </Box>
  );
};
