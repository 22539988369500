import React from 'react';

import { TimeLogsByWorker } from '@vyce/core/src/views/time/TimeLogsByWorker';

import { useTypedSelector } from '../../hooks';

export const EmployerTimeLogsByWorker: React.FC = () => {
  const { selectedCompany, selectedPosition } = useTypedSelector(state => state.helper);

  return (
    <TimeLogsByWorker userPermissions={selectedPosition?.permissions} companyId={selectedCompany?.uuid} />
  );
};
