import React, { useContext, useMemo, useRef } from 'react';

import { Marker } from 'react-leaflet';
import { LatLngExpression, LatLngLiteral } from 'leaflet';

import { ColorThemeContext } from '../../../contexts';
import { getMarkerIcon } from '../../../utils/location';
import { LONDON_POSITION } from '../../map/constants';

interface Props {
  onPositionChange: (position: LatLngLiteral) => void;
  position: LatLngExpression;
}

export const DraggableMarker: React.FC<Props> = ({ onPositionChange, position }) => {
  const { colorTheme } = useContext(ColorThemeContext);
  const isDarkTheme = colorTheme === 'dark';

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker: any = markerRef.current;
        if (marker != null) {
          onPositionChange(marker.getLatLng());
        }
      },
    }),
    []
  );
  const markerRef = useRef(null);

  return (
    <Marker
      draggable={true}
      icon={getMarkerIcon(isDarkTheme)}
      eventHandlers={eventHandlers}
      position={position || LONDON_POSITION}
      ref={markerRef}></Marker>
  );
};
