import React from 'react';

import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  RadioGroup,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';

import { FieldProps } from '../../types';
import { AppRadioButton } from '../inputs';

export const YesNoRadio: React.FC<FieldProps> = ({ disabled = false, name = 'isLimitedCompany', label }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Box display="flex" width="100%">
      <Controller
        render={({ field }) => (
          <FormControl {...field} component="fieldset">
            <FormLabel error={!!errors.gender?.message} component="legend">
              {label}
            </FormLabel>
            <RadioGroup aria-label="gender" value={field.value || null} onChange={field.onChange}>
              <Box display="flex">
                <FormControlLabel
                  disabled={disabled}
                  value={'yes'}
                  control={<AppRadioButton style={{ width: 100 }} color="primary" name="Yes" />}
                  label=""
                />
                <FormControlLabel
                  disabled={disabled}
                  value={'no'}
                  control={<AppRadioButton style={{ width: 100 }} color="primary" name="No" />}
                  label=""
                />
              </Box>
            </RadioGroup>
            <FormHelperText error id="select-helper-text">
              {(errors.gender?.message as string) || ''}
            </FormHelperText>
          </FormControl>
        )}
        name={name}
        control={control}
      />
    </Box>
  );
};
