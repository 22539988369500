import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import clsx from 'clsx';

import { DetailItem } from '@vyce/core/src/types';

import useStyles from '../styles';

export const DataRowItem = ({ title, value }: DetailItem) => {
  const classes = useStyles();

  if (!value) return null;
  return (
    <Box className={classes.recordWrapper} marginBottom={1}>
      <Grid item xs={12} sm={6} lg={6}>
        <Typography variant="subtitle1" className={classes.bold}>
          {title}:
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <Box display="flex" gridGap={16}>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            className={clsx(classes.bigTextWrap, classes.smallText)}>
            {value}
          </Typography>
        </Box>
      </Grid>
    </Box>
  );
};
