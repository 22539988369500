import queryString from 'querystring';

export const generateQueryParamsFromObj = (obj: any) => {
  Object.keys(obj).forEach(key => {
    const item = obj[key];
    if (!item) {
      delete obj[key];
    }
    if (typeof item === 'object') {
      obj[key] = JSON.stringify(item);
    }
  });
  return queryString.stringify(obj);
};

export const getUrlItems = (link: string) => {
  const items = link ? link.split('_') : [];
  return {
    id: items[1],
    additionalId: items[2],
    name: items[0],
  };
};

export const getAuthPage = (pathname: string, url: string): string => {
  const arr = pathname.split('/');
  arr.pop();
  return arr.join('/') + url;
};
