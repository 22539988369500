import { Box } from '@material-ui/core';

import { PageLoading } from '@vyce/core/src/components';

import { useStyles } from './styles';
import { Props } from './types';
import { TopPerformingContent } from './components';
import { useWidgetData } from './hooks';

export const TopPerformingWidget = (props: Props) => {
  const classes = useStyles();
  const { loading, topPerformingHours, topPerformingWorkers, cnaViewWorker } = useWidgetData(props);

  return (
    <Box className={classes.widgetContainer}>
      {loading ? (
        <PageLoading />
      ) : (
        <TopPerformingContent
          topPerformingWorkers={topPerformingWorkers}
          cnaViewWorker={cnaViewWorker}
          topPerformingHours={topPerformingHours}
        />
      )}
    </Box>
  );
};
