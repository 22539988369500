import React, { useMemo } from 'react';

import { Box, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';

import { TopPerformingHours } from '@vyce/core/src/types';
import { getColor } from '@vyce/core/src/theme/styles';
import { HourNumber } from './HourNumber';
import { useStyles } from '../styles';

const HoursDescriptionItem = ({
  value,
  text,
  bgColor,
  textColor,
}: {
  value: number;
  text: string;
  bgColor: string;
  textColor: string;
}) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.border}
      padding={2}
      display="flex"
      flexDirection="column"
      gridGap={8}
      justifyContent="center">
      <HourNumber bgColor={bgColor} textColor={textColor} label={`${value}h`} fontSize="24px" />
      <Typography variant="caption" color="textSecondary" className={clsx(classes.small, classes.bold)}>
        {text}
      </Typography>
    </Box>
  );
};

export const HoursDescriptionBlock = ({
  approved_hours,
  overtime_hours,
  pending_hours,
  regular_hours,
}: TopPerformingHours) => {
  const theme = useTheme();
  const classes = useStyles();

  const colors = getColor(theme.palette.type === 'dark');

  const options = useMemo(
    () => [
      {
        value: regular_hours,
        text: 'Regular Hours',
        bgColor: colors.secondaryBG,
        textColor: colors.secondaryDark,
      },
      {
        value: overtime_hours,
        text: 'Overall Overtime',
        bgColor: colors.primaryBG,
        textColor: colors.primaryDark,
      },
      {
        value: approved_hours,
        text: 'Approved Hours',
        bgColor: colors.successBG,
        textColor: colors.successDark,
      },
      {
        value: pending_hours,
        text: 'Pending Hours',
        bgColor: colors.warningBG,
        textColor: colors.warningDark,
      },
    ],
    [approved_hours, overtime_hours, pending_hours, regular_hours, colors]
  );
  return (
    <Box className={classes.hoursDescriptionBlockWrapper}>
      {options.map(item => (
        <HoursDescriptionItem key={item.text} {...item} />
      ))}
    </Box>
  );
};
