import React from 'react';

import { Box, Grid, Typography } from '@material-ui/core';

import { AppChip } from '../../../components';
import { useStyles } from '../styles';
import { Job, Tag, TagGroups } from '../../../types';

interface Props {
  job?: Job;
  workerTags?: Tag[];
}

export const JobDetails: React.FC<Props> = ({ job, workerTags }) => {
  const classes = useStyles();
  const skillNames =
    job?.tags?.filter((tag: Tag) => tag.group === TagGroups.SKILL)?.map(tag => tag.name) || [];
  const workerSkillNames = workerTags
    ?.filter((tag: Tag) => tag.group === TagGroups.SKILL)
    ?.map(tag => tag.name);

  return (
    <Box className={classes.dualBorder} padding={2} paddingBottom={3} paddingTop={1}>
      <Box marginTop={1}>
        <Typography variant="h6" className={classes.bold}>
          Job Details
        </Typography>
      </Box>

      <Grid container>
        <Grid item xs={6} className={classes.jobDataItem}>
          <Typography variant="subtitle2" color="textSecondary">
            Job Type
          </Typography>
          <Typography className={classes.capitalize} variant="subtitle2">
            {job?.job_type}
          </Typography>
        </Grid>

        {job?.duration && (
          <Grid item xs={6} className={classes.jobDataItem}>
            <Typography variant="subtitle2" color="textSecondary">
              Contract Duration
            </Typography>
            <Typography className={classes.capitalize} variant="subtitle2">
              {`${job?.duration} ${job?.duration !== 1 ? job?.duration_type : job?.duration_type + 's'}`}
            </Typography>
          </Grid>
        )}

        <Grid item xs={6} className={classes.jobDataItem}>
          <Box>
            <Typography variant="subtitle2" color="textSecondary">
              Experience
            </Typography>
            <Typography variant="subtitle2">
              {job?.years_of_experience} {job?.years_of_experience !== 1 ? 'years' : 'year'}
            </Typography>
          </Box>
        </Grid>

        {job?.computed?.distance?.value && (
          <Grid item xs={6} className={classes.jobDataItem}>
            <Box>
              <Typography variant="subtitle2" color="textSecondary">
                Distance
              </Typography>
              {job?.computed?.distance?.value && (
                <Typography variant="subtitle2">
                  {job.computed.distance.value} {job.computed.distance.type}
                </Typography>
              )}
            </Box>
          </Grid>
        )}

        {job?.address?.address_snippet && (
          <Grid item xs={12} className={classes.jobDataItem}>
            <Typography variant="subtitle2" color="textSecondary">
              Job location
            </Typography>
            <Typography variant="subtitle2">{job.address.address_snippet}</Typography>
          </Grid>
        )}

        {!!skillNames.length && (
          <Grid item xs={12} className={classes.jobDataItem}>
            <Typography variant="subtitle2" color="textSecondary">
              Skills
            </Typography>
            <Box marginTop={1}>
              {skillNames.map(name => (
                <AppChip
                  className={
                    workerSkillNames?.includes(name) || !workerSkillNames ? classes.chip : classes.greyChip
                  }
                  key={name}
                  label={name}
                />
              ))}
            </Box>
          </Grid>
        )}

        {job?.qualifications_required && !!Object.keys(job?.qualifications_required)?.length && (
          <Grid item xs={12} className={classes.jobDataItem}>
            <Typography variant="subtitle2" color="textSecondary">
              Qualifications required
            </Typography>
            <Box marginTop={1}>
              {job?.qualifications_required.map((item: string) => (
                <AppChip className={classes.chip} key={item} label={item} />
              ))}
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
