import React, { useContext, useEffect, useState } from 'react';

import { Route, Switch, useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';

import { CommonNavBarBlock } from '@vyce/core/src/components';
import { DeviceContext } from '@vyce/core/src/contexts';
import { getPaddingForContent } from '@vyce/core/src/utils';
import { PreviewButton } from '@vyce/core/src/views/preview/components/PreviewButton';

import { PROFILE_TABS, PREVIEW_URL, PREVIEW_TAB } from './constants';
import { RouteProfileComponent } from './RouteProfileComponent';

export const Profile: React.FC = () => {
  const { isMobile, isLargeDesktop } = useContext(DeviceContext);
  const [tabs, setTabs] = useState(PROFILE_TABS);
  const history = useHistory();
  const padding = getPaddingForContent(isMobile);

  const goToPreview = () => {
    history.push(PREVIEW_URL);
  };

  useEffect(() => {
    if (isLargeDesktop && tabs.includes(PREVIEW_TAB)) {
      setTabs(tabs.slice(0, tabs.length - 1));
    }
    if (!isLargeDesktop && !tabs.includes(PREVIEW_TAB)) {
      setTabs(value => [...value, PREVIEW_TAB]);
    }
  }, [isLargeDesktop, tabs]);
  return (
    <Box position="relative" height="calc(100% - 64px)">
      <CommonNavBarBlock tabItems={tabs} />

      <Box height="100%">
        {isLargeDesktop && <PreviewButton goToPreview={goToPreview} />}

        <Box padding={padding} height="100%" display="flex" flexDirection="column">
          <Switch>
            <Route path="*" component={RouteProfileComponent} />
          </Switch>
        </Box>
      </Box>
    </Box>
  );
};
