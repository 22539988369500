// @ts-nocheck
import React, { useMemo } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import InputMask from 'react-input-mask';

import { FieldProps } from '../../types';
import { AppTextField } from '../inputs';

const getRules = (required: boolean) => ({
  required: required ? 'Sort Code is required' : undefined,
  validate: (value: string) => {
    if (!value) return true;
    return value?.length === 6 || 'Sort code must be 6 characters long';
  },
});

export const SortCodeField: React.FC<FieldProps> = ({
  margin = undefined,
  disabled = false,
  required = true,
  name = 'bank_account.sort_code',
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const rules = useMemo(() => getRules(required), [required]);

  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      render={({ field }) => (
        <InputMask
          {...field}
          mask="99-99-99"
          onChange={e => field.onChange(e.target.value.replace(/-/g, ''))}
          disabled={disabled}
          maskChar={null}>
          {() => (
            <AppTextField
              error={!!errors?.bank_account?.sort_code?.message}
              id="sort-code"
              disabled={disabled}
              label="Sort Code"
              margin={margin}
              type="tel"
              fullWidth
              helperText={errors?.bank_account?.sort_code?.message || ''}
            />
          )}
        </InputMask>
      )}
    />
  );
};
