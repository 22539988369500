import React, { ReactElement, ReactNode } from 'react';

import { Box, createStyles, makeStyles, Popover, Theme } from '@material-ui/core';
import { HiQuestionMarkCircle } from 'react-icons/hi';
import { IconType } from 'react-icons';

import { MAIN_CONTAINER_ID } from '../constants';

interface Props {
  children?: ReactNode;
  icon?: ReactElement<IconType, any>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageContainer: {
      padding: theme.spacing(1),
      maxWidth: '320px',
    },
    icon: {
      padding: '15px',
      borderRadius: '50%',
    },
    popover: {
      pointerEvents: 'none',
    },
  })
);

export const Hint: React.FC<Props> = ({
  children,
  icon = <HiQuestionMarkCircle size="22px" color="#A0A3BD" />,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!children) {
      return;
    }
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        aria-describedby={id}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        className={classes.icon}>
        {icon}
      </Box>
      {children && (
        <Popover
          container={document.getElementById(MAIN_CONTAINER_ID)}
          id="mouse-over-popover"
          className={classes.popover}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus>
          <div className={classes.messageContainer}>{children}</div>
        </Popover>
      )}
    </>
  );
};
