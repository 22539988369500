import React, { useContext } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { DeviceContext } from '../../../contexts';
import { AppA } from '../../../components';
import { PublicLink } from '../../../types';
import { MAIN_CONTAINER_ID, SUBCONTRACTOR_TYPES } from '../../../constants';

interface Props {
  open: boolean;
  companyName: string;
  link: PublicLink;
  handleClose: () => void;
}

export const PaySchedulePublicLinkDialog: React.FC<Props> = ({ open, handleClose, companyName, link }) => {
  const { isMobile } = useContext(DeviceContext);
  const { enqueueSnackbar } = useSnackbar();
  const message = `Please fill out this new starter form for the ${companyName} payroll team. `;

  const workerType =
    link.pay_scheme === SUBCONTRACTOR_TYPES.CIS_SELF_EMPLOYED
      ? 'CIS Self Employed Contractors'
      : 'Self Employed Contractors';

  const copyToClipboard = async () => {
    const text = `${message}\n\n${link.url}`;
    await navigator?.clipboard?.writeText(text);
    enqueueSnackbar('Copied to clipboard', {
      variant: 'info',
    });
    handleClose();
  };

  return (
    <Dialog
      fullScreen={isMobile}
      open={open}
      container={document.getElementById(MAIN_CONTAINER_ID)}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">
        Here is your custom invite message and link to this pay schedule for {workerType}...
      </DialogTitle>
      <DialogContent>
        <Box marginTop={2}>
          <Typography id="custom-message-to-copy">
            {message}
            <AppA href={link.url} content={'invite link'} />
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button size="large" variant="outlined" onClick={handleClose}>
          Close
        </Button>
        <Button size="large" color="primary" onClick={copyToClipboard} variant="contained">
          Copy
        </Button>
      </DialogActions>
    </Dialog>
  );
};
