import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Box, Button, Paper } from '@material-ui/core';
import { AxiosResponse } from 'axios';
import isEqual from 'lodash/isEqual';

import { Answer, UserSurvey } from '@vyce/core/src/types';
import { HealthAndSafetyQuestions } from '../components/HealthAndSafetyQuestions';
import { RouterPrompt } from '@vyce/core/src/components';
import { DeviceContext } from '@vyce/core/src/contexts';
import { generateFileLinkAndSave } from '@vyce/core/src/utils';
import { DownloadHealthAndSafetyPDFRequest } from '@vyce/core/src/api/types';
import { ButtonTitleWithLoading } from '@vyce/core/src/components/ButtonTitleWithLoading';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import useStyles from '../styles';

interface Props {
  userId?: string;
  saveSurveyAnswers: Function;
  downloadHealthAndSafetyPDFRequest: ({
    userId,
  }: DownloadHealthAndSafetyPDFRequest) => Promise<AxiosResponse>;
  setSurvey: Function;
  survey?: UserSurvey;
}

export const SEARCH_HEALTH_SURVEY_SUBSTRING = 'health';

export const HealthAndSafety: React.FC<Props> = ({
  survey,
  saveSurveyAnswers,
  userId,
  setSurvey,
  downloadHealthAndSafetyPDFRequest,
}) => {
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const classes = useStyles();
  const [healthAndSafetySurvey, setHealthAndSafetySurvey] = useState<UserSurvey | undefined>(survey);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPDF, setLoadingPDF] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const { isMobile } = useContext(DeviceContext);

  const saveAnswers = async () => {
    if (!healthAndSafetySurvey) {
      return;
    }
    try {
      setLoading(true);
      const answers: Answer[] = healthAndSafetySurvey?.content?.map(item => ({
        yes_no: item.answer?.yes_no === undefined ? null : item.answer?.yes_no,
        comment: item.answer?.yes_no ? item.answer.comment : null,
        question_id: item.question.uuid,
      }));
      const res = await saveSurveyAnswers(healthAndSafetySurvey.uuid, answers, userId);
      setSurvey(res.data);
      showNotification({ message: 'Answers have been updated', options: { variant: 'success' } });
      setHasChanges(false);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  };

  const downloadPDF = async () => {
    try {
      setLoadingPDF(true);
      const res = await downloadHealthAndSafetyPDFRequest({ userId });
      generateFileLinkAndSave(res, 'application/pdf');
      setLoadingPDF(false);
    } catch (e) {
      setLoadingPDF(false);
      handleServerError(e);
    }
  };

  const resetSurvey = useCallback(() => setHealthAndSafetySurvey(survey), [survey]);

  useEffect(() => {
    if (survey) {
      setHealthAndSafetySurvey(survey);
      setHasChanges(false);
    }
  }, [survey]);

  useEffect(() => {
    if (healthAndSafetySurvey) {
      const isChanged = !isEqual(healthAndSafetySurvey, survey);
      setHasChanges(isChanged);
    }
  }, [healthAndSafetySurvey]);

  return (
    <Box paddingBottom={2} width={isMobile ? '100%' : '75%'}>
      {healthAndSafetySurvey && (
        <Paper variant="outlined" className={classes.formPaper}>
          <HealthAndSafetyQuestions setSurvey={setHealthAndSafetySurvey} survey={healthAndSafetySurvey} />
          <Box
            display="flex"
            justifyContent="space-between"
            marginTop={5}
            gridGap={16}
            className={classes.confirmButtonsContainer}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.pdfButton}
              onClick={downloadPDF}
              disabled={hasChanges || loading || loadingPDF}>
              <ButtonTitleWithLoading title="Download PDF" loaderVariant="paper" loading={loadingPDF} />
            </Button>

            <Box gridGap={16} display="flex" className={classes.constrolWrapper}>
              <Button variant="outlined" onClick={resetSurvey}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.saveButton}
                onClick={saveAnswers}
                disabled={!hasChanges || loading}>
                <ButtonTitleWithLoading title="Save changes" loaderVariant="paper" loading={loading} />
              </Button>
            </Box>
          </Box>
        </Paper>
      )}

      <RouterPrompt
        when={hasChanges}
        title="Before you go..."
        subtitle="Do you want to save the changes you've made?"
        okText={isMobile ? 'Yes' : 'Yes, save my changes'}
        cancelText={isMobile ? 'No' : 'No, ignore my changes'}
        onOK={async () => {
          await saveAnswers();
          return true;
        }}
        onCancel={() => true}
      />
    </Box>
  );
};
