import { TabItem } from '@vyce/core/src/types';

export const PAYROLL_TABS: TabItem[] = [
  {
    label: 'Dashboard',
    link: '/payroll/dashboard',
  },
  {
    label: 'Timesheets',
    link: '/payroll/timesheets',
  },
  {
    label: 'Payruns',
    link: '/payroll/payruns',
  },
  {
    label: 'Reports',
    link: '/payroll/reports',
  },
  {
    label: 'Users',
    link: '/payroll/users',
  },
  {
    label: 'Settings',
    link: '/payroll/settings',
  },
];

export const OUTSOURCED_PAYROLL_TABS = PAYROLL_TABS.filter(
  tab => tab.label !== 'Payruns' && tab.label !== 'Reports'
);
