import React, { useContext } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import { DeviceContext } from '../contexts';
import { ButtonTitleWithLoading } from './ButtonTitleWithLoading';
import { MAIN_CONTAINER_ID } from '../constants';

interface Props {
  open: boolean;
  loading?: boolean;
  cancelText?: string;
  confirmText?: string;
  title: string;
  subtitle?: string;
  showCancelText?: boolean;
  handleClose: () => void;
  handleConfirm?: () => void;
}

export const ConfirmDialog: React.FC<Props> = ({
  open,
  handleClose,
  loading,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  showCancelText = true,
  handleConfirm,
  title,
  subtitle,
}) => {
  const { isMobile } = useContext(DeviceContext);

  const handleConfirmClick = () => {
    handleConfirm?.();
  };

  return (
    <Dialog
      fullScreen={isMobile}
      open={open}
      container={document.getElementById(MAIN_CONTAINER_ID)}
      disableScrollLock
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>

      <DialogContent>
        <DialogContentText>{subtitle}</DialogContentText>
      </DialogContent>

      <DialogActions>
        {showCancelText && (
          <Button size="large" style={{ minWidth: 115 }} variant="outlined" onClick={handleClose}>
            {cancelText}
          </Button>
        )}
        {handleConfirm && (
          <Button
            size="large"
            cy-test-id="confirm-button"
            style={{ minWidth: 115 }}
            color="primary"
            onClick={handleConfirmClick}
            variant="contained"
            disabled={loading}>
            <ButtonTitleWithLoading title={confirmText} loaderVariant="paper" loading={!!loading} />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
