import React from 'react';

import { PopoverContentProps } from '@reactour/tour';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';

import { CloseButton, PrevButton, NextButton } from './Buttons';
import { Dot } from './DotItem';

const useStyles = makeStyles<any>((theme: Theme) =>
  createStyles({
    controlWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gridGap: theme.spacing(4),
      marginTop: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        gridGap: theme.spacing(2),
      },
    },
    stepControlsWrapper: {
      display: 'flex',
      alignItems: 'center',
      gridGap: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        gridGap: theme.spacing(2),
      },
    },
    control: {
      [theme.breakpoints.down('sm')]: {
        height: '40px',
        padding: '3px 9px',
        fontSize: '0.8125rem',
      },
    },
  })
);

export const ContentComponent = (props: PopoverContentProps) => {
  const classes = useStyles();

  const { currentStep, steps, disableDotsNavigation, disabledActions, setCurrentStep } = props;
  const content = steps[currentStep].content;

  return (
    <div>
      <>
        {typeof content === 'function' ? content({ ...props }) : content}
        <Box className={classes.controlWrapper}>
          <CloseButton {...props} className={classes.control} />

          <Box className={classes.stepControlsWrapper}>
            <PrevButton {...props} className={classes.control} />
            <Box display="flex">
              {steps.map((_dot, index) => (
                <Dot
                  key={index}
                  index={index}
                  setCurrentStep={setCurrentStep}
                  currentStep={currentStep}
                  disableDotsNavigation={disableDotsNavigation}
                  disabledActions={disabledActions}
                />
              ))}
            </Box>
            <NextButton {...props} className={classes.control} />
          </Box>
        </Box>
      </>
    </div>
  );
};
