import React from 'react';

import { OnboardingStepTitle } from '@vyce/core/src/components';
import {
  AccountsOfficeReferenceField,
  EmployerPAYEReferenceField,
  UTRNumberField,
} from '@vyce/core/src/components/controlled-inputs';

import { Company } from '../../../types';

interface Props {
  selectedCompany?: Company;
}

export const HMRCSettingsStep: React.FC<Props> = ({ selectedCompany }) => {
  return (
    <>
      <OnboardingStepTitle
        text={`In order to process payroll for ${selectedCompany?.name} through HMRC’s RTI we need to confirm your company’s HMRC details...`}
      />

      <EmployerPAYEReferenceField margin="normal" />

      <AccountsOfficeReferenceField margin="normal" />

      <UTRNumberField label="UTR (E.g. 0123456789)" showHint={false} required />
    </>
  );
};
