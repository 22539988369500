import { axios } from '@vyce/core/src/api';

import { GetUserManagementItemsPayload, ListStaffRequest, SendInviteRequest } from '../types';

export const getStaffRequest = (companyId: string, payload: ListStaffRequest) => {
  return axios.post(`/management/members/staff/companies/${companyId}/search`, payload);
};

export const getRolesRequest = (companyId: string) => {
  return axios.get(`/management/company/${companyId}/roles`);
};

export const sendInviteRequest = (companyId: string, payload: SendInviteRequest) => {
  return axios.post(`/management/company/${companyId}/staff/invites`, payload);
};

export const redeemStaffInviteTokenRequest = (token: string, staffInviteId: string, inviteToken: string) => {
  return axios.get(`/management/staff/invites/${staffInviteId}/invite_token/${inviteToken}`);
};

export const editPermissionRequest = (companyId: string, userId: string, data: any) => {
  return axios.patch(`/management/company/${companyId}/user/${userId}`, data);
};

export const getEmployeesRequest = (
  token: string,
  companyId: string,
  payload: GetUserManagementItemsPayload
) => {
  return axios.post(`/management/members/employees/companies/${companyId}/search`, payload);
};

export const getTeamMembersRequest = (companyId: string, payload: GetUserManagementItemsPayload) => {
  return axios.post(`/management/members/teams/companies/${companyId}/search`, payload);
};
