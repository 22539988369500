import React, { useContext, useEffect, useState } from 'react';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';

import { DeviceContext } from '../../../contexts';
import { ImageUploader } from '../../../components';
import { CompanyPhoto, Image } from '../../../types';
import { AppTextFieldWithLimit } from '../../../components/controlled-inputs/AppTextFieldWithLimit';
import { CreateCompanyPhotoData } from '../../../api/types';
import { useBooleanState } from '../../../hooks';
import { ButtonTitleWithLoading } from '../../../components/ButtonTitleWithLoading';
import { MAIN_CONTAINER_ID } from '../../../constants';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  open: boolean;
  handleClose: () => void;
  photoToEdit?: CompanyPhoto;
  handlePhotoCreate: (data: CreateCompanyPhotoData) => Promise<void>;
  handlePhotoUpdate: (data: CreateCompanyPhotoData) => Promise<void>;
}

interface Form {
  title: string;
  description: string;
}

const EMPTY_IMAGE = { url: '', file: null };

export const AddOrEditCompanyPhotoDialog: React.FC<Props> = ({
  open,
  handleClose,
  photoToEdit,
  handlePhotoCreate,
  handlePhotoUpdate,
}) => {
  const { handleServerError } = useContext(NotificationContext);
  const { isMobile } = useContext(DeviceContext);
  const [image, setImage] = useState<Image>(EMPTY_IMAGE);
  const [loading, setLoadingTrue, setLoadingFalse] = useBooleanState(false);
  const methods = useForm<Form>({
    defaultValues: { title: '', description: '' },
  });
  const { setValue, reset } = methods;

  const handleImageUpload = (newImage: Image) => {
    setImage(newImage);
  };

  const onSubmit = async (data: Form) => {
    setLoadingTrue();
    try {
      if (photoToEdit) {
        await handlePhotoUpdate({ title: data.title, description: data.description, file: image.file });
      } else {
        if (!image?.file) {
          return;
        }
        await handlePhotoCreate({ title: data.title, description: data.description, file: image.file });
      }
      reset();
      setImage(EMPTY_IMAGE);
      setLoadingFalse();
    } catch (e) {
      setLoadingFalse();
      handleServerError(e);
    }
  };

  useEffect(() => {
    setValue('title', photoToEdit?.title || '');
    setValue('description', photoToEdit?.description || '');
    setImage({ url: photoToEdit?.url || '', file: null });
  }, [photoToEdit]);

  const onClose = () => {
    handleClose();
    reset();
    setImage(EMPTY_IMAGE);
  };

  return (
    <Dialog
      fullScreen={isMobile}
      maxWidth="lg"
      open={open}
      container={document.getElementById(MAIN_CONTAINER_ID)}
      disableScrollLock
      onClose={onClose}
      aria-labelledby="responsive-dialog-title">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle id="responsive-dialog-title">{photoToEdit ? 'Edit' : 'Add New'} Photo</DialogTitle>

          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box width={isMobile ? '100%' : '30vw'} maxWidth="100%" height="100%" maxHeight={270}>
                  <ImageUploader
                    image={image}
                    height={200}
                    isDocument
                    width={isMobile ? 250 : 350}
                    onImageUpload={handleImageUpload}
                    extraText="New Picture"
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box width={isMobile ? '100%' : '30vw'} maxWidth="100%">
                  <Box display="flex" flexDirection="column" gridGap={24}>
                    <AppTextFieldWithLimit name="title" limit={100} label="Name" />

                    <AppTextFieldWithLimit
                      name="description"
                      rows={8}
                      limit={600}
                      label="Description"
                      multiline
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button style={{ width: 106 }} size="large" onClick={onClose} variant="outlined">
              Close
            </Button>

            <Button
              disabled={loading}
              style={{ width: 106 }}
              size="large"
              type="submit"
              variant="contained"
              color="primary">
              <ButtonTitleWithLoading title="Save" loaderVariant="paper" loading={loading} />
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
