import React from 'react';

import { OnboardingStepTitle } from '@vyce/core/src/components';
import { CSCSRegistrationNumberField } from '@vyce/core/src/components/controlled-inputs';

import { TitleProps } from './types';

export const CSCSRegistrationNumberStep = ({ title = 'CSCS Registration Number' }: TitleProps) => {
  return (
    <>
      <OnboardingStepTitle text={title} />
      <CSCSRegistrationNumberField margin="normal" />
    </>
  );
};
