import { useMemo, useState } from 'react';

import { BackToData } from '../../../types';

export const useSubscriptionContext = () => {
  const [backTo, setBackTo] = useState<BackToData | null>(null);

  const contextValue = useMemo(() => ({ backTo, setBackTo }), [backTo, setBackTo]);

  return { contextValue, backTo };
};
