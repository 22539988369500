import React from 'react';

import { Box, Typography } from '@material-ui/core';

interface Props {
  decimals: number;
  amendmentDecimals: number;
}

export const WorkerHoursCell: React.FC<Props> = ({ decimals, amendmentDecimals }) => {
  return (
    <Box display="flex" alignItems="center" style={{ fontWeight: 600 }} height={70} gridGap={8}>
      {decimals}

      {amendmentDecimals !== 0 && (
        <Typography variant="subtitle2" color="secondary">
          {amendmentDecimals < 0 ? '' : '+'}
          {amendmentDecimals}
        </Typography>
      )}
    </Box>
  );
};
