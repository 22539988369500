import React, { useState } from 'react';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { Typography, Box, createStyles, makeStyles } from '@material-ui/core';

import { monochrome } from '../theme/styles';

interface OptionProps {
  value: string;
  title: string;
  icon: React.ReactNode;
}

interface Props {
  options: OptionProps[];
  onChange: (arg0: any) => void;
}

const useStyles = makeStyles(theme =>
  createStyles({
    buttonGroup: {
      backgroundColor: theme.palette.type === 'dark' ? monochrome.dark : monochrome.mediumlight,
      borderRadius: '8px',
      '& .MuiToggleButton-root.Mui-selected': {
        backgroundColor: theme.palette.type === 'dark' ? monochrome.darkest : monochrome.light,
        borderRadius: '8px !important',
        color: theme.palette.type === 'dark' ? monochrome.light : monochrome.dark,
      },
    },
    button: {
      width: '100%',
      borderRadius: '8px',
      textTransform: 'none',
      borderColor: 'transparent',
      color: theme.palette.type === 'dark' ? '#14142B' : '#fff',
      '& .MuiTypography-body1': {
        fontWeight: 600,
      },
    },
  })
);

export const AppToggle = ({ options, onChange }: Props) => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState(options[0].value);

  const handleChange = (_event: React.MouseEvent<HTMLElement, MouseEvent>, newValue: string) => {
    if (newValue) {
      onChange(newValue);
      setSelectedValue(newValue);
    }
  };
  return (
    <ToggleButtonGroup
      value={selectedValue}
      exclusive
      onChange={handleChange}
      className={classes.buttonGroup}>
      {options.map(item => (
        <ToggleButton value={item.value} aria-label={item.value} key={item.value} className={classes.button}>
          <Box display="flex" alignItems="center" gridGap={10}>
            {item.title && <Typography>{item.title}</Typography>}
            {item.icon && item.icon}
          </Box>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
