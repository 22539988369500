import React from 'react';

import { Box, Typography, useTheme } from '@material-ui/core';

import worker from '@vyce/core/src/assets/svg/worker.svg';
import employer from '@vyce/core/src/assets/svg/employer.svg';

import { RoleCard } from './RoleCard';

export const RolePicker = ({
  onEmployerClick,
  onWorkerClick,
}: {
  onEmployerClick: () => void;
  onWorkerClick: () => void;
}) => {
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" gridGap={16}>
      <Box>
        <Typography variant="h5" style={{ marginTop: theme.spacing(3), fontWeight: 700 }}>
          Welcome to Vyce!
        </Typography>

        <Typography color="textSecondary" variant="subtitle1">
          Tell us a bit about yourself.
        </Typography>
      </Box>

      <RoleCard
        alt="worker"
        title="I am a worker"
        subTitle={`My employer uses Vyce,
          I need a personal profile.`}
        src={worker}
        onClick={onWorkerClick}
      />

      <RoleCard
        alt="employer"
        title="I represent a company"
        subTitle="I need a company profile to invite our workers."
        src={employer}
        reversed
        onClick={onEmployerClick}
      />
    </Box>
  );
};
