import React from 'react';

import { createStyles, InputBase, Select, SelectProps, Theme, withStyles } from '@material-ui/core';
import { FiChevronDown } from 'react-icons/fi';

import { appTextFieldRootStyle } from './AppTextField';

export const StyledSelect = withStyles(() =>
  createStyles({
    icon: {
      position: 'absolute',
      top: 'calc(50% - 12px)',
      right: 16,
    },
  })
)(Select);

export const AppInput = withStyles((theme: Theme) =>
  createStyles({
    root: appTextFieldRootStyle(theme),
    input: {
      padding: '27px 22px 10px',
    },
  })
)(InputBase);

export const AppSelect = React.forwardRef((props: SelectProps, ref) => {
  return (
    <StyledSelect
      {...props}
      IconComponent={FiChevronDown}
      innerRef={ref}
      MenuProps={{ disableScrollLock: true }}
      variant="filled"
      input={<AppInput disabled={props.disabled} />}>
      {props.children}
    </StyledSelect>
  );
});
