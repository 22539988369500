import { Box, Typography, Grid, Paper, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

export const useStyles = makeStyles((theme: Theme) => ({
  bold: {
    fontWeight: 600,
  },
  regularBold: {
    fontWeight: 400,
  },
  smallText: {
    fontSize: '13px',
    lineHeight: '22px',
  },

  wrapper: {
    padding: theme.spacing(3),
    minHeight: 86,
    borderRadius: '8px',
    position: 'relative',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
}));

const InfoItem = ({
  title,
  value,
  isBold = false,
}: {
  title: string;
  value: number | string;
  isBold?: boolean;
}) => {
  const classes = useStyles();
  return (
    <Box display="flex" gridGap={4}>
      <Typography variant="subtitle2" className={clsx(classes.smallText, { [classes.bold]: isBold })}>
        {title}:
      </Typography>
      <Typography variant="subtitle2" className={clsx(classes.smallText)}>
        {value}
      </Typography>
    </Box>
  );
};

export const TimeTotalInfo = ({
  totalPaid,
  overtime,
  basic,
  workers,
  totalClockedHours,
  totalAdjustments,
  period,
}: {
  totalPaid: number;
  overtime: number;
  basic: number;
  workers: number;
  totalClockedHours: number;
  totalAdjustments: number;
  period?: string;
}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={5} lg={4}>
        <Paper className={classes.wrapper} variant="outlined">
          <Box display="grid" gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))">
            <Box display="flex" flexDirection="column" gridGap={8}>
              {period && <InfoItem title="Period" value={period} />}
              <InfoItem title="Total team members working" value={workers} />
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} md={7} lg={8}>
        <Paper className={classes.wrapper} variant="outlined">
          <Box display="grid" gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))">
            <Box display="flex" flexDirection="column" gridGap={8}>
              <InfoItem title="Total Clocked Hours" value={totalClockedHours} />
              <InfoItem title="Total Adjustments" value={totalAdjustments} />
            </Box>
            <Box display="flex" flexDirection="column" gridGap={8}>
              <InfoItem title="Total Paid Regular Hours" value={basic} />
              <InfoItem title="Total Paid Overtime Hours" value={overtime} />
              <InfoItem title="Total Paid Hours" value={totalPaid} isBold />
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};
