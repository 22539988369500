import { ManualClockProps } from '@vyce/core/src/types';

export const defaultValues: ManualClockProps = {
  action: 'in',
  timestamp: 'now',
  verifiedLocation: true,
};

export const stepNames = {
  SHIFT_STEP: 'ShiftStep',
  UPLOAD_PICTURE_STEP: 'UploadPictureStep',
  LOCATION_STEP: 'LocationStep',
  WHEN_CLOCK_STEP: 'WhenClockStep',
  LOCATION_VERIFIED_STEP: 'LocationVerifiedStep',
  WORKER_NAME_STEP: 'WorkerNameStep',
  DOYOUWANTTOCLOCK_STEP: 'DoYouWantToClockStep',
};

export const clockOutHiddenSteps = [
  stepNames.SHIFT_STEP,
  stepNames.LOCATION_STEP,
  stepNames.LOCATION_VERIFIED_STEP,
];

export const clockInText = 'has been manually clocked in. Don’t forget to clock them out when they leave.';
export const clockOutText = 'has been manually clocked out.';
