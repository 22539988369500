import React from 'react';

import { Box, Typography, useTheme } from '@material-ui/core';
import capitalize from 'lodash/capitalize';

interface Props {
  leftText: string;
  middleElements?: string[];
  rightElements?: React.ReactElement[];
}

export const ShiftInfoRaw: React.FC<Props> = ({ leftText, middleElements, rightElements }) => {
  const theme = useTheme();

  return (
    <Box
      border={`1px solid ${theme.palette.divider}`}
      borderRadius="8px"
      display="flex"
      justifyContent="space-between"
      padding="8px 16px">
      <Box flex={1}>
        <Typography style={{ lineHeight: '32px' }} variant="subtitle2">
          {leftText}
        </Typography>
      </Box>

      <Box flex={1} display="flex" justifyContent="center" flexDirection="column" gridGap={16}>
        {middleElements?.map((element, index) => (
          <Typography key={'midEl' + index} style={{ lineHeight: '32px' }} variant="subtitle2">
            {capitalize(element)}
          </Typography>
        ))}
      </Box>

      <Box flex={1} display="flex" justifyContent="center" flexDirection="column" gridGap={16}>
        {rightElements?.map((element, index) => (
          <Box key={'el' + index} height={32} display="flex" alignItems="center" justifyContent="flex-end">
            {element}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
