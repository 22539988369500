import React from 'react';

import { Box } from '@material-ui/core';

import { AppTextField } from '@vyce/core/src/components/inputs';

interface RateCellProps {
  disabled: boolean;
  label: string;
  rateType: 'basic' | 'overtime';
  shiftId: string;
  defaultValue: string | number;
  onChange: (arg0: { amount: string; type: 'basic' | 'overtime'; shiftId: string }) => void;
}

export const RateCell = ({ label, rateType, disabled, shiftId, defaultValue, onChange }: RateCellProps) => {
  const handleRateChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onChange({ type: rateType, shiftId, amount: e.target.value });
  };
  return (
    <Box width="80%">
      <AppTextField
        type="number"
        disabled={disabled}
        label={label}
        onChange={handleRateChange}
        defaultValue={defaultValue}
      />
    </Box>
  );
};
