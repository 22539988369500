import React from 'react';

import { GridColDef } from '@mui/x-data-grid';
import { Box } from '@material-ui/core';
import dayjs from 'dayjs';

import { formatTableDate, getUKFormattedDate } from '@vyce/core/src/utils/dates';
import { AppA, AppLink, GridCellWithAvatar } from '@vyce/core/src/components';
import { getAvatar } from '@vyce/core/src/utils/getAvatar';
import { StatusComponents } from '@vyce/core/src/views/payroll/components';
import { currencyFormatter } from '@vyce/core/src/utils';

export const maxCountOfLegend = 4;

export const BIRTHDAYS_TABLE_COLUMNS: GridColDef[] = [
  {
    field: 'first_name',
    headerName: 'Name',
    flex: 0.2,
    minWidth: 140,
    disableColumnMenu: true,
    renderCell: params => (
      <GridCellWithAvatar
        avatarUrl={params.row.avatar}
        avatarPlaceholder={getAvatar(params.row.gender)}
        link={`/teams/dashboard/${params.row?.first_name}_${params.row?.user_id}`}
        name={`${params.row.first_name} ${params.row.last_name}`}
      />
    ),
  },
  {
    field: 'current_age',
    headerName: 'Age',
    flex: 0.1,
    minWidth: 100,
    disableColumnMenu: true,
  },
  {
    field: 'date_of_birth',
    headerName: 'Date of Birth',
    flex: 0.15,
    minWidth: 120,
    valueGetter: params => formatTableDate(params.row?.date_of_birth),
    disableColumnMenu: true,
  },
  {
    field: 'email',
    headerName: 'Email',
    minWidth: 270,
    renderCell: params => <AppA content={params.row.email} href={`mailto: ${params.row.email}`} />,
    disableColumnMenu: true,
  },
  {
    field: 'phone',
    headerName: 'Phone Number',
    minWidth: 180,
    disableColumnMenu: true,
    renderCell: params => <AppA content={params.row.phone} href={`tel: ${params.row.phone}`} />,
  },
];
export const RECENT_EMPLOYEES_TABLE_COLUMNS: GridColDef[] = [
  {
    field: 'first_name',
    headerName: 'Name',
    flex: 0.3,
    minWidth: 140,
    disableColumnMenu: true,
    renderCell: params => (
      <GridCellWithAvatar
        avatarUrl={params.row.avatar}
        avatarPlaceholder={getAvatar(params.row.gender)}
        link={`/teams/dashboard/${params.row?.first_name}_${params.row?.user_id}`}
        name={`${params.row.first_name} ${params.row.last_name}`}
      />
    ),
  },
  {
    field: 'ni_number',
    flex: 0.2,
    headerName: 'Ref #',
    minWidth: 130,
    disableColumnMenu: true,
  },
  {
    field: 'email',
    flex: 0.3,
    headerName: 'Email',
    minWidth: 270,
    renderCell: params => <AppA content={params.row.email} href={`mailto: ${params.row.email}`} />,
    disableColumnMenu: true,
  },
  {
    field: 'phone',
    flex: 0.2,
    headerName: 'Phone Number',
    minWidth: 180,
    disableColumnMenu: true,
    renderCell: params => <AppA content={params.row.phone} href={`tel: ${params.row.phone}`} />,
  },
  {
    field: 'registered_at',
    headerName: 'Registration Date',
    flex: 0.2,
    disableColumnMenu: true,
    valueGetter: params => getUKFormattedDate(params.row?.registered_at),
    sortComparator: (v1, v2) => {
      const date1 = dayjs(v1 as string, 'DD/MM/YY');
      const date2 = dayjs(v2 as string, 'DD/MM/YY');
      return date1.diff(date2);
    },

    minWidth: 150,
  },
];

export const getTimesheetsTableColumns = (companyId: string): GridColDef[] => [
  {
    field: 'name',
    headerName: 'Name',
    flex: 0.2,
    hideSortIcons: true,
    sortable: false,
    disableColumnMenu: true,
    minWidth: 150,
    renderCell: params => (
      <Box display="flex" width="100%">
        <AppLink
          to={`/payroll/timesheets/${params.row?.pay_schedule_name}_${params.row?.pay_run_id}_${companyId}`}>
          {params.row?.pay_schedule_name}
        </AppLink>
      </Box>
    ),
  },
  {
    field: 'tax_week_end',
    headerName: 'Week',
    flex: 0.1,
    disableColumnMenu: true,
    minWidth: 90,
  },
  {
    field: 'payment_date',
    valueGetter: params => formatTableDate(params.row?.payment_date),
    headerName: 'Pay Date',
    flex: 0.2,
    editable: false,
    minWidth: 150,
  },
  {
    field: 'status',
    headerName: 'Status',
    renderCell: params => (
      <StatusComponents status={params.row.status} isGreen={params.row.status === 'submitted'} />
    ),
    flex: 0.2,
    hideSortIcons: true,
    sortable: false,
    disableColumnMenu: true,
    minWidth: 90,
  },
  {
    field: 'total_gross',
    headerName: 'Gross',
    flex: 0.1,
    valueFormatter: params => currencyFormatter.format(params.row.total_gross),
    editable: false,
    minWidth: 150,
  },
  {
    field: 'total_net',
    headerName: 'Net',
    valueFormatter: params => currencyFormatter.format(params.row.total_net),
    flex: 0.1,
    editable: false,
    minWidth: 150,
  },
];
