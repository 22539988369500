import React from 'react';

import { Box, Button } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

import { OnboardingStepTitle } from '@vyce/core/src/components/';
import { ControlledDateField } from '@vyce/core/src/components/controlled-inputs';
import { formatDate } from '@vyce/core/src/utils/dates';

import { TitleProps } from './types';

const styles = { marginLeft: 8, height: 56 };

export const StartDateStep = ({ title = "What's your start date?" }: TitleProps) => {
  const { setValue } = useFormContext();

  const handleClick = () => {
    setValue('effective_date', formatDate(new Date()));
  };

  return (
    <>
      <OnboardingStepTitle text={title} />

      <Box marginTop={2} marginBottom={1} display="flex" alignItems="center">
        <ControlledDateField name="effective_date" label="Start Date" />

        <Button style={styles} onClick={handleClick} variant="contained" color="primary">
          Today
        </Button>
      </Box>
    </>
  );
};
