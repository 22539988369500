import React from 'react';
import { Avatar, useTheme } from "@material-ui/core";

interface Props {
  name: string;
  width?: number;
  height?: number;
  className?: any;
}

export const RandomLogo: React.FC<Props> = ({ name, width = 35, height = 35, className }) => {
  const theme = useTheme();

  return <Avatar className={className} style={{ width, height, backgroundColor: theme.palette.secondary.main }}>
    {name.substring(0, 1).toUpperCase()}
  </Avatar>
}
