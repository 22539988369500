import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Box, Button, MenuItem, MenuList, useTheme } from '@material-ui/core';
import type { GridColDef } from '@mui/x-data-grid';
import { AiOutlinePlus } from 'react-icons/ai';
import { BiCrown } from 'react-icons/bi';
import clsx from 'clsx';
import { AxiosResponse } from 'axios';

import { GRID_PAGE_SIZE, TABLE_OFFSET_DELAY } from '@vyce/core/src/constants';
import { AppA, AppDataGridWithSavedPage, GridActions, GridCellWithAvatar } from '@vyce/core/src/components';
import { Company, Staff } from '@vyce/core/src/types';
import { StaffInviteDialog } from '@vyce/core/src/views/staff-management/components/StaffInviteDialog';
import { useHorizontalScrollStyles } from '@vyce/core/src/styles';
import { AppSearchInput } from '@vyce/core/src/components/inputs';
import { DeviceContext } from '@vyce/core/src/contexts';
import { getAvatar } from '@vyce/core/src/utils/getAvatar';
import { ListStaffRequest, SendInviteRequest } from '@vyce/core/src/api/types';
import { getUKFormattedDate, isNil } from '@vyce/core/src/utils';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';
import { useDebounceValue } from '@vyce/core/src/hooks/useDebounceValue';
import { useTable } from '@vyce/core/src/hooks/useTable';

interface Props {
  selectedCompanyId: string | null;
  companies?: Company[];
  unit?: string;
  withProfileButton?: boolean;
  isLegend?: boolean;
  getStaffRequest: (companyId: string, payload: ListStaffRequest) => Promise<AxiosResponse<any>>;
  editPermissionRequest: (companyId: string, userId: string, data: any) => Promise<AxiosResponse<any>>;
  getRolesRequest: (companyId: string) => Promise<AxiosResponse<any>>;
  sendInviteRequest: (companyId: string, payload: SendInviteRequest) => Promise<AxiosResponse<any>>;
}

export const StaffManagementTable: React.FC<Props> = ({
  selectedCompanyId,
  isLegend,
  companies,
  withProfileButton,
  unit,
  getRolesRequest,
  sendInviteRequest,
  editPermissionRequest,
  getStaffRequest,
}) => {
  const horizontalScrollClasses = useHorizontalScrollStyles();
  const { handleServerError } = useContext(NotificationContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [staff, setStaff] = useState<Staff[]>([]);
  const [selectedUser, setSelectedUser] = useState<Staff>();
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);
  const [closeGridAction, setCloseGridAction] = useState<boolean>(false);
  const { isMobile } = useContext(DeviceContext);
  const { offset, substring, total, setTotal, setOffset, handlePageChange, handleSearchChange } = useTable(
    {}
  );

  const dOffset = useDebounceValue(offset, TABLE_OFFSET_DELAY);

  const resetCloseStatus = () => {
    setCloseGridAction(true);
    setTimeout(() => setCloseGridAction(false), 100);
  };

  const columns: GridColDef[] = [
    {
      field: 'first_name',
      headerName: 'Name',
      renderCell: params => (
        <GridCellWithAvatar
          avatarUrl={params.row.avatar}
          avatarPlaceholder={getAvatar(params.row.gender)}
          tooltip={params.row.owner ? 'Owner' : ''}
          icon={params.row.owner ? <BiCrown size="20px" color={theme.palette.primary.main} /> : undefined}
          name={`${params.row.first_name} ${params.row.last_name}`}
          link={
            isLegend ? `/user-management/admins/${params.row?.first_name}_${params.row?.user_id}` : undefined
          }
        />
      ),
      flex: 10,
      minWidth: 180,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 10,
      minWidth: 270,
      renderCell: params => <AppA content={params.row.email} href={`mailto: ${params.row.email}`} />,
    },
    {
      field: 'phone',
      headerName: 'Phone Number',
      minWidth: 180,
      renderCell: params => <AppA content={params.row.phone} href={`tel: ${params.row.phone}`} />,
    },
    {
      field: 'created_at',
      headerName: 'Reg Date',
      disableColumnMenu: true,
      minWidth: 180,
      flex: 0.1,
      valueGetter: params => getUKFormattedDate(params.row?.created_at),
    },

    {
      field: 'modules.Company',
      headerName: 'Company',
      valueGetter: params => params.row?.modules?.Company,
      flex: 7,
      minWidth: 150,
    },
    {
      field: 'modules.Connect',
      headerName: 'Hiring',
      valueGetter: params => params.row?.modules?.Connect,
      flex: 7,
      minWidth: 150,
    },
    {
      field: 'modules.Payroll',
      headerName: 'Payroll',
      valueGetter: params => params.row?.modules?.Payroll,
      flex: 7,
      minWidth: 150,
    },
    {
      field: 'modules.Teams',
      headerName: 'Teams',
      valueGetter: params => params.row?.modules?.Teams,
      flex: 7,
      minWidth: 150,
    },
    {
      field: 'modules.Time&Attendance',
      headerName: 'Time & Attendance',
      valueGetter: params => params.row?.modules['Time&Attendance'],
      flex: 7,
      minWidth: 190,
    },
    {
      field: '',
      headerName: 'Actions',
      width: 80,
      hideSortIcons: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => (
        <Box display="flex" width="100%">
          <GridActions close={closeGridAction}>
            <MenuList>
              <MenuItem
                onClick={() => {
                  setOpen(true);
                  setSelectedUser(params.row as Staff);
                  resetCloseStatus();
                }}>
                Edit Permission
              </MenuItem>
            </MenuList>
          </GridActions>
        </Box>
      ),
    },
  ];

  const getStaff = useCallback(async () => {
    if (!selectedCompanyId || isNil(dOffset)) return;
    try {
      setLoading(true);
      const res = await getStaffRequest(selectedCompanyId, {
        substring,
        limit: GRID_PAGE_SIZE,
        offset: dOffset as number,
      });
      setStaff(res.data.items);
      setTotal(res.data?.count || 0);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  }, [dOffset, selectedCompanyId, substring]);

  useEffect(() => {
    getStaff();
  }, [getStaff]);

  return (
    <>
      <Box
        marginBottom={2}
        gridGap={16}
        className={clsx(horizontalScrollClasses.blockWrapper, horizontalScrollClasses.blockWithHideScroll)}>
        <Box>
          <AppSearchInput onChange={handleSearchChange} isSmall expanded={!isMobile} />
        </Box>
        <Box>
          <Button
            size="small"
            onClick={() => setOpen(true)}
            startIcon={<AiOutlinePlus />}
            variant="contained"
            color="primary">
            Invite Admin
          </Button>
        </Box>
      </Box>

      <AppDataGridWithSavedPage
        noPaper
        rows={staff}
        getRowId={row => row.user_id}
        height="calc(100vh - 240px)"
        columns={columns}
        loading={loading}
        rowCount={total}
        paginationMode="server"
        sortingMode="server"
        pageSize={GRID_PAGE_SIZE}
        rowsPerPageOptions={[GRID_PAGE_SIZE]}
        disableSelectionOnClick
        unit={unit}
        withProfileButton={withProfileButton}
        onPageChange={handlePageChange}
        setOffset={setOffset}
      />

      <StaffInviteDialog
        getStaff={getStaff}
        companies={companies}
        setSelectedUser={setSelectedUser}
        selectedUser={selectedUser}
        open={open}
        companyId={selectedUser?.company?.company_id || selectedCompanyId}
        setOpen={setOpen}
        editPermissionRequest={editPermissionRequest}
        getRolesRequest={getRolesRequest}
        sendInviteRequest={sendInviteRequest}
      />
    </>
  );
};
