import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FieldProps } from '../../types';
import { AppTextField } from "../inputs/AppTextField";
import { capitalizeEach } from "../../utils";

export const EmergencyContactNameField: React.FC<FieldProps> = ({ margin = undefined, disabled = false }) => {
  const {
    control,
  } = useFormContext();

  return (
    <Controller
      control={control}
      name="emergency_contact_name"
      render={({ field }) => (
        <AppTextField
          {...field}
          onChange={(e) => field.onChange(capitalizeEach(e.target.value))}
          disabled={disabled}
          label="Emergency Contact Name"
          margin={margin}
          fullWidth
        />
      )}
    />
  );
};
