import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { Box, Paper, Typography } from '@material-ui/core';

import { AppTextField } from '@vyce/core/src/components/inputs/AppTextField';
import { PasswordField } from '@vyce/core/src/components/controlled-inputs/PasswordField';

import { useStyles } from '../styles';

const idRules = {
  required: 'Government Gateway ID is required',
};

const passwordRules = {
  required: 'Government Gateway password is required',
};
export const GovernmentGatewayEditBlock = () => {
  const classes = useStyles();

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Paper variant="outlined" className={classes.wrapper}>
      <Box display="flex" flexDirection="column" gridGap={24}>
        <Typography variant="h6">Government Gateway</Typography>

        <Box display="flex" flexDirection="column" gridGap={16}>
          <Controller
            control={control}
            rules={idRules}
            name="sender_id"
            render={({ field }) => (
              <AppTextField
                label="Government Gateway ID"
                fullWidth
                {...field}
                error={!!errors?.sender_id?.message}
                helperText={(errors?.sender_id?.message as string) || ''}
              />
            )}
          />

          <PasswordField name="password" label="Government Gateway Password" rules={passwordRules} />
        </Box>
      </Box>
    </Paper>
  );
};
