import React, { useContext, useEffect, useState } from 'react';

import { AiOutlinePlus } from 'react-icons/ai';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { AxiosResponse } from 'axios';

import {
  AdditionalDocument,
  IDVerification,
  Passport,
  RTWProps,
  RTWVerification,
  User,
} from '@vyce/core/src/types';
import { PageLoading } from '@vyce/core/src/components';
import { getAdditionalDocumentsRequest } from '@vyce/core/src/api/legend/checkers';
import { DeviceContext } from '@vyce/core/src/contexts';

import { RtwVerificationCard } from '../components/RtwVerificationCard';
import { PassportVerificationCard } from '../components/PassportVerificationCard';
import { IDVerificationCard } from '../components/IDVerificationCard';
import { AddDocumentDialog } from '../components/AddDocumentDialog';
import { AdditionalDocumentCard } from '../components/AdditionalDocumentCard';
import { MessageUserAboutDocs } from '../components/MessageUserAboutDocs';
import useStyles from '../styles';

interface Props {
  getRtwRequest: Function;
  getPassportRequest: Function;
  updateRTWRequest?: (userId: string, rtwVerification: Partial<RTWVerification>) => Promise<AxiosResponse>;
  triggerCheckerRequest: Function;
  uploadPassportRequest: (scan: File, userId?: string) => void;
  uploadSelfieRequest: (selfie: File, userId?: string) => void;
  user: User;
  getUser: () => Promise<void>;
  isLegend?: boolean;
}

export const Documents: React.FC<Props> = ({
  user,
  getRtwRequest,
  getPassportRequest,
  updateRTWRequest,
  triggerCheckerRequest,
  uploadPassportRequest,
  uploadSelfieRequest,
  isLegend = false,
  getUser,
}) => {
  const classes = useStyles();
  const [passport, setPassport] = useState<Passport>();
  const [rtw, setRtw] = useState<RTWProps>();
  const [loading, setLoading] = useState<boolean>(true);
  const [uploadPassportDialogOpen, setUploadPassportDialogOpen] = useState<boolean>(false);
  const [idVerification, setIDVerification] = useState<IDVerification | undefined>(user?.id_verification);
  const [additionalDocuments, setAdditionalDocuments] = useState<AdditionalDocument[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const { isMobile } = useContext(DeviceContext);

  const getAdditionalDocuments = async () => {
    if (!isLegend) {
      return;
    }
    try {
      const res = await getAdditionalDocumentsRequest(user.uuid);
      setAdditionalDocuments(res.data.items);
    } catch (e) {
      console.error(e);
    }
  };

  const getRtw = async () => {
    try {
      const rtwRes = await getRtwRequest();
      setRtw(rtwRes.data.items[0]);
    } catch (e) {
      console.error(e);
    }
  };

  const getPassport = async () => {
    try {
      const passportRes = await getPassportRequest({ userId: user.uuid });
      setPassport(passportRes.data);
    } catch (e) {
      console.error(e);
    }
  };

  const getDocuments = async () => {
    setLoading(true);
    await getPassport();
    await getRtw();
    await getAdditionalDocuments();
    setLoading(false);
  };

  useEffect(() => {
    getDocuments();
  }, []);

  return (
    <>
      <Box height="100%">
        {loading ? (
          <Box height="calc(100vh - 200px)">
            <PageLoading />
          </Box>
        ) : (
          <Box paddingBottom={10}>
            <Grid
              container
              spacing={3}
              style={{ flexDirection: isMobile && isLegend ? 'column-reverse' : undefined }}>
              <Grid item xs={12} md={6} lg={4}>
                <IDVerificationCard
                  isLegend={isLegend}
                  setUploadPassportDialogOpen={setUploadPassportDialogOpen}
                  setIDVerification={setIDVerification}
                  idVerification={idVerification}
                  userId={user.uuid}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <RtwVerificationCard
                  isLegend={isLegend}
                  updateRTWRequest={updateRTWRequest}
                  rtwVerification={user.rtw_verification}
                  triggerCheckerRequest={triggerCheckerRequest}
                  userId={user.uuid}
                  getUser={getUser}
                  rtw={rtw}
                  setRtw={setRtw}
                  shareCode={user.context?.share_code}
                  dateOfBirth={user.date_of_birth as string}
                />
              </Grid>

              {isLegend && (
                <Grid item xs={12} lg={4}>
                  <Box display="flex" justifyContent="flex-end">
                    <MessageUserAboutDocs
                      userName={`${user.first_name} ${user.last_name}`}
                      userId={user.uuid}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>

            <Box marginTop={3} marginBottom={2}>
              <Typography className={classes.tabTitle}>Documents</Typography>
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <PassportVerificationCard
                  triggerCheckerRequest={triggerCheckerRequest}
                  uploadPassportRequest={uploadPassportRequest}
                  uploadSelfieRequest={uploadSelfieRequest}
                  updatePassportManuallyCallback={getUser}
                  userId={user.uuid}
                  isLegend={isLegend}
                  passport={passport}
                  setUploadDialogOpen={setUploadPassportDialogOpen}
                  uploadDialogOpen={uploadPassportDialogOpen}
                />
              </Grid>
            </Grid>

            {isLegend && (
              <Box>
                <Box
                  marginTop={3}
                  marginBottom={2}
                  display="flex"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center">
                  <Typography className={classes.tabTitle}>Additional Documents</Typography>

                  <Button
                    onClick={() => setOpen(true)}
                    startIcon={<AiOutlinePlus />}
                    color="primary"
                    variant="contained">
                    Add Document
                  </Button>
                </Box>
                <Grid container spacing={3}>
                  {additionalDocuments.map(doc => (
                    <Grid key={doc.uuid} item xs={12} md={6} lg={4}>
                      <AdditionalDocumentCard
                        getAdditionalDocuments={getAdditionalDocuments}
                        document={doc}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          </Box>
        )}
      </Box>

      <AddDocumentDialog
        getAdditionalDocuments={getAdditionalDocuments}
        userId={user.uuid}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};
