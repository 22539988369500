import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  subscribeButton: {
    backgroundColor: theme.palette.success.main,
    width: 177,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  moduleContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
