import React from 'react';

import { OnboardingStepTitle } from '../../../../components';
import { LocationNameField } from '../../../../components/controlled-inputs/LocationNameField';

export const LocationNameStep: React.FC = () => {
  return (
    <>
      <OnboardingStepTitle text="Finally, please give this location a name that you and the team will recognise." />

      <LocationNameField />
    </>
  );
};
