import { axios } from '@vyce/core/src/api';
import { CustomCertificate, CustomQualificationRequestData, QualificationsDTO } from '@vyce/core/src/types';

import { CreateAdditionalDocument, GetUserData, TriggerChecker, UploadRTWDocumentRequest } from '../types';
import { RTWVerification } from '../../types';

export const getRtwRequest = ({ userId }: GetUserData) => {
  return axios.get(`/admin/checkers/documents/users/${userId}/rtw`);
};

export const updateRtwRequest = (userId: string, rtwVerification: Partial<RTWVerification>) => {
  return axios.patch(`/admin/checkers/documents/users/${userId}/rtw_verification`, rtwVerification);
};

export const triggerCheckerRequest = ({ task_name, parameters, userId }: TriggerChecker) => {
  const data = {
    task_name,
    parameters,
  };
  return axios.post(`/admin/checkers/users/${userId}`, data);
};

export const getQualificationsRequest = ({
  userId,
  parameters,
}: {
  userId: string;
  parameters?: { limit: number };
}) => {
  return axios.get(`/admin/checkers/qualifications/users/${userId}`, {
    params: parameters,
  });
};

export const getCertificatesListRequest = ({
  userId,
  parameters,
}: {
  userId: string;
  parameters?: { limit: number };
}) => {
  return axios.get<QualificationsDTO['certificates']>(
    `/admin/checkers/qualifications/users/${userId}/certificates`,
    {
      params: parameters,
    }
  );
};

export const getTestsListRequest = ({
  userId,
  parameters,
}: {
  userId: string;
  parameters?: { limit: number };
}) => {
  return axios.get<QualificationsDTO['tests']>(`/admin/checkers/qualifications/users/${userId}/tests`, {
    params: parameters,
  });
};
export const getCoursesListRequest = ({
  userId,
  parameters,
}: {
  userId: string;
  parameters?: { limit: number };
}) => {
  return axios.get<QualificationsDTO['courses']>(`/admin/checkers/qualifications/users/${userId}/courses`, {
    params: parameters,
  });
};

export const getUserCheckersRequest = (userId: string) => {
  return axios.get(`/admin/checkers?user_id=${userId}`);
};

export const createAdditionalDocumentRequest = ({ document, userId, data }: CreateAdditionalDocument) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', document);
  bodyFormData.append('title', data?.title || '');
  bodyFormData.append('doc_type', data?.doc_type || '');
  bodyFormData.append('description', data?.description || '');
  return axios.post(`/admin/checkers/documents/users/${userId}/additional_documents`, bodyFormData);
};

export const getAdditionalDocumentsRequest = (userId: string) => {
  return axios.get(`/admin/checkers/documents/users/${userId}/additional_documents`);
};

export const deleteAdditionalDocumentRequest = (userId: string, docId: string) => {
  return axios.delete(`/admin/checkers/documents/users/${userId}/additional_documents/${docId}`);
};

export const createRTWDocumentRequest = ({ file, userId }: UploadRTWDocumentRequest) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);
  return axios.post(`/admin/checkers/documents/users/${userId}/rtw`, bodyFormData);
};

export const createCustomQualificationRequest = ({
  data,
  userId,
}: {
  data: CustomQualificationRequestData;
  userId: string;
}) => {
  return axios.post<CustomCertificate>(`/admin/checkers/qualifications/users/${userId}/certificates`, data);
};

export const updateCustomQualificationRequest = ({
  data,
  userId,
  certificateId,
}: {
  data: CustomQualificationRequestData;
  userId: string;
  certificateId: string;
}) => {
  return axios.patch<CustomCertificate>(
    `/admin/checkers/qualifications/users/${userId}/certificates/${certificateId}`,
    data
  );
};

export const deleteCustomQualificationRequest = (certificateId: string, userId: string) => {
  return axios.delete(`/admin/checkers/qualifications/users/${userId}/certificates/${certificateId}`);
};

export const uploadCustomQualificationImageRequest = ({
  file,
  userId,
  certificateId,
}: {
  file: File;
  userId: string;
  certificateId: string;
}) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);

  return axios.post<CustomCertificate>(
    `/admin/checkers/qualifications/users/${userId}/certificates/${certificateId}/document`,
    bodyFormData
  );
};

export const deleteCustomQualificationImageRequest = ({
  userId,
  certificateId,
  documentId,
}: {
  userId: string;
  certificateId: string;
  documentId: string;
}) => {
  return axios.delete<CustomCertificate>(
    `/admin/checkers/qualifications/users/${userId}/certificates/${certificateId}/document/${documentId}`
  );
};

export const hideListedQualificationRequest = (qualificationId: string) => {
  return axios.patch(`/admin/checkers/qualifications/${qualificationId}/hide`);
};
