import React, { useContext, useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { UserPreview } from '@vyce/core/src/views/preview/user/UserPreview';
import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { getRtwRequest } from '@vyce/core/src/api/checkers';
import { getPassportRequest } from '@vyce/core/src/api/documents';
import { Passport } from '@vyce/core/src/types';

import { useTypedSelector } from '../../hooks';

export const Preview: React.FC = () => {
  const { user } = useTypedSelector(state => state);
  const { isMobile } = useContext(DeviceContext);
  const history = useHistory();
  const [rtw, setRtw] = useState<any>();
  const [passport, setPassport] = useState<Passport>();

  const goToEditView = () => {
    history.push('/profile/personal');
  };

  const getRtw = async () => {
    try {
      const rtwRes = await getRtwRequest();
      setRtw(rtwRes.data.items[0]);
    } catch (e) {
      console.error(e);
    }
  };

  const getPassport = async () => {
    try {
      const passportRes = await getPassportRequest();
      setPassport(passportRes.data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getRtw();
    getPassport();
  }, []);

  return (
    <Box padding={isMobile ? 1 : 5}>
      <UserPreview
        passport={passport}
        rtw={rtw}
        goToEditView={goToEditView}
        userPreviewData={{
          user,
          qualifications: user.qualifications,
        }}
      />
    </Box>
  );
};
