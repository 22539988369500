import { useContext } from 'react';

import { Box, Typography } from '@material-ui/core';
import { useDrop } from 'react-dnd';

import { ItemTypes } from '../config';
import { FieldsValuesContext } from './FieldsValuesContext';
import { Field } from './Field';

export const DetailedSummaryBlock = () => {
  const [, drop] = useDrop(() => ({ accept: ItemTypes.DETAILED_SUMMARY }));
  const { detailedSummaryFields } = useContext(FieldsValuesContext);

  return (
    <div ref={drop}>
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Typography variant="subtitle2">
          Customise the ‘Detailed Summary’ tab in your Time Logs Export
        </Typography>
        <Typography variant="caption">
          You can customise the columns in the ‘Detailed Summary’ tab of your Time Log export spreadsheet
          here. Simply drag and position each field in the column order you’d like them on your spreadsheet.
          Only selected fields will be included in the exported file. You can add and edit your custom fields
          in the Custom Location Details Fields section here in Settings.
        </Typography>
        <Box marginTop={1}>
          <Typography variant="subtitle2">
            Select & order the Location Details you want in your Detailed Summary tab:
          </Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gridGap={8}>
        {detailedSummaryFields.map(item => (
          <Field key={item.id} {...item} fieldType={ItemTypes.DETAILED_SUMMARY} />
        ))}
      </Box>
    </div>
  );
};
