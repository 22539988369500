//@ts-nocheck
import React, { useMemo } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import InputMask from 'react-input-mask';

import { FieldProps } from '../../types';
import { AppTextField } from '../inputs';

const getRules = (required: boolean) => ({
  required: required ? 'Account Number is required' : undefined,
  validate: (value: string) => {
    if (!value) return true;
    return value.length === 8 || 'Must be 8 characters long';
  },
});

export const BankAccountNumberField: React.FC<FieldProps> = ({
  margin = undefined,
  disabled = false,
  required = true,
  name = 'bank_account.account_number',
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const rules = useMemo(() => getRules(required), [required]);

  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      render={({ field }) => (
        <InputMask
          {...field}
          mask="9999-9999"
          onChange={e => field.onChange(e.target.value.replace(/-/g, ''))}
          disabled={disabled}
          maskChar={null}>
          {() => (
            <AppTextField
              error={!!errors.bank_account?.account_number?.message}
              disabled={disabled}
              id="account-number"
              label="Account Number"
              margin={margin}
              type="tel"
              fullWidth
              helperText={errors.bank_account?.account_number?.message || ''}
            />
          )}
        </InputMask>
      )}
    />
  );
};
