import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, FormControlLabel } from '@material-ui/core';

import { ControlledDateField, ControlledTagAutocomplete } from '@vyce/core/src/components/controlled-inputs';
import { AppCheckbox, AppTextField } from '@vyce/core/src/components/inputs';
import { Tag } from '@vyce/core/src/types';

import { notRequiredRules, rules } from '../config';

const inputProps = { maxLength: 100 };

export const NotListedIndustryQualificationForm = ({
  tags,
  isAdmin,
  setSubstring,
}: {
  tags: Tag[];
  isAdmin: boolean;
  setSubstring: (arg0: string) => void;
}) => {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const notExpired = watch('not_expired');

  const handleChange = (e: any, checked: boolean) => {
    setValue('not_expired', checked);
  };

  return (
    <>
      <Controller
        control={control}
        rules={rules}
        name="title"
        render={({ field }) => (
          <AppTextField
            {...field}
            id="type"
            inputProps={inputProps}
            label="Qualification Name"
            margin="none"
            fullWidth
            error={!!errors.title?.message}
            helperText={(errors.title?.message as string) || ''}
          />
        )}
      />
      <ControlledTagAutocomplete
        name="extra_text"
        margin="none"
        items={tags}
        label="Issuing Body"
        rules={rules}
        setSubstring={setSubstring}
        manualMode={isAdmin}
      />

      <Controller
        control={control}
        name="reference_number"
        render={({ field }) => (
          <AppTextField
            {...field}
            label="Reference number"
            margin="none"
            fullWidth
            error={!!errors.reference_number?.message}
            helperText={(errors.reference_number?.message as string) || ''}
          />
        )}
      />

      <Box display="flex" alignItems="center" gridGap={24}>
        <ControlledDateField
          name="date"
          label="Expiration Date"
          disablePast
          rules={notExpired ? notRequiredRules : rules}
        />

        <FormControlLabel
          control={
            <AppCheckbox
              color="primary"
              variant="rectangle"
              checked={!!notExpired}
              onChange={handleChange}
            />
          }
          label="Does not expire"
        />
      </Box>
      {/*  TODO will be added later
     <Controller
        control={control}
        name="details"
        render={({ field }) => (
          <AppTextField {...field} id="type" label="Details (optional)" margin="none" fullWidth />
        )}
      /> */}
    </>
  );
};
