import { useContext } from 'react';

import { Box, Typography } from '@material-ui/core';

import { HoldingPage } from '@vyce/core/src/components';

import { ANIMATION_OPTIONS } from '../constants';
import { DeviceContext } from '../../../contexts';

interface Props {
  onStartClick: Function;
}

export const GetStarted = ({ onStartClick }: Props) => {
  const { isMobile } = useContext(DeviceContext);

  const variant = isMobile ? 'subtitle1' : 'body1';

  return (
    <>
      <HoldingPage
        animationOptions={ANIMATION_OPTIONS}
        animationSize={500}
        title="Vyce AI Assistant"
        buttonText="Let's go!"
        handleButtonClick={onStartClick}>
        <Box marginBottom={isMobile ? 2 : 4}>
          <Box marginTop={isMobile ? 1 : 4} display="flex">
            <Typography variant={variant}>
              Imagine having a personal assistant with you wherever you go. And imagine they’re the smartest
              person on the planet. That’d be pretty awesome.
            </Typography>
          </Box>

          <Box marginTop={2}>
            <Typography variant={variant}>
              Well, Vyce’s AI Assistant aims to be just that. Your friendly, helpful, virtual assistant that
              can help with many of your day to day tasks.
            </Typography>
          </Box>
          <Box marginTop={2}>
            <Typography variant={variant}>What tasks…? How about…</Typography>
          </Box>
          <Box marginTop={2}>
            <Typography variant={variant}>
              “Give me a list of the biggest residential projects in London and the south east”
            </Typography>
          </Box>
          <Box marginTop={2}>
            <Typography variant={variant}>
              “Give me three ideas for a birthday present for my daughter who is thirteen and loves cricket.”
            </Typography>
          </Box>
          <Box marginTop={isMobile ? 2 : 3}>
            <Typography variant={variant} style={{ fontWeight: 500 }}>
              What will you ask?
            </Typography>
          </Box>
        </Box>
      </HoldingPage>
    </>
  );
};
