import { createContext } from 'react';

import { GridSortModel } from '@mui/x-data-grid';
import { UseFormReturn } from 'react-hook-form';

import { Image, PublicLink, Team, TeamMember } from '@vyce/core/src/types';
import { CreateTeamData } from '@vyce/core/src/api/types';

interface Context {
  open: boolean;
  logo?: Image;
  total: number;
  loading: boolean;
  closeGridAction: boolean;
  sortModel: GridSortModel;
  teamMembers: TeamMember[];
  substring: string;
  methods: UseFormReturn<CreateTeamData, any>;
  teamData?: Team;
  pathname: string;
  openDeleteTeamDialog: boolean;
  selectionModel: string[];
  selectedCompanyId: string;
  teamId: string;
  transferLoading: boolean;
  transferTotal: number;
  employees: TeamMember[];
  setOpen: Function;
  setLogo: Function;
  setOpenDeleteTeamDialog: Function;
  setSelectionModel: Function;
  isPublicLinkDialogOpen?: boolean;
  publicLink?: PublicLink;
  setOffset: (offset: number) => void;
  handleSearchChange: (event: any) => void;
  handleSortModelChange: (newModel: GridSortModel) => void;
  handlePageChange: (newPage: number) => void;
  handleSubmit: (data: CreateTeamData) => Promise<void>;
  deleteTeam: () => Promise<void>;
  getTeamMembers: () => Promise<void>;
  deleteTeamMembers: (member: string) => Promise<void>;
  handleTransfer: () => Promise<void>;
  changeManager: (id: string, isRemove: boolean) => Promise<void>;
  getNonMembers: ({
    offset,
    substring,
    sortModel,
  }: {
    offset: number;
    substring: string;
    sortModel: GridSortModel;
  }) => Promise<void>;
  getPublicLink?: () => Promise<void>;

  closePublicLinkDialog?: () => void;
}

export const TeamModuleContext = createContext({} as Context);

export const TeamModuleProvider = TeamModuleContext.Provider;
