import React from 'react';

import clsx from 'clsx';
import { Avatar, createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { FiUploadCloud } from 'react-icons/fi';

import { monochrome } from '../theme/styles';

const useStyles = makeStyles<Theme, { coverFit: boolean }>(theme =>
  createStyles({
    avatarContainer: {
      width: 'max-content',
      overflow: 'hidden',
      '&:hover': {
        borderColor: monochrome.dark,
      },
      transition: 'all .2s ease-in-out',
    },
    circular: {
      borderRadius: '50%',
    },
    documentImg: {
      objectFit: props => (props.coverFit ? 'cover' : 'contain'),
    },
    avatar: {
      cursor: 'pointer',
      border: `1px dashed  ${monochrome.dark}`,
      backgroundColor: theme.palette.background.paper,
      minHeight: 40,
      minWidth: 40,
    },
  })
);

interface Props {
  width?: number | string;
  height?: number;
  isDocument?: boolean;
  url?: string;
  coverFit?: boolean;
}

export const ImageView: React.FC<Props> = ({
  isDocument = false,
  height = 100,
  width = 100,
  url,
  coverFit = false,
}) => {
  const classes = useStyles({ coverFit });
  const avatarClass = isDocument ? classes.documentImg : undefined;

  return (
    <div className={isDocument ? classes.avatarContainer : clsx(classes.avatarContainer, classes.circular)}>
      <Avatar
        variant={isDocument ? 'square' : 'circular'}
        style={{
          height: url ? height : 'max-content',
          width: url ? width : 'max-content',
          borderRadius: isDocument && url ? '16px' : '50%',
          border: url ? 'unset' : `1px dashed  ${monochrome.dark}`,
        }}
        classes={{ img: avatarClass }}
        src={url}
        className={classes.avatar}>
        <FiUploadCloud color={monochrome.mediumdark} />
      </Avatar>
    </div>
  );
};
