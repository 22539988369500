export const ItemTypes = {
  CUSTOM: 'exportCustomLocationDetails',
  TIME: 'exportTimeLogs',
  DETAILED_SUMMARY: 'exportDetailedSummary',
} as const;

export const FieldItemTypes = {
  time: 'time_logs_fields',
  detailed_summary: 'detailed_summary_tab_fields',
  location: 'location_fields',
} as const;
