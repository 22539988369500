import React, { useContext } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { TimesheetLine } from '@vyce/core/src/types';
import { MAIN_CONTAINER_ID } from '../../../constants';

interface Props {
  open: boolean;
  closeDialog: Function;
  linesWithErrors: TimesheetLine[];
  clearErrors?: Function;
}

export const ErrorsList: React.FC<{ linesWithErrors: TimesheetLine[] }> = ({ linesWithErrors }) => {
  const theme = useTheme<Theme>();

  return (
    <Box marginTop={1}>
      {linesWithErrors.map((line, i) => (
        <Box key={i}>
          <Typography variant="subtitle2">Worker Ref No: {line.worker_reference}</Typography>
          <Box marginLeft={2}>
            <Box marginBottom={1}>
              <Typography variant="caption" style={{ color: theme.palette.error.main }}>
                {line.error?.message}
              </Typography>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export const LastErrorsDialog: React.FC<Props> = ({ open, closeDialog, linesWithErrors, clearErrors }) => {
  const { isMobile } = useContext(DeviceContext);

  return (
    <Dialog
      fullScreen={isMobile}
      container={document.getElementById(MAIN_CONTAINER_ID)}
      maxWidth="xs"
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          closeDialog();
        }
      }}>
      <DialogTitle>Rows with Errors</DialogTitle>
      <DialogContent>
        <Box marginTop={2} maxHeight={500} overflow="auto">
          <ErrorsList linesWithErrors={linesWithErrors} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button size="large" variant="outlined" onClick={() => closeDialog()}>
          Cancel
        </Button>

        {clearErrors && (
          <Button size="large" color="primary" variant="contained" onClick={() => clearErrors()}>
            Ok, Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
