import React, { useCallback, useEffect, useState } from 'react';

import { Box, Button, Paper, Theme, Typography, useTheme } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import { BiErrorCircle } from 'react-icons/bi';
import { Skeleton } from '@material-ui/lab';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { verifyEmailRequest } from '@vyce/core/src/api/auth';

import config from '../assets/config';

const useStyles = makeStyles(theme =>
  createStyles({
    verificationPaper: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: theme.spacing(3),
      width: '400px',
    },
    title: {
      fontWeight: 600,
      marginBottom: theme.spacing(1),
    },
  })
);

interface Props {
  me: () => (arg: any) => Promise<any>;
}

export const EmailVerification: React.FC<Props> = ({ me }) => {
  const classes = useStyles();
  const { token } = useParams<{ token: string }>();
  const theme = useTheme<Theme>();
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const verifyEmail = useCallback(async () => {
    setLoading(true);
    try {
      await verifyEmailRequest(token);
      await me();
      setLoading(false);
      setIsVerified(true);
    } catch {
      setLoading(false);
      setIsVerified(false);
    }
  }, [token]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      verifyEmail();
    }
    return () => {
      mounted = false;
    };
  }, [verifyEmail]);

  return (
    <Box paddingTop={16} display="flex" justifyContent="center">
      {loading ? (
        <Skeleton variant="rect" width={300} height={229} />
      ) : (
        <Paper variant="outlined" className={classes.verificationPaper}>
          {isVerified ? (
            <>
              <Typography variant="h6" color="primary" className={classes.title}>
                Thanks for verifying your email!
              </Typography>

              <img height={100} alt="Success" src={config.icons3D.shield} />

              <Box marginTop={2} marginBottom={2}>
                <Typography variant="caption" color="textSecondary">
                  You can now get back to business...
                </Typography>
              </Box>
              <Button color="primary" component={Link} to="/dashboard" variant="contained">
                Back To business
              </Button>
            </>
          ) : (
            <BiErrorCircle size="80px" color={theme.palette.error.main} />
          )}

          {!isVerified && (
            <>
              <h2>Error... Please try again.</h2>
              <Button color="primary" onClick={verifyEmail} variant="outlined">
                Try Again
              </Button>
            </>
          )}
        </Paper>
      )}
    </Box>
  );
};
