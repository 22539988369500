import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  widgetContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },

  contentWrapper: {
    display: 'grid',
    width: '100%',
    height: '100%',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 340px))',
    gridGap: '16px',
    justifyContent: 'center',
  },

  hoursDescriptionBlockWrapper: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'repeat(2, minmax(auto, 200px))',
    rowGap: '8px',
    columnGap: '16px',
  },
  numberContainer: {
    padding: '4px 16px',
    borderRadius: '8px',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
  },
  border: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '8px',
  },
  small: {
    fontSize: '13px',
    lineHeight: '22px',
  },
  bold: {
    fontWeight: 600,
  },
}));
