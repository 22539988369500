import React, { useContext } from 'react';

import { Register } from '@vyce/core/src/views/auth/components/Register';
import { RoleSelection } from '@vyce/core/src/views/auth/components/RoleSelection';
import { AuthContext } from '@vyce/core/src/contexts';

import { useActions, useTypedSelector } from '../../../hooks';
import { GENERAL_AUTH_PATHS } from './GeneralAuth';

export const GeneralRegister: React.FC = () => {
  const title = 'Welcome To Vyce';
  const subtitle = 'Please sign up for awesomeness';
  const { setLoading, userRegister } = useActions();
  const { userDataLoading } = useTypedSelector(state => state.helper);
  const { redirectUrl, passedByRoleSelection, setPassedByRoleSelection } = useContext(AuthContext);

  if (!passedByRoleSelection)
    return <RoleSelection onChangePassedByRoleSelection={setPassedByRoleSelection} />;

  return (
    <Register
      title={title}
      verifyPhoneUrl={GENERAL_AUTH_PATHS.VERIFY_PHONE}
      redirectUrl={redirectUrl || '/onboarding'}
      subtitle={subtitle}
      loading={userDataLoading}
      setLoading={setLoading}
      userRegister={userRegister}
    />
  );
};
