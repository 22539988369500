import React from 'react';

import { Avatar, Box, Typography, useTheme } from '@material-ui/core';

import { monochrome } from '../../../../theme/styles';
import useStyles from '../../styles';
import avatarPlaceholder from '../../../../assets/avatar-neutral-placeholder.png';

interface Props {
  avatar?: string | null;
  author: string;
  position: string;
  company: string;
}

export const TestimonialAuthor: React.FC<Props> = ({ avatar, author, position, company }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box display="flex" gridGap={16} alignItems="center">
      <Avatar src={avatar || avatarPlaceholder} className={classes.testimonialAvatar} />

      <Box>
        <Typography variant="subtitle1" style={{ fontWeight: 600, lineHeight: '24px' }}>
          {author}
        </Typography>

        <Typography
          variant="caption"
          style={{
            color: theme.palette.type === 'dark' ? monochrome.light : monochrome.dark,
            fontWeight: 500,
          }}>
          {position}, {company}
        </Typography>
      </Box>
    </Box>
  );
};
