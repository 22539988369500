import React, { useContext, useEffect, useMemo, useRef, useState, useLayoutEffect } from 'react';

import { Box, Divider, makeStyles, Theme } from '@material-ui/core';
import debounce from 'lodash/debounce';
import clsx from 'clsx';

import { DeviceContext } from '@vyce/core/src/contexts';
import { AppTabs, BackTo } from '@vyce/core/src/components';
import { BackToData, TabItem } from '@vyce/core/src/types';

interface StyleProps {
  height?: number;
  permissionToHiding: boolean;
}

type Props = {
  tabItems: TabItem[];
  backTo?: BackToData | null;
  children?: React.ReactNode;
};

const defaultHeight = 48;
const scrollCorrection = 20;
const minScrollValueForShowing = 5;

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  tabsWrapper: {
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-16px',
      paddingRight: props => (props.permissionToHiding ? 16 : 'unset'),
      marginRight: props => (props.permissionToHiding ? 'unset' : '-16px'),
      flexDirection: 'column-reverse',
    },
    position: 'relative',
  },

  filterWrapper: {
    paddingRight: 40,
    maxWidth: '320px',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.background.paper,
    },

    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
      marginRight: 'unset',
      padding: 0,
    },
  },
  backToBlock: {
    backgroundColor: theme.palette.background.paper,
  },
  navBarBlock: { display: 'flex', flexDirection: 'column', width: '100%' },
  navBarBlockMobile: {
    position: 'fixed',
    top: 64,
    left: 16,
    right: 0,
    zIndex: 10,
    transition: 'transform 0.25s',
    [theme.breakpoints.down('xs')]: {
      top: 56,
    },
  },
  navBarBlockHidden: {
    transform: 'translateY(-100%)',
  },
  navBarBlockVisible: {
    transform: 'translateY(0)',
  },

  wrapper: {
    transition: 'padding 0.25s ease-out',
  },
  wrapperVisible: {
    paddingBottom: props => (props.permissionToHiding ? props.height : 'unset'),
  },
  wrapperHidden: {
    padding: 0,
  },
}));

export const CommonNavBarBlock = ({ tabItems, backTo, children }: Props) => {
  const { isMobile } = useContext(DeviceContext);
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [height, setHeight] = useState(defaultHeight);
  const [, updateState] = useState({});
  const ref = useRef<HTMLDivElement>(null);
  const [permissionToHiding, setPermissionToHiding] = useState(false);
  const classes = useStyles({ height: height ?? defaultHeight, permissionToHiding });

  const offsetHeight = document.documentElement.offsetHeight;
  const clientHeight = document.documentElement.clientHeight;

  const controlNavbar = () => {
    if (typeof window !== 'undefined') {
      if (window.scrollY >= lastScrollY && permissionToHiding) {
        setShow(false);
      } else {
        setShow(true);
      }
      setLastScrollY(window.scrollY);
    }
  };

  const debouncedControlNavbar = useMemo(
    () => debounce(controlNavbar, 10),
    [lastScrollY, permissionToHiding]
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', debouncedControlNavbar);

      return () => {
        window.removeEventListener('scroll', debouncedControlNavbar);
      };
    }
  }, [lastScrollY]);

  useEffect(() => {
    setTimeout(() => updateState({}), 300);
  }, [height, tabItems, backTo, children]);

  useLayoutEffect(() => {
    setPermissionToHiding(offsetHeight - clientHeight > height + scrollCorrection);
  }, [height, tabItems, backTo, children, offsetHeight, clientHeight]);

  useEffect(() => {
    setHeight(ref?.current?.clientHeight ?? defaultHeight);
  }, [ref?.current?.clientHeight, tabItems, backTo, children]);

  return (
    <Box
      className={clsx({
        [classes.wrapper]: isMobile,
        [classes.wrapperVisible]: show && lastScrollY < minScrollValueForShowing && isMobile,
        [classes.wrapperHidden]: !show && isMobile,
      })}>
      <div
        ref={ref}
        className={clsx(classes.navBarBlock, {
          [classes.navBarBlockMobile]: isMobile && permissionToHiding,
          [classes.navBarBlockVisible]: show && isMobile,
          [classes.navBarBlockHidden]: !show && isMobile,
        })}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          className={classes.tabsWrapper}>
          <AppTabs tabItems={tabItems} />
          {isMobile && children && (
            <Box width="100%" padding="0 16px" marginBottom={isMobile ? 2 : 0}>
              <Divider style={{ height: '2px' }} />
            </Box>
          )}
          {children && (
            <Box className={classes.filterWrapper} display="flex" alignItems="center">
              {children}
            </Box>
          )}
        </Box>
        {backTo && (
          <Box marginLeft="-16px" paddingRight={isMobile ? 2 : 0} className={classes.backToBlock}>
            <Box padding={isMobile ? '0 16px' : 0}>
              <Divider style={{ height: '2px' }} />
            </Box>
            <BackTo backTo={backTo} />
          </Box>
        )}
      </div>
    </Box>
  );
};
