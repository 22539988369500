import { monochrome } from '../../theme/styles';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { leftInfoDrawerWidth } from '../hiring/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'relative',
      borderRadius: theme.spacing(2),
      overflow: 'hidden',
    },
    infoContainer: {
      padding: '0 108px',
      paddingTop: theme.spacing(7),
      paddingBottom: theme.spacing(6),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(5),
        paddingTop: theme.spacing(7),
      },
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2),
      },
    },
    poster: {
      width: '100%',
      height: '134px',
      background:
        'radial-gradient(76.07% 299.73% at 15.62% 82.5%, #00E4FE 0%, #009FFD 39.12%, #BE17FB 100%)',
      [theme.breakpoints.down('xs')]: {
        height: '90px',
        marginBottom: theme.spacing(4),
      },
    },
    editIcon: {
      position: 'absolute',
      right: theme.spacing(3),
      top: theme.spacing(3),
      color: theme.palette.primary.main,
      '&:hover': {
        color: '#fff',
      },
    },
    backIcon: {
      position: 'absolute',
      left: theme.spacing(3),
      top: theme.spacing(3),
      color: theme.palette.primary.main,
      '&:hover': {
        color: '#fff',
      },
    },
    name: {
      fontWeight: 700,
    },
    customButton: {
      color: theme.palette.primary.main,
      background: theme.palette.type === 'dark' ? 'none' : theme.palette.background.paper,
    },
    avatar: {
      position: 'absolute',
      width: '110px',
      height: '110px',
      backgroundColor: theme.palette.background.paper,
      right: 'calc(50% - 60px)',
      top: theme.spacing(9),
      border: `5px solid ${theme.palette.background.paper}`,
      [theme.breakpoints.down('xs')]: {
        top: theme.spacing(4),
        width: '100px',
        height: '100px',
        right: 'calc(50% - 50px)',
      },
    },
    testimonialAvatar: {
      width: '40px',
      height: '40px',
    },
    caseStudyAvatar: {
      width: '67px',
      height: '67px',
      borderRadius: '8px',
    },
    hiringAvatar: {
      width: '48px',
      height: '48px',
    },
    line: {
      borderTop: `2px solid ${theme.palette.divider}`,
    },
    bold: {
      fontWeight: 600,
    },
    smallBold: {
      fontWeight: 500,
    },
    smallText: {
      fontSize: '13px !important',
      lineHeight: '22px',
    },
    tileItem: {
      backgroundColor: theme.palette.background.default,
      borderRadius: '12px',
      minHeight: '96px',
    },
    smallTitleItme: {
      minHeight: 'max-content',
    },
    closeInfoItem: {
      backgroundColor: theme.palette.type === 'dark' ? '#4E4B66' : '#EBECFE',
    },
    iconContainer: {
      height: '72px',
      minWidth: '72px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.secondary.main,
      fontSize: '18px',
      fontWeight: 600,
      borderRadius: '50%',
      [theme.breakpoints.down('xs')]: {
        height: '50px',
        minWidth: '50px',
        fontSize: '16px',
      },
    },
    smallIconContainer: {
      height: '56px',
      minWidth: '56px',

      [theme.breakpoints.down('xs')]: {
        height: '42px',
        minWidth: '42px',
        fontSize: '16px',
      },
    },
    closeIconContainer: {
      height: '48px',
      width: '48px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '12px',
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: theme.palette.background.paper,
    },
    viewAll: {
      cursor: 'pointer',
      fontWeight: 600,
    },
    skill: {
      padding: '8px 16px',
      backgroundColor: theme.palette.background.paper,
      borderRadius: '8px',
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      textTransform: 'capitalize',
      fontSize: '15px',
      lineHeight: '24px',
      color: theme.palette.type === 'dark' ? monochrome.lighter : monochrome.dark,
    },
    hiringSkill: {
      padding: '3px 8px',
      marginRight: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
      backgroundColor: theme.palette.background.default,
    },
    endorsementsContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    backButton: {
      position: 'absolute',
      right: 0,
    },
    bottomButtonContainer: {
      position: 'fixed',
      display: 'flex',
      bottom: 0,
      backgroundColor: theme.palette.background.paper,
      width: leftInfoDrawerWidth,
      [theme.breakpoints.down('sm')]: {
        width: '100vw',
      },
    },
    separator: {
      width: '100%',
      borderBottom: '2px solid #EFF0F6',
      height: '15px',
      padding: '8px',
    },
    verticalSeparator: {
      borderLeft: '0.5px solid #D9DBE9',
      borderRight: '0.5px solid #D9DBE9',
      margin: '8px 32px',
    },
    grayscaleBodyColor: {
      color: theme.palette.text.disabled,
    },
    verifiedLabelWrapper: {
      position: 'absolute',
      top: theme.spacing(1.5),
      right: theme.spacing(2),
    },
    bigTextWrap: {
      wordBreak: 'break-word',
      whiteSpace: 'normal',
    },
    warningText: {
      color: theme.palette.warning.main,
    },
    ellipcedText: {
      width: '65%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('xs')]: {
        whiteSpace: 'normal',
      },
    },
    unverifiedText: {
      width: '60%',
    },
    hiringEllipcedText: {
      width: '50%',
    },
    outlinedIDButton: {
      backgroundColor: theme.palette.background.paper,
      width: '100%',
    },
    styledLink: {
      background: theme.palette.type === 'dark' ? 'none' : theme.palette.background.paper,
      border: `2px solid ${theme.palette.type === 'dark' ? monochrome.dark : monochrome.mediumlight}`,
      borderRadius: theme.spacing(1),
      color: theme.palette.primary.main,
      width: '100%',
    },
    recordWrapper: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    infoItemLabel: {
      lineHeight: '24px',
      fontSize: '15px',
      fontWeight: 600,
      flex: 1,
    },
    infoItemValue: {
      marginLeft: 4,
      lineHeight: '24px',
      fontWeight: 500,
      color: theme.palette.type === 'dark' ? monochrome.light : monochrome.dark,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },
    socialIconContainer: {
      margin: '0 6px',
      backgroundColor: theme.palette.background.paper,
      padding: 6,
      borderRadius: 16,
      height: 'fit-content',
    },
    photo: {
      width: 70,
      height: 70,
      outline: `1px solid ${theme.palette.background.paper}`,
      backgroundColor: '#BFBEFC',
      color: '#fff',
      marginLeft: '-16px !important',
    },
    carouselPhoto: {
      maxHeight: '70vh',
      objectFit: 'contain',
    },
    carouselButtonNext: {
      position: 'absolute',
      top: '50%',
      right: 16,
      zIndex: 1,
    },
    carouselButtonPrev: {
      position: 'absolute',
      top: '50%',
      left: 16,
      zIndex: 1,
    },
    carouselLegend: {
      padding: '8px 16px',
      position: 'absolute',
      width: '100% ',
      left: 0,
      bottom: 0,
      marginLeft: 0,
      backgroundColor: theme.palette.background.paper,
      opacity: 0,
      transition: 'opacity .35s ease-in-out',
    },
    carouselLegendHovered: {
      opacity: 0.8,
    },
  })
);
