import { useMemo, useEffect, useContext, useState, useCallback } from 'react';

import { DeviceContext } from '../../../contexts/deviceContext';
import { TagGroups, Candidate } from '../../../types';
import { generateFileLinkAndOpen } from '../../../utils';
import { QualificationType, UserPreviewProps } from '../types';
import {
  getWorkerQualifications,
  getPreparedHiringPersonalDetails,
  getPreparedContactDetails,
  getPreparedHiringRightToWorkDetails,
} from '../utils';
import { NotificationContext } from '../../../contexts/notificationContext';

const CONNECTED = 'connected';

interface Props extends Pick<UserPreviewProps, 'showHealthAndSafetyPDFRequest'> {
  candidate: Candidate;
  companyId: string;
}

export const useCandidateData = ({ candidate, companyId, showHealthAndSafetyPDFRequest }: Props) => {
  const { isMobile } = useContext(DeviceContext);
  const { handleServerError } = useContext(NotificationContext);
  const [loadingPDF, setLoadingPDF] = useState<boolean>(false);
  const [formattedQualifications, setFormattedQualifications] = useState<QualificationType[]>([]);
  const { tags, qualifications, computed, address } = candidate;

  const isShowInfo = computed?.connection?.status === CONNECTED;

  const preparedContactDetails = useMemo(
    () => getPreparedContactDetails({ email: candidate?.email ?? '', phone: candidate?.phone ?? '' }),
    [candidate]
  );

  const preparedPersonalDetails = useMemo(
    () =>
      getPreparedHiringPersonalDetails({
        date_of_birth: candidate?.date_of_birth,
        nationality: candidate?.nationality,
        connected: isShowInfo,
      }),
    [candidate, isShowInfo]
  );

  const preparedRightToWorkDetails = useMemo(
    () => getPreparedHiringRightToWorkDetails(candidate),
    [candidate]
  );

  const roles = useMemo(
    () => tags?.filter(tag => tag.group === TagGroups.ROLE)?.map(tag => tag.name),
    [tags]
  );

  const skills = useMemo(
    () => tags?.filter(tag => tag.group === TagGroups.SKILL)?.map(tag => tag.name),
    [tags]
  );

  const downloadPDF = useCallback(async () => {
    try {
      if (!showHealthAndSafetyPDFRequest) {
        return;
      }
      setLoadingPDF(true);
      const res = await showHealthAndSafetyPDFRequest({
        userId: candidate.user_id,
        companyId,
      });
      generateFileLinkAndOpen(res, 'contentType');
      setLoadingPDF(false);
    } catch (e) {
      setLoadingPDF(false);
      handleServerError(e);
    }
  }, []);

  const preparedSurveysDetails = useMemo(() => {
    const { status, total, answered } = candidate?.health_and_safety ?? {};

    return {
      onClick: downloadPDF,
      isShow: !!candidate?.health_and_safety,
      loading: loadingPDF,
      verified: status,
      answered,
      total,
      hasPrivilege: isShowInfo,
    };
  }, [downloadPDF, loadingPDF, isShowInfo, candidate?.health_and_safety]);

  useEffect(() => {
    setFormattedQualifications(getWorkerQualifications(qualifications));
  }, [qualifications]);

  return {
    isMobile,
    roles: roles?.join(', ') ?? '',
    skills,
    country: address?.country ?? '',
    isShowInfo,
    biography: candidate?.biography ?? '',
    formattedQualifications,
    preparedContactDetails,
    preparedPersonalDetails,
    preparedRightToWorkDetails,
    preparedSurveysDetails,
  };
};
