import React, { useEffect, useState } from 'react';
import { Box, Fade, FormControlLabel } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { FieldProps } from '../../../types';
import { AppCheckbox } from '../../../components/inputs';
import { LimitedCompanyNameField } from '../../../components/controlled-inputs/LimitedCompanyNameField';
import { LimitedCompanyNumberField } from '../../../components/controlled-inputs/LimitedCompanyNumberField';
import { UTRNumberField } from '../../../components/controlled-inputs/UTRNumberField';

export const LimitedCompanyInputs: React.FC<FieldProps> = ({ disabled = false }) => {
  const { control, getValues, setValue } = useFormContext();
  const [isLimitedCompany, setIsLimitedCompany] = useState<boolean>(getValues('isLimitedCompany'));

  useEffect(() => {
    if (!isLimitedCompany) {
      setValue('company_name', null);
      setValue('company_reg_number', null);
      setValue('company_utr', null);
    }
  }, [isLimitedCompany]);

  return (
    <>
      <Box display="flex" width="100%" marginTop={1}>
        <Controller
          name="isLimitedCompany"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <AppCheckbox
                  onChange={e => {
                    const checked = e.target.checked;
                    field.onChange(checked);
                    setIsLimitedCompany(checked);
                  }}
                  disabled={disabled}
                  color="primary"
                  checked={field.value}
                />
              }
              label="Are you in a Limited Company?"
            />
          )}
        />
      </Box>

      {isLimitedCompany && (
        <Fade timeout={500} in={true}>
          <Box style={{ width: '100%' }}>
            <LimitedCompanyNameField />

            <LimitedCompanyNumberField />

            <UTRNumberField
              showHint={false}
              margin="normal"
              label="Limited Company UTR"
              name="company_utr"
            />
          </Box>
        </Fade>
      )}
    </>
  );
};
