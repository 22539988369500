import axios from 'axios';
import config from '../config';

export const getWeatherForecastRequest = ({
  latitude,
  longitude,
}: {
  latitude: number;
  longitude: number;
}) => {
  return axios.get(
    `${config.weatherApiUrl}/forecast?latitude=${latitude}&longitude=${longitude}&daily=weathercode,temperature_2m_max&timezone=auto`
  );
};

export const getDayWeatherRequest = ({
  latitude,
  longitude,
  date,
}: {
  latitude: number;
  longitude: number;
  date: string;
}) => {
  return axios.get(
    `${config.weatherApiUrl}/forecast?latitude=${latitude}&longitude=${longitude}&hourly=temperature_2m,precipitation_probability&daily=weathercode,temperature_2m_max,precipitation_probability_max,windspeed_10m_max&start_date=${date}&end_date=${date}&timezone=auto`
  );
};
