//@ts-nocheck
import React from 'react';
import { AppTextField } from '../inputs';
import { Controller, useFormContext } from 'react-hook-form';
import { FieldProps } from '../../types';

export const BillingLine1Field: React.FC<FieldProps> = ({ margin }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      rules={{
        required: 'Address is required',
      }}
      control={control}
      name="address.line1"
      render={({ field }) => (
        <AppTextField
          label="Address"
          margin={margin}
          fullWidth
          {...field}
          error={!!errors?.address?.line1?.message}
          helperText={errors?.address?.line1?.message || ''}
        />
      )}
    />
  );
};
