import React, { MouseEventHandler, useContext } from 'react';
import { Box, Button, Drawer } from '@material-ui/core';
import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import useStyles from '../styles';
import { ButtonTitleWithLoading } from '../../../components/ButtonTitleWithLoading';

interface Props {
  label?: string;
  loading?: boolean;
  startIcon?: React.ReactNode;
  handleClick?: MouseEventHandler<HTMLButtonElement>;
  open?: boolean;
  isDisabled?: boolean;
}

export const SaveButton: React.FC<Props> = ({
  label = 'Save Changes',
  loading = false,
  startIcon,
  open,
  handleClick,
  isDisabled = false,
}) => {
  const classes = useStyles();
  const { isMobile } = useContext(DeviceContext);

  return (
    <Drawer anchor="bottom" variant="permanent" open={open}>
      <Box padding={2} display="flex" justifyContent="flex-end" className={classes.saveButtonContainer}>
        {!!handleClick ? (
          <Button
            className={classes.saveButton}
            disabled={loading || isDisabled}
            fullWidth={isMobile}
            onClick={handleClick}
            cy-test-id="save-changes-button"
            variant="contained"
            startIcon={startIcon}
            color="primary">
            <ButtonTitleWithLoading title={label} loaderVariant="paper" loading={loading} />
          </Button>
        ) : (
          <Button
            className={classes.saveButton}
            disabled={loading || isDisabled}
            fullWidth={isMobile}
            type="submit"
            cy-test-id="save-changes-button"
            startIcon={startIcon}
            variant="contained"
            color="primary">
            <ButtonTitleWithLoading title={label} loaderVariant="paper" loading={loading} />
          </Button>
        )}
      </Box>
    </Drawer>
  );
};
