import React, { useContext, useMemo } from 'react';

import { useTheme } from '@material-ui/core';

import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { AppTour } from '@vyce/core/src/components/AppTour';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';
import { VERIFY_EMAIL_ERROR_OBJECT } from '@vyce/core/src/constants';

import useStyles from '../styles';
import { useTypedSelector, useActions } from '../../../hooks';

const inviteButtonAttr = '[data-tour="invite"]';

const getMobileSteps = <T,>(mobileGeneralStyle: T) => [
  {
    selector: '[data-tour="navigation-menu"]',
    content: 'This is navigation menu.',
    style: mobileGeneralStyle,
  },
  {
    selector: '[data-tour="theme"]',
    content: 'Switch from light or dark view - you decide!',
    style: mobileGeneralStyle,
  },
  {
    selector: '[data-tour="show-tour"]',
    style: mobileGeneralStyle,
    content: 'Just click this icon to see this tour again.',
  },
];

const getEmployeeSteps = <T, K extends { bold: string }>(generalStyle: T, classes: K) => [
  {
    selector: '[data-tour="Dashboard"]',
    content: (
      <AppTour.TourContentItem
        title="Dashboard..."
        text="This is your dashboard where you’ll get a snapshot of all your activity and notifications from Vyce and the Vyce community."
        className={classes.bold}
      />
    ),
    style: generalStyle,
  },
  {
    selector: '[data-tour="Payments"]',
    content: (
      <AppTour.TourContentItem
        title="Payments..."
        text="This is where you can see all your payments & download pay statements."
        className={classes.bold}
      />
    ),
    style: generalStyle,
  },
  {
    selector: '[data-tour="Timesheets"]',
    content: (
      <AppTour.TourContentItem
        title="Timesheets..."
        text="This is where you can see all your timesheets."
        className={classes.bold}
      />
    ),
    style: generalStyle,
  },

  {
    selector: '[data-tour="Profile"]',
    content: (
      <AppTour.TourContentItem
        title="Profile..."
        text="View & edit your profile here."
        className={classes.bold}
      />
    ),
    style: generalStyle,
  },
  {
    selector: '[data-tour="theme"]',
    content: <AppTour.TourContentItem text="Switch from light or dark view - you decide!" />,
    style: generalStyle,
  },
  {
    selector: '[data-tour="show-tour"]',
    style: generalStyle,
    content: <AppTour.TourContentItem text="Just click this icon to see this tour again." />,
  },
];

const getEmployerSteps = <T, K extends { bold: string }>(generalStyle: T, classes: K) => [
  {
    selector: '[data-tour="Dashboard"]',
    content: (
      <AppTour.TourContentItem
        title="Dashboard..."
        text="This is where you’ll get a snapshot of all your activity on Vyce."
        className={classes.bold}
      />
    ),
    style: generalStyle,
  },
  {
    selector: '[data-tour="Hiring"]',
    content: (
      <AppTour.TourContentItem
        title="Hire..."
        text="Promote your jobs for free & connect with relevant, verified professionals in minutes."
        className={classes.bold}
      />
    ),
    style: generalStyle,
  },
  {
    selector: '[data-tour="Teams"]',
    content: (
      <AppTour.TourContentItem
        title="Teams..."
        text="Onboard, verify & manage your teams here. Real time data on your teams - great for compliance & H&S."
        className={classes.bold}
      />
    ),
    style: generalStyle,
  },
  {
    selector: '[data-tour="Projects"]',
    content: (
      <AppTour.TourContentItem
        title="Projects..."
        text="Track project progress & get an ‘at a glance’ snapshot of your project documents, tasks, budgets & timings in one place."
        className={classes.bold}
      />
    ),
    style: generalStyle,
  },
  {
    selector: '[data-tour="Time & Attendance"]',
    content: (
      <AppTour.TourContentItem
        title="Time & Attendance..."
        text="Accurately track, manage & report on your team's time when on location and see who’s working in real time."
        className={classes.bold}
      />
    ),
    style: generalStyle,
  },
  {
    selector: '[data-tour="Payroll"]',
    content: (
      <AppTour.TourContentItem
        title="Payroll..."
        text="Outsource your payroll to Vyce or manage your own payroll through Vyce’s tech powered payroll
    system."
        className={classes.bold}
      />
    ),
    style: generalStyle,
  },
  // TODO uncomment when we will have Messages tab
  // {
  //   selector: '[data-tour="Messages"]',
  //   content: (
  //     <AppTour.TourContentItem
  //       title="Messages..."
  //       text="Keep all work messages in one place. Send & receive messages to teams or individuals securely within Vyce."
  //       className={classes.bold}
  //     />
  //   ),
  //   style: generalStyle,
  // },

  {
    selector: '[data-tour="User Management"]',
    content: (
      <AppTour.TourContentItem
        title="User Management..."
        text="Manage your team members here."
        className={classes.bold}
      />
    ),
    style: generalStyle,
  },
  {
    selector: '[data-tour="Subscription & Billing"]',
    content: (
      <AppTour.TourContentItem
        title="Subscriptions & Billing..."
        text="Manage subscriptions and billing info here."
        className={classes.bold}
      />
    ),
    style: generalStyle,
  },
  {
    selector: '[data-tour="My Companies"]',
    content: (
      <AppTour.TourContentItem
        title="Companies..."
        text="Work at more than one company? Switch between your company accounts here."
        className={classes.bold}
      />
    ),
    style: generalStyle,
  },
  {
    selector: '[data-tour="Profile"]',
    content: (
      <AppTour.TourContentItem
        title="Profile..."
        text="View & edit your profile here."
        className={classes.bold}
      />
    ),
    style: generalStyle,
  },
  {
    selector: '[data-tour="theme"]',
    content: <AppTour.TourContentItem text="Switch from light or dark view - you decide!" />,
    style: generalStyle,
  },
  {
    selector: inviteButtonAttr,
    content: <AppTour.TourContentItem text="Invite your team to Vyce!" />,
    style: generalStyle,
  },
  {
    selector: '[data-tour="show-tour"]',
    style: generalStyle,
    content: <AppTour.TourContentItem text="Just click this icon to see this tour again." />,
  },
];

export const MainAppTour = ({ isEmployer }: { isEmployer: boolean }) => {
  const { showNotification } = useContext(NotificationContext);
  const { showTour } = useTypedSelector(state => state.helper);
  const { email_verified, phone_verified } = useTypedSelector(state => state.user);
  const { setShowTour } = useActions();
  const classes = useStyles();
  const theme = useTheme();
  const { isMobile } = useContext(DeviceContext);

  const generalStyle = useMemo(
    () => ({
      backgroundColor: theme.palette.background.paper,
      borderRadius: '16px',
      padding: theme.spacing(5),
      maxWidth: '500px',
    }),
    [theme]
  );

  const mobileGeneralStyle = useMemo(
    () => ({
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2),
    }),
    [theme]
  );

  const mobileSteps = useMemo(() => getMobileSteps(mobileGeneralStyle), [mobileGeneralStyle]);
  const steps = useMemo(
    () => (isEmployer ? getEmployerSteps(generalStyle, classes) : getEmployeeSteps(generalStyle, classes)),
    [generalStyle, classes, isEmployer]
  );

  //TODO Replace all invite buttons from modules to header
  const filteredSteps = useMemo(() => {
    const hasInviteButton = document.querySelectorAll(inviteButtonAttr).length;
    if (!hasInviteButton) {
      return steps.filter(item => item.selector !== inviteButtonAttr);
    }
    return steps;
  }, [steps]);

  const closeTourCallback = () => {
    if (!email_verified && !phone_verified) {
      showNotification(VERIFY_EMAIL_ERROR_OBJECT);
    }
  };

  return (
    <AppTour
      dialogTitle="That’s it! Thank you - you’re in."
      message="Take a quick tour to find out how to get the most out of Vyce."
      showTour={showTour}
      closeTourCallback={closeTourCallback}
      setShowTour={setShowTour}
      steps={isMobile ? mobileSteps : filteredSteps}
    />
  );
};
