import React from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import { PiRadioButtonFill } from 'react-icons/pi';
import { IoIosRadioButtonOff } from 'react-icons/io';
import { monochrome } from '../../theme/styles';

const itemStyles = {
  padding: '8px 12px',
  fontSize: '13px',
  fontWeight: 500,
  lineHeight: '22px',
  borderRadius: '8px',
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chosen: {
      ...itemStyles,
      backgroundColor: '#BFBEFC',
      width: '100%',
      color: '#2A00A2',
      border: `1px solid ${theme.palette.text.secondary}`,
    },
    empty: {
      ...itemStyles,
      backgroundColor: theme.palette.background.default,
      width: '100%',
      color: theme.palette.text.secondary,
      border: `1px solid ${theme.palette.text.secondary}`,
    },
    root: {
      backgroundColor: 'transparent !important',
      paddingLeft: 0,
      paddingRight: theme.spacing(2),
    },

    roundedRoot: {
      color: theme.palette.primary.main,
      '& .MuiSvgIcon-root': {
        width: '32px',
        height: '32px',
      },

      '&.Mui-checked': {
        color: theme.palette.primary.main,
      },
    },
  })
);

export const AppRadioButton = React.forwardRef((props: RadioProps, ref) => {
  const classes = useStyles();

  return (
    <Radio
      innerRef={ref}
      disableTouchRipple
      disableRipple
      classes={{ root: classes.root }}
      disableFocusRipple
      checkedIcon={<Box className={classes.chosen}>{props.name}</Box>}
      icon={<Box className={classes.empty}>{props.name}</Box>}
      {...props}
    />
  );
});

export const AppRoundedRadio = React.forwardRef((props: RadioProps, ref) => {
  const classes = useStyles();

  return (
    <Radio
      innerRef={ref}
      checkedIcon={<PiRadioButtonFill size="32px" />}
      icon={<IoIosRadioButtonOff size="32px" color={monochrome.mediumlight} />}
      disableTouchRipple
      disableRipple
      classes={{ root: classes.roundedRoot }}
      disableFocusRipple
      {...props}
    />
  );
});
