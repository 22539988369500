import React, { useEffect } from 'react';

import { Box, FormControlLabel, Typography, useTheme } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import capitalize from 'lodash/capitalize';
import uniq from 'lodash/uniq';

import { AppManualTimeInput } from '../../../components/controlled-inputs/AppManualTimeInput';
import { DailySchedule } from '../../../types';
import { isOverlapped } from '../../../utils/dates';
import { AppCheckbox } from '../../../components/inputs';

interface Props {
  day: string;
  scheduleFieldName: string;
  dayToCompare: DailySchedule;
  setOverlappingDays: Function;
}

export const ALL_DAY_START = '00:00';
export const ALL_DAY_END = '23:59';

export const WeekHoursDay: React.FC<Props> = ({
  day,
  scheduleFieldName,
  dayToCompare,
  setOverlappingDays,
}) => {
  const theme = useTheme();
  const { watch, setValue } = useFormContext();
  const dayField = `${scheduleFieldName}.${day}`;
  const startField = `${dayField}.start`;
  const endField = `${dayField}.end`;
  const start = watch(startField);
  const end = watch(endField);
  const isAllDayChecked = start === ALL_DAY_START && end === ALL_DAY_END;

  const handleAllDayCheckboxChange = (checked: boolean) => {
    const options = { shouldDirty: true };
    if (checked) {
      setValue(startField, ALL_DAY_START, options);
      setValue(endField, ALL_DAY_END, options);
    } else {
      setValue(startField, '', options);
      setValue(endField, '', options);
    }
  };

  useEffect(() => {
    if (isOverlapped({ start, end }, dayToCompare)) {
      setOverlappingDays((value: string[]) => uniq([...value, capitalize(day)]));
    } else {
      setOverlappingDays((value: string[]) => value.filter(item => item !== capitalize(day)));
    }
  }, [start, end]);

  return (
    <Box
      borderTop={`1px solid ${theme.palette.divider}`}
      display="flex"
      gridGap={16}
      padding="8px 0"
      alignItems="center">
      <Box flex={1} marginRight={8}>
        <Typography style={{ textTransform: 'capitalize', fontSize: '15px' }} color="textSecondary">
          {day}
        </Typography>
      </Box>

      <Box width={120}>
        <AppManualTimeInput fullWidth name={startField} />
      </Box>

      <Box width={120}>
        <AppManualTimeInput fullWidth name={endField} />
      </Box>

      <Box width={80} display="flex" justifyContent="center" alignItems="center">
        <FormControlLabel
          control={
            <AppCheckbox
              onChange={e => handleAllDayCheckboxChange(e.target.checked)}
              color="primary"
              checked={isAllDayChecked}
            />
          }
          label=""
        />
      </Box>
    </Box>
  );
};
