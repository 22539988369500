import React, { useEffect } from 'react';

import { Box, Fade } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

import { OnboardingStepTitle } from '@vyce/core/src/components';
import { YesNoRadio, VatNumberField } from '@vyce/core/src/components/controlled-inputs';

import { TitleProps } from './types';

export const VatRegisteredStep = ({ title = 'Are you VAT Registered?' }: TitleProps) => {
  const { watch, setValue } = useFormContext();
  const isVatRegistered = watch('isVatRegistered');

  useEffect(() => {
    if (isVatRegistered === 'no') {
      setValue('vat_reg_number', undefined);
    }
  }, [isVatRegistered]);

  return (
    <>
      <OnboardingStepTitle text={title} />

      <YesNoRadio name="isVatRegistered" />

      {isVatRegistered === 'yes' && (
        <Fade timeout={500} in={true}>
          <Box style={{ width: '100%' }} marginTop={2}>
            <VatNumberField />
          </Box>
        </Fade>
      )}
    </>
  );
};
