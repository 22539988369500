import React, { useContext } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Box, useTheme, Typography } from '@material-ui/core';
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';

import { AIChatIdea } from '@vyce/core/src/types';
import { DeviceContext } from '@vyce/core/src/contexts';

import { AIChatContext } from '../context';

interface Props {
  idea: AIChatIdea;
  closeDialog: Function;
  setExpandedIndex: Function;
  expandedIndex: number | null;
  index: number;
}

export const IdeaAccordion: React.FC<Props> = ({
  idea,
  closeDialog,
  expandedIndex,
  setExpandedIndex,
  index,
}) => {
  const theme = useTheme();
  const { isMobile } = useContext(DeviceContext);
  const { addUserMessage } = useContext(AIChatContext);

  return (
    <Accordion expanded={index === expandedIndex}>
      <AccordionSummary expandIcon={<FiChevronDown color="#A0A3BD" size="25px" />}>
        <Box
          onClick={() => setExpandedIndex(index === expandedIndex ? null : index)}
          display="flex"
          width="100%"
          justifyContent="space-between"
          paddingRight="10px">
          <Box display="flex" gridGap={16}>
            <Box width={24}>
              <idea.icon size={24} color={theme.palette.primary.main} />
            </Box>

            <Typography style={{ fontSize: isMobile ? 15 : 18, fontWeight: 500 }}>{idea.title}</Typography>
          </Box>
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <Box display="flex" flexDirection="column" gridGap={16}>
          {idea.requests.map(request => (
            <Box
              style={{ cursor: 'pointer' }}
              onClick={() => {
                addUserMessage(request);
                closeDialog();
              }}
              display="flex"
              alignItems="center"
              key={request}>
              <Box width={20} height={20}>
                <FiChevronRight size={20} color={theme.palette.primary.main} />
              </Box>

              <Typography style={{ marginLeft: 4, marginRight: 8, fontSize: isMobile ? 13 : 15 }}>
                {request}
              </Typography>
            </Box>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
