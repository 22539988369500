import React from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import { AiOutlinePlus } from 'react-icons/ai';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import withScrolling from 'react-dnd-scrolling';
import clsx from 'clsx';

import { ConfirmDialog, CustomPagination, PageLoading } from '@vyce/core/src/components';
import { QualificationDialog } from '@vyce/core/src/modules/qualificationDialog';

import { CustomCertificate } from '../../types';
import { QualificationPreviewItem, QualificationPreviewDialog } from './components';
import { useModuleData } from './hooks';
import { Props } from './types';
import useStyles from './styles';

const ScrollingComponent = withScrolling('div');

export const Qualifications = (props: Props) => {
  const classes = useStyles();

  const {
    isLoading,
    isMobile,
    isOpen,
    isConfirmDialogOpen,
    isPreviewDialogOpen,
    selectedQualification,
    paginationOptions,
    cuttedQualificationListByPage,
    getQualifications,
    handleOpenByAddButton,
    handleOpenByEditButton,
    moveCard,
    setConfirmDialogClose,
    handleDeleteByPreviewButton,
    deleteCustomQualification,
    setPreviewDialogClose,
    handleOpenPreviewDialog,
    setDialogClose,
  } = useModuleData(props);

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems="center"
        justifyContent="space-between"
        className={classes.titleContainer}
        marginTop={isMobile ? 3 : 0}
        marginBottom={3}>
        <Typography variant="h5">Qualifications</Typography>

        <Button
          onClick={handleOpenByAddButton}
          fullWidth={isMobile}
          startIcon={<AiOutlinePlus />}
          color="primary"
          variant="contained">
          Add Qualifications
        </Button>
      </Box>
      <Box height="100%" display="flex" flexDirection="column" gridGap={16}>
        <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
          <ScrollingComponent className={clsx(classes.qualificationListWrapper, classes.dndBlockWrapper)}>
            {cuttedQualificationListByPage.map((item, index) => (
              <QualificationPreviewItem
                data={item}
                index={index}
                key={item.title}
                isAdmin={props.isAdmin ?? false}
                moveCard={moveCard}
                handleDeleteByPreviewButton={handleDeleteByPreviewButton}
                handleOpenPreviewDialog={handleOpenPreviewDialog}
                handleOpenByEditButton={handleOpenByEditButton}
              />
            ))}
          </ScrollingComponent>
        </DndProvider>
        <CustomPagination {...paginationOptions} />
      </Box>

      <QualificationDialog
        isAdmin={props.isAdmin ?? false}
        isOpen={isOpen}
        userId={props.userId}
        onClose={setDialogClose}
        reRequestData={getQualifications}
        selectedQualification={selectedQualification as CustomCertificate}
        getCheckersRequest={props.getCheckersRequest}
        triggerCheckerRequest={props.triggerCheckerRequest}
        createCustomQualificationRequest={props.createCustomQualificationRequest}
        updateCustomQualificationRequest={props.updateCustomQualificationRequest}
        uploadCustomQualificationImageRequest={props.uploadCustomQualificationImageRequest}
        deleteCustomQualificationImageRequest={props.deleteCustomQualificationImageRequest}
        getTagListRequest={props.getTagListRequest}
        type="inside"
      />

      <ConfirmDialog
        handleClose={setConfirmDialogClose}
        open={isConfirmDialogOpen}
        confirmText="Delete"
        cancelText="Cancel"
        title="Are you sure you want to delete this certificate?"
        handleConfirm={deleteCustomQualification}
      />
      <QualificationPreviewDialog
        handleClose={setPreviewDialogClose}
        qualification={selectedQualification}
        open={isPreviewDialogOpen && !!selectedQualification}
      />
    </>
  );
};

Qualifications.QualificationPreviewDialog = QualificationPreviewDialog;
