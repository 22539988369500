import { TabItem } from '@vyce/core/src/types';

export const SUBSCRIPTION_TABS: TabItem[] = [
  {
    label: 'Subscriptions',
    link: '/subscriptions/subscriptions',
  },
  {
    label: 'Billing Info',
    link: '/subscriptions/billing',
  },
  {
    label: 'Payments',
    link: '/subscriptions/payments',
  },
];

export const PAYMENT_STATUSES: any = {
  draft: {
    name: 'Draft',
    description: 'Upcoming invoice.',
  },
  open: {
    name: 'Open',
    description: 'Not yet paid invoice.',
  },
  paid: {
    name: 'Paid',
    description: '',
  },
  uncollectible: {
    name: 'Uncollectible',
    description: 'Failed to be paid. Please update your Billing Info.',
  },
  void: {
    name: 'Void',
    description: 'Cancelled invoice.',
  },
};

export const defaultPaymentValues = {
  cvc: '',
  expiry: '',
  name_on_card: '',
  number: '',
  address: {
    country: '',
    city: '',
    state: '',
    line1: '',
    line2: '',
    postal_code: '',
  },
};

export enum EditOptions {
  BILLING = 'BILLING',
  PAYMENT = 'PAYMENT',
}

export const billingContactSuccessMessage = 'Billing contact has been updated';
export const paymentDetailsSuccessMessage = 'Payment details has been updated';
