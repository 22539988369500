import { axios } from '@vyce/core/src/api';

import { Tag } from '../../types';
import { CreateTagArgs, GetTagsArgs } from '../types';

export const fetchTagsRequest = ({ group = '', offset = 0, limit = 1000, short = true }: GetTagsArgs) => {
  const groupParam = group ? `group=${group}&` : '';
  return axios.get(`/handbook/tags?${groupParam}limit=${limit}&offset=${offset}&short=${short}`);
};

export const fetchNationalitiesRequest = () => {
  return axios.get(`/handbook/nationalities?limit=500&offset=0&short=true`);
};

export const fetchLanguagesRequest = (token: string) => {
  return axios.get(`/handbook/languages?limit=500&offset=0&short=true`);
};

export const createTagRequest = ({ name, group }: CreateTagArgs) => {
  return axios.post(`/handbook/tags`, { name, group });
};

export const getTagListRequest = ({ group, substring }: { group: string; substring?: string }) => {
  return axios.get<{ count: number; items: Tag[] }>(`/handbook/tags`, {
    params: {
      group,
      substring,
    },
  });
};
