import React, { useState } from 'react';
import {
  Chip,
  createStyles,
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Theme,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { AutocompleteProps, Tag } from '../../types';
import { useTheme } from '@material-ui/styles';
import { AppSelect } from '../inputs';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
      height: '25px',
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  })
);

export const ControlledSelect: React.FC<AutocompleteProps> = ({
  items = [],
  label,
  name,
  multiple = false,
  rules,
  margin,
  disabled = false,
  onChange = () => {},
  endAdornment,
}) => {
  const value = multiple ? [] : '';
  const theme = useTheme<Theme>();
  const classes = useStyles();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const [open, setOpen] = useState<boolean>(false);

  const renderValue = (selected: any) => {
    return multiple && Array.isArray(selected) ? (
      <div className={classes.chips}>
        {selected.map((value: Tag | string, index: number) => {
          const name = typeof value === 'string' ? value : value.name;
          return <Chip key={index} label={name} className={classes.chip} />;
        })}
      </div>
    ) : (
      <div>{typeof selected === 'string' ? selected : selected.name}</div>
    );
  };

  const getStyles = (name: string, personName: string[], theme: Theme) => {
    if (!multiple || !personName) {
      return;
    }
    return {
      fontSize: theme.typography.body1.fontSize,
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  };

  const errorMessage = errors[name] && errors[name]?.message;
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => (
        <FormControl fullWidth margin={margin} variant="filled" disabled={disabled}>
          <InputLabel id="app-select-label" error={!!errorMessage}>
            {label}
          </InputLabel>
          <AppSelect
            labelId="app-select-label"
            id={name}
            multiple={multiple}
            value={field.value || value}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            endAdornment={endAdornment}
            onChange={value => {
              field.onChange(value);
              onChange(value);
              setOpen(false);
            }}
            renderValue={renderValue}>
            {items.map((item: any) => {
              const name = typeof item === 'string' ? item : item?.name;
              return name === '' ? (
                <MenuItem key={name} value="">
                  <em>None</em>
                </MenuItem>
              ) : (
                <MenuItem
                  cy-test-id={`menu-item-${name}`}
                  key={name}
                  value={item}
                  style={getStyles(name, field.value || value, theme)}>
                  {name}
                </MenuItem>
              );
            })}
          </AppSelect>
          <FormHelperText error id="select-helper-text">
            {(errorMessage as string) || ''}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};
