import React, { useCallback, useState, useContext } from 'react';

import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { DetailItem } from '@vyce/core/src/types';
import { useBooleanState } from '@vyce/core/src/hooks';
import { DeviceContext } from '@vyce/core/src/contexts';
import { PreviewDocumentDialog } from '@vyce/core/src/components';

import useStyles from '../styles';
import { DataRowItem } from './DataRowItem';
import { DetailsHeader } from './DetailsHeader';

interface Props {
  surname: DetailItem;
  given_names: DetailItem;
  date_of_birth: DetailItem;
  sex: DetailItem;
  expiration_date: DetailItem;
  country: DetailItem;
  number: DetailItem;
  status: DetailItem;
  passportUrl: string;
  selfieUrl: string;
  verified: boolean;
  isShow: boolean;
}

export const IDDetails = ({
  surname,
  given_names,
  date_of_birth,
  selfieUrl,
  sex,
  expiration_date,
  country,
  number,
  status,
  passportUrl,
  verified,
  isShow,
}: Props) => {
  const classes = useStyles();
  const [isImageModalOpen, setImageModalOpen, setImageModalClose] = useBooleanState(false);
  const [selectedImage, setSelectedImage] = useState<string>('');
  const { isMobile } = useContext(DeviceContext);

  const handleOpenPassportImage = useCallback(() => {
    setSelectedImage(passportUrl);
    setImageModalOpen();
  }, [passportUrl]);

  const handleOpenSelfieImage = useCallback(() => {
    setSelectedImage(selfieUrl);
    setImageModalOpen();
  }, [passportUrl]);

  return (
    <Box display="flex" flexDirection="column" gridGap={8}>
      <DetailsHeader title="ID Details" verified={verified} />

      <Box display="flex" gridGap={16} flexWrap={isMobile ? 'wrap' : 'unset'}>
        {isShow && (
          <Box className={clsx(classes.blockWrapper, classes.informationBlock)}>
            <DataRowItem {...surname} />
            <DataRowItem {...given_names} />
            <DataRowItem {...date_of_birth} />
            <DataRowItem {...sex} />
            <DataRowItem {...expiration_date} />
            <DataRowItem {...country} />
            <DataRowItem {...number} />
            <DataRowItem {...status} />
          </Box>
        )}

        {passportUrl && (
          <Box
            display="flex"
            flexDirection="column"
            gridGap={16}
            onClick={handleOpenPassportImage}
            className={clsx(classes.blockWrapper, classes.photoBlock)}>
            <Typography variant="subtitle1" className={classes.bold}>
              Passport Scan
            </Typography>
            <img alt="document" className={classes.passportImage} src={passportUrl} />
          </Box>
        )}

        {selfieUrl && (
          <Box
            display="flex"
            flexDirection="column"
            gridGap={16}
            onClick={handleOpenSelfieImage}
            className={clsx(classes.blockWrapper, classes.photoBlock)}>
            <Typography variant="subtitle1" className={classes.bold}>
              Validation Selfie
            </Typography>
            <img alt="selfie" className={classes.selfieImage} src={selfieUrl} />
          </Box>
        )}
      </Box>

      <PreviewDocumentDialog
        url={selectedImage}
        type="img"
        isOpen={isImageModalOpen && !!selectedImage}
        onClose={setImageModalClose}
      />
    </Box>
  );
};
