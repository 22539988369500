export const getFaceTitle = (inFaceVerified: boolean, outFaceVerified: boolean) => {
  let faceTitle = 'Worker Face Verified';

  if (!inFaceVerified && !outFaceVerified) {
    faceTitle = 'Worker Face Unverified';
  }
  if (!inFaceVerified && outFaceVerified) {
    faceTitle = 'Worker Clock in Face Unverified';
  }
  if (inFaceVerified && !outFaceVerified) {
    faceTitle = 'Worker Clock out Face Unverified';
  }
  return faceTitle;
};
