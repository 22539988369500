import { createContext } from 'react';

interface Context {
  redirectUrl: string;
  passedByRoleSelection?: boolean;
  setPassedByRoleSelection?: (value: boolean) => void;
}

export const AuthContext = createContext({} as Context);
export const AuthProvider = AuthContext.Provider;
