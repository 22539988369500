import { useCallback, useEffect, useState } from 'react';

import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { GRID_PAGE_SIZE } from '../constants';

export const usePageSaverForTables = ({
  pageSize = GRID_PAGE_SIZE,
  setOffset,
}: {
  pageSize?: number;
  setOffset: (offset: number) => void;
}) => {
  const [page, setPage] = useState<number>(0);
  const history = useHistory();

  const setNewPage = useCallback(
    (newPage: number) => {
      setPage(newPage);
      const previousSearchParams = queryString.parse(history.location.search, { parseNumbers: true });
      history.push({
        search: `?${queryString.stringify({ ...previousSearchParams, page: newPage })}`,
      });
    },
    [history]
  );

  useEffect(() => {
    if (history.location.search) {
      const params = queryString.parse(history.location.search, { parseNumbers: true });
      if (params.page) {
        setPage(params.page as number);
        setOffset((params.page as number) * pageSize);
      }
    }
  }, [history.location.search, pageSize, setOffset]);

  return {
    page,
    setNewPage,
  };
};
