import React from 'react';

import { Box, CircularProgress } from '@material-ui/core';

import { PieChart, NumberWidget, BarChart, LineChart, TableWidget } from './components';
import { useWidgetData } from './hooks';

export const WidgetWithData = () => {
  const { data, loading, selectedCompanyId, widgetSystemName, widgetType, handleChartClick } =
    useWidgetData();

  return (
    <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
      {loading ? (
        <CircularProgress size="40px" />
      ) : (
        <>
          {widgetType === 'pie' && <PieChart data={data} handleChartClick={handleChartClick} />}
          {widgetType === 'number' && <NumberWidget data={data} />}
          {widgetType === 'bar' && <BarChart data={data} handleChartClick={handleChartClick} />}
          {widgetType === 'line' && <LineChart data={data} />}
          {widgetType === 'table' && selectedCompanyId && (
            <TableWidget companyId={selectedCompanyId} data={data} systemName={widgetSystemName} />
          )}
        </>
      )}
    </Box>
  );
};
