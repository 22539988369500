import { createContext, useState } from 'react';

import noop from 'lodash/noop';

import { AppNotification } from '../types';

export const generateErrorObject = (error: any) => {
  const detail = error?.response?.data?.detail;
  const backendErrorOptions = detail?.options;
  const notification: AppNotification = {
    message: 'Server Error',
    options: DEFAULT_ERROR_OPTIONS,
  };
  if (typeof detail === 'string') {
    notification.message = detail;
  }
  if (backendErrorOptions?.variant === 'maintenance' && window?.location) {
    const origin = window.location.origin;
    window.location.assign(`${origin}/#/maintenance`);
  }
  if (detail?.message) {
    notification.message = detail.message?.title;
    notification.description = detail.message?.description;
    notification.options.anchorOrigin = backendErrorOptions?.anchorOrigin;
    notification.options.permanent = !!backendErrorOptions?.permanent;
    notification.options.action = backendErrorOptions?.action;
  }
  return notification;
};

const DEFAULT_ERROR_OPTIONS: any = {
  permanent: false,
  variant: 'error',
};

interface Context {
  showNotification: (notification: AppNotification) => void;
  hideNotification: () => void;
  handleServerError: (e: any) => void;
  notification: AppNotification | null;
}

export const NotificationContext = createContext<Context>({
  showNotification: noop,
  hideNotification: noop,
  handleServerError: noop,
  notification: null,
});

const NotificationProvider = ({ children }: { children: any }) => {
  const [notification, setNotification] = useState<AppNotification | null>(null);

  function hideNotification() {
    setNotification(null);
  }

  const showNotification = (notification: AppNotification) => {
    setNotification(notification);
  };

  const handleServerError = (error: any) => {
    console.error(error);
    const notification = generateErrorObject(error);
    showNotification(notification);
  };

  const value = {
    showNotification,
    hideNotification,
    notification,
    handleServerError,
  };

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

export default NotificationProvider;
