import React, { useContext, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { Employee, TimesheetLine } from '@vyce/core/src/types';
import { AppTextField } from '@vyce/core/src/components/inputs/AppTextField';
import { SearchEmployeeAutocomplete } from '@vyce/core/src/views/payroll/components/SearchEmployeeAutocomplete';

import { ButtonTitleWithLoading } from '../../../components/ButtonTitleWithLoading';
import { MAIN_CONTAINER_ID } from '../../../constants';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  open: boolean;
  payrunId: string;
  payScheduleId: string;
  handleClose: Function;
  editTimesheetLineRequest: Function;
  createTimesheetLineRequest: Function;
  lookupEmployeesRequest: Function;
  selectedTimesheetLine?: TimesheetLine;
  companyId: string;
  token: string;
}

export const AddTimesheetLine: React.FC<Props> = ({
  open,
  handleClose,
  payrunId,
  payScheduleId,
  companyId,
  selectedTimesheetLine,
  createTimesheetLineRequest,
  editTimesheetLineRequest,
  lookupEmployeesRequest,
  token,
}) => {
  const { isMobile } = useContext(DeviceContext);
  const methods = useForm<TimesheetLine>({
    defaultValues: {
      employee_id: selectedTimesheetLine?.employee?.uuid,
      basic_units: selectedTimesheetLine?.basic_units || 0,
      basic_rate: selectedTimesheetLine?.basic_rate || 0,
      overtime_units: selectedTimesheetLine?.overtime_units || 0,
      overtime_rate: selectedTimesheetLine?.overtime_rate || 0,
      adjustments: selectedTimesheetLine?.adjustments || 0,
      expenses: selectedTimesheetLine?.expenses || 0,
      miles: selectedTimesheetLine?.miles || 0,
    },
  });
  const {
    control,
    formState: { errors },
  } = methods;
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [employees, setEmployees] = useState<Employee[]>([]);

  const handleSubmit = async (data: TimesheetLine) => {
    setLoading(true);
    try {
      if (selectedTimesheetLine?.uuid) {
        await editTimesheetLineRequest(token, companyId, selectedTimesheetLine?.uuid, data);
      } else {
        await createTimesheetLineRequest(token, companyId, data, payrunId);
      }
      showNotification({ message: 'Timesheet has been updated!', options: { variant: 'success' } });
      setLoading(false);
      handleClose();
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  };

  const onSearch = async (search: string) => {
    try {
      setSearchLoading(true);
      const res = await lookupEmployeesRequest({
        token,
        companyId,
        payScheduleId,
        substring: search,
      });
      setEmployees(res.data.items);
      setSearchLoading(false);
    } catch (e) {
      setSearchLoading(false);
      handleServerError(e);
    }
  };

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      open={open}
      onClose={() => handleClose()}>
      <DialogTitle>{selectedTimesheetLine?.uuid ? 'Change Line' : 'Add a new line'}</DialogTitle>

      <FormProvider {...methods}>
        <form>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SearchEmployeeAutocomplete
                  loading={searchLoading}
                  employee={selectedTimesheetLine?.employee}
                  employees={employees}
                  onSearch={onSearch}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  control={control}
                  rules={{ required: 'Units are required' }}
                  name="basic_units"
                  render={({ field }) => (
                    <AppTextField
                      {...field}
                      onChange={(event: any) => field.onChange(event.target.value)}
                      error={!!errors.basic_units?.message}
                      type="number"
                      cy-test-id="timesheet-line-units"
                      label="Hours / Days"
                      fullWidth
                      helperText={errors.basic_units?.message || ''}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  control={control}
                  rules={{ required: 'Rate is required' }}
                  name="basic_rate"
                  render={({ field }) => (
                    <AppTextField
                      {...field}
                      error={!!errors.basic_rate?.message}
                      onChange={(event: any) => field.onChange(event.target.value)}
                      type="number"
                      cy-test-id="timesheet-line-rate"
                      label="Value / Rate"
                      fullWidth
                      helperText={errors.basic_rate?.message || ''}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="overtime_units"
                  render={({ field }) => (
                    <AppTextField
                      {...field}
                      type="number"
                      onChange={(event: any) => field.onChange(event.target.value)}
                      label="Overtime Hours / Days"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="overtime_rate"
                  render={({ field }) => (
                    <AppTextField
                      {...field}
                      type="number"
                      onChange={(event: any) => field.onChange(event.target.value)}
                      label="Overtime Value / Rate"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="adjustments"
                  render={({ field }) => (
                    <AppTextField
                      {...field}
                      type="number"
                      onChange={(event: any) => field.onChange(event.target.value)}
                      label="Adjustments"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="expenses"
                  render={({ field }) => (
                    <AppTextField
                      {...field}
                      type="number"
                      onChange={(event: any) => field.onChange(event.target.value)}
                      label="Expenses"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="miles"
                  render={({ field }) => (
                    <AppTextField
                      {...field}
                      type="number"
                      onChange={(event: any) => field.onChange(event.target.value)}
                      label="Miles"
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button size="large" onClick={() => handleClose()} variant="outlined">
              Cancel
            </Button>

            <Button
              size="large"
              style={{ width: 90 }}
              disabled={loading}
              cy-test-id="save-timesheet-line"
              onClick={methods.handleSubmit(handleSubmit)}
              color="primary"
              variant="contained">
              <ButtonTitleWithLoading title="Save" loaderVariant="paper" loading={loading} />
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
