import React from 'react';

import { Box, makeStyles, Theme, Typography } from '@material-ui/core';

interface Props {
  subtitle: string;
  firstName?: string;
}

export const useStyles = makeStyles((theme: Theme) => ({
  greetingMessage: {
    fontWeight: 700,
  },
}));

export const Greetings = ({ subtitle, firstName }: Props) => {
  const classes = useStyles();

  return (
    <Box>
      {firstName && (
        <Typography variant="h5" className={classes.greetingMessage}>
          Hello {firstName}!
        </Typography>
      )}

      <Typography variant="caption" color="textSecondary">
        {subtitle}
      </Typography>
    </Box>
  );
};
