import React from 'react';

import { Box, createStyles, makeStyles, Button } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

import { OnboardingStepTitle } from '../../../../components';
import { ShiftNameField } from '../../../../components/controlled-inputs/ShiftNameField';
import { ShiftTime } from '../../../../components/controlled-inputs/ShiftTime';
import { ALL_DAY_END, ALL_DAY_START } from '../../components';

const useStyles = makeStyles(() =>
  createStyles({
    popoverClass: {
      marginLeft: '40px',
      marginTop: '26px',
    },
  })
);

export const ShiftStep: React.FC = () => {
  const classes = useStyles();
  const startTimeField = 'shift.regular.startTime';
  const endTimeField = 'shift.regular.endTime';
  const { watch, setValue } = useFormContext();

  const start = watch(startTimeField);
  const end = watch(endTimeField);
  const isAllDay = start === ALL_DAY_START && end === ALL_DAY_END;

  const handleAllDayButtonClick = () => {
    const options = { shouldDirty: true };
    if (!isAllDay) {
      setValue(startTimeField, ALL_DAY_START, options);
      setValue(endTimeField, ALL_DAY_END, options);
    } else {
      setValue(startTimeField, '', options);
      setValue(endTimeField, '', options);
    }
  };

  return (
    <>
      <OnboardingStepTitle
        popoverClass={classes.popoverClass}
        helpText="Your team can ‘check-in’ on location before and after these hours. However, these define the hours logged as regular paid working time."
        text="Now we’ll create the main shift. Let’s start with the schedule of regular paid working hours. We’ll move to overtime and breaks in a minute."
      />

      <ShiftNameField name="shift.name" margin="normal" />

      <ShiftTime name={startTimeField} label="Select Start Time" margin="normal" />

      <ShiftTime name={endTimeField} label="Select End Time" margin="normal" />

      <Box display="flex" justifyContent="flex-end" marginTop={2}>
        <Button
          size="small"
          color="primary"
          style={{ width: 120 }}
          onClick={handleAllDayButtonClick}
          variant={isAllDay ? 'contained' : 'outlined'}>
          All day
        </Button>
      </Box>
    </>
  );
};
