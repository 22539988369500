import { ActionType } from '../action-types';
import { Dispatch } from 'redux';
import { UserAction } from '../actions/user';
import { HelperAction } from '../actions/helper';
import {
  CompanyPosition,
  CreateUser,
  HelperState,
  LoginData,
  RequestWorkerData,
  UpdateUser,
  UpdateWorkerData,
  UploadAvatarData,
  User,
  UserDashboards,
  VerifyPhoneData,
  Worker,
} from '@vyce/core/src/types';
import { createUserRequest, updateMeRequest, userMeRequest } from '@vyce/core/src/api/users';
import {
  loginRequest,
  redeemVerificationCodeRequest,
  sendVerificationSMSRequest,
} from '@vyce/core/src/api/auth';
import { getUserDashboardsRequest, updateUserDashboardsRequest } from '@vyce/core/src/api/dashboards';
import { store } from '../store';
import { updateNavItems } from './helper';
import { getFromLS, saveToLS } from '@vyce/core/src/utils/local-storage';
import { saveAvatarRequest } from '@vyce/core/src/api/storage';
import { getQualificationsRequest } from '@vyce/core/src/api/checkers';
import { updateWorkerRequest } from '@vyce/core/src/api/connect';
import { CAPTCHA_AVOIDER } from '@vyce/core/src/api/config';

const updateToken = (token: string, dispatch: Dispatch<HelperAction>) => {
  saveToLS('token', token);
  dispatch({
    type: ActionType.SET_ACCESS_TOKEN,
    payload: token,
  });
};

const handleMeRequest = async (token: string): Promise<User> => {
  const meResult = await userMeRequest(token);
  const userData: User = meResult.data || {};
  return { ...userData };
};

const handleUserDashboardsRequest = async (token: string): Promise<UserDashboards> => {
  const res = await getUserDashboardsRequest(token as string);
  return res.data;
};

const getSelectedPosition = (user: User, helper: HelperState): CompanyPosition | undefined => {
  const positions = user?.positions;
  if (!positions?.length) {
    return;
  }
  let selectedPosition: CompanyPosition | undefined = positions[0];
  const positionDataFromLS = getFromLS('selectedPosition');
  if (positionDataFromLS?.role) {
    selectedPosition = positions?.find(
      position =>
        position.role === positionDataFromLS.role && position.company.uuid === positionDataFromLS.companyId
    );
  }

  return selectedPosition?.company?.uuid !== helper?.selectedPosition?.company?.uuid &&
    selectedPosition?.company?.uuid
    ? selectedPosition
    : undefined;
};

export const setUserData = (data: User) => {
  return (dispatch: Dispatch<UserAction>) => {
    dispatch({
      type: ActionType.SET_USER_DATA,
      payload: data,
    });
  };
};

export const clearUser = () => {
  return (dispatch: Dispatch<UserAction | HelperAction>) => {
    saveToLS('token', '');
    dispatch({
      type: ActionType.SET_INITIAL_USER_STATE,
    });
    dispatch({
      type: ActionType.CLEAR_NAV_ITEMS,
    });
  };
};

export const updateUserDashboards = (dashboards: UserDashboards) => {
  return async (dispatch: Dispatch<UserAction>) => {
    let token = store.getState().helper.access_token;
    try {
      await updateUserDashboardsRequest(token, dashboards);
      dispatch({
        type: ActionType.SET_USER_DATA,
        payload: { dashboards },
      });
    } catch (e) {
      console.error(e);
    }
  };
};

export const userRegister = ({
  data,
  redirectUrl,
  saveToStore,
  recaptcha_token,
  handleServerError,
}: CreateUser) => {
  return async (dispatch: Dispatch<UserAction | HelperAction>) => {
    try {
      dispatch({
        type: ActionType.SET_USER_DATA_LOADING,
        payload: true,
      });
      const helper = store.getState().helper;
      const result = await createUserRequest(data, recaptcha_token);
      const userState = result.data;
      if (redirectUrl) {
        dispatch({
          type: ActionType.SET_REDIRECT_TO,
          payload: redirectUrl,
        });
      }
      if (saveToStore) {
        dispatch({
          type: ActionType.SET_USER_DATA,
          payload: userState,
        });
      }

      let selectedPosition = getSelectedPosition(userState, helper);
      updateNavItems(userState, dispatch, selectedPosition);
      if (selectedPosition) {
        dispatch({
          type: ActionType.SET_SELECTED_COMPANY,
          payload: selectedPosition.company,
        });
        dispatch({
          type: ActionType.SET_SELECTED_POSITION,
          payload: selectedPosition,
        });
      }

      const username = data.email || data.phone || '';
      const loginResult = await loginRequest({
        username,
        password: data.password_1,
        client_secret: CAPTCHA_AVOIDER,
      });
      const token = loginResult.data.access_token;

      updateToken(token, dispatch);

      dispatch({
        type: ActionType.SET_USER_DATA_LOADING,
        payload: false,
      });

      if (data.phone) {
        sendVerificationSMSRequest({ recaptcha_token: CAPTCHA_AVOIDER });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SET_USER_DATA_LOADING,
        payload: false,
      });
      handleServerError(error);
    }
  };
};

export const verifyPhone = ({ code, redirectUrl, skip, handleServerError }: VerifyPhoneData) => {
  return async (dispatch: Dispatch<UserAction | HelperAction>) => {
    let token = store.getState().helper.access_token;
    try {
      dispatch({
        type: ActionType.SET_USER_DATA_LOADING,
        payload: true,
      });
      if (!skip) {
        await redeemVerificationCodeRequest(+code);
      }
      const userState = await handleMeRequest(token as string);

      dispatch({
        type: ActionType.SET_USER_DATA,
        payload: userState,
      });
      dispatch({
        type: ActionType.SET_USER_DATA_LOADING,
        payload: false,
      });
      if (redirectUrl) {
        dispatch({
          type: ActionType.SET_REDIRECT_TO,
          payload: redirectUrl,
        });
      }
    } catch (error) {
      clearUser();
      dispatch({
        type: ActionType.SET_USER_DATA_LOADING,
        payload: false,
      });
      handleServerError(error);
    }
  };
};

export const me = () => {
  return async (dispatch: Dispatch<UserAction | HelperAction>) => {
    //TODO remove token from redux
    const helper = store.getState().helper;
    let token = helper.access_token;
    if (!token) {
      token = getFromLS('token');
      dispatch({
        type: ActionType.SET_ACCESS_TOKEN,
        payload: token,
      });
    }
    try {
      const userState = await handleMeRequest(token as string);
      const dashboards = await handleUserDashboardsRequest(token as string);

      const res = await getQualificationsRequest({});
      userState.qualifications = res.data;

      let selectedPosition = getSelectedPosition(userState, helper);
      updateNavItems(userState, dispatch, selectedPosition);
      if (selectedPosition) {
        dispatch({
          type: ActionType.SET_SELECTED_COMPANY,
          payload: selectedPosition.company,
        });
        dispatch({
          type: ActionType.SET_SELECTED_POSITION,
          payload: selectedPosition,
        });
      }

      dispatch({
        type: ActionType.SET_USER_DATA,
        payload: { ...userState, dashboards },
      });
      dispatch({
        type: ActionType.SET_USER_DATA_LOADING,
        payload: false,
      });
    } catch (error) {
      clearUser();
    }
  };
};

export const userLogin = ({
  username,
  password,
  recaptcha_token,
  handleServerError,
  redirectUrl,
}: LoginData) => {
  return async (dispatch: Dispatch<UserAction | HelperAction>) => {
    try {
      dispatch({
        type: ActionType.SET_USER_DATA_LOADING,
        payload: true,
      });
      const helper = store.getState().helper;
      const result = await loginRequest({ username, password, client_secret: recaptcha_token });
      const token = result.data.access_token;

      updateToken(token, dispatch);

      const userState = await handleMeRequest(token as string);
      const dashboards = await handleUserDashboardsRequest(token as string);

      let selectedPosition = getSelectedPosition(userState, helper);
      updateNavItems(userState, dispatch, selectedPosition);
      if (selectedPosition) {
        dispatch({
          type: ActionType.SET_SELECTED_COMPANY,
          payload: selectedPosition.company,
        });
        dispatch({
          type: ActionType.SET_SELECTED_POSITION,
          payload: selectedPosition,
        });
      }

      dispatch({
        type: ActionType.SET_USER_DATA,
        payload: { ...userState, dashboards },
      });
      dispatch({
        type: ActionType.SET_USER_DATA_LOADING,
        payload: false,
      });
      if (redirectUrl) {
        dispatch({
          type: ActionType.SET_REDIRECT_TO,
          payload: redirectUrl,
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SET_USER_DATA_LOADING,
        payload: false,
      });
      handleServerError(error);
    }
  };
};

export const updateUser = ({
  data,
  isNotify,
  callback,
  handleServerError,
  showNotification,
}: UpdateUser) => {
  return async (dispatch: Dispatch<UserAction | HelperAction>) => {
    const token = store.getState().helper.access_token;
    const uuid = store.getState().user.uuid;
    try {
      dispatch({
        type: ActionType.SET_USER_DATA_LOADING,
        payload: true,
      });
      const result = await updateMeRequest(token as string, uuid, data);

      dispatch({
        type: ActionType.SET_USER_DATA,
        payload: result.data,
      });
      dispatch({
        type: ActionType.SET_USER_DATA_LOADING,
        payload: false,
      });
      if (isNotify) {
        showNotification({
          message: 'Your data successfully updated',
          options: {
            variant: 'success',
          },
        });
      }
      if (callback) {
        callback();
      }
    } catch (error) {
      handleServerError(error);
    }
  };
};

export const uploadUserAvatar = ({ file, saveToProfile, handleServerError }: UploadAvatarData) => {
  return async (dispatch: Dispatch<UserAction | HelperAction>) => {
    dispatch({
      type: ActionType.SET_USER_DATA_LOADING,
      payload: true,
    });
    const token = store.getState().helper.access_token;
    const uuid = store.getState().user.uuid;
    try {
      const result = await saveAvatarRequest(file, token as string, uuid);
      const photo = result.data;
      let userData = { photo };

      if (saveToProfile) {
        const result = await updateMeRequest(token as string, uuid, userData);
        userData = result.data;
      }

      dispatch({
        type: ActionType.SET_USER_DATA,
        payload: userData,
      });
      dispatch({
        type: ActionType.SET_USER_DATA_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: ActionType.SET_USER_DATA_LOADING,
        payload: false,
      });
      handleServerError(error);
    }
  };
};

export const updateWorker = ({ showNotification, data, handleServerError }: UpdateWorkerData) => {
  return async (dispatch: Dispatch<UserAction | HelperAction>) => {
    const token = store.getState().helper.access_token;
    const uuid = store.getState().user.worker?.uuid;
    const qualifications = store.getState().user.worker?.qualifications ?? null;
    try {
      dispatch({
        type: ActionType.SET_USER_DATA_LOADING,
        payload: true,
      });

      const result = await updateWorkerRequest(token as string, uuid as string, data);
      const workerData: Worker = { ...result.data, ...qualifications };

      dispatch({
        type: ActionType.SET_USER_DATA,
        payload: { worker: workerData },
      });
      dispatch({
        type: ActionType.SET_USER_DATA_LOADING,
        payload: false,
      });
      showNotification({
        message: 'Your data successfully updated',
        options: {
          variant: 'success',
        },
      });
    } catch (error) {
      handleServerError(error);
    }
  };
};
