import React, { useContext, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';

import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';
import { DeviceContext } from '@vyce/core/src/contexts';
import { changeEmailRequest, sendEmailVerificationRequest } from '@vyce/core/src/api/auth';
import { changeEmailByAdminRequest } from '@vyce/core/src/api/legend/auth';
import { EmailField } from '@vyce/core/src/components/controlled-inputs';

import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  email?: string;
  userId: string;
  isLegend?: boolean;
  verified: boolean;
  getReCaptchaToken: () => Promise<string>;
}

interface Form {
  email: string;
}

export const EditEmailOrVerify: React.FC<Props> = ({
  email: outerEmail,
  verified: outerVerified,
  userId,
  isLegend,
  getReCaptchaToken,
}) => {
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const { isMobile } = useContext(DeviceContext);
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(outerEmail ?? '');
  const [verified, setVerified] = useState<boolean>(outerVerified ?? false);

  const methods = useForm<Form>({
    defaultValues: {
      email: email,
    },
  });

  const sendEmailVerification = async () => {
    try {
      const recaptcha_token = await getReCaptchaToken();
      await sendEmailVerificationRequest({ userId, recaptcha_token });
      showNotification({ message: 'Verification link has been sent', options: { variant: 'success' } });
      setOpen(false);
    } catch (e) {
      handleServerError(e);
    }
  };

  const onSubmit = async (data: Form, event?: React.BaseSyntheticEvent<object, any, any>) => {
    event?.preventDefault();
    try {
      const recaptcha_token = await getReCaptchaToken();
      if (isLegend) {
        await changeEmailByAdminRequest({ email: data.email, user_id: userId, recaptcha_token });
      } else {
        await changeEmailRequest({ email: data.email, recaptcha_token });
      }
      setEmail(data.email);
      setVerified(false);
      setOpen(false);
      return false;
    } catch (e) {
      handleServerError(e);
    }
  };

  return (
    <>
      <Box display="flex" height="100%" alignItems="center" justifyContent="space-between">
        <Box marginLeft={1}>
          <Box display="flex">
            <Typography variant="subtitle2">Email </Typography>
            <Typography
              style={{
                color: !verified ? theme.palette.error.main : theme.palette.success.main,
                marginLeft: 4,
              }}
              variant="subtitle2">
              ({verified ? 'verified' : 'not verified'})
            </Typography>
            <Typography variant="subtitle2">:</Typography>
          </Box>
          <Typography variant="caption">{email}</Typography>
        </Box>

        <Box display="flex" height="100%">
          {!verified && !isLegend && (
            <Button variant="outlined" size="small" onClick={sendEmailVerification} color="primary">
              Verify
            </Button>
          )}

          <Button
            size="small"
            style={{ marginLeft: 8 }}
            onClick={() => setOpen(true)}
            variant="outlined"
            color="secondary">
            Edit
          </Button>
        </Box>
      </Box>

      <Dialog
        container={document.getElementById(MAIN_CONTAINER_ID)}
        fullScreen={isMobile}
        open={open}
        onClose={() => setOpen(false)}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <DialogTitle>Change your email address</DialogTitle>
            <DialogContent>
              Please input your new email address and we’ll send you a verification link.
              <EmailField autoFocus margin="normal" />
            </DialogContent>
            <DialogActions>
              <Button size="large" variant="outlined" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button size="large" type="submit" color="primary" variant="contained">
                Save
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};
