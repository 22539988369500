import React from 'react';

import { Box, Typography, useTheme } from '@material-ui/core';
import { FiBriefcase } from 'react-icons/fi';
import clsx from 'clsx';

import { ExperienceRange } from '../../../../components/WorkExperienceCard';
import useStyles from '../../styles';
import { WorkExperienceDTO } from '../../types';

export const WorkExperienceItem = ({
  title,
  company,
  start_date,
  end_date,
  isHiring,
}: WorkExperienceDTO) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <Box className={clsx(classes.iconContainer, { [classes.smallIconContainer]: isHiring })}>
        <FiBriefcase size="22px" color={theme.palette.primary.main} />
      </Box>

      <Box marginLeft={2} display="flex" flexDirection="column">
        <Typography variant="subtitle1" className={clsx(classes.bold, { [classes.smallBold]: isHiring })}>
          {company.name}
        </Typography>
        <Typography
          variant="caption"
          color="textSecondary"
          className={clsx(classes.smallBold, classes.smallText, classes.grayscaleBodyColor)}>
          {title}
        </Typography>

        <ExperienceRange start_date={start_date} end_date={end_date} smallBold={true} />
      </Box>
    </>
  );
};
