import React, { useContext, useEffect, useState } from 'react';

import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { HelperState, Position } from '@vyce/core/src/types';
import { formatDate } from '@vyce/core/src/utils/dates';
import { CompaniesField } from '@vyce/core/src/components/controlled-inputs/CompaniesField';
import { JobsField } from '@vyce/core/src/components/controlled-inputs/JobsField';
import { AppCheckbox } from '@vyce/core/src/components/inputs/AppCheckbox';

import useStyles from '../styles';
import { AppKeyboardDatePicker } from '../../../components/inputs';
import { ButtonTitleWithLoading } from '../../../components/ButtonTitleWithLoading';
import { MAIN_CONTAINER_ID } from '../../../constants';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  handleClose: () => void;
  position?: Position;
  open: boolean;
  addPosition?: Function;
  updatePosition?: Function;
  helper: HelperState;
  positionIndex?: number;
}

export const PositionDialog: React.FC<Props> = ({
  handleClose,
  position,
  open,
  addPosition,
  updatePosition,
  positionIndex,
  helper,
}) => {
  const { handleServerError } = useContext(NotificationContext);
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const methods = useForm<Position>({
    defaultValues: {
      title: position?.title,
      company: position?.company,
      start_date: position?.start_date || formatDate(new Date()),
      end_date: position?.end_date,
      currently_active: !position?.end_date,
    },
  });
  const { control, reset } = methods;
  const [loading, setLoading] = useState<boolean>(false);
  const { access_token } = helper;
  const [isNowadays, setIsNowadays] = useState<boolean>(!position?.end_date);

  useEffect(() => {
    if (position) {
      setIsNowadays(!position.end_date);
    }
  }, [position, open]);

  const handleSubmit = async (data: Position) => {
    setLoading(true);
    const newPosition: Position = {
      ...data,
      title: data.title?.name || data.title,
      end_date: isNowadays ? null : data.end_date,
    };

    try {
      if (!positionIndex && addPosition) {
        await addPosition(newPosition);
      }
      if (positionIndex !== undefined && updatePosition) {
        await updatePosition(positionIndex, newPosition);
      }
      setLoading(false);
      handleClose();
    } catch (e: any) {
      handleServerError(e);
    }

    setIsNowadays(true);
    reset();
  };

  return (
    <FormProvider {...methods}>
      <Dialog
        classes={{ paper: classes.dialog }}
        container={document.getElementById(MAIN_CONTAINER_ID)}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{position ? 'Edit' : 'Add'} Work Experience</DialogTitle>

        <DialogContent>
          <div>
            <CompaniesField
              margin="normal"
              name="company"
              label="Company"
              multiple={false}
              rules={{ required: 'Company is required' }}
            />

            <JobsField
              margin="normal"
              name="title"
              multiple={false}
              label="Role"
              rules={{ required: 'Role is required' }}
              token={access_token}
            />

            <Box display="flex" width="100%" marginTop={1}>
              <Controller
                name="currently_active"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <AppCheckbox
                        onChange={e => {
                          const checked = e.target.checked;
                          field.onChange(checked);
                          setIsNowadays(checked);
                        }}
                        color="primary"
                        checked={field.value}
                      />
                    }
                    label="Still working"
                  />
                )}
              />
            </Box>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                name="start_date"
                control={control}
                render={props => (
                  <AppKeyboardDatePicker
                    value={props.field.value}
                    onChange={data => props.field.onChange(formatDate(data))}
                    label="Start Date"
                    margin="normal"
                    fullWidth
                    disableFuture
                    views={['year', 'month']}
                    id="date-picker-dialog"
                  />
                )}
              />
            </MuiPickersUtilsProvider>

            {!isNowadays && (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                  name="end_date"
                  control={control}
                  render={props => (
                    <AppKeyboardDatePicker
                      value={props.field.value}
                      onChange={data => props.field.onChange(formatDate(data))}
                      label="End Date"
                      margin="normal"
                      fullWidth
                      disableFuture
                      views={['year', 'month']}
                      id="date-picker-dialog"
                    />
                  )}
                />
              </MuiPickersUtilsProvider>
            )}
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={methods.handleSubmit(handleSubmit)} variant="contained" color="primary" autoFocus>
            <ButtonTitleWithLoading
              title={position ? 'Update' : 'Add'}
              loaderVariant="paper"
              loading={loading}
            />
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};
