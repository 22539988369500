import { formatDate } from '@vyce/core/src/utils/dates';
import { ActiveCheckInDTO, ManualClockInOut } from '@vyce/core/src/api/types';
import { ManualClockProps } from '@vyce/core/src/types';

export const prepareData = (
  data: ManualClockProps,
  activeCheckInInfo?: ActiveCheckInDTO
): ManualClockInOut => {
  const date = data.timestamp === 'now' ? new Date().toString() : data.timestamp?.toString();
  const { site_id, shift_id, within_area, geo_point } = activeCheckInInfo ?? ({} as ActiveCheckInDTO);
  const selectedGeoPoint = data.location?.center
    ? `POINT(${data.location?.center?.lat} ${data.location?.center?.lng})`
    : undefined;
  const siteId = data.action === 'out' ? site_id : data.location?.uuid ?? '';
  const shiftId = data.action === 'out' ? shift_id : data.shift?.id ?? '';
  const withinArea = data.action === 'out' ? within_area : data.verifiedLocation ?? false;
  const geoPoint = data.action === 'out' ? geo_point : selectedGeoPoint;

  return {
    site_id: siteId,
    shift_id: shiftId,
    event_type: `check_${data.action}`,
    within_area: withinArea,
    geo_point: geoPoint,
    timestamp: date ? formatDate(date, 'YYYY-MM-DD HH:mm:ss') : '',
    //@ts-ignore
    platform: navigator?.userAgentData?.platform || navigator?.platform || 'unknown',
  };
};
