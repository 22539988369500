import React from 'react';

import { Box } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';

import { AppTextField } from '@vyce/core/src/components/inputs/AppTextField';
import { OnboardingStepTitle } from '@vyce/core/src/components/OnboardingStepTitle';

export const PayScheduleNameStep: React.FC = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Box>
      <OnboardingStepTitle text="Please give this pay schedule a name e.g. Friday Weekly" />

      <Controller
        control={control}
        rules={{
          required: 'Name is required',
        }}
        name="name"
        render={({ field }) => (
          <AppTextField
            label="Pay Schedule Name"
            margin="normal"
            fullWidth
            {...field}
            error={!!errors?.name?.message}
            helperText={(errors?.name?.message as string) || ''}
          />
        )}
      />
    </Box>
  );
};
