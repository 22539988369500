import React from 'react';

import { createStyles, makeStyles, Theme, Switch, SwitchProps } from '@material-ui/core';

import { monochrome } from '../../theme/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '48px',
      height: '24px',
      borderRadius: '40px',
      padding: 0,

      '& .MuiIconButton-root': {
        padding: '2px ',
        color: monochrome.lightest,
      },

      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(24px)',
      },

      '& .MuiSwitch-track': {
        backgroundColor: theme.palette.type === 'dark' ? monochrome.dark : monochrome.mediumlight,
      },

      '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
      },
    },
  })
);

export const AppSwitch = React.forwardRef((props: SwitchProps, ref) => {
  const classes = useStyles();

  return <Switch className={classes.root} {...props} innerRef={ref} />;
});
