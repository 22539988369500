export enum ActionType {
  // user
  SET_USER_DATA = 'set_user_data',
  SET_INITIAL_USER_STATE = 'set_initial_user_state',

  // helper
  SET_REDIRECT_TO = 'set_redirect_to',
  CLEAR_REDIRECT_TO = 'clear_redirect_to',
  SET_USER_DATA_LOADING = 'set_user_data_loading',
  SET_COMPANY_DATA_LOADING = 'set_company_data_loading',
  SET_NAV_ITEMS = 'set_nav_items',
  CLEAR_NAV_ITEMS = 'clear_nav_items',
  SET_ACCESS_TOKEN = 'set_access_token',
  CLEAR_HELPERS = 'clear_helpers',
  SET_SHOW_TOUR = 'set_show_tour',
  SET_COMPANY_DATA = 'set_company_data',
  SET_PAY_SCHEDULES = 'set_pay_schedules',
  SET_TEAMS = 'set_teams',
  ADD_TEAM = 'add_team',
  SET_IS_JOB_POSTED_STATUS = 'set_is_job_posted_status',

  // employer
  SET_SELECTED_POSITION = 'set_selected_position',
  SET_SELECTED_COMPANY = 'set_selected_company',
  SET_SELECTED_COMPANY_SUBSCRIPTION_DATA = 'set_selected_company_subscription_data',
}
