import React, { ReactElement, useMemo } from 'react';

import { Link } from 'react-router-dom';
import { FiCheck } from 'react-icons/fi';
import { Box, Button, Grid, Paper, Typography, useTheme } from '@material-ui/core';

import { currencyFormatter } from '../../../utils';
import { useStyles } from '../styles';
import { BillingData, Subscription } from '../../../types';
import { formatDate, getUKFormattedDate } from '../../../utils/dates';
import { AppLink, PageLoading } from '../../../components';
import config from '../../../api/config';

interface Props {
  companyName?: string;
  subscription?: Subscription;
  children?: ReactElement;
  loading?: boolean;
  billingData?: BillingData;
}

const buttonStyle = { width: 160, minWidth: 160 };

const ModuleRow = ({
  moduleName,
  quantity,
  amount,
  link,
  linkText,
}: {
  moduleName: string;
  quantity: number;
  amount: string | number;
  link?: string;
  linkText?: string;
}) => (
  <>
    <Grid item xs={3}>
      <Typography variant="body2">{moduleName}</Typography>
    </Grid>

    <Grid item xs={3}>
      <Typography variant="body2">{quantity}</Typography>
    </Grid>

    <Grid item xs={4}>
      <Typography variant="body2">{amount}</Typography>
    </Grid>

    {link && (
      <Grid item xs={2}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            style={buttonStyle}
            variant="outlined"
            size="small"
            component={Link}
            to={link}
            color="primary">
            {linkText}
          </Button>
        </Box>
      </Grid>
    )}
  </>
);

export const SubscriptionInfo: React.FC<Props> = ({
  companyName,
  subscription,
  children,
  loading,
  billingData,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { active, ended } = subscription || {};

  const teamsBilling = useMemo(
    () => billingData?.items?.find(item => item.product_id === config.teamsStripeProductId),
    [billingData]
  );

  const payrollBilling = useMemo(
    () => billingData?.items?.find(item => item.product_id === config.payrollStripeProductId),
    [billingData]
  );

  const timeBilling = useMemo(
    () => billingData?.items?.find(item => item.product_id === config.timeStripeProductId),
    [billingData]
  );

  const payment: number = useMemo(() => (billingData?.total || 0) - (billingData?.tax || 0), [billingData]);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <Box display="flex" flexDirection="column" height="100%" justifyContent="space-between" flex={1}>
      <Paper className={classes.paper} style={{ minWidth: 640 }} variant="outlined">
        <Grid container className={classes.gridContainer}>
          <Grid item xs={6}>
            <Typography variant="h6">{companyName}</Typography>
          </Grid>

          <Grid item xs={3}>
            <Box display="flex" alignItems="center" gridGap={4}>
              {ended ? (
                <Typography className={classes.yellowText}>
                  Expires on {formatDate(ended, 'D MMMM YYYY')}
                </Typography>
              ) : (
                <>
                  <Typography
                    className={active ? classes.positiveText : classes.redText}
                    variant="subtitle2">
                    {active ? 'Subscribed' : 'Not subscribed'}
                  </Typography>
                  {active && <FiCheck color={theme.palette.success.main} size="20px" />}
                </>
              )}
            </Box>
          </Grid>

          <Grid item xs={3}>
            {children}
          </Grid>
        </Grid>

        <Grid container className={classes.card}>
          <Grid item xs={3}>
            <Typography variant="subtitle2">Module</Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle2">Max Number of users this month</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="subtitle2">Estimated cost</Typography>
          </Grid>

          <Grid item xs={2}>
            <Box />
          </Grid>

          <Box className={classes.gap} />

          <ModuleRow
            moduleName="Teams"
            quantity={teamsBilling?.quantity || 0}
            amount={currencyFormatter.format(teamsBilling?.amount || 0)}
            link="/user-management/teams"
            linkText="Add/Remove Users"
          />
          <Box className={classes.gap} />

          <ModuleRow
            moduleName="Time & Attendance"
            quantity={timeBilling?.quantity || 0}
            amount={currencyFormatter.format(timeBilling?.amount || 0)}
            link="/time/users"
            linkText="Add/Remove Users"
          />
          <Box className={classes.gap} />
          <Grid item xs={3}>
            <Box />
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle2">Number of payments this month</Typography>
          </Grid>

          <Grid item xs={6}>
            <Box />
          </Grid>

          <Box className={classes.gap}></Box>

          <ModuleRow
            moduleName="Self Managed Payroll"
            quantity={payrollBilling?.quantity || 0}
            amount={currencyFormatter.format(payrollBilling?.amount || 0)}
            link="/user-management/payroll"
            linkText="Add/Remove Users"
          />

          <Box className={classes.gap}></Box>

          <Grid item xs={12}>
            <Typography variant="subtitle2">Estimated invoice for this month</Typography>
          </Grid>

          <Grid item xs={6}>
            {billingData?.period_start && billingData?.period_end && (
              <Box display="flex" flexDirection="column" gridGap={16}>
                <Typography className={classes.currentPeriod}>
                  Current payment period: {getUKFormattedDate(billingData.period_start)} -{' '}
                  {getUKFormattedDate(billingData.period_end)}
                </Typography>
                <Typography className={classes.currentPeriod}>VAT at 20%</Typography>
                <Typography className={classes.currentPeriod}>Amount due</Typography>
              </Box>
            )}
          </Grid>

          <Grid item xs={6}>
            <Box display="flex" flexDirection="column" gridGap={16}>
              <Typography variant="subtitle2">{currencyFormatter.format(payment)}</Typography>
              <Typography variant="subtitle2">{currencyFormatter.format(billingData?.tax || 0)}</Typography>
              <Typography variant="subtitle2">
                {currencyFormatter.format(billingData?.total || 0)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Box className={classes.card}>
        <Typography variant="body2" color="textSecondary">
          Vyce subscriptions auto-renew monthly until the organisation is <AppLink to="#">cancelled</AppLink>
          . There is a 1 month notice period required for your cancellation. Contact support if you need
          help.
        </Typography>
      </Box>
    </Box>
  );
};
