import React from 'react';

import { Box } from '@material-ui/core';
import dayjs from 'dayjs';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { AppKeyboardDatePicker } from '@vyce/core/src/components/inputs';

const inputProps = { readOnly: true };

export const AppRangeDatePicker = ({
  dateTo,
  dateFrom,
  setDateFrom,
  setDateTo,
}: {
  dateTo: string;
  dateFrom: string;
  setDateFrom: (date: string) => void;
  setDateTo: (date: string) => void;
}) => {
  const handleDateFromChange = (date: any) => {
    setDateFrom(dayjs(date).startOf('day').format());
  };

  const handleDateToChange = (date: any) => {
    setDateTo(dayjs(date).endOf('day').format());
  };
  return (
    <Box display="flex" gridGap={8}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Box width={200}>
          <AppKeyboardDatePicker
            value={dateFrom || null}
            onChange={handleDateFromChange}
            label="From"
            format="dd/MM/yyyy"
            margin="none"
            fullWidth
            id="date-picker-dialog"
            maxDate={dateTo}
            disableFuture
            InputProps={inputProps}
          />
        </Box>
      </MuiPickersUtilsProvider>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Box width={200}>
          <AppKeyboardDatePicker
            value={dateTo || null}
            onChange={handleDateToChange}
            label="To"
            margin="none"
            format="dd/MM/yyyy"
            fullWidth
            minDate={dateFrom}
            id="date-picker-dialog"
            disableFuture
            InputProps={inputProps}
          />
        </Box>
      </MuiPickersUtilsProvider>
    </Box>
  );
};
