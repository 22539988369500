import React from 'react';

import { Box, BoxProps } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from '../../styles';

interface BoxWrapperProps extends BoxProps {
  children: React.ReactNode;
  row?: boolean;
  isSimpleContainer?: boolean;
  withGrowing?: boolean;
  smallBlock?: boolean;
}

/*
  isSimpleContainer means that this BoxWrapper doesn't have classes and paddings
*/
export const BoxWrapper = ({
  children,
  row = false,
  isSimpleContainer = false,
  withGrowing = true,
  smallBlock = false,
  ...rest
}: BoxWrapperProps) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx({ [classes.tileItem]: !isSimpleContainer, [classes.smallTitleItme]: smallBlock })}
      marginTop={isSimpleContainer ? 1.5 : 2}
      padding={isSimpleContainer ? 0 : 2}
      position="relative"
      display="flex"
      alignItems="flex-start"
      flexDirection={row ? 'row' : 'column'}
      flexWrap="wrap"
      flexGrow={withGrowing ? 1 : 0}
      {...rest}>
      {children}
    </Box>
  );
};
