import React, { useEffect } from 'react';

import { Box, Button, Grid, Typography } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import capitalize from 'lodash/capitalize';

import { ScheduleDialog } from './ScheduleDialog';
import { ScheduleType } from '../../../../modules/timeModule/constants';
import { useBooleanState } from '../../../../hooks';
import { ShiftTime } from '../../../../components/controlled-inputs/ShiftTime';
import { DefaultRateField } from '../../../../components/controlled-inputs/DefaultRateField';
import { DefaultRateUnitSelect } from '../../../../components/controlled-inputs/DefaultRateUnitSelect';

interface Props {
  type: ScheduleType;
}

export const NewShiftSchedule: React.FC<Props> = ({ type }) => {
  const { getValues, setValue, watch } = useFormContext();
  const [isDialogOpen, openDialog, closeDialog] = useBooleanState(false);
  const startTime = watch(`${type}.startTime`);
  const endTime = watch(`${type}.endTime`);
  const scheduleFieldName = `${type}.schedule`;
  const isRegular = type === ScheduleType.REGULAR;
  const rateFieldName = isRegular ? 'basic_amount' : 'overtime_amount';
  const unitFieldName = isRegular ? 'basic_unit' : 'overtime_unit';
  const scheduleToCompare = watch(`${isRegular ? ScheduleType.OVERTIME : ScheduleType.REGULAR}.schedule`);

  const handleScheduleDialogClose = () => {
    closeDialog();
  };

  useEffect(() => {
    const newSchedule: any = { ...getValues(scheduleFieldName) };

    Object.keys(newSchedule).forEach(key => {
      if (key !== 'sunday' && key !== 'saturday') {
        if (startTime) {
          newSchedule[key].start = startTime;
        }
        if (endTime) {
          newSchedule[key].end = endTime;
        }
      }
    });
    setValue(scheduleFieldName, newSchedule);
  }, [startTime, endTime]);

  return (
    <Box display="flex" flexDirection="column" gridGap={16}>
      <Box display="flex" gridGap={12} alignItems="center">
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <Typography style={{ fontWeight: 500, lineHeight: '56px' }}>{capitalize(type)}</Typography>
          </Grid>

          <Grid item xs={6} md={4}>
            <ShiftTime name={`${type}.startTime`} label="Start Time" />
          </Grid>

          <Grid item xs={6} md={4}>
            <ShiftTime name={`${type}.endTime`} label="End Time" />
          </Grid>

          <Grid item xs={12} md={2}>
            <Box height="100%" minWidth={80} display="flex" alignItems="center">
              <Button size="small" variant="outlined" color="primary" onClick={() => openDialog()}>
                Customise
              </Button>
            </Box>
          </Grid>

          <Grid item xs={2}></Grid>

          <Grid item xs={12} md={4}>
            <DefaultRateField showHint name={rateFieldName} label="Default rate (£)" />
          </Grid>

          <Grid item xs={12} md={4}>
            <DefaultRateUnitSelect name={unitFieldName} />
          </Grid>

          <Grid item xs={2}></Grid>
        </Grid>
      </Box>

      <ScheduleDialog
        scheduleToCompare={scheduleToCompare}
        scheduleType={type}
        scheduleDetailsFieldName={type}
        open={isDialogOpen}
        handleClose={handleScheduleDialogClose}
      />
    </Box>
  );
};
