import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      padding: '28px 24px',
      maxWidth: '752px',
      position: 'relative',
      borderRadius: '12px',
    },
    smallText: {
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: '22px',
    },
    selectRoot: {
      borderRadius: '8px',
      '& .MuiSelect-root': {
        height: '32px',
        padding: '0px 28px 0px',
        display: 'flex',
        alignItems: 'center',
        width: '120px',
      },
    },
    accordionSummary: {
      '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: 0,
      },
    },

    additionalText: {
      color: theme.palette.text.secondary,
    },
    mainAccordionWrapper: {
      border: '1px solid #EFF0F7',
      borderRadius: '8px !important',
      padding: '12px 16px',
    },

    accordionWrapper: {
      margin: '0px !important',
    },
    questionButton: {
      position: 'absolute',
      top: 86,
      right: 32,
      zIndex: 1,

      [theme.breakpoints.down('sm')]: {
        top: 32,
        right: 24,
      },
    },

    moduleItemTitle: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'row-reverse',
        justifyContent: 'flex-end',
      },
    },

    moduleItemDetails: {
      width: '70%',

      [theme.breakpoints.down('xs')]: {
        marginLeft: '54px',
        width: '80%',
      },
    },

    styledLabel: {
      '& .MuiFormControlLabel-label': {
        whiteSpace: 'unset',
      },
    },
  })
);
