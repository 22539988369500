import React, { useMemo } from 'react';

import { GridColDef } from '@mui/x-data-grid';
import { Box, Typography } from '@material-ui/core';

import { AppDataGridWithSavedPage, ConfirmDialog } from '@vyce/core/src/components';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { TimeLogBySite, UserPermission } from '@vyce/core/src/types';
import { AppSearchInput } from '@vyce/core/src/components/inputs';
import { getUKFormattedDate } from '@vyce/core/src/utils';

import { ApproveButtonComponent, ExportButtonComponent, StatusComponent } from '../TimeLogsByPeriod';
import { TimeTotalInfo } from '../components';
import { useTimeLogsData } from './hooks';
import { allLocationsId } from './config';

export interface Props {
  companyId?: string;
  userPermissions?: UserPermission[];
}

export const TimeLogsByLocation = (props: Props) => {
  const {
    totals,
    isMobile,
    logs,
    count,
    loading,
    isConfirmDialogOpen,
    approveLoading,
    cnaApproveTimelogs,
    offset,
    periods,
    handlePageChange,
    closeConfirmDialog,
    handleApproveRequest,
    handleSearchChange,
    goToByWorkerView,
    exportTimelogs,
    handleApproveButtonClick,
    setOffset,
  } = useTimeLogsData(props);

  const columns: GridColDef[] = [
    {
      field: 'site_name',
      headerName: 'Location',
      disableColumnMenu: true,
      flex: 0.2,
      minWidth: 180,
      renderCell: ({ row }) => (
        <Box style={{ cursor: 'pointer' }} onClick={() => goToByWorkerView(row.site_name, row.site_id)}>
          <Typography color="secondary" variant="caption" style={{ fontWeight: 500 }}>
            {row.site_name}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'workers',
      headerName: 'Workers',
      disableColumnMenu: true,
      flex: 0.1,
      minWidth: 120,
    },
    {
      field: 'clocked_in_hours_decimals',
      headerName: 'Clocked Hours',
      disableColumnMenu: true,
      flex: 0.1,
      minWidth: 100,
      renderCell: ({ row }) => (
        <Typography variant="caption" style={{ fontWeight: 500 }}>
          {row.clocked_in_hours_decimals}
        </Typography>
      ),
    },
    {
      field: 'basic_hours_decimals',
      headerName: 'Regular Paid Hours',
      disableColumnMenu: true,
      flex: 0.1,
      minWidth: 100,
      renderCell: ({ row }) => (
        <Typography variant="caption" style={{ fontWeight: 500 }}>
          {row.basic_hours_decimals}
        </Typography>
      ),
    },
    {
      field: 'overtime_hours_decimals',
      headerName: 'OT Paid Hours',
      disableColumnMenu: true,
      flex: 0.1,
      minWidth: 100,
      renderCell: ({ row }) => (
        <Typography variant="caption" style={{ fontWeight: 500 }}>
          {row.overtime_hours_decimals}
        </Typography>
      ),
    },
    {
      field: 'total_hours_decimals',
      headerName: 'Total Paid Hours',
      disableColumnMenu: true,
      flex: 0.1,
      minWidth: 100,
      renderCell: ({ row }) => (
        <Typography variant="caption" style={{ fontWeight: 500 }}>
          {row.total_hours_decimals}
        </Typography>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.1,
      minWidth: 100,
      disableColumnMenu: true,
      renderCell: ({ row }) => <StatusComponent status={row.status} />,
    },
    {
      field: 'controls',
      headerName: '',
      width: 290,
      hideSortIcons: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <Box display="flex" gridGap={16} width="100%" justifyContent="flex-end" marginRight={2}>
          {row.site_id !== allLocationsId && (
            <ExportButtonComponent onClick={exportTimelogs} log={row as TimeLogBySite} />
          )}
          <ApproveButtonComponent log={row as TimeLogBySite} onClick={handleApproveButtonClick} />
        </Box>
      ),
    },
  ];

  const permissionBasedColumns = useMemo(() => {
    if (cnaApproveTimelogs) {
      return columns;
    }
    return columns.filter(col => col.field !== 'controls');
  }, [cnaApproveTimelogs]);

  return (
    <>
      <Box>
        {totals && (
          <TimeTotalInfo
            basic={totals.basic_hours_decimals}
            overtime={totals.overtime_hours_decimals}
            totalPaid={totals.total_hours_decimals}
            totalClockedHours={totals.total_clocked_in_hours_decimals}
            totalAdjustments={totals.total_amendments_decimals}
            workers={totals.total_workers}
            period={`${getUKFormattedDate(periods[0])} - ${getUKFormattedDate(periods[1])}`}
          />
        )}
      </Box>

      <Box display="flex" mt={2} mb={2} justifyContent="space-between" gridGap={8}>
        <AppSearchInput onChange={handleSearchChange} isSmall expanded={!isMobile} />
      </Box>

      <AppDataGridWithSavedPage
        noPaper
        rows={logs}
        loading={loading}
        height="calc(100vh - 372px)"
        getRowId={row => row.site_id}
        columns={permissionBasedColumns}
        rowCount={count}
        onPageChange={handlePageChange}
        pageSize={offset === 0 ? GRID_PAGE_SIZE + 1 : GRID_PAGE_SIZE}
        rowsPerPageOptions={[offset === 0 ? GRID_PAGE_SIZE + 1 : GRID_PAGE_SIZE]}
        disableSelectionOnClick
        setOffset={setOffset}
      />

      <ConfirmDialog
        handleClose={closeConfirmDialog}
        open={isConfirmDialogOpen}
        confirmText="Approve"
        title="Do you want to approve this Time log?"
        loading={approveLoading}
        handleConfirm={handleApproveRequest}
      />
    </>
  );
};
