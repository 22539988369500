import React, { useContext, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import { BiDownload } from 'react-icons/bi';

import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { generateFileLinkAndSave } from '@vyce/core/src/utils';
import { AppSelect } from '@vyce/core/src/components/inputs/AppSelect';
import { AppCheckbox } from '@vyce/core/src/components/inputs/AppCheckbox';

import { PayModuleContext } from '../../../contexts';
import { MAIN_CONTAINER_ID } from '../../../constants';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  companyId: string;
  payrunId?: string;
  disabled?: boolean;
  mode: 'single' | 'multiple';
  payrunIds?: string[];
  downloadPayrunBACSRequest: Function;
  downloadPayrunsBACSRequest: Function;
}

export const DownloadBACS: React.FC<Props> = ({
  companyId,
  payrunId,
  payrunIds,
  mode,
  downloadPayrunBACSRequest,
  downloadPayrunsBACSRequest,
  disabled,
}) => {
  const { handleServerError } = useContext(NotificationContext);
  const { isMobile } = useContext(DeviceContext);
  const { bacsFormats } = useContext(PayModuleContext);
  const [selectedFormat, setSelectedFormat] = useState<string>();
  const [isUnpaid, setIsUnpaid] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const downloadBacs = async () => {
    if (!selectedFormat) {
      return;
    }
    try {
      let res;
      if (payrunId && mode === 'single') {
        res = await downloadPayrunBACSRequest(companyId, payrunId, {
          bacs_format: selectedFormat,
          unpaid: isUnpaid,
        });
      }
      if (payrunIds?.length && mode === 'multiple') {
        res = await downloadPayrunsBACSRequest(companyId, {
          bacs_format: selectedFormat,
          unpaid: isUnpaid,
          pay_runs: payrunIds,
        });
      }
      if (!res) {
        return;
      }
      generateFileLinkAndSave(res);
    } catch (e) {
      handleServerError(e);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setIsUnpaid(false);
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        startIcon={<BiDownload size="17px" />}
        disabled={!!disabled}
        variant="contained"
        size="small"
        color="primary">
        BACS
      </Button>
      <Dialog
        fullScreen={isMobile}
        container={document.getElementById(MAIN_CONTAINER_ID)}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">Chose format of BACS File</DialogTitle>
        <DialogContent>
          <FormControl fullWidth variant="filled">
            <InputLabel id="bacs-type">Format</InputLabel>
            <AppSelect
              onChange={e => setSelectedFormat(e.target.value as string)}
              labelId="bacs-type"
              fullWidth>
              {bacsFormats?.map(format => (
                <MenuItem key={format.name} value={format.system_name}>
                  {format.name}
                </MenuItem>
              ))}
            </AppSelect>
          </FormControl>

          <Box marginTop={2}>
            <FormControlLabel
              control={
                <AppCheckbox
                  onChange={e => setIsUnpaid(e.target.checked)}
                  color="primary"
                  checked={isUnpaid}
                />
              }
              label="Only download payments that have not been marked as paid"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button size="large" variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            size="large"
            color="primary"
            disabled={!selectedFormat}
            onClick={() => downloadBacs()}
            variant="contained">
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
