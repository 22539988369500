import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import get from 'lodash/get';

import { AppTextField } from '../inputs';
import { FieldProps } from '../../types';

export const ShiftNameField: React.FC<FieldProps> = ({ name = '', margin, disabled }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      rules={{ required: 'Name is required' }}
      name={name}
      render={({ field }) => (
        <AppTextField
          {...field}
          disabled={disabled}
          value={field.value || ''}
          label="Name of the shift"
          fullWidth
          error={!!get(errors, name)}
          helperText={(get(errors, name)?.message as string) || ''}
          margin={margin}
        />
      )}
    />
  );
};
