import React, { useContext } from 'react';

import { Box, MenuItem, MenuList } from '@material-ui/core';
import { GridRenderCellParams } from '@mui/x-data-grid';

import { GridActions } from '@vyce/core/src/components';
import { TeamModuleContext } from '@vyce/core/src/contexts';

export const CustomActionCell = ({ params }: { params: GridRenderCellParams }) => {
  const { closeGridAction, changeManager, deleteTeamMembers } = useContext(TeamModuleContext);

  const handleDeleteTeamMembers = () => deleteTeamMembers(params.row.user_id);
  const handleChangeManager = () => changeManager(params.row.user_id, params.row?.is_manager);

  return (
    <Box display="flex" width="100%">
      <GridActions close={closeGridAction}>
        <MenuList>
          <MenuItem cy-test-id="change-manager-button" onClick={handleChangeManager}>
            {params.row?.is_manager ? 'Remove manager role' : 'Make a manager'}
          </MenuItem>
          <MenuItem cy-test-id="remove-user-from-team-button" onClick={handleDeleteTeamMembers}>
            Remove from this Team
          </MenuItem>
        </MenuList>
      </GridActions>
    </Box>
  );
};
