import React from 'react';

import { OnboardingStepTitle } from '../../../../components';
import { ClearableLocationField } from '../../components/ClearableLocationField';

export const PostcodeStep: React.FC = () => {
  return (
    <>
      <OnboardingStepTitle text="First of all, tell us the post code of your location." />

      <ClearableLocationField />
    </>
  );
};
