import React, { useContext, useEffect, useMemo, useState } from 'react';

import { CssBaseline, LinearProgress, ThemeProvider } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';

import { getFromLS } from '@vyce/core/src/utils/local-storage';
import { ColorThemeType, ThemeType } from '@vyce/core/src/types';
import { Header, MainNotifications, Sidebar } from '@vyce/core/src/components';
import { ColorThemeProvider, DeviceContext } from '@vyce/core/src/contexts';
import { isValueExistsInEnum } from '@vyce/core/src/utils';
import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';

import { AccountMenu } from 'src/views/main/components/AccountMenu';
import createRoutes from 'src/router';
import { useActions, useTypedSelector } from 'src/hooks';
import { MainAppTour } from './components/MainAppTour';
import useStyles from './styles';
import { ROUTES_WITHOUT_NAVIGATION } from './constants';

const routes = createRoutes();

interface Props {
  changeColorTheme: (type: ThemeType) => void;
  themeType?: ThemeType;
  colorTheme: ColorThemeType;
  theme: Theme;
}

export const Main: React.FC<Props> = ({ changeColorTheme, themeType, colorTheme, theme }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(true);
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const token = getFromLS('token');

  const {
    clearRedirectTo,
    me,
    clearUser,
    clearHelpers,
    setShowTour,
    verifyPhone,
    setPosition,
    fetchCompanyData,
  } = useActions();
  const { user, helper } = useTypedSelector(state => state);
  const { isDesktop } = useContext(DeviceContext);
  const history = useHistory();
  const { pathname } = useLocation();

  const isAuth = !!user?.first_name;
  const isEmployer = user.uuid && user?.positions?.length;

  const { redirectTo, navItems, notification, selectedPosition, selectedCompany } = helper;

  const hasPermissions = useMemo(() => !!selectedPosition?.permissions?.length, [selectedPosition]);

  const withoutNavigation = useMemo(
    () => isValueExistsInEnum(pathname, ROUTES_WITHOUT_NAVIGATION),
    [pathname]
  );

  const showHeader = useMemo(() => isAuth && !withoutNavigation, [isAuth, withoutNavigation]);

  const showSidebar = useMemo(
    () => !loading && user.uuid && !withoutNavigation,
    [loading, user.uuid, withoutNavigation]
  );

  const handleDrawerToggle = () => {
    if (isDesktop) {
      return setOpen(!open);
    }
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    async function fetchUserData() {
      await me();
      setLoading(false);
    }

    if (token) {
      fetchUserData();
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (selectedCompany?.uuid && hasPermissions) {
      fetchCompanyData(selectedCompany.uuid);
    }
  }, [selectedCompany, hasPermissions]);

  // listens and redirects from state
  useEffect(() => {
    if (redirectTo) {
      history.push(redirectTo);
      clearRedirectTo();
    }
  }, [clearRedirectTo, redirectTo, history]);

  const logout = () => {
    setOpen(false);
    setMobileOpen(false);
    clearHelpers();
    clearUser();
    // make sure localStorage.clear function will be last
    setTimeout(() => localStorage.clear());
  };

  return (
    <ColorThemeProvider value={{ colorTheme }}>
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          {showHeader && (
            <Header
              themeType={themeType}
              loading={loading}
              handleDrawerToggle={handleDrawerToggle}
              changeColorTheme={changeColorTheme}
              open={open}
              user={user}
              setShowTour={setShowTour}>
              <AccountMenu user={user} selectedPosition={selectedPosition} setPosition={setPosition} />
            </Header>
          )}

          {showSidebar && (
            <>
              <Sidebar
                handleDrawerToggle={handleDrawerToggle}
                open={open}
                logout={logout}
                mobileOpen={mobileOpen}
                setMobileOpen={setMobileOpen}
                navItems={navItems}
              />
            </>
          )}
          <main
            id={MAIN_CONTAINER_ID}
            className={clsx(classes.content, {
              [classes.contentShift]: open,
              [classes.authLayout]: !isAuth,
              [classes.withNavigation]: !withoutNavigation,
            })}>
            {loading ? <LinearProgress className={classes.progress} /> : <>{routes}</>}
          </main>

          <MainAppTour isEmployer={!!isEmployer} />

          <MainNotifications userId={user.uuid} verifyPhone={verifyPhone} />
        </div>
      </ThemeProvider>
    </ColorThemeProvider>
  );
};
