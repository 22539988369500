import React, { ReactElement } from 'react';

import { makeStyles, Theme, createStyles, Box, Typography } from '@material-ui/core';

import { useBooleanState } from '../hooks';

export interface StyleProps {
  expandedWidth: number;
}
interface ButtonProps {
  onClick: Function;
  title: string;
  icon: ReactElement;
  expandedWidth: number;
  expanded?: boolean;
}

export const AnimatedButton: React.FC<ButtonProps> = ({
  title,
  icon,
  expandedWidth,
  expanded = false,
  onClick,
}) => {
  const [isHovered, setHoverTrue, setHoverFalse] = useBooleanState(false);
  const classes = useStyles({ expandedWidth });

  return (
    <Box
      onClick={() => onClick()}
      className={`${classes.button} ${isHovered || expanded ? classes.buttonHovered : ''}`}
      onMouseEnter={setHoverTrue}
      onMouseLeave={setHoverFalse}>
      <Box className={classes.iconWrapper}>{icon}</Box>
      {(isHovered || expanded) && (
        <Typography variant="caption" className={classes.text}>
          {title}
        </Typography>
      )}
    </Box>
  );
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    button: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 40,
      height: 40,
      borderRadius: 8,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      transition: 'width 0.3s ease',
      padding: '12px 0px 12px 40px',
      '&:active': {
        opacity: 0.8,
      },
    },
    buttonHovered: {
      width: props => props.expandedWidth,
    },
    text: {
      fontWeight: 500,
      marginRight: 12,
    },
    iconWrapper: {
      width: 40,
      height: 40,
      backgroundColor: theme.palette.primary.main,
      zIndex: 1,
      position: 'absolute',
      left: 0,
      top: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    focused: {
      opacity: 0.7,
    },
  })
);
