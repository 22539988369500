import imageCompression from 'browser-image-compression';

import { pdfType } from '../config';

const compressionOptions = {
  maxSizeMB: 0.5,
  maxWidthOrHeight: 1024,
  useWebWorker: true,
};

export const compressFile = async (originalFile: File) => {
  let file: File = originalFile;
  try {
    if (file?.type.includes(pdfType)) {
      return file;
    }
    const compressedBlob = await imageCompression(originalFile, compressionOptions);
    file = new File([compressedBlob], originalFile.name);
  } catch (e) {
    console.error(e);
  }
  return file;
};
