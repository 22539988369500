import React, { useContext, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from '@material-ui/core';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { DeviceContext } from '../../../contexts';
import { Image, Testimonial } from '../../../types';
import { ImageUploader } from '../../../components';
import { AppTextField } from '../../../components/inputs';
import { AppTextFieldWithLimit } from '../../../components/controlled-inputs/AppTextFieldWithLimit';
import { useBooleanState } from '../../../hooks';
import { ButtonTitleWithLoading } from '../../../components/ButtonTitleWithLoading';
import { MAIN_CONTAINER_ID } from '../../../constants';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  open: boolean;
  handleClose: Function;
  createTestimonial: (data: Testimonial, file?: File | null) => Promise<void>;
}

export const AddTestimonialDialog: React.FC<Props> = ({ open, handleClose, createTestimonial }) => {
  const { handleServerError } = useContext(NotificationContext);
  const { isMobile } = useContext(DeviceContext);
  const [avatar, setAvatar] = useState<Image>();
  const [loading, setLoadingTrue, setLoadingFalse] = useBooleanState(false);
  const methods = useForm<Testimonial>({
    defaultValues: {
      author_name: '',
      author_position: '',
      author_company: '',
      testimonial: '',
      public: true,
    },
  });
  const {
    control,
    formState: { errors },
  } = methods;

  const onSubmit = async (data: Testimonial) => {
    try {
      setLoadingTrue();
      await createTestimonial(data, avatar?.file);
      methods.reset();
      setAvatar(undefined);
      setLoadingFalse();
    } catch (e) {
      setLoadingFalse();
      handleServerError(e);
    }
  };

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      maxWidth="md"
      disableScrollLock
      open={open}
      onClose={() => handleClose()}>
      <FormProvider {...methods}>
        <Box minWidth={isMobile ? '100vw' : '800px'}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <DialogTitle>Testimonial</DialogTitle>

            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <ImageUploader
                    image={avatar}
                    onImageUpload={image => setAvatar(image)}
                    extraText="avatar"
                  />
                </Grid>

                <Grid item xs={12} md={8}>
                  <Box flex={2} display="flex" flexDirection="column" gridGap={8}>
                    <Controller
                      control={control}
                      name="author_name"
                      rules={{ required: 'Name is required' }}
                      render={({ field }) => (
                        <AppTextField
                          {...field}
                          error={!!errors?.author_name?.message}
                          onChange={e => field.onChange(e.target.value)}
                          label="Name"
                          fullWidth
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="author_position"
                      render={({ field }) => (
                        <AppTextField
                          {...field}
                          onChange={e => field.onChange(e.target.value)}
                          label="Position"
                          fullWidth
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="author_company"
                      render={({ field }) => (
                        <AppTextField
                          {...field}
                          onChange={e => field.onChange(e.target.value)}
                          label="Company Name"
                          fullWidth
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Box marginTop={2} marginBottom={2}>
                <Divider />
              </Box>

              <AppTextFieldWithLimit name="testimonial" rows={10} limit={2000} label="Reference" multiline />
            </DialogContent>

            <DialogActions>
              <Button size="large" variant="outlined" onClick={() => handleClose()}>
                Cancel
              </Button>

              <Button
                style={{ width: 100 }}
                disabled={loading}
                size="large"
                type="submit"
                color="primary"
                variant="contained">
                <ButtonTitleWithLoading title="Publish" loaderVariant="paper" loading={loading} />
              </Button>
            </DialogActions>
          </form>
        </Box>
      </FormProvider>
    </Dialog>
  );
};
