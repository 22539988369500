// Global style variables
export const backgrounds = {
  default: '#F4F6F9',
  darkMode: '#14142B',
  paper: '#FFFFFF',
  darkModePaper: '#262338',
  input: '#EFF0F6',
  darkInput: '#4E4B66',
};

export const getBackground = (darkMode: boolean) => ({
  default: darkMode ? backgrounds.darkMode : backgrounds.default,
  paper: darkMode ? backgrounds.darkModePaper : backgrounds.paper,
  backgroundInput: darkMode ? backgrounds.darkInput : backgrounds.input,
  errorBackgroundInput: darkMode ? backgrounds.darkMode : '#FFECFC',
  hoverInputBorder: darkMode ? '#D9DBE9' : '#14142B',
});

export const monochrome = {
  lightest: '#FCFCFC',
  lighter: '#F4F6F9',
  light: '#EFF0F6',
  mediumlight: '#D9DBE9',
  medium: '#A0A3BD',
  mediumdark: '#6E7191',
  dark: '#4E4B66',
  darker: '#262338',
  darkest: '#14142B',
};
export const getColor = (darkMode: boolean) => ({
  primary: darkMode ? '#A996FF' : '#610BEF',
  primaryBG: darkMode ? '#A996FF' : '#EBECFE',
  primaryDark: '#610BEF',
  secondary: darkMode ? '#50C8FC' : '#005BD4',
  secondaryBG: darkMode ? '#50C8FC' : '#E3FEFF',
  secondaryDark: '#005BD4',
  success: darkMode ? '#A6F787' : '#008A00',
  successBG: darkMode ? '#A6F787' : '#EAF9DE',
  successDark: '#008A00',
  successLight: '#EAF9DE',
  warning: darkMode ? '#FFDF9A' : '#EAAC30',
  warningBG: darkMode ? '#FFDF9A' : '#FFF8E9',
  warningLight: darkMode ? '#946300' : '#FFF8E9',
  warningDark: '#EAAC30',
  error: darkMode ? '#FF75CB' : '#CA024F',
  errorBG: darkMode ? '#FF75CB' : '#FFECFC',
  errorDark: '#CA024F',
  disabled: darkMode ? monochrome.dark : monochrome.light,
  paperBG: darkMode ? backgrounds.darkModePaper : backgrounds.paper,
  defaultBG: darkMode ? backgrounds.darkMode : backgrounds.default,
  containedButton: darkMode ? '#4700AB' : '#FFFFFF',
  divider: darkMode ? monochrome.dark : monochrome.light,
});

export const getTextColor = (darkMode: boolean) => ({
  dark: darkMode ? monochrome.lightest : monochrome.darkest,
  light: darkMode ? monochrome.mediumlight : monochrome.mediumdark,
  medium: darkMode ? monochrome.light : monochrome.dark,
  disabled: darkMode ? monochrome.mediumlight : monochrome.mediumdark,
});

export const typography = {
  type: {
    primary: '"Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
    code: '"SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace',
  },
  weight: {
    regular: '400',
    bold: '700',
    extrabold: '800',
    black: '900',
  },
  size: {
    s1: 12,
    s2: 14,
    s3: 16,
    m1: 20,
    m2: 24,
    m3: 28,
    l1: 32,
    l2: 40,
    l3: 48,
    code: 90,
  },
} as const;

export const storiesColor = getColor(false);
export const storiesDarkModeColor = getColor(true);
