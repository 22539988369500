export const transformSalary = (salary: number, minimumFractionDigits: number) =>
  salary.toLocaleString(navigator?.language, {
    minimumFractionDigits,
  });

export const getJobSalary = (salary_from?: number, salary_to?: number, salary_type?: string): string => {
  if (!salary_type) {
    return '';
  }
  const minimumFractionDigits = salary_type === 'day' || salary_type === 'hour' ? 2 : 0;

  if (salary_type?.toLowerCase() === 'negotiable') return 'Negotiable';
  if (salary_to && salary_from) {
    return `£${transformSalary(salary_from, minimumFractionDigits)} - £${transformSalary(
      salary_to,
      minimumFractionDigits
    )} per ${salary_type}`;
  }

  return 'Not set';
};

export const DURATION_TYPES = ['Hours', 'Days', 'Weeks', 'Months', 'Years'];
export const SALARY_TYPES = ['Hour', 'Day', 'Month', 'Year', 'Negotiable'];
