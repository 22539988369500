import { useCallback, useEffect, useState, useContext } from 'react';

import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { ComputedDatum } from '@nivo/pie';

import { axios } from '@vyce/core/src/api';
import { WidgetModuleContext } from '@vyce/core/src/contexts';
import { useQuery } from '@vyce/core/src/hooks';
import { generateQueryParamsFromObj } from '@vyce/core/src/utils/url';

export const useWidgetData = () => {
  const [widgetData, setWidgetData] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const { widget, access_token, teams, selectedCompanyId, redirectUrl } = useContext(WidgetModuleContext);
  const history = useHistory();
  const query = useQuery();

  const { url: widgetUrl, type: widgetType, system_name: widgetSystemName = '' } = widget;

  const getData = useCallback(
    async (url: string, teamId?: string) => {
      if (!selectedCompanyId) {
        return setLoading(false);
      }
      try {
        const requestUrl = `/${url.replace('{company_id}', selectedCompanyId)}`;
        const headers = { Authorization: `Bearer ${access_token}` };
        const res = await axios.post(requestUrl, { limit: 100, team_id: teamId }, { headers });
        setLoading(false);
        setWidgetData(res.data.data);
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    },
    [access_token, selectedCompanyId]
  );

  const handleChartClick = (datum: ComputedDatum<any>) => {
    if (redirectUrl) {
      const search = history.location.search;
      const currentFilters = queryString.parse(search, { parseNumbers: true });
      const newFilters = datum.data.query_field;
      const query = generateQueryParamsFromObj({ ...currentFilters, ...newFilters });
      history.push(`${redirectUrl}?${query}`);
    }
  };

  const getTeamId = (name: string | null): string => {
    return teams?.find(team => team.name === name)?.uuid || '';
  };

  useEffect(() => {
    let isMounted = true;

    if (widget.url && isMounted) {
      const teamName = query.get('team_name');
      const teamId = getTeamId(teamName);
      getData(widget.url, teamId);
    }
    return () => {
      isMounted = false;
    };
  }, [selectedCompanyId, query]);

  return {
    data: widgetData,
    loading,
    widgetUrl,
    widgetSystemName,
    selectedCompanyId,
    widgetType,
    handleChartClick,
  };
};
