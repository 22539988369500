import React, { useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { PageLoading } from '@vyce/core/src/components/PageLoading';
import { INVITE_MODULES, InviteLinkType } from '@vyce/core/src/types';

export const EnrollEmployee: React.FC = () => {
  const {
    inviteToken,
    type = InviteLinkType.PRIVATE,
    payScheduleId,
    teamId,
    companyId,
  } = useParams<{
    inviteToken: string;
    type: string;
    companyId: string;
    teamId: string;
    payScheduleId: string;
  }>();
  const history = useHistory();

  useEffect(() => {
    let search = '';

    if (type === InviteLinkType.PUBLIC) {
      search += teamId ? `?${INVITE_MODULES.TEAMS}=True` : `?${INVITE_MODULES.PAY}=True`;
    }

    if (type === InviteLinkType.PRIVATE) {
      search += history.location.search;
    }

    if (payScheduleId) {
      search += `&payScheduleId=${payScheduleId}`;
    }

    if (companyId) {
      search += `&companyId=${companyId}`;
    }

    history.push(`/onboarding/employee/${inviteToken}/${type}${search}`);
  }, []);

  return <PageLoading />;
};
