import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isToday from 'dayjs/plugin/isToday';
import utc from 'dayjs/plugin/utc';

import { DailySchedule } from '../types';

dayjs.extend(customParseFormat);
dayjs.extend(isToday);
dayjs.extend(utc);

export const formatDate = (
  date: Date | string | null,
  format: string = 'YYYY-MM-DD',
  initialFormat?: string
): string => {
  if (!date) {
    return '';
  }

  if (initialFormat) {
    return dayjs(date, initialFormat).format(format);
  }

  return dayjs(date).format(format);
};

export const formatTableDate = (date: Date | string | null | undefined, isTime?: boolean): string => {
  if (!date) {
    return '';
  }
  return dayjs(date).format(`DD/MM/YY${isTime ? ' HH:mm' : ''}`);
};

export const getUKFormattedDate = (date: Date | string | null | undefined): string => {
  if (!date) {
    return '';
  }
  return dayjs(date).format(`DD/MM/YYYY`);
};

export const getTime = (date: Date | string | null | undefined): string => {
  if (!date) {
    return '';
  }
  return dayjs(date).format(`HH:mm`);
};

export const getFormattedDate = (date: Date | string | null | undefined): string => {
  if (!date) {
    return '';
  }
  return dayjs(date).format(`DD.MM.YYYY`);
};

export const getMonthsBetween = (startDate: Date | string, endDate: Date | string): number => {
  const date1 = dayjs(startDate);
  const date2 = dayjs(endDate);
  return date2.diff(date1, 'month');
};

export const formatTimeRange = (months: number): string => {
  const years = Math.floor(months / 12);
  months = months - years * 12;
  const yearsString = years !== 0 ? `${years} year${years > 1 ? 's' : ''} ` : '';
  const monthsString = months !== 0 ? `${months} month${months > 1 ? 's' : ''}` : '';
  return (yearsString + monthsString).trim() ? `${(yearsString + monthsString).trim()}` : '0 months';
};

export const isDateExpired = (date?: string | null): boolean | undefined => {
  return !date ? undefined : dayjs(date).isBefore(dayjs());
};

export const isDateExpiring = (date?: string | null): boolean | undefined => {
  if (isDateExpired(date)) {
    return false;
  }
  const expiredAfterDays = 90;
  return !date ? undefined : dayjs(date).diff(dayjs(), 'day') < expiredAfterDays;
};

export const getExpiringDays = (date?: string | null): number | undefined => {
  if (isDateExpired(date) || !date) {
    return undefined;
  }
  return dayjs(date).diff(dayjs(), 'day');
};

export const getTaxYearFilters = (): { value: number; name: string }[] => {
  const currentYear = new Date().getFullYear() + 1;
  const fromYear = 2019;
  const numberOfYears = currentYear - fromYear;
  const filters = [];
  for (let i = 1; i < numberOfYears; i++) {
    filters.push({ name: `${fromYear + i}/${fromYear + i + 1}`, value: fromYear + i });
  }
  return filters;
};

export const shiftTimeStringToDate = (timeString: string): Date => {
  const timeItems = timeString.split(':');
  const now = new Date();
  if (timeString.length) {
    now.setHours(+timeItems[0]);
    now.setMinutes(+timeItems[1]);
    now.setSeconds(0);
  }
  return now;
};

export const getWeekDayNumber = (day: string): number => {
  switch (day.toLowerCase()) {
    case 'sunday':
      return 0;
    case 'monday':
      return 1;
    case 'tuesday':
      return 2;
    case 'wednesday':
      return 3;
    case 'thursday':
      return 4;
    case 'friday':
      return 5;
    case 'saturday':
      return 6;
    default:
      return 1;
  }
};

export const getWeekDayName = (day: number): string => {
  switch (day) {
    case 0:
      return 'Sunday';
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
    default:
      return 'Monday';
  }
};

export const getDayWithDeclination = () => {
  const day = new Date().getDate();
  if ([1, 21, 31].includes(day)) return `${day}st`;
  if ([2, 22].includes(day)) return `${day}nd`;
  if ([3, 23].includes(day)) return `${day}rd`;

  return `${day}th`;
};

export const timeToDecimal = (time: string): number => {
  const [h, m] = time.split(':');
  const value = +h + +m / 60;
  return +value.toFixed(2);
};

export const isOverlapped = (day1: DailySchedule, day2: DailySchedule): boolean => {
  const day1StartDecimal = timeToDecimal(day1.start);
  const day1EndDecimal = timeToDecimal(day1.end);
  const day2StartDecimal = timeToDecimal(day2.start);
  const day2EndDecimal = timeToDecimal(day2.end);
  return (
    (day1StartDecimal >= day2StartDecimal && day1StartDecimal <= day2EndDecimal) ||
    (day1EndDecimal >= day2StartDecimal && day1EndDecimal <= day2EndDecimal) ||
    (day1StartDecimal <= day2StartDecimal && day1EndDecimal >= day2StartDecimal)
  );
};

export const numbersToTime = (hours: number, minutes: number): string => {
  const absHours = Math.abs(hours);
  const absMinutes = Math.abs(minutes);
  const minutesStr = absMinutes < 10 ? `0${absMinutes}` : absMinutes;
  const hoursStr = absHours < 10 ? `0${absHours}` : absHours;
  return `${hours < 0 || minutes < 0 ? '-' : ''}${hoursStr}:${minutesStr}`;
};

export const timeToSeconds = (time: string, isMinus: boolean): number => {
  const [h, m] = time.split(':');
  const value = Math.abs(+h) * 60 * 60 + Math.abs(+m) * 60;
  return isMinus ? value * -1 : value;
};

export const secondsToTime = (seconds: number): string => {
  const hours = Math.floor(Math.abs(seconds) / 3600);
  const minutes = Math.floor((Math.abs(seconds) % 3600) / 60);
  const h = hours > 0 && seconds < 0 ? hours * -1 : hours;
  const m = minutes > 0 && seconds < 0 ? minutes * -1 : minutes;
  return numbersToTime(h, m);
};

export const numbersToSeconds = (hours: number, minutes: number): number => {
  return hours * 60 * 60 + minutes * 60;
};

export const getTaxPeriod = (week: number, year: number): string => {
  return `Week ${week} ${year}/${(year + 1)?.toString()?.substring(2, 4)}`;
};

/* 
  dayjs('2024-04-11').format('dddd D MMM')
  Output: 'Thursday 11 Apr'
*/
export const formatDateToDayNumberMonth = (date: string) => {
  if (!date) {
    return '';
  }
  return dayjs(date).format('dddd D MMM');
};

export const removeTimeZone = (date: string): string => {
  return date.slice(0, -6);
};

export const getIsTodayFlag = (date: string) => {
  if (!date) {
    return false;
  }
  return dayjs(date).isToday();
};

export const getYesterday = () => {
  return dayjs().add(-1, 'day').format();
};
