import React, { ChangeEvent, useContext, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  RadioGroup,
} from '@material-ui/core';

import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { AppRadioButton } from '@vyce/core/src/components/inputs/AppRadio';
import { AppTextField } from '@vyce/core/src/components/inputs/AppTextField';
import { createFeedbackRequest } from '@vyce/core/src/api/feedback';
import { FeedbackItem } from '@vyce/core/src/types';
import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';

import { useTypedSelector } from '../../../hooks';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

interface Props {
  open: boolean;
  onClose: Function;
}

enum REASONS {
  TOO_EXPENSIVE = 'Too expensive',
  DONT_USE = 'Haven’t been using it',
  DONT_NEED = 'Don’t need it',
}

export const CancelSubscriptionReasonDialog: React.FC<Props> = ({ open, onClose }) => {
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const { isMobile } = useContext(DeviceContext);
  const { access_token } = useTypedSelector(state => state.helper);
  const [fixedReason, setFixedReason] = useState('');
  const [anotherReason, setAnotherReason] = useState('');

  const handleSendClick = async () => {
    const reasons: string[] = [fixedReason, anotherReason];
    const reason = reasons.filter(reason => !!reason).join('. ');
    const feedback: FeedbackItem = {
      comment: 'Cancel subscription',
      reason,
    };
    try {
      await createFeedbackRequest(access_token, feedback);
      setFixedReason('');
      setAnotherReason('');
      handleClose();
      showNotification({ message: 'Feedback has been sent', options: { variant: 'success' } });
    } catch (e) {
      handleServerError(e);
    }
  };

  const handleClose = () => onClose();

  const handleReasonChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFixedReason(e.target.value);
  };

  const handleAnotherReasonChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAnotherReason(e.target.value);
  };

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      open={open}
      onClose={handleClose}>
      <DialogTitle>Your Subscription to Vyce has been cancelled.</DialogTitle>

      <DialogContent>
        <DialogContentText>
          We’re sorry to see you go. Your account will remain active until the end of this calendar month,
          but you can no longer add any new users to your modules. The last invoice will be sent to your
          email address at the first day of the next month. You can reactivate at any time.
        </DialogContentText>
        <DialogContentText>
          We’re always looking to make improvements to Vyce and would love to know the reason for cancelling
          your subscription. Thank you.
        </DialogContentText>

        <FormControl component="fieldset">
          <RadioGroup onChange={handleReasonChange} aria-label="gender">
            <Box display="flex" flexWrap="wrap">
              <FormControlLabel
                value={REASONS.TOO_EXPENSIVE}
                control={<AppRadioButton color="primary" name={REASONS.TOO_EXPENSIVE} />}
                label=""
              />
              <FormControlLabel
                value={REASONS.DONT_USE}
                control={<AppRadioButton color="primary" name={REASONS.DONT_USE} />}
                label=""
              />
              <FormControlLabel
                value={REASONS.DONT_NEED}
                control={<AppRadioButton color="primary" name={REASONS.DONT_NEED} />}
                label=""
              />
            </Box>
          </RadioGroup>
        </FormControl>

        <AppTextField
          value={anotherReason}
          onChange={handleAnotherReasonChange}
          margin="normal"
          multiline
          label="Any other reasons?"
          fullWidth
        />
      </DialogContent>

      <DialogActions>
        <Button size="large" onClick={handleClose} variant="outlined">
          Close
        </Button>
        <Button
          disabled={!fixedReason && !anotherReason}
          size="large"
          color="primary"
          onClick={handleSendClick}
          variant="contained">
          Send feedback & close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
