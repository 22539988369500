import { Box, Paper, Typography, Divider } from '@material-ui/core';

import { AppRoundedRadio } from '@vyce/core/src/components/inputs';

import { useStyles } from '../styles';

export const AutomaticClockOut = ({
  countAutoClockOut,
  updatePreferences,
}: {
  countAutoClockOut: boolean;
  updatePreferences: (arg0: boolean) => void;
}) => {
  const classes = useStyles();

  return (
    <Paper variant="outlined" className={classes.wrapper}>
      <Box marginBottom={3}>
        <Typography variant="h6">Automatic Clock Out</Typography>
      </Box>

      <Box className={classes.controlsContainer} display="flex" flexDirection="column" padding={2}>
        <Typography variant="caption">
          If a team members clocks in but does not clock out on a particular day, they are automatically
          clocked out at 23.59. By default, zero hours are associated to this team members Time Logs.
          Managers can then go in and add hours manually. Alternatively, you can choose to automatically
          associate the total hours from the time they clock in to the end of their shift.
        </Typography>

        <Box marginTop={2} marginBottom={1}>
          <Typography className={classes.bold} variant="caption">
            Choose which option you would like here:
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" gridGap={8}>
          <Box className={classes.radioContainer} onClick={() => updatePreferences(false)}>
            <Typography variant="caption">Apply zero hours to automatic clock outs</Typography>
            <AppRoundedRadio checked={!countAutoClockOut} />
          </Box>

          <Divider />

          <Box className={classes.radioContainer} onClick={() => updatePreferences(true)}>
            <Typography variant="caption">Apply regular shift hours to automatic clock outs</Typography>
            <AppRoundedRadio checked={countAutoClockOut} />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};
