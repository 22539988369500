import React, { ChangeEvent, ReactElement } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@material-ui/core';
import capitalize from 'lodash/capitalize';

import { AppRoundedRadio } from '@vyce/core/src/components/inputs';

import { TimeLogsPeriods } from '../config';
import { useStyles } from '../styles';

interface Props {
  expanded: boolean;
  period: TimeLogsPeriods;
  children: ReactElement;
  handleChange: (panel: TimeLogsPeriods) => (event: ChangeEvent<{}>, expanded: boolean) => void;
}

export const PeriodAccordionItem: React.FC<Props> = ({ expanded, children, handleChange, period }) => {
  const classes = useStyles();

  return (
    <Box>
      <Accordion
        square
        expanded={expanded}
        onChange={handleChange(period)}
        classes={{ expanded: classes.expanded }}
        className={classes.mainAccordionWrapper}>
        <AccordionSummary classes={{ root: classes.accordionSummary }}>
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Typography variant="caption">{capitalize(period)}</Typography>
            <AppRoundedRadio checked={expanded} />
          </Box>
        </AccordionSummary>

        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </Box>
  );
};
