import { axios } from '@vyce/core/src/api';

import {
  CreatePayScheduleTagRequest,
  DeletePayScheduleTagsRequest,
  GetPayScheduleTagsRequest,
  PatchPayScheduleTagsRequest,
} from '../types';

export const getPayScheduleTagsRequest = ({
  payScheduleIds,
  companyId,
  limit = 3000,
  offset = 0,
  group,
  substring = '',
}: GetPayScheduleTagsRequest) => {
  return axios.post(`/companies/${companyId}/tags/search`, {
    limit,
    offset,
    pay_schedule_ids: payScheduleIds,
    substring,
    group,
    order_by: [
      {
        field_name: 'name',
        desc: false,
      },
    ],
  });
};

export const createPayScheduleTagRequest = ({
  payScheduleId,
  companyId,
  group,
  name,
  verified,
  byStaff,
}: CreatePayScheduleTagRequest) => {
  return axios.post(`/companies/${companyId}/pay_schedules/${payScheduleId}/tags?by_staff=${byStaff}`, {
    name,
    group,
    verified,
  });
};

export const patchPayScheduleTagsRequest = ({
  payScheduleId,
  payScheduleTagId,
  companyId,
  group,
  name,
  verified,
}: PatchPayScheduleTagsRequest) => {
  return axios.patch(`/companies/${companyId}/pay_schedules/${payScheduleId}/tags/${payScheduleTagId}`, {
    name,
    group,
    verified,
  });
};

export const deletePayScheduleTagsRequest = ({
  payScheduleId,
  payScheduleTagId,
  companyId,
}: DeletePayScheduleTagsRequest) => {
  return axios.delete(`/companies/${companyId}/pay_schedules/${payScheduleId}/tags/${payScheduleTagId}`);
};
