import React, { useContext } from 'react';

import { Box, Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';

import { DeviceContext } from '@vyce/core/src/contexts';

import { useStyles } from './styles';
import { pdfType } from './config';
import { PDFPreview } from './components';
import { MAIN_CONTAINER_ID } from '../../constants';

interface Props {
  url: string;
  type: 'img' | 'pdf';
  isOpen: boolean;
  bigPreview?: boolean;
  onClose: () => void;
}

export const PreviewDocumentDialog = ({ url, type, isOpen, bigPreview, onClose }: Props) => {
  const classes = useStyles();

  const { isMobile } = useContext(DeviceContext);

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      maxWidth="xl"
      open={isOpen}
      onClose={onClose}>
      <DialogContent>
        <Box>
          {type === pdfType ? (
            <PDFPreview url={url} bigPreview />
          ) : (
            <img alt="selectedImage" src={url} className={classes.selectedImage} />
          )}
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button size="large" variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PreviewDocumentDialog.PDFPreview = PDFPreview;
