import React, { useContext, useEffect, useMemo } from 'react';

import { Grid, Paper, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { BillingContact, Company, User } from '@vyce/core/src/types';
import {
  EmailField,
  FirstNameField,
  LastNameField,
  PhoneNumberField,
} from '@vyce/core/src/components/controlled-inputs';
import { AppFormWrapper } from '@vyce/core/src/components';
import { SubscriptionContext } from '@vyce/core/src/contexts';

import { useStyles } from '../styles';
import { useBillingContact } from '../../../modules/subscriptionModule/hooks/useBillingContact';

interface From {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
}

const getEmailRules = (phone: string) => {
  if (phone)
    return {
      pattern: {
        value: /\S+@\S+\.\S+/,
        message: 'Invalid email format',
      },
    };
  return undefined;
};

const getPhoneRules = (email: string) => {
  if (email)
    return {
      validate: (value: string) => {
        return value?.length > 8 || 'Invalid Mobile Number';
      },
    };
  return undefined;
};

interface Props {
  selectedCompany?: Company;
  token: string;
  user?: User;
}

export const EditBillingContact: React.FC<Props> = ({ token, user, selectedCompany }) => {
  const classes = useStyles();
  const { isUpdating, billingContactDefaultValues, updateBillingContact } = useBillingContact({
    token,
    selectedCompany,
    user,
  });
  const methods = useForm<From>({
    defaultValues: billingContactDefaultValues,
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const { setBackTo } = useContext(SubscriptionContext);
  const history = useHistory();
  const {
    getValues,
    formState: { isValid },
  } = methods;
  const phoneValue = getValues('phone');
  const emailValue = getValues('email');

  const emailRules = useMemo(() => getEmailRules(phoneValue), [phoneValue]);
  const phoneRules = useMemo(() => getPhoneRules(emailValue), [emailValue]);

  const handleSubmit = (data: BillingContact) => {
    updateBillingContact(data);
  };

  const back = () => {
    history.push('/subscriptions/billing');
  };

  useEffect(() => {
    setBackTo({ text: 'Billing Info', back });

    return () => setBackTo(null);
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} lg={8} style={{ marginBottom: 56 }}>
        <AppFormWrapper
          methods={methods}
          initialData={billingContactDefaultValues}
          handleSubmit={handleSubmit}
          loading={isUpdating}
          isDisabled={!isValid}>
          <Paper className={classes.paper} variant="outlined">
            <Typography style={{ marginTop: 16 }} variant="h6">
              Billing Contact
            </Typography>

            <FirstNameField margin="normal" />

            <LastNameField margin="normal" />

            <EmailField margin="normal" autoFocus={false} rules={emailRules} />

            <PhoneNumberField name="phone" margin="normal" rules={phoneRules} />
          </Paper>
        </AppFormWrapper>
      </Grid>
    </Grid>
  );
};
