import { useContext } from 'react';

import { Box, Typography } from '@material-ui/core';
import { useDrop } from 'react-dnd';

import { ItemTypes } from '../config';
import { FieldsValuesContext } from './FieldsValuesContext';
import { Field } from './Field';

export const LocationDetailBlock = () => {
  const [, drop] = useDrop(() => ({ accept: ItemTypes.CUSTOM }));
  const { customLocationFields, systemLocationFields } = useContext(FieldsValuesContext);

  return (
    <div ref={drop}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle2">
          Select & order the Location Details you want in your Custom Time Log Export:
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gridGap={8}>
        {systemLocationFields.map(item => (
          <Field key={item.id} {...item} fieldType={ItemTypes.CUSTOM} />
        ))}

        {customLocationFields.map(item => (
          <Field key={item.id} {...item} fieldType={ItemTypes.CUSTOM} />
        ))}
      </Box>
    </div>
  );
};
