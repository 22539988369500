import { TabItem } from '@vyce/core/src/types';

export const USER_MANAGEMENT_TABS: TabItem[] = [
  {
    label: 'Payroll',
    link: '/user-management/payroll',
  },
  {
    label: 'Teams',
    link: '/user-management/teams',
  },
  {
    label: 'Company Admins',
    link: '/user-management/admins',
  },
  {
    label: 'Invites',
    link: '/user-management/company-invites',
  },
];
