import { Controller, useFormContext } from 'react-hook-form';

import { Box, FormControl, FormControlLabel, Grid, RadioGroup, Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { ControlledSelect } from '@vyce/core/src/components/controlled-inputs/ControlledSelect';
import { DURATION_TYPES } from '@vyce/core/src/utils/job';
import {
  AppButtonCheckbox,
  AppKeyboardDatePicker,
  AppRadioButton,
  AppTextField,
} from '@vyce/core/src/components/inputs';

interface JobTypeProps {
  isUrgently: boolean;
  isContractDetails: boolean;
}

export const JobType = ({ isUrgently, isContractDetails }: JobTypeProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Box>
      <Typography variant="subtitle1" color="primary">
        Job Type
      </Typography>

      <Controller
        render={({ field }) => (
          <FormControl {...field} component="fieldset">
            <RadioGroup aria-label="job-type" value={field.value || null} onChange={field.onChange}>
              <Box display="flex" marginTop={1}>
                <FormControlLabel
                  value="contract"
                  control={<AppRadioButton color="primary" name="Contract" />}
                  label=""
                />
                <FormControlLabel
                  value="permanent"
                  control={<AppRadioButton color="primary" name="Permanent" />}
                  label=""
                />
              </Box>
            </RadioGroup>
          </FormControl>
        )}
        name="job_type"
        control={control}
      />

      <Grid container spacing={2}>
        {isContractDetails && (
          <>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="duration"
                rules={{ required: 'Duration is required' }}
                render={({ field }) => (
                  <AppTextField
                    {...field}
                    value={field.value}
                    type="number"
                    margin="normal"
                    label="Job Duration"
                    error={!!errors?.duration?.message}
                    helperText={(errors?.duration?.message as string) || ''}
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <ControlledSelect
                name="duration_type"
                rules={{ required: 'Duration Type is required' }}
                label="Type"
                margin="normal"
                items={DURATION_TYPES}
              />
            </Grid>
          </>
        )}
      </Grid>

      <Box marginTop={2}>
        <Typography variant="subtitle1" color="primary">
          Start Date
        </Typography>

        {!isUrgently && (
          <Box mb={1}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                name="start_date"
                control={control}
                render={props => (
                  <AppKeyboardDatePicker
                    value={props.field.value}
                    onChange={data => props.field.onChange(data)}
                    label="Start Date"
                    margin="normal"
                    disablePast
                    format="dd/MM/yyyy"
                    fullWidth
                  />
                )}
              />
            </MuiPickersUtilsProvider>
          </Box>
        )}

        <Controller
          name="urgently"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <AppButtonCheckbox
                  onChange={e => field.onChange(e.target.checked)}
                  color="primary"
                  name="ASAP"
                  checked={field.value}
                />
              }
              label=""
            />
          )}
        />
      </Box>
    </Box>
  );
};
