import { Certificate, Course, TagGroups, Test, User } from '../../../types';

export const getPersonalizedMessage = (user: User) => {
  const positions = user.positions;
  const employee = user.employee;
  let message = `This is Vyce app, let us tell you more about user who gonna ask you - Name: ${user.first_name} ${user.last_name}. Address: ${user.address?.address_snippet}. Date of Birth: ${user.date_of_birth}. `;

  if (employee?.job_title || user.positions?.length) {
    message += `Job title(s): ${
      positions?.length ? positions.map(pos => `${pos.role} at ${pos.company.name} `) : employee?.job_title
    }. `;
  }

  if (user.nationality?.name) {
    message += `Nationality: ${user.nationality.name}. `;
  }

  const roles = user.worker?.tags?.filter(tag => tag.group === TagGroups.ROLE);
  if (roles?.length) {
    message += `Worked as ${roles.map(role => `${role.name} `)}. `;
  }

  const skills = user.worker?.tags?.filter(tag => tag.group === TagGroups.SKILL);
  if (skills?.length) {
    message += `Skills: ${skills.map(skill => ` ${skill.name}`)}. `;
  }

  const certificates = user.qualifications?.certificates?.items;
  if (certificates?.length) {
    message += `Certificates: ${certificates.map((item: Certificate) => ` ${item.title}`)}`;
  }

  const courses = user.qualifications?.courses?.items;
  if (courses?.length) {
    message += `Courses: ${courses.map((item: Course) => ` ${item.title}`)}`;
  }

  const tests = user.qualifications?.tests?.items;
  if (tests?.length) {
    message += `Tests: ${tests.map((item: Course) => ` ${item.title}`)}`;
  }

  return (message +=
    'Provide answers that are more consistent with these data. Remember that you will chat with this user.');
};
