import React from 'react';

import { Typography } from '@material-ui/core';

import { useStyles } from '../styles';

interface Props {
  value: number;
}

export const TemperatureText: React.FC<Props> = ({ value }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.temperature}>
      {value.toFixed()}
      <span>&#176;</span>
    </Typography>
  );
};
