import React, { MouseEventHandler } from 'react';
import { AppIconButton } from './AppIconButton';
import { VscClose } from 'react-icons/vsc';
import { useTheme } from '@material-ui/core';

interface Props {
  back: MouseEventHandler<HTMLButtonElement>;
}

export const BackButton: React.FC<Props> = ({ back }) => {
  const theme = useTheme();

  return (
    <AppIconButton
      isBorder
      isSmall
      variant="paper"
      disableFocusRipple
      disableRipple
      disableTouchRipple
      onClick={back}>
      <VscClose size="20px" color={theme.palette.text.primary} />
    </AppIconButton>
  );
};
