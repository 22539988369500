import React from 'react';

import clsx from 'clsx';
import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';

import { PAYMENT_STATUSES } from '../../../modules/subscriptionModule/constants';
import { DetailsIcon } from '../../../components/DetailsIcon';

const useStyles = makeStyles(theme =>
  createStyles({
    popoverClass: {
      marginLeft: '0px',
      marginTop: '-32px',
    },
    grey: {
      color: theme.palette.text.secondary,
    },
    error: {
      color: theme.palette.error.main,
      fontWeight: 500,
    },
  })
);

interface Props {
  status: string;
}

export const PaymentStatus: React.FC<Props> = ({ status }) => {
  const statusObject = PAYMENT_STATUSES[status];
  const classes = useStyles();

  return (
    <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
      <Typography
        variant="caption"
        className={clsx({
          [classes.grey]:
            statusObject.name === PAYMENT_STATUSES.draft.name ||
            statusObject.name === PAYMENT_STATUSES.void.name,
          [classes.error]: statusObject.name === PAYMENT_STATUSES.uncollectible.name,
        })}>
        {statusObject?.name}
      </Typography>

      {statusObject?.description && (
        <DetailsIcon
          size="15px"
          content={<>{statusObject.description}</>}
          popoverClass={classes.popoverClass}
        />
      )}
    </Box>
  );
};
