import { checkIfAvailableRequest } from '../api/users';
import { VYCE_EMAIL } from '../constants';
import { AppNotification } from '../types';

export const checkNINumber = async (
  ni_number: string,
  showNotification: (notification: AppNotification) => void
): Promise<boolean> => {
  let isNINumberAvailable: boolean;
  try {
    const res = await checkIfAvailableRequest({ ni_number });
    isNINumberAvailable = res.data?.ni_number;
  } catch (e) {
    isNINumberAvailable = false;
    console.error(e);
  }
  if (!isNINumberAvailable) {
    const notification = getErrorObject('NI Number');
    showNotification(notification);
  }
  return isNINumberAvailable;
};

export const checkPhoneNumber = async (
  phone: string,
  showNotification: (notification: AppNotification) => void
): Promise<boolean> => {
  let isAvailable: boolean;
  try {
    const res = await checkIfAvailableRequest({ phone });
    isAvailable = res.data?.phone;
  } catch (e) {
    isAvailable = false;
    console.error(e);
  }
  if (!isAvailable) {
    const notification = getErrorObject('Phone Number');
    showNotification(notification);
  }
  return isAvailable;
};

const getErrorObject = (param: string): AppNotification => {
  return {
    message: `Hmmm. This ${param} is already registered on Vyce.`,
    description: `Please check to make sure your ${param} is correct. If this is your ${param} and it is not associated to your account, please <a class="not-app-link" href="mailto: ${VYCE_EMAIL}" target="_blank">get in touch</a> and we’ll look into it ASAP.`,
    options: {
      variant: 'error',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      action: {
        type: null,
        buttonText: '',
      },
      permanent: true,
    },
  };
};
