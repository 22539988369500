const config: any = {
  auth: {
    connect: 'https://vyce-prod.s3.eu-west-1.amazonaws.com/cdn/auth-pictures/connect-auth.png',
    employer: 'https://vyce-prod.s3.eu-west-1.amazonaws.com/cdn/auth-pictures/employer-auth.png',
    generic: 'https://vyce-prod.s3.eu-west-1.amazonaws.com/cdn/auth-pictures/generic-auth-picture.png',
    worker: 'https://vyce-prod.s3.eu-west-1.amazonaws.com/cdn/auth-pictures/worker-auth.png',
    employee_reset_password:
      'https://vyce-prod.s3.eu-west-1.amazonaws.com/cdn/auth-pictures/employee-reset-password.png',
  },
  icons3D: {
    lightning: 'https://vyce-prod.s3.eu-west-1.amazonaws.com/cdn/icons-3d/lightning.png',
    lock: 'https://vyce-prod.s3.eu-west-1.amazonaws.com/cdn/icons-3d/lock.png',
    shield: 'https://vyce-prod.s3.eu-west-1.amazonaws.com/cdn/icons-3d/shield.png',
    document: 'https://vyce-prod.s3.eu-west-1.amazonaws.com/cdn/icons-3d/document.png',
  },
  welcome: {
    hiring: 'https://vyce-prod.s3.eu-west-1.amazonaws.com/cdn/welcome-pictures/hiring.png',
    payroll: 'https://vyce-prod.s3.eu-west-1.amazonaws.com/cdn/welcome-pictures/payroll.png',
    projects: 'https://vyce-prod.s3.eu-west-1.amazonaws.com/cdn/welcome-pictures/projects.png',
    teams: 'https://vyce-prod.s3.eu-west-1.amazonaws.com/cdn/welcome-pictures/teams.png',
    time_and_attendance:
      'https://vyce-prod.s3.eu-west-1.amazonaws.com/cdn/welcome-pictures/time-and-attandance.png',
  },
  how_it_works: {
    welcome: 'https://vyce-prod.s3.eu-west-1.amazonaws.com/cdn/how-it-works/welcome.png',
    hiring: 'https://vyce-prod.s3.eu-west-1.amazonaws.com/cdn/how-it-works/hiring.png',
    payroll: 'https://vyce-prod.s3.eu-west-1.amazonaws.com/cdn/how-it-works/payroll.png',
    projects: 'https://vyce-prod.s3.eu-west-1.amazonaws.com/cdn/how-it-works/projects.png',
    teams: 'https://vyce-prod.s3.eu-west-1.amazonaws.com/cdn/how-it-works/teams.png',
    time_and_attendance:
      'https://vyce-prod.s3.eu-west-1.amazonaws.com/cdn/how-it-works/time-and-attendance.png',
  },
  settings: {
    cancel_email_subscription:
      'https://vyce-prod.s3.eu-west-1.amazonaws.com/cdn/settings/cancel-email-subscription.png',
  },
  maintenance: {
    light_mode: 'https://vyce-prod.s3.eu-west-1.amazonaws.com/cdn/maintenance/light-mode.png',
    dark_mode: 'https://vyce-prod.s3.eu-west-1.amazonaws.com/cdn/maintenance/dark-mode.png',
  },
  previewVideo: {
    payroll: 'https://vyce-prod.s3.eu-west-1.amazonaws.com/cdn/video-preview/pay-preview.png',
    teams: 'https://vyce-prod.s3.eu-west-1.amazonaws.com/cdn/video-preview/teams-preview.png',
    time_and_attendance: 'https://vyce-prod.s3.eu-west-1.amazonaws.com/cdn/video-preview/time-preview.png',
  },
};

export default config;
