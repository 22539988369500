import React, { useContext } from 'react';

import { Box, Button, useTheme } from '@material-ui/core';
import { FiDownloadCloud } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';

import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { FilterSection, EnrichedPayslips } from '@vyce/core/src/types';
import { formatTableDate, getTaxPeriod, getTaxYearFilters } from '@vyce/core/src/utils/dates';
import { currencyFormatter } from '@vyce/core/src/utils';
import {
  AppDataGrid,
  AppIconButton,
  AppLink,
  FilterSystem,
  AppMobileDataGrid,
} from '@vyce/core/src/components';
import type { RowSchemaProps, CustomPaginationProps } from '@vyce/core/src/components';
import { DeviceContext } from '@vyce/core/src/contexts';

type EnrichedPayslipsProps = EnrichedPayslips & { id: string };

interface Props {
  payslips: EnrichedPayslipsProps[];
  loading: boolean;
  sortModel?: GridSortModel;
  total?: number;
  height?: string;
  unit?: string;
  withProfileButton?: boolean;
  downloadPdf: Function;
  handleDownloadPaySummaryClick: Function;
  paginationOptions: CustomPaginationProps;
  handlePageChange?: (newPage: number) => void;
  handleSortModelChange?: (newModel: GridSortModel) => void;
  handleFilterChange?: (filters: any) => void;
}

export const PaymentsTable: React.FC<Props> = ({
  payslips,
  loading,
  total,
  unit,
  withProfileButton,
  sortModel,
  height = 'calc(100vh - 310px)',
  paginationOptions,
  downloadPdf,
  handlePageChange,
  handleSortModelChange,
  handleFilterChange,
  handleDownloadPaySummaryClick,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const { isMobile } = useContext(DeviceContext);

  const columns: GridColDef[] = [
    {
      field: 'tax_year_end',
      headerName: 'Period',
      disableColumnMenu: true,
      valueFormatter: params => getTaxPeriod(params.row.tax_week_end, params.row.tax_year_end),
      flex: 0.1,
      minWidth: 140,
    },
    {
      field: 'pay_date',
      headerName: 'Payment Date',
      flex: 0.1,
      disableColumnMenu: true,
      valueGetter: params => formatTableDate(params.row?.pay_date, false),
      minWidth: 150,
    },
    {
      field: 'company',
      headerName: 'Company',
      renderCell: params => (
        <Box display="flex" width="100%">
          <AppLink
            to={`${history.location.pathname}/${params.row.company?.name}_${params.row.company?.company_id}`}>
            {params.row?.company?.name}
          </AppLink>
        </Box>
      ),
      flex: 0.12,
      sortable: false,
      disableColumnMenu: true,
      minWidth: 120,
    },
    {
      field: 'tax_status',
      headerName: 'Tax Status',
      flex: 0.12,
      disableColumnMenu: true,
      minWidth: 120,
    },
    {
      field: 'total_pay',
      headerName: 'Total Pay',
      valueFormatter: params => currencyFormatter.format(params.row.total_pay),
      flex: 0.1,
      disableColumnMenu: true,
      minWidth: 110,
    },
    {
      field: 'total_tax',
      headerName: 'TAX',
      valueFormatter: params => currencyFormatter.format(params.row.total_tax),
      flex: 0.07,
      editable: false,
      disableColumnMenu: true,
      minWidth: 80,
    },
    {
      field: 'deductions',
      headerName: 'Deductions',
      valueFormatter: params => currencyFormatter.format(params.row.deductions),
      flex: 0.07,
      disableColumnMenu: true,
      minWidth: 120,
    },
    {
      field: 'additions',
      headerName: 'Additions',
      valueFormatter: params => currencyFormatter.format(params.row.additions),
      flex: 0.07,
      disableColumnMenu: true,
      minWidth: 120,
    },
    {
      field: 'vat',
      headerName: 'VAT (if applicable)',
      valueFormatter: params => currencyFormatter.format(params.row.vat),
      flex: 0.1,
      editable: false,
      disableColumnMenu: true,
      minWidth: 160,
    },
    {
      field: 'net',
      headerName: 'Take Home',
      valueFormatter: params => currencyFormatter.format(params.row.net),
      flex: 0.1,
      minWidth: 120,
      disableColumnMenu: true,
    },
    {
      field: '',
      headerName: ' ',
      width: 80,
      hideSortIcons: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => (
        <Box display="flex" width="100%">
          <AppIconButton variant="primary" onClick={() => downloadPdf(params.row.pay_run_line_id)}>
            <FiDownloadCloud size={24} color={theme.palette.type === 'dark' ? '#14142B' : '#610BEF'} />
          </AppIconButton>
        </Box>
      ),
    },
  ];

  const mobileOptions: RowSchemaProps<EnrichedPayslipsProps>[][] = [
    [
      {
        field: 'tax_year_end',
        titleName: 'Period',
        valueFormatter: row => getTaxPeriod(row.tax_week_end, row.tax_year_end),
      },
      {
        field: 'pay_date',
        titleName: 'Payment Date',
        valueGetter: row => formatTableDate(row?.pay_date, false),
      },
    ],
    [
      {
        field: 'company',
        titleName: 'Company',
        renderCell: row => (
          <Box display="flex" width="100%">
            <AppLink to={`${history.location.pathname}/${row.company?.name}_${row.company?.company_id}`}>
              {row?.company?.name}
            </AppLink>
          </Box>
        ),
      },
    ],
    [
      {
        field: 'tax_status',
        titleName: 'Tax Status',
      },
    ],
    [
      {
        field: 'total_pay',
        titleName: 'Total Pay',
        valueFormatter: row => currencyFormatter.format(row.total_pay),
      },
      {
        field: 'total_tax',
        titleName: 'TAX',
        valueFormatter: row => currencyFormatter.format(row.total_tax),
      },
    ],
    [
      {
        field: 'deductions',
        titleName: 'Deductions',
        valueFormatter: row => currencyFormatter.format(row.deductions),
      },
      {
        field: 'additions',
        titleName: 'Additions',
        valueFormatter: row => currencyFormatter.format(row.additions),
      },
    ],
    [
      {
        field: 'vat',
        titleName: 'VAT (if applicable)',
        valueFormatter: row => currencyFormatter.format(row.vat),
      },
      {
        field: 'net',
        titleName: 'Take Home',
        valueFormatter: row => currencyFormatter.format(row.net),
        extraComponent: row => (
          <Box display="flex" width="100%">
            <AppIconButton variant="primary" isSmall onClick={() => downloadPdf(row.pay_run_line_id)}>
              <FiDownloadCloud size={14} color={theme.palette.type === 'dark' ? '#14142B' : '#610BEF'} />
            </AppIconButton>
          </Box>
        ),
      },
    ],
  ];

  const shortMobileOptions: RowSchemaProps<EnrichedPayslipsProps>[] = [
    {
      field: 'tax_year_end',
      valueFormatter: row => getTaxPeriod(row.tax_week_end, row.tax_year_end),
    },
    {
      field: 'total_pay',
      valueFormatter: row => currencyFormatter.format(row.total_pay),
    },
  ];

  const mobileColumnNames = [
    { headerName: 'Period', flex: '0 1 49%' },
    { headerName: 'Take Home', flex: '0 1 45%' },
    {
      headerName: 'View',
      position: 'absolute',
      top: '7px',
      right: '33px',
    },
  ];

  const filtersSections: FilterSection[] = [
    {
      title: 'Period',
      expanded: true,
      filters: [
        {
          type: 'select',
          multiple: false,
          label: 'Tax Year',
          field: 'tax_year_end',
          values: ['', ...getTaxYearFilters()],
          defaultValue: '',
        },
      ],
    },
  ];

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="flex-end" mb={1}>
        <Box display="flex" gridGap={16}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleDownloadPaySummaryClick()}>
            Download pay summary
          </Button>
          {handleFilterChange && (
            <FilterSystem filtersSections={filtersSections} onFiltersChange={handleFilterChange} />
          )}
        </Box>
      </Box>

      {isMobile ? (
        <AppMobileDataGrid
          rows={payslips}
          rowsSchema={mobileOptions}
          loading={loading}
          shortSchema={shortMobileOptions}
          paginationOptions={paginationOptions}
          columnNames={mobileColumnNames}
        />
      ) : (
        <AppDataGrid
          noPaper
          rows={payslips}
          height={height}
          getRowId={row => row.pay_run_line_id}
          loading={loading}
          columns={columns}
          rowCount={total}
          paginationMode="server"
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          onPageChange={handlePageChange}
          pageSize={GRID_PAGE_SIZE}
          rowsPerPageOptions={[GRID_PAGE_SIZE]}
          disableSelectionOnClick
          withProfileButton={withProfileButton}
          unit={unit}
        />
      )}
    </>
  );
};
