import React, { useContext } from 'react';

import { Box, LinearProgress } from '@material-ui/core';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  ActivateSubscriptionDialog,
  CommonNavBarBlock,
  SuccessSubscriptionDialog,
} from '@vyce/core/src/components';
import { DeviceContext, TeamsPageProvider } from '@vyce/core/src/contexts';
import { getPaddingForContent } from '@vyce/core/src/utils';

import {
  GetStartedModule,
  TeamMembersModule,
  TeamModule,
  TeamsDashboardModule,
  TeamsListModule,
  UniversalInviteModule,
} from 'src/modules';
import { useTypedSelector } from 'src/hooks';

import { useStyles } from './styles';
import { TEAMS_TABS } from './config';
import { useTeamsData } from './hooks';

export const EmployerTeams: React.FC = () => {
  const { isMobile } = useContext(DeviceContext);
  const { selectedCompanyAppData, selectedCompany } = useTypedSelector(state => state.helper);
  const { companyDataLoading, teams } = selectedCompanyAppData || {};
  const padding = getPaddingForContent(isMobile);

  const {
    loading,
    contextValue,
    isOpenSuccessDialog,
    firstIdOfTeam,
    isInviteFormOpen,
    selectedCompanyIsSubscribed,
    hasPermissions,
    hasBillingPermissions,
    subscribeLoading,
    isActivateSubscriptionDialogOpen,
    hadSubscription,
    simpleSubscribe,
    setOpenSuccessDialog,
    setCloseInviteForm,
    closeActivateSubscriptionDialog,
  } = useTeamsData();
  const classes = useStyles();

  if (companyDataLoading || (loading && !teams?.length)) {
    return <LinearProgress />;
  }

  if (!hasPermissions) {
    return <GetStartedModule hasPermissions={hasPermissions} />;
  }

  if ((!selectedCompanyIsSubscribed && hasBillingPermissions) || (!teams?.length && !loading)) {
    return (
      <TeamsPageProvider value={contextValue}>
        <GetStartedModule hadSubscription={hadSubscription} />
      </TeamsPageProvider>
    );
  }

  return (
    <TeamsPageProvider value={contextValue}>
      <Box height="100%">
        <Box className={classes.moduleContainer}>
          <CommonNavBarBlock tabItems={TEAMS_TABS} />

          <Box padding={padding} display="flex" flexDirection="column" className={classes.container}>
            <Switch>
              <Route path="/teams/dashboard" component={TeamsDashboardModule} />
              <Route path="/teams/members" component={TeamMembersModule} />
              <Route exact path="/teams/list" component={TeamsListModule} />
              <Route exact path="/teams/list/:team" component={TeamModule} />
              <Route path="/teams" render={() => <Redirect to={{ pathname: '/teams/dashboard' }} />} />
            </Switch>
          </Box>
        </Box>
      </Box>
      <SuccessSubscriptionDialog
        open={isOpenSuccessDialog}
        setOpen={setOpenSuccessDialog}
        teamsText="Add team members now"
        withTeams
        teamsLink={firstIdOfTeam && `/teams/list/${firstIdOfTeam}?invite=true`}
      />
      <UniversalInviteModule onClose={setCloseInviteForm} isOpen={isInviteFormOpen} />
      <ActivateSubscriptionDialog
        open={isActivateSubscriptionDialogOpen}
        onClose={closeActivateSubscriptionDialog}
        callback={simpleSubscribe}
        loading={subscribeLoading}
        wasSubscribed={selectedCompany?.subscribed_company}
      />
    </TeamsPageProvider>
  );
};
