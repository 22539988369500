import { useState } from 'react';

import { useHistory } from 'react-router-dom';

import { useBooleanState } from '../../../hooks';
import { useCardDetails } from './useCardDetails';
import { useBillingContact } from './useBillingContact';
import { EditOptions } from '../constants';
import { COUNTRIES } from '../../../constants';
import { Company, User } from '../../../types';

interface Props {
  selectedCompany?: Company;
  token: string;
  user?: User;
}

export const useBillingInfoData = ({ selectedCompany, token, user }: Props) => {
  const [isLoginDialogOpen, openLoginDialog, closeLoginDialog] = useBooleanState(false);
  const [editTypeMode, setEditTypeMode] = useState<EditOptions | null>(null);
  const { billingContactOptions, billingContactDefaultValues } = useBillingContact({
    token,
    user,
    selectedCompany,
  });
  const {
    getDecryptedCardDetails,
    updateCardDetails,
    gettingDecryptedCard,
    paymentDetails,
    decryptedDetails,
    setDecryptedDetails,
  } = useCardDetails({ selectedCompany, token });
  const history = useHistory();

  const nextPaymentDate = ''; // TODO change then API will be ready
  const { name: selectedCompanyName = '' } = selectedCompany ?? {};

  const handleNext = () => {
    const editModeUrl = editTypeMode === EditOptions.BILLING ? 'contact' : 'payment-details';
    history.push(`${history.location.pathname}/${editModeUrl}`);
  };

  const handleOpenLoginForBillingEdit = () => {
    setEditTypeMode(EditOptions.BILLING);
    openLoginDialog();
  };

  const handleOpenLoginForPaymentEdit = () => {
    setEditTypeMode(EditOptions.PAYMENT);
    openLoginDialog();
  };

  const handleShowCardDetailsClick = () => {
    if (decryptedDetails) {
      setDecryptedDetails(null);
    } else {
      getDecryptedCardDetails();
    }
  };

  const getCountryName = (iso?: string): string => {
    if (!iso) {
      return '';
    }
    return COUNTRIES.find(country => country.code === iso)?.name || iso;
  };

  return {
    editTypeMode,
    nextPaymentDate,
    isLoginDialogOpen,
    selectedCompanyName,
    billingContactOptions,
    billingContactDefaultValues,
    handleNext,
    closeLoginDialog,
    paymentDetails,
    handleShowCardDetailsClick,
    handleOpenLoginForBillingEdit,
    handleOpenLoginForPaymentEdit,
    updateCardDetails,
    gettingDecryptedCard,
    decryptedDetails,
    getCountryName,
  };
};
