import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { userActionCreators, helperActionCreators } from '../store';

export const useActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(
    {
      ...userActionCreators,
      ...helperActionCreators,
    },
    dispatch
  );
};
