import { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Box, Typography } from '@material-ui/core';

import { JobsField } from '@vyce/core/src/components/controlled-inputs/JobsField';
import { AppTextField } from '@vyce/core/src/components/inputs/AppTextField';

import { HiringContext } from '../../../../modules/hiringModule/context/hiringContext';

export const WorkerType = () => {
  const { control } = useFormContext();
  const { isEditMode, token } = useContext(HiringContext);

  return (
    <Box>
      <Typography variant="subtitle1" color="primary">
        What kind of worker are you looking for?
      </Typography>

      <JobsField
        label="Job title"
        name="name"
        margin="normal"
        disabled={isEditMode}
        token={token}
        multiple={false}
        rules={{ required: 'Roles are required' }}
      />

      <Controller
        control={control}
        name="number_of_workers"
        render={({ field }) => (
          <AppTextField
            {...field}
            type="number"
            margin="normal"
            label="How many do you need?"
            id="workers-quantity"
            fullWidth
          />
        )}
      />
    </Box>
  );
};
