import { useEffect, useState } from 'react';

export const useDebounceValue = <T>(value: T, delay: number) => {
  const [debounceValue, setDebounceValue] = useState<T | null>(null);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debounceValue;
};
