import { Box, Typography } from '@material-ui/core';

import { useStyles } from '../styles';
import { HeaderItemProps } from '../types';

export const HeaderItem = ({ headerName, flex, ...rest }: HeaderItemProps) => {
  const classes = useStyles();
  return (
    <Box flex={flex ? flex : 'unset'} {...rest}>
      <Typography className={classes.text}>{headerName}</Typography>
    </Box>
  );
};
