import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { AppTextField } from '@vyce/core/src/components/inputs';
import { CustomLocationField } from '@vyce/core/src/api/types';

export const CustomField = ({ system_name, name, value }: CustomLocationField) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={`customFields.${system_name}`}
      render={({ field }) => <AppTextField label={name} margin="normal" fullWidth {...field} />}
    />
  );
};
