import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FieldProps } from '../../types';
import { AppTextField } from '../inputs';

export const BuildingSocietyRollNumberField: React.FC<FieldProps> = ({
  margin = undefined,
  disabled = false,
  name = 'bank_account.building_society_roll_number',
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <AppTextField
          {...field}
          disabled={disabled}
          label="Building Society Roll Number (if applicable)"
          margin={margin}
          type="tel"
          fullWidth
        />
      )}
    />
  );
};
