import { ChangeEvent, useCallback, useMemo, useState } from 'react';

import type { GridSortModel } from '@mui/x-data-grid';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';

import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';

const defaultArray: GridSortModel = [];

/**
 * A custom hook for managing table state and behavior.
 *
 * @param {object} options - Options for the hook.
 * @param {GridSortModel} [options.defaultSortModel=defaultArray] - The default sort model for the table.
 * @return {object} An object containing the table state and behavior handlers.
 */

export const useTable = ({
  defaultSortModel = defaultArray,
  initialSubstring = '',
}: {
  defaultSortModel?: GridSortModel;
  initialSubstring?: string;
}) => {
  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSortModel);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);
  const [substring, setSubstring] = useState(initialSubstring);
  const [total, setTotal] = useState(0);

  const handlePageChange = useCallback((newPage: number) => {
    setOffset(newPage * GRID_PAGE_SIZE);
    setPage(newPage);
  }, []);

  const handleSortModelChange = useCallback(
    (newModel: GridSortModel) => {
      if (!isEqual(newModel, sortModel)) {
        setSortModel(newModel);
      }
    },
    [sortModel]
  );

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSubstring(e.target.value);
  }, []);

  const debouncedSearchChange = useMemo(() => debounce(handleSearchChange, 500), [handleSearchChange]);

  return {
    sortModel,
    offset,
    substring,
    total,
    page,
    setOffset,
    setSubstring,
    setTotal,
    handlePageChange,
    handleSortModelChange,
    handleSearchChange: debouncedSearchChange,
  };
};
