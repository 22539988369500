import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from '../../styles';
import { OnboardingStepTitle } from '@vyce/core/src/components/OnboardingStepTitle';
import { AppA } from '@vyce/core/src/components/AppA';
import { TERMS_AND_CONDITIONS_LINK } from '../../constants';

export const CISConfirmationStep: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.form}>
      <OnboardingStepTitle text="Confirmation" />

      <Typography variant="body1">
        By providing my details and submitting this form I confirm and acknowledge:
      </Typography>
      <Typography variant="body2" className={classes.confirmText} color="textSecondary">
        That all information provided is true and accurate and is being provided with my full consent.
      </Typography>
      <Typography variant="body2" className={classes.confirmText} color="textSecondary">
        That I understand and accept the Vyce{' '}
        <AppA href={TERMS_AND_CONDITIONS_LINK} content="Vyce Terms & Conditions" />.
      </Typography>
      <Typography variant="body2" className={classes.confirmText} color="textSecondary">
        I agree to VYCE holding my personal information.
      </Typography>
    </div>
  );
};
