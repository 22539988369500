import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { Box, Typography } from '@material-ui/core';

import { AppA, OnboardingStepTitle } from '@vyce/core/src/components';
import { NationalityField } from '@vyce/core/src/components/controlled-inputs';
import { AppTextField } from '@vyce/core/src/components/inputs';
import { TitleProps } from './types';

const shareCodeLink = 'https://www.gov.uk/prove-right-to-work/get-a-share-code-online';

const rules = { required: 'Share Code is required' };
const NO_SHARE_CODE_ISO = ['GBR', 'IRL'];

export const NationalityStep = ({ title = 'What is your Nationality?' }: TitleProps) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const nationality = watch('nationality');

  return (
    <>
      <OnboardingStepTitle text={title} />
      <NationalityField margin="normal" />

      {nationality && !NO_SHARE_CODE_ISO.includes(nationality.iso3) && (
        <Box display="flex" flexDirection="column" gridGap={16}>
          <Controller
            control={control}
            rules={rules}
            name="shareCode"
            render={({ field }) => (
              <AppTextField
                fullWidth
                {...field}
                onChange={e => field.onChange(e.target.value.toUpperCase())}
                margin="normal"
                error={!!errors?.shareCode?.message}
                helperText={(errors?.shareCode?.message as string) || ''}
                label="Share Code"
              />
            )}
          />
          <Box>
            <Typography display="inline" style={{ fontSize: 13 }}>
              Don't have a share code?
            </Typography>{' '}
            <AppA href={shareCodeLink} content="Click here and follow the instructions to get one." />
          </Box>
        </Box>
      )}
    </>
  );
};
