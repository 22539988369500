import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FieldProps } from '../../types';
import { AppTextField } from '../inputs/AppTextField';
import { capitalizeEach } from '../../utils';

export const FirstNameField: React.FC<FieldProps> = ({
  margin = undefined,
  disabled = false,
  autoFocus,
  rules = { required: 'First Name is required' },
  ...props
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      rules={rules}
      name="first_name"
      render={({ field }) => (
        <AppTextField
          {...field}
          {...props}
          onChange={e => field.onChange(capitalizeEach(e.target.value))}
          error={!!errors.first_name?.message}
          disabled={disabled}
          autoFocus={autoFocus}
          id="first-name"
          cy-test-id="first-name"
          label="First Name"
          margin={margin}
          fullWidth
          helperText={(errors.first_name?.message as string) || ''}
        />
      )}
    />
  );
};
