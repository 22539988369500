import dayjs from 'dayjs';

import { PaySchedule } from '@vyce/core/src/types';
import { getWeekDayNumber } from '@vyce/core/src/utils/dates';

import { Form } from '../types';

export const preparePayScheduleData = (data: Form): PaySchedule => {
  const pay_last_workday = data.pay_last_workday === null ? data.pay_last_workday : !!data.pay_last_workday;
  const pay_weekday = pay_last_workday === null && data?.pay_weekday ? +data.pay_weekday : null;
  return {
    pay_frequency: data.pay_frequency,
    name: data.name,
    pay_weekday,
    pay_last_workday,
    payroll_paid_by_worker: !!data.payroll_paid_by_worker,
    email_pay_statement:
      data.email_pay_statement && data.emailWeekday !== undefined
        ? dayjs(data.email_pay_statement).day(getWeekDayNumber(data.emailWeekday)).format()
        : null,
    sms_pay_statement:
      data.sms_pay_statement && data.SMSWeekday !== undefined
        ? dayjs(data.sms_pay_statement).day(getWeekDayNumber(data.SMSWeekday)).format()
        : null,
  };
};
