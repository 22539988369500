import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { GridSortModel } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { Company, EnrichedPayslips } from '@vyce/core/src/types';
import { generateExcelFileLink, generateFileLinkAndSave } from '@vyce/core/src/utils';
import { GetPayslipsRequest } from '@vyce/core/src/api/types';
import { formatSortModel } from '@vyce/core/src/utils/sorting';
import { getUrlItems } from '@vyce/core/src/utils/url';
import { useTable } from '@vyce/core/src/hooks/useTable';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import { PaymentsTable } from './PaymentsTable';

interface Props {
  searchPayslipsRequest: (
    params: GetPayslipsRequest
  ) => Promise<{ data: { items: EnrichedPayslips[]; count: number } }>;
  downloadUserPayslipsRequest: (params: GetPayslipsRequest) => Promise<AxiosResponse>;
  getPayslipsPDFRequest: (id: string, companyId?: string) => Promise<AxiosResponse>;
  selectedCompany?: Company;
  isLegend: boolean;
  unit?: string;
  withProfileButton?: boolean;
}

type EnrichedPayslipsProps = EnrichedPayslips & { id: string };

const defaultSortModel: GridSortModel = [{ field: 'pay_date', sort: 'desc' }];

export const EmployeePayments: React.FC<Props> = ({
  searchPayslipsRequest,
  downloadUserPayslipsRequest,
  getPayslipsPDFRequest,
  isLegend,
  selectedCompany,
  unit,
  withProfileButton,
}) => {
  const { handleServerError } = useContext(NotificationContext);
  const [payslips, setPayslips] = useState<EnrichedPayslipsProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<any>({});
  const { id } = useParams<{ id: string }>();

  const { offset, total, page, sortModel, setTotal, handlePageChange, handleSortModelChange } = useTable({
    defaultSortModel,
  });

  const pageCount = Math.ceil(total / GRID_PAGE_SIZE);
  const selectedCompanyId = selectedCompany?.uuid;

  const downloadPdf = async (id: string) => {
    try {
      const res = await getPayslipsPDFRequest(id, selectedCompanyId);
      generateFileLinkAndSave(res);
    } catch (e) {
      handleServerError(e);
    }
  };

  const getPayslips = useCallback(async () => {
    try {
      const urlItems = getUrlItems(id);
      setLoading(true);
      const res = await searchPayslipsRequest({
        userId: urlItems.id,
        companyId: isLegend ? undefined : selectedCompanyId,
        data: {
          offset,
          order_by: formatSortModel<EnrichedPayslipsProps>(sortModel),
          tax_year_end: filters?.tax_year_end?.value,
          limit: GRID_PAGE_SIZE,
        },
      });
      setLoading(false);
      setPayslips(
        res.data.items.filter(item => item.net !== 0).map((item, i) => ({ ...item, id: String(i) }))
      );
      setTotal(res.data.count);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  }, [id, isLegend, selectedCompanyId, offset, sortModel, filters]);

  const paginationOptions = useMemo(
    () => ({
      page,
      pageSize: GRID_PAGE_SIZE,
      pageCount,
      rowCount: total,
      setPage: handlePageChange,
    }),
    [page, pageCount, total, handlePageChange]
  );

  const handleFilterChange = (newFilters: any) => {
    setFilters(newFilters);
  };

  const handleDownloadPaySummaryClick = async () => {
    try {
      const urlItems = getUrlItems(id);
      const res = await downloadUserPayslipsRequest({
        userId: urlItems.id,
        companyId: isLegend ? undefined : selectedCompany?.uuid,
        data: {
          tax_year_end: filters?.tax_year_end?.value,
          order_by: formatSortModel<EnrichedPayslipsProps>(sortModel),
          limit: 10000,
        },
      });
      generateExcelFileLink(res);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  };

  useEffect(() => {
    getPayslips();
  }, [getPayslips]);

  return (
    <PaymentsTable
      handleDownloadPaySummaryClick={handleDownloadPaySummaryClick}
      downloadPdf={downloadPdf}
      height={'calc(100vh - 240px)'}
      handlePageChange={handlePageChange}
      handleFilterChange={handleFilterChange}
      handleSortModelChange={handleSortModelChange}
      sortModel={sortModel}
      loading={loading}
      payslips={payslips}
      total={total}
      unit={unit}
      withProfileButton={withProfileButton}
      paginationOptions={paginationOptions}
    />
  );
};
