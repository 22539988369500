import React, { useContext } from 'react';

import { Box, Button } from '@material-ui/core';

import { OnboardingStepTitle } from '@vyce/core/src/components/OnboardingStepTitle';

import { ManualClockContext } from './ManualClockProvider';

const styles = { minWidth: 120 };

export const LocationVerifiedStep = () => {
  const { verifiedLocation, onChangeVerifiedLocation } = useContext(ManualClockContext);

  const handleVerifiedLocation = () => {
    onChangeVerifiedLocation(true);
  };

  const handleUnverifiedLocation = () => {
    onChangeVerifiedLocation(false);
  };

  return (
    <>
      <OnboardingStepTitle text="Is the worker’s location verified?" />

      <Box display="flex" justifyContent="center" marginTop={2} gridGap={16}>
        <Button
          onClick={handleVerifiedLocation}
          size="large"
          style={styles}
          variant={verifiedLocation ? 'contained' : 'outlined'}
          color="primary">
          Yes
        </Button>
        <Button
          onClick={handleUnverifiedLocation}
          size="large"
          style={styles}
          variant={verifiedLocation === false ? 'contained' : 'outlined'}
          color="primary">
          No
        </Button>
      </Box>
    </>
  );
};
