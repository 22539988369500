import { Dispatch, createContext } from 'react';

import { Company, User } from '@vyce/core/src/types';

interface Context {
  isLegend?: boolean;
  user?: User;
  companies: Company[];
  uploadUserAvatar?: (file: File, saveToProfile?: boolean) => (dispatch: Dispatch<any>) => Promise<void>;
}

export const ManuallyAddingMemberContext = createContext({} as Context);

export const ManuallyAddingMemberProvider = ManuallyAddingMemberContext.Provider;
