import React from 'react';

import { Box } from '@material-ui/core';

import { OnboardingStepTitle, ImageUploader } from '@vyce/core/src/components';

interface Props {
  setAgreement: Function;
}

export const UploadAgreementStep: React.FC<Props> = ({ setAgreement }) => {
  return (
    <Box position="relative">
      <OnboardingStepTitle
        text="Custom agreement..."
        additionalText="If you have a custom agreement that you want your payrollees to sign, upload it now and we'll have it automatically completed and copies filed for both you and your payrollee."
      />

      <Box marginTop={2}>
        <ImageUploader
          extraText="agreement"
          width={250}
          height={150}
          isDocument={true}
          onImageUpload={image => setAgreement(image.file)}
          type="pdf"
        />
      </Box>
    </Box>
  );
};
