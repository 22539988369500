import { axios } from '@vyce/core/src/api';

import { SendDocumentRequest } from '../types';

export const sendPassportRequest = ({ token, companyId, userId }: SendDocumentRequest) => {
  return axios.post(`/notifications/companies/${companyId}/users/${userId}/share_passport_email`);
};

export const sendShareCodeRequest = ({ token, companyId, userId }: SendDocumentRequest) => {
  return axios.post(`/notifications/companies/${companyId}/users/${userId}/share_code_email`);
};

export const getEmailUnsubscriptionGroupsRequest = () => {
  return axios.get(`/notifications/unsubscribe/groups`);
};

export const subscribeRequest = ({ token, groups }: { token: string; groups: string[] }) => {
  return axios.delete(`/notifications/unsubscribe`, {
    data: { groups },
  });
};

export const unsubscribeRequest = ({ token, groups }: { token: string; groups: string[] }) => {
  return axios.post(`/notifications/unsubscribe`, { groups });
};
