import React from 'react';

import noop from 'lodash/noop';

import { Box, Typography } from '@material-ui/core';

import { HoldingPage } from '@vyce/core/src/components/HoldingPage';
import config from '@vyce/core/src/assets/config';

export const GetStarted: React.FC = () => {
  return (
    <HoldingPage
      disabledButton={true}
      title="Projects"
      buttonText="Coming soon..."
      handleButtonClick={noop}
      image={config.welcome.projects}>
      <Box marginBottom={4}>
        <Box marginTop={1} display="flex">
          <Typography>Track and manage your projects all in one place with Vyce Projects.</Typography>
        </Box>

        <Box marginTop={3}>
          <Typography>
            Get real time reporting on tasks, track milestones and deadlines and keep all your project
            documents in one place for all relevant team members to access.
          </Typography>
        </Box>

        <Box marginTop={3}>
          <Typography>
            Simply create a new Project or request to join an existing Project to get started.
          </Typography>
        </Box>
      </Box>
    </HoldingPage>
  );
};
