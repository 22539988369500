import React from 'react';

import { OnboardingStepTitle } from '@vyce/core/src/components';
import { ControlledDateField } from '@vyce/core/src/components/controlled-inputs';
import { TitleProps } from './types';

export const DateOfBirthStep = ({ title = 'Date of Birth' }: TitleProps) => {
  return (
    <>
      <OnboardingStepTitle text={title} />
      <ControlledDateField name="date_of_birth" margin="normal" label="Date of Birth" disableFuture />
    </>
  );
};
