import React from 'react';

import { Avatar, Box, Button, Grid, Typography } from '@material-ui/core';
import { MdCheckBoxOutlineBlank } from 'react-icons/md';
import { IoCheckbox } from 'react-icons/io5';
import { useParams } from 'react-router-dom';

import { Job } from '@vyce/core/src/types';
import { useStyles } from '@vyce/core/src/views/hiring/styles';
import { getJobSalary } from '@vyce/core/src/utils/job';
import { getUrlItems } from '@vyce/core/src/utils/url';
import defaultCompanyPicture from '@vyce/core/src/assets/company-icon.png';
import { TileContainer } from '@vyce/core/src/views/hiring/components/TileContainer';
import { AppCheckbox } from '@vyce/core/src/components/inputs';

interface Props {
  currentJob: Job;
  isSelectMode?: boolean;
  selected?: boolean;
  onJobDuplicate: (job: Job) => void;
  goToCandidates: (job: Job) => void;
  handleDraftSelect?: (job: Job, checked: boolean) => void;
  goToJob: (id: number | string, name: string) => void;
}

export const EmployerJobTile: React.FC<Props> = ({
  currentJob,
  isSelectMode,
  selected,
  onJobDuplicate,
  goToCandidates,
  handleDraftSelect,
  goToJob,
}) => {
  const classes = useStyles();
  const { job } = useParams<{ job: string }>();
  const jobUrlItems = getUrlItems(job);
  const id = jobUrlItems?.id;

  const viewCandidates = (e: any) => {
    e.stopPropagation();
    goToCandidates(currentJob);
  };

  const handleTileClick = () => {
    if (isSelectMode && handleDraftSelect) {
      handleDraftSelect(currentJob, !selected);
    } else {
      goToJob(currentJob.uuid, currentJob.name as string);
    }
  };

  return (
    <TileContainer
      isClickable={currentJob.status === 'live'}
      selected={id === currentJob.uuid || selected}
      handleClick={handleTileClick}>
      <>
        <Box
          display="flex"
          alignItems="center"
          position="relative"
          justifyContent="space-between"
          marginBottom={2}>
          <Box display="flex" alignItems="center" gridGap={16}>
            <Avatar className={classes.avatar} src={currentJob?.company?.logo?.url}>
              {!currentJob?.company?.logo && <img height={40} alt="company" src={defaultCompanyPicture} />}
            </Avatar>

            <Typography variant="h6" color="primary" className={classes.bold}>
              {currentJob.name as string}
            </Typography>
          </Box>

          {isSelectMode && handleDraftSelect && (
            <Box position="relative" top={-13} right={-7}>
              <AppCheckbox
                checked={!!selected}
                icon={<MdCheckBoxOutlineBlank size="18px" />}
                checkedIcon={<IoCheckbox size="18px" />}
                color="primary"
                variant="rectangle"
              />
            </Box>
          )}
        </Box>

        <Grid container>
          <Grid item xs={6} className={classes.jobDataItem}>
            <Typography variant="subtitle2" color="textSecondary">
              Job Location
            </Typography>
            <Typography variant="subtitle2">{currentJob.address?.address_line_4 || 'Unknown'}</Typography>
          </Grid>

          <Grid item xs={6} className={classes.jobDataItem}>
            <Typography variant="subtitle2" color="textSecondary">
              Duration
            </Typography>
            <Typography className={classes.capitalize} variant="subtitle2">
              {currentJob.job_type}
              {currentJob.duration_type && currentJob.duration
                ? `, ${currentJob.duration} ${currentJob.duration_type}`
                : ''}
            </Typography>
          </Grid>
        </Grid>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={classes.controlArea}>
          <Typography variant="subtitle2" color="primary">
            {getJobSalary(currentJob.salary_from, currentJob.salary_to, currentJob.salary_type)}
          </Typography>

          {currentJob.status === 'live' ? (
            <Button
              variant="outlined"
              onClick={viewCandidates}
              style={{ whiteSpace: 'nowrap' }}
              color="primary">
              View Candidates
            </Button>
          ) : (
            <Button variant="outlined" onClick={() => onJobDuplicate(currentJob)} color="primary">
              Edit Job
            </Button>
          )}
        </Box>
      </>
    </TileContainer>
  );
};
