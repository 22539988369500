import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { GRID_PAGE_SIZE, TABLE_OFFSET_DELAY } from '@vyce/core/src/constants';
import {
  approveByPeriodRequest,
  approveBySiteRequest,
  getTimeLogsByLocationRequest,
  postExportTimeLogsRequest,
} from '@vyce/core/src/api/time';
import { TIME_INTERFACE_PERMISSIONS, TimeLogBySite, TimeTotalInfoProps } from '@vyce/core/src/types';
import { useBooleanState } from '@vyce/core/src/hooks/useBooleanState';
import { useDebounceValue } from '@vyce/core/src/hooks/useDebounceValue';
import { useTable } from '@vyce/core/src/hooks/useTable';

import { DeviceContext } from '@vyce/core/src/contexts';
import { generateExcelFileLink, isNil } from '@vyce/core/src/utils';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import { getTransformedTotalData } from '../utils';
import { allLocationsId } from '../config';
import { Props } from '..';

export const useTimeLogsData = ({ companyId, userPermissions }: Props) => {
  const [count, setCount] = useState<number>(0);
  const { handleServerError } = useContext(NotificationContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [logs, setLogs] = useState<TimeLogBySite[]>([]);
  const [totals, setTotals] = useState<TimeTotalInfoProps>();
  const history = useHistory();
  const { isMobile } = useContext(DeviceContext);
  const [approveLoading, setApproveLoading] = useState<boolean>(false);
  const [selectedLog, setSelectedLog] = useState<TimeLogBySite>();
  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] = useBooleanState(false);
  const { period } = useParams<{ period: string }>();

  const { offset, substring, setOffset, handlePageChange, handleSearchChange } = useTable({});

  const dOffset = useDebounceValue(offset, TABLE_OFFSET_DELAY);

  const cnaApproveTimelogs = useMemo(
    // probably can be replaced with another permission when backend will update the manager permission list
    () =>
      userPermissions?.length
        ? userPermissions.includes(TIME_INTERFACE_PERMISSIONS.LOCATIONS_AND_SHIFTS)
        : true,
    [userPermissions]
  );

  const periods = period?.split(' - ');

  const getTimeLogs = useCallback(async () => {
    if (!companyId || isNil(dOffset)) {
      return;
    }

    try {
      const periods = period.split(' - ');
      setLoading(true);
      const { data } = await getTimeLogsByLocationRequest(companyId, {
        offset: dOffset as number,
        substring,
        limit: dOffset === 0 ? GRID_PAGE_SIZE + 1 : GRID_PAGE_SIZE,
        start_date: periods[0],
        end_date: periods[1],
      });

      setLogs([getTransformedTotalData(data.totals) as TimeLogBySite, ...data.items]);
      setCount(data.count);
      setTotals(data.totals);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  }, [companyId, dOffset, period, substring]);

  const handleApproveButtonClick = (log: TimeLogBySite) => {
    setSelectedLog(log);
    openConfirmDialog();
  };

  const handleApproveRequest = () => {
    if (selectedLog?.site_id === allLocationsId) {
      approveAllLocations();
    } else {
      approve();
    }
  };

  const approveAllLocations = async () => {
    if (!companyId || !selectedLog?.site_id) {
      return;
    }

    try {
      setApproveLoading(true);
      await approveByPeriodRequest(companyId, {
        start: periods[0],
        end: periods[1],
      });
      getTimeLogs();
      setApproveLoading(false);
      closeConfirmDialog();
    } catch (e) {
      setApproveLoading(false);
      handleServerError(e);
      console.error(e);
    }
  };

  const approve = async () => {
    if (!companyId || !selectedLog?.site_id) {
      return;
    }

    try {
      setApproveLoading(true);
      await approveBySiteRequest(companyId, selectedLog.site_id, {
        start: periods[0],
        end: periods[1],
      });
      getTimeLogs();
      setApproveLoading(false);
      closeConfirmDialog();
    } catch (e) {
      setApproveLoading(false);
      handleServerError(e);
      console.error(e);
    }
  };

  const goToByWorkerView = (name: string, id: string) => {
    if (id === allLocationsId) {
      history.push(`${history.location.pathname}/${id}`);
    } else {
      history.push(`${history.location.pathname}/${name}_${id}`);
    }
  };

  const exportTimelogs = async (log: TimeLogBySite) => {
    if (!companyId) {
      return;
    }
    try {
      const res = await postExportTimeLogsRequest({
        companyId,
        data: { period: { start: periods[0], end: periods[1] }, sites_ids: [log.site_id] },
      });
      generateExcelFileLink(res);
    } catch (e) {
      handleServerError(e);
    }
  };

  useEffect(() => {
    getTimeLogs();
  }, [getTimeLogs]);

  return {
    totals,
    isMobile,
    logs,
    count,
    loading,
    isConfirmDialogOpen,
    approveLoading,
    cnaApproveTimelogs,
    offset,
    periods,
    handleApproveRequest,
    handlePageChange,
    closeConfirmDialog,
    handleSearchChange,
    goToByWorkerView,
    exportTimelogs,
    handleApproveButtonClick,
    setOffset,
  };
};
