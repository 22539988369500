import { OnboardingStep } from '../types';

const ONBOARDING_DATA_LS_KEY = 'onboarding';
const ONBOARDING_STEP_LS_KEY = 'onboardingStep';

export const getFromLS = (key: string) => {
  let data: any;
  if (global.localStorage) {
    const fromLS = global.localStorage.getItem(key) || '';
    try {
      data = JSON.parse(fromLS) || undefined;
    } catch (e) {}
  }
  return data;
};

export const saveToLS = (key: string, value: any) => {
  if (global.localStorage) {
    global.localStorage.setItem(key, JSON.stringify(value));
  }
};

export const saveOnboardingDataToLS = (data: any, stepNumber: number) => {
  saveToLS(ONBOARDING_STEP_LS_KEY, stepNumber);
  saveToLS(ONBOARDING_DATA_LS_KEY, data);
};

export const setOnboardingData = (
  setValue: Function,
  setActiveStep: Function,
  nextStep: Function,
  steps: OnboardingStep[]
) => {
  const onboardingData = getFromLS(ONBOARDING_DATA_LS_KEY);
  const onboardingStepNumber = getFromLS(ONBOARDING_STEP_LS_KEY);
  let status = false;
  const activeStep = steps[onboardingStepNumber];
  if (activeStep) {
    setActiveStep(activeStep);
    nextStep(onboardingStepNumber);
    status = true;
  }
  if (onboardingData) {
    Object.keys(onboardingData).forEach((key: typeof onboardingData) => setValue(key, onboardingData[key]));
  }
  return status;
};

export const clearOnboardingLSData = () => {
  saveToLS(ONBOARDING_DATA_LS_KEY, undefined);
  saveToLS(ONBOARDING_STEP_LS_KEY, undefined);
};
