import { useCallback, useContext, useEffect, useState } from 'react';

import { ComputedDatum } from '@nivo/pie';
import { useHistory } from 'react-router-dom';

import { getOnSiteTradesDataRequest } from '@vyce/core/src/api/time';
import { generateQueryParamsFromObj } from '@vyce/core/src/utils/url';

import { Props, WidgetDataItem } from '../types';
import { NotificationContext } from '../../../../contexts/notificationContext';

export const useData = ({ selectedCompanyId }: Props) => {
  const [loading, setLoading] = useState(false);
  const { handleServerError } = useContext(NotificationContext);
  const [widgetData, setWidgetData] = useState<WidgetDataItem[]>([]);
  const history = useHistory();

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await getOnSiteTradesDataRequest(selectedCompanyId);

      const preparedData = data.trades.map(item => ({
        id: item.trade,
        label: item.trade,
        value: item.count,
        query_field: { role: item.trade },
      }));
      setWidgetData(preparedData);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  }, [selectedCompanyId]);

  const handleChartClick = (datum: ComputedDatum<any>) => {
    const query = generateQueryParamsFromObj(datum.data.query_field);
    history.push(`/teams/members?${query}`);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { widgetData, loading, handleChartClick };
};
