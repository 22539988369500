import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    showMoreTextAnchor: {
      color: theme.palette.primary.dark,
      fontWeight: 600,
      fontSize: '15px',
      lineHeight: '24px',
    },

    showMoreTextContent: {
      cursor: 'pointer',
      wordBreak: 'break-word',
    },
  })
);
