import React from 'react';

import { Box } from '@material-ui/core';
import { VscClose } from 'react-icons/vsc';

import { ImageUploader } from '@vyce/core/src/components';
import { Image } from '@vyce/core/src/types';

import useStyles from '../styles';
import { previewControlSet } from '../config';

export const DocumentComponent = ({
  orderNumber,
  image,
  onRemove,
  onImageUpload,
  ...restProps
}: {
  image: Image;
  width: number;
  height: number;
  orderNumber?: number;
  onRemove?: (arg0: { image: Image; index: number }) => void;
  onImageUpload?: (image: Image) => void;
}) => {
  const classes = useStyles();

  const handleDelete = () => {
    onRemove && onRemove({ index: orderNumber ?? 0, image });
  };

  return (
    <Box position="relative">
      <ImageUploader
        image={image}
        extraText="new files"
        uploadText="Click to upload"
        extraSmallSize={true}
        isDocument={true}
        onImageUpload={onImageUpload}
        type="both"
        outsideControl
        customControlSet={previewControlSet}
        {...restProps}
      />
      {onRemove && (
        <Box className={classes.removeButton} onClick={handleDelete}>
          <VscClose size={16} />
        </Box>
      )}
    </Box>
  );
};
