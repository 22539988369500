import React from 'react';
import { Box, FormControl, FormControlLabel, RadioGroup } from '@material-ui/core';
import { AppRadioButton } from '@vyce/core/src/components/inputs/AppRadio';
import { Controller, useFormContext } from 'react-hook-form';
import { OnboardingStepTitle } from '@vyce/core/src/components/OnboardingStepTitle';
import { PAY_FREQUENCIES } from '../../../constants';

export const PayFrequencyStep: React.FC = () => {
  const { control } = useFormContext();

  return (
    <Box>
      <OnboardingStepTitle text="OK, great. How often do you want to pay your team?" />

      <Controller
        render={({ field }) => (
          <FormControl component="fieldset" style={{ width: '100%' }}>
            <RadioGroup value={field.value || null} onChange={field.onChange}>
              <Box display="flex" m={1} width="100%">
                {PAY_FREQUENCIES.map(item => (
                  <FormControlLabel
                    key={item.label}
                    value={item.value}
                    control={<AppRadioButton color="primary" name={item.label} />}
                    label=""
                  />
                ))}
              </Box>
            </RadioGroup>
          </FormControl>
        )}
        name="pay_frequency"
        control={control}
      />
    </Box>
  );
};
