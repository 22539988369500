import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  moduleContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  bold: {
    fontWeight: 600,
  },
  paper: {
    padding: '24px',
  },
  redText: {
    color: theme.palette.error.main,
  },
  positiveText: {
    color: theme.palette.success.main,
  },
  gridContainer: {
    alignItems: 'center',
    minHeight: 45,
  },
  card: {
    marginTop: '16px',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '12px',
    padding: '16px',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  gap: {
    height: '24px',
    width: '100%',
  },
  yellowText: {
    color: theme.palette.warning.main,
    fontWeight: 500,
    fontSize: '16px',
  },
  currentPeriod: {
    marginTop: 4,
    fontSize: '14px',
  },
}));
