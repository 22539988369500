import React, { useMemo } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { Box, FormControl, FormControlLabel, RadioGroup, useTheme } from '@material-ui/core';

import { OnboardingStepTitle } from '../../../../components';
import breadcrumbsImg from '../../../../assets/tracking-options/breadcrumbs.png';
import breadcrumbsImgDark from '../../../../assets/tracking-options/breadcrumbs-dark.png';
import inOutImg from '../../../../assets/tracking-options/in-out.png';
import inOutImgDark from '../../../../assets/tracking-options/in-out-dark.png';
import noTracking from '../../../../assets/tracking-options/no-tracking.png';
import noTrackingDark from '../../../../assets/tracking-options/no-tracking-dark.png';
import { TrackingOption } from './TrackingOption';
import { TRACKING_OPTIONS } from '../../../../modules/timeModule/constants';

const name = 'tracking_type';

export const TrackingOptionsStep: React.FC = () => {
  const { control, watch, setValue } = useFormContext();
  const theme = useTheme();
  const option = watch(name);

  const options = useMemo(() => {
    const isDark = theme.palette.type === 'dark';
    return [
      {
        option: TRACKING_OPTIONS.breadcrumbs,
        image: isDark ? breadcrumbsImgDark : breadcrumbsImg,
      },
      {
        option: TRACKING_OPTIONS.inOut,
        image: isDark ? inOutImgDark : inOutImg,
      },
      {
        option: TRACKING_OPTIONS.none,
        image: isDark ? noTrackingDark : noTracking,
      },
    ];
  }, [theme.palette.type]);

  return (
    <>
      <OnboardingStepTitle text="How would you like to validate and track your team?" />

      <Controller
        render={() => (
          <FormControl component="fieldset" style={{ width: '100%' }}>
            <RadioGroup aria-label="tracking_type">
              <Box display="flex" gridGap={24} marginTop={2} justifyContent="space-between">
                {options.map(optionItem => (
                  <FormControlLabel
                    key={optionItem.option.value}
                    value={optionItem.option}
                    style={{ alignItems: 'flex-start', marginRight: 0 }}
                    onClick={() => setValue(name, optionItem.option)}
                    control={
                      <TrackingOption
                        description={optionItem.option.description}
                        label={optionItem.option.name}
                        image={optionItem.image}
                        checked={option.value === optionItem.option.value}
                      />
                    }
                    label=""
                  />
                ))}
              </Box>
            </RadioGroup>
          </FormControl>
        )}
        name={name}
        control={control}
      />
    </>
  );
};
