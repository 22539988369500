import React, { useContext, useState } from 'react';

import { Button, Divider, Theme, Typography } from '@material-ui/core';
import { IoCheckmarkDoneOutline } from 'react-icons/io5';
import { useTheme } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';

import { ButtonTitleWithLoading } from '@vyce/core/src/components/ButtonTitleWithLoading';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import { useStyles } from '../styles';
import { useActions } from '../../../hooks';

export const SuccessStep: React.FC = () => {
  const { handleServerError } = useContext(NotificationContext);
  const theme = useTheme<Theme>();
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const { me } = useActions();
  const history = useHistory();

  const goToProfile = async () => {
    try {
      setLoading(true);
      await me();
      history.push('/profile');
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleServerError(e);
    }
  };

  return (
    <div className={classes.form}>
      <div className={classes.formTitle}>
        <Typography variant="h4">
          <IoCheckmarkDoneOutline className={classes.successIcon} color={theme.palette.success.main} />{' '}
          Success
        </Typography>

        <Typography variant="body1" className={classes.successText}>
          Thank you! Your form has been successfully submitted and a copy of the agreement has been sent to
          your email for reference. (You may need to check your spam and save us to your contacts)
        </Typography>

        <Divider />

        <Typography variant="body2" className={classes.successText}>
          You’re now logged into your profile where you can see all your details and where you can view and
          download all of your pay statements. If you ever need to change your details or access your pay
          statements, this is where you need to come.
        </Typography>
      </div>

      <Button onClick={goToProfile} fullWidth disabled={loading} color="primary" variant="outlined">
        <ButtonTitleWithLoading title="Go To Profile" loaderVariant="primary" loading={loading} />
      </Button>
    </div>
  );
};
