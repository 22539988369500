import React from 'react';

import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import { KeyboardTimePicker, KeyboardTimePickerProps } from '@material-ui/pickers';
import { FiClock } from 'react-icons/fi';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core';

import { useAppTextFieldStyles } from './AppTextField';
import { LabelButton } from './AppKeyboardDatePicker';
import { monochrome } from '../../theme/styles';

export const timePickerUseStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      borderRadius: '8px',
    },
    dialog: {
      '& .MuiDialogActions-root': {
        justifyContent: 'space-between',
        padding: theme.spacing(3),
        paddingTop: theme.spacing(1),
        '& .MuiButtonBase-root': {
          padding: 0,
        },
      },
      '& .MuiPickersBasePicker-container': {
        flexDirection: 'column-reverse',
      },
      '& .MuiPickersToolbar-toolbar': {
        backgroundColor: theme.palette.background.paper,
        padding: '0 24px 24px',
      },
      '& .MuiPickersToolbarText-toolbarTxt': {
        color: theme.palette.text.secondary,
        fontSize: '24px',
        fontWeight: 700,
      },
      '& .MuiPickersTimePickerToolbar-ampmSelection': {
        marginRight: 0,
        marginLeft: 12,
        backgroundColor: theme.palette.type === 'dark' ? 'rgba(0,0,0,.07)' : '#D9DBE9',
        borderRadius: '8px',
        '& .MuiButtonBase-root': {
          height: 40,
          width: 121,
        },
        '& .MuiPickersToolbarText-toolbarBtnSelected': {
          backgroundColor: theme.palette.background.default,
          width: 121,
          borderRadius: '8px',
        },
        '& .MuiPickersTimePickerToolbar-ampmLabel': {
          fontSize: '18px',
          fontWeight: 600,
          lineHeight: '40px',
        },
      },
      '& .MuiPickersTimePickerToolbar-separator': {
        margin: '0 12px',
        lineHeight: '80px',
      },
      '& .MuiPickersTimePickerToolbar-hourMinuteLabel': {
        '& .MuiButtonBase-root': {
          width: 80,
          height: 80,
          backgroundColor: theme.palette.background.default,
        },
      },
      '& .MuiPickersBasePicker-pickerView': {
        maxWidth: 'initial',
        alignItems: 'center',
      },
      '& .MuiPickersClock-clock': {
        backgroundColor: theme.palette.background.default,
      },
      '& .MuiPickersClockPointer-pointer': {
        width: '4px',
        left: 'calc(50% - 2px)',
      },
      '& .MuiPickersClock-pin': {
        width: '4px',
        height: '4px',
      },
      '& .MuiPickersClockPointer-thumb': {
        borderColor: '#BFBEFC',
        backgroundColor: '#BFBEFC',
        left: -14,
      },
    },
    iconButton: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '6px',
      padding: '7px',
    },
  })
);

export const AppKeyboardTimePicker = React.forwardRef((props: KeyboardTimePickerProps, ref) => {
  const classes = useAppTextFieldStyles({});
  const componentClasses = timePickerUseStyles();
  const theme = useTheme();

  const iconColor = theme.palette.type === 'dark' ? monochrome.mediumdark : monochrome.medium;

  return (
    <KeyboardTimePicker
      innerRef={ref}
      mask={props.mask || '__:__ _M'}
      inputVariant="filled"
      {...props}
      InputProps={
        {
          classes,
          disableUnderline: true,
          ...props.InputProps,
        } as Partial<OutlinedInputProps>
      }
      DialogProps={{
        ...props.DialogProps,
        className: componentClasses.dialog,
        PaperProps: {
          classes: {
            root: componentClasses.paper,
          },
        },
      }}
      cancelLabel={<LabelButton cancel={true} />}
      okLabel={<LabelButton cancel={false} />}
      keyboardIcon={<FiClock size={24} color={iconColor} />}
    />
  );
});
