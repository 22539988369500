import { HelperState } from '@vyce/core/src/types';

import { DEFAULT_NAV_ITEMS } from '../../views/main/constants';
import { HelperAction } from '../actions/helper';
import { ActionType } from '../action-types';

const initialState: HelperState = {
  userDataLoading: false,
  notification: undefined,
  redirectTo: '',
  navItems: DEFAULT_NAV_ITEMS,
  access_token: '',
  showTour: false,
  selectedPosition: undefined,
  selectedCompany: undefined,
  selectedCompanyAppData: {
    teams: [],
    paySchedules: [],
    isJobPosted: false,
    companyDataLoading: false,
    subscription: undefined,
  },
};

const reducer = (state: HelperState = initialState, action: HelperAction): HelperState => {
  switch (action.type) {
    case ActionType.SET_REDIRECT_TO:
      return {
        ...state,
        redirectTo: action.payload,
      };
    case ActionType.CLEAR_REDIRECT_TO:
      return {
        ...state,
        redirectTo: '',
      };
    case ActionType.SET_USER_DATA_LOADING:
      return {
        ...state,
        userDataLoading: action.payload,
      };
    case ActionType.SET_COMPANY_DATA_LOADING:
      return {
        ...state,
        selectedCompanyAppData: {
          ...state.selectedCompanyAppData,
          companyDataLoading: action.payload,
        },
      };
    case ActionType.SET_SELECTED_COMPANY_SUBSCRIPTION_DATA:
      return {
        ...state,
        selectedCompanyAppData: {
          ...state.selectedCompanyAppData,
          subscription: action.payload,
        },
      };
    case ActionType.SET_NAV_ITEMS:
      return {
        ...state,
        navItems: action.payload,
      };
    case ActionType.CLEAR_NAV_ITEMS:
      return {
        ...state,
        navItems: [],
      };
    case ActionType.SET_ACCESS_TOKEN:
      return {
        ...state,
        access_token: action.payload,
      };
    case ActionType.CLEAR_HELPERS:
      return initialState;
    case ActionType.SET_SHOW_TOUR:
      return {
        ...state,
        showTour: action.payload,
      };
    case ActionType.SET_SELECTED_POSITION:
      return {
        ...state,
        selectedPosition: action.payload,
      };
    case ActionType.SET_SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: action.payload,
      };
    case ActionType.SET_COMPANY_DATA:
      return {
        ...state,
        selectedCompanyAppData: {
          ...state.selectedCompanyAppData,
          ...action.payload,
        },
      };
    case ActionType.SET_PAY_SCHEDULES:
      return {
        ...state,
        selectedCompanyAppData: {
          ...state.selectedCompanyAppData,
          paySchedules: action.payload,
        },
      };
    case ActionType.SET_TEAMS:
      return {
        ...state,
        selectedCompanyAppData: {
          ...state.selectedCompanyAppData,
          teams: action.payload,
        },
      };
    case ActionType.ADD_TEAM:
      return {
        ...state,
        selectedCompanyAppData: {
          ...state.selectedCompanyAppData,
          teams: [...(state.selectedCompanyAppData?.teams || []), action.payload],
        },
      };
    case ActionType.SET_IS_JOB_POSTED_STATUS:
      return {
        ...state,
        selectedCompanyAppData: {
          ...state.selectedCompanyAppData,
          isJobPosted: true,
        },
      };

    default:
      return state;
  }
};

export default reducer;
