import { useContext, useEffect, useState } from 'react';

import useGeolocation from 'react-hook-geolocation';
import { useTheme } from '@material-ui/core';

import { DailyForecastItem, DayWeather, WeatherLocation } from '../../../../types';
import { DEFAULT_LOCATION, NUMBER_OF_DAYS, YOUR_LOCATION_NAME } from '../constants';
import { useBooleanState } from '../../../../hooks';
import { getDayWeatherRequest, getWeatherForecastRequest } from '../../../../api/weather';
import { formatDate } from '../../../../utils/dates';
import {
  drizzle,
  fog,
  heavyRain,
  heavySnow,
  partlyCloudy,
  rain,
  snow,
  sunny,
  thunderstorm,
  thunderstormAndHail,
} from '../../../../assets/svg/weather-icons';
import { getLocationDetails } from '../../../../utils/location';
import dayjs from 'dayjs';
import { getFromLS, saveToLS } from '../../../../utils/local-storage';
import { NotificationContext } from '../../../../contexts/notificationContext';

const LS_LOCATIONS_FIELD_NAME = 'weatherLocations';
const LS_SELECTED_LOCATION_FIELD_NAME = 'selectedWeatherLocations';

export const useWeatherWidgetData = () => {
  const { showNotification } = useContext(NotificationContext);
  const theme = useTheme();
  const today = new Date();
  const [selectedLocation, setSelectedLocation] = useState<WeatherLocation>();
  const [locations, setLocations] = useState<WeatherLocation[]>([]);
  const [dailyForecast, setDailyForecast] = useState<DailyForecastItem[]>([]);
  const [selectedDay, setSelectedDay] = useState<DailyForecastItem | null>(null);
  const [selectedDayIndex, setSelectedDayIndex] = useState<number>(0);
  const [dayWeather, setDayWeather] = useState<DayWeather>();
  const geolocation = useGeolocation();
  const [isSelectAnotherPlaceDialogOpen, openSelectAnotherPlaceDialog, closeSelectAnotherPlaceDialog] =
    useBooleanState(false);
  const [forecastLoading, setForecastLoading] = useState<boolean>(true);
  const [dayWeatherLoading, setDayWeatherLoading] = useState<boolean>(true);
  const [isRainChart, setIsRainChart] = useState<boolean>(false);
  const [temperatureChartData, setTemperatureChartData] = useState<any>([]);
  const [chanceOfRainChartData, setChanceOfRainChartData] = useState<any>([]);

  const getWeatherForecast = async (location: WeatherLocation) => {
    try {
      const res = await getWeatherForecastRequest({
        latitude: location.latitude,
        longitude: location.longitude,
      });
      const daily = res.data.daily || {};
      setDailyForecast(prepareDailyForecastData(daily));
      setForecastLoading(false);
    } catch (e) {
      setForecastLoading(false);
      console.error(e);
    }
  };

  const getDayWeather = async (location: WeatherLocation, date: string) => {
    try {
      const res = await getDayWeatherRequest({
        latitude: location.latitude,
        longitude: location.longitude,
        date,
      });
      setDayWeather(prepareDayWeatherData(res.data));
      setDayWeatherLoading(false);
      setTemperatureChartData(prepareTemperatureChartData(res.data));
      setChanceOfRainChartData(prepareChanceOfRainChartData(res.data));
    } catch (e) {
      setDayWeatherLoading(false);
      console.error(e);
    }
  };

  useEffect(() => {
    const isYourLocation = locations?.find(loc => loc.name === YOUR_LOCATION_NAME);

    if (selectedLocation?.longitude && selectedLocation?.latitude) {
      return;
    }

    let newLocation = DEFAULT_LOCATION;

    if (!geolocation?.error) {
      newLocation = {
        name: YOUR_LOCATION_NAME,
        longitude: geolocation.longitude,
        latitude: geolocation.latitude,
      };
    }

    setSelectedLocation(newLocation);
    if (!isYourLocation) {
      setLocations([newLocation]);
    }
  }, [geolocation]);

  useEffect(() => {
    if (selectedLocation?.longitude && selectedLocation?.latitude) {
      getWeatherForecast(selectedLocation);
      getDayWeather(selectedLocation, formatDate(today));
      setSelectedDay(null);
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (selectedLocation?.longitude && selectedLocation?.latitude) {
      const time = selectedDay?.time || formatDate(today);
      getDayWeather(selectedLocation, time);
    }
  }, [selectedDay]);

  useEffect(() => {
    const locationsFromLS = getFromLS(LS_LOCATIONS_FIELD_NAME);
    const selectedLocationFromLS = getFromLS(LS_SELECTED_LOCATION_FIELD_NAME);

    setLocations(locationsFromLS || [DEFAULT_LOCATION]);
    if (selectedLocationFromLS) {
      setSelectedLocation(selectedLocationFromLS);
    }
  }, []);

  useEffect(() => {
    if (locations?.length) {
      saveToLS(LS_LOCATIONS_FIELD_NAME, locations);
    }
  }, [locations]);

  useEffect(() => {
    if (selectedLocation) {
      saveToLS(LS_SELECTED_LOCATION_FIELD_NAME, selectedLocation);
    }
  }, [selectedLocation]);

  const handleMenuItemClick = (item: WeatherLocation) => {
    setSelectedLocation(item);
  };

  const prepareDailyForecastData = (resDailyData: any): DailyForecastItem[] => {
    const { temperature_2m_max, time, weathercode } = resDailyData;
    if (!temperature_2m_max || !time || !weathercode) {
      return [];
    }

    const formattedForecast: DailyForecastItem[] = [];
    for (let i = 0; i <= NUMBER_OF_DAYS - 1; i++) {
      const temperatureMax = temperature_2m_max[i];
      const initialTime = time[i];
      const date = new Date(initialTime);
      const weekDay = getShortWeekday(date);
      const weatherCode = weathercode[i];
      formattedForecast.push({ temperatureMax, weatherCode, weekDay, time: initialTime });
    }

    return formattedForecast;
  };

  const getShortWeekday = (date: Date): string => {
    return date.toLocaleDateString('en-GB', { weekday: 'short' });
  };

  const prepareDayWeatherData = (resDayWeatherData: any): DayWeather => {
    const hourly = resDayWeatherData?.hourly || {};
    const daily = resDayWeatherData?.daily || {};
    const formattedDate = daily.time[0];
    const date = new Date(formattedDate);
    const today = new Date();
    const formattedToday = formatDate(today);
    const currentHour = today.getHours();
    const isTodaySelected = formattedDate === formattedToday;
    const currentTemperature = isTodaySelected
      ? hourly.temperature_2m[currentHour]
      : daily.temperature_2m_max[0];
    const time = isTodaySelected
      ? dayjs().format('dddd, DD/MM/YY, HH:MM')
      : dayjs(date).format('dddd, DD/MM/YY');
    return {
      weatherCode: daily.weathercode[0] || 0,
      currentTemperature: currentTemperature || 0,
      precipitationProbability: daily.precipitation_probability_max[0] || 0,
      windSpeed: daily.windspeed_10m_max[0] || 0,
      time,
    };
  };

  const prepareTemperatureChartData = (resDayWeatherData: any) => {
    const data = getChartDataByThreeHours(resDayWeatherData?.hourly?.temperature_2m);
    const formattedData: any[] = [];
    data.forEach((item: number, index: number) => {
      formattedData.push({
        x: `${index * 3}:00`,
        y: item.toFixed(),
      });
    });
    return [
      {
        id: 'temperature',
        color: theme.palette.primary.main,
        data: formattedData,
      },
    ];
  };

  const prepareChanceOfRainChartData = (resDayWeatherData: any) => {
    const data = getChartDataByThreeHours(resDayWeatherData?.hourly?.precipitation_probability);
    return data.map((item: number, index: number) => ({
      time: `${index * 3}:00`,
      value: item.toFixed(),
    }));
  };

  // displaying each 3rd hour to safe space
  const getChartDataByThreeHours = (data?: any[]) => {
    if (!data) {
      return [];
    }
    return data.filter((item: any, index: number) => index % 3 === 0);
  };

  const getWeatherDescription = (weatherCode: number) => {
    switch (weatherCode) {
      case 0:
      case 1:
        return { icon: sunny, label: 'Sunny' };
      case 2:
      case 3:
        return { icon: partlyCloudy, label: 'Partly Cloudy' };
      case 45:
      case 48:
        return { icon: fog, label: 'Fog' };
      case 51:
      case 53:
      case 56:
        return { icon: drizzle, label: 'Drizzle' };
      case 55:
      case 57:
      case 61:
      case 63:
      case 66:
      case 80:
      case 81:
        return { icon: rain, label: 'Rain' };
      case 65:
      case 67:
      case 82:
        return { icon: heavyRain, label: 'Heavy Rain' };
      case 95:
        return { icon: thunderstorm, label: 'Thunderstorm' };
      case 96:
      case 99:
        return { icon: thunderstormAndHail, label: 'Thunderstorm & Hail' };
      case 71:
      case 73:
      case 77:
      case 85:
        return { icon: snow, label: 'Snow' };
      case 75:
      case 86:
        return { icon: heavySnow, label: 'Heavy Snow' };
      default: {
        return { icon: sunny, label: 'Sunny' };
      }
    }
  };

  const handleAnotherPlaceSelect = async ({ location }: { location: string }) => {
    const addressData = await getLocationDetails({
      fullAddress: location,
    });
    if (addressData?.lat && addressData?.lon) {
      const newLocation: WeatherLocation = {
        name: addressData.address_line_2 || addressData.address_line_3 || addressData.country || 'Unknown',
        latitude: addressData.lat,
        longitude: addressData.lon,
      };
      setSelectedLocation(newLocation);
      setLocations(value => [...value, newLocation]);
    } else {
      showNotification({
        message: 'Can not get the location, please try another address',
        options: { variant: 'error' },
      });
    }
    closeSelectAnotherPlaceDialog();
  };

  const handleDayClick = (item: DailyForecastItem) => {
    const currentIndex = dailyForecast?.findIndex(value => selectedDay?.time === value.time);
    setSelectedDay(item);
    setSelectedDayIndex(currentIndex);
  };

  const handleChevronClick = (type: 'left' | 'right') => {
    const currentIndex = dailyForecast?.findIndex(value => selectedDay?.time === value.time);
    let index = currentIndex + 1;
    if (type === 'left' && !!currentIndex) {
      index = currentIndex - 1;
    }
    setSelectedDayIndex(index);
    setSelectedDay(dailyForecast[index]);
  };

  const deleteLocation = (name: string) => {
    setLocations(value => value.filter(loc => loc.name !== name));
    const yourLocation = locations?.find(loc => loc.name === YOUR_LOCATION_NAME);
    setSelectedLocation(yourLocation || DEFAULT_LOCATION);
  };

  return {
    handleDayClick,
    openSelectAnotherPlaceDialog,
    locations,
    selectedLocation,
    handleMenuItemClick,
    dayWeatherLoading,
    dayWeather,
    isRainChart,
    getWeatherDescription,
    setIsRainChart,
    chanceOfRainChartData,
    temperatureChartData,
    forecastLoading,
    dailyForecast,
    selectedDay,
    handleAnotherPlaceSelect,
    isSelectAnotherPlaceDialogOpen,
    closeSelectAnotherPlaceDialog,
    handleChevronClick,
    deleteLocation,
    selectedDayIndex,
  };
};
