import React, { useContext, useMemo, useState } from 'react';

import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, withStyles } from '@material-ui/core';
import { BiPencil } from 'react-icons/bi';
import { IoCloseOutline } from 'react-icons/io5';

import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';
import { DeviceContext } from '@vyce/core/src/contexts';
import { formatDate, getTime } from '@vyce/core/src/utils/dates';
import { TimeLogByShiftDateItem, TimeLogByShiftDateItemLog } from '@vyce/core/src/types';
import { AppCustomTable } from '@vyce/core/src/components';
import { useBooleanState } from '@vyce/core/src/hooks';
import { monochrome } from '@vyce/core/src/theme/styles';

import { EditSingleTimeLogWorkerDialog } from './EditSingleTimeLogWorkerDialog';
import { TimeLogWarningCell } from './components/TimeLogWarningCell';
import { WorkerHoursCell } from './components/WorkerHoursCell';
import useStyles from './styles';

const StyledDialog = withStyles({
  root: {
    //@ts-ignore
    zIndex: '1250 !important',
  },
})(Dialog);

export const ExpandTimeLogsInformation = ({
  timeLogByShift,
  isOpen,
  children,
  userId,
  firstName,
  lastName,
  onClose,
  onSuccess,
  renderAvatar,
}: {
  timeLogByShift: TimeLogByShiftDateItem;
  isOpen: boolean;
  children: React.ReactNode;
  userId: string;
  firstName: string;
  lastName: string;
  onClose: () => void;
  onSuccess: () => void;
  renderAvatar: () => React.ReactNode;
}) => {
  const classes = useStyles();

  const [selectedLog, setSelectedLog] = useState<TimeLogByShiftDateItemLog | null>(null);
  const [isEditDialogOpen, openEditDialogOpen, closeEditDialogOpen] = useBooleanState(false);

  const { isMobile } = useContext(DeviceContext);

  const handleEditClick = (log: TimeLogByShiftDateItemLog) => {
    setSelectedLog(log);
    openEditDialogOpen();
  };
  const handleCloseDialog = () => {
    closeEditDialogOpen();
    setSelectedLog(null);
  };

  const columns = useMemo(
    () => [
      {
        name: 'date',
        title: 'Date',
        flex: 0.3,
        renderCell: (row: TimeLogByShiftDateItemLog) => (
          <Box
            display="flex"
            alignItems="center"
            height={70}
            lineHeight="48px"
            onClick={() => handleEditClick(row)}>
            {formatDate(timeLogByShift.date, 'dddd D MMMM')}
          </Box>
        ),
      },
      {
        name: 'time_logs',
        title: 'Shift',
        flex: 0.2,
        renderCell: (row: TimeLogByShiftDateItemLog) => (
          <Box onClick={() => handleEditClick(row)}>
            <Box display="flex" alignItems="center" height={70}>
              {row.shift_name}
            </Box>
          </Box>
        ),
      },
      {
        name: 'time_logs',
        title: 'Clock In Time',
        flex: 0.2,
        renderCell: (row: TimeLogByShiftDateItemLog) => (
          <Box onClick={() => handleEditClick(row)}>
            <Box display="flex" alignItems="center" style={{ fontWeight: 600 }} height={70}>
              {row.checked_in ? (
                <TimeLogWarningCell check_in_ok={row.check_in_ok} face_in_ok={row.face_in_ok}>
                  <>{getTime(row.checked_in)}</>
                </TimeLogWarningCell>
              ) : (
                '-'
              )}
            </Box>
          </Box>
        ),
      },
      {
        name: 'time_logs',
        title: 'Clock Out Time',
        flex: 0.15,
        renderCell: (row: TimeLogByShiftDateItemLog) => (
          <Box onClick={() => handleEditClick(row)}>
            <Box display="flex" alignItems="center" style={{ fontWeight: 600 }} height={70}>
              {row.checked_out ? (
                <TimeLogWarningCell
                  check_out_ok={row.check_out_ok}
                  face_out_ok={row.face_out_ok}
                  auto_clock_out={row.auto_clock_out}
                  manual_clock_out={row.manual_clock_out}>
                  <>{getTime(row.checked_out)}</>
                </TimeLogWarningCell>
              ) : (
                '-'
              )}
            </Box>
          </Box>
        ),
      },
      {
        name: 'time_logs',
        title: 'Clocked Hours',
        flex: 0.15,
        renderCell: (row: TimeLogByShiftDateItemLog) => (
          <Box onClick={() => handleEditClick(row)}>
            <WorkerHoursCell decimals={row.clocked_in_hours_decimals} amendmentDecimals={0} />
          </Box>
        ),
      },
      {
        name: 'time_logs',
        title: 'Regular Paid Hours',
        flex: 0.2,
        renderCell: (row: TimeLogByShiftDateItemLog) => (
          <Box onClick={() => handleEditClick(row)}>
            <WorkerHoursCell
              decimals={row.basic_hours_decimals}
              amendmentDecimals={row.basic_amendment_hours_decimals}
            />
          </Box>
        ),
      },
      {
        name: 'time_logs',
        title: 'Overtime Paid Hours',
        flex: 0.15,
        renderCell: (row: TimeLogByShiftDateItemLog) => (
          <Box onClick={() => handleEditClick(row)}>
            <WorkerHoursCell
              decimals={row.overtime_hours_decimals}
              amendmentDecimals={row.overtime_amendment_hours_decimals}
            />
          </Box>
        ),
      },
      {
        name: 'action',
        title: '',
        flex: 0.15,
        renderCell: (row: TimeLogByShiftDateItemLog) => (
          <Box height="100%" display="flex" alignItems="center" justifyContent="center">
            <Button
              startIcon={<BiPencil size="16px" color={monochrome.mediumdark} />}
              className={classes.buttonWrapper}
              style={{ backgroundColor: monochrome.lighter }}
              onClick={() => handleEditClick(row)}
            />
          </Box>
        ),
      },
    ],
    []
  );

  return (
    <Box>
      <StyledDialog
        container={document.getElementById(MAIN_CONTAINER_ID)}
        fullScreen={isMobile}
        maxWidth="md"
        onClose={onClose}
        open={isOpen}>
        <DialogTitle>
          {`Time Log for ${firstName} ${lastName} - ${formatDate(timeLogByShift.date, 'dddd DD MMMM')}`}
          <IconButton onClick={() => onClose()} className={classes.closeButton}>
            <IoCloseOutline size={30} />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Box display="flex" flexDirection="column" gridGap="16px" marginBottom={3}>
            <AppCustomTable data={timeLogByShift.time_logs} columns={columns} />
            {children}
          </Box>
        </DialogContent>
      </StyledDialog>

      {selectedLog && (
        <EditSingleTimeLogWorkerDialog
          onSuccess={onSuccess}
          userId={userId}
          log={selectedLog}
          open={isEditDialogOpen}
          handleClose={handleCloseDialog}
          date={timeLogByShift.date}
          renderAvatar={renderAvatar}
        />
      )}
    </Box>
  );
};
