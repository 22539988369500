import React, { useState } from 'react';

import { Box, Grid } from '@material-ui/core';
import { VscClose } from 'react-icons/vsc';

import { EditShiftSchedule } from './EditShiftSchedule';
import { EditBreaks } from './EditBreaks';
import { ShiftNameField } from '../../../../components/controlled-inputs/ShiftNameField';
import { useBooleanState } from '../../../../hooks';
import { useStyles } from '../../styles';
import { ScheduleType, SHIFTS_FIELD_NAME } from '../../../../modules/timeModule/constants';
import { AppIconButton, ConfirmDialog } from '../../../../components';
import { useCheckClockedInWorkers } from '../hooks/useCheckClockedInWorkers';

interface Props {
  shiftIndex: number;
  shiftId?: string;
  deleteShift: (shiftId: string) => void;
  handleSaveChangesClick: Function;
  siteId: string;
  companyId: string;
}

export const EditShift: React.FC<Props> = ({
  shiftIndex,
  deleteShift,
  shiftId,
  handleSaveChangesClick,
  siteId,
  companyId,
}) => {
  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] = useBooleanState(false);
  const { closeForbidDialog, isForbidDIalogOpen, handleDeleteClick } = useCheckClockedInWorkers({
    companyId,
    successCallback: openConfirmDialog,
  });
  const classes = useStyles();

  return (
    <Box className={classes.shiftContainer}>
      <Box display="flex" gridGap={16} alignItems="center" marginBottom={3}>
        <ShiftNameField name={`${SHIFTS_FIELD_NAME}[${shiftIndex}].name`} />

        <AppIconButton isSmall onClick={() => handleDeleteClick(siteId)} variant="error">
          <VscClose size="16px" />
        </AppIconButton>
      </Box>

      <Grid spacing={4} container>
        <Grid item xs={12} lg={6}>
          <Box display="flex" flexDirection="column" gridGap={16}>
            <EditShiftSchedule
              handleSaveChangesClick={handleSaveChangesClick}
              index={shiftIndex}
              type={ScheduleType.REGULAR}
            />

            <EditShiftSchedule
              handleSaveChangesClick={handleSaveChangesClick}
              index={shiftIndex}
              type={ScheduleType.OVERTIME}
            />
          </Box>
        </Grid>

        <Box className={classes.shiftDivider} />

        <Grid item xs={12} lg={6}>
          <Box height="100%" display="flex" gridGap={16} flexDirection="column">
            <EditBreaks shiftIndex={shiftIndex} />
          </Box>
        </Grid>
      </Grid>

      {shiftId && (
        <ConfirmDialog
          handleClose={closeConfirmDialog}
          open={isConfirmDialogOpen}
          confirmText="Delete Shift"
          title="Do you want to delete this Shift?"
          subtitle="All workers assigned only to this shift will lose the access to the Location. This action cannot be undone."
          handleConfirm={() => deleteShift(shiftId)}
        />
      )}

      <ConfirmDialog
        handleClose={closeForbidDialog}
        open={isForbidDIalogOpen}
        cancelText="Ok, close"
        title="You can't delete this shift"
        subtitle="Workers are still checked in, wait until the end of the day."
      />
    </Box>
  );
};
