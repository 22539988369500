import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { Badge, Box, Button, Drawer, FormControlLabel, Typography, useTheme } from '@material-ui/core';
import { BsFilter } from 'react-icons/bs';
import { Controller, FormProvider, RegisterOptions, useForm } from 'react-hook-form';
import isEqual from 'lodash/isEqual';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { VscClose } from 'react-icons/vsc';

import { formatDate } from '../../utils/dates';
import { FilterOption, FilterSection } from '../../types';
import { AppButtonCheckbox, AppKeyboardDatePicker, AppSlider, AppTextField } from '../inputs';
import { ControlledSelect } from '../controlled-inputs/ControlledSelect';
import { AppIconButton } from '../AppIconButton';
import useStyles from './styles';
import { ControlledAutocomplete } from '../controlled-inputs/ControlledAutocomplete';
import { DetailsIcon } from '../DetailsIcon';

interface Props {
  filtersSections: FilterSection[];
  buttonBorder?: boolean;
  rules?: Record<string, RegisterOptions>;
  onFiltersChange: (filters: any) => void;
  selectedValues?: any;
}

const getDefaultValue = (filtersSections: FilterSection[]) => {
  const obj: any = {};
  const allFilters: FilterOption[] = filtersSections.map(section => [...section.filters]).flat();
  allFilters.forEach(filter => {
    if (!filter.field) return;
    obj[filter.field] = filter.defaultValue;
  });
  return obj;
};

export const FilterSystem = memo(
  ({ filtersSections, buttonBorder = false, rules, onFiltersChange, selectedValues }: Props) => {
    const theme = useTheme();
    const classes = useStyles();
    const [filters, setFilters] = useState();
    const [open, setOpen] = useState<boolean>(false);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [filterCount, setFilterCount] = useState<number>(0);
    const [expandedFilters, setExpandedFilters] = useState<any>({});
    const defaultValues = useMemo(() => getDefaultValue(filtersSections), [filtersSections]);

    const methods = useForm<any>({
      mode: 'all',
      reValidateMode: 'onChange',
      defaultValues,
    });
    const {
      watch,
      reset,
      trigger,
      setValue,
      formState: { errors, isValid, isValidating, dirtyFields },
    } = methods;

    const clearFilters = () => {
      reset();
    };

    const countFilters = useCallback(
      (filters: any, filterTitle?: string): number => {
        let count = 0;
        Object.keys(filters).forEach(key => {
          const sections = filterTitle
            ? filtersSections.filter(section => section.title === filterTitle)
            : [...filtersSections];
          const filtersForCount: FilterOption[] = sections.map(section => [...section.filters]).flat();

          const option = filtersForCount.find(option => option.field === key);

          if (option && Array.isArray(filters[key])) {
            if (!isEqual(option?.defaultValue, filters[key])) {
              count++;
            }
          } else if (option && !isEqual(filters[key], option?.defaultValue)) {
            count++;
          }
        });
        return count;
      },
      [filtersSections]
    );

    const handleApply = () => {
      setOpen(false);
    };

    useEffect(() => {
      const subscription = watch(filters => {
        setFilterCount(countFilters(filters));
        setFilters(filters);
      });
      return () => subscription.unsubscribe();
    }, [isValid, countFilters, onFiltersChange, watch]);

    useEffect(() => {
      const expandedFiltersObject: any = {};
      filtersSections.map(
        section =>
          (expandedFiltersObject[section.title] = {
            expanded: !!section.expanded,
            count: 0,
          })
      );
      setExpandedFilters(expandedFiltersObject);
    }, []);

    useEffect(() => {
      trigger();
    }, [rules]);

    useEffect(() => {
      if (selectedValues) {
        Object.keys(selectedValues).forEach(key => setValue(key, selectedValues[key]));
      }
    }, [selectedValues]);

    useEffect(() => {
      if (isFirstRender) {
        setIsFirstRender(false);
        return;
      }
      if (isValid && !isValidating && filters) onFiltersChange(filters);
    }, [isValid, isValidating, filters]);

    const onCollapseButtonClick = (title: string) => {
      setExpandedFilters((filters: any) => {
        return {
          ...filters,
          [title]: {
            ...filters[title],
            expanded: !filters[title].expanded,
          },
        };
      });
    };

    return (
      <>
        <Badge overlap="rectangular" badgeContent={filterCount} color="primary" style={{ height: '100%' }}>
          <Button
            size="small"
            cy-test-id="filter-button"
            onClick={() => setOpen(true)}
            style={{ border: buttonBorder ? `1px solid ${theme.palette.divider}` : 'none' }}
            startIcon={<BsFilter color={theme.palette.text.primary} />}
            className={classes.filterButton}>
            Filter
          </Button>
        </Badge>

        <Drawer
          anchor="right"
          open={open}
          classes={{ paper: classes.drawer }}
          onClose={() => setOpen(false)}>
          <Box display="flex" alignItems="center" justifyContent="space-between" height={50}>
            <Box display="flex" alignItems="center" height={50}>
              <AppIconButton
                isBorder
                isSmall
                variant="paper"
                cy-test-id="close-filter-button"
                className={classes.closeIcon}
                onClick={() => setOpen(false)}>
                <VscClose size="20px" color={theme.palette.text.primary} />
              </AppIconButton>
              <Typography variant="h6" style={{ fontWeight: 600 }}>
                Filter
              </Typography>
            </Box>

            {filterCount > 0 && (
              <Button
                cy-test-id="clear-filter-button"
                style={{ height: 40 }}
                onClick={clearFilters}
                color="primary"
                variant="outlined">
                Clear All
              </Button>
            )}
          </Box>

          <FormProvider {...methods}>
            <form>
              <Box display="flex" flexWrap="wrap">
                {filtersSections.map((section, index) => (
                  <Box key={'section' + index} width="100%">
                    <Box
                      display="flex"
                      className={classes.filterTitle}
                      justifyContent="space-between"
                      alignItems="center"
                      cy-test-id={`expand-filter-${section.title}`}
                      onClick={() => onCollapseButtonClick(section.title)}>
                      <Box display="flex" alignItems="center">
                        <Typography variant="subtitle2">{section.title}</Typography>
                        {!!countFilters(methods.getValues(), section.title) && (
                          <Box marginLeft={0.5} className={classes.filterCount}>
                            {countFilters(methods.getValues(), section.title)}
                          </Box>
                        )}
                      </Box>

                      <AppIconButton variant="paper">
                        {expandedFilters[section.title]?.expanded ? <HiChevronUp /> : <HiChevronDown />}
                      </AppIconButton>
                    </Box>

                    {expandedFilters[section.title]?.expanded && (
                      <Box display="flex" flexWrap="wrap">
                        {section.filters.map((option, index) => {
                          switch (option.type) {
                            case 'select':
                              return (
                                <Box
                                  key={'option' + index}
                                  width="100%"
                                  cy-test-id={`filter-item-${option.field}`}>
                                  <ControlledSelect
                                    label={option.label}
                                    name={option?.field as string}
                                    margin="normal"
                                    items={option.values}
                                    multiple={option.multiple}
                                  />
                                </Box>
                              );
                            case 'slider':
                              return (
                                <Box key={'option' + index} marginTop={2} width="100%">
                                  <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <Typography variant="subtitle2" color="textSecondary">
                                      {option.label}
                                    </Typography>
                                    {option.hintMessage && (
                                      <DetailsIcon content={<>{option.hintMessage}</>} />
                                    )}
                                  </Box>

                                  <Controller
                                    name={option?.field as string}
                                    control={methods.control}
                                    defaultValue={option.defaultValue}
                                    render={({ field }) => (
                                      <Box pl={3} pr={3}>
                                        <AppSlider
                                          {...field}
                                          // object -> {gte: 1, lte: 2} array (by default) -> [1, 2]
                                          value={
                                            option.dataType === 'object'
                                              ? [+field.value.gte, +field.value.lte]
                                              : field.value
                                          }
                                          onChange={
                                            option.dataType === 'object'
                                              ? value => field.onChange({ gte: +value[0], lte: +value[1] })
                                              : field.onChange
                                          }
                                          max={option.max}
                                          min={option.min}
                                          marks={option?.marks}
                                          step={option?.step}
                                          valueLabelDisplay="off"
                                        />
                                      </Box>
                                    )}
                                  />
                                </Box>
                              );
                            case 'date':
                              return (
                                <Box key={'option' + index} width="100%">
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Controller
                                      name={option.field as string}
                                      control={methods.control}
                                      render={props => (
                                        <AppKeyboardDatePicker
                                          value={props.field.value || null}
                                          onChange={data => props.field.onChange(formatDate(data) || null)}
                                          label={option.label}
                                          margin="normal"
                                          format="dd/MM/yyyy"
                                          error={
                                            !!errors[option.field]?.message && dirtyFields[option.field]
                                          }
                                          helperText={
                                            (dirtyFields[option.field] && errors[option.field]?.message) ||
                                            ''
                                          }
                                          fullWidth
                                          id="date-picker-dialog"
                                        />
                                      )}
                                    />
                                  </MuiPickersUtilsProvider>
                                </Box>
                              );
                            case 'input':
                              return (
                                <Box key={'option' + index} width="100%">
                                  <Controller
                                    control={methods.control}
                                    rules={rules && rules[option.field]}
                                    name={option?.field ?? ''}
                                    render={({ field }) => (
                                      <AppTextField
                                        {...field}
                                        type={option.dataType}
                                        value={field.value}
                                        onChange={field.onChange}
                                        label={option.label}
                                        margin="normal"
                                        error={!!errors[option.field]?.message && dirtyFields[option.field]}
                                        helperText={
                                          (dirtyFields[option.field] && errors[option.field]?.message) || ''
                                        }
                                        fullWidth
                                      />
                                    )}
                                  />
                                </Box>
                              );
                            case 'checkbox':
                              return (
                                <Box key={'option' + index}>
                                  <Controller
                                    name={option.field as string}
                                    control={methods.control}
                                    render={({ field }) => (
                                      <FormControlLabel
                                        control={
                                          <AppButtonCheckbox
                                            onChange={e => field.onChange(e.target.checked)}
                                            color="primary"
                                            name={option.label}
                                            checked={field.value}
                                          />
                                        }
                                        label=""
                                      />
                                    )}
                                  />
                                </Box>
                              );
                            case 'autocomplete':
                              return (
                                <Box key={'option' + index} width="100%" marginTop={1}>
                                  <ControlledAutocomplete
                                    name={option.field as string}
                                    label={option.label as string}
                                    multiple={option.multiple}
                                    items={option.values}
                                  />
                                </Box>
                              );
                            default:
                              return <></>;
                          }
                        })}
                      </Box>
                    )}
                  </Box>
                ))}

                <Box marginTop={2} width="100%">
                  <Button
                    disabled={filterCount === 0}
                    onClick={handleApply}
                    variant="contained"
                    cy-test-id="apply-filter-button"
                    fullWidth
                    color="primary">
                    Apply
                  </Button>
                </Box>
              </Box>
            </form>
          </FormProvider>
        </Drawer>
      </>
    );
  }
);
