import React from 'react';

import { Avatar, Box, Button, Drawer, Grid, Tooltip, Typography, useTheme } from '@material-ui/core';
import TimeAgo from 'timeago-react';
import { BiBuildings, BiTimeFive } from 'react-icons/bi';
import { FcMoneyTransfer } from 'react-icons/fc';
import { TbPencil } from 'react-icons/tb';
import { FiCopy } from 'react-icons/fi';
import { RiEyeLine } from 'react-icons/ri';
import { Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import debounce from 'lodash/debounce';

import {
  AcceptApplicationDialog,
  CancelConnectionDialog,
  CancelDeclinationDialog,
  CandidateTile,
  ConnectCandidateDialog,
  DeclineApplicationDialog,
  MapButton,
  Sorting,
  SuccessConnectionDialog,
  TileList,
  CreateJobDialog,
  EmployerJobPage,
  CloseJobButton,
} from './components';
import { useJobCandidatesData } from '../../modules/hiringModule/hooks/useJobCandidatesData';
import { AppIconButton, AppTabs, EmptyList, FilterSystem } from '../../components';
import { getJobSalary } from '../../utils/job';
import { CompanyPosition, CONNECT_EVENTS } from '../../types';
import { CandidatePage } from '../preview/user/CandidatePage';
import { getEmptyListTitle } from '../../modules/hiringModule/utils';
import { FILTER_OPTIONS } from '../../modules/hiringModule/config';
import { MapView } from '../map/MapView';
import { HiringProvider } from '../../modules/hiringModule/context/hiringContext';
import { useStyles } from './styles';
import config from '../../assets/config';
import { getHealthAndSafetyPDFByUserIdRequest } from '../../api/pay';

interface Props {
  token: string;
  companyId: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  setIsJobPostedStatus?: Function;
  selectedPosition?: CompanyPosition;
}

export const Candidates: React.FC<Props> = ({
  token,
  companyId,
  setIsJobPostedStatus,
  lastName,
  firstName,
  phone,
  email,
  selectedPosition,
}) => {
  const classes = useStyles();
  const theme = useTheme<Theme>();

  const {
    isMobile,
    mapMode,
    count,
    currentJob,
    tabs,
    jobPreviewOpen,
    candidateDetailsOpen,
    connectDialogOpen,
    cancelConnectionDialogOpen,
    declineApplicationDialogOpen,
    cancelDeclinationDialogOpen,
    successConnectionDialogOpen,
    acceptDialogOpen,
    contextProps,
    selectedMarker,
    markers,
    candidates,
    loading,
    dialogLoading,
    category,
    selectedCandidate,
    connectedCandidate,
    jobDialogOpen,
    paginationOptions,
    goToMatched,
    handleDuplicateJob,
    handleEditJob,
    handleJobDialogClose,
    openConnectDialog,
    openCancelConnectionDialog,
    openDeclineConnectionDialog,
    openAcceptDialog,
    openCancelDeclinationDialog,
    setJobPreviewOpen,
    closeJob,
    sort,
    handleFilterChange,
    setMapMode,
    getJobPosition,
    goToCandidate,
    goBack,
    setConnectDialogOpen,
    connectCandidate,
    setCancelConnectionDialogOpen,
    cancelConnection,
    setAcceptDialogOpen,
    acceptApplication,
    declineApplication,
    setDeclineApplicationDialogOpen,
    setCancelDeclinationDialogOpen,
    cancelDeclination,
    setSuccessConnectionDialogOpen,
    setConnectedCandidate,
  } = useJobCandidatesData({ token, companyId });

  if (jobDialogOpen)
    return (
      <HiringProvider value={contextProps}>
        <Box>
          <CreateJobDialog
            token={token}
            contactEmail={email}
            companyId={companyId}
            contactName={`${firstName} ${lastName}`}
            contactPhone={phone}
            job={currentJob}
            handleClose={handleJobDialogClose}
            setIsJobPostedStatus={setIsJobPostedStatus}
          />
        </Box>
      </HiringProvider>
    );

  return (
    <HiringProvider value={contextProps}>
      <Box
        display="flex"
        flexDirection="column"
        padding={isMobile ? 1 : '0 16px'}
        className={clsx(classes.content, {
          [classes.contentShift]: jobPreviewOpen || candidateDetailsOpen,
        })}>
        <Box padding={3} paddingBottom={0} className={classes.pageHeader}>
          <Box
            marginTop={2}
            minHeight={75}
            display="flex"
            padding={1}
            flexWrap="wrap"
            alignItems="center"
            justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Avatar className={classes.avatar} src={currentJob?.company?.logo?.url}>
                {!currentJob?.company?.logo && <BiBuildings size="25px" />}
              </Avatar>

              {!!currentJob && (
                <Box marginLeft={2}>
                  <Typography className={classes.bold} variant="h6">
                    {currentJob.name as string}
                  </Typography>

                  <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
                    <Typography
                      variant="subtitle2"
                      className={classes.textWithIcon}
                      style={{ color: theme.palette.success.main, marginRight: '4px' }}>
                      <FcMoneyTransfer size="15px" className={classes.iconWithText} />
                      {getJobSalary(currentJob.salary_from, currentJob.salary_to, currentJob.salary_type)}
                    </Typography>

                    {currentJob.overtime_type && (
                      <Typography
                        variant="subtitle2"
                        className={classes.textWithIcon}
                        style={{ color: theme.palette.success.main, marginRight: '4px' }}>
                        (overtime:{' '}
                        {getJobSalary(
                          currentJob?.overtime_from,
                          currentJob?.overtime_to,
                          currentJob?.overtime_type
                        )}
                        )
                      </Typography>
                    )}

                    {currentJob?.updated_at && (
                      <Typography variant="subtitle2" color="textSecondary" className={classes.textWithIcon}>
                        <BiTimeFive size="15px" className={classes.iconWithText} />
                        <TimeAgo datetime={currentJob.updated_at} />
                      </Typography>
                    )}
                  </Box>
                </Box>
              )}
            </Box>

            <Box display="flex">
              <Tooltip title="Preview">
                <Box marginRight={2}>
                  <AppIconButton onClick={() => setJobPreviewOpen(value => !value)}>
                    <RiEyeLine size="20px" />
                  </AppIconButton>
                </Box>
              </Tooltip>

              <Tooltip title="Edit">
                <Box marginRight={2}>
                  <AppIconButton onClick={handleEditJob} variant="secondary">
                    <TbPencil size="21px" />
                  </AppIconButton>
                </Box>
              </Tooltip>

              <Tooltip title="Duplicate">
                <Box marginRight={2}>
                  <AppIconButton onClick={handleDuplicateJob} variant="primary">
                    <FiCopy size="15px" />
                  </AppIconButton>
                </Box>
              </Tooltip>

              {currentJob && <CloseJobButton isIconButton={true} job={currentJob} closeJob={closeJob} />}
            </Box>
          </Box>

          <AppTabs tabItems={tabs} />
        </Box>

        <Box marginTop={5}>
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
            marginBottom={2}
            alignItems="center">
            <Typography color="textSecondary" variant="subtitle2">
              Found {`${count} result${count > 1 ? 's' : ''}`}
            </Typography>

            <Box display="flex" gridGap={16}>
              <MapButton mapMode={mapMode} onMapModeChange={() => setMapMode(mode => !mode)} />

              <Sorting sort={sort} sortOptions={['Relevant', 'Nearest']} />

              <FilterSystem
                filtersSections={FILTER_OPTIONS}
                onFiltersChange={debounce(handleFilterChange, 500)}
              />
            </Box>
          </Box>
        </Box>

        <Box marginTop={1} pb={3} className={classes.flexContentWrapper}>
          {mapMode ? (
            <MapView
              height="calc(100vh - 320px)"
              myPosition={getJobPosition()}
              selectedMarker={selectedMarker}
              markers={markers}
              onMarkerClick={goToCandidate}
            />
          ) : (
            <TileList
              paginationOptions={paginationOptions}
              loading={loading}
              emptyComponent={
                <EmptyList
                  image={config.icons3D.lightning}
                  buttonText={category !== 'matched' ? 'Go To Matched Candidates' : ''}
                  callback={goToMatched}
                  subtitle={getEmptyListTitle(category)}
                  title={'No candidates in here yet!'}
                />
              }>
              <>
                {candidates.map((candidate, index) => (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    md={jobPreviewOpen || candidateDetailsOpen ? 12 : 6}
                    lg={jobPreviewOpen || candidateDetailsOpen ? 6 : 4}>
                    <CandidateTile
                      openConnectDialog={event => openConnectDialog(event, candidate.user_id)}
                      openCancelConnectionDialog={event =>
                        openCancelConnectionDialog(event, candidate.user_id)
                      }
                      openDeclineConnectionDialog={event =>
                        openDeclineConnectionDialog(event, candidate.user_id)
                      }
                      openAcceptDialog={event => openAcceptDialog(event, candidate.user_id)}
                      openCancelDeclinationDialog={event =>
                        openCancelDeclinationDialog(event, candidate.user_id)
                      }
                      currentCandidate={candidate}
                      goToCandidate={goToCandidate}
                    />
                  </Grid>
                ))}
              </>
            </TileList>
          )}
        </Box>

        <ConnectCandidateDialog
          open={connectDialogOpen}
          loading={dialogLoading}
          setOpen={setConnectDialogOpen}
          connect={connectCandidate}
        />

        <CancelConnectionDialog
          open={cancelConnectionDialogOpen}
          loading={dialogLoading}
          setOpen={setCancelConnectionDialogOpen}
          cancelConnection={cancelConnection}
        />

        <DeclineApplicationDialog
          open={declineApplicationDialogOpen}
          loading={dialogLoading}
          setOpen={setDeclineApplicationDialogOpen}
          declineApplication={declineApplication}
        />

        <AcceptApplicationDialog
          open={acceptDialogOpen}
          loading={dialogLoading}
          setOpen={setAcceptDialogOpen}
          accept={acceptApplication}
        />

        {connectedCandidate && (
          <SuccessConnectionDialog
            selectedPosition={selectedPosition}
            open={successConnectionDialogOpen}
            handleClose={() => {
              setSuccessConnectionDialogOpen(false);
              setConnectedCandidate(undefined);
            }}
            candidate={connectedCandidate}
          />
        )}

        <CancelDeclinationDialog
          open={cancelDeclinationDialogOpen}
          loading={dialogLoading}
          setOpen={setCancelDeclinationDialogOpen}
          cancelDeclination={cancelDeclination}
        />

        {currentJob && (
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={jobPreviewOpen}
            classes={{
              paper: classes.drawerPaper,
            }}>
            <EmployerJobPage job={currentJob} back={() => setJobPreviewOpen(false)} closeJob={closeJob} />
          </Drawer>
        )}

        {selectedCandidate && (
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={candidateDetailsOpen}
            classes={{
              paper: classes.drawerPaper,
            }}>
            <CandidatePage
              candidate={selectedCandidate}
              back={goBack}
              companyId={companyId ?? ''}
              showHealthAndSafetyPDFRequest={getHealthAndSafetyPDFByUserIdRequest}>
              <>
                {selectedCandidate.computed?.connection?.event === CONNECT_EVENTS.applied_by_worker && (
                  <Box display="flex" width="100%" gridGap={16}>
                    <Button
                      fullWidth
                      variant="outlined"
                      onClick={event => openDeclineConnectionDialog(event, selectedCandidate.user_id)}
                      className={classes.redButton}>
                      Decline
                    </Button>
                    <Button
                      onClick={event => openAcceptDialog(event, selectedCandidate.user_id)}
                      fullWidth
                      variant="contained"
                      className={classes.acceptButton}>
                      Accept
                    </Button>
                  </Box>
                )}
                {!selectedCandidate.computed?.connection?.event && (
                  <>
                    <Button
                      variant="contained"
                      onClick={event => openConnectDialog(event, selectedCandidate.user_id)}
                      fullWidth
                      color="primary">
                      Connect
                    </Button>
                  </>
                )}

                {selectedCandidate.computed?.connection?.event === CONNECT_EVENTS.applied_by_employer && (
                  <>
                    <Button
                      onClick={event => openCancelConnectionDialog(event, selectedCandidate.user_id)}
                      fullWidth
                      variant="contained"
                      color="secondary"
                      style={{ backgroundColor: theme.palette.warning.main }}>
                      Cancel connection
                    </Button>
                  </>
                )}

                {selectedCandidate.computed?.connection?.event === CONNECT_EVENTS.declined_by_employer && (
                  <>
                    <Button
                      onClick={event => openCancelDeclinationDialog(event, selectedCandidate.user_id)}
                      fullWidth
                      variant="contained"
                      className={classes.warningButton}>
                      Cancel Declination
                    </Button>
                  </>
                )}

                {selectedCandidate.computed?.connection?.event === CONNECT_EVENTS.connected_by_employer && (
                  <>
                    <Typography
                      align="center"
                      variant="subtitle2"
                      color="primary"
                      style={{ lineHeight: '40px' }}>
                      Connected
                    </Typography>
                  </>
                )}
              </>
            </CandidatePage>
          </Drawer>
        )}
      </Box>
    </HiringProvider>
  );
};
