import React, { useState } from 'react';

import { Button, Menu, MenuItem } from '@material-ui/core';
import { BsFilterLeft } from 'react-icons/bs';

import { useStyles } from '../styles';

interface Props {
  sort: (option: string) => void;
  sortOptions: string[];
}

export const Sorting: React.FC<Props> = ({ sort, sortOptions }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedOption, setSelectedOption] = useState<string>('');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    handleClose();
    sort(option);
  };

  return (
    <>
      <Button
        size="small"
        className={classes.filterButton}
        startIcon={<BsFilterLeft />}
        onClick={handleClick}>
        {selectedOption || 'Sort by'}
      </Button>

      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {sortOptions.map(option => (
          <MenuItem className={classes.sortMenuItem} key={option} onClick={() => handleOptionClick(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
