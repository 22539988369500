import React from 'react';
import { FieldProps } from '../../types';
import { PhoneNumberField } from "./PhoneNumberField";

export const EmergencyContactNumberField: React.FC<FieldProps> = ({ margin = undefined }) => {

  return (
    <PhoneNumberField rules={{}} label="Emergency Contact Number" margin={margin} name="emergency_contact_number"/>
  );
};
