import React, { useContext, useEffect } from 'react';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
} from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';

import { EmailField } from '@vyce/core/src/components/controlled-inputs';
import { DeviceContext } from '@vyce/core/src/contexts';
import { MAIN_CONTAINER_ID } from '../../../constants';

interface Props {
  open: boolean;
  email: string;
  setOpen: Function;
  onSubmit: (data: { email: string }) => Promise<void>;
}

interface Form {
  email: string;
}

export const ForgotPasswordDialog: React.FC<Props> = ({ open, email, setOpen, onSubmit }) => {
  const { isMobile } = useContext(DeviceContext);
  const methods = useForm<Form>({
    defaultValues: {
      email: '',
    },
  });

  useEffect(() => {
    methods.setValue('email', email);
  }, [email]);

  return (
    <Dialog
      fullScreen={isMobile}
      container={document.getElementById(MAIN_CONTAINER_ID)}
      maxWidth="md"
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="responsive-dialog-title">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle id="responsive-dialog-title">Forgot your password?</DialogTitle>
          <DialogContent>
            <Typography variant="subtitle1" color="textSecondary">
              No problem! We’ll get you logged back into your account in no time.
            </Typography>

            <Box marginTop={1}>
              <Typography variant="subtitle1" color="textSecondary">
                Please confirm your email address and we’ll send you a reset password link.
              </Typography>
            </Box>

            <EmailField margin="normal" />
          </DialogContent>
          <DialogActions>
            <Button size="large" onClick={() => setOpen(false)} variant="outlined">
              Cancel
            </Button>
            <Button size="large" color="primary" onClick={() => {}} type="submit" variant="contained">
              Send Reset Link
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
