import React, { useContext, useState } from 'react';

import { Box, Button, makeStyles, Menu, MenuItem, Theme, Typography } from '@material-ui/core';
import { FiChevronDown, FiMapPin } from 'react-icons/fi';
import { IoClose } from 'react-icons/io5';

import { monochrome } from '../../../../theme/styles';
import { YOUR_LOCATION_NAME } from '../constants';
import { WeatherLocation } from '../../../../types';
import { DeviceContext } from '../../../../contexts';

export const useStyles = makeStyles((theme: Theme) => ({
  selectButton: {
    boxShadow: 'none',
    height: 32,
    '&:hover': {
      boxShadow: 'none',
    },
    border: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('xs')]: {
      border: 'none',
      backgroundColor: theme.palette.background.default,
      borderRadius: '8px',
      marginBottom: 8,
    },
  },
  menu: {
    width: 228,
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 80px)',
    },
  },
  buttonLabel: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    color: theme.palette.type === 'dark' ? monochrome.light : monochrome.dark,
    fontWeight: 400,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      fontWeight: 600,
      fontSize: '15px',
    },
  },
}));

interface Props {
  menuItems: WeatherLocation[];
  selectedLocationName: string;
  handleMenuItemClick: (item: any) => void;
  handleSelectAnotherPlaceItemClick: Function;
  deleteLocation: (name: string) => void;
}

export const LocationSelect: React.FC<Props> = ({
  menuItems,
  selectedLocationName,
  handleMenuItemClick,
  handleSelectAnotherPlaceItemClick,
  deleteLocation,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { isMobile } = useContext(DeviceContext);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option: WeatherLocation) => {
    handleMenuItemClick(option);
    handleClose();
  };

  const handleAnotherPlaceClick = () => {
    handleSelectAnotherPlaceItemClick();
    handleClose();
  };

  const handleLocationDelete = (e: any, locationName: string) => {
    e.stopPropagation();
    deleteLocation(locationName);
  };

  return (
    <>
      <Button
        classes={{ root: classes.selectButton, label: classes.buttonLabel }}
        variant="contained"
        disableRipple
        fullWidth={isMobile}
        disableTouchRipple
        disableFocusRipple
        disableElevation
        endIcon={<FiChevronDown />}
        onClick={handleClick}>
        {selectedLocationName}
      </Button>
      <Menu
        id="simple-menu"
        classes={{ paper: classes.menu }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {menuItems.map(option => (
          <MenuItem
            selected={option.name === selectedLocationName}
            className={classes.menu}
            key={option.name}
            onClick={() => handleOptionClick(option)}>
            <Box
              width="100%"
              position="relative"
              display="flex"
              justifyContent="space-between"
              alignItems="center">
              <Typography variant="caption">{option.name}</Typography>

              {option.name === YOUR_LOCATION_NAME ? (
                <FiMapPin size="16px" />
              ) : (
                <Box display="flex" alignItems="center" onClick={e => handleLocationDelete(e, option.name)}>
                  <IoClose size="17px" />
                </Box>
              )}
            </Box>
          </MenuItem>
        ))}
        <MenuItem className={classes.menu} key="Select another place" onClick={handleAnotherPlaceClick}>
          + Select another place
        </MenuItem>
      </Menu>
    </>
  );
};
