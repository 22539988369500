import React, { MouseEventHandler, ReactElement } from 'react';

import { Avatar, Box, Divider, Typography, useTheme } from '@material-ui/core';
import { FiBriefcase, FiMap } from 'react-icons/fi';
import clsx from 'clsx';

import { Candidate } from '../../../types';
import { BackButton } from '../../../components/BackButton';
import { ExpandableViews } from '../components/ExpandableViews';
import { useCandidateData } from '../hooks';
import { ExpandableViewItem, QualificationType, UserPreviewProps, WorkExperienceDTO } from '../types';
import useStyles from '../styles';
import { WorkExperienceItem } from './components/WorkExperienceItem';
import { QualificationItem } from './components/QualificationItem';
import { HiringInfoItem } from './components/HiringInfoItem';
import { Bio } from './components/Bio';
import { Skills } from './components/Skills';
import { HiringRightToWork } from './components/HiringRightToWork';
import { HealthRecords } from './components/HealthRecords';
import { Arbitrator } from './components/Arbitrator';

interface Props extends Pick<UserPreviewProps, 'showHealthAndSafetyPDFRequest'> {
  candidate: Candidate;
  back: MouseEventHandler<HTMLButtonElement>;
  companyId: string;
  children: ReactElement;
}

export const CandidatePage: React.FC<Props> = ({ children, candidate, back, ...restProps }) => {
  const classes = useStyles();
  const theme = useTheme();

  const {
    roles,
    skills,
    isShowInfo,
    biography,
    country,
    formattedQualifications,
    preparedContactDetails,
    preparedPersonalDetails,
    preparedRightToWorkDetails,
    preparedSurveysDetails,
  } = useCandidateData({
    candidate,
    ...restProps,
  });

  const getWorkExperienceItem = (item: ExpandableViewItem) => {
    return <WorkExperienceItem {...(item as WorkExperienceDTO)} />;
  };

  const getQualificationItem = (item: QualificationType) => {
    return <QualificationItem qualification={item} isHiring />;
  };

  return (
    <Box position="relative">
      <Box className={classes.backButton} padding={1}>
        <BackButton back={back} />
      </Box>

      <Box p={3} pt={3.5} pb={2}>
        <Box display="flex" alignItems="center" gridGap={16} overflow="hidden">
          <Avatar src={candidate.photo?.url} className={classes.hiringAvatar} />
          <Box flexGrow={1}>
            <Typography className={classes.bold}>
              {`${candidate.first_name} ${candidate.last_name}`}
            </Typography>
            <Box display="flex" alignItems="center" gridGap={8}>
              <FiBriefcase
                style={{ marginRight: theme.spacing(0.5) }}
                size="14px"
                color={theme.palette.primary.main}
              />
              <Typography
                color="primary"
                className={clsx(
                  classes.ellipcedText,
                  classes.hiringEllipcedText,
                  classes.bold,
                  classes.smallText
                )}>
                {roles}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gridGap={8}>
              <FiMap
                style={{ marginRight: theme.spacing(0.5) }}
                size="14px"
                color={theme.palette.text.secondary}
              />
              <Typography
                color="textSecondary"
                className={clsx(
                  classes.ellipcedText,
                  classes.hiringEllipcedText,
                  classes.bold,
                  classes.smallText
                )}>
                {country}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {biography && isShowInfo && (
        <>
          <Divider />
          <Box display="flex" flexDirection="column" gridGap={16} mb={3} mt={2} pl={3} pr={3}>
            {biography && <Bio bio={biography} />}
          </Box>
        </>
      )}

      <Divider />

      {isShowInfo && (
        <>
          <Box padding={3} display="flex" flexDirection="column" gridGap={12}>
            <Typography className={classes.bold} variant="h6">
              Contact Details
            </Typography>
            <Box display="flex" flexDirection="column" gridGap={20}>
              <HiringInfoItem {...preparedContactDetails.email} copyToClipboard={true} />
              <HiringInfoItem {...preparedContactDetails.phone} copyToClipboard={true} />
            </Box>
          </Box>
          <Divider />
        </>
      )}

      <Box padding={3} display="flex" flexDirection="column" gridGap={12}>
        <Typography className={classes.bold} variant="h6">
          Professional Details
        </Typography>
        <Box display="flex" flexDirection="column" gridGap={20}>
          {!!formattedQualifications.length && (
            <Box>
              <ExpandableViews
                getDisplayedItem={getQualificationItem}
                title="Qualifications"
                items={formattedQualifications}
                isHiring
              />
            </Box>
          )}

          {!!skills?.length && <Skills skills={skills} isHiring />}

          {!!candidate?.positions?.length && (
            <Box>
              <ExpandableViews
                getDisplayedItem={getWorkExperienceItem}
                title="Work Experience"
                items={candidate.positions}
                isHiring
              />
            </Box>
          )}
        </Box>
      </Box>

      <Divider />

      <Box padding={3} display="flex" flexDirection="column" gridGap={12}>
        <HiringRightToWork {...preparedRightToWorkDetails} />
      </Box>

      <Divider />

      <Box padding={3} display="flex" flexDirection="column" gridGap={12}>
        <HealthRecords {...preparedSurveysDetails} isHiring />
      </Box>

      <Divider />

      <Box padding={3} display="flex" flexDirection="column" gridGap={12}>
        <Typography className={classes.bold} variant="h6">
          Personal Details
        </Typography>
        <Box display="flex" flexDirection="column" gridGap={20}>
          <HiringInfoItem {...preparedPersonalDetails.date_of_birth} />
          <HiringInfoItem {...preparedPersonalDetails.nationality} />
        </Box>
      </Box>

      <Divider />

      <Box padding={3} display="flex" flexDirection="column" gridGap={12}>
        <Arbitrator isShowInfo={isShowInfo} title="Contact Details" isHiring>
          {null}
        </Arbitrator>
      </Box>

      <Box padding={2} className={classes.bottomButtonContainer} display="flex" justifyContent="center">
        {children}
      </Box>
    </Box>
  );
};
