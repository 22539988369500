import React from 'react';

import clsx from 'clsx';
import { Theme } from '@material-ui/core/styles';
import { Box, Card, createStyles, makeStyles, Tooltip, Typography, useTheme } from '@material-ui/core';
import { VscClose } from 'react-icons/vsc';
import { CgArrowsExpandRight, CgCompressLeft } from 'react-icons/cg';

import { WidgetItem } from '../../types';
import { useFullscreenStatus } from '../../hooks';
import { AppIconButton } from '../AppIconButton';

const WIDGET_HEADER_HEIGHT = 56;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      gridGap: theme.spacing(3),
      padding: theme.spacing(3),
      borderRadius: '8px',
      flexDirection: 'column',
      position: 'relative',
    },
    name: {
      marginBottom: 0,
      cursor: 'pointer',
      fontWeight: 600,
      flex: 1,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  })
);

interface Props {
  item: WidgetItem;
  onRemoveItem: Function;
  getWidget: Function;
  closable: boolean;
}

export const WidgetWrapper: React.FC<Props> = ({ item, onRemoveItem, getWidget, closable }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullscreenRef = React.useRef(null);
  const { isFullscreen, setFullscreen, exitFullscreen, isFullScreenSupported } =
    useFullscreenStatus(fullscreenRef);

  const handleFullscreenStatusChange = () => {
    if (isFullscreen) {
      exitFullscreen();
    } else {
      setFullscreen();
    }
  };

  return (
    <Card ref={fullscreenRef} id={item.system_name} className={classes.root} variant="outlined">
      <Box display="flex" alignItems="center" gridGap={8} flexWrap="nowrap">
        <Tooltip title={item.name} placement="top">
          <Typography
            variant="h6"
            color={item.title_color || 'textPrimary'}
            className={clsx(classes.name, 'draggable-widget-header')}
            gutterBottom>
            {item.name}
          </Typography>
        </Tooltip>

        <Box display="flex" flexBasis="72px" flexGrow={0} flexShrink={0} justifyContent="flex-end">
          {isFullScreenSupported && item.layout?.isResizable !== false && (
            <Tooltip title="Fullscreen">
              <AppIconButton variant="paper" isSmall isBorder onClick={handleFullscreenStatusChange}>
                {isFullscreen ? (
                  <CgCompressLeft size="16px" color={theme.palette.text.primary} />
                ) : (
                  <CgArrowsExpandRight size="16px" color={theme.palette.text.primary} />
                )}
              </AppIconButton>
            </Tooltip>
          )}

          {!isFullscreen && closable && (
            <Box marginLeft={1}>
              <Tooltip title="Delete">
                <AppIconButton
                  variant="paper"
                  isSmall
                  isBorder
                  onClick={() => onRemoveItem(item.system_name)}>
                  <VscClose size="20px" color={theme.palette.text.primary} />
                </AppIconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
      </Box>

      <Box
        width="100%"
        height="100%"
        overflow="auto"
        display="flex"
        alignItems="flex-start"
        justifyContent="center">
        {getWidget(item)}
      </Box>
    </Card>
  );
};
