import React, { useContext } from 'react';

import { createStyles, makeStyles, Popover, Theme, Typography, useTheme, Box } from '@material-ui/core';
import { BsQuestionCircleFill } from 'react-icons/bs';
import clsx from 'clsx';

import { monochrome } from '@vyce/core/src/theme/styles';
import { DeviceContext } from '@vyce/core/src/contexts';
import { MAIN_CONTAINER_ID } from '../../../constants';

const id = 'teams-settings-help-text-popover';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverPaper: {
      width: 300,
      boxShadow: 'none',
      border: `1px solid ${theme.palette.divider}`,
      padding: '24px',
    },
    popover: {
      pointerEvents: 'none',
      zIndex: 1,
    },
    title: {
      marginLeft: theme.spacing(1),
      color: monochrome.medium,
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },

    text: {
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '20px',
    },

    bold: {
      fontWeight: 600,
    },
  })
);

export const HintIcon = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const { isMobile } = useContext(DeviceContext);
  const open = Boolean(anchorEl);

  const iconColor = theme.palette.type === 'dark' ? monochrome.mediumdark : monochrome.mediumlight;

  const handleOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <span
        className={clsx(classes.iconContainer)}
        aria-owns={open ? id : undefined}
        aria-haspopup="true"
        onMouseLeave={handleClose}
        onMouseEnter={handleOpen}>
        <BsQuestionCircleFill size={isMobile ? 24 : 15} color={iconColor} />
      </span>

      <Popover
        id={id}
        open={open}
        className={classes.popover}
        classes={{
          paper: clsx(classes.popoverPaper),
        }}
        container={document.getElementById(MAIN_CONTAINER_ID)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onClose={handleClose}
        disableRestoreFocus>
        <Box display="flex" flexDirection="column" gridGap={24}>
          <Box>
            <Typography className={classes.text}>
              'Mirror' functionality helps ensure you have the most current data in your Teams module. It
              keeps it up to date by automatically managing users based on one of your other modules.
            </Typography>
          </Box>
          <Box>
            <Typography className={clsx(classes.text, classes.bold)}>
              Mirror Time & Attendance Module:
            </Typography>
            <Typography className={classes.text}>
              Your Teams module will be kept up to date with your active T&A users. New members clocking in
              and out will be added, while users that haven’t done so in a set time period will be
              automatically removed from your Teams module.
            </Typography>
          </Box>

          <Box>
            <Typography className={clsx(classes.text, classes.bold)}>Mirror Payroll Module:</Typography>
            <Typography className={classes.text}>
              Your Teams module will be kept up to date with your recently paid workers. New members
              receiving their pay via Payroll will be added, while users that haven’t been paid during a set
              period will automatically be removed from your Teams module.
            </Typography>
          </Box>
        </Box>
      </Popover>
    </>
  );
};
