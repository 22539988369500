import React from 'react';

import { TbPencil } from 'react-icons/tb';
import { Box, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

interface Props {
  handleClose: Function;
  handleSaveChangesClick?: Function;
  isLocationChanged: boolean;
  editMode: boolean;
}

export const LocationControlButtons: React.FC<Props> = ({
  handleClose,
  isLocationChanged,
  handleSaveChangesClick,
  editMode,
}) => {
  const history = useHistory();

  const handleEditModeButtonClick = () => {
    history.push(`${history.location.pathname}?mode=${editMode ? 'preview' : 'edit'}`);
  };

  const saveChanges = () => {
    if (handleSaveChangesClick && editMode) {
      handleSaveChangesClick();
    }
  };

  return (
    <Box display="flex" justifyContent="flex-end" gridGap={16}>
      {/*TODO: finish when backend will be ready*/}
      {/*<Button onClick={() => handleClose()} size="small" variant="contained">*/}
      {/*  Close location*/}
      {/*</Button>*/}

      {isLocationChanged ? (
        <Button
          onClick={saveChanges}
          style={{ width: 152 }}
          size="small"
          variant="contained"
          color="secondary">
          Save Changes
        </Button>
      ) : (
        <Button
          onClick={handleEditModeButtonClick}
          size="small"
          variant="contained"
          color="primary"
          style={{ width: 152 }}
          startIcon={editMode ? undefined : <TbPencil size="18px" />}>
          {editMode ? 'Back To Preview' : 'Edit Location'}
        </Button>
      )}
    </Box>
  );
};
