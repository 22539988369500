import React from 'react';

import { Box, Typography, useTheme } from '@material-ui/core';

import { QuestionCard } from './QuestionCard';

export const WorkerQuestions = ({
  onAffirmativeClick,
  onNegativeClick,
}: {
  onAffirmativeClick: () => void;
  onNegativeClick: () => void;
}) => {
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" gridGap={16}>
      <Box>
        <Typography variant="h5" style={{ marginTop: theme.spacing(3), fontWeight: 700 }}>
          Do you already have a Vyce account?
        </Typography>
      </Box>

      <QuestionCard title="Yes" subTitle="I have an account, I want to login" onClick={onAffirmativeClick} />

      <QuestionCard
        title="No"
        subTitle="I want to register & join my team on Vyce."
        onClick={onNegativeClick}
      />
    </Box>
  );
};
