import React from 'react';

import { LocationForm } from '@vyce/core/src/components';
import { OnboardingStepTitle } from '@vyce/core/src/components';
import { TitleProps } from './types';

export const HomeAddressStep = ({ title = 'What is your home address?' }: TitleProps) => {
  return (
    <>
      <OnboardingStepTitle text={title} />
      <LocationForm />
    </>
  );
};
