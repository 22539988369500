import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { monochrome } from '@vyce/core/src/theme/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: 600,
    },

    bigTextWrap: {
      wordBreak: 'break-word',
      whiteSpace: 'normal',
    },

    smallText: {
      fontSize: '13px !important',
    },

    headerText: {
      fontSize: '20px',
    },

    passportImage: {
      height: 203,
      maxWidth: 337,
      objectFit: 'contain',
      [theme.breakpoints.down('xs')]: {
        height: '100%',
      },
    },

    selfieImage: {
      height: 203,
      maxWidth: 200,
      objectFit: 'contain',
      [theme.breakpoints.down('xs')]: {
        height: '100%',
        maxWidth: 337,
      },
    },

    RTWImage: {
      height: 203,
      maxWidth: 151,
      objectFit: 'contain',
    },

    blockWrapper: {
      border: `1px solid ${theme.palette.type === 'dark' ? monochrome.dark : monochrome.mediumlight}`,
      borderRadius: theme.spacing(1),
    },

    informationBlock: {
      width: '385px',
      padding: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    photoBlock: {
      padding: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },

    selectedImage: {
      maxWidth: '100%',
      objectFit: 'contain',
    },

    recordWrapper: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
  })
);
