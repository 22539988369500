import React, { useContext } from 'react';

import { useHistory } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';

import { DeviceContext } from '../../../contexts';
import useStyles from '../styles';
import { MAIN_CONTAINER_ID } from '../../../constants';

interface Props {
  open: boolean;
  closeDialog: Function;
}

export const SuccessDialog: React.FC<Props> = ({ open, closeDialog }) => {
  const { isMobile } = useContext(DeviceContext);
  const history = useHistory();
  const classes = useStyles();

  const handleClose = () => {
    closeDialog();
  };

  const handleClick = () => {
    handleClose();
    history.push('/user-management/company-invites');
  };

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">Your invites have been sent!</DialogTitle>

      <DialogContent>
        <Typography className={classes.dialogText}>
          All new users are added with standard permissions. You can customise user permissions and settings
          in the <span className={classes.moduleName}>User Management</span> tab.
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button size="large" variant="outlined" onClick={handleClose}>
          Ok
        </Button>

        <Button size="large" color="primary" onClick={handleClick} variant="contained">
          Go to User Management
        </Button>
      </DialogActions>
    </Dialog>
  );
};
