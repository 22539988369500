import { axios } from '@vyce/core/src/api';

import {
  CreateTemplateRequest,
  DeleteTemplateRequest,
  SendCustomSMSRequest,
  SendDocumentRequest,
  SendEmailRequest,
  SendEmailToMeRequest,
  SendSMSRequest,
  SendSMSToMeRequest,
  UpdateTemplateRequest,
} from '../types';

export const sendPassportRequest = ({ token, userId }: SendDocumentRequest) => {
  return axios.post(`/admin/notifications/users/${userId}/share_passport_email`);
};

export const sendShareCodeRequest = ({ token, userId }: SendDocumentRequest) => {
  return axios.post(`/admin/notifications/users/${userId}/share_code_email`);
};

export const getEmailTemplatesRequest = (substring: string = '') => {
  return axios.post(`/admin/notifications/email/templates/search`, { substring, offset: 0, limit: 1000 });
};

export const createEmailTemplateRequest = ({ token, data }: CreateTemplateRequest) => {
  return axios.post(`/admin/notifications/email/templates`, data);
};

export const deleteEmailTemplateRequest = ({ token, templateId }: DeleteTemplateRequest) => {
  return axios.delete(`/admin/notifications/email/templates/${templateId}`);
};

export const getSMSTemplatesRequest = (substring: string = '') => {
  return axios.post(`/admin/notifications/sms/templates/search`, { substring, offset: 0, limit: 1000 });
};

export const createSMSTemplateRequest = ({ token, data }: CreateTemplateRequest) => {
  return axios.post(`/admin/notifications/sms/templates`, data);
};

export const updateSMSTemplateRequest = ({ token, data, templateId }: UpdateTemplateRequest) => {
  return axios.patch(`/admin/notifications/sms/templates/${templateId}`, data);
};

export const sendSMSRequest = ({ scheduled, users, phones, templateId }: SendSMSRequest) => {
  return axios.post(`/admin/notifications/sms/templates/${templateId}/send_sms`, {
    users,
    scheduled,
    phones,
  });
};

export const sendCustomSMSRequest = ({ token, scheduled, phones, users, message }: SendCustomSMSRequest) => {
  return axios.post(`/admin/notifications/sms/send_custom_sms`, {
    users,
    scheduled,
    phones,
    message,
  });
};

export const deleteSMSTemplateRequest = ({ token, templateId }: DeleteTemplateRequest) => {
  return axios.delete(`/admin/notifications/sms/templates/${templateId}`);
};

export const sendEmailRequest = ({ scheduled, users, emails, templateId }: SendEmailRequest) => {
  return axios.post(`/admin/notifications/email/templates/${templateId}/send_email`, {
    users,
    emails,
    scheduled,
  });
};

export const sendEmailToMeRequest = ({ token, userId, email, templateId }: SendEmailToMeRequest) => {
  return axios.post(`/admin/notifications/email/users/${userId}/templates/${templateId}/send_email_to_me`, {
    email,
  });
};

export const sendSMSToMeRequest = ({ token, userId, phone, templateId }: SendSMSToMeRequest) => {
  return axios.post(`/admin/notifications/sms/users/${userId}/templates/${templateId}/send_sms_to_me`, {
    phone,
  });
};
