import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { Box, FormControlLabel, useTheme } from '@material-ui/core';
import { VscClose } from 'react-icons/vsc';

import { AppCheckbox, AppTextField } from '../../../components/inputs';
import { AppIconButton } from '../../../components';

interface Props {
  parentField: string;
  index: number;
  remove: Function;
  isCloseButtonTransparent?: boolean;
}

export const EditBreak: React.FC<Props> = ({
  index,
  parentField,
  remove,
  isCloseButtonTransparent = false,
}) => {
  const { control } = useFormContext();
  const theme = useTheme();
  const breakField = `${parentField}[${index}]`;

  return (
    <Box display="flex" alignItems="center" gridGap={16}>
      <Box flex={1}>
        <Controller
          control={control}
          name={`${breakField}.name`}
          render={({ field }) => (
            <AppTextField {...field} value={field.value || ''} label="Name" fullWidth />
          )}
        />
      </Box>

      <Box flex={1}>
        <Controller
          control={control}
          name={`${breakField}.minutes`}
          render={({ field }) => (
            <AppTextField {...field} type="number" value={field.value} label="Time (minutes)" fullWidth />
          )}
        />
      </Box>

      <Controller
        name={`${breakField}.paid`}
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={
              <AppCheckbox
                onChange={e => field.onChange(e.target.checked)}
                color="primary"
                checked={field.value}
              />
            }
            label="Paid"
          />
        )}
      />

      {isCloseButtonTransparent ? (
        <Box
          marginLeft={1}
          display="flex"
          alignItems="center"
          onClick={() => remove(index)}
          style={{ cursor: 'pointer' }}>
          <VscClose size="21px" color={theme.palette.error.main} />
        </Box>
      ) : (
        <AppIconButton isSmall onClick={() => remove(index)} variant="error">
          <VscClose size="16px" />
        </AppIconButton>
      )}
    </Box>
  );
};
