import React, { MouseEventHandler } from 'react';
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { AppIconButton } from "./AppIconButton";
import { drawerWidth } from "./Header";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expandIcon: {
      position: 'fixed',
      top: '42px',
      zIndex: theme.zIndex.drawer + 2,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      transition: 'left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
    expandIconLabel: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: '50%'
    }
  })
);

interface Props {
  handleDrawerToggle: MouseEventHandler<HTMLButtonElement>;
  open?: boolean;
}

export const ExpandIcon: React.FC<Props> = ({ handleDrawerToggle, open }) => {
  const classes = useStyles();
  return (
    <AppIconButton
      disableFocusRipple
      disableRipple
      disableTouchRipple
      classes={{ label: classes.expandIconLabel }}
      style={{ left: open ? `calc(${drawerWidth}px - 23px)` : '42px' }}
      className={classes.expandIcon}
      onClick={handleDrawerToggle}>
      {open ? <IoIosArrowDropleft size="21px" color="#A0A3BD"/> : <IoIosArrowDropright size="21px" color="#A0A3BD"/>}
    </AppIconButton>
  )
}
