import React, { useContext } from 'react';

import {
  Paper,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
  Button,
} from '@material-ui/core';
import { Controller, FormProvider } from 'react-hook-form';

import { AppSwitch } from '@vyce/core/src/components/inputs';
import { PageLoading } from '@vyce/core/src/components';
import { DeviceContext } from '@vyce/core/src/contexts';

import { ModuleItem, HintIcon } from './components';
import { useTeamSettingsData } from './hooks';
import { payrollOptions, timeAttendanceOptions } from './config';
import { useStyles } from './styles';
import { Props } from './types';

export const TeamsSettings = (props: Props) => {
  const classes = useStyles();
  const { buttonLoading, methods, activate, isPaySync, isTimeSync, isShowButton, updateTeamsSettings } =
    useTeamSettingsData(props);
  const { isMobile } = useContext(DeviceContext);

  const { control } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(updateTeamsSettings)}>
        <Paper variant="outlined" className={classes.wrapper}>
          <Box marginBottom="20px">
            <Typography variant="h6">Teams module settings</Typography>
          </Box>

          <Box className={classes.questionButton}>
            <HintIcon />
          </Box>

          <Accordion expanded={activate} className={classes.mainAccordionWrapper}>
            <AccordionSummary>
              <Box display="flex" width="100%" justifyContent={isMobile ? 'space-between' : 'unset'}>
                <Box flexBasis="70%">
                  <Typography>Mirror users from other Vyce modules</Typography>
                </Box>
                <Controller
                  control={control}
                  name={'active'}
                  render={({ field }) => <AppSwitch {...field} checked={field.value} />}
                />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex" flexDirection="column" gridGap={8}>
                {/* TODO uncomment when backend will be ready  */}
                {/*       <ModuleItem
                  isSelected={isTimeSync}
                  type="time"
                  options={timeAttendanceOptions}
                  name="recent_time_periods"
                />
                <Box marginTop={isMobile ? '12px' : 0}>
                  <Divider />
                </Box> */}
                <ModuleItem
                  isSelected={isPaySync}
                  type="pay"
                  options={payrollOptions}
                  name="recent_pay_periods"
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        </Paper>
        {isShowButton && (
          <Box maxWidth="752px" marginTop={3} display="flex" justifyContent="flex-end">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={buttonLoading}
              style={{ width: 140 }}>
              {buttonLoading ? <PageLoading size={24} /> : 'Save changes'}
            </Button>
          </Box>
        )}
      </form>
    </FormProvider>
  );
};
