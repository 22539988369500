import React, { useContext, useEffect, useState } from 'react';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import { AxiosResponse } from 'axios';

import { SendInviteRequest } from '@vyce/core/src/api/types';
import { Company, MODULES, Role, Staff } from '@vyce/core/src/types';
import { DeviceContext } from '@vyce/core/src/contexts';
import { AppTextField } from '@vyce/core/src/components/inputs';
import { EmailField, emailPattern } from '@vyce/core/src/components/controlled-inputs';
import { DetailsIcon } from '@vyce/core/src/components/DetailsIcon';
import { CompanyFilter } from '@vyce/core/src/components';

import useStyles from '../styles';
import { ModulePermissionItem } from './ModulePermissionItem';
import { MAIN_CONTAINER_ID } from '../../../constants';
import { NotificationContext } from '../../../contexts/notificationContext';

interface Props {
  open: boolean;
  companies?: Company[];
  selectedUser?: Staff;
  setOpen: Function;
  setSelectedUser: Function;
  getStaff: Function;
  companyId: string | null;
  editPermissionRequest: (companyId: string, userId: string, data: any) => Promise<AxiosResponse<any>>;
  getRolesRequest: (companyId: string) => Promise<AxiosResponse<any>>;
  sendInviteRequest: (companyId: string, payload: SendInviteRequest) => Promise<AxiosResponse<any>>;
}

interface Form {
  email: string;
  phone: string;
}

export const StaffInviteDialog: React.FC<Props> = ({
  open,
  setOpen,
  setSelectedUser,
  selectedUser,
  getStaff,
  companyId,
  getRolesRequest,
  editPermissionRequest,
  sendInviteRequest,
  companies,
}) => {
  const classes = useStyles();
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const { isMobile } = useContext(DeviceContext);
  const [isPayrollSelected, setIsPayrollSelected] = useState<boolean>(false);
  const [isHiringSelected, setIsHiringSelected] = useState<boolean>(false);
  const [isCompanySelected, setIsCompanySelected] = useState<boolean>(false);
  const [isTeamsSelected, setIsTeamsSelected] = useState<boolean>(false);
  const [isTimeSelected, setIsTimeSelected] = useState<boolean>(false);
  const [payrollRoles, setPayrollRoles] = useState<Role[]>([]);
  const [connectRoles, setConnectRoles] = useState<Role[]>([]);
  const [companyRoles, setCompanyRoles] = useState<Role[]>([]);
  const [teamsRoles, setTeamsRoles] = useState<Role[]>([]);
  const [timeRoles, setTimeRoles] = useState<Role[]>([]);
  const [payrollRole, setPayrollRole] = useState<Role | null>(null);
  const [connectRole, setConnectRole] = useState<Role | null>(null);
  const [companyRole, setCompanyRole] = useState<Role | null>(null);
  const [teamRole, setTeamRole] = useState<Role | null>(null);
  const [timeRole, setTimeRole] = useState<Role | null>(null);
  const [position, setPosition] = useState<string>('');
  const methods = useForm<Form>({
    mode: 'all',
    defaultValues: {
      email: '',
      phone: '',
    },
  });
  const { reset, watch } = methods;
  const phone = watch('phone');
  const email = watch('email');
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(companyId);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setIsPayrollSelected(false);
      setIsHiringSelected(false);
      setIsCompanySelected(false);
      setIsTeamsSelected(false);
      setIsTimeSelected(false);
      setPayrollRole(null);
      setConnectRole(null);
      setCompanyRole(null);
      setTeamRole(null);
      setTimeRole(null);
      setPosition('');
      reset();
      setSelectedUser(undefined);
    }, 100);
  };

  const getPayrollRoles = async (companyId: string) => {
    try {
      const res = await getRolesRequest(companyId);
      const roles: Role[] = res.data;
      const payrollRoles: Role[] = [];
      const connectRoles: Role[] = [];
      const companyRoles: Role[] = [];
      const teamsRoles: Role[] = [];
      const timeRoles: Role[] = [];
      roles.forEach(item => {
        if (item.module.name === MODULES.PAYROLL) {
          payrollRoles.push(item);
        }
        if (item.module.name === MODULES.COMPANY) {
          companyRoles.push(item);
        }
        if (item.module.name === MODULES.CONNECT) {
          connectRoles.push(item);
        }
        if (item.module.name === MODULES.TEAMS) {
          teamsRoles.push(item);
        }
        if (item.module.name === MODULES.TIME) {
          timeRoles.push(item);
        }
      });
      return { connectRoles, payrollRoles, companyRoles, teamsRoles, timeRoles };
    } catch (e) {
      console.error(e);
      return { connectRoles: [], payrollRoles: [], companyRoles: [], teamsRoles: [], timeRoles: [] };
    }
  };

  const editPermissions = async () => {
    if (!selectedCompanyId || !selectedUser) {
      return;
    }
    try {
      const data = {
        roles: [
          {
            module_id: payrollRoles[0].module?.uuid,
            role_id: isPayrollSelected && payrollRole ? payrollRole.uuid : null,
          },
          {
            module_id: companyRoles[0].module?.uuid,
            role_id: isCompanySelected && companyRole ? companyRole.uuid : null,
          },
          {
            module_id: connectRoles[0].module?.uuid,
            role_id: isHiringSelected && connectRole ? connectRole.uuid : null,
          },
          {
            module_id: teamsRoles[0].module?.uuid,
            role_id: isTeamsSelected && teamRole ? teamRole.uuid : null,
          },
          {
            module_id: timeRoles[0].module?.uuid,
            role_id: isTimeSelected && timeRole ? timeRole.uuid : null,
          },
        ],
      };
      await editPermissionRequest(selectedCompanyId, selectedUser.user_id, data);
      showNotification({ message: 'Permissions have been updated', options: { variant: 'success' } });
      getStaff();
      handleClose();
    } catch (e) {
      handleServerError(e);
    }
  };

  const sendInvite = async (data: Form) => {
    if (!selectedCompanyId) {
      return;
    }

    const roles: any[] = [
      isPayrollSelected && payrollRole?.uuid,
      isCompanySelected && companyRole?.uuid,
      isHiringSelected && connectRole?.uuid,
      isTeamsSelected && teamRole?.uuid,
      isTimeSelected && timeRole?.uuid,
    ].filter(item => !!item);

    const payload: SendInviteRequest = {
      email: data.email || undefined,
      phone: data.phone || undefined,
      position_data: {
        position_name: position,
        roles,
      },
    };
    try {
      await sendInviteRequest(selectedCompanyId, payload);
      showNotification({ message: 'Invite has been sent', options: { variant: 'success' } });
      handleClose();
    } catch (e) {
      handleServerError(e);
    }
  };

  const applyRoles = async ({
    connectRoles,
    payrollRoles,
    companyRoles,
    teamsRoles,
    timeRoles,
  }: {
    connectRoles: Role[];
    payrollRoles: Role[];
    companyRoles: Role[];
    teamsRoles: Role[];
    timeRoles: Role[];
  }) => {
    const payrollRoleName = selectedUser?.modules[MODULES.PAYROLL];
    const connectRoleName = selectedUser?.modules[MODULES.CONNECT];
    const companyRoleName = selectedUser?.modules[MODULES.COMPANY];
    const teamRoleName = selectedUser?.modules[MODULES.TEAMS];
    const timeRoleName = selectedUser?.modules[MODULES.TIME];

    if (payrollRoleName) {
      setPayrollRole(payrollRoles.find(role => role.name === payrollRoleName) || null);
      setIsPayrollSelected(true);
    }
    if (connectRoleName) {
      setConnectRole(connectRoles.find(role => role.name === connectRoleName) || null);
      setIsHiringSelected(true);
    }
    if (companyRoleName) {
      setCompanyRole(companyRoles.find(role => role.name === companyRoleName) || null);
      setIsCompanySelected(true);
    }
    if (teamRoleName) {
      setTeamRole(teamsRoles.find(role => role.name === teamRoleName) || null);
      setIsTeamsSelected(true);
    }

    if (timeRoleName) {
      setTimeRole(timeRoles.find(role => role.name === timeRoleName) || null);
      setIsTimeSelected(true);
    }
  };

  const handleRoles = async (id: string, selectedUser?: Staff) => {
    const { connectRoles, payrollRoles, companyRoles, teamsRoles, timeRoles } = await getPayrollRoles(id);
    setConnectRoles(connectRoles);
    setPayrollRoles(payrollRoles);
    setCompanyRoles(companyRoles);
    setTeamsRoles(teamsRoles);
    setTimeRoles(timeRoles);
    if (selectedUser) {
      applyRoles({ connectRoles, payrollRoles, companyRoles, teamsRoles, timeRoles });
    }
  };

  useEffect(() => {
    const id = selectedCompanyId || selectedUser?.company?.company_id;
    if (id) {
      handleRoles(id, selectedUser);
    }
  }, [selectedCompanyId, selectedUser]);

  useEffect(() => {
    if (companyRole && isCompanySelected) {
      const payrollAdminRole = payrollRoles.find(role => role.name === 'Admin');
      const teamsAdminRole = teamsRoles.find(role => role.name === 'Admin');
      const connectAdminRole = connectRoles.find(role => role.name === 'Admin');
      const timeAdminRole = timeRoles.find(role => role.name === 'Admin');
      setIsPayrollSelected(true);
      setIsTeamsSelected(true);
      setIsHiringSelected(true);
      setIsTimeSelected(true);
      setTeamRole(teamsAdminRole || null);
      setPayrollRole(payrollAdminRole || null);
      setConnectRole(connectAdminRole || null);
      setTimeRole(timeAdminRole || null);
    }
  }, [companyRole, isCompanySelected]);

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleClose();
        }
      }}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(selectedUser ? editPermissions : sendInvite)}>
          <DialogTitle>{selectedUser ? 'Edit Permissions' : 'Invite new Admin to VYCE modules'}</DialogTitle>
          <DialogContent>
            <Box width={isMobile ? 'auto' : 500}>
              {!selectedUser && (
                <Box marginBottom={2}>
                  {companies?.length && (
                    <Box marginBottom={1}>
                      <CompanyFilter
                        companies={companies}
                        onCompanyChange={(e: any, company: any) => setSelectedCompanyId(company?.uuid)}
                      />
                    </Box>
                  )}

                  <EmailField margin="normal" name="email" rules={{ pattern: emailPattern }} />

                  <AppTextField
                    multiline
                    fullWidth
                    margin="normal"
                    value={position}
                    onChange={e => setPosition(e.target.value)}
                    label="Position"
                    InputProps={{
                      endAdornment: (
                        <Box marginRight={1}>
                          <DetailsIcon
                            content={<>Position will be displayed on user’s profile.</>}
                            popoverClass={classes.popoverClass}
                          />
                        </Box>
                      ),
                    }}
                  />
                </Box>
              )}

              <ModulePermissionItem
                label="Company"
                roles={companyRoles}
                setIsSelected={setIsCompanySelected}
                isSelected={isCompanySelected}
                setRole={setCompanyRole}
                selectedRole={companyRole}
              />

              <ModulePermissionItem
                label="Payroll"
                roles={payrollRoles}
                setIsSelected={setIsPayrollSelected}
                isSelected={isPayrollSelected}
                setRole={setPayrollRole}
                selectedRole={payrollRole}
              />

              <ModulePermissionItem
                label="Hiring"
                roles={connectRoles}
                setIsSelected={setIsHiringSelected}
                isSelected={isHiringSelected}
                setRole={setConnectRole}
                selectedRole={connectRole}
              />

              <ModulePermissionItem
                label="Time & Attendance"
                roles={timeRoles}
                setIsSelected={setIsTimeSelected}
                isSelected={isTimeSelected}
                setRole={setTimeRole}
                selectedRole={timeRole}
              />

              <ModulePermissionItem
                label="Teams"
                roles={teamsRoles}
                setIsSelected={setIsTeamsSelected}
                isSelected={isTeamsSelected}
                setRole={setTeamRole}
                selectedRole={teamRole}
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button size="large" variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              size="large"
              disabled={!selectedUser && ((!email && !phone) || !position || !selectedCompanyId)}
              variant="contained"
              color="primary"
              type="submit">
              {selectedUser ? 'Save' : 'Send'}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
