import React, { useContext } from 'react';

import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';

import { ControlledSelect } from '@vyce/core/src/components/controlled-inputs/ControlledSelect';
import { OnboardingStepTitle, DetailsIcon } from '@vyce/core/src/components';

import { ManualClockContext } from './ManualClockProvider';
import { TransformedShift } from '../types';
import { useStyles } from '../styles';

const rules = {
  required: 'Shift is required',
};
const name = 'shift';

export const ShiftsView = ({
  shifts,
  title = 'Shifts Work Hours for today:',
}: {
  shifts: TransformedShift[];
  title?: string;
}) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" gridGap={12}>
      <Typography variant="subtitle2" className={clsx(classes.bold, classes.smallText)}>
        {title}
      </Typography>
      {shifts.map(item => (
        <Box key={item.id}>
          <Typography variant="subtitle2">{item.name}:</Typography>
          <Box display="flex">
            <Typography variant="subtitle2">
              {item.regular.start} - {item.regular.end}
            </Typography>
            {item.overtime.start && item.overtime.end && (
              <Box display="flex" gridGap={4}>
                <span>,</span>
                <Typography variant="subtitle2" className={classes.bold}>
                  OT:
                </Typography>
                <Typography variant="subtitle2">
                  {item.overtime.start} - {item.overtime.end}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export const ShiftStep = () => {
  const { action, shifts } = useContext(ManualClockContext);
  const { watch } = useFormContext();

  const selectedShift: TransformedShift | undefined = watch(name);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <OnboardingStepTitle
          text={`Which shift should they get clocked ${action === 'in' ? 'into' : 'out of'}?`}
        />
        {shifts && <DetailsIcon size="24px" content={<ShiftsView shifts={shifts} />} />}
      </Box>
      <ControlledSelect name={name} label="Select Shift" rules={rules} margin="normal" items={shifts} />
      {selectedShift && !selectedShift.inRange && (
        <Typography color="error">Worker will be clocked in outside of the shift work hours.</Typography>
      )}
    </>
  );
};
