import {
  getCisReportsRequest,
  sendCis300ToHMRCRequest,
  downloadCISReportRequest,
} from '@vyce/core/src/api/pay';
import React from 'react';
import { Reports } from '@vyce/core/src/views/payroll/Reports';
import { useTypedSelector } from '../../hooks';

export const EmployerReports: React.FC = () => {
  const { access_token, selectedCompany } = useTypedSelector(state => state.helper);

  if (!selectedCompany?.uuid) {
    return <></>;
  }

  return (
    <Reports
      downloadCISReportRequest={downloadCISReportRequest}
      token={access_token}
      companyId={selectedCompany?.uuid}
      getCisReportsRequest={getCisReportsRequest}
      sendCis300ToHMRCRequest={sendCis300ToHMRCRequest}
    />
  );
};
